import React, { SVGAttributes } from "react";

const FilterCross = (props: SVGAttributes<HTMLOrSVGElement>) => {
	const { stroke = "var(--gray-dark)", fill = "var(--background-dark-main)" } = props;
	return (
		<svg
			width="12px"
			height="12px"
			viewBox="0 0 12 12"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g id="Comp---New-Nav" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="New-Nav---For-You-Configure-Finance-Aded" transform="translate(-65, -224)">
					<g id="Header" transform="translate(0, -24)">
						<g id="Page-Configurer-Modal" transform="translate(4, 65)">
							<g id="Filter-Tags" transform="translate(11, 40)">
								<g id="select-ctag---health-+-select-ctag---health-copy-+-select-ctag---crypto-+-select-ctag---finance-+-select-ctag---finance-copy-Mask" transform="translate(0, 125)">
									<g id="select-ctag---crypto" transform="translate(2, 12)">
										<g id="CB-Icon-Oval" transform="translate(48, 6)">
											<circle id="close-btn" strokeOpacity="0" stroke={stroke} fill={fill} cx="6" cy="6" r="5.5" />
											<g id="cross" transform="translate(2.0702, 2.0573)" stroke="#FFFFFF" strokeLinecap="round" strokeWidth="0.75">
												<g id="Group-4" transform="translate(3.9299, 3.9299) rotate(-315) translate(-3.9299, -3.9299)translate(1.151, 1.1511)">
													<line x1="1.20566838e-14" y1="2.75377316" x2="5.55774824" y2="2.75377316" id="Stroke-1" />
													<line x1="2.77056277" y1="2.79328422e-14" x2="2.77056277" y2="5.55761515" id="Stroke-3" />
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default FilterCross;
