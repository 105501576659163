import React, { SVGAttributes } from "react";

const VoltsIcon = (props: SVGAttributes<SVGElement>) => {
	return (
		<svg
			width="21px"
			height="21px"
			viewBox="0 0 21 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<defs>
				<circle id="path-r9zn4fmhlm-1" cx="10.5" cy="10.5" r="10.5"></circle>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-r9zn4fmhlm-3">
					<stop stopColor="#00FF44" offset="0%"></stop>
					<stop stopColor="#FFAD00" offset="25%"></stop>
					<stop stopColor="#422200" offset="100%"></stop>
				</linearGradient>
				<polygon id="mask-lightningvolt"
					points="8.50044523 -1.47937218e-14 1.03153098 6.5 4.79379943 6.5 3.54781804 13 11.031531 6.5 7.24180787 6.5"></polygon>
			</defs>
			<g>
				<mask id="mask-r9zn4fmhlm-2" fill="white">
					<use xlinkHref="#path-r9zn4fmhlm-1"></use>
				</mask>
				<circle stroke="#FF8C01" strokeWidth="0.5" cx="10.5" cy="10.5" r="10" />
				<g id="icon-volt-lightning" mask="url(#mask-r9zn4fmhlm-2)">
					<g transform="translate(4.5, 3.75)">
						<mask id="mask-r9zn4fmhlm-5" fill="white">
							<use xlinkHref="#mask-lightningvolt"></use>
						</mask>
						<use id="Path-2" stroke="#FF8C01" strokeWidth="0.5" fill="url(#linearGradient-r9zn4fmhlm-3)"
							fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" xlinkHref="#mask-lightningvolt" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default VoltsIcon;
