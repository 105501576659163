import {
	AuthoorisationEngineFactory,
	DobeEngine,
	TestPermissionsProvider,
	Context, Permission,
} from "@credo/utilities";
import { permContextProvider, permObjectTypeResolver } from "./managerUtils";

export interface DobeEngineFactoryParams {
	context: Context,
	permissions: Permission[]
}

// eslint-disable-next-line import/prefer-default-export
export class DobeEngineFactory implements AuthoorisationEngineFactory<DobeEngine> {
	// TODO: Should this be kept like this (TestPermissionsProvider)?
	static permissionsPublisher = new TestPermissionsProvider();

	// eslint-disable-next-line class-methods-use-this
	create(params: DobeEngineFactoryParams): DobeEngine {
		const res = new DobeEngine(
			DobeEngineFactory.permissionsPublisher,
			permObjectTypeResolver,
		);
		permContextProvider.context.addFrom(params.context);
		res.setContextProvider(permContextProvider);
		// configure provided permissions
		// eslint-disable-next-line array-callback-return
		params.permissions.map((p) => {
			DobeEngineFactory.permissionsPublisher.perms.add(p);
		});
		DobeEngineFactory.permissionsPublisher.publish();
		return res;
	}

	// eslint-disable-next-line class-methods-use-this
	reset() {
		DobeEngineFactory.permissionsPublisher.reset();
	}
}
