import React, { useEffect, useState } from "react";
import {
	AFSemailSent_ego,
	BackIcon, ButtonSize, classNames, ConfirmationModal, EmailIcon, InputField, PrimaryButton, SnackBarTypeOptions, Spinner, SvgIcon,
} from "@credo/ui-components";
import { useAtom, useSetAtom } from "jotai";
import { cu, EvtMgr, RegEx } from "@credo/utilities";
import { useWindowSize } from "react-use";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
	email4SignInLinkToEmailAtom, firebaseMgrAtom, userProfileAtom, userSessionAtom,
} from "../../../utils/atoms";
import { strings } from "../../../i18n/config";
import {
	AppUtils, Consts, EventConst,
} from "../../../utils";
import { HelmetComponent } from "../../../components/HelmetComponent";
import { PROFILE_PAGE, SETTINGS_PAGE } from "../../../routes/constants";
import { UserActions } from "../../../services/actions/user";
import { SocialLoginType } from "../../../utils/types";
import { findOrCreateUser } from "../../auth/requests";
import { RouteMgr } from "../../auth/RouteMgr";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

const ChangeEmail: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [firebaseMgr] = useAtom(firebaseMgrAtom);
	const { width } = useWindowSize();
	const [userProfile] = useAtom(userProfileAtom);
	const isInMobile = isMobile && width < 768;
	const [emailSending, setEmailSending] = useState<boolean>(false);
	const [userSession] = useAtom(userSessionAtom);
	const [loading, setLoading] = useState<boolean>(true);
	const [email, setEmail] = useState("");
	const [newEmail, setNewEmail] = useState("");
	const [showError, setShowError] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [isEmailVerified, setIsEmailVerified] = useState(true);
	const setEmailInStorage = useSetAtom(email4SignInLinkToEmailAtom);
	const [isEmailSentSuccessfully, setIsEmailSentSuccessfully] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	useSetAppHeader({
		title: strings("AppHeader.email"),
	});

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
	};

	const getUserEmail = () => {
		// eslint-disable-next-line no-nested-ternary
		const customEmail = userSession?.verified_custom_email ? userSession?.verified_custom_email
			: userProfile?.email_custom !== "none" && userProfile?.email_custom ? userProfile?.email_custom : null;
		if (customEmail) {
			setEmail(customEmail);
			setIsEmailVerified(true);
		}

		const userEmail = userProfile?.email_custom_rv ? userProfile.email_custom_rv : userSession?.email4SignInLinkToEmail;
		if (!!userEmail && (!customEmail || (userEmail !== customEmail))) {
			setEmail(userEmail);
			setIsEmailVerified(false);
		}
	};

	const removeEmail = () => {
		const requestObject = {
			user_id: userSession?.user_id || "",
			phone: null,
			verificationCode: null,
			email,
			password: null,
			emailSource: SocialLoginType.CUSTOM_EMAIL,
			firebaseUid: null,
			action: "d",
			new_phone: "",
			cntry_code: "",
			default_email: "n",
		};
		findOrCreateUser(requestObject, null, false);
		cu.setGlobalVar("isFirbaseLinkVerified", false);
		setEmail("");
		closeConfirmationModal();
		setEmailInStorage("");
		cu.setGlobalVar(Consts.email4SignInLinkToEmail, "");
		cu.setGlobalVar(Consts.customEmailDetails, null);
		EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
			items: [
				{
					...userProfile,
					email_custom_rv: "",
					email_custom: "",
				},
			],
		});
	};

	useEffect(() => {
		if (userProfile && userSession) {
			getUserEmail();
		}
		RouteMgr.setDontAllowRouteChange(false);
	}, [userProfile, userSession]);

	useEffect(() => {
		if (userProfile && userSession) {
			setLoading(false);
		}
	}, [email]);

	const backToSettingPage = () => {
		setIsEmailSentSuccessfully(false);
	};

	const updateAfterEmailIsSent = () => {
		setEmailSending(false);
		setIsEmailSentSuccessfully(true);
		const emailToSend = newEmail || email;
		setEmailInStorage(emailToSend);
		cu.setGlobalVar(Consts.email4SignInLinkToEmail, emailToSend);
		cu.setGlobalVar(Consts.customEmailDetails, null);
		EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
			items: [
				{
					...userProfile,
					email_custom_rv: newEmail,
				},
			],
		});
		setNewEmail("");
	};

	const updateAfterSendError = () => {
		setEmailSending(false);
		setNewEmail("");
	};

	const changeUserEmail = async () => {
		setIsValidEmail(false);
		setEmailSending(true);
		const emailToSend = newEmail || email;
		await firebaseMgr.doSendSignInLinkToEmail(emailToSend, updateAfterEmailIsSent, updateAfterSendError, true);
		if (newEmail) {
			const requestObj = {
				user_id: userSession?.user_id || "",
				email_custom_rv: newEmail,
			};
			UserActions.addCustomEmail(requestObj);
		}
	};

	const doValidateEmailFormat = (event: any) => {
		const text = event?.target?.value;
		setNewEmail(text);
		setIsValidEmail(RegEx.emailValidation.test(String(text).toLowerCase()));
		setShowError(!RegEx.emailValidation.test(String(text).toLowerCase()) && text);

		if (text.toLowerCase().replace(/\s/g, "") === email.toLowerCase().replace(/\s/g, "")) {
			setIsValidEmail(false);
			AppUtils.showToast({
				type: SnackBarTypeOptions.ERROR,
				message: strings("ChangeEmail.same_email_error"),
			});
		}
	};

	if (loading) {
		return (
			<div
				className={classNames(
					"flex flex-col justify-center items-center",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					cu.getGlobalVar(Consts.isBottomNavVisible) ? "" : "pt-4",
					isInMobile ? "" : "px-3",
					"text-basic",
					"bg-background",
					isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
				)}
			>
				<Spinner leaf_fill="var(--primary)" height="32" width="32" />
			</div>
		);
	}

	return (
		<div
			className={classNames(
				"flex flex-col",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-9",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<HelmetComponent
				title={strings("ChangeEmail.email")}
				url={location?.pathname}
			/>
			{isEmailSentSuccessfully ? (
				<div className="w-full h-full flex flex-col items-center pt-[15%]">
					<div className="text-center">
						<SvgIcon
							icon={AFSemailSent_ego}
						/>
					</div>
					<h3 className="text-lg font-medium text-basic pt-4">
						{strings("ChangeEmail.email_sent")}
					</h3>
					<div className="sm:w-[60%] 2xs:w-[80%] text-sm text-basic text-center pt-4 w-4/5 ml-4">
						{strings("ChangeEmail.email_sent_message")}
					</div>
					<div className="mt-8">
						<PrimaryButton
							buttonClassNames="outline-none"
							size={ButtonSize.MEDIUM}
							label={strings("ChangeEmail.button_return_settings")}
							onClick={backToSettingPage}
						/>
					</div>
				</div>
			) : (
				<>
					{/* <div className="flex flex-row items-center pb-4">
						<div className="px-1 cursor-pointer" onClick={() => navigate(`${PROFILE_PAGE}${SETTINGS_PAGE}`)} aria-hidden>
							<SvgIcon
								icon={BackIcon}
								className="sm:ml-1 2xs:ml-4 cursor-pointer"
								color="var(--text-base)"
							/>
						</div>
						<h4 className="text-md font-normal text-basic pl-3 pr-1">
							{strings("ChangeEmail.email")}
						</h4>
					</div> */}
					{email ? (
						<div className="w-full flex flex-col items-center justify-center px-0 pt-2">
							<span className="w-4/5 text-center text-dark-gray text-sm mt-4 whitespace-pre-wrap">
								{strings("ChangeEmail.current_email_text")}
							</span>
							<span className="w-4/5 text-center font-bold text-basic text-sm mt-2 whitespace-pre-wrap">
								{email}
							</span>
							{!isEmailVerified ? (
								<div className="flex flex-row py-1">
									<span className="text-sm text-error">
										{strings("ChangeEmail.not_verified")}
									</span>
									<span
										className="text-primary text-sm pl-1 cursor-pointer"
										onClick={changeUserEmail}
										aria-hidden
									>
										{strings("ChangeEmail.resend_link")}
									</span>
								</div>
							) : (
								<div className="flex flex-row pb-2">
									<span
										className="text-sm text-error cursor-pointer"
										onClick={() => { setOpenConfirmationModal(true); }}
										aria-hidden
									>
										{strings("ChangeEmail.remove_email")}
									</span>
								</div>
							)}
							<span className="sm:w-[60%] 2xs:w-[80%] text-center text-basic text-sm mt-4 whitespace-pre-wrap">
								{strings("ChangeEmail.explain_change_email")}
							</span>
						</div>
					) : (
						<div className="w-full flex flex-col items-center justify-center px-2 pt-2">
							<span className="sm:w-[60%] 2xs:w-[80%] text-center text-dark-gray text-sm mt-4 whitespace-pre-wrap">
								{strings("ChangeEmail.explain_need_email")}
							</span>
						</div>
					)}
					<div className="flex flex-col py-5 w-full items-center justify-center">
						<div className="flex flex-col sm:w-1/2 2xs:w-4/6">
							<InputField
								value={newEmail}
								name="email"
								wrapperClassName=""
								onChange={doValidateEmailFormat}
								leftIcon={<EmailIcon width={18} height={18} />}
								placeholder={email ? strings("ChangeEmail.new_email")
									: strings("ChangeEmail.email")}
							/>
							{showError && (
								<span
									className="text-error text-left text-support absolute mt-10 pt-1 px-5"
								>
									{strings("ChangeEmail.email_error_message")}
								</span>
							)}
							<PrimaryButton
								size={ButtonSize.LARGE}
								label={email ? strings("ChangeEmail.button_change_email")
									: strings("ChangeEmail.button_verify_email")}
								handleClick={changeUserEmail}
								buttonClassNames="rounded-full !w-full mt-6"
								disabled={!isValidEmail}
								isLoading={emailSending}
							/>
						</div>
					</div>
				</>
			)}
			<ConfirmationModal
				isVisible={openConfirmationModal}
				onClose={closeConfirmationModal}
				cancel_button_function={closeConfirmationModal}
				ok_button_title={strings("ChangeEmail.remove")}
				cancel_button_title={strings("ChangeEmail.cancel")}
				ok_button_function={removeEmail}
				title={strings("ChangeEmail.remove_email")}
				message={strings("ChangeEmail.confirm_message")}
				okButtonStyle=""
				cancelButtonStyle=""
			/>
		</div>
	);
};

export default ChangeEmail;
