import { BackIcon, classNames, SvgIcon } from "@credo/ui-components";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { cu } from "@credo/utilities";
import { HelmetComponent } from "../../components/HelmetComponent";
import { strings } from "../../i18n/config";
import { ENGAGE_PAGE } from "../../routes/constants";
import { newStreamSelectedExpertise } from "../../utils/atoms";
import ExpertiseEditScreen from "./ExpertiseEditScreen";
import NewStreamSettingsScreen from "./NewStreamSettingsScreen";
import StreamEditDetails from "./StreamEditDetails";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";
import { Consts } from "../../utils";

export enum NewStreamSections {
	BASIC_DETAILS = "BASIC_DETAILS",
	STREAM_SETTINGS = "STREAM_SETTINGS",
	STREAM_EXPERTISE = "STREAM_EXPERTISE",
}

const CreateStream: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { width } = useWindowSize();
	const isInMobile = isMobile && width < 768;
	const [contentType, setContentType] = useState<NewStreamSections>();
	const [prevContentType, setPrevContentType] = useState<NewStreamSections>(NewStreamSections.BASIC_DETAILS);
	const [newStreamObj, setNewStreamObj] = useState<any>({});
	const [selectedTags, setSelectedTags] = useState<any>([]);
	const [newStreamSelectedExpertiseAtom, setNewStreamSelectedExpertiseAtom] = useAtom(newStreamSelectedExpertise);

	useSetAppHeader({
		title: strings("AppHeader.new_community"),
	});

	useEffect(() => {
		setNewStreamSelectedExpertiseAtom([]);
	}, [location]);

	const onDoneBasicDetails = (streamObj: any) => {
		if (streamObj) {
			setNewStreamObj({ ...newStreamObj, ...streamObj });
			setContentType(NewStreamSections.STREAM_SETTINGS);
			setPrevContentType(NewStreamSections.STREAM_SETTINGS);
		}
	};

	const onSubmitNewStreamSettings = (streamObj: any) => {
		if (streamObj) {
			setNewStreamObj({ ...newStreamObj, ...streamObj });
			setContentType(NewStreamSections.STREAM_EXPERTISE);
			setPrevContentType(NewStreamSections.STREAM_EXPERTISE);
		}
	};

	const onChangeStreamSettings = (streamObj: any) => {
		if (streamObj) {
			setNewStreamObj({ ...newStreamObj, ...streamObj });
		}
	};

	const onCreateStreamButtonClick = () => {
		navigate(ENGAGE_PAGE);
	};

	const backToPrevious = () => {
		if (prevContentType === NewStreamSections.BASIC_DETAILS) {
			navigate(ENGAGE_PAGE);
		} else if (prevContentType === NewStreamSections.STREAM_SETTINGS) {
			setContentType(NewStreamSections.BASIC_DETAILS);
			setPrevContentType(NewStreamSections.BASIC_DETAILS);
		} else if (prevContentType === NewStreamSections.STREAM_EXPERTISE) {
			setContentType(NewStreamSections.STREAM_SETTINGS);
			setPrevContentType(NewStreamSections.STREAM_SETTINGS);
			setSelectedTags(newStreamSelectedExpertiseAtom);
		}
	};

	const renderContent = () => {
		switch (contentType) {
			case NewStreamSections.STREAM_SETTINGS:
				return (
					<NewStreamSettingsScreen
						onSubmit={onSubmitNewStreamSettings}
						onChangeValues={onChangeStreamSettings}
						selectedValues={newStreamObj}
					/>
				);
			case NewStreamSections.STREAM_EXPERTISE:
				return (
					<ExpertiseEditScreen
						addedExpertise={selectedTags}
						tagLimit={4}
						onDone={onCreateStreamButtonClick}
						streamObj={newStreamObj}
					/>
				);
			default: return (
				<StreamEditDetails
					onClose={() => { }}
					onDone={onDoneBasicDetails}
					newStreamObj={newStreamObj}
				/>
			);
		}
	};

	return (
		<div
			className={classNames(
				"flex flex-col justify-start",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-8",
			)}
		>
			<HelmetComponent
				title={strings("StreamSettingsMenuScreen.group_settings")}
				url={location?.pathname}
			/>
			{/* <div className="flex flex-row items-center pb-2">
				<div className="px-1 cursor-pointer" onClick={backToPrevious} aria-hidden>
					<SvgIcon
						icon={BackIcon}
						className="sm:ml-1 2xs:ml-4 cursor-pointer"
						color="var(--text-base)"
					/>
				</div>
				<h4 className="text-md font-normal text-basic pl-3 pr-1">
					{strings("AddOrEditStreamDetailsModal.create_stream")}
				</h4>
			</div> */}
			{cu.getGlobalVar(Consts.isBottomNavVisible) && <div className="h-3 bg-background w-full" />}
			{renderContent()}
		</div>
	);
};

export default CreateStream;
