import React, { useEffect } from "react";
import {
	AppMode, cu, dbg, EvtMgr, LogMgr, SessMgr, toggleTheme, YesNoOptions,
} from "@credo/utilities";
import { useAtom, useSetAtom } from "jotai";
import { emojiDataAtom, isCredoModeAtom, removedHintsAtom } from "@credo/store";
import RoutesMain from "./routes";
import {
	allCachedImagesAtom,
	applicationModeAtom,
	downloadAppBannerAtom,
	email4SignInLinkToEmailAtom,
	eventBusMgrAtom,
	firebaseMgrAtom,
	isConnectedAtom,
	isSessInProgressAtom,
	isSessionAuthAtom,
	newsDataAtom,
	showValidatingEmailModalAtom,
	userProfileAtom,
	userSessionAtom,
} from "./utils/atoms";
import "./utils/globalDeclarations";
import GlobalProvider from "./routes/GlobalProvider";
import { UserActions } from "./services/actions/user";
import { ThemeOptions } from "./utils/types";
import {
	AnalyticEventsConst, Consts, EventConst, InitAppManager,
} from "./utils";
import { MsgMgr } from "./config/MsgMgr";
import { SearchActions } from "./services/actions/search";
import { ElementLocators } from "./utils/ElementLocators";

function App() {
	const [eventBusMgr] = useAtom(eventBusMgrAtom);
	const [firebaseMgr] = useAtom(firebaseMgrAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [userSession, setUserSession] = useAtom(userSessionAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [isSessInProgress] = useAtom(isSessInProgressAtom);
	const updateNewsData = useSetAtom(newsDataAtom);
	const [email4SignInLinkToEmail] = useAtom(email4SignInLinkToEmailAtom);
	const [, setShowDownloadAppBanner] = useAtom(downloadAppBannerAtom);
	const setApplicationMode = useSetAtom(applicationModeAtom);
	const [cachedImages] = useAtom(allCachedImagesAtom);
	const setIsCredoMode = useSetAtom(isCredoModeAtom);
	const setEmojiData = useSetAtom(emojiDataAtom);
	const [isValidatingEmailModalOpen] = useAtom(showValidatingEmailModalAtom);
	const setRemovedHintsData = useSetAtom(removedHintsAtom);

	InitAppManager();

	/**
	 * Checks if the page is reloaded or is opened by new url on
	 * a new tab.
	 * */
	const handleDownloadAppBannerOnReload = () => {
		// check for Navigation Timing API support
		if (window.performance) {
			if (dbg) LogMgr.mydbg("window.performance works fine on this browser", performance.navigation.type);
		}
		if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
			if (dbg) LogMgr.mydbg("This page is reloaded");
		} else {
			if (dbg) LogMgr.mydbg("This page is not reloaded");
			setShowDownloadAppBanner(YesNoOptions.YES);
		}
	};

	const myappInit = async () => {
		if (window) {
			// should be added to window when application is mounted
			(window as any).ElementLocator = ElementLocators;
		}
		global.ebMgr = eventBusMgr;
		await eventBusMgr.init();
		if (dbg) LogMgr.mydbg("ebMgr inited");
		global.firebaseMgr = firebaseMgr;
		firebaseMgr.init();
		await MsgMgr.init();

		// Set app in credo mode and change the theme
		const currentMode = cu.getAppMode();
		const currentSwitchVal = cu.getSwitchValue();
		setIsCredoMode(currentSwitchVal);
		toggleTheme(currentMode === AppMode.EGO ? ThemeOptions.EGO : ThemeOptions.CREDO);
		setApplicationMode(currentMode);
		cu.setGlobalVar(Consts.isCredoMode, currentSwitchVal);

		// check if needs to render download app banner or not
		handleDownloadAppBannerOnReload();
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.appLaunch,
		});
	};

	useEffect(() => {
		myappInit();
	}, []);

	const getAllExistingTags = async () => {
		const request = {
			user_id: userSession?.user_id,
			mode: cu.getAppMode(),
			what: "tags",
			tag_dictionary: YesNoOptions.YES,
		};
		await SearchActions.retrieveAllExistingTags(request);
	};

	useEffect(() => {
		if (isConnected && SessMgr.isSessionAuth()) {
			if (!userProfile?.firstName && userSession?.firstName) {
				EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
					items: [
						{
							firstName: userSession?.firstName,
							lastName: userSession?.lastName,
							cprofilePicRelUrl: userSession?.cprofilePicRelUrl,
							eprofilePicRelUrl: userSession?.eprofilePicRelUrl,
							username: userSession?.username,
							egousername: userSession?.egousername,
							cprof_id: userSession?.cprof_id,
							eprof_id: userSession?.eprof_id,
						},
					],
				});
			}
			setRemovedHintsData("");
			UserActions.retrieveInfoFromSvr();
			UserActions.getEmoItems(setEmojiData);
			UserActions.retrieveUserNotifAndReq();
			if (firebaseMgr.credoApp && isSessionAuth) {
				const link = cu.getGlobalVar("signInLink");
				if (link) {
					cu.setGlobalVar(Consts.customEmailDetails, null);
					firebaseMgr.handleSignInViaEmail(link);
				}
				cu.setGlobalVar("signInLink", null);
			}
			UserActions.getUserClientMetadata();
			UserActions.getWalletBalance();
			getAllExistingTags();
			EvtMgr.getInstance(EventConst.setUserSearchHistory).notifyListeners();
			EvtMgr.getInstance(EventConst.showHomeSuspense).notifyListeners(false);
			cu.setGlobalVar(Consts.allCachedImages, cachedImages);
			localStorage.removeItem(Consts.regCode);
			// News feed should be updated when user logs in.
			updateNewsData([]);
		}
	}, [isConnected, isSessionAuth, isSessInProgress]);

	useEffect(() => {
		if (window.location.href.match(/signInViaEmailLink/) && !isValidatingEmailModalOpen) {
			cu.setGlobalVar("signInLink", window.location.href);
			cu.setGlobalVar(Consts.email4SignInLinkToEmail, email4SignInLinkToEmail);
		}
	}, [isConnected]);

	useEffect(() => {
		/**
		 * If phone is stored in the session and user is not authenticated
		 * when user used to open the login modal the country code used to
		 * be empty.
		 *
		 * CWEB-413, CWEB-371
		 * */
		if (!(userSession?.sessAuth || userSession?.sessInProgress) && userSession?.phone) {
			setUserSession(null);
		}
	}, []);

	return (
		<GlobalProvider>
			<RoutesMain />
		</GlobalProvider>
	);
}

export default App;
