import { classNames, LazyLoad, OtherUserProfileSkeleton } from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const OtherUserProfile = LazyLoad(
	() => import(/* webpackChunkName: "OUProfilePage" */ "./OtherUserProfile"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					isMobile ? "" : "px-3",
					"text-basic",
				)}
				data-testid="community-details-loading-wrapper"
			>
				<OtherUserProfileSkeleton />
			</div>
		),
	},
);
