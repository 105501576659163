import React, { useEffect, useMemo, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppMode, cu, EvtMgr } from "@credo/utilities";
import { ProfilePicture } from "../../profile/profile-picture";
import {
	createDataProvider, CredoTag, CredoTagDataProvider, NullCredoTagScoreData,
} from "../../credo-tag";
import {
	classNames, Consts, EventConst, ScalerSize,
} from "../../common";
import { CredoSwitch, CredoSwitchProps } from "../../credo-switch";
import { TagInfoProps, UxpRatingCardMessages } from "../types";
import { SecondaryButton, ButtonSize } from "../../button";

interface UxpRatingCardInfoProps {
	id: string;
	card: any;
	handleClickUser: (id: string, username: string, mode: string, egousername: string, targetText: string) => void;
	onRateCard: (tag: string, rating: any, card: any) => void;
	removeCard: (data: any) => void;
	switchProps: CredoSwitchProps;
	tagInfoProps: TagInfoProps;
	messages: UxpRatingCardMessages;
}

export const UxpRatingCardInfo = (props: UxpRatingCardInfoProps) => {
	const {
		id,
		card,
		handleClickUser,
		removeCard,
		switchProps,
		messages,
		onRateCard,
		tagInfoProps,
	} = props;

	const [item, setItem] = useState<any>(null);
	const [showFadeInAnimation, setShowFadeInAnimation] = useState(false);
	const [tagScoreData, setTagScoreData] = useState<CredoTagDataProvider>(createDataProvider(50, NullCredoTagScoreData));
	const targetText = useMemo(() => {
		if (item && item["p.dest_firstName"]) {
			const username = `${item["p.dest_egousername"] ? `@${item["p.dest_egousername"]}` : ""}`;
			return `${item["p.dest_firstName"]} ${item["p.dest_lastName"]} ${username}`;
		}
		return "";
	}, [item]);

	const formatDataForXpCard = () => {
		if (card) {
			let formattedData = {};
			if (card.pcodes) {
				const uxptag = Object.keys(card.pcodes)?.[0];
				const tagData = card.pcodes[uxptag];
				formattedData = {
					...card,
					uxptag,
					...tagData,
				};
			}
			setItem(formattedData);
		}
	};

	useEffect(() => {
		formatDataForXpCard();
		setShowFadeInAnimation(false);
	}, [card]);

	const renderToolTip = () => {
		if (!switchProps.isCredo) {
			return (
				<div
					className="flex w-28 h-32 justify-center items-center rounded-lg opacity-95 bg-white shadow-no-rate-tooltip"
				>
					<div className="flex flex-col justify-center items-center">
						<CredoSwitch
							size={switchProps.size}
							uCredibility={switchProps.uCredibility}
							userProfile={switchProps.userProfile}
							isCredo={switchProps.isCredo}
							onModeChange={switchProps.onModeChange}
						/>
						<p className="w-24 mt-2 text-gray-dark text-xs text-center">
							{messages.SwitchToCredoModeToRate}
						</p>
					</div>
				</div>
			);
		}
		if (cu.isSet(cu.getGlobalVar(Consts.ratingFirstTime)) && cu.getGlobalVar(Consts.ratingFirstTime)) {
			EvtMgr
				.getInstance(EventConst.ratingTutorialModal)
				.notifyListeners({
					showModal: true,
				});
			return null;
		}
		return null;
	};

	const onNamePress = () => {
		handleClickUser(
			item["p.dest_prof_id"],
			item["p.username"],
			AppMode.EGO,
			item["p.dest_egousername"],
			targetText,
		);
	};

	const onTagRate = (tag: string, rating: any) => {
		setTagScoreData(createDataProvider(50, {
			engagement: item.engagement ?? -1,
			hasCampaign: item.has_campaign ?? false,
			objScore: item.g_credo_score ?? -1,
			ownInterest: item.is_interest ?? false,
			ownRating: rating ?? -1,
			relevance: item.relevance ?? -1,
			subjScore: item.s_credo_score ?? -1,
			subjectivity: item.subjectivity ?? 0.5,
		}));
		onRateCard(tag, rating, item);
	};

	if (!cu.isSet(item)) {
		return null;
	}

	return (
		<div>
			<div
				className={classNames(
					"px-8 py-1",
					"flex justify-center items-center",
					showFadeInAnimation ? "animate-fadeIn" : "",
				)}
			>
				<div className="shadow-rating-cards rounded-15 w-full uxp-rating-card relative">
					<div
						className="flex flex-col w-full justify-start bg-background
								rounded-t-15 p-3"
					>
						<div className="flex flex-row mt-1 items-center w-full">
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(item["p.dest_profilePicRelUrl"]) ?? ""}
								profilePicStyle="flex flex-1"
							/>
							<p
								className={classNames("mx-2 text-base text-primary font-medium",
									"cursor-pointer text-start break-words flex flex-1")}
								style={{
									lineClamp: 2,
									maxWidth: "100%",
									display: "-webkit-box",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 2,
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
								onClick={onNamePress}
								aria-hidden
							>
								{item["p.dest_firstName"]}
								{item["p.dest_lastName"] ? ` ${item["p.dest_lastName"]}` : null}
							</p>
							<SecondaryButton
								disabled={false}
								size={ButtonSize.MEDIUM}
								label={messages.Dismiss}
								dimensionsClasses="w-20 h-7"
								buttonClassNames="capitalize text-xs"
								handleClick={() => removeCard({
									isCardRated: false,
									tag: item.pcodes,
									profId: item["p.dest_prof_id"],
									card: item,
								})}
							/>
						</div>
						<p className="flex justify-start mt-8 text-sm text-gray-dark">
							{messages.RateYourConnExpertise}
						</p>
					</div>
					<div
						className="flex px-4 py-4 rounded-b-15 justify-center bg-userRatingOverlayBackground z-20 overflow-visible"
					>
						<CredoTag
							id={id}
							tag={item.uxptag}
							size={ScalerSize.M}
							onRate={(tag: string, rating: number) => onTagRate(tag, rating)}
							dataProvider={tagScoreData}
							isUserOwnPost={false}
							isCredoMode={switchProps.isCredo}
							tooltip={renderToolTip}
							egoInfoProps={{
								switchComp: <CredoSwitch
									size={switchProps.size}
									userProfile={switchProps.userProfile}
									isCredo
									disabled
								/>,
								onPressCredoGraphText: tagInfoProps.onPressCredoGraphText,
								onPressTagInfoBubble: tagInfoProps.onPressTagInfoBubble,
							}}
							messages={messages.tagMessages}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UxpRatingCardInfo;
