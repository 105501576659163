import React from "react";
import { classNames, isWideCalc, WEB_SIZE_PROP_BUTTON } from "../../../common";
import Styles from "../BoostTray.module.css";

interface BalanceItemWrapperProps {
	/**
	 * unique id which will be used for testing and
	 * unique key.
	 * */
	id: string;
	children: any;
	className?: string;
	style?: object;
	isWide?: boolean,
	title?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const BalanceItemWrapper: React.FC<BalanceItemWrapperProps> = ({
	id,
	children,
	className,
	style = {},
	isWide = false,
	title,
}: BalanceItemWrapperProps) => (
	<div
		key={id}
		id={id}
		data-testid={id}
		style={{
			width: isWideCalc(isWide, 72, WEB_SIZE_PROP_BUTTON),
			height: isWideCalc(isWide, 18, WEB_SIZE_PROP_BUTTON),
			...style,
		}}
		className={classNames(
			"py-px px-1 rounded flex flex-row justify-between items-center",
			Styles.balanceItem,
			className,
		)}
		title={title}
	>
		{children}
	</div>
);

BalanceItemWrapper.defaultProps = {
	className: "",
	style: {},
	isWide: false,
	title: undefined,
};
