// eslint-disable-next-line max-classes-per-file
export interface PermIdentifiable {
	id: string;
}

export class Post implements PermIdentifiable {
	community: Community;

	author: User;

	id: string;

	// in production Post.name does not work in js it sets as e
	static resolveIdentifier: string = "Post";

	constructor(id: string, author: User, community: Community) {
		this.id = id;
		this.author = author;
		this.community = community;
	}
}

export class User implements PermIdentifiable {
	readonly id: string;

	readonly name: string;

	readonly loggedIn: boolean;

	readonly vault_balance: number;

	// in production User.name does not work in js it sets as e
	static resolveIdentifier: string = "User";

	constructor(id: string, name: string, loggedIn = true, vault_balance = 0) {
		this.id = id;
		this.name = name;
		this.loggedIn = loggedIn;
		this.vault_balance = vault_balance;
	}
}

export class Feature implements PermIdentifiable {
	readonly id: string;

	readonly name: string;

	// in production Feature.name does not work in js it sets as e
	static resolveIdentifier: string = "Feature";

	constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}
}

export class Community implements PermIdentifiable {
	readonly id: string;

	readonly name: string;

	// in production Community.name does not work in js it sets as e
	static resolveIdentifier: string = "Community";

	constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}
}
