import { SnackBarTypeOptions } from "@credo/ui-components";
import {
	AppMode, EvtMgr, PostType, cu, ResponseCode, YesNoOptions, dbg, LogMgr,
} from "@credo/utilities";
import { orderBy } from "lodash";
import CfgMgr from "../../config/CfgMgr";
import { strings } from "../../i18n/config";
import { AppUtils, EventConst, MsgConst } from "../../utils";
import { CommentItem, FeedCommentItem } from "../../utils/comments/types";
import { ExpertiseActions, ItemType } from "../../utils/types";
import FirebaseMgr from "../../utils/FirebaseMgr";

export enum DeleteItemType {
	Post = "p",
	Comment = "c",
	Stream = "s",
	User = "u",
}

export enum Status {
	// eslint-disable-next-line no-unused-vars
	OK = "ok",
	ERROR_COMMENT = "ERROR_COMMENT",
}

export enum ErrorInfo {
	POST_DELETED = "Post Deleted"
}

export interface RequestObject {
	lastTs: number;
	lastId: number;
	// optional user id because we want to fetch the data even if the user is not logged in
	user_id?: string;
	mode: AppMode;
	fetchSize: number;
	summary?: "y" | "n" | "h";
	newestTs?: number;
	exca?: string;
	inca?: string;
	APIVersion?: string;
	filterByTags: string;
}

export interface FeedItem {
	comments: CommentItem[];
	emoCode: (string | null)[][];
	emoCountA: (number | null)[][];
	postTags: (string)[];
	id: number;
	nb_of_emo: number;
	nb_ratings: number;
	"p.creat_ts": number;
	"p.created_by": "u";
	"p.edit_ts": number;
	"p.mode": AppMode;
	"p.modif_ts": number;
	"p.nb_views": number;
	"p.postText": string;
	"p.postTitle": string;
	"p.postType": PostType;
	"p.postUuid": string;
	"p.prof_id": string;
	"p.profilePicRelUrl": string;
	"p.username": string;
	"p.firstName": string;
	"p.lastName": string;
	"p.shared_cnt"?: number;
	"p.egousername": string;
	"s.struname": string;
	"p.nb_of_co": number;
	"s.stramId": string;
	"p.shared_postUuid": string;
	"p.shared_firstName": string;
	"p.shared_lastName": string;
	"p.shared_egousername": string;
	"p.shared_username": string;
	"p.article_date": number;
	p_dc?: string;
	p_edit: YesNoOptions;
	"s.name": string;
	"s.streamId": string;
	cardData: any[]; // FIXME make sub-interfaces for different types of feed items
	postFlare: string;
	sortedPostTags: any;
	authorTags: any;
	"isAdmin"?: boolean;
}

export type ServerFeedItem = Omit<FeedItem, "comments"> & {
	comments: FeedCommentItem[];
}

export interface ResponseObject {
	addr: "getHomeFeed";
	items: ServerFeedItem[];
	msg_id: string;
	page: number;
	retcd: string; // "ok",
	seed: number;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export function fetchFeed(request: RequestObject) {
	return new Promise<ResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: ResponseObject) => {
			if (response.retcd === "ok") {
				resolve(response);
			} else {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from api: ${response.retcd}`);
			}
		};
		const msgName = cu.isSet(request.user_id) ? EventConst.getHomeFeed : EventConst.getNoAuthHomeFeed;
		global.ebMgr.sendMsg("feed", msgName, request, callBack);
	});
}

export interface DeletePostRequestObject {
	user_id: string;
	mode: AppMode;
	itemType: DeleteItemType;
	postUuid: string;
}

export interface DeletePostResponseObject {
	addr: "deleteItem";
	items: any;
	msg_id: string;
	page: number;
	retcd: string;
	seed: number;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export function deletePost(request: DeletePostRequestObject) {
	return new Promise<DeletePostResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: DeletePostResponseObject) => {
			if (response.retcd === "ok") {
				EvtMgr.getInstance(EventConst.onDeletePost).notifyListeners(response.items[0].postUuid);
				AppUtils.showToast({
					type: SnackBarTypeOptions.SUCCESS,
					message: strings("PostView.post_successfully_deleted"),
				});
				resolve(response);
			} else {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from api: ${response.retcd}`);
			}
		};
		global.ebMgr.sendMsg("deleteItem", "deleteItem", request, callBack);
	});
}

export interface RatePostByUserRequestObject {
	user_id: string,
	mode: string,
	uxpcode: string,
	action: ExpertiseActions,
	rating: number,
	postUuid: string,
}

export interface RatePostByUserResponseObject {
	addr: "ratePostByUser",
	msg_id: string,
	procTs: number,
	retcd: string,
	sendTs: number,
	sid: string,
	wrap_id: string,
	items: any,
	boost_submitted: boolean;
}

export function ratePostByUser(request: RatePostByUserRequestObject) {
	return new Promise<RatePostByUserResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: RatePostByUserResponseObject) => {
			if (response.retcd === ResponseCode.OK) {
				resolve(response);
			} else {
				reject(new Error(`Received not ok from api: ${response.retcd}`));
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg(MsgConst.ratePostByUser, MsgConst.ratePostByUser, request, callBack);
	});
}

export interface SaveCommentRequestObject {
	prof_id?: string,
	user_id: string,
	mode: AppMode | null,
	action: string,
	postUuid: string,
	streamId: string,
	commentText: string | null,
	commentUuid: string | null,
	newUserTags: string,
	removedUserTags?: string,
	pCommentUuid: string | null,
	tags: string,
	hidden: YesNoOptions,
	img_bucketRelativeRefPath0?: string | null,
}

export interface SaveCommentResponseObject {
	addr: "saveComment",
	msg_id: string,
	procTs: number,
	retcd: Status,
	sendTs: number,
	sid: string,
	wrap_id: string,
	items: any,
	info?: ErrorInfo
}

export async function uploadPhotosToFirebaseAndSaveComment(msgData: any, callBack: any) {
	try {
		let updatedMsgData = msgData;
		if (msgData?.img_bucketRelativeRefPath0) {
			if (!msgData?.img_bucketRelativeRefPath0.includes(CfgMgr.cfg_imageStorage_path)) {
				const fullFilePathOnDevice = msgData?.img_bucketRelativeRefPath0;
				const bucketRelativeRefPath = `${msgData.user_id}_${msgData.commentUuid}_c${0}.jpg`;
				await FirebaseMgr.saveFile2Firebase(fullFilePathOnDevice, bucketRelativeRefPath);
				updatedMsgData = {
					...msgData,
					img_bucketRelativeRefPath0: bucketRelativeRefPath,
				};
			} else {
				const imgPath = msgData?.img_bucketRelativeRefPath0?.replace(CfgMgr.cfg_imageStorage_path, "")?.replace("?alt=media", "");
				updatedMsgData = {
					...msgData,
					img_bucketRelativeRefPath0: imgPath,
				};
			}
		}
		global.ebMgr.sendMsg(MsgConst.saveComment, MsgConst.saveComment, updatedMsgData, callBack);
	} catch (e) {
		if (dbg) LogMgr.mydbg("while saving comment \"uploadPhotosToFirebaseAndSaveComment\"", e);
	}
}

export function saveComment(request: SaveCommentRequestObject) {
	return new Promise<SaveCommentResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: SaveCommentResponseObject) => {
			if (response.retcd === Status.OK) {
				EvtMgr.getInstance(EventConst.saveComment).notifyListeners({ ...response, deletedPostUuid: request.postUuid });
				resolve(response);
			} else {
				let errorMessage = `${response?.retcd}: ${response?.info}`;
				if (response.retcd === Status.ERROR_COMMENT && response.info === ErrorInfo.POST_DELETED) {
					errorMessage = strings("PostComment.post_not_available");
					EvtMgr.getInstance(EventConst.postDeleted).notifyListeners(request.postUuid);
				}
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: errorMessage,
				});
				reject(new Error(`Received not ok from api: ${response.retcd}`));
			}
		};
		uploadPhotosToFirebaseAndSaveComment(request, callBack);
	}).catch((error) => error);
}

export interface DeleteItemRequestObject {
	prof_id?: string,
	user_id: string,
	mode: AppMode | null,
	postUuid: string | null,
	streamId: string | null,
	commentUuid: string | null,
	itemType: DeleteItemType,
}

export interface DeleteItemResponseObject {
	addr: "deleteItem",
	msg_id: string,
	procTs: number,
	retcd: string,
	sendTs: number,
	sid: string,
	wrap_id: string,
	items: any,
}

export function deleteItem(request: DeleteItemRequestObject) {
	return new Promise<DeleteItemResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: DeleteItemResponseObject) => {
			if (response.retcd === Status.OK) {
				if (response?.items?.[0]?.itemType === ItemType.COMMENT) {
					AppUtils.showToast({
						type: SnackBarTypeOptions.SUCCESS,
						message: strings("PostComment.comment_successfully_deleted"),
						textStyle: "whitespace-pre-line",
					});
				} else if (response?.items?.[0]?.itemType === ItemType.STREAM) {
					EvtMgr.getInstance(MsgConst.deleteItem).notifyListeners(response.items);
				}
				resolve(response);
			} else {
				reject(new Error(`Received not ok from api: ${response.retcd}`));
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg(MsgConst.deleteItem, MsgConst.deleteItem, request, callBack);
	});
}

interface feedFilterTagsRequestObj {
	user_id: string;
	feedType: string;
}

export interface feedFilterTagsResponseObj {
	addr: "getFeedFilterTags";
	items: Array<any>;
	msg_id: string;
	procTs: number;
	retcd: string;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export function getFeedFilterTags(request: feedFilterTagsRequestObj, tagsSetter: any) {
	return new Promise<feedFilterTagsResponseObj>((resolve, reject) => {
		const callBack = (_p: null, response: feedFilterTagsResponseObj) => {
			if (response.retcd === "ok") {
				if (response.items?.length > 0) {
					const sortedTags = orderBy(response.items, ["count"], ["desc"]);
					tagsSetter(sortedTags);
				} else {
					tagsSetter([]);
				}
				resolve(response);
			} else {
				reject(new Error(`Received not ok from api getFeedFilterTags: ${response.retcd}`));
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg(MsgConst.getFeedFilterTags, MsgConst.getFeedFilterTags, request, callBack);
	});
}
