import React, { useEffect, useState } from "react";
import {
	classNames, ExploreItemsSkeleton, NoContentIcon, SvgIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isMobile } from "react-device-detect";
import { useMeasure, useWindowSize } from "react-use";
import { useLocation } from "react-router-dom";
import { cu } from "@credo/utilities";
import {
	EngageTabOptions, EntityType, FetchOperations,
} from "../../../utils/types";
import { EngageActions, ExploreCommunity } from "../../../services/actions/engage";
import { isConnectedAtom, isSessionAuthAtom, userMetaDataAtom } from "../../../utils/atoms";
import ExploreItem from "./ExploreItem";
import { strings } from "../../../i18n/config";
import { ExplainComponent } from "../../../components/ExplainComponent";
import { ClientMetaDataMapping, Consts } from "../../../utils";
import { UserActions } from "../../../services/actions/user";
import { HelmetComponent } from "../../../components/HelmetComponent";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

const ExplorePage: React.FC = () => {
	const [isConnected] = useAtom(isConnectedAtom);
	const [isSessAuth] = useAtom(isSessionAuthAtom);
	const [clientMetadata, setClientMetaData] = useAtom(userMetaDataAtom);
	const location = useLocation();
	const [divRef, { width }] = useMeasure();
	const { width: windowWidth } = useWindowSize();

	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<ExploreCommunity[]>([]);

	useSetAppHeader({
		title: strings("Sidebar.explore"),
	});

	const fetchExploreItems = async (op: FetchOperations) => {
		setLoading(true);
		const onSuccess = (response: ExploreCommunity[]) => {
			setLoading(false);
			setData(response);
		};

		const onError = () => {
			setLoading(false);
		};

		await EngageActions.retrieveExploreItems(
			undefined,
			onSuccess,
			onError,
		);
	};

	const removeEntry = (item: ExploreCommunity) => {
		const updatedData = data.filter((stream) => stream.streamId !== item.streamId);
		setData(updatedData);
	};

	useEffect(() => {
		if (isConnected && isSessAuth) {
			fetchExploreItems(FetchOperations.REPLACE);
		}
	}, [isSessAuth, isConnected]);

	const handleDismissExplainCard = () => {
		UserActions.saveClientMetaDataToServer({
			[ClientMetaDataMapping[EngageTabOptions.EXPLORE]]: false,
		});
		setClientMetaData({
			...clientMetadata,
			[ClientMetaDataMapping[EngageTabOptions.EXPLORE]]: false,
		});
	};

	return (
		<div
			className={classNames(
				"block",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				"text-basic",
				"bg-background",
				"overflow-visible",
			)}
			// @ts-ignore
			ref={divRef}
		>
			<HelmetComponent
				title={strings("EngagePage.explore")}
				url={location?.pathname}
			/>
			<div
				className={classNames(
					"flex flex-col text-white",
					"bg-background w-full",
					// clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.EXPLORE]] ? "pt-5" : "pt-9",
					// eslint-disable-next-line no-nested-ternary
					clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.EXPLORE]]
						? cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-6" : "pt-5"
						: cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-9",
					isMobile && windowWidth < 768 ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-4rem-1px)]",
				)}
			>
				{cu.getGlobalVar(Consts.isBottomNavVisible) && <div className="h-3 bg-background w-full" />}
				<div className="w-full text-basic pb-3">
					{loading
						? (
							<div
								className={classNames(
									"w-full text-basic overflow-hidden",
									// isMobile => 4rem: Header Height, 1px: Header Border, 3rem: Page nav tabs, 4rem: Bottom nav tab
									isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
								)}
							>
								<ExploreItemsSkeleton noOfItems={30} />
							</div>
						) : (
							<>
								{clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.EXPLORE]] && (
									<ExplainComponent
										message={strings("EngagePage.explain_tab.explore")}
										onClickClose={handleDismissExplainCard}
									/>
								)}
								{data && data.length > 0
									? (
										data.map((item, index: number) => (
											<ExploreItem
												id={index}
												key={item.struname}
												item={item}
												entityType={EntityType.STREAM}
												onRemoveItem={removeEntry}
											/>
										))
									) : (
										<div className="mt-12 w-full flex justify-center items-center text-basic h-[calc(100vh-4rem-1px-3rem-3rem)]">
											<div className="flex flex-col justify-center items-center">
												<SvgIcon icon={NoContentIcon} width={96} height={96} className="ml-4" />
												<span className="text-basic pt-4">
													{strings("ExplorePage.no_content_message")}
												</span>
											</div>
										</div>
									)}
							</>
						)}
				</div>
			</div>
		</div>
	);
};

export default ExplorePage;
