import {
	AppMode,
	cu, DataStatus,
	dbg,
	EmojiItem,
	EvtMgr,
	GenericResponse,
	LogMgr,
	Permission,
	ResponseCode, Rule,
	SessMgr,
	UserBalance,
	YesNoOptions,
} from "@credo/utilities";
import moment from "moment";
import {
	browserName,
	browserVersion,
	deviceType,
	getUA,
	isDesktop,
	isIOS,
	isMobile,
	isTablet,
	mobileModel,
	mobileVendor,
	osName,
	osVersion,
} from "react-device-detect";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import { SnackBarTypeOptions } from "@credo/ui-components";
import { GlobalState } from "@credo/store";
import { MsgMgr } from "../../../config/MsgMgr";
import {
	AnalyticEventsConst, AppUtils, ClientMetaData, Consts, EventConst, MsgConst,
} from "../../../utils";
import {
	EntityType,
	ExpertiseActions,
	ExpertiseTypes,
	HistoryItemType,
	ItemType,
	NetworkNotificationItem,
	OtherUserDetails,
	UserNotifSummaryItem,
	UserProfile,
	UserReqSummaryItem,
} from "../../../utils/types";
import CfgMgr from "../../../config/CfgMgr";
import { RouteMgr } from "../../../modules/auth/RouteMgr";
import { REGISTER_CONFIRM, REGISTER_PAGE } from "../../../routes/constants";
import { FeedItem, ratePostByUser, ServerFeedItem } from "../../../modules/home/requests";
import { strings } from "../../../i18n/config";
import { GraphDataRequest, GraphDataResponse } from "../../../components/CredoGraphWrapper/types";
import { GetUserWalletRequest, UserPermissionItem } from "./types";

interface UserNotificationsMessage {
	summary: YesNoOptions,
	lastTs: number,
	targetUserOrProfId: string,
	user_id: string;
	fetchSize: number,
	mode: any,
	streamId: string,
	page: number,
	seed: number,
	lastId: number,
}

export interface FetchUserRequestsItemsRequestObject {
	user_id: string;
	page: number;
	seed: number;
	lastTs?: number;
	lastId?: number;
	fetchSize: number;
	targetUserOrProfId: string;
	streamId: string,
	summary: string | YesNoOptions.NO; // 'y' = summary response, 'n' = all requests
}

export interface FetchUserRequestsItemsResponseObject {
	addr: "get_userRequestsItems";
	items: [];
	msg_id: string;
	retcd: string;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export interface PerformActionOnTaskRequestObject {
	user_id: string;
	mode: string;
	postUuid?: string,
	streamId?: string,
	action: string,
	taskType: string,
	targetPostUuid?: string,
}

export interface PerformActionOnTaskResponseObject {
	addr: "actionOnTask";
	items: [];
	msg_id: string;
	retcd: string;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export interface EmailVerificationResponseObject {
	addr: "preLoginEmailVerification";
	info: string;
	msg_id: string;
	procTs: string;
	replyAddress: string;
	retcd: string;
	sendTs: string;
	sid: string;
	wrap_id: string;
}

export interface fetchUserInfoByProfIdRequestObj {
	user_id?: string;
	mode: string;
	prof_id: string | null;
	username: string | null;
	egousername?: string | null;
	s_history?: string;
	postUuid?: string;
}

export interface handleConnectionRequestObj {
	user_id: string;
	mode: string;
	postUuid: string;
	streamId: string | null;
	action: string;
	taskType: string;
	targetPostUuid?: string;
}

export interface manageRelation4UserRequestObject {
	user_id: string;
	mode: string;
	prof_id: string;
	relType: string;
	action: string;
}
export interface ScheduleUserChange {
	firstName?: string;
	lastName?: string;
	user_id: string;
	profilePic?: string;
}

export interface addCustomEmailRequestObject {
	user_id: string;
	email_custom_rv: string;
}
export interface fetchBlockedUsersRequestObject {
	user_id: string;
	mode: string;
	page: number;
	seed: number;
	lastTs: number;
	lastId: number;
	fetchSize: number;
}
export interface UpdateUserInfo {
	user_id: string;
	mode: AppMode;
	egousername?: string;
	username?: string;
	descr?: string;
	lang?: string;
}

interface getUsersHistoryRequestObj {
	user_id: string;
	historyItemType: string;
	prof_id: string;
	username: string;
}
interface getOtherUsersHistoryRequestObj {
	user_id: string;
	mode: string;
	historyItemType: string;
	prof_id: string;
	username: string;
	egousername?: string;
	otherUser?: YesNoOptions;
}
export interface MngUserXpItemSignUp {
	user_id?: string | null;
	mode: AppMode;
	itemType: string,
	xpType: string,
	action: string,
	xpCodeA: string,
	xpRankA: string,
	xpStreamIdA: string,
	streamId: null,
}

export interface getOtherUserInfoNoAuthRequestObj {
	prof_id: string,
	username: string,
	egousername: string,
}
export interface mngSugconItemRequestObject {
	user_id: string;
	mode: string;
	action: string;
	dest_mode: string;
	dest_prof_id: string;
}

export interface RateUser4XpItemRequestObj {
	user_id?: string | null;
	mode: AppMode;
	itemType: ItemType;
	action: ExpertiseActions;
	xpType: ExpertiseTypes;
	uxpcode?: string;
	rating?: number;
	dest_mode?: string;
	dest_prof_id?: string;
	isCredoNetRating?: YesNoOptions;
}

export interface getUsersRecentPostRequestObj {
	user_id: string;
	mode: string;
	evt_src?: string;
	page: number;
	seed: number;
	lastTs: number;
	lastId: number;
	fetchSize: number;
	targetUserOrProfId: string;
	streamId: string;
	statusFilter?: string | null;
	postUuid?: string | null;
	username: string;
	egousername: string;
	APIVersion?: string;
}

export interface FeedCardsRequestObject {
	user_id?: string | null;
}

export interface FetchUserNotificationsRequest {
	page: number,
	seed: number,
	lastTs?: number | string,
	lastId?: number | string,
	user_id?: string | null,
	fetchSize: number,
	targetUserOrProfId?: string | null,
	streamId: string,
	summary: YesNoOptions,
}

export interface FetchNetworkNotificationsRequestObject {
	user_id: string;
	mode: string;
	lastTs?: number | string,
	lastId?: number | string,
	fetchSize: number;
	summary: string | YesNoOptions.NO; // 'y' = summary response, 'n' = all network notifications
}
export interface UserNotificationResponseItem {
	"id": number,
	"p.postUuid": string,
	"p.postType": string,
	"p.creat_ts": number,
	"p.postText": string,
	"s.name": string,
	"p.targetStreamId": string,
	"p.s_name": string,
	"p.s_struname": string,
	"p.s_profilePicRelUrl": string,
	"p.src_firstName": string,
	"p.src_lastName": string,
	"p.src_egousername": string,
	"p.src_username": string;
	"p.src_mode": AppMode,
	"p.src_prof_id": string;
	"p.src_profilePicRelUrl": string;
	"p.src_postUuid": string;
	"p.src_commentUuid": string;
	"p.src_pCommentUuid": string;
	// Sub comment
	"p.pCommentUuid": string;
	"p.commentUuid": string;
	"p.dest_prof_id": string,
	"p.dest_firstName": string,
	"p.dest_lastName": string,
	"p.dest_egousername": string;
	"p.dest_profilePicRelUrl": string;
	emo_code: string;
	// Entity titles: post or comment title
	src_commentText?: string;
	src_postTitle?: string;
	// read flag if the notification is read or not
	read: boolean;
	/**
	 * AppMode either c or e for displaying the mode
	 * of the notification for current user
	 * */
	streamStyle: AppMode
}

export enum PostType {
	CREATE_COMMENT = "cc",
	COMMENT_ON_POST = "cp",
	NEW_POST = "np",
	JOINED_STREAM = "js",
	CONNECT_USER = "cu",
	FOLLOW_USER = "fu",
	ADDED_AS_STREAM_ADMIN = "nsa",
	MADE_STREAM_ADMIN = "jsa",
	TAG = "tag",
	FOLLOW_STREAM = "fs",
	EMOJI_REACT_COMMENT = "erc",
	EMOJI_REACT_POST = "erp",
}

export interface EmoItemsRequestObject {
	user_id: string | null;
	mode: string;
}

export interface EmoItemsResponseObject {
	addr: "get_emoItems";
	items: EmojiItem[];
	msg_id: string;
	retcd: ResponseCode;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export interface EmoReactionRequestObject {
	prof_id: string | null;
	mode: string;
	postUuid: string;
	action: string;
	code: string;
}

export interface EmoReactionResponseObject {
	addr: "mngEmocons4Post";
	"p.postUuid": string;
	msg_id: string;
	retcd: ResponseCode;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export interface CommentEmoReactionRequestObject {
	prof_id: string | null;
	mode: string;
	commentUuid: string;
	pCommentUuid: string | null;
	action: string;
	code: string;
	APIVersion: string,
}

export interface CommentEmoReactionResponseObject {
	addr: "mngEmocons4Comments";
	"p.postUuid": string;
	msg_id: string;
	retcd: ResponseCode;
	procTs: number;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

type UserRequestsMessage = UserNotificationsMessage;
// eslint-disable-next-line import/prefer-default-export
export class UserActions {
	/**
	 * Retrieve the connection requests and its count from the server.
	 * */
	static async retrieveRequestsFromSvr() {
		if (dbg) LogMgr.mydbg(this, "checking if any requests for this user");
		let userRequestsLastTs = cu.getGlobalVar(Consts.userRequestsLastTs);
		/* If the last timestamp is not defined, I set it 5 minutes before the current timestamp
		 So we can get up to 5 minutes before notifications after the new login */
		userRequestsLastTs = userRequestsLastTs || moment().subtract(5, "m").valueOf();
		if (SessMgr.getFromSession(Consts.userId)) {
			return MsgMgr.makeRemoteRequest_generic<UserRequestsMessage, GenericResponse<UserReqSummaryItem[]>>({
				msgName: `get_${EventConst.userRequestsItems}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userRequestsItems).notifyListeners(response);
				},
				request: {
					summary: YesNoOptions.YES,
					lastTs: userRequestsLastTs,
					targetUserOrProfId: SessMgr.getFromSession(Consts.userId),
					user_id: SessMgr.getFromSession(Consts.user_id),
					fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
					mode: cu.getAppMode(),
					streamId: "None",
					page: 0,
					seed: 0,
					lastId: 0,
				},
			});
		}

		return null;
	}

	/**
	 * Retrieve the notifications and its count from the server.
	 * */
	static async retrieveNotifsFromSvr() {
		if (dbg) LogMgr.mydbg(this, "checking if any Notifs for this user");
		let userNotifsLastTs = cu.getGlobalVar(Consts.userNotifsLastTs);
		/* If the last timestamp is not defined, I set it 5 minutes before the current timestamp
		 So we can get up to 5 minutes before notifications after the new login */
		userNotifsLastTs = userNotifsLastTs || moment().subtract(5, "m").valueOf();
		if (SessMgr.getFromSession(Consts.userId)) {
			return MsgMgr.makeRemoteRequest_generic<UserNotificationsMessage, GenericResponse<UserNotifSummaryItem[]>>({
				msgName: `get_${EventConst.userNotificationsItems}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userNotificationsItems).notifyListeners(response);
				},
				request: {
					summary: YesNoOptions.YES,
					lastTs: userNotifsLastTs,
					targetUserOrProfId: SessMgr.getFromSession(Consts.userId),
					user_id: SessMgr.getFromSession(Consts.user_id),
					fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
					mode: cu.getAppMode(),
					streamId: "None",
					page: 0,
					seed: 0,
					lastId: 0,
				},
			});
		}

		return null;
	}

	static retrieveUserExperience(items: UserProfile) {
		if (SessMgr.getFromSession(Consts.userId)) {
			MsgMgr.makeRemoteRequest_generic<getUsersHistoryRequestObj, GenericResponse<UserProfile[]>>({
				msgName: `get_${MsgConst.userHistoryItemsByProfId}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userHistoryItems).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					historyItemType: HistoryItemType.Qualification_And_Experience,
					prof_id: "",
					username: items?.username,
				},
			});
		}
	}

	static async retrieveUserNotifAndReq() {
		const notifications = await UserActions.retrieveNotifsFromSvr();
		const requests = await UserActions.retrieveRequestsFromSvr();

		const mode = cu.getAppMode();

		const notifReq = {
			notifications: {
				ego: 0,
				credo: 0,
			},
			requests: {
				ego: 0,
				credo: 0,
			},
		};

		if (mode === AppMode.CREDO) {
			if (requests && requests.items?.length > 0) {
				set(notifReq, "requests.credo", requests.items[0].cnt);
			}
			if (notifications && notifications.items?.length > 0) {
				set(notifReq, "notifications.credo", notifications.items[0].cnt);
			}
		} else {
			if (requests && requests.items?.length > 0) {
				set(notifReq, "requests.ego", requests.items[0].cnt);
			}
			if (notifications && notifications.items?.length > 0) {
				set(notifReq, "notifications.ego", notifications.items[0].cnt);
			}
		}

		if (dbg) {
			LogMgr.mydbg("Notifications: Unread notifications response", notifications);
			LogMgr.mydbg("Requests: Unread requests response", requests);
			LogMgr.mydbg("Updated object for requests and notifications", notifReq);
		}

		EvtMgr.getInstance(EventConst.notifReqCount).notifyListeners(notifReq);
	}

	/**
	 * Retrieve the user info from the server if the user
	 * is authenticated successfully. It will also retrieve
	 * notification and connection requests from teh server
	 * for the authenticated user.
	 *
	 * @see retrieveRequestsCountFromSvr
	 * @see retrieveNotifsCountFromSvr
	 * */
	static retrieveInfoFromSvr() {
		/*	NOTE: needs EvtMgr.getInstance('userInfo').addListener to process the response msg
			for example like this:
			EvtMgr.getInstance('userInfo').addListener(CmHandlers.handleUpdate4userInfo);
			=> this adds only once but could be placed in a better place, so we don't do it all the time
		*/
		if (SessMgr.isSessionAuth()) {
			// get user info from server
			MsgMgr.makeRemoteRequest_generic<{ user_id: string }, GenericResponse<UserProfile[]>>({
				msgName: `get_${EventConst.userInfo}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userInfo).notifyListeners(response);
				},
				successCallback: (response: GenericResponse<UserProfile[]>) => {
					if (!isEmpty(response.items)) {
						if (!response.items[0].firstName && !response.items[0].lastName) {
							if (cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess))) {
								localStorage.removeItem(Consts.emailRegInProcess);
							}
							RouteMgr.setDontAllowRouteChange(true);
							RouteMgr.setVerificationSuccessScreen(cu.isSet(response.items[0].email_custom)
								? REGISTER_PAGE : REGISTER_CONFIRM);
						} else {
							UserActions.retrieveUserExperience(response.items[0]);
							/**
							 * Save the profile pictures in session storage so that
							 * we don't need to call userInfo everytime user refreshes
							 * any page.
							 * We need image and name of the current user to display it
							 * in our switch.
							 * */
							SessMgr.setInSessionAndPersistSync({
								eprofilePicRelUrl: response.items[0].eprofilePicRelUrl,
								cprofilePicRelUrl: response.items[0].cprofilePicRelUrl,
								username: response.items[0].username,
								egousername: response.items[0].egousername,
								cprof_id: response.items[0].cprof_id,
								eprof_id: response.items[0].eprof_id,
							});
							const userRecentPostRequestObject = {
								user_id: SessMgr.getFromSession(Consts.user_id),
								mode: cu.getGlobalVar(Consts.mode),
								page: 1,
								seed: 1,
								lastTs: 0,
								lastId: 0,
								fetchSize: 1,
								statusFilter: null,
								postUuid: null,
								targetUserOrProfId: response.items[0].eprof_id || "",
								streamId: Consts.main_wall_ego_stream_id + response.items[0].eprof_id,
								username: "",
								egousername: "",
							};
							UserActions.getUsersRecentPost(
								userRecentPostRequestObject,
								(res) => {
									EvtMgr.getInstance(EventConst.userStreamItems).notifyListeners(res);
								},
							);
						}
					}
					EvtMgr.getInstance(EventConst.showSocialLoading).notifyListeners(false);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not retrieving user info from svr");
	}

	/**
	 * Sends user device's data to server while login and also
	 * to register this device in the session list in the application.
	 * */
	static async sendSaveData() {
		if (SessMgr.isSessionAuth()) {
			try {
				if (dbg) LogMgr.mydbg(this, "sending device info to server");
				const user_id = SessMgr.getFromSession(Consts.user_id);
				const clientLocation = cu.getGlobalVar(Consts.clientLocation);
				const getDeviceType = () => {
					if (isMobile) return "Mobile Browser";
					if (isDesktop) return "Personal Computer";
					if (isTablet) return "Tablet Browser";
					return deviceType;
				};
				const getDeviceModel = () => {
					if (isMobile) return `${mobileVendor} - ${mobileModel}`;
					if (isDesktop) return `${browserName ?? "Browser"} ${browserVersion ?? ""} on ${osName} - ${osVersion}`;
					if (isTablet) return `${mobileVendor} - ${mobileModel}`;
					return deviceType;
				};
				const msgData = {
					action: Consts.saveDeviceToken,
					app_launch_time: moment().unix(),
					app_version: CfgMgr.APP_VERSION,
					user_id,
					user_agent: getUA,
					mode: cu.getGlobalVar(Consts.mode),
					city: clientLocation?.sublocality ?? "UN",
					state: clientLocation?.locality ?? "UN",
					country: clientLocation?.country ?? "UN",
					dvc_type: getDeviceType(),
					dvc_model: getDeviceModel(),
					// TODO: Change to dynamic device token at the time of notification implementation
					dvc_tok: (cu.getGlobalVar(Consts.deviceToken)) ?? "dummy_token",
					system_name: osName,
					system_version: osVersion,
					is_ios: cu.asYesNo(isIOS),
					ip_address: cu.getGlobalVar(Consts.clientIPAddress),
					unique_id: cu.getGlobalVar(Consts.browserId),
				};
				global.ebMgr.sendMsg(EventConst.saveData, EventConst.saveData, msgData, null);
			} catch (error) {
				if (dbg) LogMgr.mydbg("User SaveData error", error);
			}
		}
	}

	/**
	 * Fetches user's pending connect/join stream requests
	 * @params {FetchUserRequestsItemsRequestObject} request: user request object
	 * */
	static fetchUserRequest(request: FetchUserRequestsItemsRequestObject) {
		return new Promise<FetchUserRequestsItemsResponseObject>((resolve, reject) => {
			const callBack = (_p: null, response: FetchUserRequestsItemsResponseObject) => {
				if (response.retcd === ResponseCode.OK) {
					resolve(response);
				} else {
					reject(new Error(`Received not ok from api: ${response.retcd}`));
				}
			};
			// @ts-ignore
			global.ebMgr.sendMsg(`get_${MsgConst.userRequestsItems}`, `get_${MsgConst.userRequestsItems}`, request, callBack);
		});
	}

	static fetchNetworkNotifications(
		request: FetchNetworkNotificationsRequestObject,
		onSuccessCallback: (response: any) => void,
		onErrorCallback: (response: any) => void,
	) {
		return MsgMgr.makeRemoteRequest_generic<FetchNetworkNotificationsRequestObject, GenericResponse<any[]>>({
			msgName: MsgConst.getNetworkNotifications,
			instanceCallback: (response) => {
				if (request && request.summary === YesNoOptions.YES) {
					EvtMgr.getInstance(EventConst.getNetworkNotifications).notifyListeners(response);
				}
			},
			request: {
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
				lastTs: request.lastTs,
				lastId: request.lastId,
				fetchSize: request.fetchSize,
				summary: request.summary,
			},
			successCallback: (response: GenericResponse<NetworkNotificationItem[]>) => {
				const formattedData = response.items.map((item: NetworkNotificationItem) => {
					switch (item["p.postType"]) {
						case PostType.FOLLOW_USER: {
							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.username"];
							const destTitle = item["p.dest_mode"] === AppMode.EGO
								? `${item["p.dest_firstName"]} ${item["p.dest_lastName"]}` : item["p.dest_username"];
							const destUsername = item["p.dest_mode"] === AppMode.EGO
								? item["p.dest_egousername"] : item["p.dest_username"] || item["p.username"];
							return {
								id: item["p.postUuid"],
								src: {
									title: srcTitle,
									username: item["p.src_egousername"] || item["p.username"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: strings("SharedActivities.started_following"),
								dest: {
									title: destTitle,
									username: destUsername,
									id: item["p.dest_prof_id"],
									mode: item["p.dest_mode"],
									entityType: EntityType.USER,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									username: item["p.src_username"] || item["p.src_egousername"],
								},
								read: item.read,
								itemMode: item.streamStyle,
							};
						}
						case PostType.FOLLOW_STREAM: {
							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.username"];
							return {
								id: item["p.postUuid"],
								src: {
									title: srcTitle,
									username: item["p.src_egousername"] || item["p.username"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: strings("SharedActivities.started_following"),
								dest: {
									title: item["p.s_name"],
									username: item["p.s_struname"],
									id: item["p.targetStreamId"],
									entityType: EntityType.STREAM,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									username: item["p.src_username"] || item["p.src_egousername"],
								},
								read: item.read,
								itemMode: item.streamStyle,
							};
						}
						case PostType.JOINED_STREAM: {
							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.username"];
							return {
								id: item["p.postUuid"],
								src: {
									title: srcTitle,
									username: item["p.src_username"] || item["p.src_egousername"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: strings("SharedActivities.just_joined"),
								dest: {
									title: item["p.s_name"],
									username: item["p.s_struname"],
									id: item["p.targetStreamId"],
									entityType: EntityType.STREAM,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									username: item["p.src_username"] || item["p.src_egousername"],
								},
								read: item.read,
								itemMode: item.streamStyle,
							};
						}
						case PostType.CONNECT_USER: {
							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.username"];
							const destTitle = `${item["p.dest_firstName"]} ${item["p.dest_lastName"]}`;
							return {
								id: item["p.postUuid"],
								src: {
									title: srcTitle,
									username: item["p.src_egousername"] || item["p.username"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: strings("SharedActivities.connected_with"),
								dest: {
									title: destTitle,
									username: item["p.dest_egousername"],
									id: item["p.dest_prof_id"],
									mode: item["p.dest_mode"],
									entityType: EntityType.USER,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									username: item["p.src_username"] || item["p.src_egousername"],
								},
								read: item.read,
								itemMode: item.streamStyle,
							};
						}
						default: return {
							createTs: item["p.creat_ts"],
							id: item["p.postUuid"],
							isNotHandled: true,
						};
					}
				});

				if (onSuccessCallback) {
					onSuccessCallback({
						...response,
						items: formattedData,
					});
				}
			},
			errorCallback: (response) => {
				if (onErrorCallback) {
					onErrorCallback(response);
				}
			},
		});
	}

	static retrieveUserNotifications(
		request: FetchUserNotificationsRequest,
		onSuccessCallback: (response: any) => void,
		onErrorCallback: (response: any) => void,
	) {
		MsgMgr.makeRemoteRequest_generic({
			request,
			successCallback: (response: GenericResponse<UserNotificationResponseItem[]>) => {
				const formattedData = response.items.map((item: UserNotificationResponseItem) => {
					switch (item["p.postType"]) {
						case PostType.ADDED_AS_STREAM_ADMIN:
							return {
								id: item.id,
								src: {
									title: item["p.src_mode"] === AppMode.EGO
										? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.src_username"],
									username: item["p.src_egousername"],
									id: item["p.src_prof_id"] || item["p.dest_prof_id"],
									mode: item["p.src_mode"],
								},
								textPrimary: item["p.postText"],
								dest: {
									title: item["p.s_name"],
									username: item["p.s_struname"],
									id: item["p.targetStreamId"],
									entityType: EntityType.STREAM,
								},
								profilePic: item["p.s_profilePicRelUrl"],
								createTs: item["p.creat_ts"],
								isStreamPic: true,
								image: {
									title: item["p.s_name"],
									imageSrcUrl: item["p.s_profilePicRelUrl"],
									isStreamPic: true,
									username: item["p.s_struname"],
									imageEntityType: "dest",
								},
								itemMode: item.streamStyle,
								read: item.read,
							};
						case PostType.TAG:
						case PostType.COMMENT_ON_POST:
						case PostType.EMOJI_REACT_POST:
						case PostType.EMOJI_REACT_COMMENT:
						case PostType.CREATE_COMMENT: {
							const getPrimaryText = () => {
								switch (item["p.postType"]) {
									case PostType.COMMENT_ON_POST:
										return strings("NotificationItem.user_made_a_comment_text");
									case PostType.TAG:
										return strings("NotificationItem.user_mentioned_text");
									default: return item["p.postText"];
								}
							};

							const getDestTitle = () => {
								switch (item["p.postType"]) {
									case PostType.EMOJI_REACT_POST:
										return ` ${strings("NotificationItem.post")}`;
									case PostType.COMMENT_ON_POST:
										return strings("NotificationItem.post");
									case PostType.CREATE_COMMENT:
									case PostType.EMOJI_REACT_COMMENT:
										return strings("NotificationItem.comment");
									case PostType.TAG: {
										if (item["p.postText"].split(" ").includes("comment")) {
											return strings("NotificationItem.comment");
										}
										return strings("NotificationItem.post");
									}
									default: return null;
								}
							};

							const getDestinationSubText = () => {
								const emoji = cu.getGlobalVar(Consts.emoItems)?.find((emo: any) => emo.code === item.emo_code)?.emoji;
								switch (item["p.postType"]) {
									case PostType.EMOJI_REACT_COMMENT:
									case PostType.EMOJI_REACT_POST:
										return ` ${strings("NotificationItem.with")} ${emoji}`;
									default: return null;
								}
							};

							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.src_username"];

							return {
								id: item.id,
								src: {
									title: srcTitle,
									username: item["p.src_username"] || item["p.src_egousername"],
									id: item["p.src_prof_id"] || item["p.dest_prof_id"],
									mode: item["p.src_mode"],
								},
								textPrimary: getPrimaryText(),
								dest: {
									title: getDestTitle(),
									username: item["p.s_struname"],
									id: item["p.src_postUuid"],
									entityType: EntityType.POST,
									// parent comment uuid
									pCommentId: item["p.pCommentUuid"] || item["p.src_pCommentUuid"],
									// subcomment when pCommentId is present else it's parent comment
									commentId: item["p.commentUuid"] || item["p.src_commentUuid"],
									entityText: item?.src_commentText || item?.src_postTitle,
									subText: getDestinationSubText(),
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									isStreamPic: false,
									username: item["p.src_username"] || item["p.src_egousername"],
									imageEntityType: "src",
								},
								itemMode: item.streamStyle,
								read: item.read,
							};
						}
						case PostType.FOLLOW_USER: {
							const srcTitle = item["p.src_mode"] === AppMode.EGO
								? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.src_username"];

							return {
								id: item.id,
								src: {
									title: srcTitle,
									username: item["p.src_username"] || item["p.src_egousername"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: item["p.postText"],
								dest: {
									title: undefined,
									username: undefined,
									id: undefined,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.src_profilePicRelUrl"],
									isStreamPic: false,
									username: item["p.src_username"] || item["p.src_egousername"],
									imageEntityType: "src",
								},
								itemMode: item.streamStyle,
								read: item.read,
							};
						}
						case PostType.JOINED_STREAM: {
							return {
								id: item.id,
								src: {
									title: item["p.src_mode"] === AppMode.EGO
										? `${item["p.src_firstName"]} ${item["p.src_lastName"]}` : item["p.src_username"],
									username: item["p.src_username"] || item["p.src_egousername"],
									id: item["p.src_prof_id"],
									mode: item["p.src_mode"],
								},
								textPrimary: item["p.postText"],
								dest: {
									title: item["p.s_name"],
									username: item["p.s_struname"],
									id: item["p.targetStreamId"],
									entityType: EntityType.STREAM,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: item["p.s_name"],
									imageSrcUrl: item["p.s_profilePicRelUrl"],
									isStreamPic: true,
									username: item["p.s_struname"],
									imageEntityType: "dest",
								},
								itemMode: item.streamStyle,
								read: item.read,
							};
						}
						case PostType.CONNECT_USER: {
							const srcTitle = `${item["p.dest_firstName"]} ${item["p.dest_lastName"]}`;
							return {
								id: item.id,
								src: {
									title: srcTitle,
									username: item["p.dest_egousername"],
									id: item["p.dest_prof_id"],
									mode: item["p.src_mode"],
									entityType: EntityType.USER,
								},
								textPrimary: item["p.postText"],
								dest: {
									title: undefined,
									username: undefined,
									id: undefined,
								},
								createTs: item["p.creat_ts"],
								image: {
									title: srcTitle,
									imageSrcUrl: item["p.dest_profilePicRelUrl"],
									isStreamPic: false,
									username: item["p.dest_egousername"],
								},
								itemMode: item.streamStyle,
								read: item.read,
							};
						}
						default: return {
							createTs: item["p.creat_ts"],
							id: item.id,
							isNotHandled: true,
						};
					}
				});

				if (onSuccessCallback) {
					onSuccessCallback({
						...response,
						items: formattedData,
					});
				}
			},
			msgName: MsgConst.getUserNotificationsItems,
			errorCallback: (response) => {
				if (onErrorCallback) {
					onErrorCallback(response);
				}
			},
		});
	}

	/**
	 * Sends action performed by the user on connect/join stream requests
	 * @params {PerformActionOnTaskRequestObject} request: has action as Accept/reject
	 * */
	static performActionOnTask(request: PerformActionOnTaskRequestObject) {
		return new Promise<PerformActionOnTaskResponseObject>((resolve, reject) => {
			const callBack = (_p: null, response: PerformActionOnTaskResponseObject) => {
				if (response.retcd === ResponseCode.OK) {
					resolve(response);
				} else {
					if (response.retcd === ResponseCode.ERR_MUCU) {
						AppUtils.showToast({
							message: strings("OtherUserProfile.already_connected_error"),
							type: SnackBarTypeOptions.ERROR,
						});
					}
					reject(new Error(`Received not ok from ${MsgConst.actionOnTask} api: ${response.retcd}`));
				}
			};
			// @ts-ignore
			global.ebMgr.sendMsg(MsgConst.actionOnTask, MsgConst.actionOnTask, request, callBack);
		});
	}

	/**
	 * Checks if the email is registered in the system or not
	 *
	 * @param {string} email - Email which needs to be checked if its
	 * present in the system or not.
	 * */
	static isEmailRegistered(email: string) {
		return new Promise<EmailVerificationResponseObject>((resolve, reject) => {
			const callBack = (_p: null, response: EmailVerificationResponseObject) => {
				resolve(response);
			};
			// @ts-ignore
			global.ebMgr.sendMsg(MsgConst.preLoginEmailVerification, MsgConst.preLoginEmailVerification, { email }, callBack);
		});
	}

	/**
	 * Update user details
	 *
	 * @param {UpdateUserInfo} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async updateUserInfo(
		request: UpdateUserInfo,
		successCallback?: () => void,
		errorCallback?: () => void,
	) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<UpdateUserInfo, GenericResponse<UserProfile[]>>({
				msgName: MsgConst.updateUserInfo,
				request: {
					...request,
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					egousername: request.egousername,
					username: request.username,
					descr: request.descr,
				},
				successCallback: (response) => {
					if (dbg) LogMgr.mydbg(this, "got success message from updateUserInfo: ", response);
					if (successCallback) {
						EvtMgr.getInstance(EventConst.updateUserInfo).notifyListeners(response);
						successCallback();
					}
				},
				errorCallback: (response) => {
					if (dbg) LogMgr.mydbg(this, "got error message from updateUserInfo: ", response);
					if (errorCallback) {
						errorCallback();
					}
				},
			});
		}
	}

	/**
	 * Add scheduling the update job of changing the name, username
	 * in the backend
	 *
	 * @param {ScheduleUserChange} user - User which needs to be
	 * updated
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async scheduleChange4UserEgoData(
		user: ScheduleUserChange,
		successCallback?: (response: any) => void,
		errorCallback?: (response: any) => void,
	) {
		let finalUser = {};

		if (user?.firstName) {
			finalUser = {
				...finalUser,
				firstName: user.firstName,
			};
		}

		if (user?.lastName) {
			finalUser = {
				...finalUser,
				lastName: user.lastName,
			};
		}

		if (user?.profilePic) {
			finalUser = {
				profilePic: user.profilePic,
				...finalUser,
			};
		}

		if (SessMgr.isSessionAuth()) {
			await MsgMgr.makeRemoteRequest_generic({
				request: {
					...finalUser,
					user_id: user.user_id,
					mode: cu.getAppMode(),
				},
				msgName: MsgConst.scheduleChangeEgoName,
				successCallback: (response: any) => {
					if (dbg) LogMgr.mydbg("scheduleChange4UserEgoData Success: ", response);
					if (response) {
						if (successCallback) {
							successCallback(response);
						}
					}
				},
				errorCallback: (response: any) => {
					if (dbg) LogMgr.mydbg("scheduleChange4UserEgoData Error: ", response);
					if (errorCallback) {
						errorCallback(response);
					}
				},
			});
		}
	}

	static getOtherUserInfo(
		request: fetchUserInfoByProfIdRequestObj,
		successCallback?: (response: GenericResponse<OtherUserDetails[]>) => void,
	) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<fetchUserInfoByProfIdRequestObj, GenericResponse<OtherUserDetails[]>>({
				msgName: `get_${EventConst.userInfoByProfId}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userInfoByProfId).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					prof_id: request.prof_id,
					username: request.username,
					egousername: request.egousername,
					s_history: request.s_history,
					postUuid: request.postUuid,
				},
				successCallback,
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not retrieving other user info from svr");
	}

	static makeRemoteRequestHandleConnectionRequest(request: handleConnectionRequestObj) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<handleConnectionRequestObj, GenericResponse<OtherUserDetails[]>>({
				msgName: MsgConst.actionOnTask,
				instanceCallback: () => { },
				errorCallback: (response) => {
					if (response.retcd === ResponseCode.ERR_MUCU) {
						AppUtils.showToast({
							message: strings("OtherUserProfile.already_connected_error"),
							type: SnackBarTypeOptions.ERROR,
						});
					}
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					postUuid: request.postUuid,
					streamId: request.streamId,
					action: request.action,
					taskType: request.taskType,
					targetPostUuid: request.targetPostUuid,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not handling connection request");
	}

	static manageRelation4User(request: manageRelation4UserRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<manageRelation4UserRequestObject, GenericResponse<OtherUserDetails[]>>({
				msgName: MsgConst.mngRelation4User,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(MsgConst.mngRelation4User).notifyListeners(response);
				},
				errorCallback: (response: any) => {
					if (response && response?.retcd === ResponseCode.ERR_MUCP) {
						AppUtils.showToast({
							message: strings("HomePage.already_pending_conn_request_error"),
							type: SnackBarTypeOptions.ERROR,
							containerStyle: "w-48",
						});
					}
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					prof_id: request.prof_id,
					relType: request.relType,
					action: request.action,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not performing manageRelation4User");
	}

	static fetchUserHistoryItems(request: getOtherUsersHistoryRequestObj) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<getOtherUsersHistoryRequestObj, GenericResponse<OtherUserDetails[]>>({
				msgName: `get_${MsgConst.userHistoryItemsByProfId}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.userHistoryItemsByProfId).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					prof_id: request.prof_id,
					historyItemType: request.historyItemType,
					username: request.username,
					egousername: request.egousername,
					otherUser: YesNoOptions.YES,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not getting user history items");
	}

	/**
	 * Add user expertise while creating a new user
	 *
	 * @param {MngUserXpItemSignUp} request - The request object
	 * which will consist of all the tags, stream, value.
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async manageUserXpItemSignUp(
		request: MngUserXpItemSignUp,
		successCallback?: () => void,
		errorCallback?: () => void,
	) {
		await MsgMgr.makeRemoteRequest_generic({
			msgName: MsgConst.mngXpItem,
			request,
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from manageUserXpItemSignUp for mngXpItem: ", response);
				if (successCallback) {
					successCallback();
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from manageUserXpItemSignUp for mngXpItem: ", response);
				if (errorCallback) {
					errorCallback();
				}
			},
		});
	}

	/**
	 * Rate expertise of user or post
	 *
	 * @param {RateUser4XpItemRequestObj} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async rateUser4XpItem(
		request: RateUser4XpItemRequestObj,
		successCallback?: () => void,
		errorCallback?: () => void,
	) {
		await MsgMgr.makeRemoteRequest_generic<RateUser4XpItemRequestObj, GenericResponse<OtherUserDetails[]>>({
			msgName: MsgConst.rateUser4XpItem,
			request: {
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
				itemType: request.itemType,
				action: request.action,
				xpType: request.xpType,
				uxpcode: request.uxpcode,
				rating: request.rating,
				dest_mode: request.dest_mode,
				dest_prof_id: request.dest_prof_id,
				isCredoNetRating: request.isCredoNetRating,
			},
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from rateUser4XpItem: ", response);
				if (successCallback) {
					successCallback();
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from rateUser4XpItem: ", response);
				if (errorCallback) {
					errorCallback();
				}
			},
		});
	}

	/**
	 * Get user recent posts in stream
	 *
	 * @param {RateUser4XpItemRequestObj} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async getUsersRecentPost(
		request: getUsersRecentPostRequestObj,
		successCallback?: (response: any) => void,
		errorCallback?: (response: any) => void,
	) {
		return MsgMgr.makeRemoteRequest_generic<getUsersRecentPostRequestObj, GenericResponse<ServerFeedItem[]>>({
			msgName: `get_${MsgConst.userStreamItems}`,
			request: {
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
				evt_src: request.evt_src,
				page: request.page,
				seed: request.seed,
				lastTs: request.lastTs,
				lastId: request.lastId,
				fetchSize: request.fetchSize,
				targetUserOrProfId: request.targetUserOrProfId,
				streamId: request.streamId,
				statusFilter: request.statusFilter,
				postUuid: request.postUuid,
				username: request.username,
				egousername: request.egousername,
				APIVersion: request.APIVersion,
			},
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from userStreamItems: ", response);
				if (successCallback) {
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from userStreamItems: ", response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	/**
	 * Get other user info without authentication
	 *
	 * @param {RateUser4XpItemRequestObj} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async getOtherUserInfoNoAuth(
		request: getOtherUserInfoNoAuthRequestObj,
		successCallback?: (response: any) => void,
		errorCallback?: (response: any) => void,
	) {
		MsgMgr.makeRemoteRequest_generic<any, GenericResponse<OtherUserDetails[]>>({
			msgName: `get_${MsgConst.NoAuthUserInfoByProfId}`,
			msgAddr: `get${MsgConst.NoAuthUserInfoByProfId}`,
			instanceCallback: (response: any) => {
				EvtMgr.getInstance(EventConst.NoAuthUserInfo).notifyListeners(response);
			},
			request: {
				prof_id: request.prof_id,
				username: request.prof_id ? null : request.username,
				egousername: request.prof_id ? null : request.egousername,
			},
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from NoAuthUserInfo: ", response);
				if (successCallback) {
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from NoAuthUserInfo: ", response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	/**
	 * Get other user info without authentication
	 *
	 * @param {RateUser4XpItemRequestObj} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async getNoAuthUserStreamItems(
		request: getUsersRecentPostRequestObj,
		successCallback?: (response: any) => void,
		errorCallback?: (response: any) => void,
	) {
		return MsgMgr.makeRemoteRequest_generic<getUsersRecentPostRequestObj, GenericResponse<ServerFeedItem[]>>({
			msgName: `get_${MsgConst.NoAuthUserStreamItemsProcessor}`,
			msgAddr: `get_${MsgConst.NoAuthUserStreamItems}`,
			instanceCallback: (response: any) => {
				EvtMgr.getInstance(EventConst.NoAuthUserStreamItems).notifyListeners(response);
			},
			request: {
				user_id: "",
				mode: "",
				evt_src: request.evt_src,
				page: request.page,
				seed: request.seed,
				lastTs: request.lastTs,
				lastId: request.lastId,
				fetchSize: request.fetchSize,
				targetUserOrProfId: request.targetUserOrProfId,
				streamId: request.streamId,
				statusFilter: request.statusFilter,
				postUuid: request.postUuid,
				username: request.username,
				egousername: request.egousername,
				APIVersion: request.APIVersion,
			},
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from userStreamItems: ", response);
				if (successCallback) {
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from userStreamItems: ", response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	/**
	 * Get other user history items like qualification experience without authentication
	 *
	 * @param {RateUser4XpItemRequestObj} request - The request object
	 * @param {Function} successCallback - access the successful
	 * response, mostly used for handling process completion state
	 * @param {Function} errorCallback - access the error
	 * response, mostly used for handling process completion state
	 * */
	static async getNoAuthUserHistoryItemsByProfId(
		request: getOtherUsersHistoryRequestObj,
		successCallback?: (response: any) => void,
		errorCallback?: (response: any) => void,
	) {
		MsgMgr.makeRemoteRequest_generic<getOtherUsersHistoryRequestObj, GenericResponse<OtherUserDetails[]>>({
			msgName: `get_${MsgConst.NoAuthUserHistoryItems}`,
			instanceCallback: (response: any) => {
				EvtMgr.getInstance(EventConst.NoAuthUserHistoryItems).notifyListeners(response);
			},
			request: {
				user_id: "",
				mode: "",
				prof_id: request.prof_id,
				historyItemType: request.historyItemType,
				username: request.username,
				egousername: request.egousername,
			},
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from NoAuthUserInfo: ", response);
				if (successCallback) {
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from NoAuthUserInfo: ", response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	static fetchFeedCards(msgName: string) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<FeedCardsRequestObject, GenericResponse<FeedItem[]>>({
				msgName: `get_${msgName}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(msgName).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not getting user xp cards");
	}

	static getEmoItems(dataSetter: any) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<EmoItemsRequestObject, EmoItemsResponseObject>({
				msgName: `get_${MsgConst.emoItems}`,
				instanceCallback: (response: EmoItemsResponseObject) => {
					const data = response.items;
					if (data && data.length > 0) {
						const getVal = (value: string) => value.replace(":", "").replace(":", "");
						const formattedData: EmojiItem[] = [];
						data.forEach((item: EmojiItem) => {
							formattedData.push({
								short_name: getVal(String(item.value)),
								value: 0,
								selected: false,
								code: item.code,
								emoji: item.emoji,
							});
						});
						dataSetter(formattedData);
						cu.setGlobalVar(Consts.emoItems, formattedData);
					}
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: cu.getAppMode(),
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not performing mngSugconItem");
	}

	static mngEmocons4Post(request: EmoReactionRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<EmoReactionRequestObject, EmoReactionResponseObject>({
				msgName: MsgConst.mngEmocons4Post,
				instanceCallback: () => { },
				request,
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not getting mngEmocons4Post");
	}

	static mngEmocons4Comments(request: CommentEmoReactionRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<CommentEmoReactionRequestObject, CommentEmoReactionResponseObject>({
				msgName: MsgConst.mngEmocons4Comments,
				instanceCallback: () => { },
				request,
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not getting mngEmocons4Comments");
	}

	static mngSugconItem(request: mngSugconItemRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<mngSugconItemRequestObject, GenericResponse<any[]>>({
				msgName: MsgConst.mngSugconItem,
				instanceCallback: () => { },
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					dest_mode: request.dest_mode,
					dest_prof_id: request.dest_prof_id,
					action: request.action,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not performing mngSugconItem");
	}

	static onTagRate(
		tag: string,
		rating: any,
		postUuid: string,
		boostEp?: number,
	) {
		const request = {
			user_id: SessMgr.getFromSession(Consts.user_id) ?? "",
			uxpcode: tag,
			rating,
			mode: AppMode.CREDO,
			action: ExpertiseActions.RATE,
			postUuid,
			boostEp,
		};
		ratePostByUser(request);
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.ratePostTag,
		});
	}

	static saveClientMetaDataToServer(updatedMetaData: any) {
		if (SessMgr.isSessionAuth()) {
			const metaData = cu.getGlobalVar(Consts.metaData);
			let clientMetadata = {};
			if (metaData) {
				clientMetadata = {
					...metaData,
					...updatedMetaData,
				};
			} else {
				clientMetadata = {
					...ClientMetaData,
					...updatedMetaData,
				};
			}
			cu.setGlobalVar(Consts.metaData, clientMetadata);
			MsgMgr.makeRemoteRequest_generic<any, GenericResponse<any>>({
				msgName: MsgConst.mngUserClientMetadata,
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					clientMetadata,
				},
			});
		} else if (dbg) LogMgr.mydbg("sess not authenticated so not saving client meta data");
	}

	static getUserClientMetadata() {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<any, GenericResponse<any>>({
				msgName: MsgConst.getUserClientMetadata,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.getUserClientMetadata).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
				},
			});
		} else if (dbg) LogMgr.mydbg("sess not authenticated so not retrieving client meta data");
	}

	static getCredoGraphData(
		request: GraphDataRequest,
		successCallback: (response: GenericResponse<GraphDataResponse[]>) => void,
		errorCallback: any,
	) {
		MsgMgr.makeRemoteRequest_generic<GraphDataRequest, GenericResponse<GraphDataResponse[]>>({
			msgName: "get_userCredoGraph",
			msgAddr: "get_userCredoGraph",
			request,
			successCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got success message from getCredoGraphData: ", response);
				if (successCallback) {
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(this, "got error message from getCredoGraphData: ", response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	static addCustomEmail(request: addCustomEmailRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<any, GenericResponse<any>>({
				msgName: MsgConst.customEmail,
				request,
			});
		} else if (dbg) LogMgr.mydbg("sess not authenticated while adding custom email");
	}

	static getBlockedUsers(
		request: fetchBlockedUsersRequestObject,
		successCallback: any,
		errorCallback: any,
	) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<fetchBlockedUsersRequestObject, GenericResponse<any>>({
				msgName: MsgConst.blockedUsersItems,
				request,
				successCallback: (response) => {
					if (successCallback) {
						successCallback(response);
					}
				},
				errorCallback: (response) => {
					if (errorCallback) {
						errorCallback(response);
					}
				},
			});
		} else if (dbg) LogMgr.mydbg("sess not authenticated so not retrieving client meta data");
	}

	static getWalletBalance() {
		if (SessMgr.isSessionAuth()) {
			const request = {
				user_id: SessMgr.getFromSession(Consts.user_id),
			};
			MsgMgr.makeRemoteRequest_generic<GetUserWalletRequest, GenericResponse<UserBalance[]>>({
				msgName: MsgConst.getUserWalletBalance,
				request,
				successCallback: (response) => {
					if (response.items.length > 0) {
						if (dbg) {
							LogMgr.mydbg("UserActions.getWalletBalance got message from server for getUserWalletSummary", response);
						}

						const walletBalance: number = response.items[0].balance ?? 0;
						// TODO: Remove this once the GlobalState is fixed
						// Setting state by global state is setting the data in store
						// but its getting reset to 0 right after that. Investigate
						// why it is happening.
						EvtMgr
							.getInstance(EventConst.setUserWalletBalance)
							.notifyListeners(walletBalance);
						// GlobalState.User.walletBalance.set(walletBalance);
					} else if (dbg) {
						LogMgr.mydbg(
							"UserActions.getWalletBalance got message from server for getUserWalletSummary with empty items array",
						);
					}
				},
				errorCallback: (response) => {
					if (dbg) {
						LogMgr.mydbg(
							`UserActions.getWalletBalance got error from server for getUserWalletSummary: ${response.retcd}`,
							response,
						);
					}
				},
			});
		} else if (dbg) LogMgr.mydbg("sess not authenticated so not user wallet balance");
	}

	/**
	 * TODO:
	 * 1. format data and get all of them in one array or object so that we can just access them directly by the permission
	 * */
	static getUserPermissions() {
		const msgName = MsgConst.getUserPermissions;
		if (SessMgr.isSessionAuth()) {
			const request = {
				user_id: SessMgr.getFromSession(Consts.user_id),
			};
			const convertPermissionDict = (arrayItem: UserPermissionItem): Permission[] => arrayItem.permissions
				.map((item) => {
					let finalPermission: any;
					try {
						finalPermission = new Permission(
							item.id,
							item.rule as Rule,
							item.actions,
							item.onObjects,
							item.withConditions,
						);
					} catch (error) {
						if (dbg) {
							LogMgr.mydbg(
								`UserActions.getUserPermissions: Error while converting the permission ${item}`,
								error,
							);
						}
						finalPermission = null;
					}
					return finalPermission;
				})
				.filter((item) => item !== null);
			MsgMgr.makeRemoteRequest_generic<GetUserWalletRequest, GenericResponse<UserPermissionItem[]>>({
				msgName,
				request,
				successCallback: (response) => {
					if (response.items.length > 0) {
						if (dbg) {
							LogMgr.mydbg(`UserActions.getUserPermissions got message from server for ${msgName}`, response);
						}
						const formattedData: {
							dataStatus: DataStatus,
							[AppMode.EGO]: Permission[],
							[AppMode.CREDO]: Permission[],
						} = {
							[AppMode.EGO]: response.items
								.filter((arrayItem) => arrayItem.mode === AppMode.EGO)
								.map(convertPermissionDict)
								.flat(),
							[AppMode.CREDO]: response.items
								.filter((arrayItem) => arrayItem.mode === AppMode.CREDO)
								.map(convertPermissionDict)
								.flat(),
							dataStatus: DataStatus.LOADED,
						};
						GlobalState.User.permissions.set(formattedData);
					}
				},
				errorCallback: (response) => {
					if (dbg) {
						LogMgr.mydbg(
							`UserActions.getUserPermissions got error from server for ${msgName}: ${response.retcd}`,
							response,
						);
					}
				},
			});
		} else if (dbg) LogMgr.mydbg(`sess not authenticated to ${msgName}`);
	}
}
