// eslint-disable-next-line import/prefer-default-export

import {
	AppMode, BoostBoardItem, DataStatus, PostTag, PostType, YesNoOptions,
} from "@credo/utilities";
import { TXNStatus } from "../services/actions/boost/types";

export enum ThemeOptions {
  CREDO = "credo",
  EGO = "ego"
}

export interface OtherUserDetails {
  hasAccess: string | null;
  ret_prof_id: string | null;
  prof_id: string | null;
  info_mode: any;
  blockedUser: any;
  user_id: string;
  firstName: string;
  lastName: string;
  email_custom: string;
  username: string;
  egousername: string;
  eprofilePicRelUrl: string;
  ecoverPicRelUrl: string;
  edescr: string;
  cdescr: string,
  cprofilePicRelUrl: string,
  ccoverPicRelUrl: string,
  isbot: YesNoOptions;
  isSuspended?: YesNoOptions;
  suspension_reason?: string;
  suspension_end_date?: string;
  time_left?: string;
  eprof_id: string;
  cprof_id: string;
  email_custom_rv: string;
  id_verif_status: string;
  timezone: string;
  phone: string;
  email_google: string;
  email_google_ts: string;
  email_facebook: string;
  email_facebook_ts: string;
  email_linkedin: string;
  email_linkedin_ts: string;
  email_twitter: string;
  email_twitter_ts: string;
  email_apple: string;
  email_apple_ts: string;
  is_apn_newfol: string;
  is_apn_newconreq: string;
  is_apn_conapp: string;
  is_apn_engpts: string;
  is_apn_bdgerd: string;
  is_apn_compos: string;
  is_apn_comrep: string;
  is_apn_menpos: string;
  is_apn_mencom: string;
  is_apn_comnewpos: string;
  is_apn_comnewfol: string;
  is_apn_comnewfolreq: string;
  is_apn_comnewjoireq: string;
  is_apn_comjoireqapp: string;
  is_apn_newmsg: string;
  ps_e_provis: string;
  ps_c_provis: string;
  ps_e_possee: string;
  ps_c_possee: string;
  ps_e_compos: string;
  ps_c_compos: string;
  ps_e_dirmsg: string;
  ps_c_dirmsg: string;
  ps_e_folyou: string;
  ps_c_folyou: string;
  ps_e_folsee: string;
  ps_c_folsee: string;
  ps_e_conyou: string;
  ps_c_conyou: string;
  ps_e_consee: string;
  ps_c_consee: string;
  ps_e_comsee: string;
  ps_c_comsee: string;
  ps_c_evtfoluse: string;
  ps_e_evtfoluse: string;
  ps_c_evtfolcom: string;
  ps_e_evtfolcom: string;
  ps_c_evtusecon: string;
  ps_e_evtusecon: string;
  ps_c_evtjoicom: string;
  ps_e_evtjoicom: string;
  q_rating_steps: any;
  uxptags: Array<any>;
  uxp_summary?: string;
  sortedUxptags: Array<PostTag>;
  modif_uname_ts: number;
  nb_of_uname_change: number;
  uCredibilityDefault: number;
  "descr": string;
  "profilePicRelUrl": string;
  "coverPicRelUrl": string;
  "relTypes": Array<any>;
  "cuReqPosts": Array<any>;
  "cuReqPostsFromProfId": Array<any>;
  "ps_folyou": string;
  "ps_conyou": string;
  "ps_dirmsg": string;
  "ps_possee": string;
  "ps_compos": string;
  "ps_folsee": string;
  "ps_consee": string;
  "ps_comsee": string;
  "all_user_rank": number;
  "totalUsrCnt": number;
  "delta_all_user_rank": number;
  "con_user_rank": number;
  "totalConnUsrCnt": number;
  "lst_seven_day_dep": number;
  "delta_lst_seven_day_dep": number;
  "t.id_verif_status": any;
}

export interface ExperienceCompany {
  name: string,
  code: string,
}

export interface ExperienceIndustry {
  name: string,
  code: string,
}

export interface UserExperienceInterface {
  postUuid: string;
  company: ExperienceCompany[];
  industry: ExperienceIndustry[]
  exp_position: UserExperiencePosition[]
}
export interface UserExperiencePosition {
  endDate: string;
  isCrtPos: YesNoOptions;
  isPosValidated: YesNoOptions;
  locCode: string | null;
  locDescr: string;
  posDescr: string;
  posRole: string;
  posTitle: string;
  startDate: string;
}
export interface UserHistoryItem {
  "comp.creat_ts": number;
  "comp.idx": string;
  "comp.industryCode": string;
  "comp.modif_ts": number;
  "comp.postType": string;
  "comp.postUuid": string;
  companyCode: string;
  companyName: string;
  entity: string;
  id: number;
  industryName: string;
  posA: Array<any>;
}

export interface UserQualificationItem {
  degA: Array<any>;
  entity: string;
  "school.creat_ts": string;
  "school.idx": string;
  "school.modif_ts": number;
  "school.postType": string;
  "school.postUuid": string;
  "school.schoolCode": string;
  "schoolName": string;
  "schoolTypeCode": string;
  "schoolTypeName": string;
  "sid": number;
}

export interface Language {
  index: number;
  label: string;
  value: string;
}

export interface UserProfile {
  eprof_id: string;
  cprof_id: string;
  user_id: string;
  cprofilePicRelUrl: string;
  eprofilePicRelUrl: string;
  ccoverPicRelUrl: string;
  ecoverPicRelUrl: string;
  username: string;
  email_custom: string;
  email_custom_rv: string;
  id_verif_status: string;
  timezone: string;
  firstName: string;
  lastName: string;
  phone: string;
  email_google: string;
  email_google_ts: string;
  email_facebook: string;
  email_facebook_ts: string;
  email_linkedin: string;
  email_linkedin_ts: string;
  email_twitter: string;
  email_twitter_ts: string;
  email_apple: string;
  email_apple_ts: string;
  is_apn_newfol: string;
  is_apn_newconreq: string;
  is_apn_conapp: string;
  is_apn_engpts: string;
  is_apn_bdgerd: string;
  is_apn_compos: string;
  is_apn_comrep: string;
  is_apn_menpos: string;
  is_apn_mencom: string;
  is_apn_comnewpos: string;
  is_apn_comnewfol: string;
  is_apn_comnewfolreq: string;
  is_apn_comnewjoireq: string;
  is_apn_comjoireqapp: string;
  is_apn_newmsg: string;
  ps_e_provis: string;
  ps_c_provis: string;
  ps_e_possee: string;
  ps_c_possee: string;
  ps_e_compos: string;
  ps_c_compos: string;
  ps_e_dirmsg: string;
  ps_c_dirmsg: string;
  ps_e_folyou: string;
  ps_c_folyou: string;
  ps_e_folsee: string;
  ps_c_folsee: string;
  ps_e_conyou: string;
  ps_c_conyou: string;
  ps_e_consee: string;
  ps_c_consee: string;
  ps_e_comsee: string;
  ps_c_comsee: string;
  ps_c_evtfoluse: string;
  ps_e_evtfoluse: string;
  ps_c_evtfolcom: string;
  ps_e_evtfolcom: string;
  ps_c_evtusecon: string;
  ps_e_evtusecon: string;
  ps_c_evtjoicom: string;
  ps_e_evtjoicom: string;
  edescr: string;
  cdescr: string;
  egousername: string;
  q_rating_steps: any;
  uxptags: Array<any>;
  uxp_summary?: string;
  sortedUxptags: Array<PostTag>;
  modif_uname_ts: number;
  nb_of_uname_change: number;
  uCredibilityDefault: number;
  lang?: string;
  profilePictureChangeCount?: number | 0;
}

export interface UserReqSummaryItem {
  cnt: number;
  user_id: string;
}

export interface UserNotifSummaryItem {
  cnt: number;
  user_id: string;
}

export interface LoggedInMenu {
  id: number;
  name: string;
  href?: string;
  items?: LoggedInMenu[];
  isExternal?: boolean;
  badgeCount?: number;
  active?: boolean;
  default?: boolean;
  icon?: any;
  // TODO: Add user session interface here
  userData?: any;
  testId: string;
  hideDesktop?: boolean;
  isDevOption?: boolean;
  onclick?: () => void;
  isPublic?: boolean;
}

export interface CookiesInfo {
  isCookiesAllowed: boolean;
  isPerfCookiesAllowed: boolean;
}

export enum PushNotiFicationType {
  ConnectionRequest = "CONN_USER_NOTIF",
  FollowUser = "FOLLOW_USER_NOTIF",
  JoinStream = "JOIN_STREAM_NOTIF",
  FollowStream = "FOLLOW_STREAM_NOTIF",
  AdminStream = "ADMIN_STREAM_NOTIF",
  Comment = "COMMENT_NOTIF",
  UserTag = "USER_TAGGED_NOTIF",
  Reward = "REWARD_NOTIF",
  PostNotif = "POST_NOTIF",
  SuggestedContacts = "SUGGESTED_USER_NOTIF",
}

export interface StreamInfo {
  user_id: string;
  mode: string;
  name?: string,
  descr?: string,
  style?: string,
  struname?: string,
  type?: string,
  hier?: string,
  categ?: string,
  profilePicRelUrl?: string,
  coverPicRelUrl?: string,
  streamId?: string,
  disc?: string,
  joinModes?: string,
  ac_folcom?: string,
  ac_joicom?: string,
  ac_joiaut?: string,
  ac_alwpos?: string,
  ps_folsee?: string,
  ps_memsee?: string,
  src_prop_val?: string,
  relTypes?: Array<any>,
  jsReqPosts?: Array<any>,
  joinCnt?: number,
  folCnt?: number,
  uxptags?: Array<any>,
  src_prof_id?: string,
  streamAdmin?: Array<any>;
  changeProfilePictureCount?: number | 0,
  changeCoverPictureCount?: number | 0,
}

export interface StreamItem {
  comments: (string | null)[][];
  emoCode: (string | null)[][];
  emoCountA: (number | null)[][];
  id: number;
  nb_of_emo: number;
  nb_ratings: number;
  "p.creat_ts": number;
  "p.dest_firstName": string;
  "p.dest_lastName": string;
  "p.dest_mode": AppMode,
  "p.dest_prof_id": string,
  "p.dest_profilePicRelUrl": string,
  "p.modif_ts": number,
  "p.postText": string,
  "p.postType": PostType,
  "p.postUuid": string,
  "p.src_firstName": string,
  "p.src_lastName": string,
  "p.src_mode": AppMode,
  "p.src_prof_id": string,
  "p.src_profilePicRelUrl": string,
  "p_edit": string,
  "s.name": string,
  "name": string,
  "streamId": string,
  "s.streamId": string,
  "creat_ts": number,
}

export enum ProfileSize {
  X_SMALL = "XS",
  X_SMALL_SEMI = "XSS",
  SMALL = "S",
  SMALL_MEDIUM = "SM",
  MEDIUM = "M",
  LARGE = "L"
}

export enum JoinModes {
  EGO_OR_CREDO = "ec",
  EGO = "e",
  CREDO = "c"
}
export interface StreamProfileJoinReqCount {
  [key: string]: number;
}

export enum SocialLoginType {
  TWITTER = "twitter",
  GOOGLE = "google",
  FACEBOOK = "facebook",
  LINKEDIN = "linkedin",
  APPLE = "apple",
  CUSTOM_EMAIL = "custom"
}

export enum Actions {
  CANCEL = "cancel",
  REMOVE = "r",
  ACCEPT = "accept",
  DELETE = "d",
  CREATE = "c",
}

export enum TaskType {
  CONNECT_REQUEST = "connect_request",
  JOIN_REQUEST = "join_request",
}

export enum RelationType {
  FOLLOWS = "FOLLOWS",
  JOIN_STREAM = "JOIN_STREAM",
  CONNECT_USER = "CONNECT_USER",
}

export enum RelationTypeStatus {
  FOLLOW = "fo",
  JOIN_STREAM = "jo",
  CONNECT_USER = "co",
  BLOCKED = "bl",
}

export interface ReportListItem {
  label: string;
  value: string;
  mode?: AppMode;
}
export enum AuthModalContentType {
  LOGIN = "login",
  SIGN_UP = "signup",
  /**
   * Code sent on the phone number should be entered
   * and handle on this container.
   * */
  PHONE_VERIFICATION_CODE = "phoneVerificationScreen",
  /**
   * Phone number not yet registered content. Which
   * will consists of 'Register' button and 'Login with
   * another account' button
   * */
  PHONE_NOT_YET_REG = "phoneNotYetRegistered",
  /**
   * Phone number is already registered and linked
   * with other social login. User has an option to
   * create a new account or login with same account by
   * verifying his/her identity.
   * */
  PHONE_ALREADY_REG = "phoneAlreadyRegistered",
  /**
   * If set this screen will contain only social login
   * options. Google, Twitter, custom email, Facebook,
   * apple and LinkedIn.
   * */
  LOGIN_ALTERNATIVE = "loginAlternative",
  EMAIL_NOT_YET_REG = "emailNotRegistered",
  /**
   * Loading
   * */
  LOADING = "loading",
}

export interface SocialEmailCred {
  email: string;
  firebaseUUID: string;
}

export enum HistoryItemType {
  Qualification_And_Experience = "qe", // To get the user's qualification and experience details from server
}
export interface TagCategory {
  aliases: string[];
  description: string;
  stream: string;
  tags: string[];
}

export interface Tag extends TagCategory {
  tag: string;
  index?: any;
  default?: boolean;
  type?: string;
}

export enum ItemType {
  USER = "u",
  STREAM = "s",
  PROBLEM = "prb",
  COMMENT = "c"
}

export enum ExpertiseActions {
  RATE = "r",
  DISMISS = "x",
}

export enum ExpertiseTypes {
  UXP_EXPT = "uxp_expt",
  UXP_SKILL = "uxp_skill",
}

export enum StreamPrivacyOptions {
  NO_ONE = "no",
  MEMBERS_ONLY = "mo",
  EVERYONE = "ee",
}

export enum EntityType {
  USER = "u",
  STREAM = "s",
  POST = "p",
}

export interface NotificationItem {
  src: {
    title: string;
    id: string;
    username: string;
    mode: AppMode;
    entityType: EntityType;
  }
  dest: {
    title: string | undefined;
    id: string | undefined;
    username: string | undefined;
    entityType: EntityType;
    commentId?: string;
    pCommentId?: string;
    subText?: string;
    /**
     * Title of the actual Comment/post
     * */
    entityText?: string;
  }
  textPrimary: string;
  profilePic: string;
  id: string;
  createTs: string;
  image: {
    title: string;
    imageSrcUrl: string;
    isStreamPic: boolean;
    username: string;
    imageEntityType: "src" | "dest",
  }
  isStreamPic: boolean;
  /**
   * If this notification item is read or not
   * */
  read: boolean;
  /**
   * The mode in which user got the notification
   * Either c or e. comes from streamStyle.
   * */
  itemMode: AppMode;
  /**
   * If the item is not handled by our parser, we don't
   * need to show it in the UI, but need the item
   * still to get creatTs and send it to server for next
   * data.
   * */
  isNotHandled?: boolean;
}

export enum ParentNavTypes {
  HOME = "home",
  SETTINGS = "settings",
  PROFILE = "profile",
  ENGAGE = "engage",
  INBOX = "inbox"
}

export interface ConfirmationModalProps {
  isVisible: boolean;
  onClose: () => void;
  cancel_button_function: () => void;
  ok_button_title: string;
  cancel_button_title?: string;
  ok_button_function: () => void;
  title: string;
  message: string;
  tx: any;
}

export enum ReportItemType {
  Comment = "c",
  Post = "p",
  User = "u",
  Stream = "s",
}

export interface NotiReqCount {
  notifications: {
    ego: number;
    credo: number;
  },
  requests: {
    ego: number;
    credo: number;
  }
  network: number;
}

export interface RequestItem {
  id: number;
  "p.creat_ts": number;
  "p.firstName"?: string;
  "p.lastName"?: string;
  "p.mode": string;
  "p.postText": string;
  "p.postUuid": string;
  "p.prof_id": string;
  "p.profilePicRelUrl": string;
  "p.reqType": string;
  "p.s_name"?: string;
  "p.status": string;
  "p.targetStreamId"?: string;
}

export interface NetworkNotificationItem {
  "p.postUuid": string;
  "p.postType": string;
  "p.mode": string;
  "p.creat_ts": number;
  "p.modif_ts": number;
  "p.postText": string;
  "p.postTitle": string;
  "p.src_mode": string;
  "p.username": string;
  "p.s_struname": string;
  "p.s_name": string;
  "p.src_prof_id": string;
  "p.src_username": string;
  "p.src_firstName": string;
  "p.src_lastName": string;
  "p.src_egousername": string;
  "p.src_profilePicRelUrl": string;
  "p.dest_prof_id": string;
  "p.dest_username": string;
  "p.dest_firstName": string;
  "p.dest_lastName": string;
  "p.dest_egousername": string;
  "p.dest_profilePicRelUrl": string;
  "p.dest_mode": string;
  "p.targetStreamId": string;
  // read flag if the notification is read or not
  read: boolean;
  /**
   * AppMode either c or e for displaying the mode
   * of the notification for current user
   * */
  streamStyle: AppMode;
}

export enum FetchOperations {
  APPEND = "append",
  REPLACE = "replace",
}

export enum RecommendationTypes {
  COMMUNITIES = "communities",
  TAGS = "uxptags",
}

export enum StreamPostPermission {
  ADMIN_ONLY = "ao",
  ALL_MEMBERS = "am",
}

export enum RecommendationRank {
  /**
   * Recommended by followers/connections
   * */
  CONNECTION = "byconn",
  /**
   * Recommended by tags
   * */
  TAG = "bytag",
  /**
   * Recommended by tags as well as
   * by followers/connections
   * */
  BOTH = "byconnbytag"
}

export interface PendingCommentsItem {
  "p.postUuid": string;
  comments: any[][];
  commentObj: any[];
}

export interface CommentBeenRepliedObject {
  postUuid: string;
  commentUuid: string;
  focusedCommentUuid?: string;
  isFocused: boolean;
}

export enum ShareItemType {
  Post = "post",
  Comment = "comment",
  stream = "stream",
  Profile = "profile"
}

export enum FeedType {
  FEED = "feed",
  NEWS = "news",
  BLASTS = "blasts",
  TOP = "top",
}

export enum HomeFeedType {
  MIXED_FEED = "mixedFeedV1"
}

export enum NewPostContentOptions {
  /**
   * New post view with post title and description along with
   * stream picker button and add images button
   * */
  POST = "post",
  /**
   * Listview of streams user has joined and can select from
   * to post
   * */
  STREAM_PICKER = "stream-picker",
  /**
   * List of tags from where user can add tags to the post
   * created.
   * */
  TAG_PICKER = "tag-picker",
}

export interface AnalyticsEvent {
  name: string;
  params?: any;
}

export enum EngageTabOptions {
  EXPLORE = "engage_explore",
  COMMUNITIES = "engage_communities",
  DASHBOARD = "engage_dashboard",
  BOTS = "engage_bots",
  PEOPLE = "engage_people",
}

export enum OperationType {
  APPEND = "append",
  REPLACE = "replace",
}
export interface ExploreItem {
	name: string;
	descr: string;
	struname: string;
	streamId: string;
	profilePicRelUrl: string;
	ac_alwpos: string;
	ac_folcom: string;
	ac_joiaut: string;
	ac_joicom: string;
	rank: number,
	nb_posts: number,
	rank_type: string;
	isFollowed?: boolean;
	joinModes: string;
}

export interface WithInitialValue<Value> {
  init: Value;
}

export enum ProposeTagActions {
	CREATE = "c",
	PROPOSE = "p",
}

export enum TagActions {
  REPLACE = "replace",
  APPEND = "append"
}

export interface BoostBoardInfo {
  boardAccessCost: number,
  boardVersion: number,
  boardTs: number,
  boardSnapIntervalSecs: number,
  currentTs: number,
  txStatus: TXNStatus;
}

export interface BoostBoardMapAtom {
  data: Map<string, BoostBoardItem>
  dataIds: string[],
  renderIds: string[],
  isLoading: DataStatus,
  boardInfo: BoostBoardInfo,
}

export enum FetchAction {
  LOAD = "load",
  REFRESH = "refresh"
}

export interface AppHeader {
  title: string;
  showBackButton?: boolean;
  onPressBack?: () => void;
}

export enum FilterTagStatus {
	Included = "included",
	Excluded = "excluded",
	Undefined = "undefined",
}
export interface FilterTag {
	tag: string;
	count: number;
	status: FilterTagStatus;
}
export interface FilterData {
	sortBy: string;
	hasInteracted: boolean;
	filterByCred?: string,
	filterTags: Array<string>;
}

export enum FilterTagsFeed {
	ForYou = "for_you",
	News = "news",
	Blast = "blast",
	Top = "top"
}
