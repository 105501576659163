import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
	RadioButtonEmptyIcon, RadioButtonSelectedIcon, classNames, SvgIcon, BackIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { cu, DiscoveryOptions, EvtMgr } from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { strings } from "../../i18n/config";
import { EventConst } from "../../utils";
import { ReportListItem, StreamInfo } from "../../utils/types";
import { userSessionAtom } from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";

export interface StreamDiscoverySettingsScreenProps {
	onDone: () => void;
	streamInfo: any;
}

const StreamDiscoveryOptions = [
	{
		label: strings("StreamDiscoverySettingsScreen.public"),
		description: strings("StreamDiscoverySettingsScreen.all_users_of_the_platform"),
		value: DiscoveryOptions.public,
	},
	{
		label: strings("StreamDiscoverySettingsScreen.connections_of_connections"),
		description: strings("StreamDiscoverySettingsScreen.connections_of_any_connections"),
		value: DiscoveryOptions.connections_of_connections,
	},
	{
		label: strings("StreamDiscoverySettingsScreen.participant_connections"),
		description: strings("StreamDiscoverySettingsScreen.connections_of_the_group_participants"),
		value: DiscoveryOptions.participant_connections,
	},
	{
		label: strings("StreamDiscoverySettingsScreen.owner_connections"),
		description: strings("StreamDiscoverySettingsScreen.visible_to_direct_connections"),
		value: DiscoveryOptions.owener_connections,
	},
	{
		label: strings("StreamDiscoverySettingsScreen.private"),
		description: strings("StreamDiscoverySettingsScreen.only_visible_to_you"),
		value: DiscoveryOptions.private,
	},
];
const StreamDiscoverySettingsScreen = (props: StreamDiscoverySettingsScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [userSession] = useAtom(userSessionAtom);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(streamInfo);

	useEffect(() => {
		setCommunityDetails(streamInfo);
	}, [streamInfo]);

	const onDiscoveryChange = (value: any) => {
		const updateStream = {
			user_id: userSession?.user_id || "",
			mode: cu.getAppMode(),
			name: streamInfo?.name,
			descr: streamInfo.descr,
			struname: streamInfo.struname,
			style: streamInfo?.style,
			type: streamInfo?.type,
			hier: streamInfo?.hier,
			categ: streamInfo?.categ,
			disc: value,
			streamId: streamInfo?.streamId,
		};

		StreamActions.updateStreamInfo(
			updateStream,
			(response) => {
				EvtMgr.getInstance(EventConst.updateStreamInfo).notifyListeners({
					...response,
					items: [updateStream],
				});
				setCommunityDetails({
					...communityDetails,
					...updateStream,
				});
			},
			() => { },
		);
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const renderRadioGroupItem = (item: any) => (
		<RadioGroup.Option key={item.value} value={item.value}>
			{({ checked }) => (
				<div className="px-2">
					<div
						className="flex flex-row py-2.5 items-center hover:cursor-pointer"
					>
						<div className="w-10 h-6">
							{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
						</div>
						<div className="w-full">
							<p className="text-sm font-medium text-title-color">{item.label}</p>
							<p className="text-sm w-full text-start text-gray-dark">
								{item?.description}
							</p>
						</div>
					</div>
					<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
				</div>
			)}
		</RadioGroup.Option>
	);

	return (
		<div className="w-full flex flex-col pb-3 px-3 h-[calc(100vh-100px)]">
			<RadioGroup value={communityDetails?.disc} onChange={onDiscoveryChange}>
				{StreamDiscoveryOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
		</div>
	);
};

export default StreamDiscoverySettingsScreen;
