/* eslint-disable no-nested-ternary */
import { EvtMgr } from "@credo/utilities";
import isNil from "lodash/isNil";
import CfgMgr from "../../config/CfgMgr";
import { POST_DETAIL_PAGE } from "../../routes/constants";
import { AnalyticEventsConst, AppUtils, EventConst } from "../../utils";
import { ShareItemType } from "../../utils/types";
import { FeedItem } from "../home/requests";

const P_FIELDS = [
	"postTitle",
	"postText",
	"mediaObj_Link_Title",
	"mediaObj_Link_Text",
	"mediaObj_Link_Url",
	"mediaObj_Link_ImgUrl",
	"mediaObj_hostname",
	"mediaObj_channel_name",
	"shared_post_streamId",
	"tags",
	"postUuid",
	"created_by",
	"mode",
	"shared_postUuid",
	"shared_creat_ts",
	"shared_postTitle",
	"shared_postText",
	"shared_mode",
	"shared_prof_id",
	"shared_username",
	"shared_firstName",
	"shared_lastName",
	"shared_profilePicRelUrl",
	"src_s_name",
	"shared_created_by",
	"is_shared",
	"shared_post_streamId",
	"sharedContentRating",
	"nb_of_co",
	"shared_cnt",
];

// eslint-disable-next-line import/prefer-default-export
export function postToFeed(newPost: any, existingFeedItem: any, userProfile: any, isCredo: boolean): Partial<FeedItem> {
	const commonFields = P_FIELDS.reduce((feedItem, field) => {
		const pField = `p.${field}`;
		const fieldValue = isNil(newPost[field]) ? existingFeedItem[pField] : newPost[field];
		return { ...feedItem, [pField]: isNil(fieldValue) ? null : fieldValue };
	}, {});

	const currentTs = new Date().getTime();
	const tsFields = {
		"p.edit_ts": currentTs,
		"p.modif_ts": currentTs,
		"p.creat_ts": existingFeedItem["p.creat_ts"] ? existingFeedItem["p.creat_ts"] : currentTs,
		"p.shared_modif_ts": existingFeedItem["p.shared_modif_ts"] ? existingFeedItem["p.shared_modif_ts"] : currentTs,
	};

	const isSharedPost = newPost.is_shared === "y";
	const imgId = `${userProfile.user_id}_${newPost.postUuid}`;
	const noOfImages = newPost.imgPathA?.length || 0;
	const postActionsFields = {
		p_edit: newPost?.created_by === "u" ? "y" : "n",
	};

	const imageFields = {
		"p.img_bucketRelativeRefPath0": isSharedPost ? newPost.imgPathA[0] : noOfImages > 0 ? `${imgId}_p0.jpg` : null,
		"p.img_bucketRelativeRefPath1": isSharedPost ? newPost.imgPathA[1] : noOfImages > 1 ? `${imgId}_p1.jpg` : null,
		"p.img_bucketRelativeRefPath2": isSharedPost ? newPost.imgPathA[2] : noOfImages > 2 ? `${imgId}_p2.jpg` : null,
		"p.img_bucketRelativeRefPath3": isSharedPost ? newPost.imgPathA[3] : noOfImages > 3 ? `${imgId}_p3.jpg` : null,
		"p.video_bucketRelativeRefPath": isSharedPost ? newPost.videoPath : newPost.videoPath ? `${imgId}_v.mp4` : null,
	};

	const userFields = {
		"p.prof_id": isCredo ? userProfile.cprof_id : userProfile.eprof_id,
		"p.username": isCredo ? userProfile.username : null,
		"p.firstName": !isCredo ? userProfile.firstName : null,
		"p.lastName": !isCredo ? userProfile.lastName : null,
		"p.profilePicRelUrl": !isCredo ? userProfile.eprofilePicRelUrl : userProfile.cprofilePicRelUrl,
	};

	const sFields = {
		"s.name": newPost.streamName,
		"s.streamId": newPost.streamId,
		comments: existingFeedItem.comments || null,
	};

	return {
		"p.postType": "p",
		...commonFields,
		...tsFields,
		...imageFields,
		...userFields,
		...sFields,
		...postActionsFields,
	} as Partial<FeedItem>;
}

export function sharePost(post: FeedItem) {
	const url = `${CfgMgr.APP_DOMAIN}${POST_DETAIL_PAGE}/id=${post["p.postUuid"]}`;
	AppUtils.copyLinkToClipboard({
		shareItem: ShareItemType.Post,
		item: post,
		url,
	});
	EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
		name: AnalyticEventsConst.postShareCopyLink,
	});
}
