import React, { useCallback, useMemo, useState } from "react";
import { BoostTray, MOBILE_COMPACT_WIDTH, SnackBarTypeOptions } from "@credo/ui-components";
import {
	AppMode,
	BoostActionType,
	BoostActionUpdateResponse,
	BoostBoardItem,
	Context,
	EvtMgr,
	onActionsParam,
	PermissionActions,
	PostBoostInfo,
	ResponseCode,
	SessMgr,
	useWindowSize,
} from "@credo/utilities";
import { useAtom, useAtomValue } from "jotai";
import { userProfileAtom, userWalletAtom } from "@credo/store";
import { CredoBoost } from "../../../services/actions/boost";
import { GenericBoostResponse } from "../../../services/actions/boost/types";
import {
	AppUtils, Community, Consts, ContextConst, EventConst, Post, useAuthManager, User,
} from "../../../utils";
import { strings } from "../../../i18n/config";
import { ExpertiseActions } from "../../../utils/types";
import { ratePostByUser } from "../requests";
import { activeBoostTagAtom, isSessionAuthAtom } from "../../../utils/atoms";

interface TrayWrapperProps {
	item: BoostBoardItem;
}

// eslint-disable-next-line import/prefer-default-export
export const TrayWrapper: React.FC<TrayWrapperProps> = (props: TrayWrapperProps) => {
	const {
		item,
	} = props;
	const { width } = useWindowSize();
	const [postBoostInfo, setPostBoostInfo] = useState<PostBoostInfo | undefined>(undefined);
	const [walletBalance, setWalletBalance] = useAtom(userWalletAtom);
	const activeTag = useAtomValue(activeBoostTagAtom);
	const isSessionAuth = useAtomValue(isSessionAuthAtom);
	const userProfile = useAtomValue(userProfileAtom);

	const { authManager } = useAuthManager();

	const isBoostAllowed = useMemo(() => authManager.isActionAllowed(
		PermissionActions.BOOST,
		new Post(
			item["p.postUuid"],
			new User(
				item["p.prof_id"],
				item["p.egousername"] ?? item["p.username"],
				isSessionAuth,
			),
			new Community(
				item["s.streamId"],
				item["s.struname"],
			),
		),
		new Context()
			.add(ContextConst.actor, {
				...userProfile,
				[ContextConst.vaultBalance]: walletBalance,
			} as unknown as User),
	), [authManager, isSessionAuth, item, walletBalance, userProfile]);

	const fetchData = useCallback(() => {
		const request = {
			postUuid: item["p.postUuid"],
			tag: activeTag,
		};

		const successCallback = (response: GenericBoostResponse<PostBoostInfo[]>) => {
			setPostBoostInfo(response.items[0]);
		};

		const errorCallback = () => {};

		CredoBoost.getBoostPostInfo(
			request,
			successCallback,
			errorCallback,
		);
	}, [item]);

	const handlePromise = () => new Promise((resolve, reject) => {
		const handleMessage = (
			message: BoostActionUpdateResponse,
		) => {
			if (message.retcd === ResponseCode.OK) {
				resolve(message);
			} else {
				reject(Error(`Not able to boost postUuid: ${item["p.postUuid"]}, error: ${message.retcd}`));
			}
			// Remove listeners since no need of keeping them active.
			// If we need it again, we will add them in the next request
			EvtMgr
				.getInstance(`${EventConst.boostPostActionUpdate}-${item["p.postUuid"]}`)
				.removeAllListeners();
		};
		EvtMgr
			.getInstance(`${EventConst.boostPostActionUpdate}-${item["p.postUuid"]}`)
			.addListener(handleMessage);
	});

	const handleIncrement = useCallback(async (param: onActionsParam) => {
		if (param.type === BoostActionType.INCREMENT && param?.message?.value) {
			setWalletBalance((prev: number) => {
				if (param.message.value <= prev) {
					return prev - param.message.value;
				}
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: strings("BoostTray.error.insufficient_funds"),
				});
				return prev;
			});
			const request = {
				user_id: SessMgr.getFromSession(Consts.user_id) ?? "",
				uxpcode: activeTag,
				rating: 1,
				mode: AppMode.CREDO,
				action: ExpertiseActions.RATE,
				postUuid: item["p.postUuid"],
				boostEp: param.message.value,
			};
			ratePostByUser(request);
			return handlePromise();
		}
		return Promise.reject(Error("No Value sent"));
	}, [item]);

	const handleActions = (param: onActionsParam) => {
		switch (param.type) {
			case BoostActionType.EXPAND:
				return fetchData();
			case BoostActionType.INCREMENT:
				return handleIncrement(param);
			default: return null;
		}
	};

	return (
		<BoostTray
			// postUuid={item["p.postUuid"]}
			ranking={{
				prevRanking: item.prevRanking,
				currentRanking: item.ranking,
			}}
			totalBoost={{
				prev: item.prevTotalBoost,
				current: item.totalBoost,
			}}
			topBoosters={item.topBoosters}
			isWide={width >= MOBILE_COMPACT_WIDTH}
			onActions={handleActions}
			postBoostInfo={postBoostInfo}
			messages={{
				boostSuccess: strings("BoostTray.success.boost_success"),
			}}
			authorisation={{
				isBoostAllowed,
			}}
		/>
	);
};
