import React, { useEffect, useRef, useState } from "react";
import {
	classNames,
	SvgIcon,
	BackIcon,
	Spinner,
	UserListSkeleton,
	RequestNoDataIcon,
	SpinnerLeafType,
} from "@credo/ui-components";
import {
	useLocation, useNavigate, useParams,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "react-use";
import {
	AppMode, cu, ResponseCode, SessMgr, useEvtMgrListener, YesNoOptions,
} from "@credo/utilities";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import InfiniteScroll from "react-infinite-scroll-component";
import last from "lodash/last";
import { strings } from "../../i18n/config";
import {
	Consts, EventConst,
} from "../../utils";
import { HelmetComponent } from "../../components/HelmetComponent";
import {
	isConnectedAtom, isSessionAuthAtom, userIdAtom, userProfileAtom,
} from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";
import {
	OperationType, RelationType, StreamInfo, StreamPrivacyOptions,
} from "../../utils/types";
import CfgMgr from "../../config/CfgMgr";
import UserListItem, { UserItem } from "./UserListItem";

interface ModeMeta {
	isCurrentUserMember: boolean,
	isFollowing: boolean,
	isPostAllowed: boolean,
	isUserJoinReqPending: boolean,
	isUserJoinRequestAccepted: boolean
	showJoinRequested: boolean,
}

interface NavigationParams {
	s_history: YesNoOptions
	entityId: string;
	postUuid: string;
	username: string;
	meta: {
		title: string;
	};
}

const defaultModeMeta = {
	isCurrentUserMember: false,
	isFollowing: false,
	isPostAllowed: false,
	isUserJoinReqPending: false,
	showJoinRequested: false,
	isUserJoinRequestAccepted: false,
};

const StreamViewMembersScreen: React.FC = () => {
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const location = useLocation();
	const streamInfo = location?.state?.communityDetails ?? null;
	const isInMobile = isMobile && width < 768;
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [itemsLoading, setItemsLoading] = useState<boolean>(true);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userId] = useAtom(userIdAtom);
	const { pathname } = window.location;
	const [userInfo] = useAtom(userProfileAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [isLastPage, setIsLastPage] = useState(false);
	const [membersData, setMembersData] = useState<UserItem[]>([]);
	const [isCurrentUserCredoAdmin, setIsCurrentUserCredoAdmin] = useState(null);
	const [isCurrentUserEgoAdmin, setIsCurrentUserEgoAdmin] = useState(null);
	const [, setIsLoadMore] = useState<boolean>(false);
	const [ego, setEgo] = useState<ModeMeta>(defaultModeMeta);
	const [credo, setCredo] = useState<ModeMeta>(defaultModeMeta);

	const dataPage = useRef(1);
	const dataSeed = useRef(1);
	const communityId = pathname.includes("id=") ? pathname.substring(pathname.indexOf("=") + 1) : null;
	const params = useParams();
	const struname = params?.struname ?? null;
	const navigationParams: NavigationParams = (location.state as NavigationParams) || {
		s_history: "",
		entityId: "",
		postUuid: "",
		username: "",
		meta: {
			title: "",
		},
	};

	const getStreamMembers = async (
		inProcessSetter: ((inProcess: boolean) => void) | null,
		operation: OperationType,
	) => {
		try {
			if (inProcessSetter) {
				inProcessSetter(true);
			}

			const stream = {
				page: dataPage.current,
				seed: dataSeed.current,
				lastTs: (membersData.length > 0 && operation !== OperationType.REPLACE ? last(membersData)?.creat_ts : 0) ?? 0,
				lastId: 0,
				user_id: userId || "",
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
				targetUserOrProfId: userId || "",
				streamId: communityDetails?.streamId,
			};
			const { items } = await StreamActions.fetchStreamMembers(stream, () => { }, () => { });
			if (items.length < CfgMgr.cfg_feed_nbOfItems2FetchFromDb) {
				setIsLastPage(true);
			}
			if (operation === OperationType.APPEND) {
				setMembersData((prevState: any) => [
					...prevState,
					...items,
				]);
			} else {
				setMembersData(items as any);
			}
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		} catch (e) {
			setItemsLoading(false);
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		}
	};

	const navigateToStreamProfile = () => {
		navigate(-1);
	};

	const beforeValueChange = (handleSwitch: any) => {
		handleSwitch();
		navigateToStreamProfile();
	};

	useEvtMgrListener(EventConst.handleBeforeSwitchModeChange, beforeValueChange);
	useEvtMgrListener(EventConst.streamDeleted, navigateToStreamProfile);

	const getStreamDetailsFromServer = () => {
		if (isConnected && (communityId || struname) && SessMgr.getFromSession(Consts.user_id)) {
			StreamActions.retrieveStreamInfoFromSvr({
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				streamId: communityId || navigationParams.entityId || "",
				s_history: navigationParams.s_history,
				postUuid: navigationParams.postUuid,
				struname: struname || "",
			});
		}
	};

	const checkStreamRelationsStatus = (relTypes: any[] | undefined) => {
		const currentUserInfo = userInfo || null;
		let credoRef = credo;
		let egoRef = ego;
		if (relTypes && relTypes[0][0]) {
			for (let i = 0; i < relTypes.length; i += 1) {
				if (relTypes[i].length > 0) {
					if (relTypes[i][0] === RelationType.JOIN_STREAM) {
						if (relTypes[i][2] === currentUserInfo?.cprof_id) {
							credoRef = {
								...credoRef,
								isPostAllowed: true,
								isUserJoinRequestAccepted: true,
								isCurrentUserMember: true,
							};
						}
						if (relTypes[i][2] === currentUserInfo?.eprof_id) {
							egoRef = {
								...egoRef,
								isPostAllowed: true,
								isUserJoinRequestAccepted: true,
								isCurrentUserMember: true,
							};
						}
					}
				}
			}
		}
		setEgo(egoRef);
		setCredo(credoRef);
	};

	useEffect(() => {
		if (!streamInfo) {
			setLoading(true);
			if (!isSessionAuth) {
				navigateToStreamProfile();
			} else {
				getStreamDetailsFromServer();
			}
		} else {
			setCommunityDetails(streamInfo);
			setLoading(false);
		}
	}, [communityId, struname, streamInfo, isConnected]);

	useEffect(() => {
		if (communityDetails
			&& (communityDetails?.ps_memsee === StreamPrivacyOptions.EVERYONE
				|| (isCredoMode && isCurrentUserCredoAdmin)
				|| (!isCredoMode && isCurrentUserEgoAdmin)
				|| ((isCredoMode && (communityDetails?.ps_memsee === StreamPrivacyOptions.MEMBERS_ONLY && credo.isCurrentUserMember))
					|| (!isCredoMode && (communityDetails?.ps_memsee === StreamPrivacyOptions.MEMBERS_ONLY && ego.isCurrentUserMember)))
			)
		) {
			setLoading(false);
		} else if (communityDetails) {
			navigateToStreamProfile();
		}
	}, [isCurrentUserEgoAdmin, isCurrentUserCredoAdmin]);

	const handleStreamInfo = (msg: any) => {
		if (cu.isSuccessMsg(msg)) {
			if (msg && msg.retcd === ResponseCode.OK && msg?.items && msg?.items.length > 0) {
				const data = msg?.items[0];
				setCommunityDetails(data);
			} else {
				navigateToStreamProfile();
			}
		} else {
			navigateToStreamProfile();
		}
		setLoading(false);
	};

	useEvtMgrListener(EventConst.streamInfo, handleStreamInfo);

	const checkIfUserIsAdmin = (
		streamAdmin: string[][],
		currentUserId: string,
		setIsCurrentUserAdmin: Function,
		setIsCrtUserSuperAdmin: Function,
		mode: string,
	) => {
		if (streamAdmin.length > 0) {
			const currentUserEgoAdmin = streamAdmin.find((single: string[]) => (single[3] === currentUserId && single[1] === mode));
			if (currentUserEgoAdmin) {
				setIsCurrentUserAdmin(true);
			} else {
				setIsCurrentUserAdmin(false);
			}
		}
	};

	useEffect(() => {
		if (userInfo && communityDetails) {
			if (!isSessionAuth || (userInfo && communityDetails && communityDetails?.relTypes && communityDetails?.jsReqPosts)) {
				checkStreamRelationsStatus(communityDetails?.relTypes);
			}
			getStreamMembers(setItemsLoading, OperationType.REPLACE);
			if (communityDetails?.streamAdmin && communityDetails?.streamAdmin.length > 0) {
				checkIfUserIsAdmin(
					communityDetails?.streamAdmin,
					userInfo?.eprof_id || "",
					setIsCurrentUserEgoAdmin,
					() => { },
					AppMode.EGO,
				);
				checkIfUserIsAdmin(
					communityDetails?.streamAdmin,
					userInfo?.cprof_id || "",
					setIsCurrentUserCredoAdmin,
					() => { },
					AppMode.CREDO,
				);
			} else {
				navigateToStreamProfile();
			}
		} else if (!isSessionAuth) {
			navigateToStreamProfile();
		}
	}, [communityDetails]);

	const handleLoadMore = () => {
		if (loading || isLastPage) return;
		dataPage.current += 1;
		getStreamMembers(setIsLoadMore, OperationType.APPEND);
	};

	if (loading) {
		return (
			<div className={classNames(
				"flex flex-col justify-center items-center",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic ",
				"bg-background",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "" : "pt-4",
			)}
			>
				<Spinner leaf_fill="var(--primary)" height="32" width="32" />
			</div>
		);
	}

	const renderMembers = () => (
		membersData.map((item: UserItem) => (
			<UserListItem
				item={item}
				isLast={!isLastPage}
			/>
		))
	);

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner leaf_shape={SpinnerLeafType.CIRCLES} width="20px" height="20px" />
			</div>
		);
	};

	const renderStreamMembersSection = () => {
		if (loading || itemsLoading) {
			return (
				<div className="max-h-[calc(100vh-4rem-1px-0.5rem)] overflow-hidden pt-2">
					<UserListSkeleton noOfItems={10} />
				</div>
			);
		}

		if (!loading && membersData && membersData.length === 0) {
			return (
				<div
					className={classNames(
						"flex flex-col items-center justify-center mt-10",
					)}
				>
					<SvgIcon
						icon={RequestNoDataIcon}
						title={strings("Assets.no_request")}
					/>
					<p className="text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap px-20">
						{communityDetails?.name + strings("StreamViewMembersScreen.no_member_message")}
					</p>
				</div>
			);
		}

		return (
			<div className={classNames(
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "pt-3 px-1" : "",
			)}
			>
				<InfiniteScroll
					next={handleLoadMore}
					hasMore
					loader={renderFooter()}
					dataLength={membersData.length}
				>
					{renderMembers()}
				</InfiniteScroll>
			</div>
		);
	};

	return (
		<div
			className={classNames(
				"flex flex-col",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-9",
			)}
		>
			<HelmetComponent
				title={strings("StreamViewMembersScreen.title")}
				url={location?.pathname}
			/>
			{renderStreamMembersSection()}
		</div>
	);
};

export default StreamViewMembersScreen;
