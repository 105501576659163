/* eslint-disable no-nested-ternary */
import {
	classNames,
	CommentDeletedIcon,
	ConfirmationModal,
	ContentSensitiveIcon,
	CopyCommentIcon,
	DeleteIcon,
	Modal,
	MoreMenu,
	MoreMenuInterface,
	PostCommentInput,
	PostHeaderScreenType,
	ProfilePicture,
	ReadMoreText,
	RegEx,
	ReplyCommentIcon,
	ReportCommentIcon,
	ReportIcon,
	RequestDeclineIcon,
	ShareIcon,
	Spinner,
	SpinnerLeafType,
	SvgIcon,
	ThreeDotIcon,
	ProfileSize,
	ImageCollage,
} from "@credo/ui-components";
import {
	AppMode, cu, EvtMgr, useEvtMgrListener, YesNoOptions,
} from "@credo/utilities";
import { useAtom } from "jotai";
import isNil from "lodash/isNil";
import React, {
	useEffect, useMemo, useRef, useState,
} from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useLongPress, useWindowSize } from "react-use";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import moment from "moment";
import { isCredoModeAtom } from "@credo/store";
import CfgMgr from "../../config/CfgMgr";
import { strings } from "../../i18n/config";
import {
	deleteItem, DeleteItemType, FeedItem, saveComment,
} from "../../modules/home/requests";
import ReportSuccess from "../../modules/legal/ReportSuccess";
import { POST_DETAIL_PAGE } from "../../routes/constants";
import { SearchActions } from "../../services/actions/search";
import {
	AnalyticEventsConst, AppUtils, EventConst, Consts,
} from "../../utils";
import {
	commentBeenRepliedAtom,
	isSessionAuthAtom,
	userIdAtom,
	userProfileAtom,
} from "../../utils/atoms";
import {
	CommentAction, CommentCurrentScreen, CommentItem, CommentType,
} from "../../utils/comments/types";
import ReportComment from "./ReportComment";
import { PostCommentHeader } from "./PostCommentHeader";
import { useDebouncedCallback } from "../../utils/hooks/useDebouncedCallback";
import { UserActions } from "../../services/actions/user";
import { PostCommentEmotions } from "./PostCommentEmotions";

export interface PostCommentProps {
	postData: FeedItem;
	commentData: CommentItem;
	parentCommentData?: CommentItem;
	isSubComment: boolean;
	isSubCommentPresent: boolean;
	isReplyAllowed: boolean;
	numberOfLines: number;
	currentScreen?: string;
}

export function PostComment({
	postData,
	commentData,
	isSubComment,
	isReplyAllowed,
	numberOfLines = 3,
	parentCommentData,
	isSubCommentPresent,
	currentScreen,
}: PostCommentProps) {
	const {
		commentUuid,
		commentText,
		mode,
		profilePicRelUrl,
		firstName,
		lastName,
		username,
		creatTs,
		modifTs,
		hidden,
		isRestricted,
		profId,
		s_img_bucketRelativeRefPath0,
		img_bucketRelativeRefPath0,
	} = commentData;

	const [userId] = useAtom(userIdAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [showRestrictedContent, setShowRestrictedContent] = useState(isRestricted);
	const [isCommentEditable, setIsCommentEditable] = useState(false);
	const [showFlagAsInappModal, setShowFlagAsInappModal] = useState(false);
	const [showReportSuccessModal, setShowReportSuccessModal] = useState(false);
	const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
	const [showMoreIcon, setShowMoreIcon] = useState(false);
	const [showMobileMoreMenu, setShowMobileMoreMenu] = useState(false);
	const [isReplyCommentInputVisible, setIsReplyCommentInputVisible] = useState<boolean>(false);
	const [commentBeenReplied, setCommentBeenReplied] = useAtom(commentBeenRepliedAtom);
	const [subCommentText, setSubCommentText] = useState<string>();
	const [commentTag, setCommentTag] = useState<string>("");
	const [userSuggestions, setUserSuggestions] = useState([]);
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const currentUserProfIds = [userProfile?.cprof_id, userProfile?.eprof_id];

	const viewRef = useRef(null);

	const commentWithPostData = useMemo(() => ({
		...commentData,
		"p.postUuid": postData?.["p.postUuid"],
		parentCommentUuid: isSubComment ? parentCommentData?.commentUuid : null,
	}), [commentData.authorTags]);

	const onLongPress = () => {
		if (isMobile) {
			setShowMobileMoreMenu(true);
		}
	};

	const defaultOptions = {
		isPreventDefault: false,
		delay: 800,
	};
	const longPressEvent = useLongPress(onLongPress, defaultOptions);

	const isAuthor = useMemo(() => {
		if (postData?.["p.username"]) {
			return postData
				&& postData?.["p.prof_id"] === (commentData.prof_id || commentData.profId)
				// in case user changes the credo username we should check that too
				&& postData?.["p.username"] === commentData?.username;
		}
		return postData
			&& postData?.["p.prof_id"] === (commentData.prof_id || commentData.profId);
	}, [postData, commentData]);

	useEffect(() => {
		setIsCommentEditable(
			AppUtils.allowUserToUpdateORDeleteItem(isCredoMode, commentData.edit ?? YesNoOptions.NO, commentData.mode ?? AppMode.CREDO),
		);
	}, [commentData, showRestrictedContent, isCredoMode]);

	const onSeeTextPress = () => {
		setShowRestrictedContent(!showRestrictedContent);
	};

	const onFlagAsInappClose = () => {
		setShowFlagAsInappModal(false);
	};

	const onPostReported = () => {
		setShowFlagAsInappModal(false);
		setShowReportSuccessModal(true);
	};

	const onReportContinue = () => {
		setShowReportSuccessModal(false);
	};

	const navigateToUser = () => {
		AppUtils.navigateToUserProfile(
			profId ?? "",
			mode === AppMode.CREDO ? username ?? "" : "",
			mode ?? "",
			isCredoMode,
			userProfile,
			navigate,
			mode === AppMode.EGO ? username ?? "" : "",
			YesNoOptions.NO,
			{
				title: mode === AppMode.CREDO && username
					? `@${username}` : `${firstName} ${lastName} ${username ? `(@${username})` : ""}`,
			},
		);
	};

	const navigateToUserOrStream = (data: any) => {
		AppUtils.navigateToUserOrStream({
			isCredoMode,
			userProfile,
			navigate,
			...data,
			meta: {
				title: mode === AppMode.CREDO && username
					? `@${username}` : `${firstName} ${lastName} ${username ? `(@${username})` : ""}`,
			},
		});
	};

	const onEditComment = () => {
		AppUtils.handleDownloadAppModal(true);
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentEdit,
		});
	};

	const onReportCommentPress = () => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentReport,
		});
		if (isSessionAuth) {
			setShowFlagAsInappModal(true);
		} else {
			AppUtils.handleLoginModal(true);
		}
	};

	const onDeleteCommentPress = () => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentDelete,
		});
		setShowDeleteCommentModal(true);
	};

	const onCloseDeletPostModal = () => {
		setShowDeleteCommentModal(false);
	};

	const onShareComment = () => {
		const commentParams = isSubComment ? `$commentUuid=${parentCommentData?.commentUuid}$subCommentUuid=${commentUuid}`
			: `$commentUuid=${commentUuid}`;
		const url = `${CfgMgr.APP_DOMAIN}${POST_DETAIL_PAGE}/id=${postData["p.postUuid"]}${commentParams}`;
		AppUtils.copyLinkToClipboard({
			shareItem: "comment",
			postItem: postData,
			commentText,
			commentUuid: isSubComment ? parentCommentData?.commentUuid : commentUuid,
			subCommentUuid: isSubComment ? commentUuid : undefined,
			url,
		});
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentShareCopyLink,
		});
	};

	const onPressOkDeleteComment = () => {
		if (isSubCommentPresent || hidden === YesNoOptions.YES) {
			// unhiding a parent comment having subcomments
			const updateRequest = {
				prof_id: profId ?? "",
				user_id: userId ?? "",
				mode,
				action: CommentAction.Update,
				postUuid: postData["p.postUuid"],
				streamId: postData["s.stramId"],
				commentText,
				commentUuid,
				newUserTags: "",
				removedUserTags: "",
				pCommentUuid: commentUuid,
				tags: "",
				hidden: AppUtils.toggleYesOrNoOption(hidden ?? YesNoOptions.YES),
			};
			saveComment(updateRequest);
		} else {
			// delete comment
			const deleteRequest = {
				prof_id: profId ?? "",
				user_id: userId ?? "",
				mode,
				itemType: DeleteItemType.Comment,
				postUuid: null,
				commentUuid,
				streamId: null,
			};
			deleteItem(deleteRequest);
		}
		EvtMgr.getInstance(`${EventConst.commentManipulated}_${postData["p.postUuid"]}`).notifyListeners({
			type: EventConst.commentDeleted,
			commentType: isSubComment ? CommentType.SubComment : CommentType.MainComment,
			isHiddenComment: isSubCommentPresent ? (hidden === YesNoOptions.NO ? YesNoOptions.YES : YesNoOptions.NO)
				: (hidden === YesNoOptions.YES ? YesNoOptions.NO : null),
			postUuid: postData["p.postUuid"],
			commentObj: {
				commentUuid,
				parentCommentUuid: isSubComment ? parentCommentData?.commentUuid : commentUuid,
			},
		});
		onCloseDeletPostModal();
	};

	const onCopyComment = () => {
		const formattedText = commentText?.replaceAll(RegEx.userTag, "@$1");
		navigator.clipboard.writeText(formattedText ?? "");
	};

	const moreMenuList = useMemo(() => {
		const menuList: MoreMenuInterface[] = [];
		if (isCommentEditable) {
			menuList.push({
				title: hidden === YesNoOptions.NO ? strings("PostComment.delete_comment") : strings("PostComment.unhide_comment"),
				icon: <DeleteIcon stroke="var(--gray-dark)" />,
				onClick: () => { onDeleteCommentPress(); },
			});
			// Uncomment this to display edit comment option
			// if (hidden === YesNoOptions.NO) {
			// 	menuList.push({
			// 		title: strings("PostComment.edit_comment"),
			// 		icon: <EditPostIcon stroke="var(--gray-dark)" />,
			// 		onClick: () => { onEditComment(); },
			// 	});
			// }
		}
		if (hidden === YesNoOptions.NO && !showRestrictedContent) {
			if (!isCommentEditable) {
				menuList.push({
					title: strings("PostComment.flag_as_inappropriate"),
					icon: <ReportIcon stroke="var(--gray-dark)" />,
					onClick: () => { onReportCommentPress(); },
				});
			}
			menuList.push({
				title: strings("PostComment.share_comment"),
				icon: <ShareIcon stroke="var(--gray-dark)" />,
				onClick: () => { onShareComment(); },
			}, {
				title: strings("PostComment.copy_comment"),
				icon: <CopyCommentIcon stroke="var(--gray-dark)" />,
				onClick: () => { onCopyComment(); },
			});
		}
		if (isMobile && menuList.length > 0) {
			menuList.push({
				title: strings("PostComment.cancel"),
				icon: <RequestDeclineIcon stroke="var(--gray-dark)" />,
				onClick: () => { setShowMobileMoreMenu(false); },
			});
		}
		return menuList;
	}, [showRestrictedContent, isCommentEditable, isCredoMode]);

	const onMoreMenuItemClick = (event: any, menuItem: MoreMenuInterface) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentMoreMenu,
		});
		event.stopPropagation();
		menuItem.onClick();
		setShowMobileMoreMenu(false);
	};

	const renderMobileMoreMenu = () => {
		if (isMobile && showMobileMoreMenu && moreMenuList.length > 0) {
			return (
				<Modal
					id="post-comment-more-menu-modal"
					show={showMobileMoreMenu}
					onClose={() => setShowMobileMoreMenu(false)}
					hideCloseIcon
					dialogClassName={classNames(
						"bg-background !p-0 max-w-full flex flex-col justify-center !rounded-none absolute bottom-0",
					)}
					dialogWrapperClassName="!p-0 z-[200]"
					closeIconClassName={classNames("hover:bg-background-secondary")}
					titleClassName="text-primary"
				>
					<div className="flex flex-col px-4 py-2">
						{moreMenuList.map((item: any) => (
							<div key={item.title}>
								<button
									type="button"
									key={item.title}
									onClick={(event) => onMoreMenuItemClick(event, item)}
									className={classNames("flex items-center rounded-lg transition w-full py-4 outline-none")}
								>
									<div className={classNames("flex items-center h-4 w-4 mr-3",
										"justify-center text-gray-dark")}
									>
										{item.icon}
									</div>
									<p className={
										classNames(
											"text-sm font-medium text-title-color text-left",
										)
									}
									>
										{item.title}
									</p>
								</button>
								<div style={{ height: 1 }} className="bg-post-bar-border ml-7" />
							</div>
						))}
					</div>
				</Modal>
			);
		}
		return null;
	};

	const COLLAGE_LAYOUT = [[1, 1], [2, 1], [1, 2], [2, 2]];

	const nvl = (value: any, defaultValue: any) => (!isNil(value) ? value : defaultValue);

	const images = useMemo(() => {
		const imageData = [
			s_img_bucketRelativeRefPath0 || img_bucketRelativeRefPath0,
		];

		const tempImages: Array<{ source: string }> = [];
		imageData.forEach((img) => {
			if (!isNil(img) && img !== " "
				&& !tempImages.includes(nvl(cu.buildSourceUrlImage(img), ""))) {
				tempImages.push({
					source: RegEx.url.test(img) || img?.includes("blob:") ? img : nvl(cu.buildSourceUrlImage(img), ""),
				});
			}
		});
		if (tempImages.length !== 0) {
			return tempImages;
		}

		return [];
	}, [s_img_bucketRelativeRefPath0, img_bucketRelativeRefPath0]);

	const renderCollageImages = () => {
		if (images && images.length === 0) {
			return null;
		}
		return (
			<div className="w-40 px-2 -mx-2 pt-2">
				<ImageCollage
					caption={commentText || ""}
					photos={images}
					layout={COLLAGE_LAYOUT[images.length - 1]}
					height={images.length === 1 ? ["100%"] : ["100%", "100%"]}
					width="100%"
					imageErrorMsg={strings("PostView.imageErrorMsg")}
					isContentRestricted={isRestricted || false}
					contentSensitiveMsg={strings("PostView.contentSensitiveMsg")}
					imgErrorStyle="!bg-background"
				/>
			</div>
		);
	};

	const checkAndrenderReportedComment = () => {
		if (showRestrictedContent) {
			return (
				<div className="flex flex-row pt-1">
					<SvgIcon
						icon={ContentSensitiveIcon}
					/>
					<p className="text-gray-dark text-sm font-normal ml-1.5">{strings("PostComment.contentSensitiveMsg")}</p>
					<p
						className="text-primary text-sm font-normal whitespace-pre-wrap cursor-pointer"
						onClick={onSeeTextPress}
						aria-hidden
					>
						{` ${strings("PostComment.see_text")}`}
					</p>
				</div>
			);
		}
		return (
			<div className="flex flex-col w-full h-full">
				<ReadMoreText
					text={commentText ?? ""}
					numberOfLines={numberOfLines}
					textId={commentUuid ?? ""}
					textLineHeight={26}
					textClasses="text-basic font-thin text-xs !pt-1 mr-1.5"
					navigateToMentionedUser={navigateToUserOrStream}
				/>
				{renderCollageImages()}
			</div>
		);
	};

	const renderDeletedComment = () => (
		<div className="flex flex-row pt-1 items-center">
			<SvgIcon
				icon={CommentDeletedIcon}
			/>
			<p className="text-gray-dark text-sm font-normal ml-1.5">{strings("PostComment.comment_deleted_by_author")}</p>
		</div>
	);

	const onClickMoreMenu = () => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentMoreMenu,
		});
	};

	const renderMoreIcon = () => {
		if (isDesktop) {
			return (
				<MoreMenu
					iconComp={
						(
							<div
								className={classNames(
									"flex w-8 h-8 rounded-2xl bg-background-comment items-center justify-center cursor-pointer ml-2",
									showMoreIcon && moreMenuList.length > 0 && !commentData.isCommentPending ? "visible" : "invisible",
								)}
								aria-hidden
								onClick={onClickMoreMenu}
							>
								<SvgIcon
									icon={ThreeDotIcon}
									width={16}
								/>
							</div>
						)
					}
					items={moreMenuList}
					containerStyle="xs:-left-48 sm:-left-52 -left-48 -top-28"
				/>
			);
		}
		return null;
	};

	const renderReportCommentModal = () => (
		<Modal
			id="report-comment-modal"
			show={showFlagAsInappModal}
			onClose={onFlagAsInappClose}
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<div>
					<p className="text-primary text-lg font-bold">{strings("PostView.report_comment")}</p>
					<div className="mt-2">
						<ReportComment
							commentData={commentData}
							onCommentReported={onPostReported}
							parentCommentData={parentCommentData}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);

	const renderReportSucessModal = () => (
		<Modal
			id="report-comment-success-modal"
			show={showReportSuccessModal}
			onClose={onReportContinue}
			hideCloseIcon
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col justify-center !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<ReportSuccess
					icon={<ReportCommentIcon />}
					title={strings("ReportComment.sent")}
					subTitle={strings("ReportComment.thank_you_message")}
					buttonTitle={strings("ReportComment.continue")}
					onPressButton={onReportContinue}
				/>
			</div>
		</Modal>
	);

	const renderDeleteCommentAlert = () => (
		<ConfirmationModal
			isVisible={showDeleteCommentModal}
			onClose={onCloseDeletPostModal}
			cancel_button_function={onCloseDeletPostModal}
			ok_button_title={!isSubCommentPresent && hidden === YesNoOptions.NO
				? strings("PostComment.delete") : hidden === YesNoOptions.NO
					? strings("PostComment.hide_content") : strings("PostComment.unhide_comment")}
			cancel_button_title={strings("PostView.cancel")}
			ok_button_function={onPressOkDeleteComment}
			title={isSubCommentPresent ? hidden === YesNoOptions.NO
				? strings("PostComment.cant_delete") : strings("PostComment.unhide_comment") : strings("PostComment.delete_comment")}
			message={isSubCommentPresent ? hidden === "n"
				? strings("PostComment.hide_message") : strings("PostComment.unhide_message") : strings("PostComment.confirm_message")}
			okButtonStyle={hidden === YesNoOptions.YES ? "!w-32" : ""}
			cancelButtonStyle=""
		/>
	);

	const renderPendingCommentOverlay = () => {
		if (commentData.isCommentPending) {
			return (
				<div
					className={classNames(
						"flex flex-1 flex-row w-full p-2.5 pl-5 rounded-b-xl rounded-tr-xl",
						"bg-userRatingOverlayBackground items-center justify-center",
						"absolute top-0 left-0 bottom-0 right-0",
					)}
				>
					<Spinner
						leaf_shape={SpinnerLeafType.CIRCLES}
						width="20px"
						height="20px"
						leaf_fill="var(--primary)"
					/>
				</div>
			);
		}
		return null;
	};

	const handleReply4Comment = () => {
		let replyCommentText = "";
		if (isSubComment && !currentUserProfIds.includes(profId ?? "")) {
			replyCommentText = `@${username} `;
		}
		if (replyCommentText) {
			setCommentTag(replyCommentText);
		}
		setIsReplyCommentInputVisible(true);
		setCommentBeenReplied({
			postUuid: postData["p.postUuid"],
			commentUuid: isSubComment ? parentCommentData?.commentUuid ?? "" : commentUuid ?? "",
			focusedCommentUuid: commentUuid ?? "",
			isFocused: true,
		});
	};

	const onChangeReplyCommentText = (text: string) => {
		setSubCommentText(text);
		if (commentTag) {
			setCommentTag("");
		}
	};

	const retrieveSuggestions = async (msgData: any) => {
		const suggestionsData = await SearchActions.fetchMentionsSuggestions(msgData);
		setUserSuggestions(suggestionsData);
	};

	const onSendReplyComment = (imgData: Array<string>) => {
		if ((isNil(subCommentText) || subCommentText.trim() === "")
			&& (isNil(imgData) || imgData.length === 0)) {
			return;
		}
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentNewFromPostDetails,
		});
		const newSubCommentUuid = uuidv5(uuidv4(), uuidv5.URL).toLowerCase();
		const currentCommentId = isSubComment ? parentCommentData?.commentUuid ?? "" : commentUuid;
		const saveCommentReq = {
			prof_id: profId ?? "",
			user_id: userId ?? "",
			mode: cu.getAppMode(),
			action: "",
			postUuid: postData["p.postUuid"],
			streamId: postData["s.streamId"],
			commentText: subCommentText || "",
			commentUuid: newSubCommentUuid,
			tags: AppUtils.getHashTagsFromText(subCommentText || ""),
			newUserTags: AppUtils.getUserTagsFromText(subCommentText || ""),
			removedUserTags: "",
			pCommentUuid: currentCommentId,
			hidden: YesNoOptions.NO,
			img_bucketRelativeRefPath0: imgData?.length > 0 ? imgData[0] : null,
			APIVersion: "v1",
		};
		saveComment(saveCommentReq);
		const currentTs = moment().toDate().getTime();
		const currentComment: CommentItem = {
			commentUuid: newSubCommentUuid,
			profId: (isCredoMode ? userProfile?.cprof_id : userProfile?.eprof_id) ?? "",
			commentText: subCommentText || "",
			profilePicRelUrl: (isCredoMode ? userProfile?.cprofilePicRelUrl : userProfile?.eprofilePicRelUrl) ?? "",
			creatTs: currentTs,
			modifTs: currentTs,
			username: isCredoMode ? userProfile?.username ?? "" : null,
			mode: cu.getAppMode(),
			firstName: isCredoMode ? null : userProfile?.firstName ?? "",
			lastName: isCredoMode ? null : userProfile?.lastName ?? "",
			edit: YesNoOptions.YES,
			hidden: YesNoOptions.NO,
			s_img_bucketRelativeRefPath0: imgData?.length > 0 ? imgData[0] : null,
			isRestricted: false,
			isCommentPending: true,
			authorTags: [],
			subcomms: [],
		};

		EvtMgr.getInstance(`${EventConst.commentManipulated}_${postData["p.postUuid"]}`).notifyListeners({
			type: EventConst.commentAdded,
			commentType: CommentType.SubComment,
			postUuid: postData["p.postUuid"],
			commentObj: {
				comment: currentComment,
				parentCommentUuid: isSubComment ? parentCommentData?.commentUuid : commentUuid,
			},
		});
		setSubCommentText("");
		setIsReplyCommentInputVisible(false);
		setCommentBeenReplied({
			commentUuid: commentBeenReplied?.commentUuid ?? "",
			postUuid: commentBeenReplied?.postUuid ?? "",
			focusedCommentUuid: commentBeenReplied?.focusedCommentUuid ?? "",
			isFocused: false,
		});
	};

	const onCommentInputFocus = () => {
		if (!isSessionAuth) {
			AppUtils.handleLoginModal(true);
		}
	};

	const getPlaceholderText = () => {
		let name = isCredoMode ? userProfile?.username : userProfile?.firstName;
		if (isMobile && width < 768) {
			name = name && name.length > 12 ? `${name.substring(0, 12)}...` : name;
		}
		name = cu.isSet(name) ? `as ${name}` : "";
		return `${strings("PostComment.reply")} ${name}`;
	};

	const showDownloadModal = () => {
		AppUtils.handleDownloadAppModal(true);
	};

	const renderReplyCommentInput = () => {
		if (isNil(postData)) {
			return null;
		}

		if (!isReplyCommentInputVisible) {
			return null;
		}

		if (postData["p.postUuid"]
			=== commentBeenReplied?.postUuid && !cu.isSet(commentBeenReplied?.commentUuid)) {
			if (subCommentText) {
				setSubCommentText("");
			}
			return null;
		}
		return (
			<div className={classNames(
				"flex flex-row pb-2.5 w-full ml-10",
			)}
			>
				<div className={classNames(
					"w-full",
					isMobile ? "mr-2.5" : "",
				)}
				>
					<PostCommentInput
						id={postData["p.postUuid"]}
						placeHolder={getPlaceholderText()}
						onChangeText={onChangeReplyCommentText}
						containerStyle=""
						getSuggesionsFromServer={retrieveSuggestions}
						mentionData={userSuggestions}
						onSendComment={onSendReplyComment}
						onCommentInputFocus={onCommentInputFocus}
						disabled={!isSessionAuth}
						isFocused
						value={commentTag}
						userDetails={userProfile}
						postMsgs={{
							imageErrorMsg: strings("PostView.imageErrorMsg"),
							contentSensitiveMsg: strings("PostView.contentSensitiveMsg"),
							cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
							switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
							sharedPostDeleted: strings("PostView.sharedPostDeleted"),
						}}
						showDownloadModal={showDownloadModal}
					/>
				</div>
				{isDesktop && <div className="w-8 h-8 ml-5" />}
			</div>
		);
	};

	const scollToView = (msg: any) => {
		const item = msg?.items?.[0];
		if (currentScreen === CommentCurrentScreen.PostDetails
			&& (item?.["pc.commentUuid"] === commentUuid || item["c.commentUuid"] === commentUuid)) {
			// @ts-ignore
			viewRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};

	const onEmojiChange = useDebouncedCallback((data: any) => {
		EvtMgr
			.getInstance(`${EventConst.commentManipulated}_${postData["p.postUuid"]}`)
			.notifyListeners({
				type: Consts.commentReacted,
				postUuid: postData["p.postUuid"],
				commentObj: {
					commentUuid,
					parentCommentUuid: isSubComment ? parentCommentData?.commentUuid : "",
				},
				emojiData: data,
			});
		const request = {
			prof_id: (isCredoMode ? userProfile?.cprof_id : userProfile?.eprof_id) ?? "",
			mode: cu.getAppMode(),
			commentUuid: commentUuid ?? "",
			pCommentUuid: isSubComment ? parentCommentData?.commentUuid ?? null : null,
			action: data.action,
			code: data.code,
			APIVersion: "v1",
			emoji: data.emoji,
		};
		UserActions.mngEmocons4Comments(request);
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEmojiSelected,
		});
	}, 200);

	useEvtMgrListener(EventConst.saveComment, scollToView);

	const isCommentBeenRepliedFromAtom = commentBeenReplied ? !(commentBeenReplied.focusedCommentUuid === commentUuid
		|| !commentBeenReplied.isFocused) : false;

	const numberForTimeStampBG = modifTs && modifTs !== creatTs ? modifTs : creatTs;

	return (
		<div
			ref={viewRef}
			className="w-full"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...longPressEvent}
			onMouseEnter={() => setShowMoreIcon(true)}
			onMouseLeave={() => setShowMoreIcon(false)}
		>
			<div
				className={classNames(
					"flex flex-col w-full mb-4 relative",
					isSubComment ? "items-end" : "items-start",
					isCommentBeenRepliedFromAtom ? "opacity-30" : "opacity-100",
				)}
			>
				<div className="flex flex-row w-full">
					<div
						className={classNames(
							"flex flex-row w-full items-center",
							// isSubComment ? "mr-2.5" : "",
						)}
					>
						<div
							className={classNames(
								"self-start items-center absolute z-10",
								!isSubComment ? "left-0" : "left-6",
							)}
						>
							<ProfilePicture
								profilePicUrl={profilePicRelUrl ? (cu.buildSourceUrlImage(profilePicRelUrl) || "") : ""}
								size={ProfileSize.X_SMALL_MEDIUM}
								onProfilePicPress={() => {
									navigateToUserOrStream({
										type: PostHeaderScreenType.User,
										mode,
										username,
										egousername: username,
										profId,
										meta: {
											title: mode === AppMode.CREDO && username
												? `@${username}` : `${firstName} ${lastName} ${username ? `(@${username})` : ""}`,
										},
									});
								}}
							/>
						</div>
						<div
							className={classNames(
								"flex flex-1 flex-col w-full p-2.5 pl-5",
								"border border-content-L2 rounded-b-15 rounded-tr-15 bg-content-L1 relative",
								!isSubComment ? "ml-4" : "ml-10",
							)}
						>
							{renderPendingCommentOverlay()}
							<PostCommentHeader
								name={mode === AppMode.CREDO ? username : `${firstName} ${lastName}`}
								timestamp={creatTs}
								// TODO: use editTs instead of using modifTs when we introduce update comment back
								// timestampForBg={numberForTimeStampBG}
								// timestampSubscript={modifTs && modifTs !== creatTs
								// 	? strings("PostComment.edited") : null}
								navigateToUser={navigateToUser}
								// on post details we get prof_id and on feeds we profId
								isAuthor={isAuthor}
								commentData={commentWithPostData}
							/>
							{/* render gif here */}
							<div className="flex flex-row">
								{hidden === YesNoOptions.NO
									? (
										checkAndrenderReportedComment()
									) : (
										renderDeletedComment()
									)}
							</div>
						</div>
						{isReplyAllowed
							? (
								<div
									className={
										classNames(
											"flex bg-background rounded-xl w-6 h-6 -ml-3 items-center justify-center cursor-pointer z-10",
											commentData.isCommentPending ? "invisible" : "visible",
											"-mt-3",
										)
									}
									onClick={handleReply4Comment}
									aria-hidden
								>
									<SvgIcon
										icon={ReplyCommentIcon}
									/>
								</div>
							) : null}
						<PostCommentEmotions
							commentData={commentData}
							onEmojiClick={onEmojiChange}
						/>
						{renderMoreIcon()}
					</div>
				</div>
			</div>
			<div className="flex items-end w-full">
				{renderReplyCommentInput()}
			</div>
			{showFlagAsInappModal && renderReportCommentModal()}
			{showReportSuccessModal && renderReportSucessModal()}
			{renderDeleteCommentAlert()}
			{renderMobileMoreMenu()}
		</div>
	);
}

PostComment.defaultProps = {
	parentCommentData: null,
	currentScreen: "",
};
