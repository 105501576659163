export enum EventItemScreenType {
	MEMBER = "member",
	BOT = "bot",
	BOT_ADD_COMMUNITY = "bot_add_community",
	REQUEST_SCREEN = "request_screen",
	STREAM_JOIN_REQUEST_SCREEN = "stream_join_request_screen",
	VIEW_FOLLOWERS = "view_followers",
	VIEW_MEMBERS = "view_members",
	ADD_ADMIN = "add_admin",
	ROLE_MANAGEMENT = "role_management",
	MEMBER_MANAGEMENT = "member_management",
}

export enum REQUEST_TYPE {
	CONNECT_USER = "cu",
	JOIN_STREAM = "js"
}

export enum REQUEST_ACTION {
	ACCEPT = "accept",
	REJECT = "reject"
}
