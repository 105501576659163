import { AppMode, PostTag, YesNoOptions } from "@credo/utilities";

export interface CommentItem {
	commentUuid: string | null;
	profId: string | null;
	commentText: string | null;
	profilePicRelUrl: string | null;
	creatTs: number | null;
	username: string | null;
	mode: AppMode | null;
	firstName: string | null;
	lastName: string | null;
	edit: YesNoOptions | null;
	modifTs: number | null;
	hidden: YesNoOptions | null;
	isRestricted: boolean | null;
	s_img_bucketRelativeRefPath0: string | null;
	subcomms?: CommentItem[];
	isCommentPending?: boolean;
	// PostDetails comment keys
	modif_ts?: string;
	nb_of_co?: number;
	nb_of_subco?: number;
	s_commentUuid?: string;
	s_creat_ts?: number | null;
	img_bucketRelativeRefPath0?: string | null;
	nb_of_emo?: number;
	emoCode?: [][];
	emoCountA?: [][];
	prof_id?: string | null;
	authorTags: PostTag[];
}

export const NullComment: CommentItem = Object.freeze({
	type: null,
	commentUuid: null,
	profId: null,
	commentText: null,
	profilePicRelUrl: null,
	creatTs: null,
	username: null,
	mode: null,
	firstName: null,
	lastName: null,
	edit: null,
	modifTs: null,
	hidden: null,
	isRestricted: null,
	s_img_bucketRelativeRefPath0: null,
	isCommentPending: false,
	subcomms: [],
	nb_of_emo: 0,
	emoCode: [],
	emoCountA: [],
	authorTags: [],
	nb_of_subco: 0,
});

export interface PostDetailCommentItem {
	id: number;
	c_edit: YesNoOptions;
	commentUuid: string;
	commentText: string;
	hidden: YesNoOptions;
	prof_id: string;
	profilePicRelUrl: string;
	creat_ts: string;
	mode: AppMode;
	firstName?: string;
	lastName?: string;
	isRestricted: boolean;
	modif_ts: string;
	nb_of_co: number;
	nb_of_subco: number;
	username?: string;
	egousername?: string;
	subcomms: any;
	s_img_bucketRelativeRefPath0?: string;
	nb_of_c_emo: number;
	c_emoCnt: any;
	emoCode4Comments: any;
	c_commentAuthorTags: PostTag[],
}

export interface MainCommentItem {
	c_username: string;
	c_commentText: string;
	c_commentUuid: string;
	c_creat_ts: number;
	c_edit: string;
	c_firstName: string;
	c_hidden: string;
	c_img_bucketRelativeRefPath0: string;
	c_isRestricted: boolean;
	c_lastName: string;
	c_mode: string;
	c_modif_ts: number;
	c_prof_id: string;
	c_profilePicRelUrl: string;
	c_no_of_subco: number;
}

export interface ParentCommentItem {
	p_username: string;
	p_commentText: string;
	p_commentUuid: string;
	p_creat_ts: number;
	p_edit: string;
	p_firstName: string;
	p_hidden: string;
	p_img_bucketRelativeRefPath0: string;
	p_isRestricted: boolean;
	p_lastName: string;
	p_mode: string;
	p_modif_ts: number;
	p_prof_id: string;
	p_profilePicRelUrl: string;
	p_no_of_subco: number;
}

export interface SubCommentItem {
	s_edit: string;
	s_commentUuid: string;
	s_commentText: string;
	s_hidden: string;
	s_prof_id: string;
	s_profilePicRelUrl: string;
	s_creat_ts: number;
	s_mode: string;
	s_firstName?: string;
	s_lastName?: string;
	s_isRestricted: boolean;
	s_modif_ts?: number;
	s_username?: string;
	s_egousername?: string;
	s_img_bucketRelativeRefPath0?: string;
}

export interface FeedCommentItem extends MainCommentItem, ParentCommentItem, SubCommentItem {
	id?: number;
}

export interface HomeFeedCommentData {
	isCommentPresent: boolean;
	comment: CommentItem;
	isSubCommentPresent: boolean;
	subComment: CommentItem;
	isParentCommentPresent: boolean;
	parentComment: CommentItem;
}

export enum CommentType {
	ConnectUser = "CONNECT_USER",
	X = "x",
	MainComment = "MAIN_COMMENT",
	SubComment = "SUB_COMMENT"
}

export enum CommentAction {
	Update = "up",
	Delete = "d"
}

export enum CommentCurrentScreen {
	PostDetails = "PostDetails",
	ShareScreen = "ShareScreen",
	HomeFeed = "Home",
	StreamProfile = "StreamProfileScreen"
}
