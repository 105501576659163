import { classNames, LazyLoad } from "@credo/ui-components";
import React from "react";
import { isMobile } from "react-device-detect";

// eslint-disable-next-line import/prefer-default-export
export const PrivacySettings = LazyLoad(
	() => import(/* webpackChunkName: "PrivacySettings" */ "./PrivacySettings"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"text-basic",
					"bg-background",
					"overflow-visible",
				)}
			>
				<div
					className={classNames(
						"w-full text-basic overflow-hidden",
						isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
					)}
				/>
			</div>
		),
	},
);
