import React, {
	useCallback, useEffect, useRef, useState,
} from "react";
import {
	ButtonSize,
	classNames,
	CredoTagBar,
	ExpertiseTag,
	Modal,
	PrimaryButton,
	SearchMessageIcon,
	SecondaryButton,
	SnackBarTypeOptions,
	SvgIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { Helmet } from "react-helmet";
import { isMobile, isTablet } from "react-device-detect";
import {
	cu, SessMgr,
} from "@credo/utilities";
import intersectionBy from "lodash/intersectionBy";
import xorBy from "lodash/xorBy";
import { useWindowSize } from "react-use";
import { isCredoModeAtom } from "@credo/store";
import SearchInput from "./SearchInput";
import {
	allSubTagsAtom,
} from "../utils/atoms";
import { strings } from "../i18n/config";
import { AppUtils, Consts } from "../utils";
import { ProposeTagActions, Tag } from "../utils/types";
import { MiscActions } from "../services/actions/misc";

interface ExpertiseSearchModalProps {
	show: boolean;
	onClose: () => void;
	selectedTags?: Tag[];
	emptySearchText?: string;
	searchNotFoundText?: string;
	updateUnselectedTagsProp: (tags: Tag[]) => void;
	tagLimit?: number;
}

const PROPOSED_TAG_TIMER_COUNT = 500;

// eslint-disable-next-line import/prefer-default-export
export const ExpertiseSearchModal: React.FC<ExpertiseSearchModalProps> = (props: ExpertiseSearchModalProps) => {
	const {
		show,
		onClose,
		selectedTags = [],
		emptySearchText,
		searchNotFoundText,
		updateUnselectedTagsProp,
		tagLimit,
	} = props;

	const [allSubTags, setAllSubTags] = useAtom(allSubTagsAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [search, setSearch] = useState<string>("");
	// Searched tags state
	const [searchTags, setSearchTags] = useState<Tag[]>([]);
	// Temporary tags so that we can clear this state on selecting any tag
	const [tempSearchTags, setTempSearchTags] = useState<Tag[]>([]);
	// Update the selected interest in local state so that we can update the atom in the end
	const [updatedSelectedTags, setUpdatedSelectedTags] = useState<Tag[]>(selectedTags);
	const [isTagProposed, setIsTagProposed] = useState(false);
	const [showProposedTagButton, setShowProposedTagButton] = useState(false);
	const proposedTagTextTimer = useRef<any>(null);
	const proposedTagButtonTimer = useRef<any>(null);
	const [proposedTagData, setProposedTagData] = useState<Tag[]>([]);
	const [proposedTagSugg, setProposedTagSugg] = useState<Tag[]>([]);
	const proposedTagTimer = useRef<any>(null);

	const searchInputRef = useRef<any>(null);

	const { width } = useWindowSize();

	const setProposedTagTimeout = () => {
		if (proposedTagTextTimer.current) clearTimeout(proposedTagTextTimer.current);
		// timer to show propose tag text
		proposedTagTextTimer.current = setTimeout(() => {
			if (!isTagProposed) setIsTagProposed(true);
		}, PROPOSED_TAG_TIMER_COUNT);

		if (proposedTagButtonTimer.current) clearTimeout(proposedTagButtonTimer.current);
		// timer to show propose tag button
		proposedTagButtonTimer.current = setTimeout(() => {
			if (!showProposedTagButton) setShowProposedTagButton(true);
		}, 2 * PROPOSED_TAG_TIMER_COUNT);
	};

	const searchAction = () => {
		if (cu.isSet(search.trim())) {
			setProposedTagTimeout();
			const filteredTags = allSubTags.filter((subTag) => subTag.tag.trim().toLowerCase().includes(search.trim().toLowerCase()));
			setSearchTags(filteredTags);
			setTempSearchTags(filteredTags);
		}
	};

	useEffect(() => {
		if (cu.isSet(search)) {
			searchAction();
		}
		if (search === "") {
			setSearchTags([]);
			setTempSearchTags([]);
			setProposedTagData([]);
			setProposedTagSugg([]);
		}
	}, [search]);

	useEffect(() => {
		setUpdatedSelectedTags(selectedTags);
	}, [selectedTags]);

	const getDialogueClasses = () => {
		if (isMobile && !isTablet) {
			return "max-w-full fullheight flex flex-col !rounded-none bg-background !p-0";
		}
		return "h-[32rem] min-w-[32rem] relative bg-background !p-0";
	};

	useEffect(() => {
		const tagsSelected = document.getElementById("tags-selected");
		// const searchResultContainer = document.getElementById("tags-result-container");
		const searchResult = document.getElementById("tags-result");
		if (isMobile && !isTablet && searchResult && tagsSelected) {
			searchResult.style.height = `calc((var(--vh, 1vh) * 100) - ${tagsSelected.clientHeight + 64}px)`;
		}
		if (tagsSelected && searchResult) {
			// @ts-ignore 64 is the height of the search input field
			searchResult.style.height = `calc(100% - ${tagsSelected.clientHeight}px)`;
		}
	}, [updatedSelectedTags]);

	const handleClearSearchInput = () => {
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	};

	const onCloseModal = () => {
		if (onClose) {
			setSearchTags([]);
			setTempSearchTags([]);
			setSearch("");
			onClose();
		}
	};

	const handleOnClickUnselectedTag = useCallback((item: Tag) => {
		if ((tagLimit && updatedSelectedTags.length < tagLimit) || !tagLimit) {
			setUpdatedSelectedTags((current) => [
				item,
				...current,
			]);
			setSearch("");
		}
		if (tagLimit && updatedSelectedTags.length === tagLimit) {
			// eslint-disable-next-line max-len
			const message = `${strings("ExpertiseModal.max_tag_message")}${tagLimit} ${strings("ExpertiseModal.tags_can_be_selected")}`;
			AppUtils.showToast({
				message,
				type: SnackBarTypeOptions.ERROR,
			});
		}
	}, [tagLimit, updatedSelectedTags]);

	const addToSelectedTags = (item: Tag) => {
		const tagIndex = selectedTags?.findIndex((singleTag: Tag) => singleTag.tag === item.tag);
		if (tagIndex === -1) {
			handleOnClickUnselectedTag(item);
			const updatedSearchedTags = [...tempSearchTags, item];
			setSearchTags(updatedSearchedTags);
		} else {
			AppUtils.showToast({
				type: SnackBarTypeOptions.ERROR,
				message: strings("ExpertiseModal.tag_already_selected"),
			});
		}
	};

	const handleOnClickSuggProposedTag = (item: Tag) => {
		if (proposedTagData.length > 0 || proposedTagSugg.length > 0) {
			setProposedTagData([]);
			setProposedTagSugg([]);
			addToSelectedTags(item);
		}
	};

	const onCreateProposedTag = () => {
		if (proposedTagData.length > 0) {
			addToSelectedTags(proposedTagData[0]);
			setProposedTagData([]);
			setProposedTagSugg([]);
			setAllSubTags([...allSubTags, proposedTagData[0]]);
		}
	};

	const handleOnClickProposeTagBack = () => {
		if (isTagProposed) setIsTagProposed(false);
		setProposedTagData([]);
		setProposedTagSugg([]);
		setProposedTagTimeout();
	};

	const isProposedTagValid = () => {
		// condition for proposing a tag
		// 1. Allow only AlphaNumeric and _
		// 2. Can’t start with a number
		// 3. Can’t be only numbers
		let errorMsg = "";
		if (search) {
			if (search.match(/^\d/)) {
				// search string starts with a number
				errorMsg = strings("ExpertiseModal.error_tag_starts_with_a_number");
			} else if (search.length < 2) {
				errorMsg = strings("ExpertiseModal.error_tag_min_length");
			} else if (search.length > 40) {
				errorMsg = strings("ExpertiseModal.error_tag_max_length");
			} else if (/\s/g.test(search)) {
				errorMsg = strings("ExpertiseModal.error_tag_whitespace");
			}
			if (errorMsg?.length > 0) {
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: errorMsg,
				});
				return false;
			}
			return Consts.proposedTagRegex.test(search);
		}
		return false;
	};

	const onProposeTag = async () => {
		if (isProposedTagValid()) {
			setIsTagProposed(false);
			const proposeTagResponse = await MiscActions.proposeTag({
				user_id: SessMgr.getFromSession(Consts.user_id),
				action: ProposeTagActions.PROPOSE,
				proposed_tag: search,
			});
			const suggestedTags: Tag[] = [];
			if (proposeTagResponse?.proposed_tag?.passed_check) {
				// tag is ok
				const newTag = {
					tag: proposeTagResponse?.proposed_tag?.tag,
					type: Consts.proposeTag,
					aliases: [],
					stream: "",
					tags: [],
					description: "",
				};
				setProposedTagData([newTag]);
			} else {
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: strings("ExpertiseModal.invalid_tag"),
				});
			}
			if (proposeTagResponse?.similar_tags?.length > 0) {
				proposeTagResponse?.similar_tags.forEach((item: any) => {
					suggestedTags.push({
						tag: item?.tag,
						type: item?.proposedTag ? Consts.proposeTag : Consts.similarTags,
						aliases: [],
						stream: "",
						tags: [],
						description: "",
					});
				});
				setProposedTagSugg(suggestedTags);
			}
		}
	};

	const onSearchTextChange = (value: string) => {
		if (isTagProposed) setIsTagProposed(false);
		if (showProposedTagButton) setShowProposedTagButton(false);
		setProposedTagTimeout();
		if (proposedTagData.length > 0) setProposedTagData([]);
		if (proposedTagSugg.length > 0) setProposedTagSugg([]);
		setSearch(value);
	};

	const renderSearchContainer = () => (
		<div className="flex justify-between px-4 py-3">
			<SearchInput
				hideSearchIcon
				inputClassName="bg-background-tertiary"
				onChange={onSearchTextChange}
				value={search}
				onClearSearch={handleClearSearchInput}
				inputProps={{
					ref: searchInputRef,
				}}
				className="max-w-full"
				applyTagFilter
			/>
			{/* {isMobile && !isTablet && (
				<button
					type="button"
					onClick={() => {
						onCloseModal();
						updateUnselectedTagsProp(updatedSelectedTags);
					}}
					className={classNames(
						"outline-none bg-transparent text-primary",
						"ml-2",
					)}
				>
					{strings("UserExpertise.done")}
				</button>
			)} */}
		</div>
	);

	const renderTagAlreadySelected = () => (
		<div className={classNames(
			"flex bg-background-tertiary justify-center items-start mt-7",
		)}
		>
			<p className="font-thin text-gray-dark text-sm text-cente max-w-[20rem]">
				Tag already selected
			</p>
		</div>
	);

	const renderNoDataFound = (isExactMatch: boolean) => (
		<div className={classNames(
			"flex bg-background-tertiary justify-center items-start",
			isExactMatch ? "" : "h-full",
		)}
		>
			<div
				className={classNames(
					"flex flex-col justify-start items-center h-56 bg-background-tertiary mt-7",
					// eslint-disable-next-line no-nested-ternary
					isExactMatch ? "" : isMobile && width < 768 ? "h-56" : "h-full",
				)}
			>
				<SvgIcon icon={SearchMessageIcon} height={32} width={32} />
				<p className="font-thin text-gray-dark text-sm text-center mt-4 max-w-[20rem]">
					{searchNotFoundText}
				</p>
				{isTagProposed && (
					<div>
						<p className="font-thin text-gray-dark text-sm text-center mt-6 max-w-[20rem]">
							{strings("ExpertiseModal.proposed_tag_msg")}
						</p>
						{showProposedTagButton && (
							<PrimaryButton
								size={ButtonSize.SMALL}
								label={strings("ExpertiseModal.propose_tag")}
								onClick={onProposeTag}
								buttonClassNames="!text-sm !font-normal mt-2 animate-fadeIn"
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);

	const renderEmptySearchContent = () => {
		if (!search && searchTags.length === 0) {
			return (
				<div className="flex h-full bg-background-tertiary justify-center items-start">
					<div
						className={classNames(
							"flex flex-col justify-center items-center h-56 bg-background-tertiary",
							isMobile && width < 768 ? "h-56" : "h-full",
						)}
					>
						<SvgIcon icon={SearchMessageIcon} height={32} width={32} />
						<p className="font-thin text-gray-dark text-sm text-center mt-4 max-w-[20rem]">
							{emptySearchText}
						</p>
					</div>
				</div>
			);
		}

		if (search && searchTags.length === 0 && proposedTagData.length === 0 && proposedTagSugg.length === 0) {
			return renderNoDataFound(false);
		}

		return null;
	};

	const renderSelectedExpertise = ({ item }: { item: Tag }) => (
		<ExpertiseTag
			title={item.tag}
			key={`${item.tag}-key`}
			titleStyle="!text-primary"
			buttonStyle={classNames(
				"!bg-transparent !border-primary  mb-2",
				item?.type === Consts.proposeTag ? "border-2 border-dashed !border-proposed-tag" : "",
			)}
			onPress={() => {
				const removedItemTags = updatedSelectedTags.filter((tag) => tag.tag !== item.tag);
				setUpdatedSelectedTags(removedItemTags);
			}}
		/>
	);

	const renderTagResult = () => {
		if (proposedTagData.length > 0 || proposedTagSugg.length > 0) {
			return (
				<div
					className={classNames(
						"flex flex-col",
						"overflow-auto h-full",
						"bg-background-tertiary",
					)}
				>
					<div className="mx-[16px]">
						{proposedTagData.length > 0 && (
							<>
								<div className="py-2 border border-r-0 border-l-0 border-t-0 border-background">
									<span className="text-gray-dark font-thin text-xs uppercase">
										{strings("ExpertiseModal.your_proposed_tag")}
									</span>
								</div>
								<div className="flex flex-col w-full items-center justify-center">
									<CredoTagBar
										containerStyle={classNames("mt-2 !bg-background-tertiary flex w-full items-center justify-center")}
										tagData={proposedTagData}
										tagClassName="mb-2"
										disabledAddMoreTags
										onPressTag={() => { }}
										showProposedTagIndicator
									/>
									<div className="flex flex-col items-center justify-center">
										<PrimaryButton
											size={ButtonSize.MEDIUM}
											label={strings("ExpertiseModal.create_proposed_tag")}
											onClick={onCreateProposedTag}
											buttonClassNames="!text-sm !font-normal"
										/>
										<SecondaryButton
											size={ButtonSize.SMALL}
											buttonClassNames="m-2"
											dimensionsClasses="w-14 h-5 capitalize"
											label="Back"
											handleClick={handleOnClickProposeTagBack}
										/>
									</div>
								</div>
							</>
						)}
						{proposedTagSugg.length > 0 && (
							<>
								<div className="py-2 border border-r-0 border-l-0 border-t-0 border-background">
									<span className="text-gray-dark font-thin text-xs uppercase">
										{strings("ExpertiseModal.other_suggested_tags")}
									</span>
								</div>
								<div>
									<CredoTagBar
										containerStyle={classNames("mt-2 !bg-background-tertiary")}
										tagData={proposedTagSugg}
										tagClassName="mb-2"
										disabledAddMoreTags
										onPressTag={handleOnClickSuggProposedTag}
										showProposedTagIndicator
									/>
								</div>
							</>
						)}
					</div>
				</div>
			);
		}
		if (searchTags.length > 0) {
			const selectedInSearchTags = intersectionBy(updatedSelectedTags, searchTags, "tag");
			const remainingUnselectedSearch = tempSearchTags.length > 0 ? xorBy(selectedInSearchTags, tempSearchTags, "tag") : [];
			const allTags = [...selectedInSearchTags, ...remainingUnselectedSearch];
			// @ts-ignore
			const isExactMatch = allTags?.find((singleResult: Tag) => singleResult?.tag?.toLowerCase() === search?.toLowerCase());
			const isExactMatchSelected = selectedInSearchTags?.find((singleResult: Tag) => singleResult?.tag?.toLowerCase() === search?.toLowerCase());
			return (
				<div
					className={classNames(
						"flex flex-col",
						"overflow-auto h-full",
						"bg-background-tertiary",
					)}
				>
					<div className="mx-4">
						{remainingUnselectedSearch.length > 0 && (
							<>
								<div className="py-2 border border-r-0 border-l-0 border-t-0 border-background">
									<span className="text-gray-dark font-thin text-xs uppercase">
										{strings("ExpertiseModal.search_result")}
									</span>
								</div>
								<CredoTagBar
									containerStyle={classNames("mt-2 !bg-background-tertiary")}
									tagData={remainingUnselectedSearch}
									tagClassName="mb-2"
									onPressTag={handleOnClickUnselectedTag}
									disabledAddMoreTags
									showProposedTagIndicator
								/>
							</>
						)}
						{!isExactMatch && renderNoDataFound(true)}
						{isExactMatchSelected && renderTagAlreadySelected()}
					</div>
				</div>
			);
		}
		return null;
	};

	const handleOnClickDone = useCallback(() => {
		updateUnselectedTagsProp(updatedSelectedTags);
		onCloseModal();
	}, [updatedSelectedTags]);

	return (
		<Modal
			id="expertise-search-modal"
			show={show}
			onClose={onCloseModal}
			hideCloseIcon
			dialogClassName={getDialogueClasses()}
			dialogWrapperClassName={classNames(
				isMobile && !isTablet ? "!p-0" : "p-4",
			)}
		>
			<Helmet>
				<meta name="theme-color" content={isCredoMode ? "#212636" : "#FFFFFF"} />
			</Helmet>
			<div
				className={classNames(
					"flex flex-col h-full",
					"p-0",
				)}
			>
				{renderSearchContainer()}
				<div
					id="tags-result"
					className={classNames(
						"overflow-auto w-full",
					)}
				>
					{renderEmptySearchContent()}
					{renderTagResult()}
				</div>
				<div
					id="tags-selected"
					className="flex flex-row relative bg-background bottom-0 w-full z-20 px-[16px] max-h-36 overflow-y-auto"
				>
					<div className="flex basis-3/4">
						{updatedSelectedTags.length > 0 && (
							<CredoTagBar
								tagData={updatedSelectedTags}
								renderItem={renderSelectedExpertise}
								disabledAddMoreTags
								showProposedTagIndicator
							/>
						)}
					</div>
					<div className="relative flex justify-start items-start pt-2 pb-4">
						<PrimaryButton
							size={ButtonSize.SMALL}
							label={strings("ExpertiseModal.add_selected_tags")}
							onClick={handleOnClickDone}
							buttonClassNames={classNames(
								"rounded-[18px] !text-sm !font-normal",
								updatedSelectedTags.length > 0 ? "mt-1" : "",
							)}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

ExpertiseSearchModal.defaultProps = {
	selectedTags: [],
	emptySearchText: "",
	searchNotFoundText: "",
	tagLimit: 0,
};
