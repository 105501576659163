import {
	AppMode,
	BoostActionUpdateResponse,
	cu,
	dbg,
	EvtMgr,
	LogMgr,
	ResponseCode,
	YesNoOptions,
} from "@credo/utilities";
import { GlobalState } from "@credo/store";
import { SnackBarTypeOptions } from "@credo/ui-components";
import {
	AppUtils, Consts, EventConst, MsgConst,
} from "../utils";
import { TXNStatus } from "../services/actions/boost/types";
import { strings } from "../i18n/config";

interface ResponseMap {
	retcd: ResponseCode
}

interface AdminResponse {
	addr: string
	msg_id: string
	newsid: null | string
	req: ResponseCode
	sendTs: number
	wrap_id: string
}

interface NotIfReqMsgResponse {
	addr: string
	msg_id: string
	nb_of_notifications: string;
	nb_of_request: string;
	nb_of_network_notifications: string;
	retcd: ResponseCode
	sendTs: number
	wrap_id: string
}

interface LightningDepositUpdateResponse {
	addr: string;
	sid: string;
	user_id: string;
	retcd: string;
	info: string | null,
	status: string;
	amount: string;
	procTs: number,
	sendTs: number,
	wrap_id: string;
	msg_id: string;
}

interface RemoteRequestMgr_generic<RequestType, ResponseType extends ResponseMap> {
	/**
	 * Server message type (endpoint in websocket) which
	 * we need to send/catch the request to/from.
	 * */
	msgName: string;
	/**
	 * Server message address(endpoint in websocket) which
	 * we need to send/catch the request to/from.
	 * If it is null, pass the value of msgName
	 * */
	msgAddr?: string;
	/**
	 * Callbacks or actions which can be taken after a successful
	 * response from the server on the message address. Takes the
	 * parameter as the response which is sent from the server and
	 * that response can be used in the callback to complete the
	 * required actions.
	 * @default null
	 * */
	instanceCallback?: (response: ResponseType) => void;
	/**
	 * Callbacks or actions which can be taken after a successful
	 * response from the server on the message address. Takes the
	 * parameter as the response which is sent from the server and
	 * that response can be used in the callback to complete the
	 * required actions.
	 * @default null
	 *
	 * @param {ResponseType} response - Success response from the server
	 * */
	successCallback?: (response: ResponseType) => void;
	/**
	 * Callbacks or actions which can be taken after a error
	 * response from the server on the message address. Takes the
	 * parameter as the error which is sent from the server and
	 * that error can be used in the callback to complete the
	 * required actions.
	 * @default null
	 *
	 * @param {ResponseType} error - Error response from the server
	 * */
	errorCallback?: (error: ResponseType) => void;
	/**
	 * Request object which needs to be sent to the address for
	 * fetching the data. Inside request object user_id is required
	 * field fetch any private information.
	 * */
	request: RequestType
}

// eslint-disable-next-line import/prefer-default-export
export class MsgMgr {
	/**
	 * Initialising Message Manager.
	 *
	 * Consists of:
	 * 	* Register admin handler for every admin request from the server.
	 * */
	static async init() {
		// Registering handler to the admin call from the server.
		global.ebMgr.ws.checkAndRegisterHandler("admin", {}, this.processServerResponse_admin.bind(this));
		global.ebMgr.ws.checkAndRegisterHandler(MsgConst.notif_req_msg, {}, this.processServerResponse_notif_req_msg.bind(this));
		global.ebMgr.ws.checkAndRegisterHandler(MsgConst.boostActionUpdate, {}, this.processServerResponse_boostActionUpdate.bind(this));
		global.ebMgr.ws.checkAndRegisterHandler(
			MsgConst.lightningDepositUpdate,
			{},
			this.processServerResponse_lightning_deposit_update.bind(this),
		);
	}

	/**
	 * Makes a remote requests to the server with message name and message request.
	 * On a successful response the actions inside the instanceCallback will be
	 * executed, which takes response as parameter.
	 *
	 * @param {RemoteRequestMgr_generic} msgData - The object which consists of
	 * msgName, instanceCallback and request.
	 * */
	static makeRemoteRequest_generic<Request, Response extends ResponseMap>(
		msgData: RemoteRequestMgr_generic<Request, Response>,
	) {
		const {
			msgName,
			msgAddr,
			instanceCallback,
			successCallback,
			errorCallback,
			request,
		} = msgData;

		return new Promise<Response>((resolve, reject) => {
			const callBack = (_p: null, response: Response) => {
				if (instanceCallback) instanceCallback(response);
				if (response.retcd === ResponseCode.OK || response.retcd.startsWith(ResponseCode.OK_WITH)) {
					if (successCallback) successCallback(response);
					resolve(response);
				} else {
					if (errorCallback) errorCallback(response);
					if (![ResponseCode.ERR_QR_NV].includes(response.retcd)) {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject(`Received not ok from api ${msgName}: ${response.retcd}`);
					} else if (dbg) {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject(`Received not ok from api ${msgName}: ${response.retcd}`);
					}
				}
			};
			global.ebMgr.sendMsg(msgAddr || msgName, msgName, request, callBack);
		});
	}

	/**
	 * Listener to the server's admin call.
	 * Whenever the server responds with "addr": "admin", this function will be executed.
	 * */
	static processServerResponse_admin(error: any, msg: AdminResponse) {
		if (dbg) LogMgr.mydbg("MsgMgr", "GOT MSG: ADMIN from svr", msg);
		if (msg.req === ResponseCode.REQ_REAU) {
			EvtMgr
				.getInstance(EventConst.logoutUser)
				.notifyListeners({
					avoidLogoutModal: true,
					avoidNotifySvr: true,
				});
			cu.setGlobalVar(Consts.isQRAuth, YesNoOptions.NO);
			if (dbg) LogMgr.mydbg("MsgMgr", "Logging out");
		}
	}

	/**
	 * Listener to the server's boost_action_update call.
	 * Whenever the server responds with "addr": "boost_action_update", this function will be executed.
	 * */
	static processServerResponse_boostActionUpdate(error: any, msg: BoostActionUpdateResponse) {
		if (dbg) LogMgr.mydbg("MsgMgr.processServerResponse_boostActionUpdate", "GOT MSG: boost_action_update from svr", msg);
		if (msg.retcd === ResponseCode.OK) {
			EvtMgr
				.getInstance(`${EventConst.boostPostActionUpdate}-${msg.postUuid}`)
				.notifyListeners(msg);
		}
	}

	/**
	 * Listener to the server's notif_req_msg call.
	 * Whenever the server responds with "addr": "notif_req_msg", this function will be executed.
	 * This function mainly will be executed when user gets notifications
	 * or requests, in the response user will have the count of the notifications
	 * which are pending to be seen or which are new in the realtime.
	 * */
	static processServerResponse_notif_req_msg(error: any, msg: NotIfReqMsgResponse) {
		if (dbg) LogMgr.mydbg("MsgMgr", "GOT MSG: notif_req_msg from svr", msg);
		if (msg.retcd === ResponseCode.OK) {
			let notifReqCount = {
				notifications: {
					ego: 0,
					credo: 0,
				},
				requests: {
					ego: 0,
					credo: 0,
				},
				network: 0,
			};

			if (msg.nb_of_notifications) {
				const splitMessage = msg.nb_of_notifications.split(",");
				if (splitMessage.length > 0) {
					splitMessage.forEach((item: string) => {
						const modeCount = item.split("=");
						if (modeCount.length > 0) {
							if (modeCount[0] === AppMode.EGO) {
								notifReqCount = {
									...notifReqCount,
									notifications: {
										...notifReqCount.notifications,
										ego: Number(modeCount[1]),
									},
								};
							} else {
								notifReqCount = {
									...notifReqCount,
									notifications: {
										...notifReqCount.notifications,
										credo: Number(modeCount[1]),
									},
								};
							}
						}
					});
				}
			}

			if (msg.nb_of_request) {
				const splitMessage = msg.nb_of_request.split(",");
				if (splitMessage.length > 0) {
					splitMessage.forEach((item: string) => {
						const modeCount = item.split("=");
						if (modeCount.length > 0) {
							if (modeCount[0] === AppMode.EGO) {
								notifReqCount = {
									...notifReqCount,
									requests: {
										...notifReqCount.requests,
										ego: Number(modeCount[1]),
									},
								};
							} else {
								notifReqCount = {
									...notifReqCount,
									requests: {
										...notifReqCount.requests,
										credo: Number(modeCount[1]),
									},
								};
							}
						}
					});
				}
			}

			if (msg?.nb_of_network_notifications) {
				notifReqCount = {
					...notifReqCount,
					network: Number(msg.nb_of_network_notifications),
				};
			}

			EvtMgr.getInstance(EventConst.notifReqCount).notifyListeners(notifReqCount);
			EvtMgr.getInstance(EventConst.loadNetwork).notifyListeners();
			if (dbg) LogMgr.mydbg("MsgMgr", "Saving the notif_req_msg in atom");
		}
	}

	/**
	 * Handles the message when deposit transaction is successful
	 * from any wallet to credo account and amount is added in
	 * the wallet. The amount from the server is directly set/updated
	 * in the GlobalState (store atoms).
	 * */
	static processServerResponse_lightning_deposit_update(error: any, message: LightningDepositUpdateResponse) {
		if (dbg) {
			LogMgr.mydbg(
				"MsgMgr.processServerResponse_lightning_deposit_update",
				"GOT MSG: lightning_deposit_update from svr",
				message,
			);
		}
		const amount = Number(message.amount);
		if (message.retcd === ResponseCode.OK && message.status === TXNStatus.COMPLETED && amount > 0) {
			EvtMgr
				.getInstance(EventConst.lightningDepositUpdate)
				.notifyListeners({
					amount,
				});
			GlobalState.User.walletBalance.set((prev: number) => prev + amount);
			GlobalState.Volts.summary.set((prev) => {
				const amount24H: number = Number(prev.wallet_24h) + amount;
				// eslint-disable-next-line no-nested-ternary
				const amountWithThousandStep: string = amount24H > 0
					? amount24H > 99999
						? `+${cu.formatNumberWithThousandsSep(amount24H)}` : `+${amount24H}`
					: amount24H < -99999 ? `-${cu.formatNumberWithThousandsSep(Math.abs(amount24H))}` : amount24H.toString();
				return {
					...prev,
					wallet_balance: prev.wallet_balance + amount,
					wallet_24h: amountWithThousandStep,
				};
			});
			EvtMgr
				.getInstance(EventConst.fetchWalletHistory)
				.notifyListeners();
			AppUtils.showToast({
				message: strings("VoltsDepositQRContent.success.amount_received", { amount })
					.replace("<0>", "")
					.replace("</0>", ""),
				type: SnackBarTypeOptions.SUCCESS,
			});
		}
	}
}
