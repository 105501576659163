import { FeedItem, ResponseCode, YesNoOptions } from "./types";

export interface TopBoosters {
	eprofilePicRelUrl: string,
	eprof_id: string;
	firstName: string;
	lastName: string;
}

export interface BoostBoardItem extends FeedItem {
	boardVersion: number,
	boardTs: number,
	ranking: number,
	prevRanking: number,
	rankScore: number,
	topBoosters: Array<TopBoosters>,
	totalBoost: number,
	prevTotalBoost: number,

	// Should be in FeedItem but adding it here so there any no issues at least in BoostBoardItem
	"p.video_bucketRelativeRefPath": string;
	"p.img_bucketRelativeRefPath0": string;
	"p.img_bucketRelativeRefPath1": string;
	"p.img_bucketRelativeRefPath2": string;
	"p.img_bucketRelativeRefPath3": string;
	"p.is_shared": YesNoOptions;
	"p.mediaObj_Link_Url": string;
	"p.mediaObj_Link_ImgUrl": string;
	"p.nb_rep": number;
	"p.shared_prof_id": string;
	"p.src_s_struname": string;
	"p.shared_mode": string;
	"p.shared_post_streamId": string;
	"p.src_s_name": string;
	"p.shared_profilePicRelUrl": string;
	"p.shared_creat_ts": string;
	"p.shared_edit_ts": string;
	// the types should be updated in PostHeader as well
	"p.shared_created_by": any;
}


export interface PostBoostInfo {
	/**
	 * Tag of post
	 * */
	"tag": string,
	/**
	 * postuuid of post
	 * */
	"postUuid": string,
	/**
	 * user_id of the user who is requesting the info
	 * */
	"user_id": string,
	/**
	 * Total boost of the post
	 * */
	"boost_total": number,
	/**
	 * Boost level of the post
	 * */
	"user_boost": number,
	/**
	 * Requesting user's current balance
	 * */
	"user_balance": number,
	/**
	 * PNL of the post
	 * */
	"pnl": {
		/**
		 * User earned by boosting this post.
		 * */
		"earned": number,
		/**
		 * User spent on the post
		 * */
		"spent": number,
		/**
		 * Net profit by this post
		 * */
		"net": number
	},
	extraInfo: {
		ranking: number,
		prevRanking: number,
		rankScore: number,
		prevTotalBoost: number,
		totalBoost: number;
		topBoosters: TopBoosters[];
	}
}

export enum BoostActionType {
	EXPAND = "expand",
	COLLAPSE = "collapse",
	INCREMENT = "inc",
	DECREMENT = "dec",
	FETCH_DATA = "fetch-data",
}

/**
 * Boost action status returned from server
 * */
export enum BoostActionStatus {
	SUCCESS = "boost_successful",
}

export type onActionsParam = {
	type: BoostActionType.EXPAND,
	message: null
} | {
	type: BoostActionType.COLLAPSE,
	message: null
} | {
	type: BoostActionType.FETCH_DATA,
	message: {
		tag: string;
	}
} | {
	type: BoostActionType.INCREMENT,
	message: {
		value: number;
	}
}

interface ViewerShipIncome {
	date: Date,
	income: number
}

interface ViewerShipIncomeForGraph {
	label: Date,
	value: number
}

export interface BoostTag {
	tag: string;
	user_boost: number;
	viewership_income: ViewerShipIncome[];
	viewership_income_for_graph: ViewerShipIncomeForGraph[];
	total_boost: number;
}

export interface BoostActionUpdateResponse {
	addr: "boost_action_update",
	"sid": string,
	"user_id": string,
	"retcd": ResponseCode,
	"info": string | null,
	"postUuid": string,
	"boostStatus": BoostActionStatus,
	"procTs": number,
	"sendTs": number,
	"wrap_id": string,
	"msg_id": string,
}
