import React, {
	Suspense,
} from "react";
import {
	classNames,
	PostFeedSkeleton,
} from "@credo/ui-components";
import { useMeasure } from "react-use";
import { useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import {
	blastDataAtom, blastsFilterTagsAtom, selectedBlastsFilterTagsAtom,
} from "../../utils/atoms";
import { strings } from "../../i18n/config";
import {
	FeedType,
} from "../../utils/types";
import { HelmetComponent } from "../../components/HelmetComponent";
import HomeFeed from "./HomeFeed";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";

// eslint-disable-next-line import/prefer-default-export
export const HomeBlasts = () => {
	const [divRef, { width }] = useMeasure();
	const location = useLocation();

	useSetAppHeader({
		title: strings("Sidebar.blasts"),
	});

	return (
		<Suspense
			fallback={(
				<div
					data-testid="home-content-suspense-loading"
					className={classNames(
						"block",
						"w-full max-w-screen-sm md:min-w-[40rem]",
						"overflow-hidden",
						// 4rem: Header, 1px: border, 4rem: Bottom navtab
						isMobile ? "max-h-[calc(100vh-4rem-1px-4rem)]" : "max-h-[calc(100vh-4rem-1px)]",
					)}
					style={{ width }}
				>
					{
						/**
						 * When showing suspense on mobile on changing the mode it was being cached
						 * */
						!(isMobile || isTablet) && (
							<HelmetComponent
								title={strings("HomePage.feed")}
								url={location?.pathname}
							/>
						)
					}
					<div
						className={classNames(
							"flex flex-col text-white",
							"bg-background w-full",
						)}
					>
						<PostFeedSkeleton showTabs />
					</div>
				</div>
			)}
		>
			<HelmetComponent
				title={strings("HomePage.blasts")}
				url={location?.pathname}
			/>
			<div
				data-testid="home-content-render-data"
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
				)}
				// @ts-ignore
				ref={divRef}
			>
				<div
					className={classNames(
						"flex flex-col text-white",
						"bg-background w-full",
					)}
				>
					<HomeFeed
						tabWidth={width}
						extraMsgObjParams={{ inca: "blst" }}
						atomName={blastDataAtom}
						feedType={FeedType.BLASTS}
						selectedFilterDataAtom={selectedBlastsFilterTagsAtom}
						filterDataAtom={blastsFilterTagsAtom}
					/>
				</div>
			</div>
		</Suspense>
	);
};
