import { Notifier } from "../../utils/Notifier";
import { Logger } from "../../utils/Logger";
import {
	AuthoorisationEngineFactory,
	AuthorisationCheckOutcome,
	AuthorisationEngine,
	Context,
	ContextFactory,
	ObjectTypeResolver,
	Permission,
	PermissionChangedListener,
	PermissionsConsumer,
	PermissionsPublisher,
	Rule,
	StaticContextFactory
} from "../AuthorisationEngine";
import { FactoryRegistrarSingleton } from "../../utils/Factory";

export type DobeEngineFactoryProps = {
	permissionsPublisher: PermissionsPublisher,
	objectTypeResolver: ObjectTypeResolver,
};

export class DobeEngineFactory implements AuthoorisationEngineFactory<DobeEngine> {
	static registerFactory() {
		FactoryRegistrarSingleton.register(AuthorisationEngine, new DobeEngineFactory());
	}

	create(args: DobeEngineFactoryProps): DobeEngine {
		return new DobeEngine(args.permissionsPublisher, args.objectTypeResolver);
	}

}

/**
 * Dobe - Authorisation Engine - FrontEnd
 *
 * Usage:
 * // At the start of the app, initialise it with something that can load (and reload) perms for this user
 * // It is expected that the backend will know how to serve up all the permissions for all keys for this user
 * // We specifically don't want to expose what keys user has to the code, this is not necessary for the FE to know
 * authorizer = new DobeEngine((load) {
 *     load(permissionSet);
 * });
 *
 * // later on, you can ask if something is allowed, e.g. can the user create a post
 * authorizer.isAllowed(
 * 		Actions.CREATE, 	// action,
 * 		ObjectTypes.POST, 	// on what object
 * 		new Context()		// context, this can be modified to include some standard stuff, ie credo mode, etc
 * 		.add(ObjectTypes.COMMUNITY, curCommunity)
 *
 *
 * )
 */
export class DobeEngine extends AuthorisationEngine implements PermissionsConsumer {

	// current set of permissions
	private permMap: Map<string, Permission> = new Map<string, Permission>();

	// notifiers
	private notifiers = {
		change: new Notifier<Permission>(),
		delete: new Notifier<Permission>()
	};

	/**
	 * gets called to consume permissions
	 * @param perms
	 */
	public consume(perms: Set<Permission>) {

		Logger.isDebug() && Logger.debug("Permissions onload:" + perms);

		this.setPermissions(perms);
	}

	/**
	 * delete all permissions
	 */
	public reset() {
		Logger.isDebug() && Logger.debug("Resetting permissions");
		this.permIndex.index.clear();
		this.permMap.clear();
	}

	/**
	 * @param pp
	 * @param objectTypeResolver
	 */
	constructor(pp: PermissionsPublisher, objectTypeResolver: ObjectTypeResolver) {
		super();
		this._objectTypeResolver = objectTypeResolver;
		pp.start(this);
	}

	private _contextProvider: ContextFactory = new StaticContextFactory(new Context());

	public setContextProvider(contextProvider: ContextFactory) {
		this._contextProvider = contextProvider;
	}

	private _objectTypeResolver: ObjectTypeResolver;

	/**
	 * Processes permissions to load them into an internal map, reacting to reduced permissions by notifying listeners if they're removed
	 * deletes the ones that are not found in the
	 * @param perms
	 * @private
	 */
	private setPermissions(perms: Set<Permission>) {
		let permsToDelete = new Set(this.permMap.keys());

		perms.forEach((p: Permission) => {
			Logger.isDebug() && Logger.debug("Processing permission:" + p);

			this.grantPermission(p);
			// remove from the set to delete
			if (this.permMap.has(p.id)) permsToDelete.delete(p.id);
		});

		permsToDelete.forEach((deletedId) => {
			let p = this.permMap.get(deletedId);

			if(p) this.removePermission(p);
		});
	}

	/**
	 *
	 * @param permission
	 * @private
	 */
	private permIndex = {
		index: new Map<string, Array<Permission>>(),
		makeKey(action: string, objectType: string) {
			return action + ":" + objectType;
		},
		lookup(action: string, onObjectType: string): Array<Permission> | undefined {
			let key = this.makeKey(action, onObjectType);
			return this.index.get(key);
		},
		update(p: Permission, remove = false) {
			for (let ai in p.actions) {
				let action = p.actions[ai];
				for (let oi in p.onObjects) {
					let objectType = p.onObjects[oi];
					let key = this.makeKey(action, objectType);
					let arr_p = this.index.get(key);
					if (remove) {
						if (arr_p) {
							arr_p = arr_p.filter((val) => val !== p);
							if (arr_p.length > 0) {
								this.index.set(key, arr_p);
							} else {
								this.index.delete(key);
							}
						}
					} else {
						if (!arr_p) arr_p = new Array<Permission>();
						if (!remove && !arr_p.includes(p)) {
							arr_p.push(p);
						}
						this.index.set(key, arr_p);
					}
				}
			}
		}
	};

	/**
	 * Removes permission
	 * @param p
	 * @private - because all permission changes should be done via the permissions provider
	 */
	private removePermission(p: Permission) {
		if(!this.permMap.has(p.id)) return;

		// update index maps
		this.permIndex.update(p, true);

		this.permMap.delete(p.id);

		this.notifiers.delete.notify(p);

		Logger.isDebug() && Logger.debug("Removed permission:" + p);

	}
	private grantPermission(p: Permission) {
		let updated = this.permMap.has(p.id);

		// update index maps
		this.permIndex.update(p);

		this.permMap.set(p.id, p);

		this.notifiers.change.notify(p, null);

		Logger.isDebug() && Logger.debug((updated ? "Updated" : "New") + " permission:" + p);

	}

	/**
	 * Add a listener to the permission changes
	 * @param permission
	 * @param listener
	 */
	public addListener(permission: Permission, listener: PermissionChangedListener) {
		this.notifiers.change.add(permission, listener.onChange);
		this.notifiers.delete.add(permission, listener.onDelete);
	}

	/**
	 * Add a listener to the permission changes
	 * @param permission
	 * @param listener
	 */
	public removeListener(permission: Permission, listener: PermissionChangedListener) {
		this.notifiers.change.remove(permission, listener.onChange);
		this.notifiers.delete.remove(permission, listener.onDelete);
	}

	/**
	 *
	 * @param onObject
	 */
	private resolveType(onObject: any): string {
		return this._objectTypeResolver.resolve(onObject);
	}

	/**
	 * A wrapper around isActionAllowed that allows for more *uniformly orchestrated* responses
	 * to the outcomes of the permission checking
	 * Why?
	 *
	 * @see AuthorisationCheckOutcome
	 *
	 * @param action
	 * @param onObject
	 * @param addContext
	 */
	public checkAction(action: string, onObject: any, addContext?: Context) {
		const de = this;
		let blocker: Permission;
		let allPerms: Permission[] = [];
		let failedPerms: Permission[] = [];
		let successfulPerms: Permission[] = [];
		let blocked = false;

		let res = this.isActionAllowed(action, onObject, addContext, (res, p: Permission) => {
			allPerms.push(p);

			switch (p.rule) {
				case Rule.Allow:
					if (res) {
						successfulPerms.push(p);
					} else {
						failedPerms.push(p);
					}
					break;
				case Rule.Block:
					if (res) {
						blocker = p;
						blocked = true;
					} else {
						successfulPerms.push(p);
					}
					break;
				default:
					throw new Error("Don't know how to handle this rule", p.rule,);
			}
		});

		return {
			result: () => res,
			failed: () => failedPerms,
			blocker: () => blocker,

			onPermissionChange: function (fn: (cp: Permission, deleted: boolean) => void) {

				for (let i = 0; i < allPerms.length; i++) {
					let p = allPerms[i];
					de.addListener(p, {
						onChange(changed: Permission): void {
							fn(changed, false);
						},
						onDelete(deleted: Permission): void {
							fn(deleted, true);
						}
					});
				}
				return this;
			},
			onBlocked: function (fn: (p: Permission) => void) {
				if (blocked) fn(blocker);
				return this;
			},
			onFailed: function (fn: (p: Permission) => boolean) {
				for (let i in failedPerms) {
					let p = failedPerms[i];
					if (!fn(p)) break;
				}
				return this;
			}
		};
	}

	/**
	 * Validates the action as per currently loaded permissions
	 * TODO implement caching of results
	 * TODO implement configurable resolvers for context (ie link)
	 * TODO implement base context
	 * @param action
	 * @param onObject - which object, this will be resolved into an object type using objectTypeResolver
	 * @param addContext - additional context to supply to the action
	 * @param onResult? - optoinal callback on (result:boolean, p"Permission)
	 */
	public isActionAllowed(action: string, onObject: any, addContext?: Context, onResult?: (result: boolean, p: Permission) => void): boolean {
		let res = false;

		let onObjectType = this.resolveType(onObject);

		// 1. find all the permissions that matches this action and onObject object
		let perms = this.permIndex.lookup(action, onObjectType);

		if (!perms || perms.length == 0) return false; // no permissions

		// 2. prepare the addContext
		let context = this._contextProvider
			.createContext()
			.add(onObjectType, onObject);

		if (addContext) context.addFrom(addContext);

		// 3. evaluate permissions, if encounter 'block' we quit
		for (let i = 0; i < perms.length; i++) {
			let p: Permission = perms[i];
			let p_res = p.withConditions(context);

			onResult && onResult(p_res, p);

			switch (p.rule) {
				case Rule.Allow:
					/**
					 * If a permission is allowed which is being checked upon
					 * any onObject that means that action should be allowed.
					 * If we straightaway set res = p_res then if next permission
					 * after a true result permission is false then that action
					 * is set as not allowed. So we need to save the true state
					 * if any and not override it with false.
					 * */
					if (!res) {
						res = p_res;
					}
					continue; // continues the loop
				case Rule.Block:
					if (p_res) {
						res = false;
						break; // veto time, so get out
					} else {
						continue; // continues the loop
					}
			}
			break; // this exits the break
		}

		return res;
	}

}
