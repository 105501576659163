/* eslint-disable react/require-default-props */
/* eslint-disable max-classes-per-file */
import React from "react";
import { CIStyler, classNames, ScalerSize } from "../common";
import styles from "./ExpertiseTag.module.css";

class TagStyler extends CIStyler<any> {
	constructor(base: string) {
		super(`${styles.tag} ${base}`);
	}

	sizeClass = (size?: ScalerSize) => styles[size ? size.toString() : ScalerSize.M];
}

class PillStyler extends TagStyler {
	constructor() {
		super(styles.pill);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}

	dynamicStyles(props: ExpertiseTagProps, _state: any) {
		return {
			...super.dynamicStyles(props),
		};
	}
}

class LabelStyler extends TagStyler {
	constructor() {
		super(styles.label);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}

	dynamicStyles(props: ExpertiseTagProps) {
		return {
			...super.dynamicStyles(props),
		};
	}
}

class IconStyler extends TagStyler {
	constructor() {
		super(styles.icon);
	}

	classes(data: ExpertiseTagProps, state: any | undefined, extraClasses: string) {
		return super.classes(data, state, extraClasses);
	}
}
interface ExpertiseTagProps {
	/**
	 * tag title
	 * @default ""
	 * */
	title: string;
	/**
	 * onPress handler for tag press
	 * @default ""
	 * */
	onPress?: () => void;
	/**
	 * onPressRemoveButton handler for remove icon press
	 * @default ""
	 * */
	onPressRemoveButton?: () => void;
	/**
	 * Custom CSS class for icon
	 * @default ""
	 * */
	iconStyle?: string;
	/**
	 * Custom CSS class for main container
	 * @default ""
	 * */
	containerStyle?: string;
	/**
	 * Custom CSS class for tag container
	 * @default ""
	 * */
	buttonStyle?: string;
	/**
	 * Custom CSS class for tag title
	 * @default ""
	 * */
	titleStyle?: string;
	hasRated?: boolean;
	/**
	 * Tag Size
	 * @default ScalerSize.M
	 * */
	size?: ScalerSize;
	iconRight?: React.ReactNode,
}

class ExpertiseTag extends React.Component<ExpertiseTagProps, any> {
	stylers: {
		pill: PillStyler,
		label: LabelStyler,
		icon: IconStyler
	};

	constructor(props: ExpertiseTagProps) {
		super(props);
		this.stylers = {
			pill: new PillStyler(),
			label: new LabelStyler(),
			icon: new IconStyler(),
		};
	}

	render() {
		const {
			title,
			onPress,
			onPressRemoveButton,
			iconStyle,
			buttonStyle,
			titleStyle,
			hasRated,
			size,
			iconRight,
			containerStyle,
		} = this.props;

		if (!title) {
			return null;
		}

		return (
			<div>
				<div className={classNames(
					"mr-3 mb-2",
					hasRated ? "border-2 border-tag-green p-px rounded-full" : "",
					containerStyle,
				)}
				>
					<button
						type="button"
						ref={this.stylers.pill.ref()}
						className={this.stylers.pill.classes(
							this.props,
							{},
							classNames("bg-gray-dark pl-1.5 pr-2 text-off-white rounded-full",
								"flex justify-center items-center flex-row min-w-fit",
								!hasRated ? "border border-tag-unrated" : "",
								iconRight ? "px-2" : "",
								buttonStyle),
						)}
						style={this.stylers.pill.dynamicStyles(this.props, {})}
						onClick={onPress}
					>
						<div
							className={this.stylers.label.classes(
								this.props,
								{},
								classNames(
									"break-all relative",
									titleStyle,
									iconRight ? "!pr-5" : "",
								),
							)}
							style={this.stylers.label.dynamicStyles(this.props)}
						>
							{title}
							{iconRight ? (
								<div
									onClick={onPressRemoveButton}
									className={classNames("absolute top-2 right-0 flex justify-center p-1", iconStyle)}
									style={this.stylers.icon.dynamicStyles(this.props)}
									aria-hidden
								>
									{iconRight}
								</div>
							) : null}
						</div>
					</button>
				</div>
			</div>
		);
	}
}

export default ExpertiseTag;
