export default class CfgMgr {
	static APP_VERSION = JSON.parse(JSON.stringify(process.env.REACT_APP_VERSION));

	static CFG_FLAVOR = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_FLAVOR));

	static CFG_MYVAR = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_MYVAR));

	static cfg_ws_dont_use_ssl = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_ws_dont_use_ssl));

	static cfg_credoWebSocketSvr_nossl = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_credoWebSocketSvr_nossl));

	static cfg_credoWebSocketSvr_ssl = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_credoWebSocketSvr_ssl));

	static CFG_DEBUG_ENABLED = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_DEBUG_ENABLED));

	static CFG_ERROR_ENABLED = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_ERROR_ENABLED));

	static CFG_WARN_ENABLED = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_WARN_ENABLED));

	static cfg_feed_nbOfItems2FetchFromDb = Number(JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_feed_nbOfItems2FetchFromDb)));

	static APP_DOMAIN = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_app_domain));

	static APP_LANDING_DOMAIN = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_landingPageLinkDomain));

	static SIGN_IN_URL = process.env.REACT_APP_CFG_SIGN_IN_URL;

	static SIGN_IN_LING_DOMAIN = process.env.REACT_APP_CFG_SIGN_IN_LINK_DOMAIN;

	static GET_IP_URL: string[] = JSON.parse(JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_app_fetch_ip_url)));

	// firebase keys start
	static cfg_firebase_app_check_site_key = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_firebase_app_check_site_key));

	static cfg_firebase_app_check_secret_key = JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_firebase_app_check_secret_key));

	static cfg_firebase_js_sdk_apiKey = process.env.REACT_APP_cfg_firebase_js_sdk_apiKey;

	static cfg_firebase_js_sdk_authDomain = process.env.REACT_APP_cfg_firebase_js_sdk_authDomain;

	static cfg_firebase_js_sdk_databaseURL = process.env.REACT_APP_cfg_firebase_js_sdk_databaseURL;

	static cfg_firebase_js_sdk_projectId = process.env.REACT_APP_cfg_firebase_js_sdk_projectId;

	static cfg_firebase_js_sdk_storageBucket = process.env.REACT_APP_cfg_firebase_js_sdk_storageBucket;

	static cfg_firebase_js_sdk_messagingSenderId = process.env.REACT_APP_cfg_firebase_js_sdk_messagingSenderId;

	static cfg_firebase_js_sdk_appId = process.env.REACT_APP_cfg_firebase_js_sdk_appId;

	static cfg_firebase_js_sdk_measurementId = process.env.REACT_APP_cfg_firebase_js_sdk_measurementId;

	static FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;

	static FIREBASE_ACTION_SETTINGS = {
		// @ts-ignore
		url: CfgMgr.SIGN_IN_URL,
		handleCodeInApp: true,
		// @ts-ignore
		dynamicLinkDomain: CfgMgr.SIGN_IN_LING_DOMAIN,
	};
	// firebase keys end

	static LINKEDIN_REDIRECT = process.env.REACT_APP_CFG_LINKEDIN_REDIRECT;

	static cfg_device_token_generation_key = process.env.REACT_APP_cfg_device_token_generation_key;

	static cfg_imageStorage_path = process.env.REACT_APP_cfg_imageStorage_path;

	// Store links for the platform apps
	static ios_app_store_link = JSON.parse(JSON.stringify(process.env.REACT_APP_cfg_ios_app_store_link));

	static android_play_store_link = JSON.parse(JSON.stringify(process.env.REACT_APP_cfg_android_play_store_link));
	// Store links for the platform apps end

	static cfg_app_store_id = process.env.REACT_APP_app_store_id;

	static cfg_dynamicLinkDomain1 = process.env.REACT_APP_cfg_dynamicLinkDomain1;

	static cfg_androidConfig_packageName = process.env.REACT_APP_cfg_androidConfig_packageName;

	static cfg_iosConfig_bundleId = process.env.REACT_APP_cfg_iosConfig_bundleId;

	static cfg_dynamicLinkUrl = process.env.REACT_APP_cfg_dynamicLinkUrl;

	static FAVICON = process.env.REACT_APP_favicon;

	static FAVICON_NOTIF = process.env.REACT_APP_favicon_notif;

	static analytics_hash_key = process.env.REACT_APP_analytics_hash_key
}
