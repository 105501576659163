import { Helmet } from "react-helmet";
import React from "react";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import CfgMgr from "../config/CfgMgr";
import { getInboxCountAtom } from "../utils/atoms";

interface ExtraDescription {
	name?: string;
	description: string;
}

interface OwnProps {
	/**
	 * Sets as title of the page of that tab.
	 * Also sets og:title property
	 *
	 * @default ""
	 * */
	title?: string;
	/**
	 * Sets as description of the page of that tab.
	 * Also sets og:description property
	 *
	 * @default ""
	 * */
	description?: string;
	/**
	 * Sets og:url property
	 *
	 * @default ""
	 * */
	url?: string;
	/**
	 * Array of multiple extra descriptions
	 * @default []
	 * */
	extraDescriptions?: ExtraDescription[]
}

/**
 * Handles and displays title, description, url
 * of the tab in the browser.
 * */
// eslint-disable-next-line import/prefer-default-export
export const HelmetComponent: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		title,
		description,
		url,
		extraDescriptions,
	} = props;

	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [inboxCount] = useAtom(getInboxCountAtom);

	const count = inboxCount.credoCount + inboxCount.egoCount;

	const renderTitle = () => {
		const formattedTitle = `${title} | credo`;

		if (title && count === 0) {
			return (
				<Helmet>
					<title id="window-title" data-rh="true">
						{formattedTitle}
					</title>
					<meta content={formattedTitle} property="og:title" data-rh="true" />
					<link
						rel="alternate icon"
						href={`/favicons/${CfgMgr.FAVICON}`}
						id={`${Math.random()}`}
						key="favicon-default"
					/>
					<link rel="apple-touch-icon" href={`/favicons/${CfgMgr.FAVICON}`} />
				</Helmet>
			);
		}
		return null;
	};

	const renderWithCount = () => {
		const formattedTitle = `(${count}) ${title} | credo`;
		if (count > 0 && title) {
			return (
				<Helmet>
					<title id="window-title" data-rh="true">
						{formattedTitle}
					</title>
					<meta content={formattedTitle} property="og:title" data-rh="true" />
					<link
						rel="alternate icon"
						href={`/favicons/${CfgMgr.FAVICON_NOTIF}`}
						id={`${Math.random()}`}
						key="favicon-notif"
					/>
				</Helmet>
			);
		}
		return null;
	};

	const renderDescription = () => {
		if (description) {
			return (
				<Helmet>
					<meta name="description" content={description} />
					<meta property="og:description" content={description} />
				</Helmet>
			);
		}
		return null;
	};

	const renderUrl = () => {
		if (url) {
			return (
				<Helmet>
					<meta
						content={`${CfgMgr.APP_DOMAIN}${url}`}
						property="og:url"
						data-rh="true"
					/>
					<link
						rel="canonical"
						href={`${CfgMgr.APP_DOMAIN}${url}`}
						data-rh="true"
					/>
				</Helmet>
			);
		}
		return null;
	};

	const renderDefaultTags = () => (
		<Helmet>
			{/* Had to use hardcoded because the css variables/tailwind classes do not work on meta tag */}
			<meta name="theme-color" content="#212636" />
		</Helmet>
	);

	const renderExtraDescriptions = () => {
		if (extraDescriptions && extraDescriptions?.length > 0) {
			return (
				<Helmet>
					{extraDescriptions?.map((desc) => (
						<meta
							name={desc?.name || "description"}
							content={desc.description}
						/>
					))}
				</Helmet>
			);
		}
		return null;
	};

	return (
		<>
			{renderDefaultTags()}
			{renderTitle()}
			{renderDescription()}
			{renderUrl()}
			{renderWithCount()}
			{renderExtraDescriptions()}
		</>
	);
};

HelmetComponent.defaultProps = {
	title: "",
	description: "",
	url: "",
	extraDescriptions: [],
};
