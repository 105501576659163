import { useSetAtom } from "jotai";
import { cu } from "@credo/utilities";
import { useEffect } from "react";
import { appHeaderAtom } from "../atoms";
import { AppHeader } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useSetAppHeader = (headerData: AppHeader, dependency: any = null) => {
	const setAppHeader = useSetAtom(appHeaderAtom);

	useEffect(() => {
		if (cu.isSet(headerData.title)) {
			setAppHeader(headerData);
		}
	}, [dependency]);
};
