import { classNames, HomeRequestSkeleton, LazyLoad } from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const NotificationPage = LazyLoad(
	() => import(/* webpackChunkName: "NotifPage" */ "./NotificationPage"),
	(module) => module.default,
	{
		fallback: (
			<div
				data-testid="home-content-loading"
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"overflow-hidden",
					// 4rem: Header, 1px: border, 4rem: Bottom navtab
					isMobile ? "max-h-[calc(100vh-4rem-1px-4rem)]" : "max-h-[calc(100vh-4rem-1px)]",
				)}
			>
				<div
					className={classNames(
						"flex flex-col text-white",
						"bg-background w-full",
					)}
				>
					<HomeRequestSkeleton showTabs noOfTabs={2} skeletonCount={10} />
				</div>
			</div>
		),
	},
);
