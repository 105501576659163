import React, { useMemo } from "react";
import { RankDelta } from "../../types";

interface CurrentRankProps {
	rankDeltaSign: RankDelta;
	/**
	 * Current rank of the post
	 * */
	currentRank: number;
}

// eslint-disable-next-line import/prefer-default-export
export const CurrentRank: React.FC<CurrentRankProps> = (props: CurrentRankProps) => {
	const {
		currentRank,
		rankDeltaSign,
	} = props;

	// Since there will be multiple instances of the same component
	// we need to give the path's ids unique string
	const UniqueId = useMemo(() => Math.random(), []);

	const stopValues = useMemo(() => {
		if (rankDeltaSign === RankDelta.NEGATIVE) {
			return [
				{
					offset: "0%",
					stopOpacity: "1",
					stopColor: "#212636",
				},
				{
					offset: "80%",
					stopOpacity: "1",
					stopColor: "#212636",
				},
				{
					offset: "80%",
					stopOpacity: "1",
					stopColor: "var(--tag-green)",
				},
				{
					offset: "100%",
					stopOpacity: "1",
					stopColor: "var(--tag-green)",
				},
			];
		}

		if (rankDeltaSign === RankDelta.POSITIVE) {
			return [
				{
					offset: "0%",
					stopOpacity: "1",
					stopColor: "var(--error)",
				},
				{
					offset: "20%",
					stopOpacity: "1",
					stopColor: "var(--error)",
				},
				{
					offset: "20%",
					stopOpacity: "1",
					stopColor: "#212636",
				},
				{
					offset: "100%",
					stopOpacity: "1",
					stopColor: "#212636",
				},
			];
		}

		return [
			{
				offset: "0%",
				stopOpacity: "1",
				stopColor: "#212636",
			},
			{
				offset: "100%",
				stopOpacity: "1",
				stopColor: "#212636",
			},
		];
	}, [rankDeltaSign]);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="100%">
			<linearGradient id={`lg-${UniqueId}`} x1="0.5" y1="1" x2="0.5" y2="0">
				{stopValues?.map((value) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<stop key={`${value.stopColor}-${value.offset}-${value.stopOpacity}`} {...value} />
				))}
			</linearGradient>
			<circle
				cx="8"
				cy="8"
				r="7"
				fill={`url(#lg-${UniqueId})`}
				stroke="#212636"
				strokeWidth="0.5"
			/>
			<text
				x="50%"
				y="54%"
				fontFamily="PTMono-Bold, PT Mono"
				dominantBaseline="middle"
				fontWeight="bold"
				fontSize={7}
				textAnchor="middle"
				fill="#0BC7E0"
			>
				{currentRank}
			</text>
		</svg>
	);
};
