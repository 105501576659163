/* eslint-disable react/require-default-props */
import React, {
	useEffect, useState,
} from "react";
import {
	classNames, ConfirmationModal, CredoTagBar, SnackBarTypeOptions, ButtonSize,
	PrimaryButton, FeedNoPost,
} from "@credo/ui-components";
import {
	AppMode,
	cu, EvtMgr, TagRankType,
} from "@credo/utilities";
import { useAtom } from "jotai";
import differenceBy from "lodash/differenceBy";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import { isCredoModeAtom } from "@credo/store";
import { userProfileAtom, userSessionAtom, newStreamSelectedExpertise } from "../../utils/atoms";
import { strings } from "../../i18n/config";
import { UserActions } from "../../services/actions/user";
import { Actions, ItemType, Tag } from "../../utils/types";
import { ExpertiseSearchModal } from "../../components/ExpertiseSearchModal";
import { AppUtils, Consts, EventConst } from "../../utils";
import { StreamActions } from "../../services/actions/stream";

interface ExpertiseEditScreenProps {
	addedExpertise: any;
	tagLimit: any;
	streamId?: any;
	streamObj?: any;
	onDone: () => void;
}

export default function ExpertiseEditScreen({
	addedExpertise,
	tagLimit,
	streamId,
	onDone,
	streamObj,
}: ExpertiseEditScreenProps) {
	const [userSession] = useAtom(userSessionAtom);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [, setNewStreamSelectedExpertiseAtom] = useAtom(newStreamSelectedExpertise);
	const [selectedLocal, setSelectedTagLocal] = useState<any>([]);
	const [showExpertiseModal, setShowExpertiseModal] = useState<boolean>(false);
	const [credibleTags, setCredibleTags] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [newlyGeneratedStreamId, setNewlyGeneratedStreamId] = useState<any>(null);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userInfo] = useAtom(userProfileAtom);

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
	};

	const onCancel = () => {
		if (onDone) {
			onDone();
		}
		closeConfirmationModal();
	};

	useEffect(() => {
		setCredibleTags(addedExpertise);
	}, [addedExpertise]);

	useEffect(() => {
		setSelectedTagLocal(credibleTags);
		setNewStreamSelectedExpertiseAtom(credibleTags);
	}, [credibleTags]);

	useEffect(() => {
		if (streamObj && addedExpertise?.length === 0) {
			setShowExpertiseModal(true);
		}
	}, [streamObj]);

	const handleUpdateTagsByModal = (updateTags: Tag[]) => {
		setSelectedTagLocal(updateTags);
		setNewStreamSelectedExpertiseAtom(updateTags);
	};

	const getFormattedStreamTags = (unFormattedTags: any) => {
		const formattedTags: any[][] = [];
		if (unFormattedTags && unFormattedTags.length > 0 && unFormattedTags[0].tag) {
			unFormattedTags.forEach((item: any) => {
				formattedTags.push([
					item.tag,
					item.val,
					"",
					item.xpRank,
				]);
			});
		}
		return formattedTags;
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const successCallback = async () => {
		if (streamId) {
			EvtMgr.getInstance(EventConst.updatedStreamTags).notifyListeners(getFormattedStreamTags(selectedLocal));
		}
		onCancel();
	};

	const errorCallback = () => {
		onCancel();
	};

	const handleSubmit = async () => {
		const stream_id = newlyGeneratedStreamId ?? streamId;
		const deletedTags = streamObj ? [] : differenceBy(credibleTags, selectedLocal, "tag");
		const newlySelectedTags = streamObj ? selectedLocal : differenceBy(selectedLocal, credibleTags, "tag");
		const newlyPropsedTags: Tag[] = [];

		if (newlySelectedTags.length > 0) {
			const parsedTagForReq = {
				xpCodeA: "",
				xpRankA: "",
				xpStreamIdA: "",
				streamId: stream_id,
			};
			newlySelectedTags.forEach((tag: any) => {
				if (tag?.type === Consts.proposeTag) {
					newlyPropsedTags.push(tag);
				}
				parsedTagForReq.xpCodeA = `${parsedTagForReq.xpCodeA + tag.tag},`;
				parsedTagForReq.xpRankA = `${parsedTagForReq.xpRankA}${TagRankType.PRIMARY},`;
				if (tag.stream) parsedTagForReq.xpStreamIdA = `${parsedTagForReq.xpStreamIdA + tag.stream},`;
			});
			AppUtils.createNewProposedTags(newlyPropsedTags);
			const request = {
				user_id: userSession?.user_id,
				mode: cu.getAppMode(),
				itemType: stream_id ? ItemType.STREAM : ItemType.USER,
				xpType: "uxp_expt",
				action: Actions.CREATE,
				...parsedTagForReq,
			};

			await UserActions.manageUserXpItemSignUp(
				request,
				successCallback,
				errorCallback,
			);
		}
		if (deletedTags.length > 0) {
			const tagObj = {
				xpCodeA: "",
				xpRankA: "",
				xpStreamIdA: "",
				streamId: stream_id,
			};
			deletedTags.forEach((item: any) => {
				tagObj.xpCodeA = `${tagObj.xpCodeA + item.tag},`;
				tagObj.xpRankA = `${tagObj.xpRankA}${item.xpRank},`;
				if (item.stream) tagObj.xpStreamIdA = `${tagObj.xpStreamIdA + item.stream},`;
			});
			const requestObj = {
				user_id: userSession?.user_id,
				mode: cu.getAppMode(),
				itemType: stream_id ? ItemType.STREAM : ItemType.USER,
				xpType: "uxp_expt",
				action: Actions.DELETE,
				...tagObj,
			};
			await UserActions.manageUserXpItemSignUp(requestObj, successCallback, errorCallback);
		}
		if (deletedTags.length === 0 && newlySelectedTags.length === 0) {
			onCancel();
		}
		setLoading(false);
		setNewStreamSelectedExpertiseAtom([]);
	};

	const removeTags = (item: any) => {
		const newselectedTags = selectedLocal.filter((tag: { tag: string; }) => tag.tag !== item.tag);
		setSelectedTagLocal(newselectedTags);
		setNewStreamSelectedExpertiseAtom(newselectedTags);
	};

	const showConfirmationModal = () => {
		const deletedTags = differenceBy(credibleTags, selectedLocal, "tag");
		const newlySelectedTags = differenceBy(selectedLocal, credibleTags, "tag");
		if (deletedTags.length > 0 || newlySelectedTags.length > 0) {
			setOpenConfirmationModal(true);
		} else {
			onCancel();
			setNewStreamSelectedExpertiseAtom([]);
		}
	};

	useEffect(() => {
		if (streamObj && streamObj?.joinModes) {
			if ((isCredoMode && streamObj?.joinModes === AppMode.EGO)
				|| (!isCredoMode && streamObj?.joinModes === AppMode.CREDO)) {
				setIsValid(false);
				AppUtils.showToast({
					message: strings("NewStreamSettingsScreen.join_mode_error_msg"),
					type: SnackBarTypeOptions.ERROR,
				});
			} else {
				setIsValid(true);
			}
		}
	}, [isCredoMode, streamObj?.joinModes]);

	const onPressTag = (item: any) => {
		if (item?.doAddTag) {
			if (streamId && tagLimit && selectedLocal?.length === tagLimit) {
				AppUtils.showToast({
					// eslint-disable-next-line max-len
					message: `${strings("ExpertiseEditScreen.max_tag_message")}${selectedLocal?.length} ${strings("ExpertiseEditScreen.tags_can_be_selected")}`,
					type: SnackBarTypeOptions.ERROR,
				});
			} else { setShowExpertiseModal(true); }
		}
	};

	const renderSelectedTagBar = () => (
		<CredoTagBar
			tagData={selectedLocal}
			onPressTag={onPressTag}
			onPressRemoveButton={removeTags}
			containerStyle="-my-3"
			hasRated
			tagLimit={tagLimit ?? null}
		/>
	);

	const createNewStream = async () => {
		if (selectedLocal.length > 0) {
			setLoading(true);
			const streamId = uuidv5(uuidv4(), uuidv5.URL).toLowerCase();
			const requestObj = {
				user_id: userSession?.user_id,
				mode: cu.getAppMode(),
				prof_id: isCredoMode ? userInfo?.cprof_id : userInfo?.eprof_id,
				streamId,
				...streamObj,
			};
			StreamActions.createStream(requestObj, (res) => {
				if (cu.isSuccessMsg(res) && res.items && res.items.length > 0) {
					setNewlyGeneratedStreamId(res.items[0].streamId);
				} else {
					AppUtils.showToast({
						message: strings("AppUtils.please_try_again"),
						type: SnackBarTypeOptions.ERROR,
					});
					onCancel();
				}
			}, () => {
				AppUtils.showToast({
					message: strings("AppUtils.please_try_again"),
					type: SnackBarTypeOptions.ERROR,
				});
				setLoading(false);
				onCancel();
			});
		}
	};

	useEffect(() => {
		if (newlyGeneratedStreamId) {
			handleSubmit();
		}
	}, [newlyGeneratedStreamId]);

	const renderScreenSubtitle = () => {
		if (streamId || streamObj) {
			return (
				<div>
					<div className="flex flex-row">
						<span className="text-gray-dark font-medium text-xs uppercase">
							{strings("ExpertiseEditScreen.stream_credo_tags")}
							<span className="text-primary font-medium text-xs uppercase">
								{` (${selectedLocal.filter((e: any) => e.name !== "").length}/${tagLimit})`}
							</span>
						</span>
					</div>
					<span className="text-gray-dark font-medium text-xs uppercase">
						{strings("ExpertiseEditScreen.Set_tags_that_define_this_community")}
					</span>
				</div>
			);
		}
		return (
			<div className="flex flex-row">
				<span className="text-gray-dark font-medium text-xs uppercase">
					{strings("ExpertiseEditScreen.credo_tags")}
				</span>
			</div>
		);
	};

	return (
		<div className="h-[calc(100vh-100px)]">
			{/* {!streamObj && (
				<div className="flex flex-row items-center w-full">
					<SvgIcon
						icon={BackIcon}
						className={classNames(isMobile ? "ml-4" : "ml-2", "cursor-pointer")}
						color="var(--text-base)"
						onClick={showConfirmationModal}
					/>
					<h4
						className={classNames(
							"flex-1 text-md font-normal text-basic px-3",
						)}
					>
						{strings("ExpertiseEditScreen.edit_expertise")}
					</h4>
					<div className="flex justify-end items-center flex-row bg-background flex-1 sm:pr-0 2xs:pr-3">
						<PrimaryButton
							size={ButtonSize.SMALL}
							label={strings("UserProfile.done")}
							onClick={handleSubmit}
							buttonClassNames="!text-sm !font-normal"
							dimensionsClasses="sm:w-36 h-7 2xs:w-24"
						/>
					</div>
				</div>
			)} */}
			{!streamObj || (streamObj && selectedLocal?.length > 0)
				? (
					<div className={classNames("bg-background overflow-auto h-full w-full pt-5 sm:px-2 2xs:px-4")}>
						{renderScreenSubtitle()}
						<div className="h-px mt-3 mb-5 bg-background-tertiary" />
						{renderSelectedTagBar()}
						<div className="border border-r-0 border-l-0 border-b-0 border-background " />
						{
							streamObj && selectedLocal?.length > 0 && (
								<div className="flex items-center justify-center mt-8 mb-4">
									<PrimaryButton
										size={ButtonSize.MEDIUM}
										label={strings("NewStreamSettingsScreen.create")}
										disabled={selectedLocal?.length === 0 || loading || !isValid}
										handleClick={createNewStream}
										isLoading={loading}
									/>
								</div>
							)
						}
					</div>
				) : (
					<div className="w-full h-full flex flex-col justify-center items-center pt-10">
						<div className="text-center">
							<FeedNoPost />
						</div>
						<div className="text-sm text-basic text-center pt-4 w-4/5 ml-4">
							{strings("ExpertiseEditScreen.limit_tag_message")}
						</div>
						<div className="mt-8">
							<PrimaryButton
								size={ButtonSize.MEDIUM}
								label={strings("ExpertiseEditScreen.add_tags")}
								onClick={() => { setShowExpertiseModal(true); }}
							/>
						</div>
					</div>
				)}
			{showExpertiseModal && (
				<ExpertiseSearchModal
					show={showExpertiseModal}
					onClose={() => {
						setShowExpertiseModal(false);
					}}
					tagLimit={tagLimit}
					updateUnselectedTagsProp={handleUpdateTagsByModal}
					selectedTags={selectedLocal}
					emptySearchText={strings("ExpertiseModal.skills_search_help_text")}
					searchNotFoundText={strings("ExpertiseModal.no_result_message")}
				/>
			)}
			<ConfirmationModal
				isVisible={openConfirmationModal}
				onClose={closeConfirmationModal}
				cancel_button_function={closeConfirmationModal}
				ok_button_title={strings("ExpertiseEditScreen.discard")}
				cancel_button_title={strings("ExpertiseEditScreen.cancel")}
				ok_button_function={onCancel}
				title={strings("ExpertiseEditScreen.title")}
				message={strings("ExpertiseEditScreen.confirmation_message")}
				okButtonStyle=""
				cancelButtonStyle=""
			/>
		</div>
	);
}

ExpertiseEditScreen.defaultTags = {
	streamId: null,
};
