import {
	CredoTagSource,
	DeleteIcon,
	EditPostIcon,
	MoreMenuInterface,
	PostCompactView, ReportIcon,
	ShareIcon,
	PostHeaderScreenType, classNames,
} from "@credo/ui-components";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
	AppMode, BoostBoardItem, cu, EvtMgr, ThemeOptions, YesNoOptions,
} from "@credo/utilities";
import {
	atom, useAtom, useAtomValue, useSetAtom,
} from "jotai";
import { isCredoModeAtom } from "@credo/store";
import cloneDeep from "lodash/cloneDeep";
import {
	AnalyticEventsConst, AppUtils, EventConst, TAG_MESSAGES,
} from "../../../utils";
import { FeedItem } from "../requests";
import { useDebouncedCallback } from "../../../utils/hooks/useDebouncedCallback";
import { UserActions } from "../../../services/actions/user";
import {
	sharedPostDetailsAtom, topFeedDataMapAtom, userProfileAtom,
} from "../../../utils/atoms";
import { strings } from "../../../i18n/config";
import { ExpertiseActions, ExpertiseTypes, ItemType } from "../../../utils/types";
import { sharePost } from "../../post/post-utils";
import { TrayWrapper } from "./TrayWrapper";

interface BoostBoardPostProps {
	postUuid: string
}
/**
 * TODO: Update emoji data
 * */
const BoostBoardPostComponent: React.FC<BoostBoardPostProps> = (props: BoostBoardPostProps) => {
	const {
		postUuid,
	} = props;

	const userProfile = useAtomValue(userProfileAtom);
	const isCredoMode = useAtomValue(isCredoModeAtom);
	const setSharedPostDetails = useSetAtom(sharedPostDetailsAtom);
	const itemAtom = useMemo(() => atom(
		(get) => get(topFeedDataMapAtom).data?.get(postUuid),
		(get, set, arg: BoostBoardItem) => {
			const prevMain = get(topFeedDataMapAtom);
			const prev: Map<string, BoostBoardItem> = get(topFeedDataMapAtom).data;
			// @ts-ignore
			prev?.set(postUuid, arg);
			set(topFeedDataMapAtom, { ...prevMain, data: prev });
		},
	), [postUuid]);
	const [item, setItem] = useAtom(itemAtom);

	const navigate = useNavigate();

	if (!item) {
		return null;
	}

	const navigateToDetailsScreen = useCallback((isShared: boolean, item: FeedItem) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postViewComments,
		});
		AppUtils.navigateToDetailsScreen(isShared, item, navigate);
	}, []);

	const onCommentIconClick = useCallback(() => {
		navigateToDetailsScreen(false, item);
	}, [item]);

	const navigateToPostDetailsScreen = useCallback((isShared: boolean) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postVisitPostDetails,
		});
		navigateToDetailsScreen(isShared, item);
	}, [item]);

	// TODO: Update type
	const onEmojiClick = useDebouncedCallback((data: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEmojiSelected,
		});
		const request = {
			prof_id: (isCredoMode ? userProfile?.cprof_id : userProfile?.eprof_id) ?? "",
			mode: cu.getAppMode(),
			postUuid: data.postUuid,
			action: data.action,
			code: data.code,
		};
		UserActions.mngEmocons4Post(request);
	}, 200);

	const onSharePostIconClick = useCallback(() => {
		AppUtils.authenticatedAction(() => {
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.postResharePost,
			});
			setSharedPostDetails(item);
			AppUtils.handleNewPostModal(true);
		});
	}, [item]);

	const changeMode = useCallback((mode: boolean) => {
		AppUtils.toggleTheme(mode ? ThemeOptions.CREDO : ThemeOptions.EGO);
	}, []);

	const onTagRate = useCallback((
		data: {
			tag: string,
			rating: number,
			tagSource: CredoTagSource,
		},
	) => {
		const { tag, rating, tagSource } = data;
		switch (tagSource) {
			case CredoTagSource.POST:
				UserActions.onTagRate(tag, rating, item["p.postUuid"]);
				break;

			case CredoTagSource.AUTHOR_POST: {
				const request = {
					user_id: userProfile?.user_id,
					mode: AppMode.EGO,
					itemType: ItemType.USER,
					action: ExpertiseActions.RATE,
					xpType: ExpertiseTypes.UXP_EXPT,
					uxpcode: tag,
					rating: rating ?? 0,
					isCredoNetRating: YesNoOptions.NO,
					dest_mode: AppMode.EGO,
					dest_prof_id: item["p.prof_id"] || "",
				};
				UserActions.rateUser4XpItem(request);
				break;
			}

			default: break;
		}
		const clonedItem = cloneDeep(item);
		if (clonedItem) {
			let tags = clonedItem?.sortedPostTags;
			if (tagSource === CredoTagSource.AUTHOR_POST) {
				tags = clonedItem?.authorTags;
			}
			const updatedPostTags = AppUtils.updateTagRating(tags, tag, rating);
			const updatedItem = {
				...clonedItem,
				[tagSource === CredoTagSource.AUTHOR_POST ? "authorTags" : "sortedPostTags"]: updatedPostTags,
			};
			// TODO: Update the atom directly
			setItem(updatedItem);
		}
	}, [item]);

	const navigateToUserOrStream = useCallback((data: {
		type: PostHeaderScreenType,
		mode: AppMode,
		profId: string,
		userName: string,
		egousername: string,
		meta: {
			title: string,
		},
	}) => {
		AppUtils.navigateToUserOrStream({
			isCredoMode,
			userProfile,
			navigate,
			...data,
		});
	}, [isCredoMode, userProfile]);

	const onDeletePostOptionPress = useCallback(() => {
		EvtMgr
			.getInstance(EventConst.boostBoardDeleteModal)
			.notifyListeners({
				selectedPost: item,
			});
	}, [item]);

	const onEditPost = useCallback(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEdit,
		});
		AppUtils.handleDownloadAppModal(true);
	}, [item]);

	const onFlagAsInAppPress = useCallback(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postReport,
		});
		EvtMgr
			.getInstance(EventConst.boostBoardDeleteModal)
			.notifyListeners({
				selectedPost: item,
			});
	}, [item]);

	const getMenuList = useCallback(() => {
		const menuList: MoreMenuInterface[] = [
			{
				title: strings("PostView.share"),
				icon: <ShareIcon stroke="var(--gray-dark)" />,
				onClick: () => { sharePost(item); },
			},
		];

		if (AppUtils.allowUserToUpdateORDeleteItem(isCredoMode, item.p_edit, item["p.mode"])) {
			menuList.push(
				{
					title: strings("PostView.edit_post"),
					icon: <EditPostIcon stroke="var(--gray-dark)" />,
					onClick: onEditPost,
				},
			);
		}

		if (AppUtils.allowUserToUpdateORDeleteItem(isCredoMode, item.p_edit, item["p.mode"]) || item?.isAdmin) {
			// isAdmin: we don't differentiate credo vs ego for deleting a post for an admin,
			// this is to simplify the UX so the user doesn't need to switch modes.
			// If a user is admin or super admin in one mode, isAdmin will be true for both modes
			menuList.push(
				{
					title: strings("PostView.delete_post"),
					icon: <DeleteIcon stroke="var(--gray-dark)" />,
					onClick: onDeletePostOptionPress,
				},
			);
		}

		if (item && !AppUtils.isCrtUserProfId(item["p.prof_id"], userProfile)) {
			menuList.unshift({
				title: strings("PostView.flag_as_inappropriate"),
				icon: <ReportIcon stroke="var(--gray-dark)" />,
				onClick: () => AppUtils.authenticatedAction(onFlagAsInAppPress),
			});
		}

		return menuList;
	}, [item]);

	return (
		<div
			className={classNames(
				"flex flex-row w-full min-h-[158px] mobile-compact:min-h-[192px]",
				"pt-2 mobile-compact:pt-4 mobile-compact:pb-2",
			)}
		>
			<PostCompactView
				item={item}
				onCommentIconClick={onCommentIconClick}
				onEmojiClick={onEmojiClick}
				onSharePostIconClick={onSharePostIconClick}
				isUserOwnPost={AppUtils.isCrtUserProfId(item["p.prof_id"], userProfile)}
				onModeChange={changeMode}
				userProfile={userProfile}
				onTagRate={onTagRate}
				postMsgs={{
					imageErrorMsg: strings("PostView.imageErrorMsg"),
					contentSensitiveMsg: strings("PostView.contentSensitiveMsg"),
					cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
					switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
					sharedPostDeleted: strings("PostView.sharedPostDeleted"),
				}}
				navigateToUserOrStream={navigateToUserOrStream}
				moreMenuList={getMenuList()}
				showLoginModal={AppUtils.handleLoginModal}
				navigateToDetailsScreen={navigateToPostDetailsScreen}
				onPressTagInfoBubble={AppUtils.showRatingTagTutorial}
				onPressCredoGraphText={AppUtils.showCredoGraph}
				messages={{
					postTagMessages: {
						...TAG_MESSAGES,
						rating_info: strings("TagMessages.rating_info_post"),
						rate_this_item: strings("TagMessages.rate_this_post"),
					},
					postHeaderTagMessages: {
						...TAG_MESSAGES,
						rating_info: strings("TagMessages.rating_info_author"),
						rate_this_item: strings("TagMessages.rate_this_author"),
					},
				}}
			/>
			<div
				className="relative right-0 w-0 mobile-compact:w-10 z-[12]"
				style={{
					clipPath: "inset(0px 0px 0px -300px)",
				}}
			>
				<TrayWrapper item={item} />
			</div>
		</div>
	);
};

const compareFunction = (
	nextProps: BoostBoardPostProps, prevProps: BoostBoardPostProps,
) => JSON.stringify(nextProps.postUuid) === JSON.stringify(prevProps.postUuid);

// eslint-disable-next-line import/prefer-default-export
export const BoostBoardPost = React.memo(
	BoostBoardPostComponent,
	compareFunction,
);
