import React, {
	useMemo, useRef, useState,
} from "react";
import {
	classNames,
	SvgIcon,
	Spinner,
	SpinnerLeafType,
	MoreSubCommentEgoIcon,
	MoreSubCommentCredoIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import {
	cu,
	useEvtMgrListener,
} from "@credo/utilities";
import isNil from "lodash/isNil";
import { isCredoModeAtom } from "@credo/store";
import { useNavigate } from "react-router-dom";
import { CommentCurrentScreen, CommentItem } from "../../utils/comments/types";
import { PostComment } from "../../components/Comments/PostComment";
import { formatPostDetailSubComment } from "../../utils/comments/CommentUtils";
import {
	isConnectedAtom, userIdAtom,
} from "../../utils/atoms";
import { AppUtils, Consts } from "../../utils";
import { fetchComments } from "./PostRequest";

interface PostCommentWithSubcommentsProps {
	commentItem: CommentItem;
	isSubComment: boolean;
	isReplyAllowed: boolean;
	postItem: any;
	// eslint-disable-next-line react/require-default-props
	currentScreen?: CommentCurrentScreen;
}

export default function PostCommentWithSubcomments({
	commentItem,
	isSubComment,
	isReplyAllowed,
	postItem,
	currentScreen,
}: PostCommentWithSubcommentsProps) {
	const [userId] = useAtom(userIdAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isSubComLoading, setIsSubComLoading] = useState(false);
	const [nbOfItemsFetched, setNbOfItemsFetched] = useState(commentItem?.subcomms?.length || 0);
	const page = useRef(1);
	const navigate = useNavigate();

	const isSubComPresent = useMemo(() => commentItem?.subcomms
		&& commentItem?.subcomms.length > 0
		&& commentItem?.subcomms[0].s_commentUuid !== null,
	[commentItem, isCredoMode]);

	const loadMoreSubComments = () => {
		if (isConnected) {
			setIsSubComLoading(true);
			let lastTs = 0;
			if (commentItem?.subcomms && commentItem?.subcomms.length > 0) {
				lastTs = commentItem?.subcomms?.[0].creatTs ?? 0;
			}
			page.current += 1;
			const request = {
				page: page.current,
				seed: 2,
				fetchSize: 5,
				targetUserOrProfId: userId ?? "",
				postUuid: postItem["p.postUuid"],
				scope: Consts.scope_parentComments,
				commentUuid: commentItem.commentUuid ?? "",
				lastTs,
				lastId: 0,
				user_id: userId ?? "",
				mode: cu.getAppMode(),
				APIVersion: "v1",
			};
			page.current += 1;
			fetchComments(request, null);
		}
	};

	const onPressMoreSubCommIcon = () => {
		if (currentScreen !== CommentCurrentScreen.PostDetails) {
			AppUtils.navigateToDetailsScreen(false, postItem, navigate);
		} else {
			loadMoreSubComments();
		}
	};

	const handleSubComments = (msg: any) => {
		if (AppUtils.msgHasItems(msg)) {
			const { items } = msg;
			const formattedSubComment = formatPostDetailSubComment(items[0]?.subcomms);
			commentItem.subcomms?.unshift(...formattedSubComment);
			setNbOfItemsFetched(nbOfItemsFetched + items[0].subcomms.length);
			setIsSubComLoading(false);
			if (commentItem.nb_of_subco === nbOfItemsFetched + items[0].subcomms.length) {
				page.current = 1;
			}
		} else {
			setIsSubComLoading(false);
		}
	};

	useEvtMgrListener(`fetchSubComments_${commentItem.commentUuid}`, handleSubComments);

	const renderLoadMoreSubCommentText = (commentsArray: any, index: number) => {
		const subCommentCount = commentItem?.nb_of_subco ?? 0;
		if (index === 0 && commentsArray?.length < subCommentCount && subCommentCount > nbOfItemsFetched) {
			if (isSubComLoading) {
				return (
					<div className="flex items-center justify-center pb-2.5">
						<Spinner
							leaf_shape={SpinnerLeafType.CIRCLES}
							width="20px"
							height="20px"
							leaf_fill="var(--primary)"
						/>
					</div>
				);
			}
			return (
				<div
					className={classNames(
						"flex flex-row items-center justify-center cursor-pointer",
						"pb-2.5 -mt-1.5",
					)}
				>
					{/* <SecondaryButton
						showImage
						imageComp={(
							<SvgIcon
								icon={MoreCommentsIcon}
							/>
						)}
						size={ButtonSize.SMALL}
						disabled={false}
						label=""
						dimensionsClasses="px-3 h-4"
						buttonClassNames="rounded-full justify-center items-center"
						handleClick={onPressMoreSubCommIcon}
					/> */}
					<SvgIcon
						icon={isCredoMode ? MoreSubCommentCredoIcon : MoreSubCommentEgoIcon}
						onClick={onPressMoreSubCommIcon}
					/>
				</div>
			);
		}
		return null;
	};

	const renderPostSubComments = () => {
		const commentsArray = commentItem?.subcomms; // array of arrays of commentUuid, comment txt, subcomment txt
		if (isNil(commentsArray) || commentsArray.length === 0) return null;
		return (
			<div className="bg-background">
				{commentsArray.map((commentInfo: any, index: number) => {
					if (cu.isSet(commentInfo.commentUuid)) {
						return (
							<div key={commentInfo.commentUuid}>
								{renderLoadMoreSubCommentText(commentsArray, index)}
								<PostComment
									parentCommentData={commentItem}
									postData={postItem}
									commentData={commentInfo}
									isSubComment
									numberOfLines={3}
									isReplyAllowed
									isSubCommentPresent={false}
									currentScreen={currentScreen}
								/>
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};

	return (
		<div className="flex flex-col w-full">
			<PostComment
				postData={postItem}
				commentData={commentItem}
				isSubComment={isSubComment}
				isReplyAllowed={isReplyAllowed}
				numberOfLines={3}
				isSubCommentPresent={isSubComPresent ?? false}
				currentScreen={currentScreen}
			/>
			{renderPostSubComments()}
		</div>
	);
}
