import React, { useCallback, useMemo, useState } from "react";
import { BoostTray, BoostTrayProps } from "../../boost";
import { classNames, ScalerSize, SvgIcon } from "../../common";
import { CredoTag, NullCredoTagScoreData } from "../credo-tag";
import { createDataProvider } from "../credo-tag.composition";
import { InfoCircleIcon } from "../../assets/icons";
import { ButtonSize, SecondaryButton } from "../../button";
import styles from "./boost-content.module.css";

interface BoostContentProps {
	messages: {
		boost_this_post: string;
		boost_info: string;
		view_tutorial: string;
	}
	tag: string;
	boostTrayProps: BoostTrayProps;
}

// eslint-disable-next-line import/prefer-default-export
export const BoostContent: React.FC<BoostContentProps> = (props: BoostContentProps) => {
	const {
		messages,
		tag,
		boostTrayProps,
	} = props;
	const [showInfo, setShowInfo] = useState<boolean>(false);

	const handleIconClick = useCallback(() => {
		setShowInfo((prevState) => !prevState);
	}, []);

	const handleClickViewTutorial = useCallback(() => {

	}, []);

	const renderTagText = useMemo(() => (
		<div
			className={classNames(
				"flex flex-col xs:w-[120px]",
				"justify-center",
			)}
		>
			<p className="text-off-white font-pt-sans text-lg tracking-[0.138462px] font-normal">
				{messages.boost_this_post}
				{" "}
				<span>
					<CredoTag
						id={`creed-selector-tag-${tag}`}
						tag={tag}
						size={ScalerSize.S}
						onRate={() => {}}
						dataProvider={createDataProvider(50, NullCredoTagScoreData)}
						isUserOwnPost={false}
						isCredoMode={false}
						// @ts-ignore
						messages={null}
						disable
					/>
				</span>
			</p>
		</div>
	), [tag]);

	const renderInfo = useMemo(() => (
		<div
			className={classNames(
				styles.boostInfoText,
				"rounded-lg mt-1 relative w-full",
				showInfo ? "bg-credo-black md:max-h-[86px]" : "",
			)}
		>
			<SvgIcon
				icon={InfoCircleIcon}
				width={18}
				height={18}
				className="cursor-pointer"
				onClick={handleIconClick}
			/>
			{showInfo && (
				<React.Fragment>
					<p className="text-off-white text-[11px] leading-[14px] font-pt-sans p-1.5">
						{messages.boost_info}
					</p>
					<div className="w-full flex justify-end items-center px-2">
						<SecondaryButton
							onClick={handleClickViewTutorial}
							dimensionsClasses="h-[18px] rounded-[16px] text-xs font-light !text-accent !lowercase px-2"
							size={ButtonSize.SMALL}
							label={messages.view_tutorial}
							buttonClassNames="!border-accent"
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	), [messages, showInfo, handleClickViewTutorial]);

	return (
		<div
			className={classNames(
				" h-[260px]",
				styles.boostContentGrid,
			)}
		>
			{renderTagText}
			<BoostTray {...boostTrayProps} />
			<div />
			{renderInfo}
		</div>
	);
};
