/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from "react";
import {
	cu, EmojiItem, EmotionsType, useWindowSize,
} from "@credo/utilities";
import isNil from "lodash/isNil";
import { useAtomValue } from "jotai";
import { emojiDataAtom } from "@credo/store";
import { classNames, Consts, SvgIcon } from "../../common";
import { Tooltip } from "../../tooltip";
import { CommentEmotionIcon, PostEmojiIcon } from "../../assets/icons";

export interface EmotionsTooltipProps {
	/*
	*	Sets post data of the feed
	*/
	postData: any;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	onEmojiClick: (data: any) => void;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	/*
	*	Gives current app mode
	*/
	isCredoMode: boolean;
	/*
	*	Sets a flag to show login modal
	*/
	showLoginModal: (show: boolean) => void;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	onClickEmojiTooltip?: () => void;
	/*
	*	Gives whether emoticons is for post or comments
	*/
	type: EmotionsType;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	isCompactView?: boolean;
}

export const EmotionsTooltip = (props: EmotionsTooltipProps) => {
	const {
		postData,
		onEmojiClick,
		isCredoMode,
		showLoginModal,
		onClickEmojiTooltip,
		type,
		isCompactView,
	} = props;
	const [showTooltip, setShowTooltip] = useState(false);
	// Gives static emojis data to be render on emoji tooltip
	const emojiDataFromAtom = useAtomValue(emojiDataAtom);
	const [emojis, setEmojis] = useState<EmojiItem[]>(emojiDataFromAtom);
	const [emojiTotalCount, setEmojiTotalCount] = useState(postData.nb_of_emo ? postData.nb_of_emo : 0);
	const [selectedEmoji, setSelectedEmoji] = useState<any[]>([]);
	const [topRatedEmoji, setTopRatedEmoji] = useState<any[]>([]);
	const { width } = useWindowSize();

	const isCommentEmoji = type === EmotionsType.COMMENT;

	const iconDimensions = useMemo(() => {
		if (isCompactView && (width <= 540)) {
			return {
				height: 15,
				width: 15,
			};
		}
		if (!isCommentEmoji) {
			return {
				height: 16,
				width: 16,
			};
		}
		return {};
	}, [isCompactView, width, isCommentEmoji]);

	const openEmojiTooltip = () => {
		if (cu.isSet(cu.getGlobalVar(Consts.sess))) {
			setShowTooltip(true);
			if (onClickEmojiTooltip) onClickEmojiTooltip();
		} else if (showLoginModal) {
			showLoginModal(true);
		}
	};

	const updateEmojisData = (data: any) => {
		const arr = JSON.parse(JSON.stringify(data));
		if (arr && arr.length > 0 && !isNil(postData.emoCountA)) {
			arr.forEach((e: any) => {
				postData.emoCountA.forEach((a: any) => {
					if (e.code.includes(a[0])) {
						e.selected = false;
						e.value = a[1] ? a[1] : 0;
						if (postData.emoCode && postData.emoCode.length > 0) {
							postData.emoCode.forEach((i: any) => {
								if (i[0] === null) {
									e.selected = false;
									e.value = a[1] ? a[1] : 0;
								} else if (cu.getAppMode() === i[1]) {
									if (e.code.includes(i[0])) {
										if (e.selected === false) {
											e.selected = true;
											e.value = a[1] ? a[1] : 0;
										}
									} else {
										e.selected = false;
										e.value = a[1] ? a[1] : 0;
									}
								} else if (a[0] === i[0] && i[1] === cu.getAppMode()) {
									e.selected = false;
									e.value = a[1] ? a[1] : 0;
								} else if (a[0] === i[0]) {
									e.value = a[1] ? a[1] : 0;
								}
							});
						}
					}
				});
			});
		}
		let emoValuesArray: any[] = [];
		emoValuesArray = arr.sort((a: any, b: any) => b.value - a.value).slice(0, 7);
		const emoValues: any[] = [];
		arr.forEach((a: any) => {
			emoValuesArray.forEach((e) => {
				if (e.code.includes(a.code)) {
					emoValues.push(a.value);
				}
			});
		});
		const updatedTotalCount = emoValues.reduce((a, b) => a + b, 0);
		if (emojiTotalCount !== updatedTotalCount) {
			setEmojiTotalCount(updatedTotalCount);
		}

		// selected emoji by current user
		const selectedEmojiElement: any[] = [];
		if (!isNil(postData.emoCode) && postData.emoCode.length > 0) {
			data.forEach(((emos: any) => {
				postData.emoCode.forEach((a: any) => {
					if (cu.getAppMode() === a[1]) {
						if (emos.code === a[0]) {
							selectedEmojiElement.push(emos);
						}
					}
				});
			}));
		}
		setSelectedEmoji(selectedEmojiElement);

		// top rated emojis
		emoValuesArray = emoValuesArray.filter((item) => (item.value > 0));
		const tempTopEmojis = emoValuesArray.slice(0, type === EmotionsType.POST ? 3 : 4);
		if (tempTopEmojis?.length === 1
				&& selectedEmojiElement?.length === 1
					&& tempTopEmojis[0]?.code === selectedEmojiElement[0]?.code
		) {
			setTopRatedEmoji([]);
		} else {
			setTopRatedEmoji(tempTopEmojis);
		}

		setEmojis(arr);
		setShowTooltip(false);
	};

	const removeEmptyValues = (data: any) => {
		const emoCode = [...data];
		emoCode.forEach((t) => {
			if (t[0] === null && t[1] === null) {
				const index = emoCode.indexOf(t);
				if (index > -1) {
					emoCode.splice(index, 1);
				}
			}
		});
		return emoCode;
	};

	const onEmojiPress = (selectedEmoji: EmojiItem) => {
		let updatedEmocode: any[] = [];
		const updatedEmojis = [...emojis];
		let action = "";
		let code = "";
		updatedEmojis.forEach((item: EmojiItem) => {
			// const newItem = item;
			if (item.code === selectedEmoji.code) {
				if (item.selected) {
					// removing emoji reaction count in tooltip
					code = item.code;
					action = "d";
					item.selected = false;
					item.value -= 1;
					postData.emoCode.forEach((a: any) => {
						if (cu.getAppMode() !== a[1]) {
							updatedEmocode.push(a);
						}
					});
					updatedEmocode = removeEmptyValues(updatedEmocode);
					if (onEmojiClick) {
						onEmojiClick({
							prevEmoCode: postData.emoCode ? postData.emoCode : null,
							emoCode: updatedEmocode ? updatedEmocode.slice(0, 2) : [],
							nb_emocons: item.value ? item.value : 0,
							nb_of_emo: !isNil(emojiTotalCount) ? emojiTotalCount - 1 : 0,
							postUuid: postData["p.postUuid"],
							action,
							code,
							emoji: selectedEmoji.emoji,
						});
						// setEmojiTotalCount(emojiTotalCount - 1);
					}
				} else {
					// adding emoji reaction count in tooltip
					action = "up";
					code = item.code;
					item.selected = true;
					item.value += 1;
					if (postData.emoCode && postData.emoCode.length > 0) {
						postData.emoCode.forEach((a: any) => {
							if (cu.getAppMode() === a[1]) {
								updatedEmocode.push([item.code, a[1]]);
							} else {
								updatedEmocode.push(a, [item.code, cu.getAppMode()]);
							}
						});
					} else {
						updatedEmocode.push([item.code, cu.getAppMode()]);
					}
					updatedEmocode = removeEmptyValues(updatedEmocode);
				}
				onEmojiClick({
					prevEmoCode: postData.emoCode ? postData.emoCode : null,
					emoCode: updatedEmocode ? updatedEmocode.slice(0, 2) : [],
					nb_emocons: item.value ? item.value : 0,
					nb_of_emo: !isNil(emojiTotalCount) ? emojiTotalCount + 1 : 0,
					postUuid: postData["p.postUuid"],
					action,
					code,
					emoji: selectedEmoji.emoji,
				});
			}
		});
		setEmojis(updatedEmojis);
	};

	useEffect(() => {
		updateEmojisData(emojiDataFromAtom);
	}, [postData?.emoCode, isCredoMode, emojiDataFromAtom]);

	const renderEmojis = () => (
		<div
			className="flex w-60 min-h-[14rem] flex-wrap justify-center p-1 items-center
			rounded-lg opacity-95 bg-white shadow-no-rate-tooltip"
		>
			{emojis.map((item: any, i: number) => (
				<div
					// eslint-disable-next-line react/no-array-index-key
					key={i}
					className="flex flex-col p-1.5 items-center justify-start cursor-pointer"
					onClick={() => onEmojiPress(item)}
					aria-hidden
				>
					<p className="text-2xl">{item.emoji}</p>
					<p
						className={classNames(
							"text-sm",
							item.selected ? "text-primary" : "text-gray-dark",
						)}
					>
						{item.value}
					</p>
				</div>
			))}
		</div>
	);

	const renderTopEmojis = () => {
		if (topRatedEmoji && topRatedEmoji.length > 0) {
			return (
				<div
					className={classNames(
						"flex flex-row h-5 bg-transparent items-center",
						isCommentEmoji ? "mr-1 pl-2 rounded-tl-xl" : "mt-0.5",
					)}
					style={{
						zIndex: 5,
					}}
				>
					{topRatedEmoji.map((item: any, i: number) => (
						<p
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							className={classNames(
								"text-xs text-center",
								isCommentEmoji ? "-ml-1.5" : "",
								i === topRatedEmoji?.length - 1 && !isCommentEmoji ? "pr-1" : "",
							)}
							style={{
								zIndex: 5 - i,
								// @ts-ignore
								"text-shadow": i !== topRatedEmoji?.length - 1 ? "2px 0px 2px rgb(255, 255, 255, 1)" : "none",
							}}
						>
							{item.emoji}
						</p>
					))}
				</div>
			);
		}
		return null;
	};

	return (
		<div className="flex h-full items-center justify-center">
			<div
				className="flex h-7 items-center justify-center cursor-pointer"
				onClick={openEmojiTooltip}
				aria-hidden
			>
				<Tooltip
					content={renderEmojis()}
					placement="top"
					showArrow={false}
					toolTipAction="click"
					visible={showTooltip}
				>
					<div className={classNames(
						"flex flex-row items-center justify-center relative",
						isCommentEmoji && topRatedEmoji?.length === 0 ? "" : "px-1",
						isCompactView ? "h-6 mobile-compact:h-7" : "h-7",
					)}
					>
						<div className={classNames(
							"flex",
							isCommentEmoji ? "flex-row-reverse items-center" : "flex-row",
						)}
						>
							{selectedEmoji && selectedEmoji.length > 0
								? (
									<p
										className="flex cursor-pointer px-1 items-center justify-center text-base mr-1"
									>
										{selectedEmoji[0]?.emoji}
									</p>
								)
								: (
									<div
										className={classNames(
											"flex",
											isCommentEmoji && topRatedEmoji?.length === 0 ? "w-8 pt-0.5" : "",
											!isCommentEmoji && topRatedEmoji?.length > 0 ? "pt-0.5 mr-1" : "",
										)}
									>
										<SvgIcon
											icon={isCommentEmoji ? CommentEmotionIcon : PostEmojiIcon}
											title="Add Emoji"
											className="flex items-center justify-center"
											{...iconDimensions}
										/>
									</div>
								)}
							{renderTopEmojis()}
						</div>
						{/* {type === EmotionsType.POST
							&& (
								<p
									className={classNames(
										"text-primary pl-px",
										isCompactView ? "text-5xs mobile-compact:text-sm" : "text-base",
									)}
								>
									{" "}
									{cu.formatAsNbWithThousandsWithDefault(emojiTotalCount, 0)}
								</p>
							)} */}
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

EmotionsTooltip.defaultProps = {
	onClickEmojiTooltip: null,
	isCompactView: false,
};
