import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { UserActions } from "../services/actions/user";
import { isConnectedAtom, isSessionAuthAtom } from "../utils/atoms";

/**
 * Since we need permissions right after the app is mounted and connected to
 * the internet it's better to call it in a separate component and call the
 * permissions here because in App.tsx the useEffect will be called after
 * the complete tree is initialised.
 * */
// eslint-disable-next-line import/prefer-default-export
export const PermissionsDataProvider: React.FC = () => {
	const [isConnected] = useAtom(isConnectedAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);

	useEffect(() => {
		if (isConnected && isSessionAuth) {
			UserActions.getUserPermissions();
		}
	}, [isConnected, isSessionAuth]);
	return null;
};
