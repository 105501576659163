import React from "react";
import Style from "../BoostTray.module.css";

interface TrayHandleProps {
	expanded: boolean;
}

const TrayHandleComponent: React.FC<TrayHandleProps> = (
	props: TrayHandleProps,
) => {
	const {
		expanded,
	} = props;

	if (expanded) {
		return (
			<span className={Style.trayHandleWrapper}>
				{[32, 24, 16, 8, 4].map((height) => (
					<span
						style={{
							height,
						}}
						className="w-0.5 bg-dark-main rounded-full mr-px"
					/>
				))}
			</span>
		);
	}

	return (
		<span className={Style.trayHandleWrapper}>
			<span
				className="h-8 w-0.5 bg-dark-main absolute rounded-full"
			/>
		</span>
	);
};

const compareFn = (next: TrayHandleProps, prev: TrayHandleProps) => next.expanded === prev.expanded;

// eslint-disable-next-line import/prefer-default-export
export const TrayHandle = React.memo(
	TrayHandleComponent,
	compareFn,
);
