import * as React from "react"
import {SVGAttributes} from "react";

const SvgComponent = (props: SVGAttributes<SVGElement>) => (
	<svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<circle id="boosted-post-path-1" cx="5" cy="5" r="5"></circle>
			<filter x="-30.0%" y="-30.0%" width="160.0%" height="160.0%" filterUnits="objectBoundingBox" id="filter-2">
				<feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
				<feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
				<feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
			</filter>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="40%" y2="50%" id="linearGradient-boosted-post3" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="40%" y2="50%" id="linearGradient-boosted-post4" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="8.35442826e-13%" y1="50%" x2="40%" y2="50%" id="linearGradient-boosted-post5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="30%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosted-post6" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="30%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosted-post7" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="30%" y1="50%" x2="4.62130334e-12%" y2="50%" id="linearGradient-boosted-post8" gradientUnits="userSpaceOnUse">
				<stop stopColor="#000000" offset="0%"></stop>
				<stop stopColor="#00FF9E" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g id="Boosted-post-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Boosted-Post-Icon">
				<rect id="boosted-post-Rectangle-main" stroke="#0FF077" strokeWidth="0.8" fill="#212636" x="0.4" y="1.507526" width="13.2" height="15.2" rx="3.20000005"></rect>
				<g id="Boosted-post-CB-Icon---Default" transform="translate(3.000000, 4.000000)">
					<rect id="boosted-post-Rectangle-1" fill="#212636" x="0" y="0" width="9" height="10"></rect>
					<rect id="boosted-post-Rectangle-2" fill="#0FF077" x="0" y="8.46153846" width="9" height="1" rx="0.5"></rect>
					<rect id="boosted-post-Rectangle-3" fill="#0FF077" x="0" y="6.92307692" width="9" height="1" rx="0.5"></rect>
					<rect id="boosted-post-Rectangle-4" fill="#0FF077" x="0" y="5.38461538" width="9" height="1" rx="0.5"></rect>
					<rect id="boosted-post-Rectangle-5" fillOpacity="0.522372159" fill="#0FF077" x="0" y="2.30769231" width="9" height="1" rx="0.5"></rect>
					<rect id="boosted-post-Rectangle-6" fill="#0FF077" x="0" y="3.84615385" width="9" height="1" rx="0.5"></rect>
					<rect id="boosted-post-Rectangle-7" fill="#000000" x="0" y="0.769230769" width="9" height="1" rx="0.5"></rect>
				</g>
				<g id="Boosted-post-Group" transform="translate(7.000000, 7.000000)">
					<g id="Boosted-post-Oval">
						<use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#boosted-post-path-1"></use>
						<use fill="#0BE0D8" fillRule="evenodd" xlinkHref="#boosted-post-path-1"></use>
					</g>
					<g id="Boosted-post-CB-Icon---Default" transform="translate(1.000000, 1.000000)" strokeLinecap="round" strokeWidth="0.95904">
						<g id="boosted-post-l-bolt" transform="translate(1.333333, 0.615385)">
							<line x1="3" y1="0.307692308" x2="3.66666667" y2="0.307692308" id="Line-4" stroke="url(#linearGradient-boosted-post3)"></line>
							<line x1="1.66666667" y1="1.53846154" x2="3.66666667" y2="1.53846154" id="Line-4-Copy" stroke="url(#linearGradient-boosted-post4)"></line>
							<line x1="0.333333333" y1="2.76923077" x2="5" y2="2.76923077" id="Line-4-Copy-2" stroke="url(#linearGradient-boosted-post5)"></line>
							<line x1="0.333333333" y1="4" x2="5" y2="4" id="Line-4-Copy-2" stroke="url(#linearGradient-boosted-post6)"></line>
							<line x1="1.66666667" y1="5.23076923" x2="3.66666667" y2="5.23076923" id="Line-4-Copy-4" stroke="url(#linearGradient-boosted-post7)"></line>
							<line x1="1.66666667" y1="6.46153846" x2="2.33333333" y2="6.46153846" id="Line-4-Copy-5" stroke="url(#linearGradient-boosted-post8)"></line>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default SvgComponent
