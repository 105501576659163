import isNil from "lodash/isNil";
import { AppMode, PostTime, RegEx, ResponseCode, YesNoOptions, } from "../types";
import { Consts } from "./consts";
import {Logger} from "./Logger";

const moment = require("moment");

export const dbg = process.env.REACT_APP_CFG_DEBUG_ENABLED === "true";
/**
 * Credo Util class.
 * Contains all core utils.
 * */
export class cu {
	/**
	 * Checks if the value is set or undefined.
	 *
	 * @param {any} x The variable to be checked is empty/undefined.
	 * @return {boolean} boolean.
	 * */
	static isSet = (x: any): boolean => !isNil(x) && x !== "";

	/**
	 * Checks if the array passed is null or empty.
	 *
	 * @param {any} data The array to be checked if its null or empty.
	 * @return {boolean} boolean.
	 * @see isSet
	 * */
	static isNullOrEmptyArray(data: string | any[]): boolean {
		return !cu.isSet(data) || (cu.isSet(data) && data.length < 1);
	}

	/**
	 * Deletes the element from the array passed
	 *
	 * @param {any} val The element which needs to be deleted
	 * @param {Array<any>} arr The array from which the val element must be deleted
	 * */
	static deleteElemFromArray(val: any, arr: Array<any>) {
		if (!cu.isSet(arr)) { return; }
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === val) {
				arr.splice(i, 1);
				// eslint-disable-next-line no-plusplus
				i--;
			}
		}
	}

	// TODO: Write the what this does
	static getCircularReplacer() {
		const seen = new WeakSet();
		return (key: any, value: object | null) => {
			if (typeof value === "object" && value !== null) {
				if (seen.has(value)) {
					return "";
				}
				seen.add(value);
			}
			return value;
		};
	}

	/**
	 * Stringifies the variable which is passed.
	 *
	 * @param {any} x The variable which needs to be stringy.
	 * @return {string} A JSON string representing the given value, or undefined.
	 * @see JSON.stringify
	 * */
	static jsonify(x: any) {
		return JSON.stringify(x, cu.getCircularReplacer);
	}

	/**
	 * Checks the variable passed is "y"(Yes).
	 *
	 * @param {YesNoOptions} x The string "y" or "n" which needs to be checked.
	 * @return boolean.
	 * @see isNo
	 * */
	static isYes(x: YesNoOptions | string | null): boolean {
		return x === YesNoOptions.YES;
	}

	/**
	 * Checks the variable passed is "n"(No).
	 *
	 * @param {YesNoOptions} x The string "y" or "n" which needs to be checked.
	 * @return boolean.
	 * @see isYes
	 * */
	static isNo(x: YesNoOptions | string | null): boolean {
		return x === YesNoOptions.NO;
	}

	/**
	 * Checks the variable passed is true or false and returns in YesNoOptions.
	 *
	 * @param {boolean} x The boolean which needs to be converted into YesNoOptions.
	 * @return {YesNoOptions} "y" or "no".
	 * @see isYes
	 * @see isNo
	 * */
	static asYesNo(x: boolean): YesNoOptions {
		return x ? YesNoOptions.YES : YesNoOptions.NO;
	}

	/**
	 * Returns the value of the key which is saved in the localstorage.
	 *
	 * @param {string} fieldName The key whose value needs to be fetched.
	 *
	 * @return The value of the key saved in local storage.Will return null
	 * if nothing saved or key is not present
	 * @see setGlobalVar
	 * */
	static getGlobalVar(fieldName: string) {
		const value = localStorage.getItem(fieldName);
		if (value) {
			return typeof value === "string" ? JSON.parse(value) : value;
		}
		return null;
	}

	/**
	 * Stringifies and saves the value against the key in the localstorage.
	 * No need to stringify and pass the value, this function will take care
	 * of it and stringify the passed value and save it in the localstorage.
	 *
	 * @param {string} fieldName The key whose value needs to be fetched.
	 * @param {string} value The value which needs to be saved against the
	 * key in localstorage
	 *
	 * @see getGlobalVar
	 * */
	static setGlobalVar(fieldName: string, value: any) {
		localStorage.setItem(fieldName, JSON.stringify(value));
	}

	/**
	 * Returns true if the responded message is a success message
	 *
	 * @param {retcd: string} msg Response which needs to checked if its
	 * success or not. Response object must have `retcd` field in it.
	 * */
	static isSuccessMsg = (msg: { retcd: string }) => msg != null && msg.retcd
		&& (msg.retcd === ResponseCode.OK || msg.retcd === ResponseCode.SUCCESS || msg.retcd.startsWith(ResponseCode.OK_WITH));

	/**
	 * Get current application mode from localstorage. Returns "c" or "e" for
	 * Credo mode andEgo mode respectively. Will return credo by default if
	 * no mode is set/found.
	 *
	 * @example
	 * import { cu } from @credo/utilities
	 *
	 * // to get the app mode
	 * const mode = cu.getAppMode() // mode will be AppMode.CREDO or AppMode.EGO respective to the mode which app is in.
	 *
	 * @returns {AppendMode} The mode in which the app is currently in. "c"
	 * or "e", Credo or Ego respectively
	 * @see isSet, AppMode
	 * */
	static getAppMode() {
		const mode = cu.getGlobalVar(Consts.mode);
		if (cu.isSet(mode) && mode === AppMode.EGO) {
			return AppMode.EGO;
		} else {
			return AppMode.CREDO;
		}
	}

	/**
	 * Get current application mode from localstorage. Returns "true" or "false" for
	 * Credo mode and Ego mode respectively
	 *
	 * @returns {boolean} true for credo and false for ego
	 * */
	static getSwitchValue() {
		const mode = cu.getAppMode();
		return mode === AppMode.CREDO;
	}

	/**
	 * Gives the post time in user readable format
	 *
	 * @param {string} fromTime timeStamp value of the post
	 * @param {string} isWantDirectDate want direct date? default false
	 * @param {string} simplified need it simplified? default false
	 * @return {string} time user readable format
	 * */
	static calculatePostTime(fromTime: string, isWantDirectDate: boolean = false, simplified: boolean = false) {
		const postedDate = moment(fromTime).toDate();
		const currentDate = moment().toDate();
		if (!Number.isNaN(postedDate.getTime())) {
			const postedDateDiff = Math.abs(currentDate.getTime() - postedDate.getTime()) / 1000;
			if (!isWantDirectDate) {
				if (postedDateDiff < 60) {
					if (simplified) {
						return PostTime.Now;
					} else {
						return PostTime.PostedNow;
					}
				} if (postedDateDiff / 60 < 60) {
					if (simplified) {
						return Math.floor(postedDateDiff / 60) + PostTime.M;
					} else {
						return `${Math.floor(postedDateDiff / 60)} ${(PostTime.MinAgo)}`;
					}
				} if ((postedDateDiff / 60) / 60 < 24) {
					const yesterdayDate = moment().subtract(1, "day").toDate();
					if (yesterdayDate.getDate() === postedDate.getDate()) {
						if (simplified) {
							return PostTime.Ytd;
						} else {
							return PostTime.Yesterday;
						}
					} if (simplified) {
						return Math.floor((postedDateDiff / 60) / 60) <= 1
							? Math.floor((postedDateDiff / 60) / 60) + PostTime.H
							: Math.floor((postedDateDiff / 60) / 60) + PostTime.H;
					} else {
						return Math.floor((postedDateDiff / 60) / 60) <= 1
							? `${Math.floor((postedDateDiff / 60) / 60)} ${(PostTime.HourAgo)}`
							: `${Math.floor((postedDateDiff / 60) / 60)} ${(PostTime.HoursAgo)}`;
					}
				} if ((postedDateDiff / 60) / 60 >= 24 && (postedDateDiff / 60) / 60 < 48) {
					if (simplified) {
						return PostTime.Ytd;
					} else {
						return PostTime.Yesterday;
					}
				}
			}
			if (simplified) {
				return moment(postedDate).format("DD MMM YY");
			} else {
				return moment(postedDate).format("DD MMMM YYYY");
			}
		} else {
			return null;
		} // isNaN(postedDate.getTime())
	}

	/**
	 * Gives the source url of given image
	 *
	 * @param {string} bucketRelativeRefPath image relative path
	 * @return {string} source url of given image
	 * */
	static buildSourceUrlImage(bucketRelativeRefPath: string) {
		if (isNil(bucketRelativeRefPath) || typeof bucketRelativeRefPath !== "string"
			|| isNil(process.env.REACT_APP_cfg_imageStorage_path)
		) {
			return null;
		}
		const bucketRelativeRefPathURL = bucketRelativeRefPath.replace(/\//g, "%2F");
		const imageUrl = `${process.env.REACT_APP_cfg_imageStorage_path + bucketRelativeRefPathURL}?alt=media`;
		return imageUrl;
	}

	/**
	 * Gives formatted number with thousands separator
	 *
	 * @param {number} number absolute value number, cannot be negative
	 * @return {string} Formatted number with thousands separator
	 * o/p :-
	 * 10500 -> 10.5K
	 * 1000000 -> 1M
	*/
	static formatNumberWithThousandsSep(number: number, maintainCharacters?: boolean, numberOfCharacters?: number) {
		const lookup = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "K" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "B" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" }
		];
		let item = lookup.slice().reverse().find(function(item) {
			return number >= item.value;
		});

		const numberOfDigits = (item ? (number / item?.value) : 0).toString().indexOf(".");
		const fixedAfterDecimal = numberOfCharacters ?? 5 - numberOfDigits - 1

		const matcher = new RegExp(
			'^-?\\d+(?:\.\\d{0,' + (maintainCharacters ? fixedAfterDecimal : item?.symbol === "K" ? 2 : 1 || -1) + '})?'
		)

		return item ? (number / item.value)
			.toString().match(matcher)?.[0]
			+ item.symbol ?? "0" : "0";
	}

	/**
	 * Gives formatted number with thousands separator with default value
	 *
	 * @param {any} count count
	 * @param {any} defaultVal defaultVal
	 * @return {string} Formatted number with thousands separator
	 * */
	static formatAsNbWithThousandsWithDefault(count: any, defaultVal: any) {
		return count ? cu.formatNumberWithThousandsSep(count) : defaultVal || 0;
	}

	/**
	 * Checks whether passed URL is from youtube or now
	 *
	 * @param {string} url link
	 * @return {boolean} it's a youtube url or not
	 * */
	static isYouTubeUrl(url: string) {
		return RegEx.youtubeVideo.test(url);
	}

	/**
	 * Gives username and profId of the tagged user
	 *
	 * @param {string} tag having name, profid and username
	 * @return {object} tagData having profId and username
	 * We can only mention ego user in any comment and new post so default mode is set to ego
	 * */
	static getUserNameAndProfId(tag: string) {
		const tagData = {
			userName: "",
			profId: "",
			mode: "",
		};
		if (cu.isSet(tag)) {
			const profIdAndUserNameA = tag?.split(",");
			tagData.profId = profIdAndUserNameA?.[0] ?? "";
			tagData.userName = profIdAndUserNameA?.[1] ?? "";
			tagData.mode = profIdAndUserNameA?.[2] ?? AppMode.EGO;
		}
		return tagData;
	}

	/**
	 * Copy a string to clipboard
	 * @param  {String} string         The string to be copied to clipboard
	 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
	 * @see https://stackoverflow.com/a/53951634/938822
	 */
	static async copyToClipboard(string: string) {
		let textarea;
		let result;

		try {
			textarea = document.createElement("textarea");
			textarea.setAttribute("readonly", "true");
			textarea.setAttribute("contenteditable", "true");
			textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
			textarea.value = string;

			document.body.appendChild(textarea);

			textarea.focus();
			textarea.select();

			const range = document.createRange();
			range.selectNodeContents(textarea);

			const sel = window.getSelection();
			sel?.removeAllRanges();
			sel?.addRange(range);

			textarea.setSelectionRange(0, textarea.value.length);
			result = document.execCommand("copy");
		} catch (err) {
			console.error(err);
			result = null;
		} finally {
			// @ts-ignore
			document.body.removeChild(textarea);
		}

		// manual copy fallback using prompt
		if (!result) {
			// const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
			// const copyHotkey = isMac ? "⌘C" : "CTRL+C";
			// result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
			if (!result) {
				if (dbg) {
					// Started using new logger due to circular dependency
					Logger.debug("Credo Utils: ", "copyToClipboard error result:", result);
				}
				return false;
			}
		}
		return true;
	};

	static secondsToDhms(seconds: number) {
		const d = Math.floor(seconds / (3600 * 24));
		const h = Math.floor((seconds % (3600 * 24)) / 3600);
		const m = Math.floor((seconds % 3600) / 60);
		const s = Math.floor(seconds % 60);

		const dDisplay = d > 0 ? `${d}d ` : "";
		const hDisplay = h > 0 ? `${h}h ` : "";
		const mDisplay = m > 0 ? `${m}m ` : "";
		const sDisplay = s > 0 ? `${s}s ` : "";
		return dDisplay + hDisplay + mDisplay + sDisplay;
	}

}
