import React, { useEffect, useRef, useState } from "react";
import {
	AddIcon, classNames, EngageCommunitiesSkeleton, NoContentIcon, Spinner, SpinnerLeafType, SvgIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isMobile } from "react-device-detect";
import { AppMode, cu } from "@credo/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import { isCredoModeAtom } from "@credo/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useMeasure, useWindowSize } from "react-use";
import {
	EngageTabOptions, EntityType, StreamItem,
} from "../../../utils/types";
import { EngageActions } from "../../../services/actions/engage";
import {
	isConnectedAtom, userIdAtom, userMetaDataAtom,
} from "../../../utils/atoms";
import { strings } from "../../../i18n/config";
import { ExplainComponent } from "../../../components/ExplainComponent";
import { AppUtils, ClientMetaDataMapping, Consts } from "../../../utils";
import { UserActions } from "../../../services/actions/user";
import CfgMgr from "../../../config/CfgMgr";
import UserCommunityItem from "./UserCommunityItem";
import { CREATE_STREAM, ENGAGE_PAGE } from "../../../routes/constants";
import { HelmetComponent } from "../../../components/HelmetComponent";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

const MyCommunitiesPage: React.FC = () => {
	const navigate = useNavigate();
	const [isConnected] = useAtom(isConnectedAtom);
	const [clientMetadata, setClientMetaData] = useAtom(userMetaDataAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userId] = useAtom(userIdAtom);
	const [data, setData] = useState<StreamItem[]>([]);
	const [isLastPage, setIsLastPage] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const dataPage = useRef(1);
	const dataSeed = useRef(1);
	const location = useLocation();
	const [divRef, { width }] = useMeasure();
	const { width: windowWidth } = useWindowSize();

	useSetAppHeader({
		title: strings("AppHeader.my_communities"),
	});

	const fetchUserStreams = async (inProcessSetter: (inProcess: boolean) => void, op: "append" | "replace") => {
		if (!isConnected) {
			if (isLoading) {
				setIsLoading(false);
			}
			return;
		}
		try {
			inProcessSetter(true);
			const stream = {
				page: dataPage.current,
				seed: dataSeed.current,
				lastTs: 0,
				lastId: 0,
				user_id: userId,
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
				targetUserOrProfId: userId || "",
			};

			const onSuccess = (response: StreamItem[]) => {
				const items = response;
				if (op === "replace") {
					setData(items);
				} else {
					setData([...data, ...items]);
				}

				if (items.length < CfgMgr.cfg_feed_nbOfItems2FetchFromDb) {
					setIsLastPage(true);
				}
				inProcessSetter(false);
			};

			const onError = () => {
				inProcessSetter(false);
			};

			await EngageActions.retrieveUserCommunitities(
				stream,
				onSuccess,
				onError,
			);
		} catch (error) {
			inProcessSetter(false);
		}
	};

	const handleLoadMore = () => {
		if (isLoading || isRefreshing || isLastPage) return;
		dataPage.current += 1;
		fetchUserStreams(setIsRefreshing, "append");
	};

	const handleRefresh = () => {
		if (isLoading || isRefreshing) return;
		dataPage.current = 1;
		dataSeed.current += 1;
		setData([]);
		fetchUserStreams(setIsLoading, "replace");
	};

	useEffect(() => {
		if (isConnected) {
			dataPage.current = 1;
			dataSeed.current = 1;
			fetchUserStreams(data.length === 0 ? setIsLoading : setIsRefreshing, "replace");
		} else if (!userId) {
			setIsLoading(false);
			setIsRefreshing(false);
		}
	}, [isConnected, userId]);

	useEffect(() => {
		setIsLastPage(false);
		handleRefresh();
	}, [isCredoMode]);

	const handleDismissExplainCard = () => {
		UserActions.saveClientMetaDataToServer({
			[ClientMetaDataMapping[EngageTabOptions.COMMUNITIES]]: false,
		});
		setClientMetaData({
			...clientMetadata,
			[ClientMetaDataMapping[EngageTabOptions.COMMUNITIES]]: false,
		});
	};

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		);
	};

	const renderStreamItem = ({ item, index }: { item: any; index: number }) => (
		<UserCommunityItem
			id={index}
			key={item.struname}
			item={item}
			showInfoIcon={index === 0}
			isEgoWall={item.name === Consts.main_wall_ego_stream_id}
			entityType={EntityType.STREAM}
		/>
	);

	const renderNoDataContent = () => (
		<div className="mt-12 w-full flex justify-center items-center text-basic h-[calc(100vh-4rem-1px-3rem-3rem)]">
			<div className="flex flex-col justify-center items-center">
				<SvgIcon icon={NoContentIcon} width={96} height={96} className="ml-4" />
				<span className="text-basic pt-4">
					{strings("ExplorePage.no_content_message")}
				</span>
			</div>
		</div>
	);

	const showDownloadModal = () => {
		AppUtils.handleDownloadAppModal(true);
	};

	const navigateToCreateStream = () => {
		navigate(`${ENGAGE_PAGE}${CREATE_STREAM}`);
	};

	const renderCreateStreamButton = () => (
		<div className="w-full flex items-center justify-center py-3 border-b border-background-tertiary flex">
			<button
				type="button"
				className={classNames("w-64 h-9 rounded-[18px] text-sm font-normal",
					"!border-primary !text-background-tertiary !bg-primary ",
					"items-center justify-center flex flex-row",
					"border overflow-hidden text-ellipsis capitalize")}
				onClick={navigateToCreateStream}
			>
				<SvgIcon icon={AddIcon} stroke="var(--background-tertiary )" className="mr-2" />
				<span>
					{strings("EngagePage.new_community")}
				</span>
			</button>
		</div>
	);

	const streamData = data.filter((element: StreamItem) => (element.name !== Consts.main_wall_credo_stream_id
		&& !element.streamId.includes(Consts.main_wall_credo_stream_id)));

	return (
		<div
			className={classNames(
				"block",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				"text-basic",
				"bg-background",
				"overflow-visible",
			)}
			// @ts-ignore
			ref={divRef}
		>
			<HelmetComponent
				title={strings("EngagePage.title")}
				url={location?.pathname}
			/>
			<div
				className={classNames(
					"flex flex-col text-white",
					"bg-background w-full",
					// clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.COMMUNITIES]]? "pt-5" : "pt-9",
					// eslint-disable-next-line no-nested-ternary
					clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.COMMUNITIES]]
						? cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-6" : "pt-5"
						: cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-9",
					isMobile && windowWidth < 768 ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-4rem-1px)]",
				)}
			>
				{cu.getGlobalVar(Consts.isBottomNavVisible) && <div className="h-3 bg-background w-full" />}
				<div className="w-full text-basic pb-3">
					{isLoading
						? (
							<div
								className={classNames(
									"w-full text-basic overflow-hidden",
									isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
								)}
							>
								<EngageCommunitiesSkeleton noOfItems={30} />
							</div>
						) : (
							<>
								{clientMetadata && clientMetadata[ClientMetaDataMapping[EngageTabOptions.COMMUNITIES]] && (
									<ExplainComponent
										message={strings("EngagePage.explain_tab.communities")}
										onClickClose={handleDismissExplainCard}
									/>
								)}
								{renderCreateStreamButton()}
								{streamData && streamData.length > 0
									? (
										<InfiniteScroll
											dataLength={streamData.length}
											next={handleLoadMore}
											hasMore
											loader={renderFooter()}
											className="!overflow-visible"
										>
											{streamData.map((item: any, idx: any) => renderStreamItem({ item, index: idx }))}
										</InfiniteScroll>
									)
									: renderNoDataContent()}
							</>
						)}
				</div>
			</div>
		</div>
	);
};

export default MyCommunitiesPage;
