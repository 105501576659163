import React, { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import { Detector } from "react-detect-offline";
import { WSConnStat } from "@credo/websocket";
import { dbg } from "@credo/utilities";
import { StateListener } from "@credo/store";
import GlobalListeners from "../components/GlobalListeners";
import { GraphDataProvider } from "../components/CredoGraphWrapper/GraphDataProvider";
import { VoltsDataProvider } from "../modules/voltsDashboard/components/VoltsDataProvider";
import { PermissionsDataProvider } from "../components/PermissionsDataProvider";

const GlobalProvider = ({ children }: {children: ReactElement}) => {
	const handleConnectionChange = ({ online }: {online: boolean}) => {
		if (online) {
			WSConnStat.announceMetCond(WSConnStat.IS_NETWORK);
			if (dbg) {
				// CredoAlert.showSuccess("Connected");
			}
		} else {
			WSConnStat.announceNotMetCond(WSConnStat.IS_NETWORK);
			if (dbg) {
				// CredoAlert.showError("Not Connected");
			}
		}
		return null;
	};

	return (
		<BrowserRouter>
			<Detector render={handleConnectionChange} />
			<PermissionsDataProvider />
			<GraphDataProvider />
			<VoltsDataProvider />
			<StateListener />
			<GlobalListeners />
			{children}
		</BrowserRouter>
	);
};

export default GlobalProvider;
