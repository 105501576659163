import React, {
	useCallback,
	useEffect, useMemo, useState,
} from "react";
import {
	classNames,
	PostView,
	MoreMenuInterface,
	ReportIcon,
	ShareIcon,
	EditPostIcon,
	DeleteIcon,
	Modal,
	ReportPostSuccessIcon,
	ConfirmationModal,
	PostCommentInput,
	CredoTagSource,
	ButtonSize,
	SecondaryButton,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import {
	ThemeOptions,
	cu,
	YesNoOptions,
	useEvtMgrListener, EvtMgr, AppMode, dbg, LogMgr,
} from "@credo/utilities";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";
import { isCredoModeAtom } from "@credo/store";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import moment from "moment";
import { useWindowSize } from "react-use";
import { FeedItem, saveComment } from "./requests";
import {
	isSessionAuthAtom,
	userIdAtom,
	userProfileAtom,
	sharedPostDetailsAtom,
	quickShareLinkAtom,
} from "../../utils/atoms";
import { strings } from "../../i18n/config";
import {
	AnalyticEventsConst, AppUtils, Consts, EventConst, TAG_MESSAGES, useBoostTrayProps,
} from "../../utils";
import { UserActions } from "../../services/actions/user";
import ReportSuccess from "../legal/ReportSuccess";
import ReportPost from "../post/ReportPost";
import { useDebouncedCallback } from "../../utils/hooks/useDebouncedCallback";
import {
	deleteComment, isHomeFeedCommentsNull, saveCommentAndRemovePendingComments, updateEmoCode,
} from "../../utils/comments/CommentUtils";
import { CommentCurrentScreen, CommentItem, CommentType } from "../../utils/comments/types";
import { SearchActions } from "../../services/actions/search";
import { sharePost } from "../post/post-utils";
import PostCommentWithSubcomments from "../post/PostCommentWithSubcomments";
import { ItemType, ExpertiseActions, ExpertiseTypes } from "../../utils/types";

export interface PostWithDirectConnCommentsProps {
	item: FeedItem;
	onDeletePost: (post: FeedItem) => void;
}

export default function PostWithDirectConnComments({
	item,
	onDeletePost,
}: PostWithDirectConnCommentsProps) {
	const [userId] = useAtom(userIdAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [showFlagAsInappModal, setShowFlagAsInappModal] = useState(false);
	const [selectedPost, setSelectedPost] = useState(null);
	const [showReportSuccessModal, setShowReportSuccessModal] = useState(false);
	const [showDeletePostModal, setShowDeletePostModal] = useState(false);
	const [, setSharedPostDetails] = useAtom(sharedPostDetailsAtom);
	const [, setQuickShareLink] = useAtom(quickShareLinkAtom);
	const [itemState, setItemState] = useState(item);
	const [userSuggestions, setUserSuggestions] = useState([]);
	const [commentText, setCommentText] = useState<string>("");
	const [showCommentInput, setShowCommentInput] = useState<boolean>(false);
	const navigate = useNavigate();
	const { width } = useWindowSize();

	const hasComments = useMemo(() => !isHomeFeedCommentsNull(itemState.comments), [itemState]);

	const postCommentCount = itemState["p.nb_of_co"] || 0;

	const changeMode = useCallback((mode: boolean) => {
		AppUtils.toggleTheme(mode ? ThemeOptions.CREDO : ThemeOptions.EGO);
	}, []);

	const displayCommentsCount = useMemo(() => {
		if (!itemState || !itemState.comments) return 0;
		const commentsArray = itemState.comments;
		let count = 0;
		commentsArray.forEach((commentInfo: CommentItem) => {
			count += commentInfo.subcomms?.length ?? 0;
		});
		return count + commentsArray.length;
	}, [itemState]);

	const showMoreCommentsCount = useMemo(
		() => item["p.nb_of_co"] - displayCommentsCount,
		[item, displayCommentsCount],
	);

	const onMoreCommentsPress = () => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postViewComments,
		});
		AppUtils.navigateToDetailsScreen(false, item, navigate);
	};

	useEffect(() => {
		setItemState(item);
	}, [item]);

	const handleSaveCommentResponse = (msg: any) => {
		const item = msg.items[0];
		setItemState(
			saveCommentAndRemovePendingComments(
				itemState,
				item,
			),
		);
	};

	const rateCommentTag = (tagData: any) => {
		try {
			if (cu.isSet(tagData?.tag)) {
				const {
					tag, rating, commentUuid, parentCommentUuid,
				} = tagData;
				const clonedItem = cloneDeep(itemState);
				if (clonedItem) {
					if (cu.isSet(parentCommentUuid)) {
						// updating subcomment author tags locally
						const parentCommentIndex = clonedItem?.comments?.findIndex((comm) => comm.commentUuid === parentCommentUuid);
						if (parentCommentIndex !== -1) {
							const parentComment = clonedItem.comments[parentCommentIndex];
							const subCommentIndex = parentComment?.subcomms?.findIndex((comm) => comm.commentUuid === commentUuid);
							if (subCommentIndex !== -1) {
								// @ts-ignore
								const tags = clonedItem.comments[parentCommentIndex].subcomms[subCommentIndex].authorTags;
								// @ts-ignore
								clonedItem.comments[parentCommentIndex].subcomms[subCommentIndex].authorTags = AppUtils.updateTagRating(tags, tag, rating);
								// setAtomItem({ [postUuid]: { ...clonedItem } });
								setItemState(clonedItem);
							}
						}
					} else {
						// updating main/parent comment author tags locally
						const commentIndex = clonedItem.comments?.findIndex((comm) => comm.commentUuid === commentUuid);
						if (commentIndex !== -1) {
							const tags = clonedItem.comments[commentIndex]?.authorTags;
							clonedItem.comments[commentIndex].authorTags = AppUtils.updateTagRating(tags, tag, rating);
							setItemState(clonedItem);
						}
					}
				}
			}
		} catch (err) {
			if (dbg) LogMgr.myerr("error while rateCommentTag: ", err);
		}
	};

	useEvtMgrListener(EventConst.saveComment, handleSaveCommentResponse);
	useEvtMgrListener(`${EventConst.rateCommentTag}_${item?.["p.postUuid"]}`, rateCommentTag);

	const commentManipulated = (commentData: any) => {
		const postObj: FeedItem = { ...itemState };
		const commentDetails = commentData.commentObj;
		if (commentData.postUuid !== postObj["p.postUuid"] || !commentData.type) return;
		switch (commentData.type) {
			case EventConst.commentAdded:
				if (commentData.commentType === CommentType.SubComment) {
					if (postObj?.comments?.length > 0) {
						const index = postObj.comments.findIndex((comm) => comm.commentUuid === commentDetails.parentCommentUuid);
						if (index !== -1) {
							postObj.comments[index].subcomms?.push(commentDetails.comment);
						}
					}
				} else {
					// Main comment
					// eslint-disable-next-line no-lonely-if
					if (isHomeFeedCommentsNull(postObj.comments)) {
						postObj.comments = [commentData.comment];
					} else {
						postObj.comments.push(commentData.comment);
					}
				}
				postObj["p.nb_of_co"] = postObj["p.nb_of_co"] ? postObj["p.nb_of_co"] + 1 : 1;
				setItemState(postObj);
				EvtMgr.getInstance(EventConst.updateFeedData).notifyListeners({ post: postObj });
				break;
			case EventConst.commentDeleted: {
				const updatedPostData = deleteComment(postObj, commentData);
				setItemState(updatedPostData);
				EvtMgr.getInstance(EventConst.updateFeedData).notifyListeners({ post: updatedPostData });
			}
				break;

			case Consts.commentReacted:
				if (cu.isSet(commentDetails?.parentCommentUuid)) {
					// subcomment
					const parentCommentIndex = postObj?.comments?.findIndex((comm) => comm.commentUuid === commentDetails.parentCommentUuid);
					if (parentCommentIndex !== -1) {
						const parentComment = postObj.comments[parentCommentIndex];
						const subCommentIndex = parentComment?.subcomms?.findIndex((comm) => comm.commentUuid === commentDetails.commentUuid);
						if (subCommentIndex !== -1) {
							// @ts-ignore
							postObj.comments[parentCommentIndex].subcomms[subCommentIndex] = updateEmoCode(postObj.comments[parentCommentIndex].subcomms[subCommentIndex], commentData.emojiData);
						}
					}
				} else {
					// main/parent comment
					const commentIndex = postObj.comments?.findIndex((comm) => comm.commentUuid === commentDetails.commentUuid);
					postObj.comments[commentIndex] = updateEmoCode(postObj.comments[commentIndex], commentData.emojiData);
				}
				setItemState(postObj);
				break;

			default:
				break;
		}
	};

	const handleSharedNumbersOnPost = (obj: any) => {
		const postObj = itemState;
		if (obj.srcPostUuid !== postObj["p.postUuid"]) return;
		if (!postObj["p.shared_cnt"]) {
			postObj["p.shared_cnt"] = 0;
		}
		if (obj.srcPostUuid === postObj["p.postUuid"]) {
			postObj["p.shared_cnt"] += 1;
		}
		setItemState(postObj);
	};

	useEvtMgrListener(`${item["p.postUuid"]}${EventConst.postSharedCount}`, handleSharedNumbersOnPost);
	useEvtMgrListener(`${EventConst.commentManipulated}_${item["p.postUuid"]}`, commentManipulated);

	const onEditPost = (post: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEdit,
		});
		AppUtils.handleDownloadAppModal(true);
	};

	const onDeletePostOptionPress = (post: any) => {
		setSelectedPost(post);
		setShowDeletePostModal(true);
	};

	const onCloseDeletPostModal = () => {
		setSelectedPost(null);
		setShowDeletePostModal(false);
	};

	const showLoginModal = () => {
		AppUtils.handleLoginModal(true);
	};

	const onFlagAsInappPress = (item: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postReport,
		});
		setSelectedPost(item);
		setShowFlagAsInappModal(true);
	};

	const onFlagAsInappClose = () => {
		setSelectedPost(null);
		setShowFlagAsInappModal(false);
	};

	const onPostReported = () => {
		setShowFlagAsInappModal(false);
		setShowReportSuccessModal(true);
	};

	const onReportContinue = () => {
		setShowReportSuccessModal(false);
	};

	const getMenuList = useCallback((post: FeedItem) => {
		const menuList: MoreMenuInterface[] = [
			{
				title: strings("PostView.share"),
				icon: <ShareIcon stroke="var(--gray-dark)" />,
				onClick: () => { sharePost(post); },
			},
		];
		if (AppUtils.allowUserToUpdateORDeleteItem(isCredoMode, post.p_edit, post["p.mode"])) {
			menuList.push(
				{
					title: strings("PostView.edit_post"),
					icon: <EditPostIcon stroke="var(--gray-dark)" />,
					onClick: () => { onEditPost(post); },
				},
			);
		}
		if (AppUtils.allowUserToUpdateORDeleteItem(isCredoMode, post.p_edit, post["p.mode"]) || post?.isAdmin) {
			// isAdmin: we don't differentiate credo vs ego for deleting a post for an admin,
			// this is to simplify the UX so the user doesn't need to switch modes.
			// If a user is admin or super admin in one mode, isAdmin will be true for both modes
			menuList.push(
				{
					title: strings("PostView.delete_post"),
					icon: <DeleteIcon stroke="var(--gray-dark)" />,
					onClick: () => { onDeletePostOptionPress(post); },
				},
			);
		}
		if (!AppUtils.isCrtUserProfId(post["p.prof_id"], userProfile)) {
			menuList.unshift({
				title: strings("PostView.flag_as_inappropriate"),
				icon: <ReportIcon stroke="var(--gray-dark)" />,
				onClick: !isSessionAuth ? showLoginModal : () => { onFlagAsInappPress(post); },
			});
		}
		return menuList;
	}, [isCredoMode, userProfile, isSessionAuth]);

	const onEmojiClick = useDebouncedCallback((data: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEmojiSelected,
		});
		const request = {
			prof_id: (isCredoMode ? userProfile?.cprof_id : userProfile?.eprof_id) ?? "",
			mode: cu.getAppMode(),
			postUuid: data.postUuid,
			action: data.action,
			code: data.code,
		};
		UserActions.mngEmocons4Post(request);
	}, 200);

	const onEmojiClickMemoised = useCallback((data: any) => onEmojiClick(data), [isCredoMode, userProfile]);

	const navigateToDetailsScreen = (isShared: boolean, item: FeedItem) => {
		AppUtils.navigateToDetailsScreen(isShared, item, navigate);
	};

	const navigateToUserOrStream = useCallback((data: any) => {
		AppUtils.navigateToUserOrStream({
			isCredoMode,
			userProfile,
			navigate,
			...data,
		});
	}, [isCredoMode, userProfile]);

	const onPressOkDelete = () => {
		if (onDeletePost) {
			onDeletePost(item);
		}
		onCloseDeletPostModal();
	};

	const renderDeletePostAlert = () => (
		<ConfirmationModal
			isVisible={showDeletePostModal}
			onClose={onCloseDeletPostModal}
			cancel_button_function={onCloseDeletPostModal}
			ok_button_title={strings("PostView.delete")}
			cancel_button_title={strings("PostView.cancel")}
			ok_button_function={onPressOkDelete}
			title={strings("PostView.delete_post")}
			message={strings("PostView.confirm_delete_message")}
			okButtonStyle=""
			cancelButtonStyle=""
		/>
	);

	const renderReportPostModal = () => (
		<Modal
			id="report-post-modal"
			show={showFlagAsInappModal}
			onClose={onFlagAsInappClose}
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<div>
					<p className="text-primary text-lg font-bold">{strings("PostView.report_post")}</p>
					<div className="mt-2">
						<ReportPost
							postData={selectedPost}
							onPostReported={onPostReported}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);

	const renderReportSucessModal = () => (
		<Modal
			id="report-post-success-modal"
			show={showReportSuccessModal}
			onClose={onReportContinue}
			hideCloseIcon
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col justify-center !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<ReportSuccess
					icon={<ReportPostSuccessIcon />}
					title={strings("ReportPostSentSuccessScreen.sent")}
					subTitle={strings("ReportPostSentSuccessScreen.thank_you_message")}
					buttonTitle={strings("ReportPostSentSuccessScreen.continue")}
					onPressButton={onReportContinue}
				/>
			</div>
		</Modal>
	);

	const retrieveSuggestions = async (msgData: any) => {
		const suggestionsData = await SearchActions.fetchMentionsSuggestions(msgData);
		setUserSuggestions(suggestionsData);
	};

	const renderCommentItem = useCallback((singleComment: CommentItem) => (
		<div
			key={singleComment.commentUuid}
			className="w-full px-4"
		>
			<PostCommentWithSubcomments
				postItem={item}
				commentItem={singleComment}
				isSubComment={false}
				isReplyAllowed
				currentScreen={CommentCurrentScreen.HomeFeed}
			/>
		</div>
	), [item]);

	const renderPostComments = () => {
		const commentA = itemState?.comments; // array of arrays of commentUuid, comment txt, subcomment txt
		if (isHomeFeedCommentsNull(commentA)) return null;
		return (
			commentA.map((item: any) => renderCommentItem(item))
		);
	};

	const onTagRate = useCallback((data: any) => {
		const { tag, rating, tagSource } = data;
		switch (tagSource) {
			case CredoTagSource.POST:
				UserActions.onTagRate(tag, rating, itemState["p.postUuid"]);
				break;

			case CredoTagSource.AUTHOR_POST: {
				const request = {
					user_id: userId,
					mode: AppMode.EGO,
					itemType: ItemType.USER,
					action: ExpertiseActions.RATE,
					xpType: ExpertiseTypes.UXP_EXPT,
					uxpcode: tag,
					rating: rating ?? 0,
					isCredoNetRating: YesNoOptions.NO,
					dest_mode: AppMode.EGO,
					dest_prof_id: item["p.prof_id"] || "",
				};
				UserActions.rateUser4XpItem(request);
				break;
			}

			default: break;
		}
		const clonedItem = cloneDeep(itemState);
		if (clonedItem) {
			let tags = clonedItem?.sortedPostTags;
			if (tagSource === CredoTagSource.AUTHOR_POST) {
				tags = clonedItem?.authorTags;
			}
			const updatedPostTags = AppUtils.updateTagRating(tags, tag, rating);
			const updatedItem = {
				...clonedItem,
				[tagSource === CredoTagSource.AUTHOR_POST ? "authorTags" : "sortedPostTags"]: updatedPostTags,
			};
			setItemState(updatedItem);
		}
	}, [itemState, userId, item]);

	const onChangeCommentText = (text: string) => {
		setCommentText(text);
	};

	const onSendComment = (imgData: Array<string>) => {
		if ((isNil(commentText) || commentText.trim() === "")
			&& (isNil(imgData) || imgData.length === 0)) {
			return;
		}
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentNewFromHomefeed,
		});
		const newCommentUuid = uuidv5(uuidv4(), uuidv5.URL).toLowerCase();
		const request = {
			user_id: userId ?? "",
			mode: cu.getAppMode(),
			tags: AppUtils.getHashTagsFromText(commentText),
			newUserTags: AppUtils.getUserTagsFromText(commentText),
			postUuid: item["p.postUuid"],
			commentText,
			commentUuid: newCommentUuid,
			streamId: item["s.streamId"],
			pCommentUuid: null,
			action: "",
			hidden: YesNoOptions.NO,
			img_bucketRelativeRefPath0: imgData?.length > 0 ? imgData[0] : null,
			APIVersion: "v1",
		};
		saveComment(request);
		setCommentText("");

		const currentTs = moment().toDate().getTime();
		const currentComment: CommentItem = {
			commentUuid: newCommentUuid,
			profId: (isCredoMode ? userProfile?.cprof_id : userProfile?.eprof_id) ?? "",
			commentText,
			profilePicRelUrl: (isCredoMode ? userProfile?.cprofilePicRelUrl : userProfile?.eprofilePicRelUrl) ?? "",
			creatTs: currentTs,
			modifTs: currentTs,
			username: isCredoMode ? userProfile?.username ?? "" : null,
			mode: cu.getAppMode(),
			firstName: isCredoMode ? null : userProfile?.firstName ?? "",
			lastName: isCredoMode ? null : userProfile?.lastName ?? "",
			edit: YesNoOptions.YES,
			hidden: YesNoOptions.NO,
			s_img_bucketRelativeRefPath0: imgData?.length > 0 ? imgData[0] : null,
			isRestricted: false,
			isCommentPending: true,
			authorTags: [],
			subcomms: [],
		};

		EvtMgr.getInstance(`${EventConst.commentManipulated}_${item["p.postUuid"]}`).notifyListeners({
			type: EventConst.commentAdded,
			commentType: CommentType.MainComment,
			postUuid: item["p.postUuid"],
			comment: currentComment,
		});
	};

	const onCommentInputFocus = () => {
		if (!isSessionAuth) {
			showLoginModal();
		}
	};

	const onClickCommentIcon = () => {
		setShowCommentInput(true);
	};

	const getPlaceholderText = () => {
		let name = isCredoMode ? userProfile?.username : userProfile?.firstName;
		if (isMobile && width < 768) {
			name = name && name.length > 12 ? `${name.substring(0, 12)}...` : name;
		}
		name = cu.isSet(name) ? `as ${name}` : "";
		return `${strings("PostComment.comment")} ${name}`;
	};

	const showDownloadModal = () => {
		AppUtils.handleDownloadAppModal(true);
	};

	const renderCommentInput = () => {
		if ((isNil(item) || isNil(item.p_dc)) && !(showCommentInput && postCommentCount < 1)) {
			return null;
		}
		return (
			<div
				key={itemState["p.postUuid"]}
				className={classNames(
					"flex flex-row px-4 pb-2.5",
				)}
			>
				<div className="w-full">
					<PostCommentInput
						id={itemState["p.postUuid"]}
						placeHolder={getPlaceholderText()}
						onChangeText={onChangeCommentText}
						containerStyle=""
						getSuggesionsFromServer={retrieveSuggestions}
						mentionData={userSuggestions}
						onSendComment={onSendComment}
						onCommentInputFocus={onCommentInputFocus}
						disabled={!isSessionAuth}
						isFocused={showCommentInput}
						userDetails={userProfile}
						postMsgs={{
							imageErrorMsg: strings("PostView.imageErrorMsg"),
							contentSensitiveMsg: strings("PostView.contentSensitiveMsg"),
							cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
							switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
							sharedPostDeleted: strings("PostView.sharedPostDeleted"),
						}}
						showDownloadModal={showDownloadModal}
					/>
				</div>
				{isDesktop && displayCommentsCount !== 0 && <div className="w-8 h-8 ml-5" />}
			</div>
		);
	};

	const onCommentIconClick = useCallback(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postViewComments,
		});
		if (postCommentCount < 1 && !showCommentInput && isNil(itemState.p_dc)) {
			onClickCommentIcon();
			return;
		}
		navigateToDetailsScreen(false, itemState);
	}, [itemState, showCommentInput, postCommentCount]);

	const onClickMoreMenu = useCallback(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postMoreMenu,
		});
	}, []);

	const onSharePostIconClick = useCallback(() => {
		if (!isSessionAuth) {
			showLoginModal();
		} else {
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.postResharePost,
			});
			setSharedPostDetails(itemState);
			AppUtils.handleNewPostModal(true);
		}
	}, [isSessionAuth, itemState]);

	const onClickEmojiTooltip = useCallback(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postEmoji,
		});
	}, []);

	const onLinkShareIconPress = useCallback((url: any) => {
		if (!isSessionAuth) {
			showLoginModal();
		} else {
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.postReshareLink,
			});
			setQuickShareLink(`\n\n${url}`);
			AppUtils.handleNewPostModal(true);
		}
	}, [isSessionAuth]);

	const navigateToPostDetailsScreen = useCallback((isShared: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postVisitPostDetails,
		});
		navigateToDetailsScreen(isShared, itemState);
	}, [itemState]);

	const boostTrayPropsFromHook = useBoostTrayProps({ item, isSessionAuth: isSessionAuth || false, userProfile });

	const boostTrayPropsMemoized = useMemo(() => boostTrayPropsFromHook, [JSON.stringify(boostTrayPropsFromHook)]);

	const postMsgs = useMemo(() => ({
		imageErrorMsg: strings("PostView.imageErrorMsg"),
		contentSensitiveMsg: strings("PostView.contentSensitiveMsg"),
		cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
		switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
		sharedPostDeleted: strings("PostView.sharedPostDeleted"),
	}), []);

	const postViewMessages = useMemo(() => ({
		postTagMessages: {
			...TAG_MESSAGES,
			rating_info: strings("TagMessages.rating_info_post"),
			rate_this_item: strings("TagMessages.rate_this_post"),
		},
		postHeaderTagMessages: {
			...TAG_MESSAGES,
			rating_info: strings("TagMessages.rating_info_author"),
			rate_this_item: strings("TagMessages.rate_this_author"),
		},
	}), []);

	return (
		<div>
			<PostView
				item={itemState}
				onCommentIconClick={onCommentIconClick}
				onClickEmojiTooltip={onClickEmojiTooltip}
				onClickMoreMenu={onClickMoreMenu}
				onEmojiClick={onEmojiClickMemoised}
				onSharePostIconClick={onSharePostIconClick}
				onLinkShareIconPress={onLinkShareIconPress}
				postMsgs={postMsgs}
				isUserOwnPost={AppUtils.isCrtUserProfId(itemState["p.prof_id"], userProfile)}
				isCredoMode={isCredoMode}
				onModeChange={changeMode}
				userProfile={userProfile}
				onTagRate={onTagRate}
				navigateToUserOrStream={navigateToUserOrStream}
				moreMenuList={getMenuList(itemState)}
				showLoginModal={AppUtils.handleLoginModal}
				navigateToDetailsScreen={navigateToPostDetailsScreen}
				hasComments={hasComments}
				commentInputVisible={showCommentInput}
				onPressTagInfoBubble={AppUtils.showRatingTagTutorial}
				onPressCredoGraphText={AppUtils.showCredoGraph}
				boostTrayProps={boostTrayPropsMemoized}
				messages={postViewMessages}
			/>
			{renderPostComments()}
			{(!isHomeFeedCommentsNull(itemState.comments) && showMoreCommentsCount > 0) ? (
				<div
					className={classNames(
						"flex flex-row items-center justify-center cursor-pointer",
						"pb-4",
					)}
				>
					<SecondaryButton
						size={ButtonSize.MEDIUM}
						disabled={false}
						label={strings("PostComment.view_post_for_more")}
						dimensionsClasses="px-6 h-6"
						buttonClassNames={classNames(
							"rounded-full justify-center items-center !border-primary !text-xs",
							isCredoMode ? "bg-content-L1 text-accent" : "bg-primary text-white",
						)}
						handleClick={onMoreCommentsPress}
					/>
				</div>
			) : null}
			{renderCommentInput()}
			{showFlagAsInappModal && renderReportPostModal()}
			{showReportSuccessModal && renderReportSucessModal()}
			{renderDeletePostAlert()}
		</div>
	);
}
