import {
	classNames, LazyLoad, PostFeedSkeleton, StreamProfileSkeleton,
} from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const StreamProfile = LazyLoad(
	() => import(/* webpackChunkName: "streamProfile" */ "./StreamProfile"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					isMobile ? "" : "px-3",
					"text-basic",
				)}
				data-testid="community-details-loading-wrapper"
			>
				<StreamProfileSkeleton />
				<div className="bg-background py-5 z-0 h-[calc(100vh-18.5rem)] overflow-hidden">
					<PostFeedSkeleton noOfItems={2} />
				</div>
			</div>
		),
	},
);
