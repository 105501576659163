import React from "react";
import { AppMode } from "@credo/utilities";
import { isCredoModeAtom } from "@credo/store";
import { useAtom } from "jotai";
import { strings } from "../../i18n/config";
import { userIdAtom } from "../../utils/atoms";
import { ReportItemType, ReportListItem } from "../../utils/types";
import Report from "../legal/Report";
import { reportItem } from "./PostRequest";

const radioProps_ego: ReportListItem[] = [
	{ label: strings("ReportPostScreen.offensive_or_hateful"), value: "offensive" },
	{ label: strings("ReportPostScreen.sexually_explicit"), value: "sexual" },
	{ label: strings("ReportPostScreen.voilent_or_prohibited"), value: "voilent" },
	{ label: strings("ReportPostScreen.spam"), value: "spam" },
	{ label: strings("ReportPostScreen.other"), value: "other" },
];

const radioProps_credo: ReportListItem[] = [
	{ label: strings("ReportPostScreen.offensive_or_hateful"), value: "offensive" },
	{ label: strings("ReportPostScreen.sexually_explicit"), value: "sexual" },
	{ label: strings("ReportPostScreen.voilent_or_prohibited"), value: "voilent" },
	{ label: strings("ReportPostScreen.spam"), value: "spam" },
	{ label: strings("ReportPostScreen.revealed_credo_identity"), value: "rci" },
	{ label: strings("ReportPostScreen.other"), value: "other" },
];

export interface ReportPostProps {
	postData: any;
	onPostReported: () => void;
}

const ReportPost = (props: ReportPostProps) => {
	const {
		postData,
		onPostReported,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userId] = useAtom(userIdAtom);

	const onReportPost = (reportReason: string, reportMessage: string) => {
		const requestObject = {
			user_id: userId ?? null,
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
			itemType: ReportItemType.Post,
			postUuid: postData["p.postUuid"],
			prof_id: null,
			streamId: null,
			commentUuid: null,
			reportReason,
			reportMessage: reportMessage || reportReason,
			arg: {},
		};
		reportItem(requestObject);
		if (onPostReported) {
			onPostReported();
		}
	};

	return (
		<Report
			radioProps={isCredoMode ? radioProps_credo : radioProps_ego}
			onSubmit={onReportPost}
		/>
	);
};

export default ReportPost;
