const CfgMgr = {
	CFG_FLAVOR: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_FLAVOR)),

	CFG_MYVAR: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_MYVAR)),

	cfg_ws_dont_use_ssl: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_ws_dont_use_ssl)),

	cfg_credoWebSocketSvr_nossl: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_credoWebSocketSvr_nossl)),

	cfg_credoWebSocketSvr_ssl: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_credoWebSocketSvr_ssl)),

	CFG_DEBUG_ENABLED: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_DEBUG_ENABLED)),

	CFG_ERROR_ENABLED: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_ERROR_ENABLED)),

	CFG_WARN_ENABLED: JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_WARN_ENABLED)),

	cfg_feed_nbOfItems2FetchFromDb: Number(JSON.parse(JSON.stringify(process.env.REACT_APP_CFG_feed_nbOfItems2FetchFromDb))),
};

export default CfgMgr;
