import React, {
	Suspense, useEffect, useMemo,
} from "react";
import {
	classNames,
	PostFeedSkeleton,
} from "@credo/ui-components";
import { useMeasure } from "react-use";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import {
	topFeedDataMapAtom,
} from "../../utils/atoms";
import { strings } from "../../i18n/config";
import { HelmetComponent } from "../../components/HelmetComponent";
import { BoostBoard } from "./BoostBoard/BoostBoard";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";
import {
	useTopBoardPermission,
} from "../../utils";
import { PAGE_404_URL } from "../../routes/constants";

/**
 * Checks permission and redirects to page not found when not authorised to
 * access this page.
 * */
// eslint-disable-next-line import/prefer-default-export
export const HomeTop = () => {
	const [divRef, { width }] = useMeasure();
	const location = useLocation();
	const navigate = useNavigate();

	const { isTopBoardAllowed, permInitialised } = useTopBoardPermission();

	useEffect(() => {
		if (!isTopBoardAllowed && permInitialised) {
			navigate(PAGE_404_URL);
		}
	}, [isTopBoardAllowed, permInitialised]);

	useSetAppHeader({
		title: strings("Sidebar.top"),
	});

	const renderLoadingComp = useMemo(() => (
		<div
			data-testid="home-content-suspense-loading"
			className={classNames(
				"block",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				"overflow-hidden",
				// 4rem: Header, 1px: border, 4rem: Bottom navtab
				isMobile ? "max-h-[calc(100vh-4rem-1px-4rem)]" : "max-h-[calc(100vh-4rem-1px)]",
			)}
			style={{ width }}
		>
			{
				/**
				 * When showing suspense on mobile on changing the mode it was being cached
				 * */
				!(isMobile || isTablet) && (
					<HelmetComponent
						title={strings("HomePage.top")}
						url={location?.pathname}
					/>
				)
			}
			<div
				className={classNames(
					"flex flex-col text-white",
					"bg-background w-full",
				)}
			>
				<PostFeedSkeleton />
			</div>
		</div>
	), [isMobile, isTablet, width, location]);

	if (!permInitialised) {
		return renderLoadingComp;
	}

	return (
		<Suspense
			fallback={renderLoadingComp}
		>
			<HelmetComponent
				title={strings("HomePage.top")}
				url={location?.pathname}
			/>
			<div
				data-testid="home-content-render-data"
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					isMobile ? "" : "min-h-[calc(100vh-64px)]",
				)}
				// @ts-ignore
				ref={divRef}
			>
				<div
					className={classNames(
						"flex flex-col text-white",
						"bg-background w-full",
					)}
				>
					<BoostBoard
						tabWidth={width}
						atomName={topFeedDataMapAtom}
					/>
				</div>
			</div>
		</Suspense>
	);
};
