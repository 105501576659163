import React, {
	useCallback, useEffect, useRef, useState,
} from "react";
import {
	classNames, FeedNoPost,
	Spinner,
	SpinnerLeafType,
	TopFeedSkeleton,
} from "@credo/ui-components";
import {
	DataStatus, cu, useEvtMgrListener,
} from "@credo/utilities";
import { PrimitiveAtom, useAtomValue } from "jotai";
import { useWindowSize } from "react-use";
import { isMobile, isTablet } from "react-device-detect";
import { strings } from "../../../i18n/config";
import {
	BoostBoardMapAtom,
	FeedType,
	FetchAction,
	OperationType,
	WithInitialValue,
} from "../../../utils/types";
import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT, HOME_FEED_TAB_HEADER_HEIGHT } from "../../../config/constants";
import { activeBoostTagAtom, isConnectedAtom } from "../../../utils/atoms";
import { BoostBoardDataProvider } from "./BoostBoardDataProvider";
import { TopFeedDataProviderRef } from "./types";
import { NoConnection } from "../components/NoConnection";
import { NoItemsToLoad } from "../components/NoItemsToLoad";
import { BoostBoardPost } from "../components/BoostBoardPost";
import { Consts, EventConst } from "../../../utils";

interface TopFeedProps {
	tabWidth: number;
	atomName: PrimitiveAtom<BoostBoardMapAtom> & WithInitialValue<BoostBoardMapAtom>;
}

const TopFeedComponent: React.FC<TopFeedProps> = (props: TopFeedProps) => {
	const {
		tabWidth,
		atomName,
	} = props;

	const [showRefreshing, setShowRefreshing] = useState<boolean>(false);

	const { renderIds: dataToRender, isLoading } = useAtomValue(atomName);
	// TODO: move this to store
	const isConnected = useAtomValue(isConnectedAtom);
	const activeTag = useAtomValue(activeBoostTagAtom);
	// const [isLoading, setIsLoading] = useState<boolean>(true);

	const DataProviderRef = useRef<TopFeedDataProviderRef | undefined>(undefined);

	const { height } = useWindowSize();

	const getContainerHeight = () => {
		let containerHeight = height;
		if (isMobile && !isTablet) {
			// for mobile
			containerHeight -= (HOME_FEED_TAB_HEADER_HEIGHT + HEADER_HEIGHT + BOTTOM_NAV_HEIGHT);
		} else {
			// for desktop or tablet
			containerHeight -= (HOME_FEED_TAB_HEADER_HEIGHT + HEADER_HEIGHT);
		}
		return containerHeight;
	};

	useEffect(() => {
		if (DataProviderRef.current && isConnected && activeTag) {
			DataProviderRef.current.fetchFeed(
				FetchAction.LOAD,
				OperationType.REPLACE,
				activeTag,
			);
		}
	}, [isConnected, activeTag]);

	useEffect(() => {
		if (isLoading === DataStatus.REFRESHING) {
			setTimeout(() => {
				setShowRefreshing(false);
			}, 3000);
		}
	}, [isLoading]);

	const renderRefreshLoader = useCallback(() => (
		<div
			style={{ width: tabWidth }}
			className={classNames(
				"flex items-center justify-center fixed -mt-10",
				isLoading === DataStatus.REFRESHING ? "animating-move-down" : "invisible-delay-animation",
				"z-[19]",
			)}
		>
			<div className="flex items-center justify-center p-2 bg-background-tertiary rounded-full shadow-xl m-px">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		</div>
	), [isLoading, tabWidth]);

	const renderContent = () => {
		if (isLoading === DataStatus.LOADING && activeTag) {
			return (
				<div
					data-testid="top-feed-loading"
					style={{
						width: tabWidth,
						height: height ? getContainerHeight() + 36 : "100%",	// 36 is top margin given to feed container
						overflow: "hidden",
					}}
				>
					<TopFeedSkeleton wrapperClasses={cu.getGlobalVar(Consts.isBottomNavVisible) ? "mt-0" : "mt-11"} />
				</div>
			);
		}

		if (!activeTag) {
			return (
				<div
					className="w-full flex flex-col h-screen items-center py-32"
					style={{
						// eslint-disable-next-line react/destructuring-assignment
						height: height ? getContainerHeight() : "100%",
					}}
				>
					<FeedNoPost />
					<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
						{strings("BoostBoard.no_tag_selected")}
					</span>
				</div>
			);
		}

		if ((isLoading === DataStatus.LOADED && dataToRender.length === 0)) {
			return (
				<div
					className={classNames(
						"w-full",
						cu.getGlobalVar(Consts.isBottomNavVisible) ? "mt-0" : "mt-11",
					)}
				>
					{!isConnected
						? <NoConnection />
						: <NoItemsToLoad height={height ? getContainerHeight() : "100%"} />}
				</div>
			);
		}

		return (
			<div
				className={classNames(
					"w-full overflow-y-visible overflow-x-clip",
					cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "mt-6",
				)}
			>
				{/* <div
					style={{ width: tabWidth }}
					className={classNames(
						"flex items-center justify-center fixed -mt-10",
						// TODO: Implement new post available after the requests are integrated
						// isNewPostAvailable ? "animating-move-down visible" : "animating-move-up invisible",
						"z-[19]",
					)}
				>
					<SecondaryButton
						size={ButtonSize.SMALL}
						label={strings("HomePage.new_posts")}
						handleClick={fetchNewPost}
						buttonClassNames="shadow-new-post-button bg-background"
					/>
				</div> */}
				<div className="h-3 bg-background w-full" />
				{showRefreshing && renderRefreshLoader()}
				{dataToRender.map((item, index) => (
					<>
						<BoostBoardPost postUuid={item} />
						{
							/**
							 * Initial separator based on only one condition if there comes more
							 * types like home feed we will add it then.
							 * */
							!((dataToRender.length - 1) === index) && (
								<div style={{ height: 10 }} className="bg-background-tertiary" />
							)
						}
					</>
				))}
			</div>
		);
	};

	const handleBoostBoardFetch = (message: {action: FetchAction, op: OperationType}) => {
		if (DataProviderRef.current && activeTag) {
			DataProviderRef.current.fetchFeed(
				message.action,
				message.op,
				activeTag,
			);
		}
		if (message.action === FetchAction.REFRESH) {
			setShowRefreshing(true);
		}
	};

	const handleBoostBoardRefresh = () => {
		if (DataProviderRef.current) {
			DataProviderRef.current.fetchFeed(
				FetchAction.REFRESH,
				OperationType.REPLACE,
				activeTag,
			);
		}
		setShowRefreshing(true);
	};

	useEvtMgrListener(EventConst.boostBoardFetch, handleBoostBoardFetch);
	useEvtMgrListener(`${EventConst.refreshHomeFeed}-${FeedType.TOP}`, handleBoostBoardRefresh);

	return (
		<>
			<BoostBoardDataProvider
				// @ts-ignore
				ref={DataProviderRef}
			/>
			{renderContent()}
		</>
	);
};

const compareFunction = (nextProps: TopFeedProps, prevProps: TopFeedProps) => nextProps.atomName === prevProps.atomName
		&& nextProps.tabWidth === prevProps.tabWidth;

// eslint-disable-next-line import/prefer-default-export
export const BoostBoard = React.memo(
	TopFeedComponent,
	compareFunction,
);
