/**
 * This file should contain the definitive list of globally managed constants
 *
 */
import { StateMgr } from "./StateMgr";
import {
	AppMode,
	BoostTag,
	EmojiItem,
	Permission,
	UserProfile,
	UserSession,
	WalletSummaryForGraph,
	LighteningAddressesAtom,
	DataStatus
} from "@credo/utilities";

/**
 * We should also be able to access the prev state
 * and update the state based on it, directly inside the state.
 * like
 * */
type StateType<Value> = Value | ((prev: Value) => Value);


/**
 * Simple wrapper around a state
 */
export interface ManagedState<T> {
	get(): T;

	set(value: StateType<T>): void;

	onWrite(onwrite: (value: T) => void): void;

	readonly key: string; // we want to keep the key so we can link up atoms by this

}

/**
 * Configures a ManagedState wrapper for use with out state manager
 *
 * @param key
 */
function _manageState<T>(key: string): ManagedState<T> {
	return {
		key: key,

		get: () => StateMgr.getState(key),
		set: (val: StateType<T>) => StateMgr.setState(key, val),
		onWrite: (onwrite) => StateMgr.setStateOnWrite(key, onwrite),
	};
}

/**
 * Here we add all the global state vars that we want to manage centrally
 *
 */
export const GlobalState = {
	App: {
		isCredoMode: _manageState<boolean>("isCredoMode"),
		emittedHints: _manageState<string[]>("emittedHints"),
		removedHints: _manageState<string>("removedHints"),
		isConnected: _manageState<boolean>("isConnected"),
		emojiData: _manageState<EmojiItem[]>("emojiData"),
	},
	UserSession: {
		session: _manageState<UserSession>("session"),
	},
	User: {
		profile: _manageState<UserProfile>("userProfile"),
		walletBalance: _manageState<number>("walletBalance"),
		permissions: _manageState<{
			dataStatus: DataStatus,
			[AppMode.CREDO]: Permission[],
			[AppMode.EGO]: Permission[],
		}>("permissions"),
		lighteningAddresses: _manageState<LighteningAddressesAtom>("lighteningAddresses"),
	},
	Boost: {
		tags: _manageState<{ data: BoostTag[], dataStatus: DataStatus }>("boostTags"),
	},
	Volts: {
		summary: _manageState<WalletSummaryForGraph>("walletSummary"),
	},
	Miscellaneous: {
		firstAtom: _manageState<number>("firstAtom"),
		secondAtom: _manageState<number>("secondAtom")
	}
};
