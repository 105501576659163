import React from "react";
import { atomSetterAtm } from "./atoms";
import {dbg, LogMgr, useEvtMgrListener} from "@credo/utilities";
import { EventConsts } from "./Consts";
import { useSetAtom } from "jotai";
import { UpdateStoreMessage } from "./types";

/**
 * This is not working
 * */
export const StateListener = () => {
	const handleStateMgr = useSetAtom(atomSetterAtm);

	const updateAtomStore = (msg: UpdateStoreMessage) => {
		if (dbg) {
			LogMgr.mydbg("StateListener:updateAtomStore:msg", msg);
		}
		handleStateMgr([msg.atomName, msg.nextValue]);
	};

	useEvtMgrListener(EventConsts.updateAtomStore, updateAtomStore);
	return null;
}