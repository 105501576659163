import { classNames, LazyLoad } from "@credo/ui-components";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const ReportAProblemPage = LazyLoad(
	() => import(/* webpackChunkName: "reportAProbPage" */ "./ReportAProblemPage"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"pt-4",
					"px-3",
					"text-basic",
					"bg-background",
					"content-with-header-and-nav",
				)}
			>
				<div />
			</div>
		),
	},
);
