import React, { useEffect, useState } from "react";
import {
	classNames,
	SvgIcon,
	BackIcon,
	ProVersionIcon,
	CredoBadge,
	StreamAccessControlIcon,
	StreamBotMngmtIcon,
	StreamDiscoverySettingsIcon,
	StreamJoinIntModeIcon,
	StreamJoinRequestsIcon,
	StreamPrivacySetttingsIcon,
	StreamRoleManagementIcon,
	StreamExpertiseIcon,
	ActivityIcon,
	DeleteIcon,
	StreamMemberManagementIcon,
	Spinner,
} from "@credo/ui-components";
import {
	useLocation, useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "react-use";
import {
	AppMode, cu, EvtMgr, ResponseCode, SessMgr, useEvtMgrListener, YesNoOptions,
} from "@credo/utilities";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { strings } from "../../i18n/config";
import {
	AnalyticEventsConst, AppUtils, Consts, EventConst,
} from "../../utils";
import { HelmetComponent } from "../../components/HelmetComponent";
import {
	isConnectedAtom, isSessionAuthAtom, streamProfileJoinReqCountAtom, userProfileAtom,
} from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";
import { StreamInfo } from "../../utils/types";
import { STREAM_PROFILE_PAGE } from "../../routes/constants";
import ExpertiseEditScreen from "./ExpertiseEditScreen";
import StreamAccessControlScreen from "./StreamAccessControlScreen";
import StreamDeleteScreen from "./StreamDeleteScreen";
import StreamJoinRequestsScreen from "./StreamJoinRequestsScreen";
import StreamPrivacySettingsScreen from "./StreamPrivacySettingsScreen";
import StreamDiscoverySettingsScreen from "./StreamDiscoverySettingsScreen";
import StreamJoinModeScreen from "./StreamJoinModeScreen";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";

export interface MenuItem {
	id: number,
	icon: any,
	title: string,
	appOnly?: boolean,
	url?: string,
	onClick?: () => void,
	devOnly?: boolean,
	isExternal?: boolean;
	nextIcon?: any,
}

interface NavigationParams {
	s_history: YesNoOptions
	entityId: string;
	postUuid: string;
	username: string;
	meta: {
		title: string;
	};
}

export enum StreamSettingsContentType {
	EDIT_EXPERTISE = "EDIT_EXPERTISE",
	JOIN_REQUESTS = "JOIN_REQUESTS",
	ACCESS_CONTROL = "ACCESS_CONTROL",
	PRIVACY_SETTINGS = "PRIVACY_SETTINGS",
	ROLE_MANAGEMENT = "ROLE_MANAGEMENT",
	MEMBER_MANAGEMENT = "MEMBER_MANAGEMENT",
	DISCOVERY_SETTINGS = "DISCOVERY_SETTINGS",
	JOIN_INTERACTION_MODE = "JOIN_INTERACTION_MODE",
	COMMUNITY_ACTIVITY = "COMMUNITY_ACTIVITY",
	DELETE_COMMUNITY = "DELETE_COMMUNITY",
	SETTINGS_MENU = "SETTINGS_MENU",
}

const StreamSettingsMenuScreen: React.FC = () => {
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const location = useLocation();
	const streamInfo = location?.state?.communityDetails ?? null;
	// const isFromRequestsButton = location?.state?.isFromRequestsButton ?? null;
	const isInMobile = isMobile && width < 768;
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [streamProfileJoinReqCount] = useAtom(streamProfileJoinReqCountAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isCurrentUserCredoAdmin, setIsCurrentUserCredoAdmin] = useState(null);
	const [isCurrentUserEgoAdmin, setIsCurrentUserEgoAdmin] = useState(null);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [contentType, setContentType] = useState<StreamSettingsContentType>();
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const { pathname } = window.location;
	const [userInfo] = useAtom(userProfileAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const communityId = pathname.includes("id=") ? pathname.substring(pathname.indexOf("=") + 1) : null;
	const params = useParams();
	const struname = params?.struname ?? null;
	const navigationParams: NavigationParams = (location.state as NavigationParams) || {
		s_history: "",
		entityId: "",
		postUuid: "",
		username: "",
		meta: {
			title: "",
		},
	};

	const getTitle = () => {
		switch (contentType) {
			case StreamSettingsContentType.EDIT_EXPERTISE:
				return strings("StreamSettingsMenuScreen.set_tags");
			case StreamSettingsContentType.PRIVACY_SETTINGS:
				return strings("StreamSettingsMenuScreen.privacy_settings");
			case StreamSettingsContentType.DISCOVERY_SETTINGS:
				return strings("StreamSettingsMenuScreen.discovery_settings");
			case StreamSettingsContentType.MEMBER_MANAGEMENT:
				return strings("StreamSettingsMenuScreen.member_management");
			case StreamSettingsContentType.ROLE_MANAGEMENT:
				return strings("StreamSettingsMenuScreen.role_management");
			case StreamSettingsContentType.JOIN_INTERACTION_MODE:
				return strings("StreamSettingsMenuScreen.join_and_interaction_mode");
			case StreamSettingsContentType.JOIN_REQUESTS:
				return strings("StreamSettingsMenuScreen.stream_joinrequests");
			case StreamSettingsContentType.ACCESS_CONTROL:
				return strings("StreamSettingsMenuScreen.stream_access_control");
			case StreamSettingsContentType.DELETE_COMMUNITY:
				return strings("StreamSettingsMenuScreen.delete_stream");
			case StreamSettingsContentType.COMMUNITY_ACTIVITY:
				return strings("StreamSettingsMenuScreen.stream_activity");
			default:
				return strings("StreamSettingsMenuScreen.community_settings");
		}
	};

	useSetAppHeader({
		title: getTitle(),
	}, contentType);

	const navigateToStreamProfile = () => {
		if (location.key === "default") {
			const username = communityDetails?.struname ?? struname;
			// window.location.replace(`${STREAM_PROFILE_PAGE}/${username}`);
			navigate(`${STREAM_PROFILE_PAGE}/${username}`, {
				replace: true,
			});
		} else {
			// need to go back to the stream profile, -1 takes us to the menu and not to stream profile.
			navigate(-2);
		}
	};

	const beforeValueChange = (handleSwitch: any) => {
		handleSwitch();
		navigateToStreamProfile();
	};

	useEvtMgrListener(EventConst.handleBeforeSwitchModeChange, beforeValueChange);
	useEvtMgrListener(EventConst.streamDeleted, navigateToStreamProfile);

	const getStreamDetailsFromServer = () => {
		if (isConnected && (communityId || struname) && SessMgr.getFromSession(Consts.user_id)) {
			StreamActions.retrieveStreamInfoFromSvr({
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				streamId: communityId || navigationParams.entityId || "",
				s_history: navigationParams.s_history,
				// Sending postUuid to identify the item in search history
				postUuid: navigationParams.postUuid,
				struname: struname || "",
			});
		}
	};

	const getFormattedStreamTags = (unFormattedTags: string[][]) => {
		const formattedTags: { tag: string; val: string; xpRank: string; }[] = [];
		if (unFormattedTags && unFormattedTags.length !== 0 && unFormattedTags[0][0]) {
			unFormattedTags.forEach((tag) => {
				formattedTags.push({
					tag: tag[0],
					val: tag[1],
					xpRank: tag[3],
				});
			});
		}
		return formattedTags;
	};

	const getStreamInfoWithNoAuth = () => {
		StreamActions.getNoAuthStreamInfo({
			streamId: communityId || "",
			struname: struname || "",
		});
	};

	useEffect(() => {
		if (!streamInfo) {
			setLoading(true);
			if (!isSessionAuth) {
				getStreamInfoWithNoAuth();
			} else {
				getStreamDetailsFromServer();
			}
		} else {
			setCommunityDetails(streamInfo);
		}
	}, [communityId, struname, streamInfo, isConnected]);

	useEffect(() => {
		if (communityDetails && !((isCredoMode && isCurrentUserCredoAdmin)
			|| (!isCredoMode && isCurrentUserEgoAdmin))) {
			navigateToStreamProfile();
		} else if (communityDetails && ((isCredoMode && isCurrentUserCredoAdmin)
			|| (!isCredoMode && isCurrentUserEgoAdmin))) {
			setLoading(false);
		}
	}, [isCurrentUserEgoAdmin, isCurrentUserCredoAdmin]);

	const checkIfUserIsAdmin = (
		streamAdmin: string[][],
		currentUserId: string,
		setIsCurrentUserAdmin: Function,
		setIsCrtUserSuperAdmin: Function,
		mode: string,
	) => {
		if (streamAdmin.length > 0) {
			const currentUserEgoAdmin = streamAdmin.find((single: string[]) => (single[3] === currentUserId && single[1] === mode));
			if (currentUserEgoAdmin) {
				setIsCurrentUserAdmin(true);
			} else {
				setIsCurrentUserAdmin(false);
			}
		}
	};

	const handleStreamInfo = (msg: any) => {
		if (cu.isSuccessMsg(msg)) {
			if (msg && msg.retcd === ResponseCode.OK && msg?.items && msg?.items.length > 0) {
				const data = msg?.items[0];
				setCommunityDetails(data);
			} else {
				navigateToStreamProfile();
			}
		} else {
			navigateToStreamProfile();
		}
	};

	const handleUpdatedStreamTags = (items: any) => {
		if (items && communityDetails) {
			setCommunityDetails({
				...communityDetails,
				uxptags: items ?? [],
			});
		}
	};

	const handleUpdateStreamInfo = (msg: any) => {
		if (cu.isSuccessMsg(msg) && msg?.items && msg?.items.length > 0 && msg?.items[0]) {
			setCommunityDetails({ ...communityDetails, ...msg?.items[0] });
		}
	};

	useEvtMgrListener(EventConst.streamInfo, handleStreamInfo);
	useEvtMgrListener(EventConst.updatedStreamTags, handleUpdatedStreamTags);
	useEvtMgrListener(EventConst.noAuthStreamInfo, handleStreamInfo);
	useEvtMgrListener(EventConst.updateStreamInfo, handleUpdateStreamInfo);

	useEffect(() => {
		if (userInfo && communityDetails) {
			if (communityDetails?.streamAdmin && communityDetails?.streamAdmin.length > 0) {
				checkIfUserIsAdmin(
					communityDetails?.streamAdmin,
					userInfo?.eprof_id || "",
					setIsCurrentUserEgoAdmin,
					() => { },
					AppMode.EGO,
				);
				checkIfUserIsAdmin(
					communityDetails?.streamAdmin,
					userInfo?.cprof_id || "",
					setIsCurrentUserCredoAdmin,
					() => { },
					AppMode.CREDO,
				);
			} else {
				navigateToStreamProfile();
			}
		} else if (!isSessionAuth) {
			navigateToStreamProfile();
		}
	}, [communityDetails]);

	useEffect(() => {
		if (searchParams.get("section")) {
			setContentType(searchParams.get("section") as StreamSettingsContentType);
		} else {
			setSearchParams({
				section: StreamSettingsContentType.SETTINGS_MENU,
			});
		}
	}, [searchParams]);

	const showStreamSection = (contentType: StreamSettingsContentType) => {
		setContentType(contentType);
		setSearchParams({
			section: contentType,
		});
	};

	const closeExpertiseSection = () => {
		navigate(-1);
	};

	const showDownloadModal = () => {
		AppUtils.handleDownloadAppModal(true);
	};

	const menuItems: MenuItem[] = [
		{
			id: 1,
			icon: StreamExpertiseIcon,
			title: strings("StreamSettingsMenuScreen.set_tags"),
			onClick: () => { showStreamSection(StreamSettingsContentType.EDIT_EXPERTISE); },
		},
		{
			id: 2,
			icon: StreamJoinRequestsIcon,
			title: strings("StreamSettingsMenuScreen.stream_joinrequests"),
			onClick: () => { showStreamSection(StreamSettingsContentType.JOIN_REQUESTS); },
			nextIcon: streamProfileJoinReqCount[`streamJoinReqCount${communityDetails?.streamId}`]
				&& ((isCredoMode && isCurrentUserCredoAdmin)
					|| (!isCredoMode && isCurrentUserEgoAdmin))
				? (
					<CredoBadge
						badgeValue={streamProfileJoinReqCount[`streamJoinReqCount${communityDetails?.streamId}`]
							? streamProfileJoinReqCount[`streamJoinReqCount${communityDetails?.streamId}`] : 0}
						containerStyle="ml-1.5"
					/>
				) : null,
		},
		{
			id: 3,
			icon: StreamAccessControlIcon,
			title: strings("StreamSettingsMenuScreen.stream_access_control"),
			onClick: () => { showStreamSection(StreamSettingsContentType.ACCESS_CONTROL); },
		},
		{
			id: 4,
			icon: StreamPrivacySetttingsIcon,
			title: strings("StreamSettingsMenuScreen.privacy_settings"),
			onClick: () => { showStreamSection(StreamSettingsContentType.PRIVACY_SETTINGS); },
		},
		{
			id: 5,
			icon: StreamRoleManagementIcon,
			title: strings("StreamSettingsMenuScreen.role_management"),
			onClick: showDownloadModal,
		},
		{
			id: 6,
			icon: StreamBotMngmtIcon,
			title: strings("StreamSettingsMenuScreen.bot_management"),
			onClick: showDownloadModal,
			nextIcon: <SvgIcon
				height={12}
				width={12}
				icon={ProVersionIcon}
				style={{ marginLeft: 5 }}
			/>,
		},
		{
			id: 7,
			icon: StreamMemberManagementIcon,
			title: strings("StreamSettingsMenuScreen.member_management"),
			onClick: showDownloadModal,
		},
		{
			id: 8,
			icon: StreamDiscoverySettingsIcon,
			title: strings("StreamSettingsMenuScreen.discovery_settings"),
			onClick: () => { showStreamSection(StreamSettingsContentType.DISCOVERY_SETTINGS); },
		},
		{
			id: 9,
			icon: StreamJoinIntModeIcon,
			title: strings("StreamSettingsMenuScreen.join_and_interaction_mode"),
			onClick: () => { showStreamSection(StreamSettingsContentType.JOIN_INTERACTION_MODE); },
		},
		{
			id: 10,
			icon: ActivityIcon,
			title: strings("StreamSettingsMenuScreen.stream_activity"),
			onClick: showDownloadModal,
		},
		{
			id: 11,
			icon: DeleteIcon,
			title: strings("StreamSettingsMenuScreen.delete_stream"),
			onClick: () => { showStreamSection(StreamSettingsContentType.DELETE_COMMUNITY); },
		},
	];

	useEffect(() => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.settingViewed,
		});
	}, []);

	const renderListItem = (item: MenuItem) => {
		const {
			title,
			id,
			icon,
			onClick,
			nextIcon,
		} = item;

		const baseStyleClasses = classNames(
			"bg-transparent w-full h-12",
			isInMobile ? "px-3" : "",
		);

		const renderItemContent = () => (
			<div className="flex justify-start items-center w-full">
				<div className="flex items-center justify-start w-full">
					<div className="flex items-center justify-center h-8 w-8">
						<SvgIcon icon={icon} width={22} height={22} color="var(--gray-dark)" />
					</div>
					<div
						className={classNames(
							"border-b border-post-bar-border w-full h-12",
							"flex justify-start items-center",
							"ml-2",
						)}
					>
						<span className="text-md">
							{title}
						</span>
						{nextIcon}
					</div>
				</div>
			</div>
		);

		if (onClick) {
			return (
				<button
					key={id}
					type="button"
					className={classNames(
						baseStyleClasses,
					)}
					onClick={onClick}
				>
					{renderItemContent()}
				</button>
			);
		}

		return null;
	};

	const renderList = () => (
		<div>
			{menuItems.map((item: MenuItem) => renderListItem(item))}
		</div>
	);

	if (loading) {
		return (
			<div className={classNames(
				"flex flex-col justify-center items-center",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic ",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-4",
			)}
			>
				<Spinner leaf_fill="var(--primary)" height="32" width="32" />
			</div>
		);
	}

	const renderSettingsMenu = () => (
		<div className="flex flex-col">
			{/* <div className="flex flex-row items-center">
				<SvgIcon
					icon={BackIcon}
					className={classNames(isMobile ? "ml-4" : "ml-2", "cursor-pointer")}
					color="var(--text-base)"
					onClick={navigateToStreamProfile}
				/>
				<h4
					className={classNames(
						"text-md font-normal text-basic px-3",
					)}
				>
					{strings("StreamSettingsMenuScreen.group_settings")}
				</h4>
			</div> */}
			<div className="block min-h-[calc(100vh-64px)]">
				{renderList()}
			</div>
		</div>
	);

	const renderContent = () => {
		switch (contentType) {
			case StreamSettingsContentType.EDIT_EXPERTISE:
				return (
					<ExpertiseEditScreen
						addedExpertise={communityDetails && communityDetails?.uxptags
							? getFormattedStreamTags(communityDetails?.uxptags ?? []) : []}
						tagLimit={4}
						streamId={communityDetails?.streamId || communityId}
						onDone={closeExpertiseSection}
					/>
				);
			case StreamSettingsContentType.PRIVACY_SETTINGS:
				return (
					<StreamPrivacySettingsScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.DISCOVERY_SETTINGS:
				return (
					<StreamDiscoverySettingsScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.MEMBER_MANAGEMENT:
			case StreamSettingsContentType.ROLE_MANAGEMENT:
			case StreamSettingsContentType.JOIN_INTERACTION_MODE:
				return (
					<StreamJoinModeScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.JOIN_REQUESTS:
				return (
					<StreamJoinRequestsScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.ACCESS_CONTROL:
				return (
					<StreamAccessControlScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.DELETE_COMMUNITY:
				return (
					<StreamDeleteScreen
						onDone={closeExpertiseSection}
						streamInfo={communityDetails}
					/>
				);
			case StreamSettingsContentType.COMMUNITY_ACTIVITY:
				return null;
			default: return renderSettingsMenu();
		}
	};

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-6",
			)}
		>
			<HelmetComponent
				title={strings("StreamSettingsMenuScreen.group_settings")}
				url={location?.pathname}
			/>
			<div className="pt-3">
				{renderContent()}
			</div>
		</div>
	);
};

export default StreamSettingsMenuScreen;
