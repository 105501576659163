import { DOMRectExtended } from "@credo/ui-components";

interface GetFirstElementOptions {
	parentComponentId: string;
	/**
	 * Mainly because of modal, on modal we need to match
	 * zIndex with modal and also for other elements where
	 * we can get that right
	 * */
	customZIndex?: number;
	fixed?: boolean;
}

/**
 * This will be in web-app so that we can add
 * new methods here itself instead of adding
 * them everytime in the storybook
 * */
// eslint-disable-next-line import/prefer-default-export
export class ElementLocators {
	static getClientRect(parentComponentId: string): DOMRect | undefined {
		if (document) {
			const element = document.getElementById(parentComponentId);
			// Safe to say getBoundingClientRect gets the component's height when component is rendered.
			return element?.getBoundingClientRect();
		}
		return undefined;
	}

	static getFirstElement(
		options: GetFirstElementOptions,
	): DOMRectExtended | undefined {
		const {
			customZIndex,
			parentComponentId,
			fixed,
		} = options;
		if (document && window) {
			const element = document.getElementById(parentComponentId);
			// TODO: This is getting the child instead of the target component should we?
			const firstElement = element?.firstElementChild;
			if (firstElement) {
				const rect = firstElement.getBoundingClientRect();
				const zIndex = customZIndex ?? document?.defaultView?.getComputedStyle(element as Element)?.getPropertyValue("z-index");
				if (fixed) {
					return {
						...rect.toJSON(),
						zIndex,
					};
				}
				/**
				 * getBoundingClientRect gives you dimensions from the viewport
				 * if the window is scrolled the data was broken hence need to
				 * add window.scrollX and window.scrollY when accessing the positions
				 * */
				return {
					...rect.toJSON(),
					top: firstElement.getBoundingClientRect().top + window.scrollY,
					left: firstElement.getBoundingClientRect().left + window.scrollX,
					zIndex,
				};
			}
			return undefined;
		}
		return undefined;
	}

	static getCredoSwitch(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "credo-switch" });
	}

	static getCredoSwitchFixed(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "credo-switch", fixed: true });
	}

	static getPostCredoTag(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "post/credo_tag", fixed: false, customZIndex: 11 });
	}

	static getPostAuthorTag(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "post/author_tags", fixed: false, customZIndex: 11 });
	}

	static getCredoGraphSwitch(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "cg_switch", fixed: true });
	}

	static getGoogleButtonFromLoginModal(): DOMRectExtended | undefined {
		return ElementLocators.getFirstElement({ parentComponentId: "google-login-button", fixed: false, customZIndex: 206 });
	}
}
