import React, { useMemo } from "react";
import { cu, PostBoostInfo } from "@credo/utilities";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { WalletBalance } from "./WalletBalance";
import {
	classNames, isWideCalc, SvgIcon, WEB_SIZE_PROP_BUTTON,
} from "../../../common";
import {
	BoostedPostIcon, BoostExpensesIcon, BoostPLIcon, BoostRevenueIcon,
} from "../../../assets/icons";
import { BalanceItemWrapper } from "./BalanceItemWrapper";
import Style from "../BoostTray.module.css";

interface BoostStatsProps {
	/**
	 * post boost info
	 *
	 * @see PostBoostInfo
	 * */
	postBoostInfo: PostBoostInfo | undefined;
	isWide: boolean;
}

const StatsSkeleton = ({ isWide }: { isWide: boolean }) => (
	<SkeletonTheme
		baseColor="var(--background-dark-main)"
		highlightColor="#171C2D"
	>
		<div className="p-4 flex flex-col">
			<div className="mt-1">
				<Skeleton
					height={20}
					width={isWideCalc(isWide, 72, WEB_SIZE_PROP_BUTTON)}
				/>
			</div>
			<div className="mt-2">
				<Skeleton
					height={20}
					width={isWideCalc(isWide, 72, WEB_SIZE_PROP_BUTTON)}
				/>
			</div>
			<div className="mt-2">
				<Skeleton
					height={40}
					width={isWideCalc(isWide, 72, WEB_SIZE_PROP_BUTTON)}
				/>
			</div>
		</div>
	</SkeletonTheme>
);

/**
 * Displays boost stats for current user. Tt consists of
 * 1. User's wallet balance
 * 2. User's boost level
 * 3. Personal Boost P/L
 * */
const BoostStatsComponent: React.FC<BoostStatsProps> = (props: BoostStatsProps) => {
	const {
		postBoostInfo,
		isWide,
	} = props;

	const netSign: string = useMemo(() => {
		if (!postBoostInfo) return "";
		if (postBoostInfo.pnl.earned - postBoostInfo.pnl.spent === 0) {
			return "";
		}
		if (postBoostInfo.pnl.earned - postBoostInfo.pnl.spent < 0) {
			return "-";
		} else {
			return "+";
		}
	}, [postBoostInfo]);

	if (!postBoostInfo) {
		return (
			<StatsSkeleton isWide={isWide} />
		);
	}

	return (
		<div className="p-4 flex flex-col">
			<div className="mt-1">
				<WalletBalance isWide={isWide} />
			</div>
			{/* Should this be a separate component? */}
			<BalanceItemWrapper
				id="user-boost-level"
				className="mt-2"
				isWide={isWide}
				title={(postBoostInfo.user_boost ?? 0).toString()}
			>
				<SvgIcon
					icon={BoostedPostIcon}
					width={isWideCalc(isWide, 14, WEB_SIZE_PROP_BUTTON)}
					className="mb-px"
				/>
				<span
					className="text-xs text-tag-green align-right"
				>
					{cu.formatNumberWithThousandsSep(postBoostInfo.user_boost ?? 0)}
				</span>
			</BalanceItemWrapper>

			<BalanceItemWrapper
				id="user-profit-level"
				className="mt-2"
				isWide={isWide}
				title={(postBoostInfo.pnl.earned ?? 0).toString()}
			>
				<SvgIcon
					icon={BoostRevenueIcon}
					width={isWideCalc(isWide, 14, WEB_SIZE_PROP_BUTTON)}
					className="mb-px"
				/>
				<span
					className="text-xs text-tag-green align-right"
				>
					+
					{cu.formatNumberWithThousandsSep(postBoostInfo.pnl.earned ?? 0)}
				</span>
			</BalanceItemWrapper>
			<BalanceItemWrapper
				id="user-loss-level"
				isWide={isWide}
				title={(postBoostInfo.pnl.spent ?? 0).toString()}
			>
				<SvgIcon
					icon={BoostExpensesIcon}
					width={isWideCalc(isWide, 14, WEB_SIZE_PROP_BUTTON)}
					className="mb-px"
				/>
				<span
					className="text-xs text-error align-right"
				>
					-
					{cu.formatNumberWithThousandsSep(postBoostInfo.pnl.spent ?? 0)}
				</span>
			</BalanceItemWrapper>
			<div
				className={classNames(
					Style.trayDividerDark,
					"h-px mx-1",
				)}
			/>
			<BalanceItemWrapper
				id="user-pnl-delta-level"
				isWide={isWide}
				title={(postBoostInfo.pnl.net ?? 0).toString()}
			>
				<SvgIcon
					icon={BoostPLIcon}
					width={isWideCalc(isWide, 14, WEB_SIZE_PROP_BUTTON)}
					className="mb-px"
				/>
				<span
					className={classNames(
						"text-xs text-tag-green align-right",
						postBoostInfo.pnl.earned - postBoostInfo.pnl.spent < 0 ? "text-error" : "text-tag-green",
					)}
				>
					{netSign}
					{cu.formatNumberWithThousandsSep(postBoostInfo.pnl.net ?? 0)}
				</span>
			</BalanceItemWrapper>
		</div>
	);
};

const compareFn = (
	nextProps: BoostStatsProps, prevProps: BoostStatsProps,
) => JSON.stringify(nextProps.postBoostInfo) === JSON.stringify(prevProps.postBoostInfo)
	&& nextProps.isWide === prevProps.isWide;

// eslint-disable-next-line import/prefer-default-export
export const BoostStats = React.memo(
	BoostStatsComponent,
	compareFn,
);
