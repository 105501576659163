import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
	ButtonSize, RadioButtonEmptyIcon, RadioButtonSelectedIcon, PrimaryButton, classNames,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { AppMode } from "@credo/utilities";
import { strings } from "../../i18n/config";
import { Consts } from "../../utils";

export interface NewStreamSettingsScreenProps {
	onSubmit: (obj: any) => void;
	onChangeValues: (obj: any) => void;
	selectedValues: any;
}

export const StreamDiscoveryOptions = [
	{
		label: strings("StreamDiscoverySettingsOptions.public"),
		description: strings("StreamDiscoverySettingsOptions.all_users_of_the_platform"),
		value: Consts.public,
	},
	{
		label: strings("StreamDiscoverySettingsOptions.connections_of_connections"),
		description: strings("StreamDiscoverySettingsOptions.connections_of_any_connections"),
		value: Consts.connections_of_connections,
	},
	{
		label: strings("StreamDiscoverySettingsOptions.participant_connections"),
		description: strings("StreamDiscoverySettingsOptions.connections_of_the_group_participants"),
		value: Consts.participant_connections,
	},
	{
		label: strings("StreamDiscoverySettingsOptions.owner_connections"),
		description: strings("StreamDiscoverySettingsOptions.visible_to_direct_connections"),
		value: Consts.owener_connections,
	},
	{
		label: strings("StreamDiscoverySettingsOptions.private"),
		description: strings("StreamDiscoverySettingsOptions.only_visible_to_you"),
		value: Consts.private,
	},
];

const NewStreamSettingsScreen = (props: NewStreamSettingsScreenProps) => {
	const {
		onSubmit,
		selectedValues,
		onChangeValues,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);

	const StreamJoinInteractionOptions = [
		{
			label: strings("StreamJoinModeOptions.ego_or_credo"),
			description: strings("StreamJoinModeOptions.ego_or_credo_desc"),
			value: Consts.ego_or_credo,
		},
		{
			label: strings("StreamJoinModeOptions.credo_only"),
			description: strings("StreamJoinModeOptions.credo_only_desc"),
			value: Consts.credo,
			disabled: !isCredoMode,
		},
		{
			label: strings("StreamJoinModeOptions.ego_only"),
			description: strings("StreamJoinModeOptions.ego_only_desc"),
			value: Consts.ego,
			disabled: isCredoMode,
		},
	];

	const [selectedDiscovery, setSelectedDiscovery] = useState(selectedValues?.disc
		? selectedValues?.disc : StreamDiscoveryOptions[0].value);
	const [selectedMode, setSelectedMode] = useState(selectedValues?.joinModes
		? selectedValues?.joinModes : StreamJoinInteractionOptions[0].value);

	const submitReport = () => {
		if ((isCredoMode && selectedMode !== AppMode.EGO)
			|| (!isCredoMode && selectedMode !== AppMode.CREDO)) {
			const stream = { disc: selectedDiscovery, joinModes: selectedMode };
			onSubmit(stream);
		}
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		if ((isCredoMode && selectedMode === AppMode.EGO)
			|| (!isCredoMode && selectedMode === AppMode.CREDO)) {
			setSelectedMode(StreamJoinInteractionOptions[0].value);
		}
	}, [isCredoMode, selectedMode]);

	const onChangeDiscovery = (text: any) => {
		setSelectedDiscovery(text);
		const stream = { disc: text, joinModes: selectedMode };
		onChangeValues(stream);
	};

	const onChangeJoinMode = (text: any) => {
		setSelectedMode(text);
		const stream = { disc: selectedDiscovery, joinModes: text };
		onChangeValues(stream);
	};

	return (
		<div className="flex flex-col md:px-2 2xs:px-5">
			<div className="flex flex-col pt-2">
				<span className="text-sm font-medium text-title-color pb-2">
					{strings("NewStreamSettingsScreen.group_discovery")}
				</span>
				<RadioGroup value={selectedDiscovery} onChange={onChangeDiscovery}>
					{StreamDiscoveryOptions.map((item: any) => (
						<RadioGroup.Option key={item.value} value={item.value}>
							{({ checked }) => (
								<div className="pl-2">
									<div
										className="flex flex-row py-2.5 items-center hover:cursor-pointer"
									>
										<div className="w-10 h-6">
											{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
										</div>
										<div className="w-full">
											<p className="text-sm font-medium text-title-color">{item.label}</p>
											<p className="text-sm font-small text-gray-dark">{item.description}</p>
										</div>
									</div>
									<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
								</div>
							)}
						</RadioGroup.Option>
					))}
				</RadioGroup>
			</div>
			<div className="py-5 flex flex-col">
				<span className="text-sm font-medium text-title-color">
					{strings("NewStreamSettingsScreen.join_and_interection_mode")}
				</span>
				<span className="text-sm font-small text-gray-dark pb-2">
					{strings("NewStreamSettingsScreen.join_and_interection_mode_desc")}
				</span>
				<RadioGroup value={selectedMode} onChange={onChangeJoinMode}>
					{StreamJoinInteractionOptions.map((item: any) => (
						<RadioGroup.Option key={item.value} value={item.value} disabled={item?.disabled}>
							{({ checked }) => (
								<div className={classNames("pl-2", item?.disabled ? "opacity-50" : "")}>
									<div
										className="flex flex-row py-2.5 items-center hover:cursor-pointer"
									>
										<div className="w-10 h-6">
											{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
										</div>
										<div className="w-full">
											<p className="text-sm font-medium text-title-color">{item.label}</p>
											<p className="text-sm font-small text-gray-dark">{item.description}</p>
										</div>
									</div>
									<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
								</div>
							)}
						</RadioGroup.Option>
					))}
				</RadioGroup>
			</div>
			<div className="flex items-center justify-center my-4">
				<PrimaryButton
					size={ButtonSize.MEDIUM}
					label={strings("NewStreamSettingsScreen.next")}
					disabled={!selectedDiscovery || !selectedMode}
					handleClick={submitReport}
				/>
			</div>
		</div>
	);
};

export default NewStreamSettingsScreen;
