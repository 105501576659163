/* eslint-disable react/require-default-props */
import React, {
	useEffect, useMemo, useState,
} from "react";
import {
	classNames, InputField, ButtonSize, PrimaryButton, SecondaryButton, ConfirmationModal,
} from "@credo/ui-components";
import { useFormik } from "formik";
import {
	AppMode, cu, EvtMgr,
} from "@credo/utilities";
import * as Yup from "yup";
import debounce from "lodash/debounce";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { userSessionAtom } from "../../utils/atoms";
import { strings } from "../../i18n/config";
import { RegEx } from "../../config/RegEx";
import { INPUT_VALUE_DEBOUNCE_TIME, MAX_STREAM_NAME_CHAR, MAX_USER_INTRO_CHAR } from "../../config/constants";
import { Consts, EventConst } from "../../utils";
import { StreamInfo } from "../../utils/types";
import { StreamActions } from "../../services/actions/stream";
import { SearchActions } from "../../services/actions/search";

interface StreamDetailsForm {
	name: string;
	struname: string;
	descr: string;
}

interface StreamEditDetailsProps {
	onClose: any;
	item?: StreamInfo;
	onDone?: any;
	newStreamObj?: any;
}

enum StreamNameStatusType {
	AVAILABLE = "available",
	CHECKING = "checking",
}

export default function StreamEditDetails({
	onClose,
	item,
	onDone,
	newStreamObj,
}: StreamEditDetailsProps) {
	const [userSession] = useAtom(userSessionAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [valuesChanged, setValuesChanged] = useState<boolean>(false);
	const [loadingComplete, setIsLoadingComplete] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [streamName, setStreamName] = useState<string>(newStreamObj?.name ?? "");
	const [streamNameStatus, setStreamNameStatus] = useState<StreamNameStatusType | null>(null);
	const [streamUsername, setStreamUsername] = useState<string>("");
	const [streamUsernameStatus, setStreamUsernameStatus] = useState<StreamNameStatusType | null>(null);
	const initialFormValues = {
		name: newStreamObj?.name ?? item?.name ?? "",
		struname: newStreamObj?.struname ?? item?.struname ?? "",
		descr: newStreamObj?.descr ?? item?.descr ?? "",
	};

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
	};

	const onCancel = () => {
		if (onClose) {
			onClose();
		}
		closeConfirmationModal();
	};

	const formSchema = Yup.object().shape({
		name: Yup
			.string()
			.required("")
			.min(1, strings("AddOrEditStreamDetailsModal.min_chars_validation_message"))
			.matches(
				RegEx.credoWord,
				strings("AddOrEditStreamDetailsModal.reserved_word_credo"),
			)
			.matches(
				RegEx.alphaNumericOnly,
				strings("AddOrEditStreamDetailsModal.eng_chars_only"),
			),
		struname: Yup
			.string()
			.min(3, strings("AddOrEditStreamDetailsModal.min_chars_validation_message"))
			.required("")
			.matches(
				RegEx.credoWord,
				strings("AddOrEditStreamDetailsModal.reserved_word_credo"),
			)
			.matches(
				RegEx.alphaNumericOnly,
				strings("AddOrEditStreamDetailsModal.please_enter_valid_username"),
			),
		descr: Yup
			.string()
			.min(5, strings("AddOrEditStreamDetailsModal.discussion_group_description_error"))
			.required("")
			.max(250),
	});

	const handleFormSubmit = async (formValues: StreamDetailsForm) => {
		setIsLoading(true);
		const stream = {
			name: formValues.name.trim(),
			descr: formValues.descr.trim(),
			struname: formValues.struname.trim(),
			style: isCredoMode ? AppMode.CREDO : AppMode.EGO,
			type: Consts.type_discussion_group_stream,
			hier: Consts.hier_parent_stream,
			categ: null,
		};
		if (item?.streamId) {
			const updateStream = {
				user_id: userSession?.user_id || "",
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				name: formValues.name.trim(),
				descr: formValues.descr.trim(),
				struname: formValues.struname.trim(),
				style: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				type: Consts.type_discussion_group_stream,
				hier: Consts.hier_parent_stream,
				categ: item?.categ,
				disc: item?.disc,
				streamId: item?.streamId,
			};
			StreamActions.updateStreamInfo(
				updateStream,
				(response) => {
					EvtMgr.getInstance(EventConst.updateStreamInfo).notifyListeners({
						...response,
						items: [{
							name: formValues.name.trim(),
							descr: formValues.descr.trim(),
							struname: formValues.struname.trim(),
						}],
					});
					setIsLoadingComplete(true);
					setIsLoading(false);
				},
				() => {
					setIsLoadingComplete(true);
					setIsLoading(false);
				},
			);
		} else {
			// TODO: handle add stream navigation
			onDone(stream);
			setIsLoadingComplete(true);
			setIsLoading(false);
		}
		onCancel();
	};

	const {
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		values,
		isValid,
		validateForm,
		setFieldValue,
		setFieldError,
		touched,
	} = useFormik({
		initialValues: initialFormValues,
		validationSchema: formSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: handleFormSubmit,
	});

	const checkStreamUsername = async () => {
		const msgObj = {
			what: values?.struname.trim(),
			s_struname: "y",
			user_id: userSession?.user_id || "",
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
		};

		const handleSearchResultsSuccess = (response: any) => {
			if (response?.s_struname === "y") {
				if (response.items && response?.items?.length === 0) {
					setStreamUsernameStatus(StreamNameStatusType.AVAILABLE);
				} else {
					setFieldError("struname", strings("AddOrEditStreamDetailsModal.username_not_available"));
					setStreamUsernameStatus(null);
				}
			}
		};

		const handleSearchResultsError = () => {
			setStreamUsernameStatus(null);
		};

		await SearchActions.getSearchResults(
			msgObj,
			(res) => handleSearchResultsSuccess({ ...res, ...msgObj }),
			handleSearchResultsError,
		);
	};

	const setNameAsUsername = (e: any) => {
		if (!item) {
			if (streamNameStatus === StreamNameStatusType.AVAILABLE) {
				if (values?.name) {
					const username = values?.name.replace(/[ !@#$%^&*()+\\=\\[\]{};':"\\|,.<>\\/?]/g, "");
					setFieldValue("struname", username.toLowerCase());
				} else {
					setStreamUsername(values?.struname);
				}
			} else {
				setStreamUsername(values?.struname);
			}
		}
		if (handleBlur) handleBlur(e);
	};

	const checkStreamName = async () => {
		const strname = streamName.trim();

		const msgObj = {
			what: strname,
			s_streams: "y",
			user_id: userSession?.user_id || "",
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
		};

		const handleSearchResultsSuccess = (response: any) => {
			if (response?.s_streams === "y") {
				if (response.items && response?.items?.length === 0) {
					setStreamNameStatus(StreamNameStatusType.AVAILABLE);
				} else {
					setFieldError("name", strings("AddOrEditStreamDetailsModal.name_not_available"));
					setStreamNameStatus(null);
				}
			}
		};

		const handleSearchResultsError = () => {
			setStreamNameStatus(null);
		};

		await SearchActions.getSearchResults(
			msgObj,
			(res) => handleSearchResultsSuccess({ ...res, ...msgObj }),
			handleSearchResultsError,
		);
	};

	const checkAvailabilityOfStreamName = async () => {
		if (streamName && !errors.name && (!item || item?.name !== values.name)) {
			await checkStreamName();
		}
	};

	const debouncedValidateName = useMemo(
		() => debounce(checkAvailabilityOfStreamName, 300),
		[values?.name, touched],
	);

	useEffect(() => {
		if (streamName && !errors.name && (!item || item?.name !== values.name)) {
			setStreamNameStatus(StreamNameStatusType.CHECKING);
			debouncedValidateName();
		} else if (item?.name === values.name) {
			setStreamNameStatus(null);
		}
	}, [values?.name, errors?.name]);

	const checkAvailabilityOfStreamUsername = async () => {
		if (values?.struname && !errors.struname && (!item || item?.struname !== values.struname)) {
			setStreamUsernameStatus(StreamNameStatusType.CHECKING);
			await checkStreamUsername();
		}
	};

	const debouncedValidateUsername = useMemo(
		() => debounce(checkAvailabilityOfStreamUsername, 300),
		[values?.struname, touched],
	);

	useEffect(() => {
		if (values?.struname && !errors.struname && (!item || item?.struname !== values.struname)) {
			debouncedValidateUsername();
		} else if (item?.struname === values.struname || !values?.struname) {
			setStreamUsernameStatus(null);
		}
	}, [values?.struname]);

	const getStreamUsernameStatus = () => {
		const statusText = () => {
			switch (streamUsernameStatus) {
				case StreamNameStatusType.AVAILABLE:
					return strings("AddOrEditStreamDetailsModal.username_available");
				case StreamNameStatusType.CHECKING:
					return strings("AddOrEditStreamDetailsModal.checking");
				default: return "";
			}
		};

		const getStatusClass = () => {
			switch (streamUsernameStatus) {
				case StreamNameStatusType.AVAILABLE:
					return "text-accent";
				case StreamNameStatusType.CHECKING:
					return "text-basic loading-dots font-thin";
				default: return "";
			}
		};
		return (
			<span
				className={classNames(
					"text-left text-support absolute mt-1 left-3.5 pl-8 -bottom-5",
					getStatusClass(),
				)}
			>
				{statusText()}
			</span>
		);
	};

	const getStreamNameStatus = () => {
		const statusText = () => {
			switch (streamNameStatus) {
				case StreamNameStatusType.AVAILABLE:
					return strings("AddOrEditStreamDetailsModal.name_available");
				case StreamNameStatusType.CHECKING:
					return strings("AddOrEditStreamDetailsModal.checking");
				default: return "";
			}
		};

		const getStatusClass = () => {
			switch (streamNameStatus) {
				case StreamNameStatusType.AVAILABLE:
					return "text-accent";
				case StreamNameStatusType.CHECKING:
					return "text-basic loading-dots font-thin";
				default: return "";
			}
		};
		return (
			<span
				className={classNames(
					"text-left text-support absolute mt-1 left-3.5 -bottom-5",
					getStatusClass(),
				)}
			>
				{statusText()}
			</span>
		);
	};

	const checkForPreviousValues = () => {
		setValuesChanged(
			values?.name?.toLowerCase() !== item?.name?.toLowerCase()
			|| values?.struname?.toLowerCase() !== item?.struname?.toLowerCase()
			|| values?.descr?.trim()?.toLowerCase() !== item?.descr?.trim()?.toLowerCase(),
		);
	};

	const handleValidateForm = () => {
		validateForm();
	};

	const debouncedValidate = useMemo(
		() => debounce(handleValidateForm, INPUT_VALUE_DEBOUNCE_TIME),
		[validateForm, touched],
	);

	/**
	 * Here we can handle the values before adding to values
	 * object of formik. In case of trimming and other stuff
	 * can be done here if required
	 * */
	const handleMaxCharLimit = (code: string, value: any, limit: number) => {
		const handleSetFieldValue = () => {
			if (value.length > limit) {
				setFieldValue(code, value.slice(0, limit));
			}
		};

		switch (code) {
			case "name":
			case "struname":
			case "descr":
				handleSetFieldValue();
				break;

			default: break;
		}
	};

	useEffect(
		() => {
			handleMaxCharLimit("name", values.name, MAX_STREAM_NAME_CHAR);
			handleMaxCharLimit("struname", values.struname, MAX_STREAM_NAME_CHAR);
			handleMaxCharLimit("descr", values.descr, MAX_USER_INTRO_CHAR);
			debouncedValidate();
			checkForPreviousValues();
		},
		[values, debouncedValidate],
	);

	useEffect(
		() => {
			if (!item || !initialFormValues.struname) {
				setStreamUsername(values.struname);
			}
		},
		[values?.struname],
	);

	useEffect(
		() => {
			setStreamName(values.name);
		},
		[values?.name],
	);

	const showConfirmationModal = () => {
		if (valuesChanged) {
			setOpenConfirmationModal(true);
		} else {
			onCancel();
		}
	};

	return (
		<div className="flex flex-col px-6 xs:px-16 sm:px-24 pt-3">
			<form onSubmit={handleSubmit}>
				<div className="flex justify-start items-center w-full flex-1 relative">
					<InputField
						value={values.name}
						name="name"
						wrapperClassName="mt-2"
						inputClassName={classNames("pl-4")}
						onChange={handleChange}
						placeholder={strings("AddOrEditStreamDetailsModal.stream_name")}
						error={errors.name}
						onBlur={setNameAsUsername}
						onInput={(e) => {
							// @ts-ignore says value is not present on target
							e.target.value = e.target.value?.toString().slice(0, MAX_STREAM_NAME_CHAR);
							// @ts-ignore says value is not present on target
							e.target.value = e.target.value.replace(/\s\s+/g, " ")
								.replace(/\u2013+|\u2014+/g, "-")
								.replace(/- -/g, "");
						}}
					/>
					{!errors.name && streamNameStatus && values.name && getStreamNameStatus()}
					<div className="text-gray-dark absolute text-support right-3 -bottom-5">
						{values.name.length}
						/
						{MAX_STREAM_NAME_CHAR}
					</div>
				</div>
				<div className="flex justify-start items-center w-full flex-1 relative">
					<span
						className={classNames(
							"flex justify-center items-center h-full mt-8 pr-4",
							"font-thin text-basic",
						)}
					>
						{strings("AddOrEditStreamDetailsModal.c")}
						/
					</span>
					<InputField
						value={values.struname}
						name="struname"
						wrapperClassName="mt-8 w-full"
						inputClassName={classNames("pl-4", initialFormValues.struname && !newStreamObj ? "!opacity-40 rounded-full" : "")}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder={strings("AddOrEditStreamDetailsModal.stream_username")}
						onInput={(e) => {
							// username should be always lowercase even while rendering
							// @ts-ignore says value is not present on target
							e.target.value = e.target.value?.toString()?.toLowerCase().slice(0, MAX_STREAM_NAME_CHAR);
							// @ts-ignore says value is not present on target
							e.target.value = e.target.value.replace(/\s\s+/g, " ")
								.replace(/\u2013+|\u2014+/g, "-")
								.replace(/- -/g, "");
						}}
						disabled={initialFormValues.struname && !newStreamObj}
						error={errors.struname}
					/>
					{!errors.struname && streamUsernameStatus && getStreamUsernameStatus()}
					<div className="text-gray-dark absolute text-support right-3 -bottom-5">
						{values.struname.length}
						/
						{MAX_STREAM_NAME_CHAR}
					</div>
				</div>
				<div className="flex justify-start items-center w-full flex-1 relative">
					<InputField
						value={values.descr}
						name="descr"
						wrapperClassName="mt-8"
						inputClassName="pl-4 max-h-[10rem] !h-28"
						onChange={handleChange}
						placeholder={strings("AddOrEditStreamDetailsModal.stream_description")}
						error={errors.descr}
						onBlur={handleBlur}
						onInput={(e) => {
							// @ts-ignore says value is not present on target
							e.target.value = e.target.value?.toString().slice(0, MAX_USER_INTRO_CHAR);
						}}
						rows={6}
						isMultiline
					/>
					<div className="text-gray-dark absolute text-support right-3 -bottom-5">
						{values.descr.length}
						/
						{MAX_USER_INTRO_CHAR}
					</div>
				</div>
				<div className="flex flex-1 w-full justify-center items-center mt-8 px-5">
					{item && (
						<SecondaryButton
							size={ButtonSize.MEDIUM}
							label={strings("ProfileDetailsForm.cancel")}
							type="button"
							onClick={showConfirmationModal}
							buttonClassNames="mt-5 mr-5"
						/>
					)}
					<PrimaryButton
						size={ButtonSize.MEDIUM}
						label={cu.isSet(item) ? strings("ProfileDetailsForm.update") : strings("ProfileDetailsForm.next")}
						type="submit"
						buttonClassNames="mt-5"
						disabled={!isValid
							|| isLoading
							|| loadingComplete
							|| !valuesChanged
							|| streamUsernameStatus === StreamNameStatusType.CHECKING
							|| streamNameStatus === StreamNameStatusType.CHECKING}
						isLoading={isLoading}
					/>
				</div>
			</form>
			<ConfirmationModal
				isVisible={openConfirmationModal}
				onClose={closeConfirmationModal}
				cancel_button_function={closeConfirmationModal}
				ok_button_title={strings("AddOrEditStreamDetailsModal.discard")}
				cancel_button_title={strings("AddOrEditStreamDetailsModal.cancel")}
				ok_button_function={onCancel}
				title={strings("AddOrEditStreamDetailsModal.title")}
				message={strings("AddOrEditStreamDetailsModal.confirmation_message")}
				okButtonStyle=""
				cancelButtonStyle=""
			/>
		</div>
	);
}

StreamEditDetails.defaultPropd = {
	iten: null,
};
