import React, { useEffect, useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
	RadioButtonEmptyIcon, RadioButtonSelectedIcon, classNames, SvgIcon, BackIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { cu, EvtMgr } from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { strings } from "../../i18n/config";
import { Consts, EventConst } from "../../utils";
import { ReportListItem, StreamInfo, StreamPrivacyOptions } from "../../utils/types";
import { userSessionAtom } from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";

export interface StreamPrivacySettingsScreenProps {
	onDone: () => void;
	streamInfo: any;
}

const streamSeeFollowersOptions = [
	{ label: strings("StreamPrivacySettingsScreen.everyone"), value: StreamPrivacyOptions.EVERYONE },
	{ label: strings("StreamPrivacySettingsScreen.members_only"), value: StreamPrivacyOptions.MEMBERS_ONLY },
	{ label: strings("StreamPrivacySettingsScreen.no_one"), value: StreamPrivacyOptions.NO_ONE },
];

const streamSeeMembersOptions = [
	{ label: strings("StreamPrivacySettingsScreen.everyone"), value: StreamPrivacyOptions.EVERYONE },
	{ label: strings("StreamPrivacySettingsScreen.members_only"), value: StreamPrivacyOptions.MEMBERS_ONLY },
	{ label: strings("StreamPrivacySettingsScreen.no_one"), value: StreamPrivacyOptions.NO_ONE },
];

const StreamPrivacySettingsScreen = (props: StreamPrivacySettingsScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [userSession] = useAtom(userSessionAtom);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(streamInfo);

	useEffect(() => {
		setCommunityDetails(streamInfo);
	}, [streamInfo]);

	const StreamDetails = useMemo(() => ({
		seeFollowers: streamInfo?.[Consts.ps_folsee],
		seeMembers: streamInfo?.[Consts.ps_memsee],
	}), [communityDetails]);

	const updateStreamSettings = (item: string, value: any) => {
		const updateStream = {
			user_id: userSession?.user_id || "",
			mode: cu.getAppMode(),
			name: streamInfo?.name,
			descr: streamInfo.descr,
			struname: streamInfo.struname,
			style: streamInfo?.style,
			type: streamInfo?.type,
			hier: streamInfo?.hier,
			categ: streamInfo?.categ,
			disc: streamInfo?.disc,
			streamId: streamInfo?.streamId,
			[item]: value,
		};

		StreamActions.updateStreamInfo(
			updateStream,
			(response) => {
				EvtMgr.getInstance(EventConst.updateStreamInfo).notifyListeners({
					...response,
					items: [updateStream],
				});
				setCommunityDetails({
					...communityDetails,
					...updateStream,
				});
			},
			() => { },
		);
	};

	const onStreamSeeFollowersChange = (value: any) => {
		updateStreamSettings(Consts.ps_folsee, value);
	};

	const onStreamSeeMemberChange = (value: any) => {
		updateStreamSettings(Consts.ps_memsee, value);
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const renderRadioGroupItem = (item: ReportListItem) => (
		<RadioGroup.Option key={item.value} value={item.value}>
			{({ checked }) => (
				<div className="px-2">
					<div
						className="flex flex-row py-2.5 items-center hover:cursor-pointer"
					>
						<div className="w-10 h-6">
							{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
						</div>
						<div className="w-full">
							<p className="text-sm font-medium text-title-color">{item.label}</p>
						</div>
					</div>
					<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
				</div>
			)}
		</RadioGroup.Option>
	);

	return (
		<div className="w-full flex flex-col pb-3 px-3 h-[calc(100vh-100px)]">
			{/* <div className="flex flex-row items-center -mx-3">
				<SvgIcon
					icon={BackIcon}
					className={classNames(isMobile ? "ml-4" : "ml-2", "cursor-pointer")}
					color="var(--text-base)"
					onClick={onDone}
				/>
				<h4
					className={classNames(
						"text-md font-normal text-basic px-3",
					)}
				>
					{strings("StreamPrivacySettingsScreen.privacy_settings")}
				</h4>
			</div> */}
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamPrivacySettingsScreen.who_can_see_followers")}
			</span>
			<RadioGroup value={StreamDetails.seeFollowers} onChange={onStreamSeeFollowersChange}>
				{streamSeeFollowersOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamPrivacySettingsScreen.who_can_see_members")}
			</span>
			<RadioGroup value={StreamDetails.seeMembers} onChange={onStreamSeeMemberChange}>
				{streamSeeMembersOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
		</div>
	);
};

export default StreamPrivacySettingsScreen;
