/**
 * The idea is to capture any event which is fired
 * on the dom, and it will be identified as last action
 * be it mouse move or keypress or mouse click. We
 * will capture all the events and add a listener
 * to update the timestamp accordingly.
 * */
import React, { useEffect, useRef } from "react";
import { Consts } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const LastActionListener: React.FC = () => {
	// eslint-disable-next-line no-undef
	const timer = useRef<NodeJS.Timeout | null>(null);

	const eventsListen = [
		"click",
		"keydown",
		"scroll",
		"resize",
		"mousemove",
		"TabSelect",
		"TabHide",
	];

	const getLastAction = () => {
		if (localStorage.getItem(Consts.lastActionStamp) !== null) {
			return parseInt(localStorage.getItem(Consts.lastActionStamp) || "0", 10);
		}
		return 0;
	};

	const check = () => {
		const now = Date.now();
		const timeLeft = getLastAction() + Consts.minutesToReloadIdle * 60 * 1000;
		const diff = timeLeft - now;
		const isTimeout = diff < 0;
		if (isTimeout) {
			window.location.reload();
		}
	};

	const initInterval = () => {
		timer.current = setInterval(() => {
			check();
		}, Consts.checkLastActionInterval);
	};

	const reset = () => {
		const currentTime = Date.now().toString();
		localStorage.setItem(Consts.lastActionStamp, currentTime);
	};

	const initListener = () => {
		reset();
		if (document) {
			eventsListen.forEach((eventName) => {
				document.addEventListener(eventName, reset);
			});
		}
	};

	useEffect(() => {
		initListener();
		initInterval();
		check();
	}, []);

	return null;
};
