/* eslint-disable react/require-default-props */
import React from "react";
import { PrimaryButton } from "../button/PrimaryButton";
import { ButtonSize } from "../button/types";
import { classNames, Consts, ScalerSize } from "../common";
import ExpertiseTag from "../expertise-tag/ExpertiseTag";
import { XIcon } from "../assets/icons";

export interface CredoTagBarProps {
	/**
	 * tag data
	 * @default ""
	 * */
	tagData?: Array<any>;
	/**
	 * Custom component for tag
	 * @default ""
	 * */
	renderItem?: any;
	/**
	 * Custom CSS class for tag container
	 * @default ""
	 * */
	containerStyle?: string;
	/**
	 * set direction for the tag
	 * @default ""
	 * */
	vertical?: boolean;
	/**
	 * shows remove button on tag
	 * @default ""
	 * */
	showRemoveButton?: boolean;
	/**
	 * checks whether tag data is in array or not
	 * @default ""
	 * */
	isResponseInArray?: boolean;
	/**
	 * onPressTag handler for tag press
	 * @default ""
	 * */
	onPressTag?: (item: any) => void;
	/**
	 * Sets css class to a single tag className
	 * @default ""
	 * */
	tagClassName?: string;
	hasRated?: boolean;
	onPressRemoveButton?: (item: any) => void;
	disabledAddMoreTags?: boolean;
	tagLimit?: number;
	showProposedTagIndicator?: boolean;
	renderShowMoreButton?: any;
}

export function CredoTagBar({
	tagData = [],
	renderItem,
	containerStyle,
	vertical,
	showRemoveButton,
	isResponseInArray,
	onPressTag,
	tagClassName,
	hasRated,
	onPressRemoveButton,
	disabledAddMoreTags,
	tagLimit,
	showProposedTagIndicator = false,
	renderShowMoreButton,
}: CredoTagBarProps) {
	const renderTags = ({ item, index }: any) => (renderItem ? (
		renderItem({ item, index })
	) : (
		<ExpertiseTag
			key={index}
			title={isResponseInArray ? item[0] : item.tag}
			onPress={() => onPressTag && onPressTag(item)}
			onPressRemoveButton={() => onPressRemoveButton && onPressRemoveButton(item)}
			buttonStyle={classNames(
				showProposedTagIndicator && item?.type === Consts.proposeTag ? "border-2 border-dashed border-proposed-tag" : "",
				tagClassName,
			)}
			hasRated={hasRated}
			size={ScalerSize.M}
			iconRight={showRemoveButton ? <XIcon width="8px" height="8px" strokeWidth={2} stroke="var(--tag-unrated)" /> : null}
		/>
	));

	const onClickAddTags = () => {
		if (onPressTag) {
			onPressTag({ doAddTag: true });
		}
	};

	const renderMoreButton = () => {
		if (renderShowMoreButton) {
			return renderShowMoreButton();
		}
		if (!disabledAddMoreTags) {
			return (
				<PrimaryButton
					size={ButtonSize.MEDIUM}
					label={tagData?.length > 0 ? "+ Add More Tags" : "+ Add Tags"}
					disabled={tagLimit && tagLimit !== -1 ? tagData?.length >= tagLimit : false}
					onClick={onClickAddTags}
					buttonClassNames={classNames("mb-2 font-medium")}
					dimensionsClasses={classNames("h-7 w-36")}
				/>
			);
		}
		return null;
	};

	const renderTagBarVertically = () => (
		<div className={classNames("flex flex-wrap flex-col")}>
			{tagData?.map((tag, i) => renderTags({ item: tag, index: i }))}
			{renderMoreButton()}
		</div>
	);

	const renderTagBarHorizontally = () => (
		<div className={classNames("flex flex-wrap flex-row items-center")}>
			{tagData?.map((tag, i) => renderTags({ item: tag, index: i }))}
			{renderMoreButton()}
		</div>
	);

	const renderTagBar = () => {
		if (vertical) {
			return renderTagBarVertically();
		}
		return renderTagBarHorizontally();
	};

	return (
		<div className={classNames("flex w-full relative bg-background min-h-[60px] py-2", containerStyle)}>
			{renderTagBar()}
		</div>
	);
}

CredoTagBar.defaultProps = {
	vertical: false,
	tagClassName: "",
	hasRated: false,
	tagLimit: 5,
};
