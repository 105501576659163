import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { HOME_PAGE } from "./constants";
import { isSessionAuthAtom } from "../utils/atoms";

function PublicRoutes() {
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	return isSessionAuth ? (
		<Navigate to={HOME_PAGE} />
	) : (
		<Outlet />
	);
}

export default PublicRoutes;
