import { EngageTabOptions, FeedType } from "./types";
import { strings } from "../i18n/config";

// eslint-disable-next-line import/prefer-default-export
export const Consts = {
	discord_link: "https://discord.gg/ZMUXc3Urxv",
	discord_widget_link: "https://discord.com/widget?id=1055679751064391811&theme=dark",
	support_query_mail: "mailto:help@credo.app",
	main_wall_credo_stream_id: "mwc",
	main_wall_ego_stream_id: "mwe",
	umas_stream_name: "UMAS",
	credo_blast_stream_id: "cb",
	userEgoRecentPost: "userEgoRecentPost",
	userId: "user_id",
	processAppEvt: "processAppEvt",
	sid: "sid",
	sess: "sess",
	regComplete: "regComplete",
	firstName: "firstName",
	lastName: "lastName",
	deviceToken: "dvc_tok",
	user_id: "user_id",
	userRequestsLastTs: "userRequestsLastTs",
	userRequestsCnt: "userRequestsCnt",
	credouserRequestsCnt: "credouserRequestsCnt",
	egouserRequestsCnt: "egouserRequestsCnt",
	userNotifsLastTs: "userNotifsLastTs",
	userNotifsCnt: "userNotifsCnt",
	clientIPAddress: "clientIPAddress",
	clientCountryCode: "clientCountryCode",
	clientCountryData: "clientCountryData",
	clientLocation: "clientLocation",
	mode: "mode",
	saveDeviceToken: "saveDeviceToken",
	qrCodeLogin: "qrCodeLogin",
	isCredoMode: "isCredoMode",
	browserId: "browserId",
	hasPushNotifPerm: "hasPushNotifPerm",
	isQRAuth: "is_qr_auth",
	cookiesInfo: "cookiesInfo",
	showDownloadAppBanner: "showDownloadAppBanner",
	showLoginModal: "showLoginModal",
	email4SignInLinkToEmail: "email4SignInLinkToEmail",
	customEmailDetails: "customEmailDetails",
	streamJoinReqCountAtom: "streamJoinReqCountAtom",
	streamProfileJoinReqCountAtom: "streamProfileJoinReqCountAtom",
	phone: "phone",
	socialAuthType: "socialAuthType",
	showSocialLoading: "showSocialLoading",
	unVerifiedPhoneNumber: "unVerifiedPhoneNumber",
	phoneVerification: "phoneVerification",
	userQualification: "userQualification",
	userExperience: "userExperience",
	allCachedImages: "allCachedImages",
	newPostUserStreams: "newPostUserStreams",
	pendingTasksAtom: "pendingTasksAtom",
	pendingTasks: "pendingTasks",
	purpose_id_check: "purpose_id_check",
	savePostAdultContent: "savePostAdultContent",
	purpose_new_post: "new_post",
	mentionUserFetchSize: 15,
	// registration using custom account in progress flag, should set as "y" if true
	emailRegInProcess: "eRegProc",
	// registration using social account in progress flag, should set as "y" if true
	socialRegInProcess: "sRegProc",
	// Authentication modal content type, sets the content which should be displayed in the modal
	authContent: "aCont",
	feedCardsFetch: "feedCardsFetchCount",
	feedCardsFetchCount: 11,
	NoInternet: "NoInternet",
	regex_valid_url: new RegExp("^"
		// protocol identifier
		+ "(?:(?:https?|ftp)://)"
		// user:pass authentication
		+ "(?:\\S+(?::\\S*)?@)?"
		+ "(?:"
		// IP address exclusion
		// private & local networks
		+ "(?!(?:10|127)(?:\\.\\d{1,3}){3})"
		+ "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})"
		+ "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})"
		// IP address dotted notation octets
		// excludes loopback network 0.0.0.0
		// excludes reserved space >= 224.0.0.0
		// excludes network & broacast addresses
		// (first & last IP address of each class)
		+ "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])"
		+ "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}"
		+ "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))"
		+ "|"
		// host name
		+ "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)"
		// domain name
		+ "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*"
		// TLD identifier
		+ "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))"
		// TLD may end with dot
		+ "\\.?"
		+ ")"
		// port number
		+ "(?::\\d{2,5})?"
		// resource path
		+ "(?:[/?#]\\S*)?"
		+ "$", "i"),
	// comment scope
	scope_allComments: "all_comments",
	scope_subComments: "sub_comments",
	scope_parentComments: "parent_comments",
	sharedObj: "sharedObj",
	PostRejected: "r",
	PostSharing: "sh",
	PostUpdating: "up",
	PostFailed: "f",
	Posting: "a",
	regCode: "regCode",
	// User is rating a for the first time
	ratingFirstTime: "ratingFT",
	metaData: "clientMetadata",
	emoItems: "emoItems",
	fetchGraphDataInterval: 300000, // 5 min
	commentReacted: "comment_reacted",
	lastActionStamp: "lastActionStamp",
	minutesToReloadIdle: 30, // 30 minutes
	checkLastActionInterval: 300000, // 5 min
	isURLPresentRegex: /\bhttps?:\/\/\S+/gi,
	type_discussion_group_stream: "dg",
	hier_parent_stream: "p",
	ps_folsee: "ps_folsee",
	ps_memsee: "ps_memsee",
	ac_folcom: "ac_folcom",
	ac_joicom: "ac_joicom",
	ac_joiaut: "ac_joiaut",
	ac_alwpos: "ac_alwpos",
	updatePhoneNumber: "updatePhoneNumber",
	privacySettings: {
		everyOne: "ee",
		connectionsOfConnections: "cc",
		noOne: "no",
		directConnections: "dc",
		followers: "fo",
	},
	privacySetting4FollowUserCredoActivity: "ps_c_evtfoluse",
	privacySetting4FollowUserEgoActivity: "ps_e_evtfoluse",
	privacySetting4FollowCommCredoActivity: "ps_c_evtfolcom",
	privacySetting4FollowCommEgoActivity: "ps_e_evtfolcom",
	privacySetting4NewConnectionCredoActivity: "ps_c_evtusecon",
	privacySetting4NewConnectionEgoActivity: "ps_e_evtusecon",
	privacySetting4JoinCommCredoActivity: "ps_c_evtjoicom",
	privacySetting4JoinCommEgoActivity: "ps_e_evtjoicom",
	privacySetting4FollowYouCredoActivity: "ps_c_folyou",
	privacySetting4SeeCommunititesCredoActivity: "ps_c_comsee",
	privacySetting4SeeFollowersCredoActivity: "ps_c_folsee",
	privacySetting4ConnectYouCredoActivity: "ps_c_conyou",
	privacySetting4SeeConnectionsCredoActivity: "ps_c_consee",
	privacySetting4FollowYouEgoActivity: "ps_e_folyou",
	privacySetting4SeeCommunititesmEgoActivity: "ps_e_comsee",
	privacySetting4SeeFollowersEgoActivity: "ps_e_folsee",
	privacySetting4ConnectYouEgoActivity: "ps_e_conyou",
	privacySetting4SeeConnectionsEgoActivity: "ps_e_consee",
	owener_connections: "owco",
	private: "priv",
	participant_connections: "paco",
	connections_of_connections: "coco",
	public: "publ",
	ego_or_credo: "ec",
	credo: "c",
	ego: "e",
	proposeTag: "proposeTag",
	similarTags: "similarTags",
	proposedTagRegex: /^([A-Za-z])\w{1,39}$/,
	alphaNumericTagsRegex: /^([a-zA-Z0-9_ ]+)$/,
	isBottomNavVisible: "isBottomNavVisible",
	WITHDRAW_REQUEST_TIMEOUT: 10000,
	activeBoostTag: "activeBoostTag",
};

export const EventConst = {
	getHomeFeed: "getHomeFeed",
	showNewPostDiscardModal: "showNewPostDiscardModal",
	getNoAuthHomeFeed: "get_NoAuthHomeFeed",
	getUserStreamItems: "get_userStreamItems",
	getStreamItems: "get_streamItems",
	socketOnConnect: "socketOnConnect",
	socketOnDisconnect: "socketOnDisconnect",
	userInfo: "userInfo",
	updateUserSess: "updateUserSess",
	logoutUser: "tmp.logout",
	loginSuccess: "login_success",
	userNotificationsItems: "userNotificationsItems",
	userRequestsItems: "userRequestsItems",
	sessAuth: "sessAuth",
	showDefaultEmailModal: "showDefaultEmailModal",
	removeSocialConnection: "removeSocialConnection",
	saveData: "saveData",
	wsConn: "tmp.ws.conn",
	qrLogin: "qrLogin",
	pushNotificationPerm: "pushNotificationPerm",
	saveDeviceToken: "saveDeviceToken",
	removeListItem: "removeListItem",
	loadRequests: "loadRequests",
	loadNotifications: "loadNotifications",
	updatedStreamTags: "updatedStreamTags",
	/**
	 * Loading network from notif_req_msg since we don't get
	 * push notifications for network like requests or notifs
	 * */
	loadNetwork: "loadNetwork",
	streamInfo: "streamInfo",
	mngRelation4Stream: "mngRelation4Stream",
	actionOnTask: "actionOnTask",
	streamDeleted: "streamDeleted",
	showLoginModal: "showLoginModal",
	showToast: "showToast",
	handleValidationModal: "handleValidationModal",
	streamJoinReqCountUpdated: "streamJoinReqCountUpdated",
	handleBeforeSwitchModeChange: "handleBeforeSwitchModeChange",
	socialAuthProcessComplete: "socialAuthProcessComplete",
	showSocialLoading: "showSocialLoading",
	handleLkdInEmailNotFound: "handleLkdInEmailNotFound",
	streamItems: "streamItems",
	noAuthStreamInfo: "NoAuthStreamInfo",
	noAuthStreamItems: "NoAuthStreamItems",
	userInfoByProfId: "userInfoByProfId",
	changeAuthModalType: "changeAuthModalType",
	clearSession: "clearSession",
	toggleTheme: "toggleTheme",
	updateUserInfo: "updateUserInfo",
	userHistoryItemsByProfId: "userHistoryItemsByProfId",
	userQualification: "userQualification",
	saveCachedImages: "saveCachedImages",
	userHistoryItems: "userHistoryItems",
	setAllTags: "setAllTags",
	allTagsLoading: "allTagsLoading",
	NoAuthUserInfo: "NoAuthUserInfo",
	userStreamItems: "userStreamItems",
	setUserSearchHistory: "setUserSearchHistory",
	showDownloadAppModal: "showDownloadAppModal",
	onDeletePost: "onDeletePost",
	setAllSubTags: "setAllSubTags",
	NoAuthUserStreamItems: "NoAuthUserStreamItems",
	NoAuthUserHistoryItems: "NoAuthUserHistoryItems",
	newPostUserStreams: "newPostUserStreams",
	handleSwitchChange: "handleSwitchChange",
	showNewPostModal: "showNewPostModal",
	updatePendingTasks: "updatePendingTasks",
	handlePostFromFeed: "handlePostFromFeed",
	updateStreamData: "updateStreamData",
	updateOtherUserData: "updateOtherUserData",
	/**
	 * While signing up using social we need the firebaseUID/LinkedInID and
	 * email, so we will save that data here and make user when
	 * EmailNotYetRegistered component unmounts this atom will be reset to null
	 * */
	setSocialEmailCred: "setSocialEmailCred",
	clearSearch: "clearSearch",
	setRouteParent: "setRouteParent",
	notifReqCount: "notifReqCount",
	saveComment: "saveComment",
	postDeleted: "postDeleted",
	commentManipulated: "commentManipulated",
	commentDeleted: "commentDeleted",
	postSharedCount: "postSharedCount",
	postShared: "postShared",
	commentAdded: "commentAdded",
	pendingSubComment: "pendingSubComment",
	updateAtomStore: "updateAtomStore",
	updateFeedData: "updateFeedData",
	addingNewPostFeed: "addingNewPostFeed",
	showHomeSuspense: "showHomeSuspense",
	showConfirmationModal: "showConfirmationModal",
	resetQR: "resetQR",
	logAnalyticsEvent: "logAnalyticsEvent",
	ratingTutorialModal: "ratingTutorialModal",
	getNetworkNotifications: "getNetworkNotifications",
	getUserClientMetadata: "getUserClientMetadata",
	showEmailRegisterModal: "showEmailRegisterModal",
	showCredoGraph: "showCredoGraph",
	hintActions: "hintActions",
	refreshHomeFeed: "refreshHomeFeed",
	updateStreamInfo: "updateStreamInfo",
	rateCommentTag: "rateCommentTag",
	boostBoardDeleteModal: "boostBoardDeleteModal",
	boostBoardReportModal: "boostBoardReportModal",
	getPostGraphData: "getPostGraphData",
	pushPostGraphDataToComp: "pushPostGraphDataToComp",
	setUserWalletBalance: "setUserWalletBalance",
	showCredoGraphHint: "showCredoGraphHint",
	boostBoardFetch: "boost-board-fetch",
	boostPostActionUpdate: "boostPostActionUpdate",
	showVoltsDashboardModal: "showVoltsDashboardModal",
	trayOnExpand: "trayOnExpand",
	showManageAddressesModal: "showManageAddressesModal",
	// disable esc button for child dashboard modals
	disableEscVoltsDashModal: "disableEscVoltsDashboardModal",
	fetchWalletHistory: "fetchWalletHistory",
	lightningDepositUpdate: "lightning_deposit_update",
	feedFilter: "feedFilter",
};

export const MsgConst = {
	userRequestsItems: "userRequestsItems",
	customEmail: "customEmail",
	actionOnTask: "actionOnTask", // actionOnTask is common API call used for performing action like accept ,reject and cancel.
	notifyLogout2Svr: "notifyLogout2Svr",
	preLoginEmailVerification: "preLoginEmailVerification",
	streamInfo: "streamInfo",
	noAuthStreamInfo: "NoAuthStreamInfo",
	noAuthStreamItems: "NoAuthStreamItems",
	mngRelation4Stream: "mngRelation4Stream",
	linkedInAuth: "linkedinAuth",
	streamItems: "streamItems",
	userInfoByProfId: "userInfoByProfId",
	mngRelation4User: "mngRelation4User",
	getPhoneVerification: "get_phoneVerification",
	scheduleChangeEgoName: "get_scheduleChangeEgoName",
	updateUserInfo: "updateUserInfo",
	getSearchData: "get_searchData",
	getCheckUsername: "get_checkUsername",
	getNoAuthSearchData: "get_NoAuthSearchData",
	userHistoryItemsByProfId: "userHistoryItemsByProfId",
	mngXpItem: "mngXpItem",
	rateUser4XpItem: "rateUser4XpItem",
	postInfo: "postInfo",
	NoAuthPostInfo: "NoAuthPostInfo",
	NoAuthUserInfoByProfId: "NoAuthUserInfoByProfId",
	userStreamItems: "userStreamItems",
	userStreams: "userStreams",
	NoAuthUserStreamItemsProcessor: "NoAuthUserStreamItemsProcessor",
	NoAuthUserStreamItems: "NoAuthUserStreamItems",
	NoAuthUserHistoryItems: "NoAuthUserHistoryItems",
	xpRatingCards4User: "xpRatingCards4User",
	sugConnCards4User: "sugConnCards4User",
	suggestions4User: "suggestions4User",
	mngSugconItem: "mngSugconItem",
	noAuthReportProblem: "noauthReportProblem",
	getUserNotificationsItems: "get_userNotificationsItems",
	ratePostByUser: "ratePostByUser",
	emoItems: "emoItems",
	mngEmocons4Post: "mngEmocons4Post",
	mngEmocons4Comments: "mngEmocons4Comments",
	notif_req_msg: "notif_req_msg",
	get_linkPreview: "get_linkPreview",
	saveComment: "saveComment",
	deleteItem: "deleteItem",
	postCommentsItems: "postCommentsItems",
	recom4user: "get_recom4user",
	noAuthRecom4user: "get_NoAuthRecom4user",
	mngRecomItem: "get_mngRecomItem",
	shareItemIntoStream: "shareItemIntoStream",
	getPostCommentsItems: "get_postCommentsItems",
	getNoAuthPostCommentItems: "get_NoAuthPostCommentItems",
	savePost: "savePost",
	getNetworkNotifications: "getNetworkNotifications",
	mngUserClientMetadata: "mngUserClientMetadata",
	getUserClientMetadata: "getUserClientMetadata",
	updateStreamInfo: "updateStreamInfo",
	blockedUsersItems: "get_blockedUsersItems",
	createStream: "createStream",
	streamMembersItems: "get_streamMembersItems",
	streamFollowersItems: "get_streamFollowersItems",
	getUserWalletSummary: "getUserWalletSummary",
	getUserWalletHistory: "getUserWalletHistory",
	getUserBoosts: "getUserBoosts",
	getUserWalletBalance: "getUserWalletBalance",
	getBoostBoard: "getBoostBoard",
	getBoostBoardInfo: "getBoostBoardInfo",
	getPostBoostInfo: "getPostBoostInfo",
	boostActionUpdate: "boost_action_update",
	lightningDepositUpdate: "lightning_deposit_update",
	getUserPermissions: "get_userPermissions",
	mngUserLightningAddresses: "mngUserLightningAddresses",
	getUserLightningAddresses: "getUserLightningAddresses",
	withdrawVoltsLn: "withdrawLn",
	depositVoltsLn: "depositLn",
	getFeedFilterTags: "getFeedFilterTags",
};

export const AnalyticEventsConst = {
	share: "share",
	signInViaEmailLinkLogin: "signInViaEmailLink_login",
	signUp: "signup",
	login: "login",
	appLaunch: "app_launch",
	newPost: "new_post",
	followStream: "community_follow",
	joinStream: "community_join",
	followUser: "otheruser_follow",
	connectUser: "otheruser_connect",

	rateUserExpertiseCardTag: "card_rate_user_expertise_tag",
	ratePostTag: "post_rate_tag",
	rateUserProfileTag: "profile_rate_user_tag",
	rateUserExpertiseCardTagDismiss: "rate_user_expertise_card_tag_dismiss",
	exploreFollowCommunity: "explore_follow_community",
	cardSuggConnConnect: "card_sugg_conn_connect",
	cardSuggConnDismiss: "card_sugg_conn_dismiss",
	cardCommunityFollow: "card_community_follow",
	cardCommunityDismiss: "card_community_dismiss",

	postDelete: "post_delete",
	postEdit: "post_edit",
	postVisitPostDetails: "post_visit_post_details",
	postViewComments: "post_view_comments",
	postResharePost: "post_reshare_post",
	postReshareLink: "post_reshare_link",
	postEmoji: "post_emoji",
	postEmojiSelected: "post_emoji_selected",
	postMoreMenu: "post_more_menu",
	postShareCopyLink: "post_share_copy_link",
	postReport: "post_report",

	communityViewProfile: "community_view_profile",
	communityPressPostButton: "community_press_post_button",
	communityMoreMenu: "community_more_menu",
	communityViewMembersList: "community_view_members_list",
	communityViewFollowersList: "community_view_followers_list",
	communityLeaveCommunity: "community_leave_community",
	communityShareCopyLink: "community_share_copy_link",
	communityReport: "community_report",

	otheruserProfileView: "otheruser_profile_view",
	otheruserMoreMenu: "otheruser_more_menu",
	otheruserViewConnectionsList: "otheruser_view_connections_list",
	otheruserViewCommunitiesList: "otheruser_view_communities_list",
	otheruserViewFollowersList: "otheruser_view_followers_list",
	otheruserRemoveConnection: "otheruser_remove_connection",
	otheruserShareCopyLink: "otheruser_share_copy_link",
	otheruserReport: "otheruser_report",

	commentNewFromHomefeed: "comment_new_from_homefeed",
	commentNewFromCommunity: "comment_new_from_community",
	commentNewFromPostDetails: "comment_new_from_postdetails",
	commentMoreMenu: "comment_more_menu",
	commentDelete: "comment_delete",
	commentEdit: "comment_edit",
	commentShareCopyLink: "comment_share_copy_link",
	commentReport: "comment_report",
	commentEmoji: "comment_emoji",

	userprofileViewed: "userprofile_viewed",
	userprofileEditEgoProfile: "userprofile_edit_ego_profile",
	userprofileEditCredoProfile: "userprofile_edit_credo_profile",
	userprofileSpinNewCredoUsername: "userprofile_spin_new_credo_username", // Feature Not Available on WEB
	userprofileGenerateNewCredoUsername: "userprofile_generate_new_credo_username", // Feature Not Available on WEB
	userprofileViewEgoConnectionList: "userprofile_view_ego_connection_list",
	userprofileViewEgoFollowerList: "userprofile_view_ego_follower_list",
	userprofileViewEgoCommunityList: "userprofile_view_ego_community_list",
	userprofileViewCredoFollowerList: "userprofile_view_credo_follower_list",
	userprofileViewCredoCommunityList: "userprofile_view_credo_community_list",
	userprofileShareEgoProfile: "userprofile_share_ego_profile",
	userprofileViewEgoActivity: "userprofile_view_ego_activity",
	userprofileViewCredoActivity: "userprofile_view_credo_activity",

	settingViewed: "setting_viewed",
	settingLogout: "setting_logout",
};

export const ClientMetaData = {
	hffy: true, // Home feed FOR YOU explain banner
	hfn: true, // Home feed NEWS explain banner
	hfb: true, // Home feed BLASTS explain banner
	npe: true, // New Post post in ego mode
	npc: true, // New Post post in credo mode
	ecom: true, // Engage tab COMMUNITIES explain banner
	eexp: true, // Engage tab EXPLORE explain banner
	edas: true, // Engage tab DASHBOARD explain banner
	epeo: true, // Engage tab PEOPLE explain banner
	ebot: true, // Engage tab BOT explain banner
	rh: "", // removed hints
};

export const ClientMetaDataMapping = {
	[FeedType.FEED]: "hffy",
	[FeedType.NEWS]: "hfn",
	[FeedType.BLASTS]: "hfb",
	[FeedType.TOP]: "hft",
	newPostEgo: "npe",
	newPostCredo: "npc",
	[EngageTabOptions.COMMUNITIES]: "ecom",
	[EngageTabOptions.EXPLORE]: "eexp",
	[EngageTabOptions.DASHBOARD]: "edas",
	[EngageTabOptions.PEOPLE]: "epeo",
	[EngageTabOptions.BOTS]: "ebot",
	removedHints: "rh",
};

export const TAG_MESSAGES = {
	view_tutorial: strings("TagMessages.view_tutorial"),
	creed_high: strings("TagMessages.creed_high"),
	creed_med: strings("TagMessages.creed_med"),
	creed_low: strings("TagMessages.creed_low"),
	boost_this_post: strings("TagMessages.boost_this_post"),
	boost_info: strings("TagMessages.boost_info"),
};

export const FeatureMetaForPerm = {
	boostBoard: {
		name: "Boost Board",
		id: "boost-board",
	},
	voltsDashboard: {
		name: "Volts Dashboard",
		id: "volts-dashboard",
	},
};

export const SORT_BY_FILTER_DATA = [
	{
		id: "1",
		title: "Relevance",
		description: "Relevance",
		value: "relevance",
	},
	{
		id: "2",
		title: "Time",
		description: "Time",
		value: "time",
	},
	{
		id: "3",
		title: "Credibility",
		description: "Credibility",
		value: "credibility",
	},
];
export const FILTER_BY_CREDIBILITY_DATA = [
	{
		id: "1",
		title: "Low",
		description: "Low",
		value: "low",
	},
	{
		id: "2",
		title: "Medium",
		description: "Medium",
		value: "medium",
	},
	{
		id: "3",
		title: "High",
		description: "High",
		value: "high",
	},
];
