import React, { useMemo, useState } from "react";
import { classNames } from "../../common";
import styles from "../indicator-html.module.css";
import { CreedSelectorContent, CreedSelectorContentProps } from "./creed-selector-content";
import { ModalPopup, ModalState } from "../modal-popup";
import { SvgRatingSelectorProps } from "../svg-rating-selector";

interface CreedSelectorModalProps extends CreedSelectorContentProps, Omit<SvgRatingSelectorProps, "size"> {
	pillPosition: {
		x: number,
		y: number,
		elem_x: number,
		elem_y: number,
	};
}

// eslint-disable-next-line import/prefer-default-export
export const CreedSelectorModal: React.FC<CreedSelectorModalProps> = (props: CreedSelectorModalProps) => {
	const {
		tag,
		messages,
		rating,
		onCancel,
		onSelected,
		onRatingHover,
		anchor,
		egoInfoProps,
		boostTrayProps,
		pillPosition,
		tagDataProvider,
	} = props;

	const [popupState, setPopupState] = useState<ModalState>(ModalState.Active);

	const modalPosition = useMemo(() => {
		/**
		 * 350 is the height of the modal.
		 * Getting dynamic height of the content is difficult because
		 * boost tab has more height than rating and initially rating
		 * tab is open.
		 * 350 => we are considering the boost tab's height, so it is
		 * fit perfectly.
		 * */
		if (window.innerHeight < pillPosition.elem_y + 350) {
			return {
				bottom: 32,
			};
		}
		return {};
	}, [pillPosition]);

	const closeDown = (success: boolean = false) => {
		setPopupState(ModalState.Closing);
		onCancel();
	};

	/**
	 * This is called by the modal, on state change
	 * @param state
	 */
	const onModalStateChange = (state: ModalState) => {
		if (popupState === state) {
			return;
		}

		// Logger.debug("ModalStateChange:" + state);
		setPopupState(state);
	};

	return (
		<ModalPopup
			onModalStateChange={onModalStateChange}
			onClose={closeDown}
			style={modalPosition}
		>
			<div
				className={classNames(
					styles.selectorBgGlassEffect,
					"rounded-3xl p-4",
				)}
			>
				<CreedSelectorContent
					tag={tag}
					messages={messages}
					rating={rating ?? 0}
					onCancel={onCancel}
					onSelected={onSelected}
					onRatingHover={onRatingHover}
					anchor={anchor}
					showEgoModal={false}
					egoInfoProps={egoInfoProps}
					boostTrayProps={boostTrayProps}
					tagDataProvider={tagDataProvider}
				/>
			</div>
		</ModalPopup>
	);
};
