import CfgMgr from "../config/CfgMgr";

globalThis.cfg = {
	credoWebSocketSvr: {
		nossl: CfgMgr.cfg_credoWebSocketSvr_nossl,
		ssl: CfgMgr.cfg_credoWebSocketSvr_ssl,
	},
	googleMapsApiKey: null,
	googleYoutubeApiKey: null,
};
globalThis.msgQueueMgr = null;
