import React, { useCallback } from "react";
import {
	cu, EvtMgr, NoRateTooltipFor, PostTag, TagIconType, useWindowSize,
} from "@credo/utilities";
import {
	classNames, EventConst, ScalerSize, SvgIcon,
} from "../common";
import { createDataProvider, CredoTag, TagMessages } from "../credo-tag";
import { CredoSwitch, CredoSwitchSize } from "../credo-switch";
import { TagNoRateIcon } from "../assets/icons";
import { BoostIcons, BoostTrayProps } from "../boost";

export interface PostTagsProps {
	credoTagId: string;
	tags: PostTag[];
	onRate: (tag: string, rating: number) => void
	isUserOwnPost: boolean;
	isCredoMode: boolean;
	onModeChange(mode: boolean): void;
	userProfile: any;
	cannotRateOwnPostMsg: string;
	switchToCredoModeToRate: string;
	tagSize?: ScalerSize;
	disabledTags?: boolean;
	onClickDisabledTag?: () => void
	showLoginModal?: (show: boolean) => void;
	onPressCredoGraphText: () => void;
	onPressTagInfoBubble: () => void;
	containerStyle?: string;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	// eslint-disable-next-line react/require-default-props
	isCompactView?: boolean;
	boostTrayProps?: BoostTrayProps;
	messages: TagMessages;
}

const PostTagsComponent = ({
	credoTagId,
	tags,
	onRate,
	isUserOwnPost,
	isCredoMode,
	onModeChange,
	userProfile,
	cannotRateOwnPostMsg,
	switchToCredoModeToRate,
	tagSize,
	disabledTags,
	onClickDisabledTag,
	showLoginModal,
	onPressCredoGraphText,
	onPressTagInfoBubble,
	containerStyle,
	isCompactView,
	boostTrayProps,
	messages,
}: PostTagsProps) => {
	const { width } = useWindowSize();

	const renderIcon = useCallback((icon: TagIconType) => <BoostIcons iconType={icon} />, []);

	const isTagBoostable = useCallback((icon: TagIconType | undefined): boolean => (icon ? [
		TagIconType.BOOSTABLE,
		TagIconType.BOOSTED,
		TagIconType.USER_BOOSTED,
	].includes(icon) : false), []);

	const dataProviderCallback = useCallback((item: PostTag) => createDataProvider(50, {
		engagement: item?.engagement ?? -1,
		hasCampaign: (item?.has_campaign || (item?.icon && !!renderIcon(item?.icon))) ?? false,
		objScore: item?.g_credo_score ?? -1,
		ownInterest: item?.is_interest ?? false,
		ownRating: item.b_credo_score ?? -1,
		relevance: item?.relevance ?? -1,
		subjScore: item?.s_credo_score ?? -1,
		subjectivity: item?.subjectivity ?? 0.5,
		icon: item?.icon,
		campaignIcon: () => (item?.icon ? renderIcon(item?.icon) : null),
	}), []);

	const renderCredoTag = (item: PostTag, index: number) => {
		const dataProvider = dataProviderCallback(item);

		const renderTooltipContent = (toolTipFor: string) => {
			switch (toolTipFor) {
				case NoRateTooltipFor.ModeSwitch:
					return (
						<div className="flex flex-col justify-center items-center">
							<CredoSwitch
								size={CredoSwitchSize.SMALL}
								userProfile={userProfile}
								isCredo={isCredoMode}
								onModeChange={onModeChange}
							/>
							<p className="w-24 mt-2 text-gray-dark text-xs text-center">
								{switchToCredoModeToRate}
							</p>
						</div>
					);

				case NoRateTooltipFor.OwnPost:
					return (
						<div className="flex flex-col justify-center items-center">
							<SvgIcon
								icon={TagNoRateIcon}
							/>
							<p className="w-24 mt-2 text-gray-dark text-xs text-center">
								{cannotRateOwnPostMsg}
							</p>
						</div>
					);
				case NoRateTooltipFor.ShowTutorial:
				default:
					return null;
			}
		};

		const renderToolTip = (toolTipFor: string) => {
			if ([NoRateTooltipFor.ShowTutorial].includes(toolTipFor as NoRateTooltipFor)) {
				EvtMgr
					.getInstance(EventConst.ratingTutorialModal)
					.notifyListeners({
						showModal: true,
					});
				return null;
			}
			return (
				<div
					className="flex w-28 h-32 justify-center items-center rounded-lg opacity-95 bg-white shadow-no-rate-tooltip"
				>
					{renderTooltipContent(toolTipFor)}
				</div>
			);
		};

		return (
			<div
				className={classNames(
					"pb-2",
					index !== tags.length - 1 ? "pr-2.5" : null,
				)}
				key={index}
			>
				<CredoTag
					id={credoTagId}
					tag={item.tag}
					size={tagSize ?? ScalerSize.M}
					onRate={disabledTags ? () => {} : onRate}
					dataProvider={dataProvider}
					isUserOwnPost={isUserOwnPost}
					isCredoMode={isCredoMode}
					tooltip={disabledTags ? undefined : renderToolTip}
					disable={disabledTags}
					showLoginModal={showLoginModal}
					egoInfoProps={{
						switchComp: <CredoSwitch
							disabled
							isCredo
							size={CredoSwitchSize.SMALL}
							userProfile={userProfile}
						/>,
						onPressCredoGraphText,
						onPressTagInfoBubble,
					}}
					boostTrayProps={isTagBoostable(item.icon) ? boostTrayProps : undefined}
					messages={messages}
				/>
			</div>
		);
	};

	if (cu.isSet(tags) && Object.keys(tags).length > 0) {
		return (
			<div className={classNames(
				"flex flex-row flex-wrap bg-background",
				isCompactView && width <= 540 ? "px-2" : "px-4",
				isCompactView ? "" : "pb-1.5",
				containerStyle,
			)}
			>
				{tags.map((tag, i) => renderCredoTag(tag, i))}
			</div>
		);
	}
	return null;
};

PostTagsComponent.defaultProps = {
	tagSize: ScalerSize.M,
	disabledTags: false,
	onClickDisabledTag: null,
	showLoginModal: () => {},
	containerStyle: "",
};

const compareFn = (nextProps: PostTagsProps, prevProps: PostTagsProps) => nextProps.credoTagId === prevProps.credoTagId
	&& JSON.stringify(nextProps.tags) === JSON.stringify(prevProps.tags)
	&& nextProps.onRate === prevProps.onRate
	&& nextProps.isUserOwnPost === prevProps.isUserOwnPost
	&& nextProps.isCredoMode === prevProps.isCredoMode
	&& nextProps.onModeChange === prevProps.onModeChange
	&& nextProps.userProfile === prevProps.userProfile
	&& nextProps.cannotRateOwnPostMsg === prevProps.cannotRateOwnPostMsg
	&& nextProps.switchToCredoModeToRate === prevProps.switchToCredoModeToRate
	&& nextProps.tagSize === prevProps.tagSize
	&& nextProps.disabledTags === prevProps.disabledTags
	&& nextProps.onClickDisabledTag === prevProps.onClickDisabledTag
	&& nextProps.showLoginModal === prevProps.showLoginModal
	&& nextProps.onPressCredoGraphText === prevProps.onPressCredoGraphText
	&& nextProps.onPressTagInfoBubble === prevProps.onPressTagInfoBubble
	&& JSON.stringify(nextProps.containerStyle) === JSON.stringify(prevProps.containerStyle)
	&& nextProps.isCompactView === prevProps.isCompactView
	&& JSON.stringify(nextProps.boostTrayProps) === JSON.stringify(prevProps.boostTrayProps)
	&& JSON.stringify(nextProps.messages) === JSON.stringify(prevProps.messages);

export const PostTags = React.memo(
	PostTagsComponent,
	compareFn,
);
