import { EvtMgr } from "@credo/utilities";
import { GlobalState } from "@credo/store";
import { HintActions, HintsData, HintsDataParsed } from "./types";
import { EventConst } from "../common";

// eslint-disable-next-line import/prefer-default-export
export class HintMgr {
	static HINT_TRIGGER_TIMEOUT: number = 5000;

	static HINT_TRIGGER_TIMEOUT_MODAL: number = 1000;

	static emitHintsByTrigger(trigger: string): void {
		EvtMgr
			.getInstance(EventConst.hintActions)
			.notifyListeners({
				trigger,
				action: HintActions.SHOW,
			});
	}

	static removeHintById(id: string): void {
		EvtMgr
			.getInstance(EventConst.hintActions)
			.notifyListeners({
				id,
				action: HintActions.REMOVE,
			});
	}

	static removeHintByTrigger(trigger: string): void {
		EvtMgr
			.getInstance(EventConst.hintActions)
			.notifyListeners({
				trigger,
				action: HintActions.REMOVE_TRIGGER,
			});
	}

	static getParsedData(data: HintsData[]): HintsDataParsed {
		const parsedMap = new Map();
		data.forEach((element) => {
			if (parsedMap.get(element.present.trigger)) {
				parsedMap.set(
					element.present.trigger,
					[
						element,
						...parsedMap.get(element.present.trigger),
					],
				);
			} else {
				parsedMap.set(element.present.trigger, [element]);
			}
		});
		return parsedMap;
	}

	static domUpdated() {
		EvtMgr
			.getInstance(EventConst.hintActions)
			.notifyListeners({
				action: HintActions.BODY_UPDATED,
			});
	}

	static updateRemovedHintsAtom(removedHints: string) {
		GlobalState.App.removedHints.set(removedHints);
	}
}
