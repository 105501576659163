import {
	DictObjectTypeResolver,
	StaticContextFactory, Context,
} from "@credo/utilities";
import { GlobalState } from "@credo/store";
import {
	Community, Feature, Post, User,
} from "../permissions";

export const permContextProvider = new StaticContextFactory(new Context()
	.add("credoMode", GlobalState.App.isCredoMode)
	.add("actor", GlobalState.User.profile as unknown as User));

/**
 * On production/build the js sets all the types as anonymous e which was giving
 * Post.name as "feature" because it was setting "e" => "feature" because it was
 * last. So added and using a custom resolver resolveIdentifier to fix that issue.
 * */
export const permObjectTypeResolver = new DictObjectTypeResolver({
	post: Post.resolveIdentifier,
	user: User.resolveIdentifier,
	community: Community.resolveIdentifier,
	feature: Feature.resolveIdentifier,
});
