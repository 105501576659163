import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import {
	BoostActionType, cu, EvtMgr, onActionsParam, PostBoostInfo, TopBoosters,
} from "@credo/utilities";
import { CSSTransition } from "react-transition-group";
import {
	BoostOp, BoostStats, CurrentBoost, CurrentRank,
} from "./components";
import { ProfilePicture } from "../../profile/profile-picture";
import { ProfileSize } from "../../profile/types";
import Style from "./BoostTray.module.css";
import { classNames, EventConst, isWideCalc } from "../../common";
import { SliderScroll } from "../../slider-scroll/SliderScroll";
import { RankDelta } from "../types";
import { TrayHandle } from "./components/TrayHandle";

/**
 * TODO:
 * 2. PNL icon
 * 5. Auto close tray after 30 seconds
 * */

export interface BoostTrayProps {
	/**
	 * consists of currentRanking and prevRanking to determine
	 * and display the current rank of the post in the tray.
	 * */
	ranking: {
		currentRanking: number;
		prevRanking: number;
	};
	/**
	 * Boost level information of post.
	 * */
	totalBoost: {
		/**
		 * Total boost level which this post has in previous snap
		 * */
		prev: number,
		/**
		 * Total boost level which this post has at this time
		 * */
		current: number,
	}
	/**
	 * Top users who have boosted this post.
	 *
	 * @see TopBoosters
	 * */
	topBoosters: TopBoosters[];
	/**
	 * If the width of the window is more than certain
	 * limit then we can identify if the UI needs to be
	 * rendered for mobile or desktop
	 * */
	isWide: boolean;
	/**
	 * actions which are taken in the component. Param
	 * will contain the type of action and the message
	 * with it.
	 *
	 * @see onActionsParam
	 * */
	onActions: (param: onActionsParam) => void | Promise<any> | null;
	/**
	 * post boost info
	 *
	 * @see PostBoostInfo
	 * */
	postBoostInfo: PostBoostInfo | undefined;
	/**
	 * String literals which will come from locale and
	 * will be shown to the respective entity
	 * */
	messages: {
		// message on boost submitted in toast
		boostSuccess: string;
	}
	/**
	 * control parts of the component throw authorisation
	 * object boolean keys. We can also add some context
	 * functions in the keys
	 * */
	authorisation: {
		isBoostAllowed: boolean;
	}
	/**
	 * Keep the tray always open
	 * @default false;
	 * */
	alwaysOpen?: boolean;
}

const TRAY_ANIMATION_DURATION = 100;

/**
 * Boost tray will consist of number of small components.
 * Boost tray will have its own handling for expanding and
 * minimizing.
 *
 * When minimized it will contain:
 * 1. Board ranking
 * 2. Ranking Delta Marker (if current rank is higher or lower than previous)
 * 3. Boost indicator (The actual boost value with graphic to display the boost
 * of current post)
 * 4. Top Boosters (Users who are top boosters - maybe they are routable)
 * */
const BoostTrayComponent: React.FC<BoostTrayProps> = (props: BoostTrayProps) => {
	const {
		ranking,
		totalBoost,
		topBoosters,
		isWide,
		onActions,
		postBoostInfo,
		messages,
		authorisation,
		alwaysOpen = false,
	} = props;

	const [isExpanded, setIsExpanded] = useState<boolean>(alwaysOpen);
	/**
	 * Each post will have the tray mounted from the start, we don't
	 * too many useless elements in the dom so we will un-hide the
	 * content only when tray is expanded and hide it while collapsing
	 * */
	const [showContent, setShowContent] = useState<boolean>(alwaysOpen);

	const trayRef = useRef<HTMLDivElement>(null);
	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const rankDelta: RankDelta = useMemo(() => {
		if (ranking.currentRanking === ranking.prevRanking) return RankDelta.SAME;
		if (ranking.currentRanking > ranking.prevRanking) {
			return RankDelta.POSITIVE;
		} else {
			return RankDelta.NEGATIVE;
		}
	}, [ranking]);

	const handleTrayOnExpandEvent = (message: { showTray: boolean }) => {
		setIsExpanded(message.showTray);
		setShowContent(message.showTray);
	};

	useEffect(() => {
		if (isExpanded) {
			EvtMgr
				.getInstance(EventConst.trayOnExpand)
				.addListener(handleTrayOnExpandEvent);
		} else {
			EvtMgr
				.getInstance(EventConst.trayOnExpand)
				.removeListener(handleTrayOnExpandEvent);
		}

		return () => {
			EvtMgr
				.getInstance(EventConst.trayOnExpand)
				.removeListener(handleTrayOnExpandEvent);
		};
	}, [isExpanded]);

	const handleSummaryClick = () => {
		if (alwaysOpen) return;
		setIsExpanded((prevState: boolean) => {
			if (!prevState) {
				setShowContent(true);
				onActions({
					type: BoostActionType.EXPAND,
					message: null,
				});
			} else {
				if (timerRef.current) {
					clearTimeout(timerRef.current);
				}
				timerRef.current = setTimeout(() => {
					setShowContent(false);
				}, 500);
				onActions({
					type: BoostActionType.COLLAPSE,
					message: null,
				});
			}
			return !prevState;
		});
	};

	const renderSummary = useCallback(() => (
		<button
			type="button"
			onClick={handleSummaryClick}
			data-testid="boost-summary-wrapper"
			className="flex flex-col justify-between items-center"
			style={{
				width: isWideCalc(isWide, 24),
			}}
		>
			<div
				data-testid="top-section-wrapper"
				className={classNames(
					"flex flex-col justify-start p-1",
					isWide ? "px-1.5" : "",
				)}
			>
				<div
					data-testid="current-rank-indicator"
				>
					<CurrentRank
						rankDeltaSign={rankDelta}
						currentRank={ranking.currentRanking}
					/>
				</div>
				{!isExpanded && (
					<div
						data-testid="current-boost-indicator"
						className="pt-1"
					>
						<CurrentBoost totalBoost={totalBoost} />
					</div>
				)}
			</div>
			<div
				className="flex flex-col items-center justify-end relative mx-1 mb-0.5 mobile-compact:mb-1"
			>
				<SliderScroll
					wrapperClassName="max-h-12 min-h-[19px]"
					isVertical
				>
					{topBoosters.map((user, index) => (
						<div
							key={user.eprof_id}
							className={classNames(
								"leading-3",
								topBoosters.length - 1 === index ? "" : "pb-0.5",
								"overflow-hidden",
							)}
							title={`${user.firstName ?? ""} ${user.lastName ?? ""}`}
						>
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(user.eprofilePicRelUrl) ?? ""}
								size={isWide ? ProfileSize.XX_SMALL_WEB : ProfileSize.XX_SMALL}
							/>
						</div>
					))}
				</SliderScroll>
			</div>
		</button>
	), [totalBoost, ranking, topBoosters, isWide, isExpanded]);

	const renderTrayContent = useCallback(() => {
		if (showContent) {
			return (
				<React.Fragment>
					<div
						className={classNames(
							Style.trayDivider,
							"w-px",
						)}
					/>
					<BoostOp
						totalBoost={totalBoost}
						onActions={onActions}
						isWide={isWide}
						messages={messages}
						authorisation={authorisation}
					/>
					<div
						className={classNames(
							Style.trayDivider,
							"w-px",
						)}
					/>
					<BoostStats
						postBoostInfo={postBoostInfo}
						isWide={isWide}
					/>
				</React.Fragment>
			);
		}
		return <div className="w-60" />;
	}, [totalBoost, showContent, postBoostInfo, isWide]);

	if (alwaysOpen) {
		return (
			<div
				ref={trayRef}
				className={classNames(
					"flex flex-row h-full max-h-36 mobile-compact:max-h-40",
					Style.trayBg,
					// Style.trayCont,
					// Style.trayContEnterDone,
				)}
			>
				{renderSummary()}
				{renderTrayContent()}
			</div>
		);
	}

	return (
		<CSSTransition
			nodeRef={trayRef}
			in={isExpanded}
			timeout={TRAY_ANIMATION_DURATION}
			classNames={{
				enter: Style.trayContEnter,
				enterDone: Style.trayContEnterDone,
				enterActive: Style.trayContEnterActive,
				exit: Style.trayContExit,
				exitDone: Style.trayContExitDone,
			}}
		>
			<div
				ref={trayRef}
				className={classNames(
					"flex flex-row h-full max-h-36 mobile-compact:max-h-40",
					Style.trayBg,
					Style.trayCont,
				)}
			>
				<TrayHandle expanded={isExpanded} />
				{renderSummary()}
				{renderTrayContent()}
			</div>
		</CSSTransition>
	);
};

const compareFn = (
	nextProps: BoostTrayProps,
	prevProps: BoostTrayProps,
) => nextProps.totalBoost === prevProps.totalBoost
	&& nextProps.isWide === prevProps.isWide
	&& nextProps.alwaysOpen === prevProps.alwaysOpen
	&& JSON.stringify(nextProps.topBoosters) === JSON.stringify(prevProps.topBoosters)
	&& JSON.stringify(nextProps.ranking) === JSON.stringify(prevProps.ranking)
	&& JSON.stringify(nextProps.postBoostInfo) === JSON.stringify(prevProps.postBoostInfo)
	&& JSON.stringify(nextProps.authorisation) === JSON.stringify(prevProps.authorisation);

// eslint-disable-next-line import/prefer-default-export
export const BoostTray = React.memo(
	BoostTrayComponent,
	compareFn,
);
