import React, { useEffect, useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
	RadioButtonEmptyIcon, RadioButtonSelectedIcon, classNames, SvgIcon, BackIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import {
	cu, EvtMgr, YesNoOptions,
} from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { strings } from "../../i18n/config";
import { Consts, EventConst } from "../../utils";
import { ReportListItem, StreamInfo, StreamPostPermission } from "../../utils/types";
import { userSessionAtom } from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";

export interface StreamAccessControlScreenProps {
	onDone: () => void;
	streamInfo: any;
}

const streamYesNOOptions = [
	{ label: strings("StreamAccessControlScreen.yes"), value: YesNoOptions.YES },
	{ label: strings("StreamAccessControlScreen.no"), value: YesNoOptions.NO },
];

const streamPostOptions = [
	{ label: strings("StreamAccessControlScreen.all_members"), value: StreamPostPermission.ALL_MEMBERS },
	{ label: strings("StreamAccessControlScreen.admin_only"), value: StreamPostPermission.ADMIN_ONLY },
];

const StreamAccessControlScreen = (props: StreamAccessControlScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [userSession] = useAtom(userSessionAtom);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(streamInfo);

	useEffect(() => {
		setCommunityDetails(streamInfo);
	}, [streamInfo]);

	const StreamDetails = useMemo(() => ({
		followStream: streamInfo?.[Consts.ac_folcom],
		joinStream: streamInfo?.[Consts.ac_joicom],
		autoApproveJoinRequests: streamInfo?.[Consts.ac_joiaut],
		postToCommunity: streamInfo?.[Consts.ac_alwpos],
	}), [communityDetails]);

	const updateStreamSettings = (item: string, value: any) => {
		const updateStream = {
			user_id: userSession?.user_id || "",
			mode: cu.getAppMode(),
			name: streamInfo?.name,
			descr: streamInfo.descr,
			struname: streamInfo.struname,
			style: streamInfo?.style,
			type: streamInfo?.type,
			hier: streamInfo?.hier,
			categ: streamInfo?.categ,
			disc: streamInfo?.disc,
			streamId: streamInfo?.streamId,
			[item]: value,
		};

		StreamActions.updateStreamInfo(
			updateStream,
			(response) => {
				EvtMgr.getInstance(EventConst.updateStreamInfo).notifyListeners({
					...response,
					items: [updateStream],
				});
				setCommunityDetails({
					...communityDetails,
					...updateStream,
				});
			},
			() => { },
		);
	};

	const onStreamFollowChange = (value: any) => {
		updateStreamSettings(Consts.ac_folcom, value);
	};
	const onStreamJoinChange = (value: any) => {
		updateStreamSettings(Consts.ac_joicom, value);
	};
	const onStreamAutoJoinChange = (value: any) => {
		updateStreamSettings(Consts.ac_joiaut, value);
	};
	const onStreamPostChange = (value: any) => {
		updateStreamSettings(Consts.ac_alwpos, value);
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const renderRadioGroupItem = (item: ReportListItem) => (
		<RadioGroup.Option key={item.value} value={item.value}>
			{({ checked }) => (
				<div className="px-2">
					<div
						className="flex flex-row py-2.5 items-center hover:cursor-pointer"
					>
						<div className="w-10 h-6">
							{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
						</div>
						<div className="w-full">
							<p className="text-sm font-medium text-title-color">{item.label}</p>
						</div>
					</div>
					<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
				</div>
			)}
		</RadioGroup.Option>
	);

	return (
		<div className="w-full flex flex-col pb-3 px-3 h-[calc(100vh-100px)]">
			{/* <div className="flex flex-row items-center -mx-3">
				<SvgIcon
					icon={BackIcon}
					className={classNames(isMobile ? "ml-4" : "ml-2", "cursor-pointer")}
					color="var(--text-base)"
					onClick={onDone}
				/>
				<h4
					className={classNames(
						"text-md font-normal text-basic px-3",
					)}
				>
					{strings("StreamAccessControlScreen.access_control")}
				</h4>
			</div> */}
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamAccessControlScreen.allow_users_to_follow")}
			</span>
			<RadioGroup value={StreamDetails.followStream} onChange={onStreamFollowChange}>
				{streamYesNOOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamAccessControlScreen.allow_users_to_join")}
			</span>
			<RadioGroup value={StreamDetails.joinStream} onChange={onStreamJoinChange}>
				{streamYesNOOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamAccessControlScreen.auto_approve_join_requests")}
			</span>
			<RadioGroup
				value={StreamDetails.autoApproveJoinRequests}
				onChange={onStreamAutoJoinChange}
				disabled={StreamDetails.joinStream === YesNoOptions.NO}
				className={classNames(StreamDetails.joinStream === YesNoOptions.NO ? "opacity-50" : "")}
			>
				{streamYesNOOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
			<span className="text-sm font-medium text-title-color pt-5 pb-3">
				{strings("StreamAccessControlScreen.who_can_post")}
			</span>
			<RadioGroup value={StreamDetails.postToCommunity} onChange={onStreamPostChange}>
				{streamPostOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
		</div>
	);
};

export default StreamAccessControlScreen;
