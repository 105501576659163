import React, { useEffect, useState } from "react";
import {
	RadioButtonSelectedIcon, RadioButtonEmptyIcon, classNames,
} from "@credo/ui-components";
import { RadioGroup } from "@headlessui/react";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { cu, EvtMgr } from "@credo/utilities";
import { useWindowSize } from "react-use";
import { isMobile } from "react-device-detect";
import { strings } from "../../../i18n/config";
import {
	AppUtils, Consts, EventConst, MsgConst,
} from "../../../utils";
import { ReportListItem, ThemeOptions } from "../../../utils/types";
import { showCredoModeWarning, userProfileAtom, userSessionAtom } from "../../../utils/atoms";
import { MsgMgr } from "../../../config/MsgMgr";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

const SettingsPSPostsVisibiltyScreen: React.FC = () => {
	const [hasPostVisibilityEgoPerm, setHasPostVisibilityEgoPerm] = useState<any>();
	const [userProfile] = useAtom(userProfileAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userSession] = useAtom(userSessionAtom);
	const [, setShowCredoModeWarningAtom] = useAtom(showCredoModeWarning);
	const { width } = useWindowSize();
	const isInMobile = isMobile && width < 768;

	useSetAppHeader({
		title: strings("SettingsPrivacySettingsScreen.posts_visibility"),
	});

	useEffect(() => {
		setShowCredoModeWarningAtom(true);
		if (isCredoMode) {
			AppUtils.toggleTheme(ThemeOptions.EGO);
		}
		setHasPostVisibilityEgoPerm(userProfile?.ps_e_possee);
	}, [isCredoMode, userProfile]);

	const onProfilePostsVisibilityChange = (value: any) => {
		setHasPostVisibilityEgoPerm(value);
		EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
			items: [
				{
					...userProfile,
					ps_e_possee: value,
				},
			],
		});
		const updateProfileActivity = {
			user_id: userSession?.user_id,
			mode: cu.getAppMode(),
			ps_e_possee: value,
		};
		MsgMgr.makeRemoteRequest_generic({
			request: updateProfileActivity,
			msgName: MsgConst.updateUserInfo,
		});
	};

	const profilePostsOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.followers"),
			value: Consts.privacySettings.followers,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.direct_connections"),
			value: Consts.privacySettings.directConnections,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-5",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<div className="px-3 w-full flex flex-col">
				<div className="flex text-sm text-gray-dark text-center py-5 w-9/12 self-center">
					{strings("SettingsPSPostsVisibiltyScreen.profile_visibility_ego_msg")}
				</div>
				<span className="text-sm font-medium text-title-color pb-3">
					{strings("SettingsPSPostsVisibiltyScreen.who_can_see_posts")}
				</span>
				<RadioGroup value={hasPostVisibilityEgoPerm} onChange={onProfilePostsVisibilityChange}>
					{profilePostsOptions.map((item: ReportListItem) => (
						<RadioGroup.Option key={item.value} value={item.value}>
							{({ checked }) => (
								<div className="px-2">
									<div
										className="flex flex-row py-2.5 items-center hover:cursor-pointer"
									>
										<div className="w-10 h-6">
											{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
										</div>
										<div className="w-full">
											<p className="text-sm font-medium text-title-color">{item.label}</p>
										</div>
									</div>
									<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
								</div>
							)}
						</RadioGroup.Option>
					))}
				</RadioGroup>
			</div>
		</div>
	);
};

export default SettingsPSPostsVisibiltyScreen;
