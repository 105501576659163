export const Consts = {
	postType_followUser: "fu",
	postType_connectUser: "cu",
	postType_followStream: "fs",
	postType_joinStream: "js",
	main_wall_credo_stream_id: "mwc",
	main_wall_ego_stream_id: "mwe",
	sess: "sess",
	umas_stream_name: "UMAS",
	blast_stream_name: "Credo Blasts",
	blast_stream_id: "cb",
	blast_stream_username: "credo_blasts",
	share_link: "Share Link",
	// User is rating a for the first time
	ratingFirstTime: "ratingFT",
	proposeTag: "proposeTag",
};

export enum ItemType {
  USER = "u",
  STREAM = "s",
}

export const RegEx = {
	url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
	hashTag: /(?:\s|^)#[A-Za-z0-9\-._]+(?:|$)/gi,
	userTag: /@\[([^@[]*)]\((([\d\w,._-]*))\)/gi,
	userTagUsernameOnly: /@([^@[ ]*)/gi,
	boldTag: /\*([^*]+?)\*/gi,
	youtubeVideo: /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
};

export const EventConst = {
	updateFeedData: "updateFeedData",
	ratingTutorialModal: "ratingTutorialModal",
	hintActions: "hintActions",
	getPostGraphData: "getPostGraphData",
	showCredoGraph: "showCredoGraph",
	pushPostGraphDataToComp: "pushPostGraphDataToComp",
	trayOnExpand: "trayOnExpand",
};

export const DEFAULT_TAG_MESSAGES = {
	rate_this_item: "Rate this post in",
	rating_info: "Rating a post helps train your personal credibility lens and contributes to the collective credibility assessment of content and people.",
	view_tutorial: "view tutorial",
	creed_high: "Highly credible",
	creed_med: "Not sure...",
	creed_low: "Not credible at all",
	boost_this_post: "Boost this post in",
	boost_info: "Boosting a post locks up your volts in return for a higher ranking in Top Boards.",
};

export const TABLET_WIDTH = 968;

export const MOBILE_COMPACT_WIDTH = 540;
// Web size proportion
export const WEB_SIZE_PROP = 1.5;
// Web size proportion for button
export const WEB_SIZE_PROP_BUTTON = 1.2;
