import React from "react";
import { AppMode } from "@credo/utilities";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { strings } from "../../i18n/config";
import { userIdAtom } from "../../utils/atoms";
import { ReportItemType, ReportListItem } from "../../utils/types";
import Report from "../../modules/legal/Report";
import { CommentItem } from "../../utils/comments/types";
import { reportItem } from "../../modules/post/PostRequest";

const radioProps_ego: ReportListItem[] = [
	{ label: strings("ReportComment.offensive_or_hateful"), value: "offensive" },
	{ label: strings("ReportComment.sexually_explicit"), value: "sexual" },
	{ label: strings("ReportComment.voilent_or_prohibited"), value: "voilent" },
	{ label: strings("ReportComment.spam"), value: "spam" },
	{ label: strings("ReportComment.other"), value: "other" },
];

const radioProps_credo: ReportListItem[] = [
	{ label: strings("ReportComment.offensive_or_hateful"), value: "offensive" },
	{ label: strings("ReportComment.sexually_explicit"), value: "sexual" },
	{ label: strings("ReportComment.voilent_or_prohibited"), value: "voilent" },
	{ label: strings("ReportComment.spam"), value: "spam" },
	{ label: strings("ReportComment.revealed_credo_identity"), value: "rci" },
	{ label: strings("ReportComment.other"), value: "other" },
];

export interface ReportCommentProps {
	commentData: CommentItem;
	parentCommentData?: CommentItem;
	onCommentReported: () => void;
}

const ReportComment = (props: ReportCommentProps) => {
	const {
		commentData,
		parentCommentData,
		onCommentReported,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userId] = useAtom(userIdAtom);

	const onReportComment = (reportReason: string, reportMessage: string) => {
		const requestObject = {
			user_id: userId ?? null,
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
			itemType: ReportItemType.Comment,
			postUuid: null,
			prof_id: null,
			streamId: null,
			commentUuid: commentData?.commentUuid ?? "",
			pCommentUuid: parentCommentData?.commentUuid ?? "",
			reportReason,
			reportMessage: reportMessage || reportReason,
			arg: {},
		};
		reportItem(requestObject);
		if (onCommentReported) {
			onCommentReported();
		}
	};

	return (
		<Report
			radioProps={isCredoMode ? radioProps_credo : radioProps_ego}
			onSubmit={onReportComment}
		/>
	);
};

export default ReportComment;

ReportComment.defaultProps = {
	parentCommentData: null,
};
