import { HOME_PAGE } from "../../routes/constants";
import { AuthModalContentType } from "../../utils/types";

interface DynamicPair {
	[key: string]: any;
}

// eslint-disable-next-line import/prefer-default-export
export class RouteMgr {
	private static verification_success_screen: string = HOME_PAGE;

	private static verification_success_modal_screen: AuthModalContentType = AuthModalContentType.LOGIN;

	private static verification_success_screen_navigation_params: null | DynamicPair = null;

	private static social_login_verification_failed_screen: null | string = null;

	private static social_login_success_screen: null | string = null;

	private static dont_allow_route_change: boolean = false;

	public static setDontAllowRouteChange(value: boolean) {
		this.dont_allow_route_change = value;
	}

	public static getDontAllowRouteChange(): boolean {
		return this.dont_allow_route_change;
	}

	public static setVerificationSuccessScreen(screen: string) {
		this.verification_success_screen = screen;
	}

	public static getVerificationSuccessScreen(): string {
		return this.verification_success_screen;
	}

	public static setVerificationSuccessModalScreen(screen: AuthModalContentType) {
		this.verification_success_modal_screen = screen;
	}

	public static getVerificationSuccessModalScreen(): AuthModalContentType {
		return this.verification_success_modal_screen;
	}

	public static setVerificationSuccessNavParams(params: DynamicPair) {
		this.verification_success_screen_navigation_params = params;
	}

	public static getVerificationSuccessNavParams(): null | DynamicPair {
		return this.verification_success_screen_navigation_params;
	}

	public static setSocialLoginVerificationFailedScreen(screen: string | null) {
		this.social_login_verification_failed_screen = screen;
	}

	public static getSocialLoginVerificationFailedScreen(): string | null {
		return this.social_login_verification_failed_screen;
	}

	public static setSocialLoginSuccessScreen(screen: string) {
		this.social_login_success_screen = screen;
	}

	public static getSocialLoginSuccessScreen(): string | null {
		return this.social_login_success_screen;
	}
}
