/* eslint-disable no-else-return */
import React, { useState } from "react";
import { EmotionsType, EvtMgr } from "@credo/utilities";
import { classNames, EmotionsTooltip } from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { AnalyticEventsConst, AppUtils, EventConst } from "../../utils";
import { CommentItem } from "../../utils/comments/types";

interface PostCommentEmotionsProps {
	/*
	*	Sets post data of the feed
	*/
	commentData: CommentItem;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	onEmojiClick: (data: any) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const PostCommentEmotions: React.FC<PostCommentEmotionsProps> = (props: PostCommentEmotionsProps) => {
	const {
		commentData,
		onEmojiClick,
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const [isCredoMode] = useAtom(isCredoModeAtom);

	const onTooltipOpen = () => {
		setIsOpen(true);
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.commentEmoji,
		});
	};

	const onTooltipClose = (data: any) => {
		if (onEmojiClick) onEmojiClick(data);
		setIsOpen(false);
	};

	const showLoginModal = () => {
		AppUtils.handleLoginModal(true);
	};

	return (
		<div
			className={
				classNames(
					"flex bg-background rounded-xl h-7 items-center justify-center cursor-pointer",
					"absolute -bottom-3.5",
					isOpen ? "z-[21]" : "",
					isMobile ? "-right-1" : "right-8",
					commentData.isCommentPending ? "invisible" : "visible",
				)
			}
		>
			<EmotionsTooltip
				type={EmotionsType.COMMENT}
				onClickEmojiTooltip={onTooltipOpen}
				postData={commentData}
				onEmojiClick={onTooltipClose}
				isCredoMode={isCredoMode}
				showLoginModal={showLoginModal}
			/>
		</div>
	);
};
