import {
	AppManager,
	AuthorisationManager, AuthorisationManagerProps,
	DobeEngine,
	DobeEngineFactoryProps,
	PropsConfigurator,
} from "@credo/utilities";
import { DobeEngineFactory } from "./DobeEngineFactory";

// eslint-disable-next-line import/prefer-default-export
export const InitAppManager = (): void => {
	AppManager.instance().addManager(
		AuthorisationManager.name,
		{
			create(params: any): AuthorisationManager<string, DobeEngine, DobeEngineFactoryProps> {
				return new AuthorisationManager<string, DobeEngine, DobeEngineFactoryProps>(params);
			},
		},
		new PropsConfigurator({
			factory: new DobeEngineFactory(),
			paramsFn: (key, props) => props,
		} as AuthorisationManagerProps<string, DobeEngine>),
	);
};
