import { cu, EvtMgr, ResponseCode } from "@credo/utilities";
import { MsgConst } from "../../utils";

export interface reportedPostObj {
	itemType: string;
	"p.postUuid": string;
}

interface ReportPostRequestObj {
	user_id: string | null;
	mode: string;
	itemType: string;
	postUuid: null;
	prof_id: null;
	streamId: string | null;
	commentUuid: string | null;
	pCommentUuid?: string | null;
	reportReason: string;
	reportMessage: string;
	arg: Object;
}

interface ReportPostResponseObj {
	addr: "reportItem";
	items: Array<reportedPostObj>;
	msg_id: string;
	procTs: string;
	retcd: string;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export function reportItem(request: ReportPostRequestObj) {
	return new Promise<ReportPostResponseObj>((resolve, reject) => {
		const callBack = (_p: null, response: ReportPostResponseObj) => {
			if (response.retcd === ResponseCode.OK) {
				resolve(response);
			} else {
				reject(new Error(`Received not ok from api: ${response.retcd}`));
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg("reportItem", "reportItem", request, callBack);
	});
}

export interface FetchSubCommentRequestObj {
	user_id: string;
	mode: string;
	page: number;
	seed: number;
	fetchSize: number;
	postUuid: string;
	targetUserOrProfId: string;
	scope: string;
	commentUuid?: string;
	lastId: number;
	lastTs: number;
	parentCommentUuid?: string;
	APIVersion: string;
}

interface FetchSubCommentResponseObj {
	addr: "get_postCommentsItems";
	items: [];
	msg_id: string;
	procTs: string;
	retcd: string;
	sendTs: number;
	sid: string;
	wrap_id: string;
}

export function fetchComments(request: FetchSubCommentRequestObj, action: string | null) {
	return new Promise<FetchSubCommentResponseObj>((resolve, reject) => {
		const callBack = (_p: null, response: FetchSubCommentResponseObj) => {
			if (response.retcd === ResponseCode.OK) {
				if (action) {
					EvtMgr.getInstance(`${action}`).notifyListeners(response);
				} else {
					EvtMgr.getInstance(`fetchSubComments_${request.commentUuid}`).notifyListeners(response);
				}
				resolve(response);
			} else {
				reject(new Error(`Received not ok from api: ${response.retcd}`));
			}
		};
		const msgName = cu.isSet(request.user_id) ? MsgConst.getPostCommentsItems : MsgConst.getNoAuthPostCommentItems;
		global.ebMgr.sendMsg(msgName, msgName, request, callBack);
	});
}
