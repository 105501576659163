export const convertToPixels = (lengthString: string) => {
	const regex = /^([-+]?[0-9]*\.?[0-9]+)(px|em|rem|pt)$/;
	const match = lengthString.match(regex);

	if (!match) {
		throw new Error("Invalid length string");
	}
	const length = parseFloat(match[1]);
	const unit = match[2];

	let conversionFactor = 1;

	switch (unit) {
		case "px":
			return length;
		case "pt":
			conversionFactor = 96 / 72;
			break;
		case "in":
			conversionFactor = 96;
			break;
		case "cm":
			conversionFactor = 96 * 2.54;
			break;
		case "mm":
			conversionFactor = 96 * 25.4;
			break;
		default:
			throw new Error("Unsupported unit");
	}

	return length * conversionFactor;
};

export const keyId = (key: string, id: string) => `${key}-${id}`;
