import {
	AppMode, cu, dbg, EvtMgr, LogMgr, ResponseCode, SessMgr, YesNoOptions,
} from "@credo/utilities";
import { SnackBarTypeOptions } from "@credo/ui-components";
import { MsgMgr } from "../../../config/MsgMgr";
import {
	AppUtils, Consts, EventConst, MsgConst,
} from "../../../utils";
import { SocialLoginType } from "../../../utils/types";
import { DEFAULT_COUNTRY_CODE } from "../../../config/constants";
import { findOrCreateUser } from "../requests";
import { strings } from "../../../i18n/config";

interface LinkedinMessageResponse {
	addr: string,
	sid: string | null,
	replyAddress: string,
	retcd: ResponseCode,
	firstLastNameProfilePic: string,
	email: string,
	procTs: number,
	sendTs: number,
	wrap_id: string,
	msg_id: string
}

interface LinkedInCodeResponse {
	authUrl: string;
	retcd: ResponseCode,
	firstLastNameProfilePic: string,
	email: string,
	procTs: number,
	sendTs: number,
	wrap_id: string,
	msg_id: string
}

interface LinkedInCodeRequest {
	user_id: string,
	mode: AppMode,
	phoneNumber: string | null,
	code: string | null,
	access_type: string,
}

/**
 * Class to Authenticate User using their LinkedIn Account
 * */

// eslint-disable-next-line import/prefer-default-export
export class LinkedInMgr {
	/**
	 * Opens up a popUp window to authenticate
	 * the user on LinkedIn, once the user is authenticated in that window, that
	 * window is closed and a message is sent to the opener tab, basically the
	 * window from where the pop-up was open. Once the window gets the code,
	 * the code is then sent to the main opener window again to do the further
	 * process. See handlePostMessage for next step.
	 *
	 * @see handlePostMessage
	 * */
	static doLoginWithLinkedIn = async () => {
		EvtMgr.getInstance(EventConst.showSocialLoading).notifyListeners(true);
		const response = await MsgMgr.makeRemoteRequest_generic<LinkedInCodeRequest, LinkedInCodeResponse>(
			{
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: cu.getAppMode(),
					phoneNumber: null,
					code: null,
					access_type: "web",
				},
				msgName: MsgConst.linkedInAuth,
			},
		);

		if (dbg) LogMgr.mydbg("LinkedIn Login: Got response from the server for authurl", response);

		if (response.retcd === ResponseCode.OK && response?.authUrl) {
			cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.LINKEDIN);
			window.location.replace(
				response?.authUrl,
			);
		}
	};

	/**
	 * This function will get that message with this handlePostMessage
	 * sent by that pop-up window, which will contain the generated code by
	 * LinkedIn. Once received the message, the code is sent to our server
	 * again to get the user's details provided by LinkedIn.
	 * Once we get the email of the user from those details, we pack those details
	 * in our requestObject and sent it to findOrCreateUser api to check and
	 * authenticate the session.
	 *
	 * @see doLoginWithLinkedIn
	 * */
	static handlePostMessage = async (event: MessageEvent<any>) => {
		if (event.data.type === "code" && event.data.linkedinAuth) {
			const { code } = event.data;
			if (dbg) LogMgr.mydbg("LinkedIn Login: Got code from linkedIn redirection", code);
			// Login using code

			await MsgMgr.makeRemoteRequest_generic(
				{
					msgName: MsgConst.linkedInAuth,
					request: {
						user_id: SessMgr.getFromSession(Consts.user_id),
						mode: cu.getAppMode(),
						phoneNumber: null,
						someParam: null,
						code,
						access_type: "web",
					},
					instanceCallback: async (messageResponse: LinkedinMessageResponse) => {
						if (dbg) LogMgr.mydbg("LinkedIn Login: Message response", messageResponse);
						if (messageResponse.retcd === ResponseCode.OK) {
							if (dbg) LogMgr.mydbg("LinkedIn Login Success: Account found", messageResponse);
							const emailArray = messageResponse?.email ? JSON.parse(messageResponse?.email).elements : [];
							// eslint-disable-next-line no-nested-ternary
							const email = emailArray && emailArray.length > 0
								? emailArray[0]["handle~"] ? emailArray[0]["handle~"].emailAddress : null : null;
							const linkedinId = JSON.parse(messageResponse?.firstLastNameProfilePic).id;
							if (email && linkedinId) {
								EvtMgr.getInstance(EventConst.setSocialEmailCred).notifyListeners({
									email,
									firebaseUUID: linkedinId,
								});
							}
							if (dbg) LogMgr.mydbg("useLinkedInAuth", `email from resp msg is: ${email} linkedinId=${linkedinId}`);
							if (cu.isSet(email)) {
								const default_email = YesNoOptions.NO;
								// cu.isCustomEmailSet() ? default_email = YesNoOptions.NO : default_email = YesNoOptions.YES;
								const requestObject = {
									user_id: SessMgr.getFromSession(Consts.user_id),
									phone: null,
									verificationCode: null,
									email,
									password: null,
									emailSource: SocialLoginType.LINKEDIN,
									firebaseUid: linkedinId,
									action: null,
									new_phone: null,
									cntry_code: cu.getGlobalVar(Consts.clientCountryCode) || DEFAULT_COUNTRY_CODE,
									default_email,
								};
								findOrCreateUser(requestObject, null).then(() => {
									if (dbg) LogMgr.mydbg(this, "LinkedIn Login: User FindOrCreate User Complete");
								}, (error) => {
									EvtMgr.getInstance(EventConst.handleLkdInEmailNotFound).notifyListeners();
									if (dbg) LogMgr.mydbg("LinkedIn Login Error: Error while findOrCreateUser", error);
								});
								EvtMgr.getInstance(EventConst.socialAuthProcessComplete).notifyListeners();
							}
						} else {
							if (dbg) LogMgr.mydbg("LinkedIn Login Error: Account not found", messageResponse);
							AppUtils.showToast({
								type: SnackBarTypeOptions.ERROR,
								message: strings("AuthModal.error.could_not_login", { name: SocialLoginType.LINKEDIN }),
							});
							EvtMgr.getInstance(EventConst.socialAuthProcessComplete).notifyListeners();
						}
					},
				},
			);
			if (dbg) LogMgr.mydbg("LinkedIn Login Complete closing social loading modal");
			EvtMgr.getInstance(EventConst.showSocialLoading).notifyListeners(false);
		}
	};
}
