import React, { useEffect, useState } from "react";
import {
	classNames, SvgIcon, BackIcon, PrimaryButton, ButtonSize, InputField, ConfirmationModal,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { cu, useEvtMgrListener } from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { strings } from "../../i18n/config";
import { MsgConst } from "../../utils";
import { userSessionAtom } from "../../utils/atoms";
import { deleteItem, DeleteItemType } from "../home/requests";
import { ENGAGE_PAGE } from "../../routes/constants";

export interface StreamDeleteScreenProps {
	onDone: () => void;
	streamInfo: any;
}

const StreamDeleteScreen = (props: StreamDeleteScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userSession] = useAtom(userSessionAtom);
	const navigate = useNavigate();
	const [streamName, setStreamName] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const closeConfirmationModal = () => {
		setOpenConfirmationModal(false);
	};

	const deleteStream = () => {
		setLoading(true);
		const stream = {
			user_id: userSession?.user_id || "",
			mode: cu.getAppMode(),
			itemType: DeleteItemType.Stream,
			streamId: streamInfo?.streamId,
			prof_id: isCredoMode ? userSession?.cprof_id : userSession?.eprof_id || "",
			postUuid: null,
			commentUuid: null,
		};
		deleteItem(stream);
		closeConfirmationModal();
	};

	const handleStreamDelete = (items: any) => {
		if (items && items?.length > 0) {
			if (items?.[0]?.itemType === DeleteItemType.Stream && items?.[0]?.streamId === streamInfo?.streamId) {
				navigate(ENGAGE_PAGE);
			}
		}
		setLoading(false);
	};

	useEvtMgrListener(MsgConst.deleteItem, handleStreamDelete);

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const onChangeStreamName = (event: any) => {
		const text = event?.target?.value;
		setStreamName(text);
	};

	const capitalizeFirstLetter = (text: string) => text.toLowerCase().split(" ")
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ");

	return (
		<div className="w-full flex flex-col pb-3 px-3 h-[calc(100vh-100px)]">
			<div className="w-full flex flex-col items-center justify-center pt-5">
				<span className="w-4/5 text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap">
					{strings("StreamDeleteScreen.explain_delete_stream1")}
					<span className="font-bold text-sm capitalize text-basic">
						`
						{streamInfo?.name}
						`
					</span>
					{strings("StreamDeleteScreen.explain_delete_stream2")}
				</span>
				<span className="sm:w-[60%] 2xs:w-[80%] text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap">
					{strings("StreamDeleteScreen.write_stream_name_message")}
				</span>
			</div>
			<div className="flex flex-col py-5 w-full items-center justify-center">
				<div className="flex flex-col sm:w-1/2 2xs:w-4/6">
					<InputField
						value={streamName}
						name="name"
						onChange={onChangeStreamName}
						placeholder={strings("StreamDeleteScreen.enter_stream_name")}
					/>
					<PrimaryButton
						size={ButtonSize.LARGE}
						label={strings("StreamDeleteScreen.delete")}
						handleClick={() => { setOpenConfirmationModal(true); }}
						isDanger={streamName === capitalizeFirstLetter(streamInfo?.name)}
						buttonClassNames="rounded-full !w-full mt-6"
						disabled={streamName !== capitalizeFirstLetter(streamInfo?.name)}
						isLoading={loading}
					/>
				</div>
				<ConfirmationModal
					isVisible={openConfirmationModal}
					onClose={closeConfirmationModal}
					cancel_button_function={closeConfirmationModal}
					ok_button_title={strings("StreamDeleteScreen.yes")}
					cancel_button_title={strings("StreamDeleteScreen.cancel")}
					ok_button_function={deleteStream}
					title={strings("StreamDeleteScreen.delete_group")}
					message={strings("StreamDeleteScreen.confirm_message")}
				/>
			</div>
		</div>
	);
};

export default StreamDeleteScreen;
