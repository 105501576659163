import React, { useEffect, useMemo, useState } from "react";
import {
	cu, EvtMgr, EmotionsType, useWindowSize,
} from "@credo/utilities";
import { classNames, EventConst, SvgIcon } from "../../common";
import { EmotionsTooltip } from "./EmotionsTooltip";
import { PostCommentIcon, PostShareIcon } from "../../assets/icons";
import ThreeDot from "../../assets/icons/ThreeDot";
import { MoreMenuInterface } from "../types";
import { MoreMenu } from "../../credo-more-menu";

export interface PostBarProps {
	/*
	*	Sets post data of the feed
	*/
	postData: any;
	/*
	*	Sets whether post has comments or not
	*/
	hasComments: boolean;
	/*
	*	callback executed when user clicks on comment icon
	*/
	onCommentIconClick: () => void;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	onEmojiClick: (data: any) => void;
	/*
	*	callback executed when user clicks on re-share icon
	*/
	onSharePostIconClick: () => void;
	/*
	*	List of items to be displayed on click of more icon
	*/
	moreMenuList: MoreMenuInterface[];
	/*
	*	Gives current app mode
	*/
	isCredoMode: boolean;
	/*
	*	Sets a flag to show login modal
	*/
	showLoginModal: (show: boolean) => void;
	/*
	*	callback executed when user clicks on emoji icon
	*/
	onClickEmojiTooltip?: () => void;
	/*
	*	callback executed when user clicks on more menu icon
	*/
	onClickMoreMenu?: () => void;
	/**
	 * If comment input is visible on click of the comment button,
	 * we should show bottom border of postbar,
	 * */
	commentInputVisible?: boolean;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	isCompactView?: boolean;
}

export const PostBar = (props: PostBarProps) => {
	const {
		postData,
		hasComments,
		onCommentIconClick,
		onEmojiClick,
		onSharePostIconClick,
		moreMenuList,
		isCredoMode,
		showLoginModal,
		onClickEmojiTooltip,
		onClickMoreMenu,
		commentInputVisible,
		isCompactView = false,
	} = props;
	const [itemState, setItemState] = useState(postData);
	const { width } = useWindowSize();

	useEffect(() => {
		setItemState(postData);
	}, [postData]);

	const iconDimensions = useMemo(() => {
		if ((width <= 540)) {
			return {
				height: 15,
				width: 15,
			};
		}
		return {
			height: 16,
			width: 16,
		};
	}, [width]);

	const renderCommentIconAndCount = () => (
		<div
			className="flex flex-row items-center justify-center px-2 h-7 cursor-pointer rounded-15 mr-2.5 border bg-content-L1 border-content-L2"
			onClick={() => onCommentIconClick && onCommentIconClick()}
			aria-hidden
		>
			<SvgIcon
				icon={PostCommentIcon}
				title="Comment"
				className="flex cursor-pointer pr-1 items-center justify-center"
				{...iconDimensions}
			/>
			<p
				className={classNames(
					"text-gray-dark pl-px font-bold text-xs",
				)}
			>
				{" "}
				{cu.formatAsNbWithThousandsWithDefault(itemState["p.nb_of_co"], 0)}
			</p>
		</div>
	);

	const renderShareIconAndCount = () => (
		<div
			className="flex flex-row items-center justify-center px-2 h-7 cursor-pointer rounded-15 border bg-content-L1 border-content-L2"
			onClick={() => onSharePostIconClick && onSharePostIconClick()}
			aria-hidden
		>
			<SvgIcon
				icon={PostShareIcon}
				title="Re-Share"
				className="flex cursor-pointer pr-1 items-center justify-center"
				{...iconDimensions}
			/>
			<p
				className={classNames(
					"text-gray-dark pl-px font-bold text-xs",
				)}
			>
				{" "}
				{cu.formatAsNbWithThousandsWithDefault(itemState["p.shared_cnt"], 0)}
			</p>
		</div>
	);

	const renderMoreIcon = () => (
		<MoreMenu
			iconComp={
				(
					<div
						className={classNames(
							"flex flex-row h-7 items-center justify-end cursor-pointer w-7",
						)}
						aria-hidden
						onClick={onClickMoreMenu}
					>
						<SvgIcon
							icon={ThreeDot}
							fill="var(--primary)"
							className="flex cursor-pointer"
							title="More"
							{...iconDimensions}
						/>
					</div>
				)
			}
			items={moreMenuList}
			containerStyle={classNames("xs:-left-48 sm:-left-52 -left-48",
				moreMenuList.length === 1 ? "-top-20" : "-top-28")}
		/>
	);

	const onEmojiChange = (data: any) => {
		const postObj = itemState;
		const emoCountA: any[] = [];
		let isEmoPresent = false;
		if (!postObj.emoCountA) {
			data.emoCode.forEach((a: any) => {
				if (cu.getAppMode() === a[1]) {
					emoCountA.push([a[0], data.nb_emocons]);
				}
			});
			postObj.emoCode = data.emoCode;
			postObj.emoCountA = emoCountA;
			postObj.nb_of_emo = data.nb_of_emo;
		} else {
			if (postObj.emoCountA && postObj.emoCountA[0][0] === null) {
				// new post having count blank
				data.emoCode.forEach((a: any) => {
					if (cu.getAppMode() === a[1]) {
						emoCountA.push([a[0], data.nb_emocons]);
					}
				});
				postObj.emoCode = data.emoCode;
				postObj.emoCountA = emoCountA;
				postObj.nb_of_emo = data.nb_of_emo + 1;
			} else if (!data.emoCode && data.prevEmoCode && postObj.emoCountA.length > 0) {
				// when user deselect emogi
				const index = postObj.emoCountA.findIndex((x: any) => x[0] === data.prevEmoCode);
				if (index !== -1) {
					postObj.emoCountA[index][1] = data.nb_emocons;
					postObj.emoCode = data.emoCode;
					postObj.nb_of_emo = data.nb_of_emo - 1;
				}
			} else if (!data.prevEmoCode && postObj.emoCountA.length > 0) {
				// when user react with new emoji
				const index = postObj.emoCountA.findIndex((x: any) => x[0] === data.emoCode);
				if (index === -1) {
					postObj.emoCountA.push([data.emoCode, data.nb_emocons]);
					postObj.emoCode = data.emoCode;
					postObj.nb_of_emo = data.nb_of_emo + 1;
				} else {
					postObj.emoCountA[index][1] = data.nb_emocons;
					postObj.emoCode = data.emoCode;
					postObj.nb_of_emo = data.nb_of_emo + 1;
				}
			} else if (data.prevEmoCode && postObj.emoCountA.length > 0) {
				// when user updating emoji
				postObj.emoCountA.forEach((a: any) => {
					data.prevEmoCode.forEach((prevc: any) => {
						if (cu.getAppMode() === prevc[1]) {
							if (prevc[0] === a[0] && a[1] > 0) {
								// decrementing prev emo count by 1
								// eslint-disable-next-line no-param-reassign
								a[1] -= 1;
							}
						}
					});
					data.emoCode.forEach((emoc: any) => {
						if (cu.getAppMode() === emoc[1]) {
							if (emoc[0] === a[0]) {
								// checking if emo is already reacted by another user
								isEmoPresent = true;
								// eslint-disable-next-line no-param-reassign
								a[1] = data.nb_emocons;
								postObj.emoCode = data.emoCode;
							}
						}
					});
				});
				if (!isEmoPresent) {
					// cheking if emo is not reacted by any user
					data.emoCode.forEach((a: any) => {
						if (cu.getAppMode() === a[1]) {
							postObj.emoCountA.push([a[0], data.nb_emocons]);
						}
					});
					postObj.emoCode = data.emoCode;
				}
			}
			postObj.nb_of_emo = data.nb_of_emo;
		}
		EvtMgr.getInstance(EventConst.updateFeedData).notifyListeners({ post: postObj });
		setItemState(postObj);
		if (onEmojiClick) {
			onEmojiClick(data);
		}
	};

	return (
		<div
			className={classNames(
				"flex flex-row w-full bg-background items-start h-11 border-background-tertiary",
				isCompactView ? "items-center" : "items-start",
			)}
		>
			<div className="flex flex-row justify-between w-full">
				<div className="flex flex-row">
					{renderCommentIconAndCount()}
					<div className="flex flex-row items-center justify-center px-0 h-7 rounded-15 border bg-content-L1 border-content-L2">
						<EmotionsTooltip
							type={EmotionsType.POST}
							onClickEmojiTooltip={onClickEmojiTooltip}
							postData={itemState}
							onEmojiClick={onEmojiChange}
							isCredoMode={isCredoMode}
							showLoginModal={showLoginModal}
						/>
					</div>
				</div>
				<div className="flex flex-row">
					{renderShareIconAndCount()}
					{renderMoreIcon()}
				</div>
			</div>
		</div>
	);
};

PostBar.defaultProps = {
	onClickMoreMenu: null,
	onClickEmojiTooltip: null,
	commentInputVisible: false,
	isCompactView: false,
};
