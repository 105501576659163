import { EventItemScreenType } from "./types";

interface Action {
	type: string;
	payload: any;
}

const RequestUserListItemReducer = (state: any, action: Action) => {
	switch (action.type) {
		case EventItemScreenType.REQUEST_SCREEN: {
			return {
				...state,
				firstName: action.payload["p.firstName"],
				lastName: action.payload["p.lastName"],
				username: action.payload["p.username"],
				egousername: action.payload["p.egousername"],
				profilePicUrl: action.payload["p.profilePicRelUrl"],
				profId: action.payload["p.prof_id"],
				postText: action.payload["p.postText"],
				reqType: action.payload["p.reqType"],
				mode: action.payload["p.mode"],
				edit: action.payload.p_edit,
				streamNm: action.payload["p.s_name"],
				streamId: action.payload["p.targetStreamId"],
				struname: action.payload["p.s_struname"],
				postType: action.payload["p.postType"],
				status: action.payload["p.status"],
				createTs: action.payload["p.creat_ts"],
			};
		}
		default: return state;
	}
};

export default RequestUserListItemReducer;
