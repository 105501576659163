import React, { useEffect, useRef, useState } from "react";
import {
	BoostActionStatus,
	BoostActionType,
	BoostActionUpdateResponse,
	dbg,
	LogMgr,
	onActionsParam,
} from "@credo/utilities";
import {
	classNames, isWideCalc, SvgIcon, WEB_SIZE_PROP_BUTTON,
} from "../../../common";
import { CurrentBoost } from "./CurrentBoost";
import { BoostIcon } from "../../../assets/icons";

interface BoostOpProps {
	/**
	 * Boost level information of post.
	 * */
	totalBoost: {
		/**
		 * Total boost level which this post has in previous snap
		 * */
		prev: number,
		/**
		 * Total boost level which this post has at this time
		 * */
		current: number,
	}
	/**
	 * actions which are taken in the component. Param
	 * will contain the type of action and the message
	 * with it.
	 *
	 * @see onActionsParam
	 * */
	onActions: (param: onActionsParam) => void | Promise<any> | null;
	/**
	 * set true to display for web
	 * */
	isWide: boolean;
	/**
	 * String literals which will come from locale and
	 * will be shown to the respective entity
	 * */
	messages: {
		// message on boost submitted in toast
		boostSuccess: string;
	}
	/**
	 * control parts of the component throw authorisation
	 * object boolean keys. We can also add some context
	 * functions in the keys
	 * */
	authorisation: {
		isBoostAllowed: boolean;
	}
}

const INCREMENT_VALUE = 100;

const isPromise = (op: any) => typeof op === "object" && typeof op.then === "function" && typeof op.catch === "function";

enum BoostIncrementStatus {
	INITIAL = "i",
	PENDING = "p",
	SUCCESS = "s",
	ERROR = "e"
}

/**
 * Boost operations like increasing the boost or
 * decreasing the boost
 * */
const BoostOpComponent: React.FC<BoostOpProps> = (props: BoostOpProps) => {
	const {
		totalBoost,
		onActions,
		isWide,
		messages,
		authorisation,
	} = props;

	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [boostIncrementStatus, setBoostIncrementStatus] = useState<BoostIncrementStatus>(BoostIncrementStatus.INITIAL);

	/**
	 * Using this effect for updating fade-out animation while exiting the
	 * toast.
	 * */
	useEffect(() => {
		if (boostIncrementStatus === BoostIncrementStatus.SUCCESS && document) {
			const element = document.getElementById("boost-success-toast");
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
			if (element) {
				timerRef.current = setTimeout(() => {
					element.classList.remove("animate-[fadeIn_0.75s_ease-in-out]");
					element.classList.add("animate-[fadeOut_1s_ease-in-out]", "opacity-0");
				}, 3000);
			}
		}
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [boostIncrementStatus]);

	const handleIncrement = () => {
		setBoostIncrementStatus(BoostIncrementStatus.PENDING);
		const result = onActions({
			type: BoostActionType.INCREMENT,
			message: {
				value: INCREMENT_VALUE,
			},
		});
		if (result && isPromise(result)) {
			result
				.then((response: BoostActionUpdateResponse) => {
					if (dbg) {
						LogMgr.mydbg("BoostOp.handleIncrement got response from the server", response);
					}
					if (response?.boostStatus === BoostActionStatus.SUCCESS) {
						setBoostIncrementStatus(BoostIncrementStatus.SUCCESS);
					} else {
						setBoostIncrementStatus(BoostIncrementStatus.ERROR);
					}
				})
				.catch((error) => {
					if (dbg) {
						LogMgr.mydbg("BoostOp.handleIncrement got error from the server", error);
					}
					setBoostIncrementStatus(BoostIncrementStatus.ERROR);
				});
		}
	};

	return (
		<div
			data-testid="boost-op"
			className="p-4 flex flex-col items-center relative"
		>
			{boostIncrementStatus === BoostIncrementStatus.SUCCESS && (
				<div
					id="boost-success-toast"
					className={classNames(
						"bg-accent-2 text-4xs px-1 rounded-full text-dark-main",
						"absolute bottom-6 -left-[42px] translate-x-1/2",
						"drop-shadow-xl",
						"animate-[fadeIn_0.75s_ease-in-out]",
					)}
				>
					{messages.boostSuccess}
				</div>
			)}
			<button
				type="button"
				data-testid="boost-increment-button"
				className={classNames(
					"bg-tag-green w-14 !text-black !font-bold !border-dark-main h-6 rounded-[16px] uppercase",
					"flex justify-start items-center relative px-1",
				)}
				onClick={handleIncrement}
				style={{
					height: isWideCalc(isWide, 22, WEB_SIZE_PROP_BUTTON),
					width: isWideCalc(isWide, 54, WEB_SIZE_PROP_BUTTON),
					fontSize: isWideCalc(isWide, 11, WEB_SIZE_PROP_BUTTON),
					lineHeight: `${isWideCalc(isWide, 11, WEB_SIZE_PROP_BUTTON)}px`,
				}}
				disabled={!authorisation.isBoostAllowed || boostIncrementStatus === BoostIncrementStatus.PENDING}
			>
				<span
					className="pb-px"
				>
					{`+${INCREMENT_VALUE}`}
				</span>
				<SvgIcon
					icon={BoostIcon}
					className={classNames(
						"absolute",
						isWide ? "right-0.5" : "right-1",
					)}
					height={isWideCalc(isWide, 16)}
					width={isWideCalc(isWide, 16)}
				/>
			</button>
			<div
				className={classNames(
					"py-2",
					isWide ? "w-10" : "w-8",
				)}
			>
				<CurrentBoost totalBoost={totalBoost} />
			</div>
			{/* <SecondaryButton */}
			{/*	data-testid="boost-decrement-button" */}
			{/*	size={ButtonSize.SMALL} */}
			{/*	label="-10" */}
			{/*	buttonClassNames="bg-error w-14 !text-black !font-bold !border-dark-main h-6" */}
			{/*	rightIcon={( */}
			{/*		<SvgIcon */}
			{/*			icon={BoostIcon} */}
			{/*			className="pl-1" */}
			{/*			height={isWideCalc(isWide, 16)} */}
			{/*			width={isWideCalc(isWide, 16)} */}
			{/*			// @ts-ignore */}
			{/*			borderStroke="var(--error)" */}
			{/*		/> */}
			{/*	)} */}
			{/*	leftIcon={( */}
			{/*		<div */}
			{/*			className={classNames( */}
			{/*				isWide ? "pl-2.5" : "pl-2", */}
			{/*			)} */}
			{/*		/> */}
			{/*	)} */}
			{/*	onClick={handleIncrement} */}
			{/*	style={{ */}
			{/*		height: isWideCalc(isWide, 22, WEB_SIZE_PROP_BUTTON), */}
			{/*		width: isWideCalc(isWide, 54, WEB_SIZE_PROP_BUTTON), */}
			{/*		fontSize: isWideCalc(isWide, 11, WEB_SIZE_PROP_BUTTON), */}
			{/*		lineHeight: `${isWideCalc(isWide, 11, WEB_SIZE_PROP_BUTTON)}px`, */}
			{/*	}} */}
			{/* /> */}
		</div>
	);
};

const compareFn = (nextProps: BoostOpProps, prevProps: BoostOpProps) => nextProps.totalBoost === prevProps.totalBoost
	&& nextProps.isWide === prevProps.isWide
	&& JSON.stringify(nextProps.authorisation) === JSON.stringify(prevProps.authorisation);

// eslint-disable-next-line import/prefer-default-export
export const BoostOp = React.memo(
	BoostOpComponent,
	compareFn,
);
