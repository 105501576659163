import React from "react";
import { FeedNoPost } from "@credo/ui-components";
import { strings } from "../../../i18n/config";

interface NoItemsToLoadProps {
	height: string | number;
}

// eslint-disable-next-line import/prefer-default-export
export const NoItemsToLoad: React.FC<NoItemsToLoadProps> = (props: NoItemsToLoadProps) => (
	<div
		className="w-full flex flex-col h-screen items-center py-32"
		style={{
			// eslint-disable-next-line react/destructuring-assignment
			height: props.height,
		}}
	>
		<FeedNoPost />
		<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
			{strings("StreamProfile.no_post_message")}
		</span>
	</div>
);
