import React, { useEffect, useRef, useState } from "react";
import {
	classNames, BlockedUsersSkeleton, NoBlockedUsers, Spinner, SpinnerLeafType, SvgIcon, ConfirmationModal,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isMobile } from "react-device-detect";
import { ActionType, AppMode, cu } from "@credo/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import { isCredoModeAtom } from "@credo/store";
import { useWindowSize } from "react-use";
import {
	OperationType,
	RelationTypeStatus,
} from "../../../utils/types";
import {
	isConnectedAtom, userIdAtom,
} from "../../../utils/atoms";
import { strings } from "../../../i18n/config";
import { UserActions } from "../../../services/actions/user";
import CfgMgr from "../../../config/CfgMgr";
import BlockedUserItem, { BlockedUser } from "./BlockedUserItem";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";
import { Consts } from "../../../utils";

const SettingsPSBlockedUsersScreen: React.FC = () => {
	const [isConnected] = useAtom(isConnectedAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userId] = useAtom(userIdAtom);
	const [data, setData] = useState<BlockedUser[]>([]);
	const [blockedUser, setBlockedUser] = useState<BlockedUser | null>(null);
	const [isLastPage, setIsLastPage] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const dataPage = useRef(1);
	const dataSeed = useRef(1);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const { width } = useWindowSize();
	const isInMobile = isMobile && width < 768;

	useSetAppHeader({
		title: strings("SettingsPrivacySettingsScreen.blocked_users"),
	});

	const closeConfirmationModal = () => {
		setBlockedUser(null);
		setOpenConfirmationModal(false);
	};

	const fetchBlockedUserList = async (inProcessSetter: (inProcess: boolean) => void, op: OperationType) => {
		if (!isConnected) {
			if (isLoading) {
				setIsLoading(false);
			}
			return;
		}
		try {
			inProcessSetter(true);
			const requestObject = {
				user_id: userId || "",
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				page: dataPage.current,
				seed: dataSeed.current,
				lastTs: 0,
				lastId: 0,
				fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
			};

			const successCallback = (response: any) => {
				const items = response?.items;
				if (op === "replace") {
					setData(items);
				} else {
					setData([...data, ...items]);
				}

				if (items.length < CfgMgr.cfg_feed_nbOfItems2FetchFromDb) {
					setIsLastPage(true);
				}
				inProcessSetter(false);
			};

			const errorCallback = () => {
				inProcessSetter(false);
			};

			await UserActions.getBlockedUsers(
				requestObject,
				successCallback,
				errorCallback,
			);
		} catch (error) {
			inProcessSetter(false);
		}
	};

	const handleLoadMore = () => {
		if (isLoading || isRefreshing || isLastPage) return;
		dataPage.current += 1;
		fetchBlockedUserList(setIsRefreshing, OperationType.APPEND);
	};

	const handleRefresh = () => {
		if (isLoading || isRefreshing) return;
		dataPage.current = 1;
		dataSeed.current += 1;
		setData([]);
		fetchBlockedUserList(setIsLoading, OperationType.REPLACE);
	};

	useEffect(() => {
		if (isConnected) {
			dataPage.current = 1;
			dataSeed.current = 1;
			fetchBlockedUserList(data.length === 0 ? setIsLoading : setIsRefreshing, OperationType.REPLACE);
		} else if (!userId) {
			setIsLoading(false);
			setIsRefreshing(false);
		}
	}, [isConnected, userId]);

	const doUserUnblock = () => {
		const requestObject = {
			user_id: userId || "",
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
			prof_id: blockedUser?.prof_id || "",
			relType: RelationTypeStatus.BLOCKED,
			action: ActionType.DELETE,
		};
		UserActions.manageRelation4User(requestObject);
		handleRefresh();
		closeConfirmationModal();
	};

	useEffect(() => {
		setIsLastPage(false);
		handleRefresh();
	}, [isCredoMode]);

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		);
	};

	const showConfirmationPopupForUnblockUser = (item: any) => {
		setBlockedUser(item);
		setOpenConfirmationModal(true);
	};

	const renderStreamItem = ({ item }: any) => (
		<BlockedUserItem
			item={item}
			isLast={!isLastPage}
			unblockUser={showConfirmationPopupForUnblockUser}
		/>
	);

	const renderNoDataContent = () => (
		<div className="w-full flex justify-center items-center text-basic h-full mt-10">
			<div className="flex flex-col justify-center items-center mt-2">
				<SvgIcon icon={NoBlockedUsers} width={96} height={96} className="ml-4" />
				<span className="text-basic pt-4">
					{strings("SettingsPSBlockedUsersScreen.no_block_user_message")}
				</span>
			</div>
		</div>
	);

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-5",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<div className={classNames("w-full text-basic pt-2", isMobile ? "px-2" : "")}>
				{isLoading
					? (
						<div
							className={classNames(
								"mt-2 w-full text-basic overflow-hidden",
								isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
							)}
						>
							<BlockedUsersSkeleton noOfItems={30} />
						</div>
					) : (
						<>
							{data && data.length > 0
								? (
									<InfiniteScroll
										dataLength={data.length}
										next={handleLoadMore}
										hasMore
										loader={renderFooter()}
										className="!overflow-visible"
									>
										{data.map((item: any, idx: any) => renderStreamItem({ item, index: idx }))}
									</InfiniteScroll>
								)
								: renderNoDataContent()}
						</>
					)}
				<ConfirmationModal
					isVisible={openConfirmationModal}
					onClose={closeConfirmationModal}
					cancel_button_function={closeConfirmationModal}
					ok_button_title={strings("SettingsPSBlockedUsersScreen.unblock")}
					cancel_button_title={strings("SettingsPSBlockedUsersScreen.cancel")}
					ok_button_function={doUserUnblock}
					title={strings("SettingsPSBlockedUsersScreen.unblock_user")}
					message={strings("SettingsPSBlockedUsersScreen.confirmation_message")}
					okButtonStyle=""
					cancelButtonStyle=""
				/>
			</div>
		</div>
	);
};

export default SettingsPSBlockedUsersScreen;
