// eslint-disable-next-line import/prefer-default-export
import {
	EngageIcon,
	HomeIcon,
	NewPostIcon,
	NotificationIcon,
	ProfileIcon,
	BlastsIcon,
	NewsIcon,
	TopIcon,
	ExploreIcon,
	BotsIcon,
	RequestIcon,
	NetworkIcon,
	VoltsNavIcon,
	ContactsIcon,
	SettingIcon,
} from "@credo/ui-components";
import cloneDeep from "lodash/cloneDeep";
import { EngageTabOptions, LoggedInMenu } from "../utils/types";
import { strings } from "../i18n/config";
import {
	BLASTS_PAGE,
	COMMUNITIES_PAGE,
	ENGAGE_PAGE, EXPLORE_PAGE, HOME_PAGE, NETWORK_PAGE,
	NEWS_PAGE, NOTIFICATIONS_PAGE, POST_DETAIL_PAGE, PROFILE_PAGE,
	REGISTER_INTEREST, REQUEST_PAGE, SETTINGS_PAGE, TOP_PAGE,
} from "../routes/constants";
import CfgMgr from "./CfgMgr";
import { AppUtils } from "../utils";

export const SID = "sid";
export const DEV_MODE = (CfgMgr.CFG_DEBUG_ENABLED === "true") || (CfgMgr.CFG_FLAVOR === "DEV");
export const NO_RIGHT_LEFT_MENU_PAGES = [
	"login",
	"404",
	"signInViaEmailLink",
	"linkedInAuth",
	"linkedInAuthMobile",
	"legal",
	"support",
];
export const NO_BOTTOM_BANNER_PAGES = [
	"linkedInAuthMobile",
];

export const SIDE_NAV_MIN_SCREEN_WIDTH = 968;
export const RIGHT_MENU_MIN_SCREEN_WIDTH = 1410;
export const BOTTOM_NAV_LONGPRESS_TIME = 150;	// ms

/**
 * The array of routes on which the right and
 * left menu must not be displayed, but the
 * child must be in the center. And it is also
 * used for removing the search input field from
 * the header.
 * */
export const NO_RL_MENU_WITH_CHILD_CENTER = [
	"register",
	"expertise",
	"confirm-register",
	REGISTER_INTEREST.substring(1),
];

export const NO_HEADER = [
	"404",
	"legal",
	"support",
];

export const TAB_NAMES = [
	"home",
	"manage",
	"new-post",
	"inbox",
	"profile",
];

export const HIDE_BACK_ICON_SCREENS = [
	strings("Sidebar.for_you"),
	strings("Sidebar.blasts"),
	strings("Sidebar.news"),
	strings("Sidebar.top"),
	strings("Sidebar.review"),
	strings("Sidebar.manage"),
	strings("Sidebar.explore"),
	strings("AppHeader.my_communities"),
	strings("Sidebar.newPost"),
	strings("Sidebar.inbox"),
	strings("Sidebar.profile"),
	strings("Sidebar.requests"),
	strings("Sidebar.network"),
	strings("Sidebar.settings"),
	"for-you",
	"communities",
];

export const DYNAMIC_ACTIONS_FEED_SCREENS = [
	strings("Sidebar.for_you"),
	strings("Sidebar.blasts"),
	strings("Sidebar.news"),
	strings("Sidebar.top"),
];

export const FILL_ICONS = [
	"news",
	"explore",
	"boosts",
	"bots",
	"requests",
	"contacts",
	"network",
	"volts",
	"search",
	"settings",
];

export const UNIQUE_HEADERS = ["p", "u"];

export enum UniqueHeaderType {
	POST = "p",
	USER = "u",
	// COMMUNITy = "c"
}

export const NO_BOTTOM_NAV_BAR = ["signInViaEmailLink", "linkedInAuth", "linkedInAuthMobile", "p"];
export const NO_HEADER_PAGES = ["linkedInAuth", "linkedInAuthMobile"];
export const RELOAD_QR_TIME = 30000;
export const AUTH_MODAL_TIMEOUT = 10000;
export const DEFAULT_COUNTRY_CODE = DEV_MODE ? "hk" : "us";
export const DEFAULT_COUNTRY_DIAL = DEV_MODE ? "852" : "1";
export const MAX_CODE_DIGITS = 6;
export const MAX_USER_FULL_NAME_CHAR = 40;
export const MAX_SUBJECT_CHAR = 50;
export const MAX_STREAM_NAME_CHAR = 50;
export const MAX_DESCRIPTION_CHAR = 1000;
export const MAX_USER_INTRO_CHAR = 250;
export const INPUT_VALUE_DEBOUNCE_TIME = 700;
export const LANG_REQ_DEBOUNCE_TIME = 1000;
export const HEADER_HEIGHT = 68;
export const HOME_FEED_TAB_HEADER_HEIGHT = 41;
export const BOTTOM_NAV_HEIGHT = 60;
export const LOGOUT_MODAL_ALIVE_TIME = 5000;
export const BACK_ON_POST_STRING = `${POST_DETAIL_PAGE}/id`;
export const NEW_POST_ANIMATION = 100;
export const DEFAULT_ACTIVE_BOOST_TAG: string = "crypto";
export const MOBILE_L = 425;
export const VOLTS_MODAL_WEB_PROP = 1.2;
export const WIDGET_CAROUSEL_INTERVAL = 10_000;
export const BTC_CONV_COEFF = 0.00000001;
export const BTC_BALANCE_PRECISION = 8;
export const LOGGED_IN_MENU: LoggedInMenu[] = [
	{
		id: 1,
		name: strings("Sidebar.for_you"),
		href: HOME_PAGE,
		icon: HomeIcon,
		testId: "home",
	},
	{
		id: 2,
		name: strings("Sidebar.manage"),
		icon: EngageIcon,
		href: `${ENGAGE_PAGE}${COMMUNITIES_PAGE}`,
		testId: "manage",
	},
	{
		id: 3,
		name: strings("Sidebar.newPost"),
		icon: NewPostIcon,
		testId: "new-post",
		onclick: () => AppUtils.handleNewPostModal(true),
	},
	{
		id: 4,
		name: strings("Sidebar.inbox"),
		href: `${NOTIFICATIONS_PAGE}`,
		icon: NotificationIcon,
		testId: "inbox",
	},
	// {
	//	id: 5,
	// 	name: strings("Sidebar.IM"),
	// 	icon: IMIcon,
	// },
	{
		id: 6,
		name: strings("Sidebar.profile"),
		icon: ProfileIcon,
		href: PROFILE_PAGE,
		testId: "profile",
	},
	// {
	// 	id: 7,
	// 	name: strings("Sidebar.settings"),
	// 	icon: SettingsIcon,
	// 	href: SETTINGS_PAGE,
	// 	testId: "settings",
	// },
	// {
	// 	id: 8,
	// 	name: strings("Sidebar.more"),
	// 	icon: ThreeDotIcon,
	// 	testId: "more-options",
	// },
];

export const BOTTOM_MOBILE_MENU: LoggedInMenu[] = [
	{
		id: 2,
		name: strings("Sidebar.for_you"),
		href: HOME_PAGE,
		icon: HomeIcon,
		testId: "home",
	},
	{
		id: 3,
		name: strings("Sidebar.manage"),
		icon: EngageIcon,
		href: `${ENGAGE_PAGE}?tab=${EngageTabOptions.COMMUNITIES}`,
		testId: "manage",
	},
	{
		id: 4,
		name: strings("Sidebar.newPost"),
		icon: NewPostIcon,
		href: "",
		testId: "new-post",
		onclick: () => AppUtils.handleNewPostModal(true),
	},
	{
		id: 1,
		name: strings("Sidebar.inbox"),
		href: `${NOTIFICATIONS_PAGE}`,
		icon: NotificationIcon,
		testId: "inbox",
		hideDesktop: true,
	},
	{
		id: 6,
		name: strings("Sidebar.profile"),
		icon: ProfileIcon,
		href: PROFILE_PAGE,
		testId: "profile",
		hideDesktop: true,
	},
];

export const HOME_MENU: LoggedInMenu[] = [
	{
		id: 1,
		name: strings("Sidebar.for_you"),
		href: HOME_PAGE,
		icon: HomeIcon,
		testId: "for-you",
		active: false,
		default: true,
	},
	{
		id: 2,
		name: strings("Sidebar.blasts"),
		href: BLASTS_PAGE,
		icon: BlastsIcon,
		testId: "blasts",
		active: false,
		default: false,
		isPublic: true,
	},
	{
		id: 3,
		name: strings("Sidebar.news"),
		href: NEWS_PAGE,
		icon: NewsIcon,
		testId: "news",
		active: false,
		default: false,
		isPublic: true,
	},
	{
		id: 4,
		name: strings("Sidebar.top"),
		href: TOP_PAGE,
		icon: TopIcon,
		testId: "top",
		active: false,
		default: false,
	},
	// {
	// 	id: 5,
	// 	name: strings("Sidebar.review"),
	// 	href: REVIEW_PAGE,
	// 	icon: ReviewIcon,
	// 	testId: "review",
	// 	active: false,
	// 	default: false,
	// },
];

export const MANAGE_MENU: LoggedInMenu[] = [
	{
		id: 1,
		name: strings("Sidebar.manage"),
		href: `${ENGAGE_PAGE}${COMMUNITIES_PAGE}`,
		icon: EngageIcon,
		testId: "communities",
		active: false,
		default: true,
	},
	{
		id: 2,
		name: strings("Sidebar.explore"),
		href: `${ENGAGE_PAGE}${EXPLORE_PAGE}`,
		icon: ExploreIcon,
		testId: "explore",
		active: false,
		default: false,
	},
	// {
	// 	id: 3,
	// 	name: strings("Sidebar.boosts"),
	// 	icon: BoostsIcon,
	// 	testId: "boosts",
	// 	active: false,
	// 	default: false,
	// 	onclick: () => AppUtils.handleDownloadAppModal(true),
	// },
	{
		id: 4,
		name: strings("Sidebar.bots"),
		icon: BotsIcon,
		testId: "bots",
		active: false,
		default: false,
		onclick: () => AppUtils.handleDownloadAppModal(true),
	},
];

export const PROFILE_MENU: LoggedInMenu[] = [
	{
		id: 1,
		name: strings("Sidebar.profile"),
		href: PROFILE_PAGE,
		icon: ProfileIcon,
		testId: "profile",
		active: false,
		default: true,
	},
	{
		id: 2,
		name: strings("Sidebar.requests"),
		href: `${PROFILE_PAGE}${REQUEST_PAGE}`,
		icon: RequestIcon,
		testId: "requests",
		active: false,
		default: false,
	},
	{
		id: 3,
		name: strings("Sidebar.network"),
		href: `${PROFILE_PAGE}${NETWORK_PAGE}`,
		icon: NetworkIcon,
		testId: "network",
		active: false,
		default: false,
	},
	{
		id: 4,
		name: strings("Sidebar.volts"),
		// href: "",
		icon: VoltsNavIcon,
		testId: "volts",
		active: false,
		default: false,
		onclick: () => AppUtils.handleDownloadAppModal(true),
	},
	{
		id: 5,
		name: strings("Sidebar.contacts"),
		// href: "",
		icon: ContactsIcon,
		testId: "contacts",
		active: false,
		default: false,
		onclick: () => AppUtils.handleDownloadAppModal(true),
	},
	// {
	// 	id: 6,
	// 	name: strings("Sidebar.search"),
	// 	// href: "",
	// 	icon: SearchPersonIcon,
	// 	testId: "search",
	// 	active: false,
	// 	default: false,
	// 	onclick: () => AppUtils.handleDownloadAppModal(true),
	// },
	{
		id: 7,
		name: strings("Sidebar.settings"),
		href: `${PROFILE_PAGE}${SETTINGS_PAGE}`,
		icon: SettingIcon,
		testId: "settings",
		active: false,
		default: false,
	},
];

export const FREEZED_MENU = Object.freeze(cloneDeep(LOGGED_IN_MENU));

export const BOTTOM_HOME_MENU = cloneDeep(HOME_MENU).reverse();
export const BOTTOM_MANAGE_MENU = cloneDeep(MANAGE_MENU).reverse();
export const BOTTOM_PROFILE_MENU = cloneDeep(PROFILE_MENU).reverse();
