import { cu } from "@credo/utilities";

// eslint-disable-next-line import/prefer-default-export
export class WSConnStat {
	static CONN_OPEN_COND = "CO";

	static IS_NETWORK = "NET";

	static FIREBASE_CONN_COND = "FBCON"; // indicating firebase real-time database connection status

	static conds: {
		[key: string]: any;
	} = {};

	static isMetCond(condName: string) {
		return cu.isSet(condName) && WSConnStat.conds[condName] === true;
	}

	static announceMetCond(condName: string) {
		WSConnStat.conds[condName] = true;
	}

	static announceNotMetCond(condName: string) {
		WSConnStat.conds[condName] = false;
	}
}
