import React from "react";
import ReactDOM from "react-dom/client";
import "@credo/ui-components/dist/index.css";
import "./index.css";
import "react-phone-input-2/lib/high-res.css";
import "./assets/styles/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

// TODO: Websocket was connecting twice so had to comment React StrictMode. It only runs in dev env anyway.
root.render(
	// <React.StrictMode>
	<CookiesProvider>
		<App />
	</CookiesProvider>,
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
