import { useEffect } from "react";
import { LinkedInMgr } from "../../modules/auth/utils/LinkedInMgr";

/**
 * Adds an event to capture the message posted by the popUp window
 * while authenticating via LinkedIn.
 * */
// eslint-disable-next-line import/prefer-default-export
export const useLinkedInAuthentication = () => {
	useEffect(() => {
		/**
		 * TODO: Come up with a solution to add the listener only if
		 * the user is not logged in, so that we will not add it
		 * unnecessarily.
		 * */
		window.addEventListener("message", LinkedInMgr.handlePostMessage);

		return () => {
			window.removeEventListener("message", LinkedInMgr.handlePostMessage);
		};
	}, []);
};
