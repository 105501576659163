import React, { SVGAttributes, useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { cu } from "@credo/utilities";
import Lightbox from "react-image-lightbox";
import { UCWSize } from "../../credo-cred-widget";
import { classNames, SvgIcon, WEB_SIZE_PROP_BUTTON } from "../../common";
import { ProfileSize } from "../types";
import { CredoSwitchSize } from "../../credo-switch/types";
import { EgoAvatarPlaceholderIcon } from "../../assets/icons";
import "react-image-lightbox/style.css";

// eslint-disable-next-line no-undef
export interface ProfilePictureProps extends SVGAttributes<HTMLOrSVGElement> {
	/**
	 * Profile picture source url
	 * @default ""
	 * */
	profilePicUrl: string;
	/**
	 * Custom CSS class for profile picture
	 * @default ""
	 * */
	profilePicStyle?: string;
	/**
	 * Attribute that determines the profile picture for a stream or user
	 * @default false
	 * */
	isStream?: boolean;
	/**
	 * Profile ID only required for user profile
	 * @default null
	 * */
	profId?: string;
	/**
	 * Boolean Attribute for shwoing widget in profile
	 * @default false
	 * */
	showCredoWidgetInProfilePic?: boolean;
	/**
	 * Cached Ratings for user profile picture in credo mode
	 * @default any
	 * */
	cachedRatings?: any;
	/**
	 * function after clicking on profile picture
	 * @default null
	 * */
	onProfilePicPress?: (event: React.MouseEvent<HTMLElement>) => void;
	/**
	 * Custom CSS class for profile picture container
	 * @default ""
	 * */
	profilePicWrapperStyle?: string;
	/**
	 * css styling object for actual image
	 * @default {}
	 * */
	imageStyle?: Object;
	/**
	 * Set size for the profile picture
	 * @default ProfileSize.SMALL
	 * */
	size?: ProfileSize;
	/**
	 * Set size for the UCW
	 * @default UCWSize.SMALL
	 * */
	ucw_size?: UCWSize | CredoSwitchSize;
	showImagePreview?: boolean;
}

export const ProfilePicture = (props: ProfilePictureProps) => {
	const {
		profilePicUrl,
		profilePicStyle,
		isStream,
		profId,
		showCredoWidgetInProfilePic,
		cachedRatings = {},
		onProfilePicPress,
		profilePicWrapperStyle,
		imageStyle,
		size,
		ucw_size,
		showImagePreview,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
	const [showCredoWidget, setShowCredoWidget] = useState(false);
	const imageSizeStyle = {
		[ProfileSize.XX_SMALL]: { width: 13, height: 13, borderRadius: isStream ? 4 : 16 },
		[ProfileSize.XX_SMALL_WEB]: { width: 13 * WEB_SIZE_PROP_BUTTON, height: 13 * WEB_SIZE_PROP_BUTTON, borderRadius: isStream ? 4 : 16 },
		[ProfileSize.X_SMALL]: { width: 25, height: 25, borderRadius: isStream ? 4 : 20 },
		[ProfileSize.X_SMALL_SEMI]: { width: 30, height: 30, borderRadius: isStream ? 4 : 20 },
		[ProfileSize.X_SMALL_MEDIUM]: { width: 35, height: 35, borderRadius: isStream ? 4 : 20 },
		[ProfileSize.SMALL]: { width: 40, height: 40, borderRadius: isStream ? 4 : 20 },
		[ProfileSize.SMALL_MEDIUM]: { width: 50, height: 50, borderRadius: isStream ? 4 : 25 },
		[ProfileSize.MEDIUM]: { width: 60, height: 60, borderRadius: isStream ? 10 : 30 },
		[ProfileSize.LARGE]: { width: 100, height: 100, borderRadius: isStream ? 10 : 50 },
	};
	const [imgLoading, setImgLoading] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);

	const imageLoaded = () => {
		setImgLoading(false);
	};

	const onProfileClick = (event: React.MouseEvent<HTMLElement>) => {
		if (onProfilePicPress) {
			onProfilePicPress(event);
		} else if (showImagePreview) {
			setViewerIsOpen(true);
		}
	};

	const closeLightbox = useCallback(() => {
		setViewerIsOpen(false);
	}, []);

	const getProfileImage = () => {
		if (profilePicUrl) {
			return profilePicUrl;
		}
		const streamDefaultProfileImage = cu.buildSourceUrlImage("s_pp_dg.png");
		if (!profilePicUrl && isStream && cu.isSet(streamDefaultProfileImage)) {
			return streamDefaultProfileImage;
		}
		const egoDefaultProfileImage = cu.buildSourceUrlImage("u_e_pp.png");
		return egoDefaultProfileImage;
	};

	const renderImagePreviewModal = () => {
		if (viewerIsOpen) {
			return (
				<Lightbox
					mainSrc={getProfileImage() || ""}
					onCloseRequest={closeLightbox}
					imageCaption=""
					onImageLoad={() => {
						window.dispatchEvent(new Event("resize"));
					}}
					wrapperClassName="bg-modal"
					reactModalStyle={{ backgroundColor: "var(--bg-modal)" }}
				/>
			);
		}
		return null;
	};

	const renderDefaultUserProfile = () => (
		<div
			className={classNames(
				profilePicWrapperStyle!,
			)}
		>
			<SvgIcon
				onClick={onProfileClick}
				icon={EgoAvatarPlaceholderIcon}
				style={{
					...imageSizeStyle[size || ProfileSize.SMALL],
				}}
			/>
			{renderImagePreviewModal()}
		</div>
	);

	// Removing UserCredibilityWidget
	// const showUCW = () => {
	// 	if (onProfilePicPress) {
	// 		if (profId && cachedRatings && !cachedRatings[`${profId}ucred`]) {
	// 			setIsLoading(true);
	// 		}
	// 		onProfilePicPress();
	// 	}
	// 	setShowCredoWidget(true);
	// };

	// const zoomWidget = () => {
	// 	if (!isLoading) {
	// 		setTimeout(() => {
	// 			// timeout for showing animation for 3 secs
	// 			setShowCredoWidget(false);
	// 		}, 4000);
	// 	}
	// };

	// useEffect(() => {
	// 	if (showCredoWidget) {
	// 		zoomWidget();
	// 	}
	// }, [isLoading, showCredoWidget]);

	// useEffect(() => {
	// 	if (profId && cachedRatings && cachedRatings[`${profId}ucred`]) {
	// 		setIsLoading(false);
	// 	}
	// }, [profId && cachedRatings && cachedRatings[`${profId}ucred`]]);

	// if (showCredoWidget && showCredoWidgetInProfilePic) {
	// 	return (
	// 		<div
	// 			style={{
	// 				...imageSizeStyle[size || ProfileSize.SMALL], ...imageStyle,
	// 			}}
	// 			className="flex border-transparent bg-transparent justify-center items-center"
	// 		>
	// 			{isLoading
	// 				? (
	// 					<Spinner
	// 						width="20"
	// 						height="20"
	// 						leaf_fill="var(--primary)"
	// 					/>
	// 				)
	// 				: (
	// 					<div className="animate-zoomInAndOut">
	// 						<div
	// 							style={{
	// 								...imageSizeStyle[size || ProfileSize.SMALL], ...imageStyle,
	// 							}}
	// 							className="flex border border-primary bg-background-primary justify-center items-center"
	// 						>
	// 							<div className="ml-0.5">
	// 								<UserCredibilityWidget
	// 									uCredibility={(profId && cachedRatings && cachedRatings[`${profId}ucred`]) ?? 0}
	// 									size={ucw_size || UCWSize.SMALL}
	// 								/>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				)}
	// 		</div>
	// 	);
	// }

	if ((!profilePicUrl && !isStream) || isError) {
		return renderDefaultUserProfile();
	}

	if (!profilePicUrl && isStream) {
		if (cu.isSet(cu.buildSourceUrlImage("s_pp_dg.png"))) {
			return (
				<div
					className={classNames(
						profilePicWrapperStyle!,
					)}
				>
					<img
						// TODO: When using lazy loading the image's onload was not working
						onClick={onProfileClick}
						src={cu.buildSourceUrlImage("s_pp_dg.png") || ""}
						alt="pic"
						onLoad={imageLoaded}
						style={{
							display: imgLoading ? "none" : "block",
							...imageSizeStyle[size || ProfileSize.SMALL],
							...imageStyle,
						}}
						className={classNames("justify-center items-center border cursor-pointer",
							isStream ? "border-solid border-profile-border rounded-lg" : "border-profile-border",
							profilePicStyle)}
						aria-hidden
					/>
					{renderImagePreviewModal()}
				</div>
			);
		} else {
			return renderDefaultUserProfile();
		}
	}

	return (
		<div
			className={classNames(
				profilePicWrapperStyle!,
				"profile-pic-wrapper",
			)}
		>
			<img
				// TODO: When using lazy loading the image's onload was not working
				onClick={onProfileClick}
				src={profilePicUrl}
				alt="pic"
				onLoad={imageLoaded}
				onError={(event) => {
					setIsError(true);
				}}
				style={{
					display: imgLoading ? "none" : "block",
					...imageSizeStyle[size || ProfileSize.SMALL],
					...imageStyle,
				}}
				className={classNames("justify-center items-center border cursor-pointer",
					isStream ? "border-solid border-profile-border rounded-lg" : "border-profile-border",
					profilePicStyle)}
				aria-hidden
			/>
			{imgLoading ? (
				<Skeleton
					circle={!isStream}
					style={{
						...imageSizeStyle[size || ProfileSize.SMALL],
						...imageStyle,
					}}
					className={classNames("justify-center items-center border cursor-pointer",
						isStream ? "border-solid border-profile-border rounded-lg" : "border-profile-border",
						profilePicStyle)}
					baseColor="var(--background-skeleton)"
					highlightColor="var(--hightlight-skeleton)"
				/>
			) : null}
			{renderImagePreviewModal()}
		</div>
	);
};

ProfilePicture.defaultProps = {
	profilePicStyle: "",
	isStream: false,
	onProfilePicPress: null,
	showCredoWidgetInProfilePic: false,
	cachedRatings: {},
	profId: null,
	profilePicWrapperStyle: "",
	imageStyle: {},
	size: ProfileSize.SMALL,
	ucw_size: UCWSize.SMALL,
	showImagePreview: false,
};
