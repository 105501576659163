/* eslint-disable no-nested-ternary */
import { PostHeaderScreenType, RegEx, SnackBarTypeOptions } from "@credo/ui-components";
import {
	AppMode, cu, dbg, EvtMgr, LogMgr, PostTag, SessMgr, ThemeOptions, YesNoOptions,
} from "@credo/utilities";
import moment from "moment";
import isNil from "lodash/isNil";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { isMobile, isSafari } from "react-device-detect";
import last from "lodash/last";
import { strings } from "../i18n/config";
import {
	OTHER_USER_PROFILE_PAGE, POST_DETAIL_PAGE, PROFILE_PAGE, STREAM_PROFILE_PAGE,
} from "../routes/constants";
import { Consts, EventConst } from "./Consts";
import FirebaseMgr from "./FirebaseMgr";
import { isCredoConnOpenAndNetAvailable } from "./network";
import {
	ConfirmationModalProps, EngageTabOptions, FeedType, ProposeTagActions, Tag, UserProfile,
} from "./types";
import { FeedItem } from "../modules/home/requests";
import CfgMgr from "../config/CfgMgr";
import { MiscActions } from "../services/actions/misc";

/**
 * Current App utilities.
 * Encapsulation of basic small utilities for Web App (Current App).
 * */
class AppUtils {
	/**
	 * Set `true` if you need to display login modal.
	 * Sets the login modal state to display or close whenever called.
	 *
	 * @param {boolean} show state passed which needs to be set to
	 * display the modal or not.
	 * */
	static handleLoginModal(show: boolean) {
		EvtMgr.getInstance(EventConst.showLoginModal).notifyListeners(show);
	}

	/**
	 * Set `true` if you need to display new post modal.
	 *
	 * @param {boolean} show state passed which needs to be set to
	 * display the modal or not.
	 * */
	static handleNewPostModal(show: boolean) {
		EvtMgr.getInstance(EventConst.showNewPostModal).notifyListeners(show);
	}

	/**
	 * Set `true` if you need to display download app modal.
	 * Sets the download app modal state to display or close whenever called.
	 *
	 * @param {boolean} show state passed which needs to be set to
	 * display the modal or not.
	 * */
	static handleDownloadAppModal(show: boolean) {
		EvtMgr.getInstance(EventConst.showDownloadAppModal).notifyListeners(show);
	}

	/**
	 * Returns true if app is in dev mode by checking the env
	 * */
	static isDevMode() {
		return CfgMgr.CFG_FLAVOR === "DEV";
	}

	/**
	 * Show toast msg with msg type
	 * @param props snackbar props
	 * */
	static showToast(props: {
		showButton?: boolean;
		buttonTitle?: string;
		containerStyle?: string;
		textStyle?: string;
		buttonStyle?: string;
		message: string;
		type?: SnackBarTypeOptions | SnackBarTypeOptions.INFO;
	}) {
		EvtMgr.getInstance(EventConst.showToast).notifyListeners(props);
	}

	/**
	 * This method gets the phone number and country code and returns
	 * the string where only country code and last 3 digits of screen will
	 * be visible - others will be replaced with '*'.
	 * e.g: '+852 9446 5249' will displayed like '+852 **** *249'
	 *
	 * @param {string} phoneNumber - Phone number which needs to be masked
	 * @param {string} countryCode - Country code which will be prefixed and required
	 * 	for formatting
	 * */
	static maskPhoneNumber(phoneNumber: string | undefined, countryCode: string) {
		if (phoneNumber && cu.isSet(phoneNumber)) {
			let phoneWithoutCountry = phoneNumber.replace(`+${countryCode}`, "");
			phoneWithoutCountry = phoneWithoutCountry
				.substring(0, phoneWithoutCountry.length - 3)
				.replace(/\S/gi, "*") + phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length);
			return `+${countryCode} ${phoneWithoutCountry}`;
		}
		return "";
	}

	static handleStreamName(streamName: string) {
		if (streamName === Consts.main_wall_credo_stream_id) {
			return strings("AppUtils.credo_wall");
		}
		if (streamName === Consts.main_wall_ego_stream_id) {
			return strings("AppUtils.ego_wall");
		}
		return streamName;
	}

	static parseNewPostStreamData(userStreamData: any) {
		if (userStreamData) {
			if (typeof (userStreamData) === "string") {
				return JSON.parse(userStreamData);
			}
			return userStreamData;
		}
		return [];
	}

	/**
	 * Will convert the text which will be compatible for
	 * displaying the text in the preview.
	 * For instance # are not being displayed in the preview,
	 * and we need to encode them.
	 * Another thing we need to convert the mentions in
	 * normal text since they are exposing details of user.
	 * */
	static parseTextForShortLink = (value: string) => {
		if (cu.isSet(value)) {
			return encodeURIComponent(value.replaceAll(RegEx.userTag, "@$1"));
		}
		return "";
	};

	static createShortURLLink = async (data: any) => {
		const { href } = window.location;
		const linkUrl = data?.url ?? href;
		const shareItem = data?.shareItem;
		let urlObjName; let paramObj; let socialDescr; let socialImageUrl; let
			socialTitle;
		if (shareItem === "post") {
			const item = data?.item;
			urlObjName = "post";
			paramObj = {
				postUuid: item["p.postUuid"],
				mode: item["p.mode"],
			};
			socialDescr = item["p.postText"] ? item["p.postText"] : item["p.shared_postText"];
			socialImageUrl = item["p.mediaObj_Link_ImgUrl"] ? item["p.mediaObj_Link_ImgUrl"]
				: cu.buildSourceUrlImage(item["p.img_bucketRelativeRefPath0"]);
			// eslint-disable-next-line no-nested-ternary
			socialTitle = item["p.postTitle"] ? item["p.postTitle"] : item["p.mediaObj_Link_Title"] ? item["p.mediaObj_Link_Title"]
				: item["p.postText"] ? item["p.postText"] : item["p.shared_postText"];
		} else if (shareItem === "profile") {
			const profileDetails = data?.profileDetails;
			urlObjName = "profile";
			paramObj = {
				prof_id: profileDetails.prof_id,
				mode: profileDetails.info_mode,
				username: profileDetails.info_mode === AppMode.CREDO ? profileDetails.name : "",
			};
			socialDescr = profileDetails.description;
			socialImageUrl = profileDetails.info_mode === AppMode.EGO ? profileDetails.profileImage : profileDetails.coverImage;
			socialTitle = profileDetails.name;
		} else if (shareItem === "stream") {
			const streamDetails = data?.streamDetails;
			urlObjName = "stream";
			paramObj = {
				streamId: streamDetails.streamId,
				mode: streamDetails.mode,
			};
			socialDescr = streamDetails.description;
			socialImageUrl = streamDetails.profileImage;
			socialTitle = streamDetails.name;
		} else if (shareItem === "comment" || shareItem === "subComment") {
			const postDetails = data?.postItem;
			urlObjName = shareItem;
			paramObj = {
				postUuid: postDetails["p.postUuid"],
				commentUuid: data?.commentUuid,
				subCommentUuid: data?.subCommentUuid,
			};
			socialDescr = data?.commentText;
			socialTitle = postDetails?.["p.postTitle"];
		}
		const link = await FirebaseMgr.createShortURLLink({
			linkUrl,
			urlObjName,
			paramObj,
			socialDescr: this.parseTextForShortLink(socialDescr),
			socialImageUrl,
			socialTitle: encodeURIComponent(socialTitle),
		});
		return link;
	};

	static copyLinkToClipboard = async (data: any) => {
		if (isCredoConnOpenAndNetAvailable()) {
			if (isMobile) {
				AppUtils.createShortURLLink(data)
					.then((response) => {
						if (response) {
							setTimeout(() => {
								cu.copyToClipboard(response);
							}, 100);
							AppUtils.showToast({
								message: strings("AppUtils.copied_to_clipboard"),
								type: SnackBarTypeOptions.SUCCESS,
							});
						}
					});
			} else if (isSafari) {
				const getLink = async () => {
					const link = await AppUtils.createShortURLLink(data);
					// eslint-disable-next-line no-return-await
					return await link || "";
				};
				const { ClipboardItem } = window;
				navigator.clipboard.write([new ClipboardItem({ "text/plain": getLink() })])
					.then((response) => {
						if (dbg) {
							LogMgr.mydbg("App Utils: ", "copyLinkToClipboard success response:", response);
						}
						AppUtils.showToast({ message: strings("AppUtils.copied_to_clipboard"), type: SnackBarTypeOptions.SUCCESS });
					})
					.catch((error) => {
						if (dbg) {
							LogMgr.mydbg("App Utils: ", "copyLinkToClipboard error:", error);
						}
					});
			} else {
				const link = await AppUtils.createShortURLLink(data) || "";
				navigator.clipboard.writeText(link)
					.then((response) => {
						AppUtils.showToast({ message: strings("AppUtils.copied_to_clipboard"), type: SnackBarTypeOptions.SUCCESS });
						if (dbg) {
							LogMgr.mydbg("App Utils: ", "copyLinkToClipboard success response:", response);
						}
					})
					.catch((error) => {
						if (dbg) {
							LogMgr.mydbg("App Utils: ", "copyLinkToClipboard error:", error);
						}
					});
			}
		} else {
			AppUtils.showToast({ message: strings("AppUtils.no_internet_msg"), type: SnackBarTypeOptions.ERROR });
		}
	};

	static navigateToUserProfile = (
		profId: string,
		username: string,
		mode: string,
		isCredo: boolean,
		userProfile: UserProfile | null,
		navigate: any,
		egousername: any,
		isBot: YesNoOptions,
		meta: {
			title: string;
		},
	) => {
		if (profId || egousername || username) {
			if ((profId && profId !== userProfile?.eprof_id && profId !== userProfile?.cprof_id)
				|| (egousername && egousername !== userProfile?.egousername)
				|| (username && username !== userProfile?.username)
			) {
				// user navigation with username or profId
				// Removed dependency on profid to navigate to other user profiles
				const path = cu.isSet(username) && mode === AppMode.CREDO ? `${OTHER_USER_PROFILE_PAGE}/${username}`
					: cu.isSet(egousername) && mode === AppMode.EGO ? `${OTHER_USER_PROFILE_PAGE}/${egousername}`
						: isBot === YesNoOptions.YES ? `${OTHER_USER_PROFILE_PAGE}/id=${profId}`
							: !cu.isSet(mode) && username ? `${OTHER_USER_PROFILE_PAGE}/${username}`
								: "";
				if (path) {
					navigate(path, {
						state: {
							meta,
						},
					});
				}
			} else {
				// Logged in  user navigation
				if ((profId === userProfile?.eprof_id) || (egousername === userProfile?.egousername)) {
					// ego mode
					if (isCredo) {
						AppUtils.toggleTheme(ThemeOptions.EGO);
					}
				}
				if ((profId === userProfile?.cprof_id) || (username === userProfile?.username)) {
					// credo mode
					if (!isCredo) {
						AppUtils.toggleTheme(ThemeOptions.CREDO);
					}
				}
				navigate(PROFILE_PAGE, {
					state: {
						meta,
					},
				});
			}
		}
	};

	static navigateToStreamProfile = (
		streamId: string,
		struname: string,
		navigate: any,
		meta: {
			title: string;
		},
	) => {
		if (streamId || struname) {
			const path = cu.isSet(struname)
				? `${STREAM_PROFILE_PAGE}/${struname}`
				: "";
			if (path) {
				navigate(path, {
					state: {
						meta,
					},
				});
			}
		}
	};

	static navigateToDetailsScreen = (
		isShared: boolean,
		item: FeedItem | { "p.postUuid"?: string, "p.shared_postUuid"?: string, commentId?: string },
		navigate: any,
		commentId?: string,
		pCommentId?: string,
	) => {
		if (item && (item["p.postUuid"] || item["p.shared_postUuid"])) {
			const path = isShared
				? `${POST_DETAIL_PAGE}/id=${item["p.shared_postUuid"]}`
				: `${POST_DETAIL_PAGE}/id=${item["p.postUuid"]}`;

			const sharedMeta = {
				// @ts-ignore
				title: item["p.shared_firstName"]
					// @ts-ignore
					? `${item["p.shared_firstName"]} ${item["p.shared_lastName"]} (@${item["p.shared_egousername"]})`
					// @ts-ignore
					: `@${item["p.shared_username"]}`,
			};

			const nonSharedMeta = {
				// @ts-ignore
				title: item["p.firstName"]
					// @ts-ignore
					? `${item["p.firstName"]} ${item["p.lastName"]} (@${item["p.egousername"]})`
					// @ts-ignore
					: `@${item["p.username"]}`,
			};

			const meta = isShared ? sharedMeta : nonSharedMeta;

			if (path && (item as FeedItem)["p.postTitle"]) {
				navigate(path, {
					state: {
						// because shared data comes like shared_postTitle
						item: isShared ? null : item,
						meta,
					},
				});
			} else if (path && cu.isSet(commentId)) {
				const params = pCommentId ? `&commentUuid=${pCommentId}&subCommentUuid=${commentId}` : `&commentUuid=${commentId}`;
				navigate(`${path}${params}`);
			} else {
				navigate(path);
			}
		}
	};

	static navigateToUserOrStream = (data: any) => {
		// To set the parent route in navbar selected while navigating
		if (cu.isSet(data?.parentLink)) {
			EvtMgr.getInstance(EventConst.setRouteParent).notifyListeners(data?.parentLink);
		}

		switch (data.type) {
			case PostHeaderScreenType.User:
				AppUtils.navigateToUserProfile(
					data.profId,
					data.userName,
					data.mode,
					data.isCredoMode,
					data.userProfile,
					data.navigate,
					data.egousername,
					YesNoOptions.NO,
					data.meta,
				);
				break;

			case PostHeaderScreenType.Stream:
				AppUtils.navigateToStreamProfile(
					data.streamId,
					data.struname,
					data.navigate,
					data.meta,
				);
				break;

			case PostHeaderScreenType.Bot:
				AppUtils.navigateToUserProfile(
					data.profId,
					"",
					data.mode,
					data.isCredoMode,
					data.userProfile,
					data.navigate,
					"",
					YesNoOptions.YES,
					data.meta,
				);
				break;

			default:
				break;
		}
	};

	static allowUserToUpdateORDeleteItem(currentMode: boolean, isUserValidToUpdate = YesNoOptions.NO, itemMode: AppMode) {
		return cu.isYes(isUserValidToUpdate) && ((currentMode && itemMode === AppMode.CREDO) || (!currentMode && itemMode === AppMode.EGO));
	}

	static toggleTheme = (value: ThemeOptions) => {
		if (dbg) LogMgr.mydbg(this, "Changing theme to ", value);
		EvtMgr.getInstance(EventConst.toggleTheme).notifyListeners(value);
	};

	static convertToCameleCase = (label: any) => {
		// Todo: check data type of input param
		let convertedLabel = String(label);
		if (convertedLabel !== "") {
			switch (convertedLabel) {
				case "information technology/it":
					convertedLabel = "Information Technology/IT";
					break;
				default:
					convertedLabel = convertedLabel.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
					break;
			}
		}
		return convertedLabel;
	};

	static convertDateInDisplayFormat = (startDate: any, endDate: any, isCurrentdate: any) => {
		const startDateMonth = startDate.substring(0, 2) - 1;
		const startDateYear = startDate.substring(4);
		const endDateMonth = endDate.substring(0, 2) - 1;
		const endDateYear = endDate.substring(4);
		let date = "";
		if (isCurrentdate === YesNoOptions.YES) {
			date = `${moment.monthsShort(startDateMonth)} ${startDateYear} - ${strings("AppUtils.present")}`;
		} else {
			date = `${moment.monthsShort(startDateMonth)} ${startDateYear} - ${moment.monthsShort(endDateMonth)} ${endDateYear}`;
		}
		return date;
	};

	static checkAndUpdateImagesInCache(url: any) {
		if (!url) {
			return null;
		}
		const storage = getStorage();
		getDownloadURL(ref(storage, url))
			.then((data) => {
				if (data) {
					let allCachedImages = cu.getGlobalVar(Consts.allCachedImages);
					if (allCachedImages && allCachedImages[url] && allCachedImages[url] === data) {
						LogMgr.mydbg("Image data already present in cache");
					} else {
						if (!allCachedImages) {
							allCachedImages = {};
						}
						allCachedImages[url] = data;
						cu.setGlobalVar(Consts.allCachedImages, allCachedImages);
						EvtMgr.getInstance(EventConst.saveCachedImages).notifyListeners(allCachedImages);
					}
				}
			})
			.catch((error) => {
				LogMgr.mydbg("getting error while downloading image data from firebase", error);
			});
		return null;
	}

	static createAppDownloadUrl = async () => {
		const url = await FirebaseMgr.createShortURLLink({
			urlObjName: "",
			params: "",
			ofl: CfgMgr.APP_DOMAIN,
		});
		return url;
	};

	static isCrtUserProfId(profId: string, userInfo: UserProfile | null) {
		return profId === userInfo?.cprof_id || profId === userInfo?.eprof_id;
	}

	static handleConfirmationModal(item: ConfirmationModalProps) {
		EvtMgr.getInstance(EventConst.showConfirmationModal).notifyListeners(item);
	}

	/**
	 * if null value or not set or not defined return default value
	 *
	 * @param {any} val - any value
	 * @param {string} defaultVal - default value if val is null
	 *
	 * */
	static nvl = (val: any, defaultVal = "") => (!isNil(val) ? val : defaultVal);

	static toggleYesOrNoOption(option: YesNoOptions) {
		return option === YesNoOptions.YES ? YesNoOptions.NO : YesNoOptions.YES;
	}

	static msgHasItems(msg: any) {
		if (msg && msg.items && msg.items.length > 0) { return true; }
		return false;
	}

	static getUrlParameter(name: string, url: string) {
		let link = url;
		if (!link) {
			link = window?.location.href;
		}
		const tempName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regexS = `[\\?&$/]${tempName}=([^&#?$]*)`;
		const regex = new RegExp(regexS);
		const results = regex.exec(link);
		return results == null ? null : results[1];
	}

	static getUserTagsFromText(text: string) {
		let newUserTags = "";
		try {
			if (cu.isSet(text)) {
				const matches = Array.from(text.matchAll(RegEx.userTagUsernameOnly), (m) => m);
				matches.forEach((tag: any, index) => {
					newUserTags += `${tag[1]?.split(",")[0]}${index !== matches.length - 1 ? "," : ""}`;
				});
			}
		} catch (err) {
			LogMgr.mydbg(this, "error while getting user tags: ", err);
		}
		return newUserTags;
	}

	static getHashTagsFromText(text: string) {
		let newHashTags = "";
		try {
			if (cu.isSet(text)) {
				const matches = Array.from(text.matchAll(RegEx.hashTag), (m) => m);
				matches.forEach((tag: any) => {
					const hashTag = tag[0]?.replace("#", "");
					newHashTags += `${hashTag?.trim()},`;
				});
			}
		} catch (err) {
			LogMgr.mydbg(this, "error while getting hashtags: ", err);
		}
		return newHashTags;
	}

	static getUpdatedUserTagData = (oldTagData: string, newTagData: string) => {
		let removedTagData = !cu.isSet(newTagData) ? oldTagData : "";
		let updatedTagData = newTagData;
		try {
			if (cu.isSet(oldTagData) && cu.isSet(newTagData)) {
				let oldDataArr = oldTagData.split(",");
				let newDataArr = newTagData.split(",");
				const tempOldDataArr = oldDataArr;

				// removed tags while editing a post
				oldDataArr = oldDataArr.filter((item: any) => newDataArr.indexOf(item) === -1);
				removedTagData = oldDataArr.toString();

				// removing already tagged users while editing a post i.e keeping only newly tagged users
				newDataArr = newDataArr.filter((item: any) => tempOldDataArr.indexOf(item) === -1);
				updatedTagData = newDataArr.toString();
			}
		} catch (err) {
			LogMgr.mydbg(this, "error while getting updated tags: ", err);
		}
		return {
			removedTagData,
			updatedTagData,
		};
	};

	static sleep(duration: number) {
		return new Promise((resolve) => setTimeout(resolve, duration));
	}

	static getHomeTabParam(value: number) {
		if (SessMgr.isSessionAuth()) {
			switch (value) {
				case 0:
					return FeedType.FEED;
				case 1:
					return FeedType.NEWS;
				case 2:
					return FeedType.BLASTS;
				case 3:
					return FeedType.TOP;
				default: return FeedType.FEED;
			}
		} else {
			switch (value) {
				case 0:
					return FeedType.FEED;
				case 1:
					return FeedType.BLASTS;
				case 2:
					return FeedType.TOP;
				default: return FeedType.FEED;
			}
		}
	}

	static getExploreTabParam(value: number) {
		switch (value) {
			case 0:
				return EngageTabOptions.COMMUNITIES;
			case 1:
				return EngageTabOptions.EXPLORE;
			case 2:
				return EngageTabOptions.DASHBOARD;
			case 3:
				return EngageTabOptions.BOTS;
			case 4:
				return EngageTabOptions.PEOPLE;
			default:
				return EngageTabOptions.COMMUNITIES;
		}
	}

	static getLastTs(data: any[], fieldName: string): number {
		const lastItem = last(data);
		return lastItem && cu.isSet(lastItem[fieldName])
			? lastItem[fieldName] : 0;
	}

	static showCredoGraph() {
		EvtMgr.getInstance(EventConst.showCredoGraph).notifyListeners({ show: true });
	}

	static showRatingTagTutorial() {
		EvtMgr.getInstance(EventConst.ratingTutorialModal).notifyListeners({ showModal: true });
	}

	static removeRatingFromCredoPostAuthorTags(tags: PostTag[], mode: string) {
		// setting rating and relevance to -1 for post made by credo profile
		if (cu.isSet(tags) && tags.length > 0) {
			let updatedAuthorTags = [...tags];
			if (mode === AppMode.CREDO && updatedAuthorTags?.length > 0) {
				updatedAuthorTags = tags.map((tag: PostTag) => ({
					...tag,
					b_credo_score: -1,
					relevance: -1,
				}));
			}
			return updatedAuthorTags;
		}
		return [];
	}

	static updateTagRating(tags: PostTag[], tag: string, rating: number) {
		const updatedPostTags = tags.map((singleTag: PostTag) => {
			if (tag === singleTag.tag) {
				return {
					...singleTag,
					b_credo_score: rating,
				};
			}
			return singleTag;
		});
		return updatedPostTags;
	}

	/**
	 * Execute the action if the user is authenticated.
	 * If not then show login modal.
	 *
	 * @param {any} action Can be any executable function
	 * which needs to executed only if user is authenticated
	 * */
	static authenticatedAction(action: any) {
		if (!SessMgr.isSessionAuth()) {
			AppUtils.handleLoginModal(true);
		} else if (action) {
			action();
		}
	}

	static createNewProposedTags(newPropsedTags: Tag[]) {
		if (newPropsedTags?.length > 0) {
			newPropsedTags.forEach((item: Tag) => {
				// creating propose tag on server
				MiscActions.proposeTag({
					user_id: SessMgr.getFromSession(Consts.user_id),
					action: ProposeTagActions.CREATE,
					proposed_tag: item.tag,
				});
			});
		}
	}

	static toggleVoltsDashboardModal(message: { show: boolean }) {
		EvtMgr
			.getInstance(EventConst.showVoltsDashboardModal)
			.notifyListeners(message);
	}

	static compareObjects(x: any, y: any): boolean {
		if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
		if (x.constructor !== y.constructor) { return false; }
		if (x instanceof Function) { return x === y; }
		if (x instanceof RegExp) { return x === y; }
		if (x === y || x.valueOf() === y.valueOf()) { return true; }
		if (Array.isArray(x) && x.length !== y.length) { return false; }
		if (x instanceof Date) { return false; }
		if (!(x instanceof Object)) { return false; }
		if (!(y instanceof Object)) { return false; }
		const p = Object.keys(x);
		return Object.keys(y).every((i) => p.indexOf(i) !== -1)
			&& p.every((i) => AppUtils.compareObjects(x[i], y[i]));
	}
}

export default AppUtils;
