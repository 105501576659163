import {
	AppMode, cu, LogMgr, PostTag, useWindowSize,
} from "@credo/utilities";
import React, { useMemo, useState } from "react";
import moment from "moment/moment";
import { isCredoModeAtom } from "@credo/store";
import { useAtomValue } from "jotai";
import {
	CIStyler, classNames, Consts, DEFAULT_TAG_MESSAGES,
} from "../../common";
import { DefaultStreamNames, PostHeaderConst, PostHeaderScreenType } from "../types";
import { ProfilePicture } from "../../profile/profile-picture";
import { AuthorTags } from "../author-tags/AuthorTags";
import { AuthorTagsModal } from "../author-tags/AuthorTagsModal";
import { PostTagsProps } from "../PostTags";
import { ProfileSize } from "../../profile/types";
import styles from "../ReadMoreText.module.css";

export interface PostHeaderItem {
	"p.shared_prof_id": string;
	"p.shared_username": string;
	"p.shared_firstName": string;
	"p.shared_lastName": string;
	"p.shared_egousername": string;
	"p.src_s_struname": string;
	"p.shared_mode": string;
	"p.shared_post_streamId": string;
	"p.src_s_name": string;
	"p.shared_profilePicRelUrl": string;
	"p.shared_creat_ts": string;
	"p.shared_edit_ts": string;
	"p.prof_id": string;
	"p.username": string;
	"p.firstName": string;
	"p.lastName": string;
	"p.egousername": string;
	"s.struname": string;
	"p.mode": string;
	"s.streamId": string;
	"s.name": string;
	"p.profilePicRelUrl": string;
	"p.edit_ts": number;
	"p.creat_ts": number;
	"p.shared_created_by": PostHeaderScreenType;
	"p.created_by": PostHeaderScreenType;
	postFlare: string;
	"p.postUuid": string;
	authorTags: PostTag[];
}

export interface PostHeaderProps {
	item: PostHeaderItem,
	hideStreamName?: boolean,
	isShared?: boolean,
	navigateToDetailsScreen: (isShared: boolean) => void;
	navigateToUserOrStream: (data: any) => void,
	showHandIcon?: boolean;
	postTagProps?: PostTagsProps;
	/**
	 * set true if the component is being called inside
	 * PostCompactView or need a compact version of this
	 * component
	 *
	 * @default false
	 * */
	isCompactView?: boolean;
}

// TODO: Add handling for keeping the normal font on author tag modal

export const PostHeader = (props: PostHeaderProps) => {
	const {
		item,
		hideStreamName,
		isShared = false,
		navigateToDetailsScreen,
		navigateToUserOrStream,
		showHandIcon,
		postTagProps,
		isCompactView = false,
	} = props;
	const [isAuthorTagsModalOpen, setIsAuthorTagsModalOpen] = useState(false);
	const { width } = useWindowSize();

	const state = useMemo(() => {
		let headerState: any = {};

		if (!item) {
			return {};
		}

		if (isShared) {
			headerState = {
				profId: item["p.shared_prof_id"],
				userName: item["p.shared_username"],
				firstName: item["p.shared_firstName"],
				lastName: item["p.shared_lastName"],
				egousername: item["p.shared_egousername"],
				struname: item["p.src_s_struname"],
				mode: item["p.shared_mode"],
				streamId: item["p.shared_post_streamId"],
				streamName: item["p.src_s_name"],
				profilePicUrl: item["p.shared_profilePicRelUrl"],
				creatTs: item["p.shared_creat_ts"],
				editTs: item["p.shared_edit_ts"],
				postedDateDiff: item["p.shared_creat_ts"],
			};
		} else {
			headerState = {
				profId: item["p.prof_id"],
				userName: item["p.username"],
				firstName: item["p.firstName"],
				lastName: item["p.lastName"],
				egousername: item["p.egousername"],
				struname: item["s.struname"],
				mode: item["p.mode"],
				streamId: item["s.streamId"],
				streamName: item["s.name"],
				profilePicUrl: item["p.profilePicRelUrl"],
				creatTs: item["p.creat_ts"],
				editTs: item["p.edit_ts"],
				postedDateDiff: item["p.creat_ts"],
			};
		}

		if (headerState.streamName === Consts.umas_stream_name && headerState?.streamId) {
			headerState = {
				...headerState,
				streamName: Consts.blast_stream_name,
				streamId: Consts.blast_stream_id,
				struname: Consts.blast_stream_username,
			};
		}

		return headerState;
	}, [item, isShared]);

	const {
		profId = "test_prof_id",
		userName,
		firstName,
		lastName,
		mode,
		streamId,
		streamName,
		profilePicUrl,
		creatTs,
		editTs,
		postedDateDiff,
		egousername,
		struname,
	} = state;

	const isAuthorTagsPresent = useMemo(() => (item?.authorTags?.length ?? 0) > 0, [item]);
	const isCredoMode = useAtomValue(isCredoModeAtom);

	const metaTitle = useMemo(() => {
		if (mode === AppMode.CREDO && userName) {
			return `@${userName}`;
		}

		if (egousername) {
			return `${firstName} ${lastName} (@${egousername})`;
		}

		return `${firstName} ${lastName}`;
	}, [state, item]);

	const closeAuthorTagsModal = () => {
		setIsAuthorTagsModalOpen(false);
	};

	const openAuthorTagsModal = () => {
		try {
			if (isShared || !isAuthorTagsPresent) {
				if (navigateToDetailsScreen) navigateToDetailsScreen(isShared);
			} else {
				setIsAuthorTagsModalOpen(true);
			}
		} catch (err) {
			LogMgr.log(`Error in openAuthorTagsModal: ${err}`);
		}
	};

	const redirectToUserProfile = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (isAuthorTagsModalOpen || isShared || !isAuthorTagsPresent) {
			closeAuthorTagsModal();
			if (navigateToUserOrStream) {
				if (item) {
					navigateToUserOrStream({
						type: item[isShared ? "p.shared_created_by" : "p.created_by"] === PostHeaderScreenType.Bot
							? PostHeaderScreenType.Bot : PostHeaderScreenType.User,
						mode,
						profId,
						userName,
						egousername,
						meta: {
							title: metaTitle,
						},
					});
				}
			}
		} else {
			openAuthorTagsModal();
		}
	};

	const renderNameOfPosterBasedOnMode = () => {
		if (mode === AppMode.CREDO) {
			return (
				<span onClick={redirectToUserProfile} className="whitespace-nowrap" aria-hidden>
					{/* [Credo] */}
					{userName}
				</span>
			);
		}
		return (
			<span onClick={redirectToUserProfile} className="whitespace-nowrap" aria-hidden>
				{/* [Ego] */}
				{firstName}
				{" "}
				{lastName}
			</span>
		);
	};

	const redirectToStreamProfile = () => {
		if (isAuthorTagsModalOpen || isShared || !isAuthorTagsPresent) {
			closeAuthorTagsModal();
			if (navigateToUserOrStream) {
				navigateToUserOrStream({
					type: PostHeaderScreenType.Stream,
					streamId,
					mode,
					userName,
					struname,
					meta: {
						title: streamName,
					},
				});
			}
		} else {
			openAuthorTagsModal();
		}
	};

	const onStreamNameClick = (event: any) => {
		event.stopPropagation();
		redirectToStreamProfile();
	};

	const redirectUserToStreamOrUserProfile = (forModal: boolean = false) => {
		if (streamName) {
			return (
				<p
					className={classNames(
						isCompactView && !forModal ? "text-xs mobile-compact:text-base" : "text-base",
					)}
				>
					<span
						onClick={redirectToUserProfile}
						className={classNames(
							"text-primary font-medium cursor-pointer max-w-full",
							isCompactView && !forModal ? classNames(
								"max-w-full",
								styles.baseClamp,
								styles.readMoreLineClamp1,
							) : "",
						)}
						title={mode === AppMode.CREDO ? userName : `${firstName} ${lastName}`}
						aria-hidden
					>
						{mode === AppMode.CREDO ? userName : `${firstName} ${lastName}`}
					</span>
					{streamId
						&& streamId.indexOf(DefaultStreamNames.MainWallCredoStreamId) === -1
						&& streamId.indexOf(DefaultStreamNames.MainWallEgoStreamId) === -1
						&& !hideStreamName
						&& !isCompactView
						? (
							<span>
								<span className="text-basic">
									{" "}
									{PostHeaderConst.In}
									{" "}
								</span>
								<span
									onClick={onStreamNameClick}
									className="text-primary font-medium cursor-pointer"
									aria-hidden
								>
									{streamName}
								</span>
							</span>
						)
						: null}
				</p>
			);
		}
		return (
			<span className="text-sm text-primary font-medium">
				{renderNameOfPosterBasedOnMode()}
			</span>
		);
	};

	const calculateOpacityForTime = (fromTime: string | null) => {
		if (!fromTime) {
			return 0;
		}

		const postedDate = moment(fromTime).toDate();
		const currentDate = moment().toDate();
		const thresholdTime = 60 * 4;
		if (!Number.isNaN(postedDate.getTime())) {
			const postedDateDiff = Math.abs(currentDate.getTime() - postedDate.getTime()) / 1000;
			if ((postedDateDiff / 60) < thresholdTime) {
				return 1 - (postedDateDiff / 60) / thresholdTime;
			}
		}
		return 0;
	};

	return (
		<div
			className="relative"
			data-testid="post-header"
		>
			<div
				onClick={openAuthorTagsModal}
				aria-hidden
				className={classNames(
					"w-full h-full",
					isShared || showHandIcon ? "cursor-pointer" : "",
					isShared ? "bg-content-L1 py-4" : "bg-background p-4",
					"pb-1",
					isCompactView && !isShared ? "!pt-0 !p-2 mobile-compact:!px-4 mobile-compact:!py-0" : "",
					isCompactView && isShared ? "!pt-2 pb-1 px-0" : "",
				)}
			>
				<div className="flex flex-row w-full">
					<div className="flex flex-col">
						<ProfilePicture
							profilePicUrl={cu.buildSourceUrlImage(profilePicUrl) || ""}
							profId={profId}
							onProfilePicPress={redirectToUserProfile}
							profilePicWrapperStyle={classNames(
								isCompactView ? "w-8 mobile-compact:w-12" : "w-12",
								isCompactView && isShared ? "!w-8" : "",
							)}
							size={(
								(isCompactView && width <= 540) || (isCompactView && isShared)
							) ? ProfileSize.X_SMALL : ProfileSize.SMALL}
						/>
					</div>
					<div
						className="flex flex-row justify-between w-full"
					>
						<div className="flex flex-col justify-center flex-1">
							{redirectUserToStreamOrUserProfile()}
							{!isShared
								&& (
									<AuthorTags postData={item} messages={{ tagMessages: postTagProps?.messages ?? DEFAULT_TAG_MESSAGES }} />
								)}
						</div>
						<div
							className={classNames(
								"flex items-center justify-end max-w-[80px] flex-2",
								isCompactView ? "h-4 mobile-compact:h-5" : "h-5 mr-2.5",
							)}
						>
							<p
								className={classNames(
									isCompactView
										? "text-5xs mobile-compact:text-sm rounded-[2px] mobile-compact:rounded pt-px"
										: "text-sm mt-0.5 rounded",
									calculateOpacityForTime(postedDateDiff) > 0 ? "text-dark-timestamp" : "text-gray-dark",
									calculateOpacityForTime(postedDateDiff) > 0
										&& calculateOpacityForTime(postedDateDiff) <= 0.5
										&& isCredoMode
										? "!text-off-white" : "",
									"px-1",
								)}
								style={{
									backgroundColor: CIStyler.engagementColor(1, calculateOpacityForTime(postedDateDiff)),
								}}
							>
								{cu.calculatePostTime(postedDateDiff ?? "", false, true)}
								{editTs && editTs !== creatTs
									? ` ${PostHeaderConst.E}` : null}
							</p>
						</div>
					</div>
				</div>
			</div>
			{isAuthorTagsModalOpen
				&& (
					<AuthorTagsModal
						postData={item}
						tagData={item?.authorTags}
						closeAuthorTagsModal={closeAuthorTagsModal}
						profilePicComp={(
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(profilePicUrl) || ""}
								profId={profId}
								onProfilePicPress={redirectToUserProfile}
								profilePicWrapperStyle="w-12"
							/>
						)}
						nameComp={redirectUserToStreamOrUserProfile(true)}
						postTagProps={postTagProps}
					/>
				)}
		</div>
	);
};

PostHeader.defaultProps = {
	hideStreamName: false,
	showHandIcon: true,
	isShared: false,
	postTagProps: null,
	isCompactView: false,
};
