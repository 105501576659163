import {
	cu, dbg, LogMgr, SessMgr,
} from "@credo/utilities";
import moment from "moment/moment";
import { v5 as uuidv5, v4 as uuidv4 } from "uuid";
import { WSEvtMgr as EvtMgr } from "../utils/WSEvtMgr";
import { Consts } from "../utils";

interface DynamicKey {
	[key: string]: any
}

interface MyWSConstOptions {
	reconnect_attempts_max?: any,
	reconnect_delay_min: number,
	reconnect_delay_max: number,
	reconnect_exponent: number,
	randomization_factor: number,
	hostAndPortA: string[] | string,
	encryption: string
	ping_interval?: number,
}

interface MessageEnvelope {
	sid: string,
	type: string, // not really used
	msgtype: string, // cm: added for credo
	addr: string, // aka a topic in vertx but not really used for credo
	sndts: string,
	hdrs: any,
	body: DynamicKey,
	replyAddress?: any
}

/* this comes from the vertx event bus bridge which uses an address to send th msgs to.
we can hve even a verticle respond to msgs sent to this address. but we dont use that now, we send to vertx which then sends to kafka */
let wsMsgCnt = 0;

function buildMsgSendTs() {
	return `${moment().format("x")}-${wsMsgCnt++}`;
}

function mergeHeaders(defaultHeaders: any, headers: any) {
	if (defaultHeaders) {
		if (!headers) {
			return defaultHeaders;
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const headerName in defaultHeaders) {
			// eslint-disable-next-line no-prototype-builtins
			if (defaultHeaders.hasOwnProperty(headerName)) {
				// user can overwrite the default headers
				if (typeof headers[headerName] === "undefined") {
					// eslint-disable-next-line no-param-reassign
					headers[headerName] = defaultHeaders[headerName];
				}
			}
		}
	}

	// headers are required to be a object
	return headers || {};
}

// eslint-disable-next-line import/prefer-default-export
export class WebSocketConn {
	CONNECTING = 0;

	OPEN = 1;

	CLOSING = 2;

	CLOSED = 3;

	hostAndPortA: string[] | string = [];

	encryption: MyWSConstOptions["encryption"] = "ssl";

	pingInterval: number;

	pingTimerID: any;

	reconnectEnabled: boolean;

	reconnectAttempts: number;

	reconnectTimerID: any;

	maxReconnectAttempts: number;

	reconnectDelayMin: number;

	reconnectDelayMax: number;

	reconnectExponent: number;

	randomizationFactor: number;

	defaultHeaders: any;

	onerror: any;

	handlers: any;

	replyHandlers: any;

	wsConn: WebSocket | undefined | null;

	state: number | undefined;

	onopen: Function | undefined;

	onreconnect: Function | undefined;

	onclose: Function | undefined;

	constructor(options: MyWSConstOptions) {
		// @ts-ignore (options cannot be string)
		if (dbg) LogMgr.mydbg(this, `ws options=${cu.jsonify(options)}`);

		// eslint-disable-next-line no-param-reassign
		options = options || {};

		this.hostAndPortA = options.hostAndPortA || [];
		this.encryption = options.encryption || "ssl"; // 'ssl' or 'nossl'

		// attributes
		this.pingInterval = options.ping_interval || 30000;
		this.pingTimerID = null;

		this.reconnectEnabled = false;
		this.reconnectAttempts = 0;
		this.reconnectTimerID = null;
		// adapted from backo
		this.maxReconnectAttempts = options.reconnect_attempts_max || Infinity;
		this.reconnectDelayMin = options.reconnect_delay_min || 100;
		this.reconnectDelayMax = options.reconnect_delay_max || 31000;
		this.reconnectExponent = options.reconnect_exponent || 2;
		this.randomizationFactor = options.randomization_factor || 0.5;

		this.defaultHeaders = null;
		// default event handlers
		this.onerror = (err: any) => {
			try {
				LogMgr.myerr(this, err);
			} catch (e) {
				// dev tools are disabled so we cannot use console on IE
			}
		};

		// handlers and reply handlers are tied to the state of the socket they are
		// added onopen or when sending, so reset when reconnecting
		this.handlers = {};
		this.replyHandlers = {};

		this.setupWebsocketConnection();
	}

	getReconnectDelay = () => {
		let ms = this.reconnectDelayMin * (this.reconnectExponent ** this.reconnectAttempts);
		if (this.randomizationFactor) {
			const rand = Math.random();
			const deviation = Math.floor(rand * this.randomizationFactor * ms);
			ms = (Math.floor(rand * 10) & 1) === 0
				? ms - deviation
				: ms + deviation;
		}
		if (dbg) {
			LogMgr.mydbg(this, `ms=${ms} getReconnectDelay: this.reconnectDelayMin=${this.reconnectDelayMin} `
				+ `this.reconnectExponent=${this.reconnectExponent} this.reconnectAttempts=${this.reconnectAttempts} `
				+ `this.reconnectDelayMax=${this.reconnectDelayMax}`);
		}

		return Math.min(ms, this.reconnectDelayMax) | 0;
	};

	setupWebsocketConnection = () => {
		// if(dbg)LogMgr.mydbg(this,'list of hosts: ' + CU.jsonify(this.hostAndPortA) + ' reconAttempts=' + this.reconnectAttempts);
		// this.wsConn = new SockJS(url, null, options);
		const wsProtocol = this.encryption === "ssl" ? "wss" : "ws";
		const hostAndPort = this.hostAndPortA[this.reconnectAttempts % this.hostAndPortA.length];
		// console.log("hostt and port", JSON.parse(JSON.parse(JSON.stringify(this.hostAndPortA))));
		const url = `${wsProtocol}://${hostAndPort}/websocket/`;
		if (dbg) LogMgr.mydbg(this, `trying connect to ${url}`);
		this.wsConn = new WebSocket(url);
		if (dbg) LogMgr.mydbg(this, "created websocket");

		this.wsConn.binaryType = "arraybuffer";

		this.state = this.CONNECTING;

		this.wsConn.onopen = () => {
			this.enablePing(true);
			this.state = this.OPEN;
			// eslint-disable-next-line no-unused-expressions
			this.onopen && this.onopen();
			if (this.reconnectTimerID) {
				this.reconnectAttempts = 0;
				// fire separate event for reconnects consistent behavior with adding handlers
				// onopen
				// eslint-disable-next-line no-unused-expressions
				this.onreconnect && this.onreconnect();
			}
			EvtMgr.getInstance("tmp.ws.conn").notifyListeners("open");
		};

		this.wsConn.onclose = (e) => {
			this.state = this.CLOSED;
			if (this.pingTimerID) clearInterval(this.pingTimerID);
			if (this.reconnectEnabled && this.reconnectAttempts < this.maxReconnectAttempts) {
				this.wsConn = null;
				// set id so users can cancel
				const reconDelay = this.getReconnectDelay();
				if (dbg) LogMgr.mydbg(this, "recon delay=", reconDelay);
				this.reconnectTimerID = setTimeout(this.setupWebsocketConnection, reconDelay);
				++this.reconnectAttempts;
			}
			// eslint-disable-next-line no-unused-expressions
			this.onclose && this.onclose(e);
			EvtMgr.getInstance("tmp.ws.conn").notifyListeners("close");
		};

		this.wsConn.onerror = () => EvtMgr.getInstance("tmp.ws.conn").notifyListeners("error");

		this.wsConn.onmessage = (msg) => {
			const json = JSON.parse(msg.data);

			if (!json) { return; }

			/**
			 * cm: we want to allow defining both the existing style of handler and per msg arrow
			 * like callback. Both can be defined and will be called:
			 * */
			let hasHandlers = false;
			let replyHandlers = false;

			if (this.handlers[json.addr]) { // cm: json.addr : this is the topic from vertx eb
				if (dbg) LogMgr.mydbg(this, "we have a handler");
				hasHandlers = true;

				// iterate all registered handlers
				const handlers = this.handlers[json.addr]; // can be several handlers
				for (let i = 0; i < handlers.length; i++) {
					if (json.type === "err") {
						handlers[i]({ failureCode: json.failureCode, failureType: json.failureType, message: json.message });
					} else {
						handlers[i](null, json); // call all handlers of this topic/addr
					}
				}
			}

			if (this.replyHandlers[json.replyAddress]) {
				if (dbg) LogMgr.mydbg(this, "we have a reply handler");
				replyHandlers = true;
				// Might be a reply message
				const handler = this.replyHandlers[json.replyAddress];
				delete this.replyHandlers[json.replyAddress];
				if (json.type === "err") {
					handler({ failureCode: json.failureCode, failureType: json.failureType, message: json.message });
				} else {
					handler(null, json);
				}
			}

			if (!hasHandlers && !replyHandlers) {
				if (json.type === "err") {
					this.onerror(json);
				} else {
					try {
						this.handlers.default[0](null, json); // call the 'default' registered handler if no specific one registered
					} catch (exc) {
						LogMgr.printException(this, "onmsg", exc);
					}
				}
			} // if msg has no addr ( ie topic ) then use the default handler
		};
	};

	send = (
		address: string,
		msgtype: string,
		message: {
			[key: string]: any
		},
		headers: any,
		callback: Function | undefined,
	) => {
		// are we ready?
		if (this.state !== this.OPEN) {
			throw new Error("INVALID_STATE_ERR at send");
		}

		if (typeof headers === "function") {
			if (dbg) LogMgr.mydbg(this, "headers is a function so use it as callback");
			// eslint-disable-next-line no-param-reassign
			callback = headers;
			// eslint-disable-next-line no-param-reassign
			headers = {};
		}

		const envelope: MessageEnvelope = {
			sid: SessMgr.getFromSession(Consts.sid),
			type: "send", // not really used
			msgtype, // cm: added for credo
			addr: address, // aka a topic in vertx but not really used for credo
			sndts: buildMsgSendTs(),
			hdrs: mergeHeaders(this.defaultHeaders, headers),
			body: message,
		};

		if (callback) {
			if (dbg) LogMgr.mydbg(this, "we have a per msg defined callback");
			const replyAddress = uuidv5(uuidv4(), uuidv5.URL); // Added unique request id for each request
			envelope.replyAddress = replyAddress; // aka correlation id
			this.replyHandlers[replyAddress] = callback;
		}

		const msg = JSON.stringify(envelope);
		const msgSize = msg ? msg.length : 0;
		if (dbg) LogMgr.mydbg(this, "size=", msgSize, "sending msg: ", msg);
		this.wsConn?.send(msg);
		if (dbg) LogMgr.mydbg(this, "sent msg of size", msgSize);
	};

	publish = (address: string, msgtype: string, message: DynamicKey, headers: Function | undefined) => {
		// are we ready?
		if (this.state !== this.OPEN) {
			throw new Error("INVALID_STATE_ERR at publish");
		}

		this
			.wsConn
			?.send(JSON.stringify({
				sid: SessMgr.getFromSession(Consts.sid),
				type: "publish",
				msgtype,
				addr: address,
				sndts: buildMsgSendTs(),
				hdrs: mergeHeaders(this.defaultHeaders, headers),
				body: message,
			}));
	};

	registerHandler = (address: string, headers: any, callback: Function | undefined) => {
		if (typeof headers === "function") {
			// eslint-disable-next-line no-param-reassign
			callback = headers;
			// eslint-disable-next-line no-param-reassign
			headers = {};
		}

		// ensure it is an array
		if (!this.handlers[address]) {
			this.handlers[address] = [];
		}

		this.handlers[address].push(callback);
	};

	unregisterHandler = (address: string, headers: any, callback: Function | undefined) => {
		// are we ready?
		if (this.state !== this.OPEN) {
			throw new Error("INVALID_STATE_ERR at unregister");
		}

		const handlers = this.handlers[address];

		if (handlers) {
			if (typeof headers === "function") {
				// eslint-disable-next-line no-param-reassign
				callback = headers;
				// eslint-disable-next-line no-param-reassign
				headers = {};
			}

			const idx = handlers.indexOf(callback);
			if (idx !== -1) {
				handlers.splice(idx, 1);
				if (handlers.length === 0) {
					// No more local handlers so we should unregister the connection
					this
						.wsConn
						?.send(JSON.stringify({
							sid: SessMgr.getFromSession(Consts.sid),
							type: "unregister",
							msgtype: "unregister",
							addr: address,
							sndts: buildMsgSendTs(),
							hdrs: mergeHeaders(this.defaultHeaders, headers),
						}));

					delete this.handlers[address];
				}
			}
		}
	};

	/**
	 * Closes the connection to the EventBus Bridge,
	 * preventing any reconnect attempts
	 */
	close = () => {
		this.state = this.CLOSING;
		this.enableReconnect(false);
		this
			.wsConn
			?.close();
	};

	enablePing = (enable: boolean) => {
		if (enable) {
			const sendPing = () => {
				this
					.wsConn
					?.send(JSON.stringify({ type: "ping", msgtype: "ping" }));
			};

			if (this.pingInterval > 0) {
				// Send the first ping then send a ping every pingInterval milliseconds
				sendPing();
				this.pingTimerID = setInterval(sendPing, this.pingInterval);
			}
		} else if (this.pingTimerID) {
			clearInterval(this.pingTimerID);
			this.pingTimerID = null;
		}
	};

	enableReconnect = (enable: boolean) => {
		this.reconnectEnabled = enable;
		if (!enable && this.reconnectTimerID) {
			clearTimeout(this.reconnectTimerID);
			this.reconnectTimerID = null;
			this.reconnectAttempts = 0;
		}
	};

	isOpen = () => this.state === this.OPEN;

	checkAndRegisterHandler = (address: string, headers: any, callback: Function | undefined | null) => {
		if (!this.handlers[address]) {
			this.handlers[address] = [];
			this.handlers[address].push(callback); // we dont need to send register msg to server
		}
	};

	removeListener = (address: string) => {
		if (this.handlers[address]) {
			delete this.handlers[address];
			if (dbg) LogMgr.mydbg(this, `removed handler for addr: ${address}`);
		}
	};
}
