import * as React from "react";
import { ReactNode, useState } from "react";
import {
	cu, useEvtMgrListener, NoRateTooltipFor, TagIconType,
} from "@credo/utilities";
import { Logger } from "../common/Logger";
import {
	Consts, EventConst, ScalerSize,
} from "../common";
import TagIndicator from "./indicator-html";
import { Spinner } from "../spinner";
import { CredoSwitch, CredoSwitchSize } from "../credo-switch";
import { BoostTrayProps } from "../boost";

export const TagIndicatorImpl = TagIndicator;

export interface TagMessages {
	rate_this_item: string;
	rating_info: string;
	view_tutorial: string;
	creed_high: string;
	creed_med: string;
	creed_low: string;
	boost_this_post: string;
	boost_info: string;
}

export type CredoTagScoreData = {
	subjScore: number,
	objScore: number,
	engagement: number,
	relevance: number,
	subjectivity: number,
	hasCampaign: boolean,
	campaignIcon?: (props: any) => React.ReactNode,
	ownRating: number | null,
	ownInterest: boolean,
	icon?: TagIconType,
};

export interface EgoInfoProps {
	// switch comp to show when tag is being rated in ego mode
	switchComp: ReactNode;
	// opne credo tag tutorial modal
	onPressTagInfoBubble: () => void;
	// opens the credograph modal
	onPressCredoGraphText: () => void;
}

export const NullCredoTagScoreData: CredoTagScoreData = {
	engagement: -1,
	hasCampaign: false,
	objScore: -1,
	ownInterest: false,
	ownRating: -1,
	relevance: -1,
	subjScore: -1,
	subjectivity: 0.5,

};
export type CredoTagDataConsumer = (data: CredoTagScoreData) => void;

/**
 * Data provider
 */
export type CredoTagDataProvider = {
	get(id: string): Promise<CredoTagScoreData>;

	subscribe(id: string, onUpdate: CredoTagDataConsumer): void;

	unsubscribe(id: string, onUpdate: CredoTagDataConsumer): void;

}

export type CredoTagProps = {
	id: string,
	tag: string,
	size: ScalerSize,
	dataProvider: CredoTagDataProvider,
	onRate: (id: string, rating: number) => void,
	// eslint-disable-next-line react/require-default-props
	isUserOwnPost?: boolean, // TODO remove a drill through
	// eslint-disable-next-line react/require-default-props
	isCredoMode?: boolean, // TODO remove a drill through
	// eslint-disable-next-line react/require-default-props
	tooltip?: (props: any) => React.ReactNode;
	disable?: boolean,
	showLoginModal?: (show: boolean) => void; // TODO review and remove
	egoInfoProps?: EgoInfoProps;
	onRatingDisabled?: () => void;
	boostTrayProps?: BoostTrayProps;
	messages: TagMessages
}
/**
 * 	// TODO recode in the mixin here
 * 	const checkAndrenderIndicator = () => {
 * 		// TODO eliminate the need for all of this, it should happen in credo tag
 * 		if (isUserOwnPost) {
 * 			// post has been created by same user
 * 			return tooltip && tooltip("ownPost");
 * 		} else if (!isCredoMode) {
 * 			// switch mode if user is in ego mode
 * 			return tooltip && tooltip("modeSwitch");
 * 		}
 * 		return null; // renderSVG();
 * 	};
 */

/**
 * Renders the tag control that represents credo rating (and other metadata)
 *
 * @constructor
 */
const loadingIcon: React.ReactNode = <Spinner />;

export const CredoTag = ({
	id,
	tag,
	size = ScalerSize.M,
	dataProvider,
	onRate,
	isUserOwnPost,
	isCredoMode = true,
	tooltip = () => null,
	disable = false, // TODO rename to disabled
	showLoginModal,
	egoInfoProps,
	onRatingDisabled,
	boostTrayProps,
	messages,
}: CredoTagProps) => {
	const [data, setData] = React.useState<CredoTagScoreData>(NullCredoTagScoreData);
	const [loading, setLoading] = React.useState(true);
	const [hasRated, setHasRated] = React.useState(false);
	const [ratingFirstTime, setRatingFirstTime] = React.useState(
		cu.isSet(cu.getGlobalVar(Consts.ratingFirstTime)) && cu.getGlobalVar(Consts.ratingFirstTime),
	);

	const onDataUpdate: CredoTagDataConsumer = (data) => {
		setData(data);
		if (cu.isSet(data.ownRating) && data.ownRating !== -1) {
			setHasRated(true);
		}
	};
	React.useEffect(() => {
		setLoading(true);
		dataProvider.get(id)
			.then(
				(result) => {
					onDataUpdate(result);

					setLoading(false);
				},
				(error) => Logger.error(`Failed to get data for tag id:${id}`, error),
			);

		dataProvider.subscribe(id, onDataUpdate);
	}, [dataProvider]);

	const renderTagIcon = (props: any) => {
		if (loading) {
			return loadingIcon;
		}
		if (data.campaignIcon) {
			return data.campaignIcon(props);
		}
		return null;
	};

	const onTagRate = (tag: string, rating: number) => {
		if (disable) return;
		const newRating = tag?.startsWith("!") ? 1 - rating : rating;
		setHasRated(true);
		setData({ ...data, ownRating: newRating });
		// Logger.debug("tag rated:" + tag + ":" + rating);
		onRate(tag, newRating);
	};

	const [tagAlert, setTagAlert] = useState<ReactNode | null>(null);

	const onRatingBlocked = () => {
		// TODO implement handling of when we can't rate
		if (onRatingDisabled) {
			onRatingDisabled();
		}
		if (showLoginModal && !cu.isSet(cu.getGlobalVar(Consts.sess))) {
			showLoginModal(true);
		} else if (tooltip) {
			Logger.debug("tooltip");
			// for compatibility
			setTagAlert(tooltip(
				// eslint-disable-next-line no-nested-ternary
				isUserOwnPost ? NoRateTooltipFor.OwnPost
					// eslint-disable-next-line no-nested-ternary
					: !isCredoMode ? NoRateTooltipFor.ModeSwitch
						: cu.isSet(cu.getGlobalVar(Consts.ratingFirstTime)) ? NoRateTooltipFor.ShowTutorial
							: null,
			));
		} else // replace with dobe-driven stuff
		if (!isCredoMode) {
			setTagAlert(<span>Need to be in credo mode</span>);
		} else if (isUserOwnPost) {
			// eslint-disable-next-line react/no-unescaped-entities
			setTagAlert(<span>Can't rate own post</span>);
		} else {
			setTagAlert(<span>Cannot!</span>);
		}
	};

	/**
	 * This has been added because when user closes the tutorial modal
	 * and then clicks on the tag to rate, it was not opening the slider
	 * due to the component was not being updated since the value (Consts.ratingFirstTime)
	 * is stored in the localstorage.
	 * */
	const handleRatingTutorialModal = (message: { showModal: boolean }) => {
		if (!message.showModal) {
			setRatingFirstTime(false);
		}
	};

	useEvtMgrListener(EventConst.ratingTutorialModal, handleRatingTutorialModal);

	const isAllowedToRate = () => (
		!disable && !isUserOwnPost && (cu.isSet(cu.getGlobalVar(Consts.sess)))
	);

	// TODO: fix UI issues for other controls XS, S, L and XL
	return (
		<TagIndicator
			id={id}
			tag={tag}
			size={size}
			icon={renderTagIcon}
			s_credo_score={data.subjScore}
			g_credo_score={data.objScore}
			engagement={data.engagement}
			subjectivity={data.subjectivity}
			is_interest={data.ownInterest}
			has_rating={data.ownRating !== -1}
			show_icon={data.hasCampaign}
			// commenting out loading indicator to avoid pill resizing
			// show_icon={loading || data.hasCampaign}
			rating={data.ownRating}
			relevance={data.relevance}
			onRate={onTagRate}
			isRatingAllowed={isAllowedToRate()}
			onRatingBlocked={onRatingBlocked}
			showEgoModal={!isCredoMode}
			egoInfoProps={egoInfoProps}
			boostTrayProps={boostTrayProps}
			disable={disable}
			messages={messages}
		>
			{!isAllowedToRate() ? tagAlert : null}
		</TagIndicator>
	);
};

CredoTag.defaultProps = {
	disable: false,
	showLoginModal: () => { },
	onRatingDisabled: () => { },
	egoInfoProps: {
		switchComp: <CredoSwitch size={CredoSwitchSize.SMALL} isCredo disabled />,
		onPressTagInfoBubble: () => {},
		onPressCredoGraphText: () => {},
	},
};
