import React, { ForwardedRef, useImperativeHandle, useState } from "react";
import { useSetAtom } from "jotai";
import {
	classNames, ConfirmationModal, Modal, ReportPostSuccessIcon,
} from "@credo/ui-components";
import { isMobile, isTablet } from "react-device-detect";
import {
	BoostBoardItem, DataStatus, EvtMgr, useEvtMgrListener,
} from "@credo/utilities";
import { CredoBoost } from "../../../services/actions/boost";
import { TopFeedDataProviderRef } from "./types";
import { BoostBoardMapAtom, FetchAction, OperationType } from "../../../utils/types";
import { topFeedDataMapAtom } from "../../../utils/atoms";
import { GenericBoostResponse } from "../../../services/actions/boost/types";
import { strings } from "../../../i18n/config";
import ReportPost from "../../post/ReportPost";
import ReportSuccess from "../../legal/ReportSuccess";
import { UserActions } from "../../../services/actions/user";
import { EventConst } from "../../../utils";

interface TopFeedDataProviderProps {

}

/**
 * A separate layer for data interaction with the feed.
 * Basically trying to keep separate layer for Business
 * logic and Presentation/UI.
 *
 * This which this component will do.
 * 1. Fetch Feed DONE
 * 2. Fetch next page
 * 3. Interaction with Feed if any like delete or rating
 * 4. Any update happens in the feed will be updated here.
 * */
// eslint-disable-next-line import/prefer-default-export
export const BoostBoardDataProvider: React.FC<TopFeedDataProviderProps> = React.forwardRef((
	props: TopFeedDataProviderProps,
	ref: ForwardedRef<TopFeedDataProviderRef>,
) => {
	const [showDeletePostModal, setShowDeletePostModal] = useState<boolean>(false);
	const [selectedPost, setSelectedPost] = useState<BoostBoardItem | null>(null);
	const [showFlagAsInAppModal, setShowFlagAsInAppModal] = useState<boolean>(false);
	const [showReportSuccessModal, setShowReportSuccessModal] = useState<boolean>(false);
	const setDataMapInAtom = useSetAtom(topFeedDataMapAtom);

	const fetchFeed = (
		action: FetchAction,
		op: OperationType,
		activeTag: string,
	) => {
		setDataMapInAtom((prev: BoostBoardMapAtom) => ({
			...prev,
			isLoading: action === FetchAction.LOAD ? DataStatus.LOADING : DataStatus.REFRESHING,
		}));

		if (action === FetchAction.REFRESH) {
			EvtMgr
				.getInstance(EventConst.trayOnExpand)
				.notifyListeners({
					showTray: false,
				});
		}

		const request: {
			filterByTags: string[],
		} = {
			filterByTags: [activeTag],
		};

		const onSuccess = (response: GenericBoostResponse<BoostBoardItem[]>) => {
			const {
				items,
				boardTs,
				boardAccessCost,
				boardVersion,
				currentTs,
				boardSnapIntervalSecs,
				txStatus,
			} = response;

			const dataMap = new Map(
				items.map((item) => [
					item["p.postUuid"],
					item,
				]),
			);
			const dataId = items.map((item) => item["p.postUuid"]);

			setDataMapInAtom({
				data: dataMap,
				renderIds: dataId,
				dataIds: dataId,
				isLoading: DataStatus.LOADED,
				boardInfo: {
					boardTs,
					boardVersion,
					boardAccessCost,
					boardSnapIntervalSecs,
					currentTs,
					txStatus,
				},
			});
		};

		const onError = () => {
			setDataMapInAtom((prev: BoostBoardMapAtom) => ({
				...prev,
				isLoading: DataStatus.LOADED,
			}));
		};

		/**
		 * User creates a post and comes back to top feed
		 * the balance should be updated.
		 * */
		UserActions.getWalletBalance();
		CredoBoost.getRankedFeed(
			request,
			onSuccess,
			onError,
		);
		// CredoBoost.getTags(
		// 	// request,
		// 	onSuccess,
		// 	onError,
		// );
	};

	useImperativeHandle(ref, () => ({
		fetchFeed,
	}));

	const onCloseDeletePostModal = () => {
		setShowDeletePostModal(false);
	};

	const onFlagAsInAppClose = () => {
		setSelectedPost(null);
		setShowFlagAsInAppModal(false);
	};

	const onPressOkDelete = () => {
		// TODO: Add delete logic here
		onCloseDeletePostModal();
	};

	const onPostReported = () => {
		setShowFlagAsInAppModal(false);
		setShowReportSuccessModal(true);
	};

	const onReportContinue = () => {
		setShowReportSuccessModal(false);
	};

	const renderDeletePostAlert = () => (
		<ConfirmationModal
			isVisible={showDeletePostModal}
			onClose={onCloseDeletePostModal}
			cancel_button_function={onCloseDeletePostModal}
			ok_button_title={strings("PostView.delete")}
			cancel_button_title={strings("PostView.cancel")}
			ok_button_function={onPressOkDelete}
			title={strings("PostView.delete_post")}
			message={strings("PostView.confirm_delete_message")}
			okButtonStyle=""
			cancelButtonStyle=""
		/>
	);

	const renderReportPostModal = () => (
		<Modal
			id="report-post-modal"
			show={showFlagAsInAppModal}
			onClose={onFlagAsInAppClose}
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<div>
					<p className="text-primary text-lg font-bold">{strings("PostView.report_post")}</p>
					<div className="mt-2">
						<ReportPost
							postData={selectedPost}
							onPostReported={onPostReported}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);

	const renderReportSucessModal = () => (
		<Modal
			id="report-post-success-modal"
			show={showReportSuccessModal}
			onClose={onReportContinue}
			hideCloseIcon
			dialogClassName={classNames(
				"bg-background",
				isMobile && !isTablet ? "max-w-full h-screen flex flex-col justify-center !rounded-none" : "",
			)}
			dialogWrapperClassName={classNames(
				isMobile ? "!p-0" : "",
				"z-[200]",
			)}
			closeIconClassName={classNames("hover:bg-background-secondary")}
			titleClassName="text-primary"
		>
			<div className="bg-background">
				<ReportSuccess
					icon={<ReportPostSuccessIcon />}
					title={strings("ReportPostSentSuccessScreen.sent")}
					subTitle={strings("ReportPostSentSuccessScreen.thank_you_message")}
					buttonTitle={strings("ReportPostSentSuccessScreen.continue")}
					onPressButton={onReportContinue}
				/>
			</div>
		</Modal>
	);

	const handleShowReportPostModal = (
		message: {
			selectedPost: BoostBoardItem,
		},
	) => {
		if (message.selectedPost) {
			setSelectedPost(message.selectedPost);
			setShowReportSuccessModal(true);
		}
	};

	const handleDeletePostModal = (
		message: {
			selectedPost: BoostBoardItem,
		},
	) => {
		if (message.selectedPost) {
			setSelectedPost(message.selectedPost);
			setShowDeletePostModal(true);
		}
	};

	useEvtMgrListener("boost-board-show-report-post-modal", handleShowReportPostModal);
	useEvtMgrListener("boost-board-delete-report-post-modal", handleDeletePostModal);

	return (
		<>
			{showFlagAsInAppModal && renderReportPostModal()}
			{showReportSuccessModal && renderReportSucessModal()}
			{renderDeletePostAlert()}
		</>
	);
});
