import { classNames, LazyLoad, PostFeedSkeleton } from "@credo/ui-components";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const PostDetails = LazyLoad(
	() => import(/* webpackChunkName: "postDetails" */ "./PostDetails"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block min-h-[calc(100vh-4rem)]",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"text-basic",
				)}
			>
				<div
					className={classNames(
						"flex flex-col text-white min-h-[calc(100vh-4rem)]",
						"bg-background w-full py-5 z-0",
					)}
				>
					<PostFeedSkeleton
						noOfItems={1}
					/>
				</div>
			</div>
		),
	},
);
