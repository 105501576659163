/* eslint-disable no-else-return */
import React, { useMemo, useState } from "react";
import {
	AppMode, cu, EvtMgr, ThemeOptions, YesNoOptions,
} from "@credo/utilities";
import moment from "moment/moment";
import {
	CIStyler, classNames, AuthorTags, AuthorTagsModal, ProfilePicture, ItemType,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { PostAuthor } from "./PostAuthor";
import { AppUtils, EventConst, TAG_MESSAGES } from "../../utils";
import { userIdAtom, userProfileAtom } from "../../utils/atoms";
import { strings } from "../../i18n/config";
import { ExpertiseActions, ExpertiseTypes } from "../../utils/types";
import { UserActions } from "../../services/actions/user";

interface PostCommentHeaderProps {
	/**
	 * Name of the author of the comment
	 * */
	name: string | null;
	/**
	 * Timestamp of the comment
	 * */
	timestamp: any;
	/**
	 * Timestamp subscript for comment, if it
	 * is edited or not. Shows up like [E]
	 * */
	// timestampSubscript: string | null;
	/**
	 * If the timestamp is between 0-4 hours ago then the background
	 * of the timestamp will fade away till 4 hours mark. Once
	 * it hit 4 hours the background will be transparent.
	 * */
	// timestampForBg: any;
	/**
	 * Function to navigate to the user profile
	 * of the author
	 * */
	navigateToUser: () => void;
	/**
	 * Set true if user of this comment is the author of the post as well
	 * will display an A in the UI if user of this comment is an author.
	 * */
	isAuthor: boolean;

	commentData: any;

}

// eslint-disable-next-line import/prefer-default-export
export const PostCommentHeader: React.FC<PostCommentHeaderProps> = (props: PostCommentHeaderProps) => {
	const {
		navigateToUser,
		// timestampSubscript,
		// timestampForBg,
		timestamp,
		name,
		isAuthor,
		commentData,
		// onCommentPress,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isAuthorTagsModalOpen, setIsAuthorTagsModalOpen] = useState(false);
	const [userProfile] = useAtom(userProfileAtom);
	const [userId] = useAtom(userIdAtom);

	const isAuthorTagsPresent = useMemo(() => commentData.authorTags?.length > 0, [commentData]);

	const onAuthorPostTagRate = (tag: string, rating: number) => {
		const request = {
			user_id: userId,
			mode: AppMode.EGO,
			itemType: ItemType.USER,
			action: ExpertiseActions.RATE,
			xpType: ExpertiseTypes.UXP_EXPT,
			uxpcode: tag,
			rating: rating ?? 0,
			isCredoNetRating: YesNoOptions.NO,
			dest_mode: AppMode.EGO,
			dest_prof_id: commentData.profId || "",
		};
		UserActions.rateUser4XpItem(request);
		EvtMgr.getInstance(`${EventConst.rateCommentTag}_${commentData?.["p.postUuid"]}`).notifyListeners({
			tag,
			rating,
			postUuid: commentData["p.postUuid"],
			commentUuid: commentData.commentUuid,
			parentCommentUuid: commentData.parentCommentUuid,
		});
	};

	const changeMode = (mode: boolean) => {
		AppUtils.toggleTheme(mode ? ThemeOptions.CREDO : ThemeOptions.EGO);
	};

	const closeAuthorTagsModal = () => {
		setIsAuthorTagsModalOpen(false);
	};

	const calculateOpacityForTime = (fromTime: string | null) => {
		if (!fromTime) {
			return 0;
		}

		const postedDate = moment(fromTime).toDate();
		const currentDate = moment().toDate();
		const thresholdTime = 60 * 4;
		if (!Number.isNaN(postedDate.getTime())) {
			const postedDateDiff = Math.abs(currentDate.getTime() - postedDate.getTime()) / 1000;
			if ((postedDateDiff / 60) < thresholdTime) {
				return 1 - (postedDateDiff / 60) / thresholdTime;
			}
		}
		return 0;
	};

	const openAuthorTagsModal = () => {
		if (isAuthorTagsPresent) {
			setIsAuthorTagsModalOpen(true);
		}
	};

	const onNamePress = () => {
		if (!isAuthorTagsPresent) {
			if (navigateToUser) navigateToUser();
			return;
		}
		if (isAuthorTagsModalOpen) {
			closeAuthorTagsModal();
			if (navigateToUser) navigateToUser();
		} else {
			setIsAuthorTagsModalOpen(true);
		}
	};

	const renderName = (forAuthorTags: boolean) => (
		<div className={classNames(
			"flex flex-row justify-center items-start",
			forAuthorTags ? "" : "max-w-[80%]",
		)}
		>
			<p
				className="text-primary text-base font-medium cursor-pointer"
				onClick={onNamePress}
				aria-hidden
			>
				{name}
			</p>
			{isAuthor && <PostAuthor />}
		</div>
	);

	if (!name) {
		return null;
	}

	return (
		<div>
			<div
				className={classNames(
					isAuthorTagsPresent ? "cursor-pointer" : "",
				)}
				onClick={openAuthorTagsModal}
				aria-hidden
			>
				<div className="flex flex-row justify-between items-start ">
					{renderName(false)}
					<div
						style={{
							backgroundColor: CIStyler.engagementColor(1, calculateOpacityForTime(timestamp)),
						}}
						className="mr-2.5 px-1 rounded mt-0.5"
					>
						<p
							className={classNames(
								"text-sm",
								calculateOpacityForTime(timestamp) > 0 ? "text-dark-timestamp" : "text-gray-dark",
								calculateOpacityForTime(timestamp) > 0
								&& calculateOpacityForTime(timestamp) <= 0.5
								&& isCredoMode
									? "!text-off-white" : "",
							)}
						>
							{cu.calculatePostTime(timestamp ?? "", false, true)}
							{/* Uncomment this to show E for edit comment */}
							{/* {timestampSubscript} */}
						</p>
					</div>
				</div>
				<div>
					<AuthorTags
						// @ts-ignore
						postData={commentData}
						messages={{
							tagMessages: {
								view_tutorial: strings("TagMessages.view_tutorial"),
								creed_high: strings("TagMessages.creed_high"),
								creed_med: strings("TagMessages.creed_med"),
								creed_low: strings("TagMessages.creed_low"),
								boost_this_post: strings("TagMessages.boost_this_post"),
								boost_info: strings("TagMessages.boost_info"),
								rating_info: strings("TagMessages.rating_info_author"),
								rate_this_item: strings("TagMessages.rate_this_author"),
							},
						}}
						// onDisabledTagPress={onDisabledTagPress}
					/>
				</div>
			</div>
			{isAuthorTagsModalOpen
				&& (
					<AuthorTagsModal
						postData={commentData}
						tagData={commentData?.authorTags}
						closeAuthorTagsModal={closeAuthorTagsModal}
						profilePicComp={(
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(commentData.profilePicRelUrl ?? "") || ""}
								profId={commentData?.profId ?? ""}
								onProfilePicPress={navigateToUser}
								profilePicWrapperStyle="w-12"
							/>
						)}
						nameComp={renderName(true)}
						postTagProps={{
							tags: commentData.authorTags,
							onRate: onAuthorPostTagRate,
							isUserOwnPost: AppUtils.isCrtUserProfId(commentData?.profId ?? "", userProfile),
							isCredoMode,
							onModeChange: changeMode,
							userProfile,
							switchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
							cannotRateOwnPostMsg: strings("PostView.cannotRateOwnPostMsg"),
							showLoginModal: AppUtils.handleLoginModal,
							onPressTagInfoBubble: AppUtils.showRatingTagTutorial,
							onPressCredoGraphText: AppUtils.showCredoGraph,
							credoTagId: "author-modal-comments/credo-tag",
							messages: {
								...TAG_MESSAGES,
								rating_info: strings("TagMessages.rating_info_author"),
								rate_this_item: strings("TagMessages.rate_this_author"),
							},
						}}
					/>
				)}
		</div>
	);
};
