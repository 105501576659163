import { Outlet } from "react-router-dom";
import {
	CHANGE_EMAIL_PAGE,
	CHANGE_PHONE_PAGE,
	CREATE_STREAM,
	DELETE_ACCOUNT,
	ENGAGE_PAGE,
	LANGUAGE_PAGE,
	NOTIFICATIONS_PAGE,
	PRIVACY_SETTINGS_PAGE,
	SOCIAL_LOGIN_PAGE,
	PROFILE_PAGE,
	REPORT_A_PROBLEM_PAGE,
	SETTINGS_PAGE,
	SUPPORT_PAGE,
	VERIFY_PHONE_PAGE,
	COMMUNITIES_PAGE,
	EXPLORE_PAGE,
	REQUEST_PAGE,
	NETWORK_PAGE,
	CONNECT_FOLLOW_PAGE,
	PROFILE_VISIBILITY_PAGE,
	POSTS_VISIBILITY_PAGE,
	BLOCKED_USERS_PAGE,
	YOUR_ACTIVITY_PAGE,
	TOP_PAGE, HOME_PAGE,
} from "../routes/constants";
import { AppUtils } from "../utils";
import { UserProfile } from "../modules/user/UserProfile";
import { NotificationPage } from "../modules/notifications/NotificationPage";
import { NewPostScreen } from "../modules/new-post/NewPostScreen";
import { SettingsPage } from "../modules/settings";
import { ReportAProblemPage } from "../modules/settings/ReportAProblem";
import { LanguagePage } from "../modules/settings/LanguagePage";
import { SettingsSupportPage } from "../modules/settings/Support/SupportPage";
import ChangeEmail from "../modules/settings/AddEmail/ChangeEmail";
import { ChangePhoneNumber, PhoneVerification } from "../modules/settings/ChangePhoneNumber";
import { PrivacySettings } from "../modules/settings/PrivacySettings";
import CreateStream from "../modules/stream/CreateStream";
import { SettingsSocialLoginScreen } from "../modules/settings/SocialLogin";
import { SettingsAccountDeleteScreen } from "../modules/settings/DeleteAccount";
import MyCommunitiesPage from "../modules/engage/communities/MyCommunitiesPage";
import ExplorePage from "../modules/engage/explore/ExplorePage";
import { ProfileRequests } from "../modules/user/ProfileRequests";
import { ProfileNetwork } from "../modules/user/ProfileNetwork";
import SettingsPSConnectFollowScreen from "../modules/settings/PrivacySettings/SettingsPSConnectFollowScreen";
import SettingsPSProfileVisibiltyScreen from "../modules/settings/PrivacySettings/SettingsPSProfileVisibiltyScreen";
import SettingsPSPostsVisibiltyScreen from "../modules/settings/PrivacySettings/SettingsPSPostsVisibiltyScreen";
import SettingsPSBlockedUsersScreen from "../modules/settings/PrivacySettings/SettingsPSBlockedUsersScreen";
import SettingsPSActivityScreen from "../modules/settings/PrivacySettings/SettingsPSActivityScreen";
import { HomeTop } from "../modules/home/HomeTop";
import { HomePage } from "../modules/home/HomePage";

const privateRoutesConfig = [
	{
		component: NotificationPage,
		path: NOTIFICATIONS_PAGE,
		title: "Notifications",
		exact: true,
	},
	{
		component: HomeTop,
		path: TOP_PAGE,
		title: "Top",
		exact: true,
	},
	{
		component: HomePage,
		path: HOME_PAGE,
		title: "For you",
		exact: true,
	},
	{
		component: Outlet,
		path: "",
		title: "Profile",
		children: [
			{
				component: UserProfile,
				path: PROFILE_PAGE,
				title: "Profile",
				exact: true,
			},
			{
				component: ProfileRequests,
				path: `${PROFILE_PAGE}${REQUEST_PAGE}`,
				title: "Requests",
				exact: true,
			},
			{
				component: ProfileNetwork,
				path: `${PROFILE_PAGE}${NETWORK_PAGE}`,
				title: "Network",
				exact: true,
			},
		],
	},
	{
		component: Outlet,
		path: "",
		title: "Manage",
		children: [
			{
				component: MyCommunitiesPage,
				path: `${ENGAGE_PAGE}${COMMUNITIES_PAGE}`,
				title: "My Communities",
				exact: true,
			},
			{
				component: CreateStream,
				path: `${ENGAGE_PAGE}${CREATE_STREAM}`,
				title: "Create A Stream",
				exact: true,
			},
			{
				component: ExplorePage,
				path: `${ENGAGE_PAGE}${EXPLORE_PAGE}`,
				title: "Explore",
				exact: true,
			},
		],
	},
	{
		component: NewPostScreen,
		title: "New Post",
		exact: true,
		onclick: () => AppUtils.handleNewPostModal(false),
	},
	{
		component: Outlet,
		path: "",
		title: "Settings",
		children: [
			{
				component: SettingsPage,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}`,
				title: "Settings",
				exact: true,
			},
			{
				component: ReportAProblemPage,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${REPORT_A_PROBLEM_PAGE}`,
				title: "Report A Problem",
				exact: true,
			},
			{
				component: SettingsSupportPage,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${SUPPORT_PAGE}`,
				title: "Support",
				exact: true,
			},
			{
				component: LanguagePage,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${LANGUAGE_PAGE}`,
				title: "Language",
				exact: true,
			},
			{
				component: ChangeEmail,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${CHANGE_EMAIL_PAGE}`,
				title: "Change Email",
				exact: true,
			},
			{
				component: ChangePhoneNumber,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${CHANGE_PHONE_PAGE}`,
				title: "Change Phone Number",
				exact: true,
			},
			{
				component: PhoneVerification,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${VERIFY_PHONE_PAGE}`,
				title: "Phone Verrification",
				exact: true,
			},
			{
				component: SettingsSocialLoginScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${SOCIAL_LOGIN_PAGE}`,
				title: "Social Login",
				exact: true,
			},
			{
				component: SettingsAccountDeleteScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${DELETE_ACCOUNT}`,
				title: "Delete Account",
				exact: true,
			},
		],
	},
	{
		component: Outlet,
		path: "",
		title: "Privacy Settings",
		children: [
			{
				component: PrivacySettings,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}`,
				title: "Privacy Settings",
				exact: true,
			},
			{
				component: SettingsPSProfileVisibiltyScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}${PROFILE_VISIBILITY_PAGE}`,
				title: "Profile Visibility",
				exact: true,
			},
			{
				component: SettingsPSConnectFollowScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}${CONNECT_FOLLOW_PAGE}`,
				title: "Connect & Follow",
				exact: true,
			},
			{
				component: SettingsPSPostsVisibiltyScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}${POSTS_VISIBILITY_PAGE}`,
				title: "Posts Visibility",
				exact: true,
			},
			{
				component: SettingsPSBlockedUsersScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}${BLOCKED_USERS_PAGE}`,
				title: "Blocked Users",
				exact: true,
			},
			{
				component: SettingsPSActivityScreen,
				path: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}${YOUR_ACTIVITY_PAGE}`,
				title: "Your Activity",
				exact: true,
			},
		],
	},
];

export default privateRoutesConfig;
