import {atom, PrimitiveAtom} from "jotai";
import {
	AppMode,
	BoostTag,
	cu,
	DataStatus,
	dbg,
	EmojiItem,
	LighteningAddressesAtom,
	LogMgr,
	Permission,
	UserProfile,
	WalletSummaryForGraph,
} from "@credo/utilities";
import { StateMgr } from "./StateMgr";
import { GlobalState } from "./globals";

/**
 * Can set atoms dynamically.
 *
 * @example
 * const updateAtom = useSetAtom(atomSetterAtm);
 *
 * // atomName: Actual atom which needs to be updated
 * // nextValue: the value which needs to be set to atom.
 * updateAtom([atomName, nextValue]);
 **/
export const atomSetterAtm = atom(
	null,
	(get, set, [atom, update]) => {
		set(atom, update)
	},
);

/**
 * Creates an atom and at the time of updating
 * it will also update the global state
 *
 * @param key - state will be set against this key name
 * @param initialValue - initial value of that state
 */
export function atomWithMgr<ValueT>(
	key: string,
	initialValue: ValueT,
) {
	const anAtom: any = atom(
		initialValue,
		(get, set, update: any) => {
			const nextValue = typeof update === "function"
				? (update as (prev: ValueT) => ValueT)(get(anAtom))
				: update;
			if (dbg) {
				LogMgr.log(`updating an atom ${{ key, nextValue }}`);
			}
			StateMgr.setState(key, nextValue, false);
			set(anAtom, nextValue);
		},
	);

	/**
	 * When atom is mounted/initialised we need to initialise
	 * the value in the StateMgr cache as well.
	 * */
	anAtom.onMount = () => {
		StateMgr.setState(key, initialValue, false);
		// this also registers the on write of this
		StateMgr.setStateOnWrite(key, val => StateMgr.updateState({
			atomName: anAtom, nextValue: val
		}));
	};

	return anAtom as (PrimitiveAtom<ValueT>);
}

// These are for testing purpose
export const firstAtomMgr = atomWithMgr(GlobalState.Miscellaneous.firstAtom.key, 0);
export const secondAtomMgr = atomWithMgr(GlobalState.Miscellaneous.secondAtom.key, 0);

// App
export const isCredoModeAtom = atomWithMgr<boolean>(GlobalState.App.isCredoMode.key, cu.getSwitchValue());
export const isConnected = atomWithMgr<boolean>(GlobalState.App.isConnected.key, false);
export const emittedHintsAtom = atomWithMgr<string[]>(GlobalState.App.emittedHints.key, []);
export const removedHintsAtom = atomWithMgr<string>(GlobalState.App.removedHints.key, "");
export const userPermissionsAtom = atomWithMgr<{
	dataStatus: DataStatus,
	[AppMode.CREDO]: Permission[],
	[AppMode.EGO]: Permission[],
}>(GlobalState.User.permissions.key, {
	dataStatus: DataStatus.INIT,
	[AppMode.CREDO]: [],
	[AppMode.EGO]: [],
})
// Emojis
export const emojiDataAtom = atomWithMgr<EmojiItem[]>(GlobalState.App.emojiData.key, []);
// User
export const userProfileAtom = atomWithMgr<UserProfile | null>(GlobalState.User.profile.key, null);
export const userWalletAtom = atomWithMgr<number>(GlobalState.User.walletBalance.key, 0);
export const lighteningAddressesAtom = atomWithMgr<LighteningAddressesAtom>(GlobalState.User.lighteningAddresses.key, {
	dataForDropdown: [],
	data: [],
	dataStatus: DataStatus.LOADING,
	withdrawMeta: {
		feePercentage: 0,
		maxAmount: 0,
		minAmount: 0,
	}
});
//Boost
export const boostTagsAtom = atomWithMgr<{ data: BoostTag[], dataStatus: DataStatus }>(GlobalState.Boost.tags.key, {
	data: [],
	dataStatus: DataStatus.LOADING,
});
export const walletSummaryAtom = atomWithMgr<WalletSummaryForGraph>(GlobalState.Volts.summary.key, {
	boost_value_history: [],
	wallet_balance_history: [],
	hasError: false,
	dataStatus: DataStatus.LOADING,
	wallet_balance: 0,
	wallet_24h: "0",
	boost_24h: "0",
	boost_balance: 0,
});
