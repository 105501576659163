/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { ScalerSize } from "../common";

export interface CIProps {
	size: ScalerSize;
}

/* Gradient configs for credo colors as per ratings
	Rating	|	 	Hex		|				Rgb(0-255)
	---------------------------------------
		1				#0FF077			rgb(15, 240, 119)
		0.8			#9DE43F			rgb(157, 228, 63)
		0.5			#F8DC1B			rgb(248, 220, 27)
		0.3			#FC923A			rgb(252, 146, 58)
		0				#FF654D			rgb(255, 101, 77)
*/
const GRADIENT_CONFIGS = {
	stops: [0, 0.25, 0.5, 0.75, 1],
	rgb: [[255, 250, 248, 175, 15], [101, 160, 220, 230, 240], [77, 50, 27, 70, 119]],
};

export const TAG_COLOR = {
	dark: "#212636",
	light: "#F5F5F5",
	relevance: "#0FF077",
	black: "#171C2D",
	marker: "#090924",
	gray: "#96A5B9",
};

export type rgba = { r: number, g: number, b: number, a: number };
const GRADIENTS = {
	NA_COLOR_RED: 0.59,
	NA_COLOR_GREEN: 0.65,
	NA_COLOR_BLUE: 0.73,
	LUMA_LIGHT_THRESHOLD: 0.22, // 0.179 is the standard

	rgba(r: number, g: number, b: number, a = 1) {
		return {
			r: r / 255,
			g: g / 255,
			b: b / 255,
			a,
		};
	},

	csColor(cs: number, stops: Array<number>, rgb: Array<any>, a = 1): rgba {
		if (cs === -1) {
			return {
				r: GRADIENTS.NA_COLOR_RED, g: GRADIENTS.NA_COLOR_GREEN, b: GRADIENTS.NA_COLOR_BLUE, a,
			};
		}
		const endIx = stops.findIndex((stop, index) => stop > cs);

		// out of stops boundaries
		if (endIx === 0) {
			return GRADIENTS.rgba(rgb[0][0], rgb[1][0], rgb[2][0], a);
		} else if (endIx === -1) {
			return GRADIENTS.rgba(rgb[0][stops.length - 1], rgb[1][stops.length - 1], rgb[2][stops.length - 1], a);
		}

		const start = stops[endIx - 1];
		const stop = stops[endIx];
		return GRADIENTS.rgba(
			rgb[0][endIx - 1] + (((cs - start) / (stop - start)) * (rgb[0][endIx] - rgb[0][endIx - 1])),
			rgb[1][endIx - 1] + (((cs - start) / (stop - start)) * (rgb[1][endIx] - rgb[1][endIx - 1])),
			rgb[2][endIx - 1] + (((cs - start) / (stop - start)) * (rgb[2][endIx] - rgb[2][endIx - 1])),
			a,
		);
	},

	calcCs(cs: number, alpha = 1) {
		return GRADIENTS.csColor(cs, GRADIENT_CONFIGS.stops, GRADIENT_CONFIGS.rgb, alpha);
	},

	GREENISH_GRADIENT: (percent: number, alpha: number = 1): rgba => ({
		r: percent === -1 ? GRADIENTS.NA_COLOR_RED : Math.min(1, percent / 3),
		g: percent === -1 ? GRADIENTS.NA_COLOR_GREEN : Math.min(1, percent * 2),
		b: percent === -1 ? GRADIENTS.NA_COLOR_BLUE : Math.min(1, percent / 3),
		a: alpha,
	}),
	RED_GREEN_GRADIENT: (percent: number, alpha: number = 1): rgba => ({
		r: percent === -1 ? GRADIENTS.NA_COLOR_RED : Math.min(1, 2 - 2 * percent),
		g: percent === -1 ? GRADIENTS.NA_COLOR_GREEN : Math.min(1, percent * 2),
		b: percent === -1 ? GRADIENTS.NA_COLOR_BLUE : 0,
		a: alpha,
	}),
	CYAN_GRADIENT: (percent: number, alpha: number = 1): rgba => ({
		r: percent === -1 ? GRADIENTS.NA_COLOR_RED : 0.3 - percent,
		g: percent === -1 ? GRADIENTS.NA_COLOR_GREEN : 0.5 + percent,
		b: percent === -1 ? GRADIENTS.NA_COLOR_BLUE : 0.5 + percent,
		a: alpha,
	}),

};

export class CIStyler<PropsClass extends CIProps> {
	baseClass: string | null = null;

	_ref: React.RefObject<any> | null = null;

	constructor(baseClass: string) {
		this.baseClass = baseClass;
	}

	sizeClass = (size: ScalerSize) => size.toString();

	ref = () => {
		if (this._ref === null) {
			this._ref = React.createRef();
		}
		return this._ref;
	};

	getExtraClassName = (extra: string = "") => {
		if (extra) {
			return ` ${extra}`;
		}
		return "";
	};

	classes(props: PropsClass, _state?: {}, extra?: string) {
		return `${this.baseClass} ${this.sizeClass(props.size)} ${this.getExtraClassName(extra)}`;
	}

	width = () => this._ref?.current.offsetWidth;

	height = () => this._ref?.current.offsetHeight;

	static rgb = (red: number,
		green: number,
		blue: number,
		alpha = 1) => `rgba(${(red * 100)}%, ${(green * 100)}%, ${(blue * 100)}%, ${(alpha * 100)}%)`;

	static rgba = (o: rgba) => `rgba(${(o.r * 100)}%, ${(o.g * 100)}%, ${(o.b * 100)}%, ${(o.a * 100)}%)`;

	_csColor = (cs: number, alpha = 1) => CIStyler.rgba(this._csRgb(cs, alpha));

	_csRgb = GRADIENTS.calcCs;

	_engagementColor = (e: number, alpha: number = 1) => CIStyler.rgba(this._engagementRgb(e, alpha));

	static engagementColor = (e: number, alpha: number = 1) => CIStyler.rgba(GRADIENTS.CYAN_GRADIENT(e, alpha));

	_engagementRgb = GRADIENTS.CYAN_GRADIENT;

	// as per W3C ITU-R recommendation BT.709
	static _luma = (c: rgba) => (0.2126 * CIStyler.__lumaPart(c.r)
		+ 0.7152 * CIStyler.__lumaPart(c.g)
		+ 0.0722 * CIStyler.__lumaPart(c.b)) * c.a;

	static __lumaPart = (p: number) => (p <= 0.04045 ? p / 12.92 : ((p + 0.055) / 1.055) ** 2.4);

	_isLight(cs: number, gcs: number, subjectivity: number, e: number) {
		return (
			(CIStyler._luma(this._csRgb(cs)) * subjectivity
				+ CIStyler._luma(this._engagementRgb(e)) * 0.3
				+ CIStyler._luma(this._csRgb(gcs)) * (1 - subjectivity)) / 3) > GRADIENTS.LUMA_LIGHT_THRESHOLD;
	}

	static _lineStyle = (px: number, style: string, color: string) => `${px}px ${style} ${color}`;

	// eslint-disable-next-line class-methods-use-this
	dynamicStyles(_props: {}, _state = null) {
		return {};
	}
}
