import React, { useMemo } from "react";
import {
	RadioButtonSelectedIcon, RadioButtonEmptyIcon, classNames,
} from "@credo/ui-components";
import { RadioGroup } from "@headlessui/react";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { AppMode, cu, EvtMgr } from "@credo/utilities";
import { useWindowSize } from "react-use";
import { isMobile } from "react-device-detect";
import { strings } from "../../../i18n/config";
import {
	Consts, EventConst, MsgConst,
} from "../../../utils";
import { ReportListItem } from "../../../utils/types";
import { userProfileAtom, userSessionAtom } from "../../../utils/atoms";
import { MsgMgr } from "../../../config/MsgMgr";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

const SettingsPSConnectFollowScreen: React.FC = () => {
	const [userProfile] = useAtom(userProfileAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userSession] = useAtom(userSessionAtom);
	const { width } = useWindowSize();
	const isInMobile = isMobile && width < 768;

	useSetAppHeader({
		title: strings("SettingsPrivacySettingsScreen.connect_and_follow"),
	});

	const PSConnectFollowDetails = useMemo(() => ({
		profileFollow: isCredoMode ? userProfile?.ps_c_folyou : userProfile?.ps_c_folyou,
		profileFollowes: isCredoMode ? userProfile?.ps_c_folsee : userProfile?.ps_e_folsee,
		profileConnections: isCredoMode ? userProfile?.ps_c_consee : userProfile?.ps_e_consee,
		profileConnect: isCredoMode ? userProfile?.ps_c_conyou : userProfile?.ps_e_conyou,
		profileCommunities: isCredoMode ? userProfile?.ps_c_comsee : userProfile?.ps_e_comsee,
	}), [isCredoMode, userProfile]);

	const updateProfileSettings = (item: string, value: any) => {
		EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
			items: [
				{
					...userProfile,
					[item]: value,
				},
			],
		});
		const updateProfileActivity = {
			user_id: userSession?.user_id,
			mode: cu.getAppMode(),
			[item]: value,
		};
		MsgMgr.makeRemoteRequest_generic({
			request: updateProfileActivity,
			msgName: MsgConst.updateUserInfo,
		});
	};

	const onProfileFollowChange = (value: any) => {
		updateProfileSettings(isCredoMode ? Consts.privacySetting4FollowYouCredoActivity
			: Consts.privacySetting4FollowYouEgoActivity, value);
	};

	const onProfileCommunitiesChange = (value: any) => {
		updateProfileSettings(isCredoMode ? Consts.privacySetting4SeeCommunititesCredoActivity
			: Consts.privacySetting4SeeCommunititesmEgoActivity, value);
	};

	const onProfileFollowerChange = (value: any) => {
		updateProfileSettings(isCredoMode ? Consts.privacySetting4SeeFollowersCredoActivity
			: Consts.privacySetting4SeeFollowersEgoActivity, value);
	};

	const onProfileConnectChange = (value: any) => {
		updateProfileSettings(isCredoMode ? Consts.privacySetting4ConnectYouCredoActivity
			: Consts.privacySetting4ConnectYouEgoActivity, value);
	};

	const onProfileConnectionsChange = (value: any) => {
		updateProfileSettings(isCredoMode ? Consts.privacySetting4SeeConnectionsCredoActivity
			: Consts.privacySetting4SeeConnectionsEgoActivity, value);
	};

	const profileFollowOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.connections_of_connections"),
			value: Consts.privacySettings.connectionsOfConnections,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	const profileFollowerOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.connections_of_connections"),
			value: Consts.privacySettings.connectionsOfConnections,
			mode: AppMode.EGO,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.direct_connections"),
			value: Consts.privacySettings.directConnections,
			mode: AppMode.EGO,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	const profileConnectOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.connections_of_connections"),
			value: Consts.privacySettings.connectionsOfConnections,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	const profileConnectionsOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.connections_of_connections"),
			value: Consts.privacySettings.connectionsOfConnections,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.direct_connections"),
			value: Consts.privacySettings.directConnections,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	const profileCommunitiesOptions = [
		{
			label: strings("SettingsPSProfileVisibiltyScreen.everyone"),
			value: Consts.privacySettings.everyOne,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.connections_of_connections"),
			value: Consts.privacySettings.connectionsOfConnections,
			mode: AppMode.EGO,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.direct_connections"),
			value: Consts.privacySettings.directConnections,
			mode: AppMode.EGO,
		},
		{
			label: strings("SettingsPSProfileVisibiltyScreen.no_one"),
			value: Consts.privacySettings.noOne,
		},
	];

	const renderRadioGroupItem = (item: ReportListItem) => {
		if (!item?.mode || (item.mode === AppMode.EGO && !isCredoMode) || (item.mode === AppMode.CREDO && isCredoMode)) {
			return (
				<RadioGroup.Option key={item.value} value={item.value}>
					{({ checked }) => (
						<div className="px-2">
							<div
								className="flex flex-row py-2.5 items-center hover:cursor-pointer"
							>
								<div className="w-10 h-6">
									{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
								</div>
								<div className="w-full">
									<p className="text-sm font-medium text-title-color">{item.label}</p>
								</div>
							</div>
							<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
						</div>
					)}
				</RadioGroup.Option>
			);
		}
		return null;
	};

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-5",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<div className="px-3 w-full flex flex-col pb-3">
				<div className="flex text-sm text-gray-dark text-center py-5 w-11/12 self-center">
					{isCredoMode ? strings("SettingsPSConnectFollowScreen.profile_relations_credo_msg")
						: strings("SettingsPSConnectFollowScreen.profile_relations_ego_msg")}
				</div>
				{!isCredoMode && (
					<>
						<span className="text-sm font-medium text-title-color pb-3">
							{strings("SettingsPSConnectFollowScreen.who_can_follow_you")}
						</span>
						<RadioGroup value={PSConnectFollowDetails.profileFollow} onChange={onProfileFollowChange}>
							{profileFollowOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
						</RadioGroup>
					</>
				)}
				<span className="text-sm font-medium text-title-color pt-5 pb-3">
					{strings("SettingsPSConnectFollowScreen.who_can_see_your_followers")}
				</span>
				<RadioGroup value={PSConnectFollowDetails.profileFollowes} onChange={onProfileFollowerChange}>
					{profileFollowerOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
				</RadioGroup>
				{!isCredoMode && (
					<>
						<span className="text-sm font-medium text-title-color pt-5 pb-3">
							{strings("SettingsPSConnectFollowScreen.who_can_connect_with_you")}
						</span>
						<RadioGroup value={PSConnectFollowDetails.profileConnect} onChange={onProfileConnectChange}>
							{profileConnectOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
						</RadioGroup>
						<span className="text-sm font-medium text-title-color pt-5 pb-3">
							{strings("SettingsPSConnectFollowScreen.who_can_see_your_connections")}
						</span>
						<RadioGroup value={PSConnectFollowDetails.profileConnections} onChange={onProfileConnectionsChange}>
							{profileConnectionsOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
						</RadioGroup>
					</>
				)}
				<span className="text-sm font-medium text-title-color pt-5 pb-3">
					{strings("SettingsPSConnectFollowScreen.who_can_see_your_communities")}
				</span>
				<RadioGroup value={PSConnectFollowDetails.profileCommunities} onChange={onProfileCommunitiesChange}>
					{profileCommunitiesOptions.map((item: ReportListItem) => renderRadioGroupItem(item))}
				</RadioGroup>
			</div>
		</div>
	);
};

export default SettingsPSConnectFollowScreen;
