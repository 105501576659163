import React, {
	useCallback, useEffect, useMemo, useState,
} from "react";
import {
	ButtonSize,
	classNames,
	CredoSwitchSize,
	FeedNoPost,
	PostFeedSkeleton,
	RewardsNotification,
	RewardsNotificationMsgs,
	SecondaryButton,
	Spinner,
	SpinnerLeafType,
	StreamNoNetworkIcon,
	SuggestedConnCard,
	UxpRatingCard,
} from "@credo/ui-components";
import isNil from "lodash/isNil";
import last from "lodash/last";
import {
	PrimitiveAtom, useAtom, useAtomValue, useSetAtom,
} from "jotai";
import {
	AppMode,
	cu,
	dbg,
	EvtMgr,
	GenericResponse,
	LogMgr,
	PostType,
	SessMgr,
	ThemeOptions,
	useEvtMgrListener,
	YesNoOptions,
} from "@credo/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInterval, useUpdateEffect, useWindowSize } from "react-use";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { emojiDataAtom, isCredoModeAtom } from "@credo/store";
import {
	DeleteItemType, deletePost, DeletePostRequestObject, FeedItem, fetchFeed, getFeedFilterTags, RequestObject,
} from "./requests";
import {
	commentBeenRepliedAtom,
	feedSugConnCardsAtom,
	feedUxpRatingCardsAtom,
	gotMixFeedDataAtom,
	isConnectedAtom,
	pendingTasksAtom,
	userIdAtom, userMetaDataAtom,
	userProfileAtom,
} from "../../utils/atoms";
import CfgMgr from "../../config/CfgMgr";
import { strings } from "../../i18n/config";
import {
	AnalyticEventsConst, AppUtils, ClientMetaDataMapping, Consts, EventConst, MsgConst, TAG_MESSAGES,
} from "../../utils";
import { UserActions } from "../../services/actions/user";
import {
	ExpertiseActions,
	ExpertiseTypes,
	ExploreItem,
	FeedType,
	FilterData,
	FilterTag,
	FilterTagsFeed,
	ItemType,
	OperationType,
	RelationTypeStatus,
	WithInitialValue,
} from "../../utils/types";
import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT, HOME_FEED_TAB_HEADER_HEIGHT } from "../../config/constants";
import PostWithDirectConnComments from "./PostWithDirectConnComments";
import { postToFeed } from "../post/post-utils";
import PendingTasks from "../post/PendingTasks";
import { ExplainComponent } from "../../components/ExplainComponent";
import { EngageActions, ExploreCommunity } from "../../services/actions/engage";
import { ExploreCommSection } from "./components/ExploreCommSection";
import { formatFeedComments } from "../../utils/comments/CommentUtils";

const REWARD_MESSAGES: RewardsNotificationMsgs = {
	CongratsReward: strings("RewardsNotification.congrats_reward"),
	Badge: strings("RewardsNotification.badge"),
	For: strings("RewardsNotification.for"),
	Point: strings("RewardsNotification.point"),
	Points: strings("RewardsNotification.points"),
	Edited: strings("RewardsNotification.edited"),
	Earned: strings("RewardsNotification.earned"),
};

interface HomeFeedProps {
	extraMsgObjParams: Partial<RequestObject>;
	tabWidth: number;
	atomName: PrimitiveAtom<FeedItem[]> & WithInitialValue<FeedItem[]>;
	feedType: FeedType,
	selectedFilterDataAtom: PrimitiveAtom<FilterData>;
	filterDataAtom: PrimitiveAtom<FilterTag[]>;
}

const HomeFeed = (props: HomeFeedProps) => {
	const {
		extraMsgObjParams, tabWidth, atomName, feedType, selectedFilterDataAtom, filterDataAtom,
	} = props;

	const [dataToRender, setDataToRender] = useAtom(atomName);
	const [data, setData] = useState<FeedItem[]>(dataToRender as any);
	const [userId] = useAtom(userIdAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const setGotMixFeedData = useSetAtom(gotMixFeedDataAtom);
	const [isLoading, setIsLoading] = useState(dataToRender.length === 0);
	// To make sure the data is loaded from the server even if the data is empty.
	const [dataLoaded, setDataLoaded] = useState<boolean>(dataToRender.length > 0 || false);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [, setIsLoadMore] = useState(false);
	const [pendingTasks] = useAtom(pendingTasksAtom);
	const [isNewPostAvailable, setIsNewPostAvailable] = useState(false);
	const [isLastPage, setIsLastPage] = useState(false);
	const [suggConnCard, setSuggConnCard] = useAtom(feedSugConnCardsAtom);
	// Render card for suggested communities
	const [suggCommCard, setSuggCommCard] = useState<ExploreCommunity[]>([]);
	const [userRatingCard, setUserRatingCard] = useAtom(feedUxpRatingCardsAtom);
	const [emojiData, setEmojiData] = useAtom(emojiDataAtom);
	const [commentBeenReplied, setCommentBeenReplied] = useAtom(commentBeenRepliedAtom);
	// To avoid the refresh loader come up when page mounts.
	const [clickedOnTab, setClickedOnTab] = useState<boolean>(false);
	const [clientMetadata, setClientMetaData] = useAtom(userMetaDataAtom);

	// const hasDataAtom = useMemo(() => atom((get) => get(atomName).hasData), [cacheKey]);
	// const hasData = useAtomValue(hasDataAtom);

	const setFilterData = useSetAtom(filterDataAtom);
	const {
		// sortBy,
		// filterByCred,
		filterTags,
		hasInteracted,
	} = useAtomValue(selectedFilterDataAtom);
	const filterTypeMap = {
		[FeedType.FEED]: FilterTagsFeed.ForYou,
		[FeedType.NEWS]: FilterTagsFeed.News,
		[FeedType.BLASTS]: FilterTagsFeed.Blast,
		[FeedType.TOP]: FilterTagsFeed.Top,
	};

	const navigate = useNavigate();
	const { height } = useWindowSize();

	const changeMode = (mode: boolean) => {
		AppUtils.toggleTheme(mode ? ThemeOptions.CREDO : ThemeOptions.EGO);
	};

	const baseRequest = (op?: OperationType): RequestObject => ({
		lastTs: data.length > 0 && op !== OperationType.REPLACE
			? AppUtils.getLastTs(
				data,
				feedType === FeedType.NEWS ? "p.article_date" : "p.modif_ts",
			) : 0,
		lastId: data.length > 0 ? last(data)?.id ?? 0 : 0,
		user_id: userId ?? "",
		mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
		fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
		summary: YesNoOptions.NO,
		APIVersion: "v1",
		filterByTags: hasInteracted && filterTags?.length > 0 ? filterTags.join() : "",
	});

	const retreiveFilterTags = () => {
		if (userId) {
			const filterTagRequest = {
				user_id: userId ?? "",
				feedType: filterTypeMap[feedType],
			};
			getFeedFilterTags(filterTagRequest, setFilterData);
		}
	};

	const loadCard = () => {
		// load suggested connections card
		UserActions.fetchFeedCards(MsgConst.sugConnCards4User);
		// load user xp rating card
		UserActions.fetchFeedCards(MsgConst.xpRatingCards4User);
	};

	const insertCard = (
		dataToRender: FeedItem[],
		type: PostType,
		cardItems: FeedItem[],
		insertIndex?: number,
	) => {
		const item = {
			"p.postUuid": `${type}123`,
			"p.postType": type,
			cardData: cardItems,
		};
		if (dataToRender.findIndex((element) => item["p.postType"] === element["p.postType"]) > -1) {
			return dataToRender;
		}
		if (cu.isSet(insertIndex)) {
			// @ts-ignore
			if (dataToRender.length < insertIndex) {
				return [...dataToRender, item] as FeedItem[];
			}
			return [...dataToRender.slice(0, insertIndex), item, ...dataToRender.slice(insertIndex)] as FeedItem[];
		}
		if (cardItems.length === 0) {
			return dataToRender;
		}

		if (dataToRender.length < 5) {
			return [...dataToRender, item] as FeedItem[];
		}
		return [...dataToRender.slice(0, 4), item, ...dataToRender.slice(4)] as FeedItem[];
	};

	const explainMessage = useMemo(() => {
		const removeSection = (removeFeedType: FeedType) => {
			UserActions.saveClientMetaDataToServer({
				[ClientMetaDataMapping[removeFeedType]]: false,
			});
			setClientMetaData({
				...clientMetadata,
				[ClientMetaDataMapping[removeFeedType]]: false,
			});
		};
		if (userProfile?.user_id) {
			switch (feedType) {
				case FeedType.FEED:
					if (clientMetadata && clientMetadata[ClientMetaDataMapping[FeedType.FEED]]) {
						return {
							message: strings("HomePage.explain.for_you"),
							onClick: () => removeSection(FeedType.FEED),
						};
					}
					return {
						message: "",
					};
				case FeedType.NEWS:
					if (clientMetadata && clientMetadata[ClientMetaDataMapping[FeedType.NEWS]]) {
						return {
							message: strings("HomePage.explain.news"),
							onClick: () => removeSection(FeedType.NEWS),
						};
					}
					return {
						message: "",
					};
				case FeedType.BLASTS:
					if (clientMetadata && clientMetadata[ClientMetaDataMapping[FeedType.BLASTS]]) {
						return {
							message: strings("HomePage.explain.blasts"),
							onClick: () => removeSection(FeedType.BLASTS),
						};
					}
					return {
						message: "",
					};
				default: return {
					message: "",
				};
			}
		}

		return null;
	}, [feedType, userProfile, clientMetadata]);

	useUpdateEffect(() => {
		let dataToRender = data;

		if (userRatingCard.length > 0) {
			dataToRender = insertCard(dataToRender, PostType.UserRatingCard, userRatingCard, 5);
			dataToRender = insertCard(dataToRender, PostType.SugConPost, suggConnCard, 10);
		} else if (suggConnCard.length > 0) {
			dataToRender = insertCard(dataToRender, PostType.SugConPost, suggConnCard, 5);
		}
		if (suggCommCard.length > 0 && feedType === FeedType.FEED) {
			dataToRender = insertCard(dataToRender, PostType.ExploreComm, suggCommCard as any, 0);
		}

		if (pendingTasks && pendingTasks.length > 0) {
			setDataToRender([{ id: -1, "p.postUuid": "-1" } as FeedItem, ...dataToRender]);
		} else {
			setDataToRender(dataToRender);
		}
		/**
		 * Two scenarios
		 * 1. When data is already in the atom and the cards are
		 * added to the existing data we need to stop the skeleton
		 * 2. When user does not have the data in the feed, user
		 * is newly registered. The data will be zero but we have
		 * to wait for the confirmation from the server that user
		 * does not have data so we will check dataLoaded as well.
		 * */
		if (isLoading && (data.length > 0 || dataLoaded)) {
			setIsLoading(false);
		}
		if (isRefreshing && (data.length > 0 || dataLoaded)) {
			setIsRefreshing(false);
		}
	}, [data, isRefreshing, isConnected, suggConnCard, userRatingCard, pendingTasks, suggCommCard, clientMetadata, explainMessage]);

	const handlePendingTasks = () => {
		if (pendingTasks && pendingTasks.length > 0) {
			setDataToRender([{ id: -1, "p.postUuid": "-1" } as FeedItem, ...dataToRender.filter((e) => e.id !== -1)]);
		}
	};

	useEvtMgrListener(EventConst.updatePendingTasks, handlePendingTasks);

	useEvtMgrListener(MsgConst.sugConnCards4User, (msg: GenericResponse<any[]>) => {
		setSuggConnCard(msg?.items ?? []);
	});

	useEvtMgrListener(MsgConst.xpRatingCards4User, (msg: GenericResponse<any[]>) => {
		setUserRatingCard(msg?.items ?? []);
	});

	useEvtMgrListener(MsgConst.suggestions4User, (message: GenericResponse<FeedItem[]>) => {
		const {
			items,
		} = message;
		if (items.length > 0) {
			EngageActions.retrieveExploreItems(
				undefined,
				(response) => {
					setSuggCommCard(response);
				},
				() => { },
			);
		}
	});

	const removeStreamCard = (stream: ExploreItem) => {
		const newCards = suggCommCard.filter((u) => u.streamId !== stream?.streamId);
		setSuggCommCard(newCards);
	};

	const fetchFeedData = async (
		/**
		 * In case of loading more content, we should not pass
		 * this function/parameter, it gives reloading
		 * indicator on android while loading more content.
		 * */
		inProcessSetter: (inProcess: boolean) => void | null,
		op: OperationType,
	) => {
		if (!isConnected) return;
		try {
			if (inProcessSetter) {
				inProcessSetter(true);
			}
			setIsNewPostAvailable(false);
			if (feedType === FeedType.FEED && userId && op === OperationType.REPLACE) {
				UserActions.fetchFeedCards(MsgConst.suggestions4User);
			}

			const { items } = await fetchFeed({ ...baseRequest(op), ...extraMsgObjParams });
			const itemsBasedOnTab = items.map((element) => ({
				...element,
				"p.creat_ts": feedType === FeedType.NEWS ? element["p.article_date"] : element["p.creat_ts"],
				/**
				 * because news posts will never be edited and due to difference
				 * in create_ts and edit_ts the post was shown as edited. So keeping
				 * both of them as same.
				 * */
				"p.edit_ts": feedType === FeedType.NEWS ? element["p.article_date"] : element["p.edit_ts"],
				comments: formatFeedComments(element.comments),
				authorTags: AppUtils.removeRatingFromCredoPostAuthorTags(element.authorTags, element["p.mode"]),
			}));

			if (feedType === FeedType.FEED) {
				setGotMixFeedData(true);
			}

			if (op === "replace") {
				setData(itemsBasedOnTab);
				if (window) {
					window.scrollTo(0, 0);
				}
			} else {
				setData([...data, ...itemsBasedOnTab]);
			}

			if (items.length < 1) {
				setIsLastPage(true);
			}
			setDataLoaded(true);
			if (userId && op === "replace") {
				loadCard();
			}
		} catch (error) {
			if (inProcessSetter) {
				inProcessSetter(false);
			}
			setDataLoaded(true);
			if (dbg) LogMgr.myerr(this, `error while getting feed data: ${error}`);
		}
	};

	const fetchSummary = async () => {
		if (!userId || isLoading || isRefreshing || isNewPostAvailable || !isConnected) return;
		const newestTs = data.length > 0 ? data[0]["p.modif_ts"] : 0;
		try {
			const { items } = await fetchFeed({
				...baseRequest(), ...extraMsgObjParams, newestTs, summary: "y",
			});
			setIsNewPostAvailable(items.length > 0);
		} catch (error) {
			if (dbg) LogMgr.myerr(this, `error while getting feed summary: ${error}`);
		}
	};

	useInterval(() => fetchSummary(), 350000);

	const fetchEmojiData = () => {
		if (emojiData && emojiData.length === 0) {
			UserActions.getEmoItems(setEmojiData);
		}
	};

	useEffect(() => {
		if (isConnected && dataToRender.length === 0) {
			fetchEmojiData();
			retreiveFilterTags();
			fetchFeedData(setIsLoading, OperationType.REPLACE);
		} else {
			setIsRefreshing(false);
		}
	}, [isConnected, userId]);

	const handleRefresh = () => {
		if (isLoading || isRefreshing) return;
		// setSuggConnCard([]);
		// setUserRatingCard([]);
		retreiveFilterTags();
		fetchFeedData(setIsRefreshing, OperationType.REPLACE);
	};

	const handleLoadMore = () => {
		if (isLoading || isRefreshing || isLastPage) return;
		fetchFeedData(setIsLoadMore, OperationType.APPEND);
	};

	const fetchNewPost = () => {
		if (isLoading) return;
		fetchFeedData(setIsLoadMore, OperationType.REPLACE);
	};

	const keyExtractor = (item: FeedItem) => item["p.postUuid"];

	const getNullSepIndex = () => {
		if (suggCommCard) {
			return 5;
		}
		return 4;
	};

	const renderSeparator = (leadingItem: FeedItem, index: number) => {
		if (
			leadingItem["p.postType"] === PostType.UserRatingCard
			|| leadingItem["p.postType"] === PostType.SugConPost
			|| leadingItem.id === -1
			|| leadingItem["p.postType"] === PostType.NoInternet
			|| (index === getNullSepIndex() && userRatingCard && userRatingCard.length > 0)
			|| (index === getNullSepIndex() && suggConnCard && suggConnCard.length > 0)
		) {
			return null;
		}
		return <div style={{ height: 10 }} className="bg-background-tertiary" />;
	};

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		);
	};

	const getContainerHeight = () => {
		let containerHeight = height;
		if (isMobile && !isTablet) {
			// for mobile
			containerHeight -= (HOME_FEED_TAB_HEADER_HEIGHT + HEADER_HEIGHT + BOTTOM_NAV_HEIGHT);
		} else {
			// for desktop or tablet
			containerHeight -= (HOME_FEED_TAB_HEADER_HEIGHT + HEADER_HEIGHT);
		}
		return containerHeight;
	};

	const NoItemsToLoad = () => (
		<div
			className="w-full flex flex-col h-screen items-center py-32"
			style={{
				height: height ? getContainerHeight() : "100%",
			}}
		>
			<FeedNoPost />
			<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
				{strings("StreamProfile.no_post_message")}
			</span>
		</div>
	);

	const NoConnection = () => (
		<div className="w-full flex flex-col justify-center items-center py-10">
			<StreamNoNetworkIcon />
			<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
				{strings("StreamProfile.no_internet_msg")}
			</span>
		</div>
	);

	const navigateToUser = (id: string, username: string, mode: string, egousername: string, targetText: string) => {
		AppUtils.navigateToUserProfile(
			id,
			username,
			mode,
			isCredoMode,
			userProfile,
			navigate,
			egousername,
			YesNoOptions.NO,
			{
				title: targetText,
			},
		);
	};

	const navigateToEngage = () => {
		AppUtils.handleDownloadAppModal(true);
	};

	const onRemoveUxpCard = (data: any) => {
		const hasRated = data.isCardRated;
		/**
		 * Once the card is removed from the list it should be also removed from the atom
		 * so that we will have correct set of cards always
		 * */
		if (!hasRated) {
			const updateCards = userRatingCard
				.filter((u: any) => !(u["p.postUuid"] === data.card["p.postUuid"] && u.pcodes === data.card.pcodes));
			setUserRatingCard(updateCards);
		}
		const request = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: AppMode.CREDO,
			itemType: ItemType.USER,
			action: hasRated ? ExpertiseActions.RATE : ExpertiseActions.DISMISS,
			xpType: ExpertiseTypes.UXP_EXPT,
			uxpcode: hasRated ? data.tag : Object.keys(data.tag)?.[0],
			rating: hasRated ? data.rating || 0 : 0,
			isCredoNetRating: YesNoOptions.NO,
			dest_mode: AppMode.EGO,
			dest_prof_id: data?.profId || "",
		};
		UserActions.rateUser4XpItem(request);
		if (hasRated) {
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.rateUserExpertiseCardTag,
			});
		} else {
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.rateUserExpertiseCardTagDismiss,
			});
		}
	};

	const onRemoveSuggCard = (data: any) => {
		if (data.isConnReqSent) {
			/**
			 * Once the user is requested, it should be also updated in the atom
			 * so that we will have correct set of cards always
			 * */
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.cardSuggConnConnect,
			});
			const updatedConnCards = suggConnCard
				.map((u: any) => (u["p.dest_prof_id"] === data.profId ? { ...u, "p.isRequested": true } : u));
			setSuggConnCard(updatedConnCards);
			// sending connection request
			const connRequest = {
				user_id: userId ?? "",
				mode: data.mode,
				prof_id: data.profId,
				action: "r",
				relType: RelationTypeStatus.CONNECT_USER,
			};
			UserActions.manageRelation4User(connRequest);
		} else {
			/**
			 * Once the card is removed from the list it should be also removed from the atom
			 * so that we will have correct set of cards always
			 * */
			EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
				name: AnalyticEventsConst.cardSuggConnDismiss,
			});
			const updatedConnCards = suggConnCard.filter((u: any) => u["p.dest_prof_id"] !== data.profId);
			setSuggConnCard(updatedConnCards);
			// dismissing suggestion card as user press on X icon
			const removeRequest = {
				user_id: userId ?? "",
				mode: AppMode.EGO,
				dest_mode: data.mode,
				dest_prof_id: data.profId,
				action: "x",
			};
			UserActions.mngSugconItem(removeRequest);
		}
	};

	const handleOnDeletePost = (deletedPost: string) => {
		const newData = data
			.filter((item) => item["p.postUuid"] !== deletedPost)
			.map((item) => (item["p.shared_postUuid"] === deletedPost ? { ...item, "p.origDeleted": "y" } : item));

		setData(newData);
	};

	const onSavePost = (post: any) => {
		if (extraMsgObjParams?.exca) {
			const oldData = data.filter((item) => item["p.postUuid"] !== post?.postUuid);
			const oldPost = data.find((item) => item["p.postUuid"] === post?.postUuid);
			const feedPost = postToFeed(post, oldPost ?? {}, userProfile, isCredoMode) as any;
			if (!isNil(feedPost["p.mediaObj_Link_ImgUrl"]) && feedPost["p.mediaObj_Link_ImgUrl"].uri) {
				feedPost["p.mediaObj_Link_ImgUrl"] = feedPost["p.mediaObj_Link_ImgUrl"].uri;
			}
			if (!isNil(post.sortedPostTags)) {
				feedPost.sortedPostTags = post.sortedPostTags;
			}
			if (!isNil(post.authorTags)) {
				feedPost.authorTags = post.authorTags;
			}
			if (isNil(post.emoCode) && !isNil(post.emoCountA)) {
				feedPost.emoCode = post.emoCode;
				feedPost.emoCountA = post.emoCountA;
				feedPost.nb_of_emo = post?.nb_of_emo;
			}
			setData([feedPost as FeedItem, ...oldData]);
		}
	};

	const handleRefreshEvent = () => {
		handleRefresh();
		setClickedOnTab(true);
	};

	const handleFeedFilter = () => {
		if (isConnected) {
			fetchFeedData(setIsLoading, OperationType.REPLACE);
		}
	};

	useEvtMgrListener(EventConst.onDeletePost, handleOnDeletePost);
	useEvtMgrListener(EventConst.handlePostFromFeed, onSavePost);
	useEvtMgrListener(`${EventConst.refreshHomeFeed}-${feedType}`, handleRefreshEvent);
	useEvtMgrListener(`${EventConst.feedFilter}_${feedType}`, handleFeedFilter);

	const onDeletePost = (post: FeedItem) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.postDelete,
		});
		const request: DeletePostRequestObject = {
			user_id: userId ?? "",
			mode: cu.getAppMode(),
			itemType: DeleteItemType.Post,
			postUuid: post?.["p.postUuid"] ?? "",
		};
		deletePost(request);
	};

	const renderPostItem = ({ item, index }: { item: FeedItem; index: number }) => {
		if (item.id === -1) {
			return (
				<div
					className="relative top-1 bg-background-tertiary pt-px"
					key={item.id}
				>
					<PendingTasks />
				</div>
			);
		}
		const postType = item["p.postType"];
		switch (postType) {
			case PostType.RewardsPost:
				return (
					<div key={keyExtractor(item)}>
						<RewardsNotification
							postData={item}
							handleClick={navigateToEngage}
							messages={REWARD_MESSAGES}
						/>
						{renderSeparator(item, index)}
					</div>
				);
			case PostType.Post:
				return (
					<div key={keyExtractor(item)}>
						<PostWithDirectConnComments
							item={item}
							onDeletePost={onDeletePost}
						/>
						{renderSeparator(item, index)}
					</div>
				);

			case PostType.UserRatingCard:
				return (
					<UxpRatingCard
						key="uxp-rating-cards"
						item={item}
						switchProps={{
							size: CredoSwitchSize.MEDIUM,
							isCredo: isCredoMode,
							onModeChange: changeMode,
							userProfile,
							uCredibility: 60,
						}}
						width={tabWidth}
						messages={{
							SwitchToCredoModeToRate: strings("PostView.switchToCredoModeToRate"),
							RateYourConnExpertise: strings("PostView.rate_your_conn_expertise"),
							Dismiss: strings("HomePage.dismiss"),
							tagMessages: {
								...TAG_MESSAGES,
								rating_info: strings("TagMessages.rating_info_user"),
								rate_this_item: strings("TagMessages.rate_this_user"),
							},
						}}
						handleClickUser={navigateToUser}
						onRemoveCard={onRemoveUxpCard}
						tagInfoProps={{
							onPressCredoGraphText: AppUtils.showCredoGraph,
							onPressTagInfoBubble: AppUtils.showRatingTagTutorial,
						}}
					/>
				);
			case PostType.SugConPost:
				return (
					<SuggestedConnCard
						key="suggest-connection-cards"
						item={item}
						switchProps={{
							size: CredoSwitchSize.SMALL,
							isCredo: isCredoMode,
							onModeChange: changeMode,
							userProfile,
							uCredibility: 60,
						}}
						width={tabWidth}
						messages={{
							IsOnCredo: strings("PostView.is_on_credo"),
							SwitchModeToConnect: strings("PostView.switch_mode_to_connect"),
							Connect: strings("PostView.connect"),
							Requested: strings("PostView.requested"),
							Dismiss: strings("HomePage.dismiss"),
							SuggestedConnection: strings("PostView.suggested_connection"),
						}}
						handleClickUser={navigateToUser}
						onRemoveCard={onRemoveSuggCard}
					/>
				);
			case PostType.ExploreComm:
				return (
					<ExploreCommSection
						key="explore-com-section"
						width={tabWidth}
						items={item as any}
						removeStreamCard={removeStreamCard}
					/>
				);
			default:
				return null;
		}
	};

	const renderExplainComponent = () => {
		if (explainMessage?.message) {
			return (
				<ExplainComponent
					// @ts-ignore
					message={explainMessage?.message as string}
					// @ts-ignore
					onClickClose={explainMessage?.onClick}
				/>
			);
		}
		return null;
	};

	const renderNoData = () => (
		!isConnected
			? <NoConnection />
			: <NoItemsToLoad />
	);

	const renderRefreshLoader = useCallback(() => (
		<div
			style={{ width: tabWidth }}
			className={classNames(
				"flex items-center justify-center fixed -mt-10",
				isRefreshing ? "animating-move-down" : "invisible-delay-animation",
				"z-[19]",
			)}
		>
			<div className="flex items-center justify-center p-2 bg-background-tertiary rounded-full shadow-xl m-px">
				<Spinner
					leaf_shape={SpinnerLeafType.CIRCLES}
					width="20px"
					height="20px"
					leaf_fill="var(--primary)"
				/>
			</div>
		</div>
	), [isRefreshing, tabWidth]);

	const getTopPadding = () => {
		if (cu.getGlobalVar(Consts.isBottomNavVisible)) {
			return "-mt-3";
		}
		if (!isLoading) {
			return explainMessage?.message ? "pt-5" : "pt-7";
		}
		return "";
	};

	if (isLoading) {
		return (
			<div
				style={{
					width: tabWidth,
					height: height ? getContainerHeight() + 36 : "100%",	// 36 is top margin given to feed container
					overflow: "hidden",
				}}
			>
				<PostFeedSkeleton wrapperClasses={cu.getGlobalVar(Consts.isBottomNavVisible) ? "" : "mt-14"} />
			</div>
		);
	}

	const onScroll = () => {
		if (commentBeenReplied?.isFocused) {
			setCommentBeenReplied({
				commentUuid: commentBeenReplied?.commentUuid ?? "",
				postUuid: commentBeenReplied?.postUuid ?? "",
				isFocused: false,
			});
		}
	};

	return (
		<div
			className={classNames(
				"w-full",
				getTopPadding(),
			)}
		>
			<div
				style={{ width: tabWidth }}
				className={classNames(
					"flex items-center justify-center fixed -mt-10",
					isNewPostAvailable ? "animating-move-down visible" : "animating-move-up invisible",
					"z-[19]",
				)}
			>
				<SecondaryButton
					size={ButtonSize.SMALL}
					label={strings("HomePage.new_posts")}
					handleClick={fetchNewPost}
					buttonClassNames="shadow-new-post-button bg-background"
				/>
			</div>
			{clickedOnTab && renderRefreshLoader()}
			{dataToRender && dataToRender.length > 0
				? (
					<>
						{!explainMessage?.message && <div className="h-2 bg-background w-full" />}
						{renderExplainComponent()}
						<InfiniteScroll
							dataLength={dataToRender.length}
							next={handleLoadMore}
							hasMore
							loader={renderFooter()}
							className="!overflow-visible"
							onScroll={onScroll}
						>
							{dataToRender.map((item: any, idx: any) => renderPostItem({ item, index: idx }))}
						</InfiniteScroll>
					</>
				)
				: renderNoData()}
		</div>
	);
};

export default HomeFeed;
