import React from "react";
import { userWalletAtom } from "@credo/store";
import { useAtomValue } from "jotai";
import { isWideCalc, SvgIcon, WEB_SIZE_PROP_BUTTON } from "../../../common";
import { WalletIcon } from "../../../assets/icons";
import { BalanceItemWrapper } from "./BalanceItemWrapper";

interface WalletBalanceProps {
	isWide: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const WalletBalance: React.FC<WalletBalanceProps> = ({ isWide = false }: WalletBalanceProps) => {
	const userBalance = useAtomValue(userWalletAtom);
	return (
		<BalanceItemWrapper
			id="user-wallet-balance"
			className="w-20"
			isWide={isWide}
		>
			<SvgIcon
				icon={WalletIcon}
				width={isWideCalc(isWide, 14, WEB_SIZE_PROP_BUTTON)}
				className="mb-px"
			/>
			<span
				className="text-xs text-tag-green align-right"
				data-testid="user-wallet-balance-text"
			>
				{userBalance}
			</span>
		</BalanceItemWrapper>
	);
};
