import {
	AppMode,
	BoostActionType,
	BoostActionUpdateResponse, Context,
	EvtMgr,
	onActionsParam, PermissionActions,
	PostBoostInfo,
	ResponseCode,
	SessMgr,
	UserProfile,
	useWindowSize,
} from "@credo/utilities";
import { BoostTrayProps, TABLET_WIDTH, SnackBarTypeOptions } from "@credo/ui-components";
import {
	useCallback, useMemo, useRef, useState,
} from "react";
import { useSetAtom } from "jotai";
import { GlobalState, userWalletAtom } from "@credo/store";
import { GenericBoostResponse } from "../../services/actions/boost/types";
import { CredoBoost } from "../../services/actions/boost";
import { Consts, EventConst } from "../Consts";
import {
	AppUtils, Community, ContextConst, Post, useAuthManager, User,
} from "../index";
import { strings } from "../../i18n/config";
import { ExpertiseActions } from "../types";
import { FeedItem, ratePostByUser } from "../../modules/home/requests";

interface useBoostTrayPropsParams {
	item: FeedItem;
	isSessionAuth: boolean;
	userProfile: UserProfile | null
}

interface FetchActionParam {
	type: BoostActionType.FETCH_DATA,
	message: {
		tag: string;
	}
}

interface IncrementActionParam {
	type: BoostActionType.INCREMENT,
	message: {
		value: number;
	}
}

// eslint-disable-next-line import/prefer-default-export
export const useBoostTrayProps = (params: useBoostTrayPropsParams): BoostTrayProps => {
	const {
		item,
		userProfile,
		isSessionAuth,
	} = params;

	const { authManager } = useAuthManager();
	const { width } = useWindowSize();

	const isWide = useMemo(() => width >= TABLET_WIDTH, [width]);

	const [postBoostInfo, setPostBoostInfo] = useState<PostBoostInfo | undefined>(undefined);
	const setWalletBalance = useSetAtom(userWalletAtom);

	const postUuid = useMemo(() => item["p.postUuid"], [item]);

	const tagRef = useRef<string | null>(null);

	const isBoostAllowed = useMemo(() => authManager.isActionAllowed(
		PermissionActions.BOOST,
		new Post(
			postUuid,
			new User(
				item["p.prof_id"],
				item["p.egousername"] ?? item["p.username"],
				isSessionAuth,
			),
			new Community(
				item["s.streamId"],
				item["s.struname"],
			),
		),
		new Context()
			.add(ContextConst.actor, {
				...userProfile,
				// using GlobalState to avoid re-renders when boost updates the wallet balance
				[ContextConst.vaultBalance]: GlobalState.User.walletBalance.get(),
			} as unknown as User),
	), [authManager, isSessionAuth, item, userProfile, GlobalState.User.walletBalance.get()]);

	const fetchData = useCallback((message: FetchActionParam) => {
		tagRef.current = message.message.tag;
		const request = {
			postUuid,
			tag: message.message.tag,
		};

		const successCallback = (response: GenericBoostResponse<PostBoostInfo[]>) => {
			setPostBoostInfo(response.items[0]);
		};

		const errorCallback = () => {};

		CredoBoost.getBoostPostInfo(
			request,
			successCallback,
			errorCallback,
		);
	}, [postUuid]);

	const handlePromise = () => new Promise((resolve, reject) => {
		const handleMessage = (
			message: BoostActionUpdateResponse,
		) => {
			if (message.retcd === ResponseCode.OK) {
				resolve(message);
			} else {
				reject(Error(`Not able to boost postUuid: ${postUuid}, error: ${message.retcd}`));
			}
			// Remove listeners since no need of keeping them active.
			// If we need it again, we will add them in the next request
			EvtMgr
				.getInstance(`${EventConst.boostPostActionUpdate}-${postUuid}`)
				.removeAllListeners();
		};
		EvtMgr
			.getInstance(`${EventConst.boostPostActionUpdate}-${postUuid}`)
			.addListener(handleMessage);
	});

	const handleIncrement = useCallback(async (param: IncrementActionParam) => {
		if (param?.message?.value && tagRef.current) {
			setWalletBalance((prev: number) => {
				if (param.message.value <= prev) {
					return prev - param.message.value;
				}
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: strings("BoostTray.error.insufficient_funds"),
				});
				return prev;
			});
			const request = {
				user_id: SessMgr.getFromSession(Consts.user_id) ?? "",
				uxpcode: tagRef.current,
				rating: 1,
				mode: AppMode.CREDO,
				action: ExpertiseActions.RATE,
				postUuid,
				boostEp: param.message.value,
			};
			ratePostByUser(request);
			return handlePromise();
		}
		return Promise.reject(Error("No Value sent"));
	}, [postUuid]);

	const handleActions = (param: onActionsParam) => {
		switch (param.type) {
			case BoostActionType.FETCH_DATA:
				return fetchData(param);
			case BoostActionType.INCREMENT:
				return handleIncrement(param);
			default: return null;
		}
	};

	return {
		isWide,
		postBoostInfo,
		topBoosters: postBoostInfo?.extraInfo?.topBoosters || [],
		onActions: handleActions,
		authorisation: {
			isBoostAllowed,
		},
		ranking: {
			currentRanking: postBoostInfo?.extraInfo?.ranking || 0,
			prevRanking: postBoostInfo?.extraInfo?.prevRanking || 0,
		},
		totalBoost: {
			prev: postBoostInfo?.extraInfo?.prevTotalBoost || 0,
			current: postBoostInfo?.boost_total || 0,
		},
		alwaysOpen: true,
		messages: {
			boostSuccess: strings("BoostTray.success.boost_submitted"),
		},
	};
};
