import React, {
	useEffect, useMemo, useRef, useState,
} from "react";
import { classNames } from "../common";
import Styles from "./SliderScroll.module.css";

interface SliderScrollProps {
	children: any;
	wrapperClassName?: string;
	isVertical?: boolean;
}

interface Arrows {
	/**
	 * Left arrow. If vertical it will be Top arrow
	 * */
	showLeft: boolean;
	/**
	 * Right arrow. If vertical it will be Bottom arrow
	 * */
	showRight: boolean;
}

/**
 * Idea is to create a carousal which will show the left
 * and right arrows when there is something to the left
 * or right respectively. On click on those arrows the
 * list should scroll.
 *
 * TODO:
 * 1. Render arrows
 * */

// eslint-disable-next-line import/prefer-default-export
export const SliderScroll: React.FC<SliderScrollProps> = (props: SliderScrollProps) => {
	const {
		children,
		wrapperClassName,
		isVertical,
	} = props;
	const uniqueKey = useMemo(() => `slider-scroll-${Math.random()}`, []);

	const [arrows, setArrows] = useState<Arrows>({
		showLeft: false,
		showRight: false,
	});
	const arrowStatusRef = useRef<Arrows>({
		showLeft: false,
		showRight: false,
	});

	useEffect(() => {
		if (document) {
			const element = document.getElementById(uniqueKey);
			if (element) {
				if (isVertical) {
					if (element.scrollTop + element.offsetHeight < element.scrollHeight) {
						setArrows({
							showRight: element.scrollTop + element.offsetHeight < element.scrollHeight,
							showLeft: element.scrollTop !== 0,
						});
					}
					element.onscroll = () => {
						const arrow = {
							showRight: element.scrollTop + element.offsetHeight < element.scrollHeight,
							showLeft: element.scrollTop !== 0,
						};
						if (JSON.stringify(arrow) !== JSON.stringify(arrowStatusRef.current)) {
							setArrows(arrow);
							arrowStatusRef.current = arrow;
						}
					};
				} else {
					if (element.scrollLeft + element.offsetWidth < element.scrollWidth) {
						setArrows({
							showRight: element.scrollLeft + element.offsetWidth < element.scrollWidth,
							showLeft: element.scrollLeft !== 0,
						});
					}
					element.onscroll = () => {
						const arrow = {
							showRight: element.scrollLeft + element.offsetWidth < element.scrollWidth,
							showLeft: element.scrollLeft !== 0,
						};
						if (JSON.stringify(arrow) !== JSON.stringify(arrowStatusRef.current)) {
							setArrows(arrow);
							arrowStatusRef.current = arrow;
						}
					};
				}
			}
		}
	}, [isVertical]);

	const handleRightArrowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		const element = document.getElementById(uniqueKey);
		if (element) {
			if (isVertical) {
				element.scrollTo({
					top: (element.offsetHeight * 0.20) + element.scrollTop,
				});
			} else {
				element.scrollTo({
					left: (element.offsetWidth * 0.20) + element.scrollLeft,
				});
			}
		}
	};

	const handleLeftArrowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		const element = document.getElementById(uniqueKey);
		if (element) {
			if (isVertical) {
				element.scrollTo({
					top: element.scrollTop - (element.offsetHeight * 0.20),
				});
			} else {
				element.scrollTo({
					top: element.scrollLeft - (element.offsetWidth * 0.20),
				});
			}
		}
	};

	return (
		<div
			key={uniqueKey}
			id={uniqueKey}
			className={classNames(
				"max-w-full overflow-auto",
				wrapperClassName,
				Styles.noScrollbar,
			)}
		>
			{arrows.showLeft && (
				<button
					type="button"
					className={classNames(
						"absolute z-[12] text-white outline-none",
						"flex justify-center items-center",
						isVertical ? "top-0 w-full left-0" : "left-0 h-full",
						isVertical ? Styles.topArrowBg : Styles.leftArrowBg,
					)}
					onClick={handleLeftArrowClick}
				>
					<div
						className={classNames(
							Styles.triangle,
							isVertical ? "-rotate-90" : "rotate-180",
						)}
					/>
				</button>
			)}
			{children}
			{arrows.showRight && (
				<button
					type="button"
					className={classNames(
						"absolute z-[11] text-white outline-none",
						"flex justify-center items-center",
						isVertical ? "bottom-0 left-0 w-full" : "right-0 h-full",
						isVertical ? Styles.bottomArrowBg : Styles.rightArrowBg,
					)}
					onClick={handleRightArrowClick}
				>
					<div
						className={classNames(
							Styles.triangle,
							isVertical ? "rotate-90" : "",
						)}
					/>
				</button>
			)}
		</div>
	);
};

SliderScroll.defaultProps = {
	wrapperClassName: "",
	isVertical: false,
};
