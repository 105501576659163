import React from "react";
import { BackIcon, classNames, SvgIcon } from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "react-use";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cu } from "@credo/utilities";
import { HelmetComponent } from "../../../components/HelmetComponent";
import { strings } from "../../../i18n/config";
import { MenuItem, supportMenu } from "../constants";
import { DEV_MODE } from "../../../config/constants";
import { PROFILE_PAGE, SETTINGS_PAGE } from "../../../routes/constants";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";
import { Consts } from "../../../utils";

// eslint-disable-next-line import/prefer-default-export
export const SettingsSupportPage: React.FC = () => {
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const location = useLocation();
	const isInMobile = isMobile && width < 768;

	useSetAppHeader({
		title: strings("AppHeader.support"),
	});

	const renderListItem = (item: MenuItem) => {
		const {
			url,
			title,
			id,
			devOnly,
			isExternal,
			onClick,
		} = item;

		if (devOnly && !DEV_MODE) return null;

		const baseStyleClasses = classNames(
			"bg-transparent w-full h-12",
			isInMobile ? "px-3" : "",
		);

		const renderItemContent = () => (
			<div className="flex justify-start items-center w-full">
				<div className="flex items-center justify-start w-full">
					<div
						className={classNames(
							"border-b border-post-bar-border w-full h-12",
							"flex justify-start items-center",
							"ml-2",
						)}
					>
						<span className="text-md">
							{title}
						</span>
					</div>
				</div>
			</div>
		);

		if (url) {
			if (isExternal) {
				return (
					<div
						className={classNames(
							"flex",
							baseStyleClasses,
						)}
					>
						<a
							rel="noreferrer"
							className={classNames(
								"flex",
								"bg-transparent w-full h-12",
							)}
							target="_blank"
							key={id}
							href={url}
						>
							{renderItemContent()}
						</a>
					</div>
				);
			}

			return (
				<div
					className={classNames(
						"flex",
						baseStyleClasses,
					)}
				>
					<Link
						className={classNames(
							"flex",
							"bg-transparent w-full h-12",
						)}
						key={id}
						to={url}
					>
						{renderItemContent()}
					</Link>
				</div>
			);
		}

		if (onClick) {
			return (
				<button
					key={id}
					type="button"
					className={classNames(
						baseStyleClasses,
					)}
					onClick={onClick}
				>
					{renderItemContent()}
				</button>
			);
		}

		return null;
	};

	const renderList = () => (
		<div>
			{supportMenu.map((item: MenuItem) => renderListItem(item))}
		</div>
	);

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-5",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<HelmetComponent
				title={strings("SettingsSupportPage.title")}
				url={location?.pathname}
			/>
			<div className="flex flex-col">
				{/* <div className="flex flex-row items-center">
					<div className="p-1 cursor-pointer -m-1" onClick={() => navigate(`${PROFILE_PAGE}${SETTINGS_PAGE}`)} aria-hidden>
						<SvgIcon
							icon={BackIcon}
							className="sm:ml-1 sm:mr-3 2xs:mr-0 2xs:ml-4 curso"
							color="var(--text-base)"
						/>
					</div>
					<h4
						className={classNames(
							"text-md font-normal text-basic",
							isInMobile ? "px-3" : "",
						)}
					>
						{strings("SettingsSupportPage.title")}
					</h4>
				</div> */}
				<div className="block pt-4">
					{renderList()}
				</div>
			</div>
		</div>
	);
};
