import {
	AppMode, cu,
} from "@credo/utilities";
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";
import { FeedItem } from "../../modules/home/requests";
import {
	CommentItem, CommentType, FeedCommentItem, NullComment, PostDetailCommentItem, SubCommentItem,
} from "./types";
import { AppUtils } from "..";

export const isStreamFeedCommentsNull = (comments: any) => {
	if (isNil(comments) || comments.length === 0) {
		return true;
	}
	const commentInfo = comments[0];
	if (!commentInfo.c_commentUuid && !commentInfo.p_commentUuid) {
		return true;
	}
	return false;
};

export const isHomeFeedCommentsNull = (comments: any) => {
	if (isNil(comments) || comments.length === 0) {
		return true;
	}
	return false;
};

// feed main comment re-structure
export function getMainComment(commentInfo: any) {
	const mainComment: CommentItem = JSON.parse(JSON.stringify(NullComment));
	let isMainCommentPresent = false;
	if (cu.isSet(commentInfo.c_commentUuid)) {
		isMainCommentPresent = true;
		mainComment.commentUuid = commentInfo.c_commentUuid;
		mainComment.profId = commentInfo.c_prof_id;
		mainComment.commentText = commentInfo.c_commentText;
		mainComment.profilePicRelUrl = commentInfo.c_profilePicRelUrl;
		mainComment.creatTs = commentInfo.c_creat_ts;
		mainComment.username = commentInfo.c_username;
		mainComment.mode = commentInfo.c_mode;
		mainComment.firstName = commentInfo.c_firstName;
		mainComment.lastName = commentInfo.c_lastName;
		mainComment.edit = commentInfo.c_edit;
		mainComment.modifTs = commentInfo.c_modif_ts;
		mainComment.hidden = commentInfo.c_hidden;
		mainComment.isRestricted = commentInfo.c_isRestricted;
		mainComment.s_img_bucketRelativeRefPath0 = commentInfo.c_img_bucketRelativeRefPath0;
		mainComment.nb_of_emo = commentInfo.c_nb_of_c_emo;
		mainComment.emoCode = commentInfo.c_emoCode4Comments;
		mainComment.emoCountA = commentInfo.c_emoCnt;
		mainComment.authorTags = AppUtils.removeRatingFromCredoPostAuthorTags(commentInfo.c_commentAuthorTags, commentInfo.c_mode);
		mainComment.nb_of_subco = commentInfo.c_no_of_subco ?? 0;
	}
	return {
		isMainCommentPresent,
		mainComment,
	};
}

// feed main parent comment re-structure
export function getParentComment(commentInfo: any) {
	const parentComment: CommentItem = JSON.parse(JSON.stringify(NullComment));
	let isParentCommentPresent = false;
	if (cu.isSet(commentInfo.p_commentUuid)) {
		isParentCommentPresent = true;
		parentComment.commentUuid = commentInfo.p_commentUuid;
		parentComment.profId = commentInfo.p_prof_id;
		parentComment.commentText = commentInfo.p_commentText;
		parentComment.profilePicRelUrl = commentInfo.p_profilePicRelUrl;
		parentComment.creatTs = commentInfo.p_creat_ts;
		parentComment.username = commentInfo.p_username;
		parentComment.mode = commentInfo.p_mode;
		parentComment.firstName = commentInfo.p_firstName;
		parentComment.lastName = commentInfo.p_lastName;
		parentComment.edit = commentInfo.p_edit;
		parentComment.modifTs = commentInfo.p_modif_ts;
		parentComment.hidden = commentInfo.p_hidden;
		parentComment.isRestricted = commentInfo.p_isRestricted;
		parentComment.s_img_bucketRelativeRefPath0 = commentInfo.p_img_bucketRelativeRefPath0;
		parentComment.nb_of_emo = commentInfo.p_nb_of_pc_emo;
		parentComment.emoCode = commentInfo.p_emoCode4ParentComments;
		parentComment.emoCountA = commentInfo.p_emoCnt;
		parentComment.authorTags = AppUtils.removeRatingFromCredoPostAuthorTags(commentInfo.p_commentAuthorTags, commentInfo.p_mode);
		parentComment.nb_of_subco = commentInfo.p_no_of_subco ?? 0;
	}
	return {
		isParentCommentPresent,
		parentComment,
	};
}

export function getSubComment(commentInfo: any) {
	const subComment: CommentItem = JSON.parse(JSON.stringify(NullComment));
	let isSubCommentPresent = false;
	if (cu.isSet(commentInfo.s_commentUuid)) {
		isSubCommentPresent = true;
		subComment.commentUuid = commentInfo.s_commentUuid;
		subComment.profId = commentInfo.s_prof_id;
		subComment.commentText = commentInfo.s_commentText;
		subComment.profilePicRelUrl = commentInfo.s_profilePicRelUrl;
		subComment.creatTs = commentInfo.s_creat_ts;
		subComment.username = commentInfo.s_username;
		subComment.mode = commentInfo.s_mode;
		subComment.firstName = commentInfo.s_firstName;
		subComment.lastName = commentInfo.s_lastName;
		subComment.edit = commentInfo.s_edit;
		subComment.modifTs = commentInfo.s_modif_ts;
		subComment.hidden = commentInfo.s_hidden;
		subComment.isRestricted = commentInfo.s_isRestricted;
		subComment.s_img_bucketRelativeRefPath0 = commentInfo.s_img_bucketRelativeRefPath0;
		subComment.nb_of_emo = commentInfo.nb_of_emo ?? commentInfo.s_nb_of_sc_emo;
		subComment.emoCode = commentInfo.emoCode ?? commentInfo.s_emoCode4SubComments;
		subComment.emoCountA = commentInfo.emoCountA ?? commentInfo.s_emoCnt;
		subComment.authorTags = AppUtils.removeRatingFromCredoPostAuthorTags(commentInfo.s_commentAuthorTags, commentInfo.s_mode);
	}
	return {
		isSubCommentPresent,
		subComment,
	};
}

export function formatFeedComments(comments: FeedCommentItem[]) {
	const commentA: CommentItem[] = [];
	if (!isHomeFeedCommentsNull(comments)) {
		const { isMainCommentPresent, mainComment } = getMainComment(comments[0]);
		const { isSubCommentPresent, subComment } = getSubComment(comments[0]);
		const { isParentCommentPresent, parentComment } = getParentComment(comments[0]);
		let isCommentProcessed = false;
		if (isMainCommentPresent) {
			if (isParentCommentPresent) {
				isCommentProcessed = mainComment.commentUuid === parentComment.commentUuid;
				const tempComment: CommentItem = isCommentProcessed ? { ...mainComment } : { ...parentComment };
				tempComment.isCommentPending = false;
				if (isSubCommentPresent) {
					subComment.isCommentPending = false;
					tempComment.subcomms = [subComment];
				}
				commentA.push(tempComment);
			}
			if (!isCommentProcessed) {
				mainComment.subcomms = [];
				mainComment.isCommentPending = false;
				commentA.push(mainComment);
			}
		}
	}
	return commentA;
}

export function formatTimeBasedFeedComments(comments: FeedCommentItem[]) {
	const commentA: CommentItem[] = [];
	if (!isStreamFeedCommentsNull(comments)) {
		const processedCommentuuid: {[key: string]: boolean} = {};
		comments.forEach((item: FeedCommentItem) => {
			const { isMainCommentPresent, mainComment } = getMainComment(item);
			const { isSubCommentPresent, subComment } = getSubComment(item);
			if (isMainCommentPresent) {
				if (mainComment.commentUuid && !processedCommentuuid[mainComment.commentUuid]) {
					const tempComment: CommentItem = { ...mainComment, isCommentPending: false, subcomms: [] };
					processedCommentuuid[mainComment.commentUuid] = true;
					if (isSubCommentPresent) {
						subComment.isCommentPending = false;
						tempComment.subcomms?.push(subComment);
					}
					commentA.unshift(tempComment);
				} else if (isSubCommentPresent) {
					subComment.isCommentPending = false;
					const index = commentA.findIndex((comment) => comment.commentUuid === mainComment.commentUuid);
					if (index !== -1) {
						commentA[index].subcomms?.unshift(subComment);
					}
				}
			}
		});
	}
	return commentA;
}

// Post details parent comment formatting
export function getParentPostDetailComment(commentItem: any) {
	const comment: CommentItem = JSON.parse(JSON.stringify(NullComment));
	if (cu.isSet(commentItem.c_commentUuid)) {
		comment.commentUuid = commentItem.c_commentUuid;
		comment.profId = commentItem.c_prof_id;
		comment.commentText = commentItem.c_commentText;
		comment.profilePicRelUrl = commentItem.c_profilePicRelUrl;
		comment.creatTs = commentItem.c_creat_ts;
		comment.username = commentItem.c_username;
		comment.mode = commentItem.c_mode;
		comment.firstName = commentItem.c_firstName;
		comment.lastName = commentItem.c_lastName;
		comment.edit = commentItem.c_edit;
		comment.modifTs = commentItem.c_modif_ts;
		comment.hidden = commentItem.c_hidden;
		comment.isRestricted = commentItem.c_isRestricted;
		comment.s_img_bucketRelativeRefPath0 = commentItem.c_img_bucketRelativeRefPath0;
	} else {
		comment.commentText = commentItem.commentText;
		comment.commentUuid = commentItem.commentUuid;
		comment.hidden = commentItem.hidden;
		comment.isRestricted = commentItem.isRestricted;
		comment.edit = commentItem.c_edit;
		comment.creatTs = commentItem.creat_ts;
		comment.modifTs = commentItem.modif_ts;
		comment.username = (commentItem.mode === AppMode.CREDO ? commentItem.username : commentItem.egousername) ?? "";
		comment.firstName = commentItem.firstName ?? "";
		comment.lastName = commentItem.lastName ?? "";
		comment.profId = commentItem.prof_id;
		comment.mode = commentItem.mode;
		comment.profilePicRelUrl = commentItem.profilePicRelUrl;
		comment.s_img_bucketRelativeRefPath0 = commentItem.s_img_bucketRelativeRefPath0;
	}
	return comment;
}

export function deleteComment(postData: FeedItem, commentData: any) {
	const postObj = cloneDeep(postData);
	const deletedCommentsId: { [key: string]: boolean } = {};
	const commentDetails = commentData.commentObj;
	let numOfCommentDeleted = 0;
	if (commentData.commentType === CommentType.MainComment) {
		const index = postObj.comments?.findIndex((comm) => comm.commentUuid === commentDetails.commentUuid);
		if (index !== -1) {
			if (commentData.isHiddenComment) {
				postObj.comments[index].hidden = commentData.isHiddenComment;
			} else {
				// @ts-ignore
				if (!deletedCommentsId[postObj.comments[index].commentUuid]) {
					// @ts-ignore
					deletedCommentsId[postObj.comments[index].commentUuid] = true;
					numOfCommentDeleted += 1;
				}
				postObj.comments.splice(index, 1);
			}
		}
	} else {
		// sub comment
		const parentCommentIndex = postObj?.comments?.findIndex((comm) => comm.commentUuid === commentDetails.parentCommentUuid);
		if (parentCommentIndex !== -1) {
			const parentComment = postObj.comments[parentCommentIndex];
			const subCommentIndex = parentComment?.subcomms?.findIndex((comm) => comm.commentUuid === commentDetails.commentUuid);
			if (subCommentIndex !== -1) {
				// @ts-ignore
				if (!deletedCommentsId[parentComment.subcomms[subCommentIndex].commentUuid]) {
					// @ts-ignore
					deletedCommentsId[parentComment.subcomms?.[subCommentIndex].commentUuid] = true;
					numOfCommentDeleted += 1;
				}
				postObj.comments[parentCommentIndex].subcomms?.splice(subCommentIndex ?? 0, 1);
			}
		}
	}
	postObj["p.nb_of_co"] = postObj["p.nb_of_co"] ? postObj["p.nb_of_co"] - numOfCommentDeleted : 0;
	return postObj;
}

export function saveCommentAndRemovePendingComments(
	postObj: FeedItem,
	commentItem: any,
) {
	const postData = { ...postObj };
	if (commentItem["pc.commentUuid"]) {
		// Subcomment
		if (postData?.comments?.length > 0) {
			const parentCommentIndex = postData.comments.findIndex((comm: CommentItem) => comm.commentUuid === commentItem["pc.commentUuid"]);
			if (parentCommentIndex !== -1) {
				const parentComment = postData.comments[parentCommentIndex];
				const subCommentIndex = parentComment?.subcomms?.findIndex((comm) => comm.commentUuid === commentItem["c.commentUuid"]);
				if (subCommentIndex !== -1) {
					// @ts-ignore
					postData.comments[parentCommentIndex].subcomms[subCommentIndex].isCommentPending = false;
				}
			}
		}
	} else {
		// main comment
		// eslint-disable-next-line no-lonely-if
		if (postData?.comments?.length > 0) {
			const index = postData.comments.findIndex((comm) => comm.commentUuid === commentItem["c.commentUuid"]);
			if (index !== -1) {
				postData.comments[index].isCommentPending = false;
			}
		}
	}
	return postData;
}

export function updateEmoCode(commentItem: CommentItem, data: any) {
	const item = commentItem;
	const emoCountA: any[] = [];
	let isEmoPresent = false;
	if (!item.emoCountA) {
		data.emoCode.forEach((a: any) => {
			if (cu.getAppMode() === a[1]) {
				emoCountA.push([a[0], data.nb_emocons]);
			}
		});
		item.emoCode = data.emoCode;
		item.emoCountA = emoCountA;
		item.nb_of_emo = data.nb_of_emo;
	} else {
		// @ts-ignore
		if (item.emoCountA && (item.emoCountA?.[0]?.[0] == null || item.emoCountA.length === 0)) {
			// new post having count blank
			data.emoCode.forEach((a: any) => {
				if (cu.getAppMode() === a[1]) {
					emoCountA.push([a[0], data.nb_emocons]);
				}
			});
			item.emoCode = data.emoCode;
			item.emoCountA = emoCountA;
			item.nb_of_emo = data.nb_of_emo + 1;
		} else if (!data.emoCode && data.prevEmoCode && item.emoCountA.length > 0) {
			// when user deselect emogi
			const index = item.emoCountA.findIndex((x: any) => x[0] === data.prevEmoCode);
			if (index !== -1) {
				// @ts-ignore
				item.emoCountA[index][1] = data.nb_emocons;
				item.emoCode = data.emoCode;
				item.nb_of_emo = data.nb_of_emo - 1;
			}
		} else if (!data.prevEmoCode && item.emoCountA.length > 0) {
			// when user react with new emoji
			const index = item.emoCountA.findIndex((x: any) => x[0] === data.emoCode);
			if (index === -1) {
				// @ts-ignore
				item.emoCountA.push([data.emoCode, data.nb_emocons]);
				item.emoCode = data.emoCode;
				item.nb_of_emo = data.nb_of_emo + 1;
			} else {
				// @ts-ignore
				item.emoCountA[index][1] = data.nb_emocons;
				item.emoCode = data.emoCode;
				item.nb_of_emo = data.nb_of_emo + 1;
			}
		} else if (data.prevEmoCode && item.emoCountA.length > 0) {
			// when user updating emoji
			item.emoCountA.forEach((a: any) => {
				data.prevEmoCode.forEach((prevc: any) => {
					if (cu.getAppMode() === prevc[1]) {
						if (prevc[0] === a[0] && a[1] > 0) {
							// decrementing prev emo count by 1
							// eslint-disable-next-line no-param-reassign
							a[1] -= 1;
						}
					}
				});
				data.emoCode.forEach((emoc: any) => {
					if (cu.getAppMode() === emoc[1]) {
						if (emoc[0] === a[0]) {
							// checking if emo is already reacted by another user
							isEmoPresent = true;
							// eslint-disable-next-line no-param-reassign
							a[1] = data.nb_emocons;
							item.emoCode = data.emoCode;
						}
					}
				});
			});
			if (!isEmoPresent) {
				// cheking if emo is not reacted by any user
				data.emoCode.forEach((a: any) => {
					if (cu.getAppMode() === a[1]) {
						// @ts-ignore
						item.emoCountA.push([a[0], data.nb_emocons]);
					}
				});
				item.emoCode = data.emoCode;
			}
		}
		item.nb_of_emo = data.nb_of_emo;
	}
	return item;
}

export function formatPostDetailSubComment(subcomments: SubCommentItem[]) {
	const subCommentA: CommentItem[] = [];
	if (subcomments?.length > 0) {
		subcomments.forEach((item) => {
			const { isSubCommentPresent, subComment } = getSubComment(item);
			if (isSubCommentPresent) {
				subCommentA.push(subComment);
			}
		});
	}
	return subCommentA;
}

export function formatPostDetailComments(comments: PostDetailCommentItem[]) {
	let commentA: CommentItem[] = [];
	if (comments?.length > 0) {
		// @ts-ignore
		commentA = comments.map((item) => ({
			...item,
			creatTs: item.creat_ts,
			edit: item.c_edit,
			modifTs: item.modif_ts,
			nb_of_emo: item.nb_of_c_emo,
			emoCountA: item.c_emoCnt,
			emoCode: item.emoCode4Comments,
			username: item.egousername || item.username,
			authorTags: AppUtils.removeRatingFromCredoPostAuthorTags(item.c_commentAuthorTags, item.mode),
			subcomms: formatPostDetailSubComment(item.subcomms),
		}));
	}
	return commentA;
}
