import {
	ActionType,
	AppMode, cu, GenericResponse, SessMgr, YesNoOptions,
} from "@credo/utilities";
import { MsgMgr } from "../../config/MsgMgr";
import {
	ItemType, RecommendationRank, RecommendationTypes, StreamItem, StreamPostPermission,
} from "../../utils/types";
import { Consts, MsgConst } from "../../utils";

export interface ExploreCommunitiesRequest {
	user_id?: string | null;
	mode: AppMode;
	recom_type: RecommendationTypes;
}

export interface ExploreCommunity {
	"streamId": string,
	"name": string,
	"descr": string,
	"struname": string,
	"profilePicRelUrl": string,
	"ac_alwpos": StreamPostPermission.ALL_MEMBERS,
	"ac_folcom": YesNoOptions,
	"ac_joiaut": YesNoOptions,
	"ac_joicom": YesNoOptions,
	"rank": number,
	"nb_posts": number,
	"rank_type": RecommendationRank,
	"joinModes": string,
}

export interface ExploreCommResItem {
	communities: ExploreCommunity[]
}

export interface RemoveExploreItemRequest {
	user_id: string,
	sid: string,
	mode: AppMode,
	itemType: ItemType,
	itemVal: string,
	action: ActionType,
}

export interface UserCommunitiesRequest {
	user_id?: string | null;
	mode: AppMode;
	page: number,
	seed: number,
	lastTs: number,
	lastId: number,
	fetchSize: number,
	targetUserOrProfId: string | null;
}
export class EngageActions {
	static async retrieveExploreItems(
		request?: ExploreCommunitiesRequest,
		successCallback?: (response: ExploreCommunity[]) => void,
		errorCallback?: (response: any) => void,
	) {
		const serverRequest = request || {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: AppMode.EGO,
			recom_type: RecommendationTypes.COMMUNITIES,
		};

		/**
		 * Since the response data is of all same objects no need to
		 * format the data and pass the data directly in the component
		 *
		 * NOTICE: If the items in response has different entities in
		 * future consider shaping the object and make it unique objects
		 * for all the items. ExploreItem component should consume a
		 * generic data which will render just the data.
		 * */
		await MsgMgr.makeRemoteRequest_generic<ExploreCommunitiesRequest, GenericResponse<ExploreCommResItem>>({
			request: serverRequest,
			msgName: MsgConst.recom4user,
			successCallback: (response) => {
				if (cu.isSet(response?.items?.communities) && successCallback) {
					successCallback(response.items.communities);
				}
			},
			errorCallback,
		});
	}

	static retrieveNoAuthExploreItem(
		request?: ExploreCommunitiesRequest,
		successCallback?: (resolve: ExploreCommunity[]) => void,
		errorCallback?: (response: any) => void,
	) {
		const serverRequest = request || {
			mode: AppMode.EGO,
			recom_type: RecommendationTypes.COMMUNITIES,
		};
		/**
		 * Since the response data is of all same objects no need to
		 * format the data and pass the data directly in the component
		 *
		 * NOTICE: If the items in response has different entities in
		 * future consider shaping the object and make it unique objects
		 * for all the items. ExploreItem component should consume a
		 * generic data which will render just the data.
		 * */
		MsgMgr.makeRemoteRequest_generic<ExploreCommunitiesRequest, GenericResponse<ExploreCommunity[]>>({
			request: serverRequest,
			msgName: MsgConst.noAuthRecom4user,
			successCallback: (response) => {
				if (cu.isSet(response?.items?.length > 0) && successCallback) {
					successCallback(response.items);
				}
			},
			errorCallback,
		});
	}

	static async removeItemFromRec(
		request: RemoveExploreItemRequest,
	) {
		await MsgMgr.makeRemoteRequest_generic<RemoveExploreItemRequest, GenericResponse<ExploreCommResItem>>({
			request,
			msgName: MsgConst.mngRecomItem,
		});
	}

	static async retrieveUserCommunitities(
		request: UserCommunitiesRequest,
		successCallback?: (response: StreamItem[]) => void,
		errorCallback?: () => void,
	) {
		const serverRequest = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: request?.mode,
			page: request?.page,
			seed: request?.seed,
			lastTs: request?.lastTs,
			lastId: request?.lastId,
			fetchSize: request?.fetchSize,
			targetUserOrProfId: request?.targetUserOrProfId,
		};

		await MsgMgr.makeRemoteRequest_generic<UserCommunitiesRequest, GenericResponse<StreamItem[]>>({
			request: serverRequest,
			msgName: `get_${MsgConst.userStreams}`,
			successCallback: (response) => {
				if (cu.isSet(response?.items) && successCallback) {
					successCallback(response?.items);
				}
			},
			errorCallback,
		});
	}
}
