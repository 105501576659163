import React, { InputHTMLAttributes, useEffect, useState } from "react";
import {
	classNames, SearchIcon, SnackBarTypeOptions, SvgIcon,
} from "@credo/ui-components";
import { useDebounce } from "react-use";
import { useEvtMgrListener } from "@credo/utilities";
import { strings } from "../i18n/config";
import { INPUT_VALUE_DEBOUNCE_TIME } from "../config/constants";
import { AppUtils, Consts, EventConst } from "../utils";

interface SearchInputProps {
	className?: string;
	hideSearchIcon?: boolean;
	// TODO: remove optional
	/**
	 * Initial value which should be displayed
	 * in the search input.
	 * */
	value?: string;
	inputClassName?: string;
	/**
	 * Takes parameter as string which is set in
	 * the input field after debounce time is over.
	 *
	 * @default null
	 * @param {string} search - The value which user has
	 * typed after debounce duration is over.
	 * */
	onChange?: (search: string) => void
	/**
	 * Input props object can be passed here
	 * to control input element in this component
	 * */
	inputProps?: InputHTMLAttributes<HTMLInputElement> | {},
	/**
	 * optional functionality which can be executed on
	 * clearing the search input field
	 * */
	onClearSearch?: () => void;
	/**
	 * filter tags using regEx
	 * */
	applyTagFilter?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = (props: SearchInputProps) => {
	const {
		className, hideSearchIcon, inputClassName, onChange,
		value, inputProps, onClearSearch, applyTagFilter,
	} = props;

	const [search, setSearch] = useState<string>("");
	const [debounceVal, setDebounceVal] = useState<string>(value || "");

	useDebounce(
		() => {
			setSearch(debounceVal);
		},
		INPUT_VALUE_DEBOUNCE_TIME,
		[debounceVal],
	);

	useEffect(() => {
		if (onChange) {
			onChange(search);
		}
	}, [search]);

	const handleClearSearch = () => {
		setSearch("");
		setDebounceVal("");
	};

	const onChangeSearchText = (event: any) => {
		let text = event.target.value;
		if (applyTagFilter && text !== "" && !Consts.alphaNumericTagsRegex.test(text)) {
			text = text.replace(/[^A-Za-z0-9_ ]/g, "");
			AppUtils.showToast({
				type: SnackBarTypeOptions.ERROR,
				message: strings("ExpertiseModal.alplhanumeric_tags_only"),
			});
		}
		setDebounceVal(text);
	};

	useEvtMgrListener(EventConst.clearSearch, handleClearSearch);

	return (
		<div
			className={`flex flex-row w-full max-w-[40rem] relative items-center ${className}`}
			data-testid="search-input-container"
		>
			<input
				value={debounceVal}
				onChange={onChangeSearchText}
				data-testid="search-input-field"
				placeholder={strings("SearchInput.search")}
				className={classNames(
					"rounded-30",
					"bg-background text-basic",
					"pl-4",
					"h-10 w-full pr-14",
					"outline-none",
					"placeholder-shown:font-thin",
					"font-thin",
					inputClassName,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...inputProps}
			/>
			{debounceVal && (
				<button
					type="button"
					className={classNames(
						"bg-transparent outline-none h-5",
						"absolute right-3 top-1.5",
						"cursor-pointer",
					)}
					onClick={() => {
						setDebounceVal("");
						setSearch("");
						if (onClearSearch) {
							onClearSearch();
						}
					}}
					data-testid="search-input__clear-button"
				>
					<span className="text-gray-dark text-xxs uppercase font-light">
						{strings("SearchInput.clear")}
					</span>
				</button>
			)}
			{!hideSearchIcon && (
				<div className="flex justify-center items-center absolute right-2">
					<SvgIcon icon={SearchIcon} height={20} width={20} fill="#212636" />
				</div>
			)}
		</div>
	);
};

SearchInput.defaultProps = {
	className: "",
	hideSearchIcon: false,
	inputClassName: "",
	onChange: () => {},
	value: "",
	inputProps: {},
	onClearSearch: () => {},
	applyTagFilter: false,
};

export default SearchInput;
