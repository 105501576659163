import { GlobalState } from "@credo/store";
import { SnackBarTypeOptions } from "@credo/ui-components";
import { ResponseCode } from "@credo/utilities";
import { MsgMgr } from "../../config/MsgMgr";
import { strings } from "../../i18n/config";
import { AppUtils, ClientMetaDataMapping, MsgConst } from "../../utils";
import { ItemType } from "../../utils/types";
import { UserActions } from "./user";

export interface ReportInterfaceRequest{
	reportReason: string,
	reportMessage: string,
	name: string,
	email: string,
	phone: string,
	itemType: ItemType,
	user_id: string | undefined,
}

export interface ProposeTagRequestObject {
	user_id: string;
	action: string;
	proposed_tag: string;
}

export interface ProposeTagResponseObject {
	addr: "proposeTag";
	similar_tags: [];
	proposed_tag: any;
	msg_id: string;
	info: string;
	retcd: string;
	procTs: number;
	sendTs: number;
	sid: string | null;
	what: string;
	wrap_id: string;
}

// eslint-disable-next-line import/prefer-default-export
export class MiscActions {
	static async reportAProblem(
		request: ReportInterfaceRequest,
		onSuccess: (response: any) => void,
		onError: (response: any) => void,
	) {
		await MsgMgr.makeRemoteRequest_generic(
			{
				request,
				msgName: MsgConst.noAuthReportProblem,
				successCallback: (response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				},
				errorCallback: (response) => {
					if (onError) {
						onError(response);
					}
				},
			},
		);
	}

	static updateRemovedHintInMetaData() {
		UserActions.saveClientMetaDataToServer({
			[ClientMetaDataMapping.removedHints]: GlobalState.App.removedHints.get(),
		});
	}

	static proposeTag(request: ProposeTagRequestObject) {
		return new Promise<ProposeTagResponseObject>((resolve, reject) => {
			const callBack = (_p: null, response: ProposeTagResponseObject) => {
				if (response.retcd === ResponseCode.OK) {
					resolve(response);
				} else {
					if (response?.info) {
						AppUtils.showToast({
							type: SnackBarTypeOptions.ERROR,
							message: strings("ExpertiseModal.invalid_tag"),
						});
					}
					reject(new Error(`Received not ok from api: ${response.retcd}`));
				}
			};
			// @ts-ignore
			global.ebMgr.sendMsg("proposeTag", "proposeTag", request, callBack);
		});
	}
}
