import { useSetAtom } from "jotai";
import axios from "axios";
import { useEffect } from "react";
import { dbg, LogMgr } from "@credo/utilities";
import { clientCountryCodeAtom, clientIPAddressAtom, clientLocationAtom } from "../atoms";
import CfgMgr from "../../config/CfgMgr";
import { DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_DIAL } from "../../config/constants";

interface FirstResult {
	data: {
		country_code: string,
		country_name: string,
		city: string,
		postal: string,
		latitude: number,
		longitude: number,
		IPv4: string,
		state: string
	}
}

interface SecondResult {
	data: {
		ip: string,
		network: string,
		version: string,
		city: string,
		region: string,
		region_code: string,
		country: string,
		country_name: string,
		country_code: string,
		country_code_iso3: string,
		country_capital: string,
		country_tld: string,
		continent_code: string,
		in_eu: boolean,
		postal: string,
		latitude: number,
		longitude: number,
		timezone: string,
		utc_offset: string,
		country_calling_code: string,
		currency: string,
		currency_name: string,
		languages: string,
		country_area: number,
		country_population: number,
		asn: string,
		org: string,
	}
}

// eslint-disable-next-line import/prefer-default-export
export const useGetIPAddress = () => {
	const setIpAddress = useSetAtom(clientIPAddressAtom);
	const setCountryCode = useSetAtom(clientCountryCodeAtom);
	const setLocation = useSetAtom(clientLocationAtom);

	const formatResult = (
		firstResult: FirstResult | null,
		secondResult: SecondResult | null,
	) => {
		if (
			firstResult && !([
				firstResult.data.IPv4.toLowerCase(),
				firstResult.data.country_code.toLowerCase(),
			].includes("not found"))
		) {
			return {
				ip: firstResult.data.IPv4,
				country_code: firstResult.data.country_code,
				subLocality: firstResult.data.city,
				locality: firstResult.data.state,
				short_name: firstResult.data.country_code,
			};
		}

		if (
			secondResult && !([
				secondResult.data.ip.toLowerCase(),
				secondResult.data.country_code.toLowerCase(),
			].includes("not found"))
		) {
			return {
				ip: secondResult.data.ip,
				country_code: secondResult.data.country_code,
				subLocality: secondResult.data.city,
				locality: secondResult.data.region,
				short_name: secondResult.data.country_code,
			};
		}

		return null;
	};

	const getGeoLocationData = async () => {
		/**
		 * Not looping through the response because both the response
		 * have different data type, so we have to handle it manually
		 * */
		let res = null;
		let resFallBack = null;
		try {
			res = await axios.get(CfgMgr.GET_IP_URL[0]);
		} catch (error) {
			if (dbg) {
				LogMgr.mydbg("getGeoLocationData: error while fetching first data", error);
			}
		}

		try {
			resFallBack = await axios.get(CfgMgr.GET_IP_URL[1]);
		} catch (error) {
			if (dbg) {
				LogMgr.mydbg("getGeoLocationData: error while fetching second data", error);
			}
		}

		try {
			const result = formatResult(res, resFallBack);

			if (result) {
				setIpAddress(result?.ip);
				setCountryCode(result?.country_code);
				setLocation({
					sublocality: result.subLocality,
					locality: result.locality,
					country: result.country_code,
					short_name: result.short_name,
				});
			} else {
				Error("getGeoLocationData: both api gave unreadable response");
			}
		} catch (error) {
			if (dbg) {
				LogMgr.mydbg("getGeoLocationData: error while fetching location data", error);
			}
			setCountryCode(DEFAULT_COUNTRY_CODE);
			setLocation({
				sublocality: "",
				locality: "",
				country: DEFAULT_COUNTRY_CODE,
				short_name: DEFAULT_COUNTRY_DIAL,
			});
		}
	};

	useEffect(() => {
		getGeoLocationData();
	}, []);
};
