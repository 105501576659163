import { ExploreCommunities } from "@credo/ui-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionType, EvtMgr, SessMgr } from "@credo/utilities";
import { useAtom } from "jotai";
import { EngageActions, ExploreCommunity } from "../../../services/actions/engage";
import {
	AnalyticEventsConst, AppUtils, Consts, EventConst,
} from "../../../utils";
import { Actions, ItemType, RelationTypeStatus } from "../../../utils/types";
import { StreamActions } from "../../../services/actions/stream";
import { applicationModeAtom } from "../../../utils/atoms";
import { strings } from "../../../i18n/config";

interface OwnProps {
	items: {
		cardData: ExploreCommunity[]
	},
	width: number
	removeStreamCard: (stream: any) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const ExploreCommSection = (props: OwnProps) => {
	const [appMode] = useAtom(applicationModeAtom);
	const {
		items,
		width,
		removeStreamCard,
	} = props;

	const navigate = useNavigate();

	const followStream = (streamId: string, isFollowed: boolean) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.cardCommunityFollow,
		});
		const manageRelation = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: appMode,
			targetStreamId: streamId,
			relType: RelationTypeStatus.FOLLOW,
			action: !isFollowed ? Actions.DELETE : Actions.CREATE,
			prof_id: "",
		};
		StreamActions.mngRelation4Stream(manageRelation);
	};

	const removeStream = (stream: any) => {
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.cardCommunityDismiss,
		});
		const request = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			sid: SessMgr.getFromSession(Consts.sid),
			mode: appMode,
			itemType: ItemType.STREAM,
			itemVal: stream?.streamId,
			action: ActionType.REMOVE,
		};
		EngageActions.removeItemFromRec(request);
		if (removeStreamCard) removeStreamCard(stream);
	};

	const navigateToStream = ({
		streamId,
		struname,
		meta,
	}: {
		streamId: string,
		struname: string,
		meta: {
			title: string;
		},
	}) => {
		AppUtils.navigateToStreamProfile(
			streamId,
			struname,
			navigate,
			meta,
		);
	};

	return (
		<ExploreCommunities
			width={width}
			items={items.cardData?.slice(0, 5) as any}
			handleClickStream={navigateToStream}
			messages={{
				Follow: strings("HomePage.explore_card.follow"),
				isFollowed: strings("HomePage.explore_card.unfollow"),
				Dismiss: strings("HomePage.dismiss"),
			}}
			onFollowClick={followStream}
			onRemoveCard={removeStream}
		/>
	);
};
