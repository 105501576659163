import React, { useEffect } from "react";
import { useAtomValue } from "jotai";
import { cu } from "@credo/utilities";
import { CredoBoost } from "../../../services/actions/boost";
import { isConnectedAtom, isSessionAuthAtom, userIdAtom } from "../../../utils/atoms";

// eslint-disable-next-line import/prefer-default-export
export const VoltsDataProvider: React.FC = () => {
	const isConnected = useAtomValue(isConnectedAtom);
	const isSessionAuth = useAtomValue(isSessionAuthAtom);
	const userId = useAtomValue(userIdAtom);

	useEffect(() => {
		if (isConnected && cu.isSet(userId) && isSessionAuth) {
			CredoBoost.getWalletSummary();
		}
	}, [isConnected, userId, isSessionAuth]);

	return null;
};
