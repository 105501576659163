import React, { useEffect, useMemo } from "react";
import {
	classNames,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import {
	cu, EvtMgr, useWindowSize, YesNoOptions,
} from "@credo/utilities";
import { isMobile } from "react-device-detect";
import ReactSwitch from "react-switch";
import { strings } from "../../../i18n/config";
import {
	AppUtils, Consts, EventConst, MsgConst,
} from "../../../utils";
import { ThemeOptions } from "../../../utils/types";
import { showCredoModeWarning, userProfileAtom, userSessionAtom } from "../../../utils/atoms";
import { MsgMgr } from "../../../config/MsgMgr";
import { useSetAppHeader } from "../../../utils/hooks/useSetAppHeader";

export interface MenuItem {
	id: number,
	title: string,
	onClick: () => void,
	hint: string;
	value: boolean;
}

const SettingsPSActivityScreen: React.FC = () => {
	const { width } = useWindowSize();
	const [userProfile] = useAtom(userProfileAtom);
	const isInMobile = isMobile && width < 768;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userSession] = useAtom(userSessionAtom);
	const [, setShowCredoModeWarningAtom] = useAtom(showCredoModeWarning);

	useSetAppHeader({
		title: strings("SettingsPrivacySettingsScreen.your_activity"),
	});

	const PSActivityDetails = useMemo(() => ({
		followUser: isCredoMode ? userProfile?.ps_c_evtfoluse === YesNoOptions.YES : userProfile?.ps_e_evtfoluse === YesNoOptions.YES,
		followCommunity: isCredoMode ? userProfile?.ps_c_evtfolcom === YesNoOptions.YES : userProfile?.ps_e_evtfolcom === YesNoOptions.YES,
		newConnection: isCredoMode ? userProfile?.ps_c_evtusecon === YesNoOptions.YES : userProfile?.ps_e_evtusecon === YesNoOptions.YES,
		joinedCommunity: isCredoMode ? userProfile?.ps_c_evtjoicom === YesNoOptions.YES : userProfile?.ps_e_evtjoicom === YesNoOptions.YES,
	}), [isCredoMode, userProfile]);

	useEffect(() => {
		setShowCredoModeWarningAtom(true);
		if (isCredoMode) {
			AppUtils.toggleTheme(ThemeOptions.EGO);
		}
	}, [isCredoMode, userProfile]);

	const updateProfileSettings = (item: string, value: any) => {
		EvtMgr.getInstance(EventConst.userInfo).notifyListeners({
			items: [
				{
					...userProfile,
					[item]: value,
				},
			],
		});
		const updateProfileActivity = {
			user_id: userSession?.user_id,
			mode: cu.getAppMode(),
			[item]: value,
		};
		MsgMgr.makeRemoteRequest_generic({
			request: updateProfileActivity,
			msgName: MsgConst.updateUserInfo,
		});
	};

	const changeFollowUser = () => {
		updateProfileSettings(
			Consts.privacySetting4FollowUserEgoActivity,
			PSActivityDetails.followUser ? YesNoOptions.NO : YesNoOptions.YES,
		);
	};

	const changeFollowCommunity = () => {
		updateProfileSettings(
			Consts.privacySetting4FollowCommEgoActivity,
			PSActivityDetails.followCommunity ? YesNoOptions.NO : YesNoOptions.YES,
		);
	};

	const changeNewConnection = () => {
		updateProfileSettings(Consts.privacySetting4NewConnectionEgoActivity,
			PSActivityDetails.newConnection ? YesNoOptions.NO : YesNoOptions.YES);
	};

	const changeJoinedCommunity = () => {
		updateProfileSettings(Consts.privacySetting4JoinCommEgoActivity,
			PSActivityDetails.joinedCommunity ? YesNoOptions.NO : YesNoOptions.YES);
	};

	const menuItems: MenuItem[] = [
		{
			id: 1,
			title: strings("SettingsPSActivityScreen.follow_a_user"),
			hint: strings("SettingsPSActivityScreen.follow_a_user_msg"),
			onClick: changeFollowUser,
			value: PSActivityDetails.followUser,
		},
		{
			id: 2,
			title: strings("SettingsPSActivityScreen.follow_a_community"),
			hint: strings("SettingsPSActivityScreen.follow_a_community_msg"),
			onClick: changeFollowCommunity,
			value: PSActivityDetails.followCommunity,
		},
		{
			id: 3,
			title: strings("SettingsPSActivityScreen.new_conncetion"),
			hint: strings("SettingsPSActivityScreen.new_conncetion_msg"),
			onClick: changeNewConnection,
			value: PSActivityDetails.newConnection,
		},
		{
			id: 4,
			title: strings("SettingsPSActivityScreen.joined_a_community"),
			hint: strings("SettingsPSActivityScreen.joined_a_community_msg"),
			onClick: changeJoinedCommunity,
			value: PSActivityDetails.joinedCommunity,
		},
	];

	const renderListItem = (item: MenuItem) => {
		const {
			title,
			id,
			onClick,
			hint,
			value,
		} = item;
		return (
			<div
				key={id}
				className={classNames(
					"bg-transparent w-full h-16",
					isInMobile ? "px-3" : "",
				)}
			>
				<div className="flex justify-start items-center w-full">
					<div className="flex items-center justify-start w-full">
						<div
							className={classNames(
								"border-b border-post-bar-border w-full h-16",
								"flex justify-start items-center",
							)}
						>
							<div className="flex flex-col w-full">
								<span className="text-md w-full text-start">
									{title}
								</span>
								<span className="text-sm w-full text-start text-gray-dark">
									{hint}
								</span>
							</div>
							<div className="flex flex-row items-center justify-center">
								<ReactSwitch
									onColor="#00A0FF"
									offColor="#96A5B9"
									onChange={onClick}
									checked={value}
									uncheckedIcon={(<div />)}
									checkedIcon={(<div />)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderList = () => (
		<div>
			{menuItems.map((item: MenuItem) => renderListItem(item))}
		</div>
	);

	return (
		<div
			className={classNames(
				"flex flex-col justify-between",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				isInMobile ? "" : "px-3",
				"text-basic",
				"bg-background",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-5",
				isInMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-64px)]",
			)}
		>
			<div className="px-3 w-full flex flex-col">
				<div className="flex text-sm text-gray-dark text-center p-5 w-11/12 self-center">
					{strings("SettingsPSActivityScreen.your_activity_msg")}
				</div>
				<div className="block pt-2">
					{renderList()}
				</div>
			</div>
		</div>
	);
};

export default SettingsPSActivityScreen;
