export const ROOT_URL = "/";
export const HOME_PAGE = "/home/for-you";
export const BLASTS_PAGE = "/home/blasts";
export const NEWS_PAGE = "/home/news";
export const TOP_PAGE = "/home/top";
export const REVIEW_PAGE = "/home/review";
export const NOTIFICATIONS_PAGE = "/inbox";
export const AUTH_URL = "/";
export const LOGIN_PAGE = "/login";
export const PAGE_404_URL = "/404";
export const PROFILE_PAGE = "/profile";
export const SETTINGS_PAGE = "/settings";
export const DEV_PAGE = "/developer";
export const NEW_POST_PAGE = "/new-post";
export const ENGAGE_PAGE = "/manage";
export const COMMUNITIES_PAGE = "/communities";
export const EXPLORE_PAGE = "/explore";
export const CREATE_STREAM = "/new-stream";
export const SUPPORT_URL = "/support";
export const PRIVACY_POLICY_URL = "/legal/privacy-policy";
export const TERMS_AND_CONDITIONS_URL = "/legal/terms-and-conditions";
export const COOKIE_USE = "/legal/cookie-policy";
export const STREAM_PROFILE_PAGE = "/c";
export const STREAM_SETTING_PAGE = "/stream-settings";
export const STREAM_MEMBERS_PAGE = "/members";
export const STREAM_FOLLOWERS_PAGE = "/followers";
export const OTHER_USER_PROFILE_PAGE = "/u";
export const POST_DETAIL_PAGE = "/p";
export const SIGN_IN_VIA_EMAIL = "/signInViaEmailLink";
export const LINKEDIN_AUTH = "/linkedInAuth";
export const LINKEDIN_AUTH_MOBILE = "/linkedInAuthMobile";
export const REGISTER_PAGE = "/register";
export const REGISTER_CONFIRM = "/confirm-register";
export const REGISTER_INTEREST = "/signup-tags";
export const REGISTER_EXPERTISE = "/expertise";
export const ANIMATIONS_PAGE = "/animations";
export const USER_ALL_POSTS = "/posts";
export const REPORT_A_PROBLEM_PAGE = "/report-a-problem";
export const LANGUAGE_PAGE = "/lang";
export const CHANGE_EMAIL_PAGE = "/change-email";
export const SUPPORT_PAGE = "/support";
export const CHANGE_PHONE_PAGE = "/change-phone";
export const VERIFY_PHONE_PAGE = "/verify-phone";
export const PRIVACY_SETTINGS_PAGE = "/privacy-settings";
export const PROFILE_VISIBILITY_PAGE = "/profile-visibilty";
export const CONNECT_FOLLOW_PAGE = "/connet-follow";
export const POSTS_VISIBILITY_PAGE = "/posts-visibilty";
export const BLOCKED_USERS_PAGE = "/blocked-users";
export const YOUR_ACTIVITY_PAGE = "/your-activity";
export const SOCIAL_LOGIN_PAGE = "/social-login";
export const DELETE_ACCOUNT = "/delete-account";
export const REQUEST_PAGE = "/requests";
export const NETWORK_PAGE = "/network";
