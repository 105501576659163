import { classNames, LazyLoad } from "@credo/ui-components";
import { isMobile } from "react-device-detect";
import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const SettingsPage: React.FC = LazyLoad(
	() => import(/* webpackChunkName: "settingsPage" */ "./SettingsPage"),
	(module) => module.default, {
		fallback: (
			<div
				className={classNames(
					"flex flex-col justify-between",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"pt-4",
					isMobile ? "" : "px-3",
					"text-basic",
					"bg-background",
					isMobile ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "",
				)}
			>
				<div />
			</div>
		),
	},
);
