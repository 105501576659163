import { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import {
	getAuth, getRedirectResult,
} from "firebase/auth";
import {
	cu, dbg, EvtMgr, LogMgr,
} from "@credo/utilities";
import {
	firebaseMgrAtom, isConnectedAtom, isSessInProgressAtom, isSessionAuthAtom, showSocialLoadingAtom,
} from "../atoms";
import { Consts, EventConst } from "../Consts";
import { AUTH_MODAL_TIMEOUT } from "../../config/constants";

// eslint-disable-next-line import/prefer-default-export
export const useFirebaseRedirectResponse = () => {
	const [firebaseMgr] = useAtom(firebaseMgrAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [isSessInProgress] = useAtom(isSessInProgressAtom);
	const redirectRef = useRef<any>(null);
	const [isConnected] = useAtom(isConnectedAtom);
	const [socialLoading, setSocialLoading] = useAtom(showSocialLoadingAtom);
	const countRef = useRef<any>(null);

	useEffect(() => {
		/**
		 * To listen when the firebase's state changes on unmount
		 * once we open the redirected url.
		 * */
		const unsubscribe = async () => {
			/**
			 * !isSessAuth: When user refreshes after social login unsubscribe
			 * function was being called again which in turn calling findOrCreate
			 * which made sessInProgress as true and not turning it false when
			 * we get ResponseCode.OK_REQ_ELNA
			 * */
			const isAddingSocialLogin = cu.getGlobalVar("IsAddingSocialLogin");
			if (firebaseMgr.credoApp && (!(isSessionAuth && !isSessInProgress) || isAddingSocialLogin)) {
				await firebaseMgr.getRedirectResponse(
					() => {
						if (dbg) {
							LogMgr.mydbg(
								`useFirebaseRedirectResponse unsubscribed: ${cu.getGlobalVar(Consts.socialAuthType)}`,
							);
						}
						clearInterval(redirectRef.current);
						// cu.setGlobalVar(Consts.socialAuthType, null);
						// localStorage.removeItem(Consts.socialAuthType);
					},
				);
			}
		};
		/**
		 * Added timer here, since we need to wait for the firebase
		 * to initialise, once the firebase is initialised we can
		 * capture the required social login response. Need to make
		 * this more robust and efficient.
		 * */
		redirectRef.current = setInterval(() => {
			const isAddingSocialLogin = cu.getGlobalVar("IsAddingSocialLogin");
			if (firebaseMgr.credoApp && (!(isSessionAuth && !isSessInProgress) || isAddingSocialLogin) && isConnected) {
				const auth = getAuth();
				if (dbg) {
					LogMgr.mydbg(
						`useFirebaseRedirectResponse Firebase Redirected from ${cu.getGlobalVar(Consts.socialAuthType)}`,
					);
				}
				if (getRedirectResult) {
					getRedirectResult(auth)
						.then((result) => {
							clearInterval(redirectRef.current);
							// cu.setGlobalVar(Consts.socialAuthType, null);
							// localStorage.removeItem(Consts.socialAuthType);
							if (dbg) {
								LogMgr.mydbg(
									"useFirebaseRedirectResponse Firebase Redirected from SUCCESS ",
									`${cu.getGlobalVar(Consts.socialAuthType)}:`,
									result,
								);
							}
						})
						.catch((error) => {
							EvtMgr.getInstance(EventConst.showSocialLoading).notifyListeners(false);
							clearInterval(redirectRef.current);
							cu.setGlobalVar(Consts.socialAuthType, null);
							localStorage.removeItem(Consts.socialAuthType);
							LogMgr.mydbg(
								"useFirebaseRedirectResponse Firebase Redirected from ERROR ",
								`${cu.getGlobalVar(Consts.socialAuthType)}:`,
								error,
							);
						});
				}
			}
		}, 3000);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [isConnected]);

	useEffect(() => {
		if (socialLoading) {
			countRef.current = setTimeout(() => {
				setSocialLoading(false);
			}, AUTH_MODAL_TIMEOUT);
		} else {
			clearTimeout(countRef.current);
		}
		return () => {
			clearTimeout(countRef.current);
		};
	}, [socialLoading]);
};
