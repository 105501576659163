import React, { useCallback } from "react";
import { cu } from "@credo/utilities";
import { PostHeader } from "./post-header/PostHeader";
import { PostText } from "./post-text/PostText";
import PostLinkPreview from "./post-link-preview/PostLinkPreview";
import { PostBar } from "./post-bar/PostBar";
import { PostTags } from "./PostTags";
import { CredoTagSource, MoreMenuInterface, PostMsgs } from "./types";
import PostVideo from "./post-video/PostVideo";
import PostImages from "./post-image/PostImages";
import PostShared from "./post-shared/PostShared";
import { PostFlare } from "./PostFlare";
import { ScalerSize } from "../common";
import { BoostTrayProps } from "../boost";
import { TagMessages } from "../credo-tag";

export interface PostViewProps {
	item: any;
	onCommentIconClick: () => void;
	onEmojiClick: (data: any) => void;
	onSharePostIconClick: () => void;
	isUserOwnPost: boolean;
	isCredoMode: boolean;
	onModeChange(mode: boolean): void;
	userProfile: any;
	onTagRate: (data: any) => void;
	postMsgs: PostMsgs;
	navigateToUserOrStream: (data: any) => void;
	moreMenuList: MoreMenuInterface[];
	showLoginModal: (show: boolean) => void;
	navigateToDetailsScreen: (isShared: boolean) => void;
	hasComments?: boolean;
	onLinkShareIconPress: (url: string) => void;
	showHandIcon?: boolean;
	onClickEmojiTooltip?: () => void;
	onClickMoreMenu?: () => void;
	commentInputVisible?: boolean;
	onPressCredoGraphText: () => void;
	onPressTagInfoBubble: () => void;
	boostTrayProps: BoostTrayProps;
	messages: {
		postTagMessages: TagMessages;
		postHeaderTagMessages: TagMessages;
	}
}

export const PostViewComponent = ({
	item,
	onCommentIconClick,
	onEmojiClick,
	onSharePostIconClick,
	isUserOwnPost,
	isCredoMode,
	onModeChange,
	userProfile,
	onTagRate,
	postMsgs,
	navigateToUserOrStream,
	moreMenuList,
	showLoginModal,
	navigateToDetailsScreen,
	onLinkShareIconPress,
	hasComments = false,
	showHandIcon = true,
	onClickEmojiTooltip,
	onClickMoreMenu,
	commentInputVisible,
	onPressCredoGraphText,
	onPressTagInfoBubble,
	boostTrayProps,
	messages,
}: PostViewProps) => {
	const {
		imageErrorMsg,
		contentSensitiveMsg,
		switchToCredoModeToRate,
		cannotRateOwnPostMsg,
		sharedPostDeleted,
	} = postMsgs;
	const bucketRelativePaths = [
		item["p.img_bucketRelativeRefPath0"],
		item["p.img_bucketRelativeRefPath1"],
		item["p.img_bucketRelativeRefPath2"],
		item["p.img_bucketRelativeRefPath3"],
	];

	const onAuthorPostTagRate = useCallback((tag: string, rating: any) => {
		if (onTagRate) {
			onTagRate({
				tag,
				rating,
				tagSource: CredoTagSource.AUTHOR_POST,
			});
		}
	}, [onTagRate]);

	const onPostTagRate = useCallback((tag: string, rating: any) => {
		if (onTagRate) {
			onTagRate({
				tag,
				rating,
				tagSource: CredoTagSource.POST,
			});
		}
	}, [onTagRate]);

	const renderPostMedia = () => {
		if (cu.isYes(item["p.is_shared"])) {
			return (
				<PostShared
					postData={item}
					isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
					onLinkShareIconPress={onLinkShareIconPress}
					navigateToDetailsScreen={navigateToDetailsScreen}
					contentSensitiveMsg={contentSensitiveMsg}
					imageErrorMsg={imageErrorMsg}
					sharedPostDeleted={sharedPostDeleted}
					navigateToUserOrStream={navigateToUserOrStream}
					showHandIcon={showHandIcon}
				/>
			);
		} else {
			return (
				<div className="w-full">
					{bucketRelativePaths.some((single) => single) && (
						<PostImages
							postData={item}
							sharedPost={false}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
						/>
					)}
					{cu.isSet(item["p.video_bucketRelativeRefPath"]) && (
						<PostVideo
							postData={item}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							sharedPost={false}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
						/>
					)}
					{cu.isSet(item["p.mediaObj_Link_Url"]?.trim()) && !cu.isSet(bucketRelativePaths[0]) && (
						<PostLinkPreview
							linkData={item}
							isContentRestricted={item["p.nb_rep"] ? item["p.nb_rep"] > 0 : false}
							sharedPost={false}
							onLinkShareIconPress={onLinkShareIconPress}
							contentSensitiveMsg={contentSensitiveMsg}
							imageErrorMsg={imageErrorMsg}
						/>
					)}
				</div>
			);
		}
	};

	return (
		<div>
			<PostHeader
				item={item}
				navigateToDetailsScreen={navigateToDetailsScreen}
				navigateToUserOrStream={navigateToUserOrStream}
				showHandIcon={showHandIcon}
				postTagProps={{
					tags: item.authorTags,
					onRate: onAuthorPostTagRate,
					isUserOwnPost,
					isCredoMode,
					onModeChange,
					userProfile,
					switchToCredoModeToRate,
					cannotRateOwnPostMsg,
					showLoginModal,
					onPressCredoGraphText,
					onPressTagInfoBubble,
					credoTagId: "author-modal/credo-tag",
					messages: messages.postHeaderTagMessages,
				}}
			/>
			{cu.isSet(item?.postFlare?.replace(/[\[\]]/g, "")) ? (
				<PostFlare item={item} />
			) : null}
			<PostText
				item={item}
				navigateToDetails={() => navigateToDetailsScreen(false)}
				navigateToMentionedUser={navigateToUserOrStream}
			/>
			<div className="flex w-full justify-center items-center bg-background">
				{renderPostMedia()}
			</div>
			<PostTags
				credoTagId="post/credo_tag"
				tags={item.sortedPostTags}
				onRate={onPostTagRate}
				isUserOwnPost={isUserOwnPost}
				isCredoMode={isCredoMode}
				onModeChange={onModeChange}
				userProfile={userProfile}
				switchToCredoModeToRate={switchToCredoModeToRate}
				cannotRateOwnPostMsg={cannotRateOwnPostMsg}
				showLoginModal={showLoginModal}
				onPressCredoGraphText={onPressCredoGraphText}
				onPressTagInfoBubble={onPressTagInfoBubble}
				tagSize={ScalerSize.S}
				boostTrayProps={boostTrayProps}
				messages={messages.postTagMessages}
			/>
			<div className="px-4 bg-background">
				<PostBar
					onClickMoreMenu={onClickMoreMenu}
					onClickEmojiTooltip={onClickEmojiTooltip}
					postData={item}
					hasComments={hasComments}
					onCommentIconClick={onCommentIconClick}
					onEmojiClick={onEmojiClick}
					onSharePostIconClick={onSharePostIconClick}
					moreMenuList={moreMenuList}
					isCredoMode={isCredoMode}
					showLoginModal={showLoginModal}
					commentInputVisible={commentInputVisible}
				/>
			</div>
		</div>
	);
};

PostViewComponent.defaultProps = {
	hasComments: false,
	showHandIcon: true,
	onClickMoreMenu: null,
	onClickEmojiTooltip: null,
	commentInputVisible: false,
};

const compareFn = (
	nextProps: PostViewProps,
	prevProps: PostViewProps,
) => JSON.stringify(nextProps.item) === JSON.stringify(prevProps.item)
	&& nextProps.isUserOwnPost === prevProps.isUserOwnPost
	&& nextProps.onCommentIconClick === prevProps.onCommentIconClick
	&& nextProps.onEmojiClick === prevProps.onEmojiClick
	&& nextProps.onSharePostIconClick === prevProps.onSharePostIconClick
	&& nextProps.isUserOwnPost === prevProps.isUserOwnPost
	&& nextProps.isCredoMode === prevProps.isCredoMode
	&& nextProps.onModeChange === prevProps.onModeChange
	&& JSON.stringify(nextProps.userProfile) === JSON.stringify(prevProps.userProfile)
	&& nextProps.onTagRate === prevProps.onTagRate
	&& JSON.stringify(nextProps.postMsgs) === JSON.stringify(prevProps.postMsgs)
	&& nextProps.navigateToUserOrStream === prevProps.navigateToUserOrStream
	&& nextProps.showLoginModal === prevProps.showLoginModal
	&& nextProps.navigateToDetailsScreen === prevProps.navigateToDetailsScreen
	&& nextProps.onLinkShareIconPress === prevProps.onLinkShareIconPress
	&& nextProps.hasComments === prevProps.hasComments
	&& nextProps.showHandIcon === prevProps.showHandIcon
	&& nextProps.onClickEmojiTooltip === prevProps.onClickEmojiTooltip
	&& nextProps.onClickMoreMenu === prevProps.onClickMoreMenu
	&& nextProps.commentInputVisible === prevProps.commentInputVisible
	&& nextProps.onPressCredoGraphText === prevProps.onPressCredoGraphText
	&& nextProps.onPressTagInfoBubble === prevProps.onPressTagInfoBubble
	&& nextProps.moreMenuList.length === prevProps.moreMenuList.length
	&& JSON.stringify(nextProps.boostTrayProps) === JSON.stringify(prevProps.boostTrayProps)
	&& JSON.stringify(nextProps.messages) === JSON.stringify(prevProps.messages);

export const PostView = React.memo(
	PostViewComponent,
	// compareFn,
);
