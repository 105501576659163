import {
	AuthoorisationEngineFactory,
	Context,
	DictObjectTypeResolver,
	Permission,
	PermissionsConsumer,
	PermissionsPublisher,
	Rule,
	StaticContextFactory,
} from "../AuthorisationEngine";
import { Consumer } from "../../utils/Notifier";
import { DobeEngine } from "./DobeEngine";
import * as assert from "assert";

export const TestActions = {
	CREATE: "create",
	DELETE: "delete",
	EDIT: "edit",
	RATE: "rate",
	SIGNIN: "signin",
	ACCESS: "access",
	BOOST: "boost",
};

export const enum TestObjectTypes {
	POST = "post",
	USER = "user",
	APP = "app",
	COMMUNITY = "community",
	FEATURE = "feature",
}

export const TestObjectIDs = {
	COMMUNITY_ID: "test1",
	POST_ID: "post1",
	LINK_TYPE: "member",
	USER1_ID: "user1",
	USER2_ID: "user2",
	GUEST_USER_ID: "guest3",
	FEATURE_1_ID: "feature1",
	FEATURE_2_ID: "feature2",
	FEATURE_BOOST_BOARD: "boost-board",
	FEATURE_VOLTS_DASHBOARD: "volts-dashboard",
};

export const TestPermissions = {
	PERM_CREATE_POST: new Permission(
		"post-create",
		Rule.Allow,
		[TestActions.CREATE],
		[TestObjectTypes.COMMUNITY],
		"context.linked(community,actor,'member') || context.linked(community,actor,'owner')"
	),
	PERM_DELETE_POST: new Permission(
		"post-delete",
		Rule.Allow,
		[TestActions.DELETE],
		[TestObjectTypes.POST],
		"post.author==actor"
	),
	PERM_RATE_USER: new Permission(
		"user-rate",
		Rule.Allow,
		[TestActions.RATE],
		[TestObjectTypes.USER],
		"user!=actor"
	),
	PERM_BLOCK_RATE_NOTCREDOMODE: new Permission(
		"block-rate-not_in_credomode",
		Rule.Block,
		[TestActions.RATE],
		[TestObjectTypes.USER, TestObjectTypes.POST],
		"!context.credoMode"
	),
	PERM_BLOCK_NOTLOGGEDIN: new Permission(
		"block-not_logged_in",
		Rule.Block,
		[TestActions.RATE, TestActions.CREATE, TestActions.EDIT, TestActions.DELETE],
		[TestObjectTypes.USER, TestObjectTypes.POST],
		"!context.actor.loggedIn"
	),
	BOOST_FEATURE_ALLOWED: new Permission(
		"boost-board",
		Rule.Allow,
		[TestActions.ACCESS],
		[TestObjectTypes.FEATURE],
		"context.feature.id == \"boost-board\"",
	),
	VOLTS_FEATURE_ALLOWED: new Permission(
		"volts-dashboard",
		Rule.Allow,
		[TestActions.ACCESS],
		[TestObjectTypes.FEATURE],
		"context.feature.id == \"volts-dashboard\"",
	),
	BOOSTING_ON_POST_ALLOWED: new Permission(
		"boost-post",
		Rule.Allow,
		[TestActions.BOOST],
		[TestObjectTypes.POST],
		"context.balance !== 0",
	),

};

export class TestPermissionsProvider implements PermissionsPublisher {
	private c: PermissionsConsumer | null = null;
	public perms = new Set<Permission>();

	clear() {
		this.perms.clear();
		this.publish();
	}

	reset() {
		this.perms.clear();
		this.c && this.c.reset?.();
	}

	publish() {
		this.c && this.c.consume(this.perms);
	}

	start(c: Consumer<Set<Permission>>): void {
		this.c = c;
		this.publish();
	}

	stop(c: Consumer<Set<Permission>>): void {
		this.c = null;
	}

}

export interface TestDobeEngineFactoryParams {
	context: Context,
	permissions: Permission[]
}

export class TestDobeEngineFactory implements AuthoorisationEngineFactory<DobeEngine> {
	static permissionsPublisher = new TestPermissionsProvider();

	create(params: TestDobeEngineFactoryParams): DobeEngine {
		const res = new DobeEngine(
			TestDobeEngineFactory.permissionsPublisher,
			testObjectTypeResolver
		);
		testContextProvider.context.addFrom(params.context);
		res.setContextProvider(testContextProvider);
		// configure provided permissions
		params.permissions.map((p) => {
			TestDobeEngineFactory.permissionsPublisher.perms.add(p);
		});
		TestDobeEngineFactory.permissionsPublisher.publish();
		return res;
	}

}

export interface MockIdentifiable {
	id: string;
}

export class MockPost implements MockIdentifiable {
	community: MockCommunity;
	author: MockUser;
	id: string;
	// in production Post.name does not work in js it sets as e
	static resolveIdentifier: string = "MockPost";

	constructor(id: string, author: MockUser, community: MockCommunity) {
		this.id = id;
		this.author = author;
		this.community = community;
	}
}

export class MockUser implements MockIdentifiable {
	readonly id: string;
	readonly name: string;
	readonly loggedIn: boolean;
	static resolveIdentifier: string = "MockUser"

	constructor(id: string, name: string, loggedIn = true) {
		this.id = id;
		this.name = name;
		this.loggedIn = loggedIn;
	}
}

export class MockFeature implements MockIdentifiable {
	readonly id: string;
	readonly name: string;
	static resolveIdentifier: string = "MockFeature"

	constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}
}

export class MockCommunity implements MockIdentifiable {
	readonly id: string;
	readonly name: string;
	static resolveIdentifier: string = "MockCommunity"

	constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}
}

const TEST_USER1 = new MockUser(TestObjectIDs.USER1_ID, "Regular User");
const TEST_COMMMUNITY = new MockCommunity(TestObjectIDs.COMMUNITY_ID, "Test Community");

export const MockObjects = {
	TEST_USER1: TEST_USER1,
	TEST_USER2: new MockUser(TestObjectIDs.USER2_ID, "Pro User"),
	TEST_USER_NOTLOGGEDIN: new MockUser(TestObjectIDs.GUEST_USER_ID, "Guest User", false),
	TEST_COMMMUNITY: TEST_COMMMUNITY,
	TEST_POST: new MockPost(TestObjectIDs.POST_ID, TEST_USER1, TEST_COMMMUNITY),
	TEST_FEATURE: new MockFeature(TestObjectIDs.FEATURE_1_ID, "boost-board"),
	TEST_FEATURE2: new MockFeature(TestObjectIDs.FEATURE_2_ID, "volts-dashboard"),
	TEST_BOOST_BOARD: new MockFeature(TestObjectIDs.FEATURE_BOOST_BOARD, "boost-board"),
	TEST_VOLTS_DASHBOARD: new MockFeature(TestObjectIDs.FEATURE_VOLTS_DASHBOARD, "volts-dashboard"),
};
export const TestUsers = [MockObjects.TEST_USER1, MockObjects.TEST_USER2, MockObjects.TEST_USER_NOTLOGGEDIN];

export const testContextResolvers = {
	linked: (community: MockIdentifiable, user: MockIdentifiable, type: string) =>
		(community.id == TestObjectIDs.COMMUNITY_ID && user.id == TestObjectIDs.USER1_ID && type == "member"),
	assert: (expression: any, expected: any ) => assert.equal(expression, expected),
};

export const testContextProvider = new StaticContextFactory(new Context()
	.add("linked", testContextResolvers.linked)
	.add("assert", testContextResolvers.assert)
	.add("credoMode", true)
	.add("actor", TEST_USER1));

export const testObjectTypeResolver = new DictObjectTypeResolver({
	"post": MockPost.resolveIdentifier,
	"user": MockUser.resolveIdentifier,
	"community": MockCommunity.resolveIdentifier,
	"feature": MockFeature.resolveIdentifier,
});
