interface BaseCalcFeeParam {
	totalAmount: number;
	feePercentage: number;
}

interface ReturnCalculateFeesInverse {
	total: number,
	fees: number,
	amountAfterFeeDeduction: number,
}

interface ReturnCalculateFees {
	total: number,
	fees: number,
	totalWithdraw: number,
}

interface CalcWithdrawDataParam extends BaseCalcFeeParam {
	balance: number
}

interface ReturnCalculateWithdrawData {
	totalToReceive: number,
	fees: number,
	totalWithdraw: number,
}

// eslint-disable-next-line import/prefer-default-export
export class WithdrawUtils {
	/**
	 * Calculate inverse fees based on the input percentage
	 *
	 * @param {BaseCalcFeeParam} params includes totalAmount: amount on which the fees should be calculated
	 * feePercentage: fee percent (not in decimal)
	 *
	 * @return {
	 * 		total: number,
	 * 		fees: number,
	 * 		amountAfterFeeDeduction: number,
	 * 	}
	 * */
	static calculateFeesInverse(
		params: BaseCalcFeeParam,
	): ReturnCalculateFeesInverse {
		const { totalAmount, feePercentage } = params;
		const fees = Math.max(1, totalAmount - Math.floor(totalAmount / (1 + (feePercentage * 0.01))));
		return {
			total: totalAmount,
			fees,
			amountAfterFeeDeduction: totalAmount - fees,
		};
	}

	/**
	 * Calculate normal fees based on the input percentage
	 *
	 * @param {BaseCalcFeeParam} params includes totalAmount: amount on which the fees should be calculated
	 * feePercentage: fee percent (not in decimal)
	 *
	 * @return ReturnCalculateFees
	 * */
	static calculateFees(
		params: BaseCalcFeeParam,
	): ReturnCalculateFees {
		const { totalAmount, feePercentage } = params;
		const fees = Math.max(1, Math.ceil(totalAmount * (feePercentage * 0.01)));
		return {
			total: totalAmount,
			fees,
			totalWithdraw: totalAmount + fees,
		};
	}

	/**
	 * Calculation of fees
	 * * When user selects the amount, calculate the fees on that amount, add that fees in that amount and
	 * show user that total will be withdrawn from the wallet
	 * * If user tries to withdraw 100% amount in the wallet, reverse the formula, calculate the fees and
	 * send the amount before the fees to the server and show user that 100% amount will be deducted
	 * * If user tries to withdraw some funds and for calculated fees user don't have the adequate balance
	 * withdraw that amount and calculate fees based on inverse calculation and withdraw the amount which
	 * user is trying to withdraw fees included in it and user will receive amount excluding the fees.
	 * * If user tries to withdraw full amount and receiving amount is set in terms of 102, 203
	 * calculate the fees on these amounts and then add that fees to these amounts which should be total
	 * withdrawn amount.
	 *
	 * @param {CalcWithdrawDataParam} params containing balance, totalAmount and feePercentage
	 *
	 * @return ReturnCalculateWithdrawData
	 * */
	static calculateWithdrawData(
		params: CalcWithdrawDataParam,
	): ReturnCalculateWithdrawData {
		const { totalAmount, feePercentage, balance } = params;
		let finalFees: number;
		let withdrawAmount: number;
		let willReceiveAmount: number;
		const { fees, totalWithdraw } = this.calculateFees({ feePercentage, totalAmount });
		finalFees = fees;
		withdrawAmount = totalWithdraw;
		willReceiveAmount = totalAmount;
		if ((fees + totalAmount) > balance) {
			const { fees: feesInverse, amountAfterFeeDeduction } = this.calculateFeesInverse({ feePercentage, totalAmount: balance });
			finalFees = feesInverse;
			withdrawAmount = balance;
			willReceiveAmount = amountAfterFeeDeduction;
			const {
				fees: normalFees,
				totalWithdraw: normalTotalWithdraw,
			} = this.calculateFees({
				totalAmount: amountAfterFeeDeduction,
				feePercentage,
			});
			if (normalFees !== feesInverse) {
				finalFees = normalFees;
				willReceiveAmount = amountAfterFeeDeduction;
				withdrawAmount = normalTotalWithdraw;
			}
		}
		return {
			totalToReceive: willReceiveAmount,
			fees: finalFees,
			totalWithdraw: withdrawAmount,
		};
	}

	static calculateStepsForSliding(amount: number, totalBalance: number): number {
		let finalAmount = (amount / totalBalance);
		const step = 1 / 4;
		const snapRange = step / 3;
		const steppedNewAmount = (Math.round((finalAmount * 100) / (step * 100)) * (step * 100)) / 100;

		if (Math.abs(steppedNewAmount - finalAmount) <= snapRange) {
			finalAmount = steppedNewAmount;
		} else {
			finalAmount = Math.round(finalAmount * 100) / 100;
		}

		return Math.round(finalAmount * totalBalance);
	}
}
