import React, { useEffect } from "react";
import {
	Navigate, Route, Routes, useSearchParams,
} from "react-router-dom";
import { cu, AppMode, ThemeOptions } from "@credo/utilities";
import { LazyLoad } from "@credo/ui-components";
import { useAtom } from "jotai";
import privateRoutesConfig from "../config/PrivateRoutesConfig";
import PrivateRoutes from "./PrivateRoutes";
import {
	ANIMATIONS_PAGE,
	AUTH_URL,
	COOKIE_USE,
	DEV_PAGE,
	HOME_PAGE,
	LINKEDIN_AUTH,
	LINKEDIN_AUTH_MOBILE,
	OTHER_USER_PROFILE_PAGE,
	PAGE_404_URL,
	POST_DETAIL_PAGE,
	PRIVACY_POLICY_URL,
	PROFILE_PAGE, REGISTER_CONFIRM,
	REGISTER_INTEREST,
	REGISTER_PAGE,
	REPORT_A_PROBLEM_PAGE,
	ROOT_URL,
	SIGN_IN_VIA_EMAIL,
	STREAM_MEMBERS_PAGE,
	STREAM_FOLLOWERS_PAGE,
	STREAM_PROFILE_PAGE,
	STREAM_SETTING_PAGE,
	SUPPORT_PAGE,
	SUPPORT_URL,
	TERMS_AND_CONDITIONS_URL,
	USER_ALL_POSTS,
	BLASTS_PAGE,
	NEWS_PAGE,
	REVIEW_PAGE,
	TOP_PAGE,
	ENGAGE_PAGE,
	COMMUNITIES_PAGE,
} from "./constants";
import PublicRoutes from "./PublicRoutes";
import { DEV_MODE } from "../config/constants";
import "react-toastify/dist/ReactToastify.css";
import { PartialPrivateRoute } from "./PartialPrivateRoute";
import ScrollToTop from "../components/ScrollToTop";
import { HomePage } from "../modules/home/HomePage";
import { UserProfile } from "../modules/user/UserProfile";
import { StreamProfile } from "../modules/stream/StreamProfile";
import { DeveloperPage } from "../modules/dev/DeveloperPage";
import { AnimationsPage } from "../modules/dev/Animation";
import { PageNotFound } from "../modules/PageNotFound";
import { PostDetails } from "../modules/post/PostDetails";
import { UserAllPosts } from "../modules/user/UserAllPosts";
import { OtherUserProfile } from "../modules/user/OtherUserProfile";
import { ReportAProblemPage } from "../modules/settings/ReportAProblem";
import { SignUpSetUserExpertise } from "../modules/auth/SignUpSetUserExpertisePage";
import { RegisterPage } from "../modules/auth/RegisterPage";
import { LinkedInMobile } from "../modules/auth/LinkedInMobile";
import { Linkedin } from "../modules/auth/LinkedIn";
import { SignInViaEmail } from "../modules/auth/SignInViaEmail";
import { AppUtils, Consts } from "../utils";
import { ConfirmRegPage } from "../modules/auth/ConfirmRegPage";
import PrivateSupportRoute from "./PrivateSupportRoute";
import { LastActionListener } from "../components/LastActionListener";
import StreamSettingsMenuScreen from "../modules/stream/StreamSettingsMenuScreen";
import StreamViewMembersScreen from "../modules/stream/StreamViewMembersScreen";
import StreamViewFollowersScreen from "../modules/stream/StreamViewFollowersScreen";
import { HomeBlasts } from "../modules/home/HomeBlasts";
import { HomeNews } from "../modules/home/HomeNews";
import { HomeReview } from "../modules/home/HomeReview";
import { HomeTop } from "../modules/home/HomeTop";
import { isSessionAuthAtom } from "../utils/atoms";

const CookiePolicyPage = LazyLoad(
	() => import(/* webpackChunkName: "CookiePolicyPage" */ "../modules/legal/CookiePolicyPage"),
	(module) => module.CookiePolicyPage,
);

const PrivacyPolicyPage = LazyLoad(
	() => import(/* webpackChunkName: "PrivacyPolicyPage" */ "../modules/legal/PrivacyPolicyPage"),
	(module) => module.PrivacyPolicyPage,
);

const TermsNConditionPage = LazyLoad(
	() => import(/* webpackChunkName: "TermsNConditionPage" */ "../modules/legal/TermsNConditionPage"),
	(module) => module.TermsNConditionPage,
);

const SupportPage = LazyLoad(
	() => import(/* webpackChunkName: "SupportPage" */ "../modules/legal/SupportPage"),
	(module) => module.SupportPage,
);

const PreLoginSupportPage = LazyLoad(
	() => import(/* webpackChunkName: "PreLoginSupportPage" */ "../modules/settings/Support/PreLoginSupportPage"),
	(module) => module.PreLoginSupportPage,
);

const BaseWrapper = LazyLoad(
	() => import(/* webpackChunkName: "BaseWrapper" */ "../components/BaseWrapper"),
	(module) => module.default,
);

const RoutesMain = () => {
	const [searchParams, setSearchParam] = useSearchParams();
	const mode = searchParams.get(Consts.mode);
	const regcode = searchParams.get(Consts.regCode);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);

	useEffect(() => {
		/**
		 * Mainly used for toggling theme when opening through
		 * Push notifications, if the link has mode=c it should
		 * change the mode to credo mode.
		 * */
		if (mode) {
			AppUtils.toggleTheme(mode === AppMode.EGO ? ThemeOptions.EGO : ThemeOptions.CREDO);
			// Clean up the url after changing the mode
			searchParams.delete(Consts.mode);
			setSearchParam(searchParams);
		}
	}, []);

	useEffect(() => {
		if (regcode) {
			cu.setGlobalVar(Consts.regCode, regcode);
		}
	}, [regcode]);

	return (
		<div>
			<ScrollToTop />
			<LastActionListener />
			<BaseWrapper>
				<Routes>
					<Route path="/" element={<Navigate to={isSessionAuth ? HOME_PAGE : NEWS_PAGE} />} />
					<Route path={ENGAGE_PAGE} element={<Navigate to={`${ENGAGE_PAGE}${COMMUNITIES_PAGE}`} />} />
					<Route path={AUTH_URL} element={<PrivateRoutes />}>
						{privateRoutesConfig?.map((routeDetails, index) => {
							const {
								// @ts-ignore
								path, component: Component, children, ...rest
							} = routeDetails;
							return (
								// eslint-disable-next-line react/no-array-index-key
								<Route {...rest} key={`${path}-${index}`} path={`${AUTH_URL}${path}`} element={<Component />}>
									{children?.map((details: any) => (
										<Route
											{...details}
											key={details.path}
											path={`${AUTH_URL}${details.path}`}
											element={<details.component />}
										/>
									))}
								</Route>
							);
						})}
					</Route>
					<Route path={ROOT_URL} element={<PublicRoutes />}>
						<Route path={SIGN_IN_VIA_EMAIL} element={<SignInViaEmail />} />
						<Route path={LINKEDIN_AUTH} element={<Linkedin />} />
						<Route path={LINKEDIN_AUTH_MOBILE} element={<LinkedInMobile />} />
					</Route>
					<Route element={<PartialPrivateRoute />}>
						<Route path={REGISTER_CONFIRM} element={<ConfirmRegPage />} />
						<Route path={REGISTER_PAGE} element={<RegisterPage />} />
						<Route path={REGISTER_INTEREST} element={<SignUpSetUserExpertise />} />
					</Route>
					<Route path={HOME_PAGE} element={<HomePage />} />
					<Route path={BLASTS_PAGE} element={<HomeBlasts />} />
					<Route path={NEWS_PAGE} element={<HomeNews />} />
					<Route path={REVIEW_PAGE} element={<HomeReview />} />
					<Route path={TOP_PAGE} element={<HomeTop />} />
					<Route path={PROFILE_PAGE} element={<UserProfile />} />
					<Route path={REPORT_A_PROBLEM_PAGE} element={<ReportAProblemPage />} />
					<Route element={<PrivateSupportRoute />}>
						<Route path={SUPPORT_PAGE} element={<PreLoginSupportPage />} />
					</Route>
					<Route path={COOKIE_USE} element={<CookiePolicyPage />} />
					<Route path={PRIVACY_POLICY_URL} element={<PrivacyPolicyPage />} />
					<Route path={TERMS_AND_CONDITIONS_URL} element={<TermsNConditionPage />} />
					<Route path={SUPPORT_URL} element={<SupportPage />} />
					<Route path={`${STREAM_PROFILE_PAGE}/:struname${STREAM_MEMBERS_PAGE}`} element={<StreamViewMembersScreen />} />
					<Route path={`${STREAM_PROFILE_PAGE}/:struname${STREAM_FOLLOWERS_PAGE}`} element={<StreamViewFollowersScreen />} />
					<Route path={`${STREAM_PROFILE_PAGE}/:struname${STREAM_SETTING_PAGE}`} element={<StreamSettingsMenuScreen />} />
					<Route path={`${STREAM_PROFILE_PAGE}/:struname`} element={<StreamProfile />} />
					<Route path={`${OTHER_USER_PROFILE_PAGE}/*`} element={<OtherUserProfile />} />
					<Route path={`${USER_ALL_POSTS}/*`} element={<UserAllPosts />} />
					<Route path={`${POST_DETAIL_PAGE}/*`} element={<PostDetails />} />
					<Route path="*" element={<Navigate to={PAGE_404_URL} />} />
					<Route path={PAGE_404_URL} element={<PageNotFound />} />
					{DEV_MODE && (
						<>
							<Route path={DEV_PAGE} element={<DeveloperPage />} />
							<Route path={ANIMATIONS_PAGE} element={<AnimationsPage />} />
						</>
					)}
				</Routes>
			</BaseWrapper>
		</div>
	);
};
export default RoutesMain;
