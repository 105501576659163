import {
	BoostBoardItem,
	BoostTag,
	cu,
	DataStatus,
	dbg,
	GenericResponse,
	LogMgr,
	PostBoostInfo,
	SessMgr,
	YesNoOptions,
	WalletSummaryForGraph,
} from "@credo/utilities";
import { GlobalState } from "@credo/store";
import { MsgMgr } from "../../../config/MsgMgr";
import { Consts, MsgConst } from "../../../utils";
import CfgMgr from "../../../config/CfgMgr";
import {
	BoostHistory,
	BoostRankFeedRequest,
	GenericBoostResponse,
	PostBoostInfoRequest,
	VoltsBoostHistoryRequest,
	WalletHistory,
	WalletHistoryRequest,
	WalletSummary,
	WalletSummaryRequest,
} from "./types";

// eslint-disable-next-line import/prefer-default-export
export class CredoBoost {
	/**
	 * Get boostable tags which can be used to filter
	 * the Top Feed.
	 * */
	static getTags(
		errorCallback?: (response: any) => void,
	) {
		const request = {
			user_id: SessMgr.getFromSession(Consts.user_id),
		};
		const messageName = MsgConst.getBoostBoardInfo;
		MsgMgr.makeRemoteRequest_generic<{ user_id: string }, GenericResponse<BoostTag[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request,
			successCallback: (response: GenericResponse<BoostTag[]>) => {
				if (dbg) LogMgr.mydbg(`CredoBoost.getTags got success message from ${messageName}: `, response);
				if (response.items.length > 0) {
					const formatTimeInData: BoostTag[] = response.items.map((item: BoostTag) => ({
						...item,
						viewership_income_for_graph: item.viewership_income.map((income) => ({
							value: income.income,
							label: new Date(income.date),
						})),
					}));
					GlobalState.Boost.tags.set({
						data: formatTimeInData,
						dataStatus: DataStatus.LOADED,
					});
				}
			},
			errorCallback: (response) => {
				if (dbg) LogMgr.mydbg(`CredoBoost.getTags got error message from ${messageName}: `, response);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	/**
	 * returns the array of items/posts which is used to display
	 * the board of boosted post based on the tags which is being
	 * passed in the param.
	 * */
	static getRankedFeed(
		request: {
			filterByTags: string[],
		},
		successCallback: (
			response: GenericBoostResponse<BoostBoardItem[]>
		) => void,
		errorCallback: any,
	) {
		const requestMerged: BoostRankFeedRequest = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: cu.getAppMode(),
			fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
			summary: YesNoOptions.NO,
			// APIVersion: "v1",
			...request,
		};

		const messageName = MsgConst.getBoostBoard;

		MsgMgr.makeRemoteRequest_generic<BoostRankFeedRequest, GenericBoostResponse<BoostBoardItem[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request: requestMerged,
			successCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getRankedFeed got success message from ${messageName} for tags:`,
						JSON.stringify(request.filterByTags),
						"response: ",
						response,
					);
				}
				successCallback(response);
			},
			errorCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getRankedFeed got error message from ${messageName} for tags:`,
						JSON.stringify(request.filterByTags),
						"response: ",
						response,
					);
				}
				errorCallback(response);
			},
		});
	}

	/**
	 * returns the array of items/posts which is used to display
	 * the board of boosted post based on the tags which is being
	 * passed in the param.
	 * */
	static getBoostPostInfo(
		request: {
			postUuid: string,
			tag: string,
		},
		successCallback: (
			response: GenericBoostResponse<PostBoostInfo[]>
		) => void,
		errorCallback: any,
	) {
		const requestMerged: PostBoostInfoRequest = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			// APIVersion: "v1",
			...request,
		};
		const messageName = MsgConst.getPostBoostInfo;

		MsgMgr.makeRemoteRequest_generic<PostBoostInfoRequest, GenericBoostResponse<PostBoostInfo[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request: requestMerged,
			successCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getRankedFeed got success message from ${messageName} for tags:`,
						JSON.stringify(request.tag),
						"response: ",
						response,
					);
				}
				successCallback(response);
			},
			errorCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getRankedFeed got error message from ${messageName} for tags:`,
						JSON.stringify(request.tag),
						"response: ",
						response,
					);
				}
				errorCallback(response);
			},
		});
	}

	static getWalletSummary(
		successCallback?: (response: WalletSummaryForGraph) => void,
		errorCallback?: (response: any) => void,
	) {
		const request = {
			user_id: SessMgr.getFromSession(Consts.user_id),
		};

		const messageName = MsgConst.getUserWalletSummary;

		MsgMgr.makeRemoteRequest_generic<WalletSummaryRequest, GenericBoostResponse<WalletSummary[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request,
			successCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getWalletSummary got success message from ${messageName} `,
						"response: ",
						response,
					);
				}

				let dataFormatted: WalletSummaryForGraph = {
					wallet_balance_history: [],
					boost_value_history: [],
					hasError: true,
					dataStatus: DataStatus.LOADING,
					wallet_balance: 0,
					wallet_24h: "0",
					boost_24h: "0",
					boost_balance: 0,
				};

				if (response.items.length > 0) {
					let wallet_balance: number = 0;
					let wallet_24h: number = 0;
					let boost_balance: number = 0;
					let boost_24h: number = 0;

					const walletDataLength = response.items[0].wallet_balance_history.length;
					if (response.items[0].wallet_balance_history.length > 0) {
						wallet_balance = response.items[0].wallet_balance_history[walletDataLength - 1].balance;
						wallet_24h = wallet_balance - (
							walletDataLength > 1 ? response.items[0].wallet_balance_history[walletDataLength - 2].balance : 0
						);
					}

					const boostDataLength = response.items[0].boost_value_history.length;
					if (response.items[0].boost_value_history.length > 0) {
						boost_balance = response.items[0].boost_value_history[boostDataLength - 1].balance;
						boost_24h = boost_balance - (
							boostDataLength > 1 ? response.items[0].boost_value_history[boostDataLength - 2].balance : 0
						);
					}
					// eslint-disable-next-line no-nested-ternary
					const wallet24HThousandStep: string = wallet_24h > 0
						? wallet_24h > 99999
							? `+${cu.formatNumberWithThousandsSep(wallet_24h)}` : `+${wallet_24h}`
						: wallet_24h < -99999 ? `-${cu.formatNumberWithThousandsSep(Math.abs(wallet_24h))}` : wallet_24h.toString();
					// eslint-disable-next-line no-nested-ternary
					const boost24hThousandStep: string = boost_24h > 0
						? boost_24h > 99999
							? `+${cu.formatNumberWithThousandsSep(boost_24h)}` : `+${boost_24h}`
						: boost_24h < -99999 ? `-${cu.formatNumberWithThousandsSep(Math.abs(boost_24h))}` : boost_24h.toString();
					dataFormatted = {
						wallet_balance_history: response.items[0].wallet_balance_history.map((item) => ({
							label: new Date(item.date),
							value: item.balance,
						})),
						boost_value_history: response.items[0].boost_value_history.map((item) => ({
							label: new Date(item.date),
							value: item.balance,
						})),
						hasError: false,
						dataStatus: DataStatus.LOADED,
						wallet_balance,
						wallet_24h: wallet24HThousandStep,
						boost_24h: boost24hThousandStep,
						boost_balance,
					};
				}
				GlobalState.Volts.summary.set(dataFormatted);
			},
			errorCallback: (error) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getWalletSummary got error from ${messageName} `,
						"error: ",
						error,
					);
				}
				const dataFormatted: WalletSummaryForGraph = {
					wallet_balance_history: [],
					boost_value_history: [],
					hasError: true,
					dataStatus: DataStatus.ERROR,
					wallet_balance: 0,
					wallet_24h: "0",
					boost_24h: "0",
					boost_balance: 0,
				};
				GlobalState.Volts.summary.set(dataFormatted);
				if (errorCallback) {
					errorCallback(error);
				}
			},
		});
	}

	static getWalletHistory(
		request: {
			lastTransactionId: number,
			fetchSize?: number;
		},
		successCallback: (response: WalletHistory[]) => void,
		errorCallback: (error: any) => void,
	) {
		const requestMerged = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			fetchSize: 20,
			...request,
		};

		const messageName = MsgConst.getUserWalletHistory;

		MsgMgr.makeRemoteRequest_generic<WalletHistoryRequest, GenericBoostResponse<WalletHistory[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request: requestMerged,
			successCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getWalletHistory got success message from ${messageName} `,
						"response: ",
						response,
					);
				}
				successCallback(response.items);
			},
			errorCallback: (error) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getWalletHistory got error from ${messageName} `,
						"error: ",
						error,
					);
				}
				errorCallback(error);
			},
		});
	}

	static getBoostHistory(
		request: {
			lastTs: number,
		},
		successCallback: (response: BoostHistory[]) => void,
		errorCallback: (error: any) => void,
	) {
		const requestMerged = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			fetchSize: 20,
			...request,
		};

		const messageName = MsgConst.getUserBoosts;

		MsgMgr.makeRemoteRequest_generic<VoltsBoostHistoryRequest, GenericBoostResponse<BoostHistory[]>>({
			msgName: messageName,
			msgAddr: messageName,
			request: requestMerged,
			successCallback: (response) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getBoostHistory got success message from ${messageName} `,
						"response: ",
						response,
					);
				}
				successCallback(response.items);
			},
			errorCallback: (error) => {
				if (dbg) {
					LogMgr.mydbg(
						`CredoBoost.getBoostHistory got error from ${messageName} `,
						"error: ",
						error,
					);
				}
				errorCallback(error);
			},
		});
	}
}
