import React, { useEffect, useRef, useState } from "react";
import {
	classNames, SvgIcon, BackIcon,
	Spinner, SpinnerLeafType, SnackBarTypeOptions, HomeRequestSkeleton, RequestNoDataIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import {
	AppMode, GenericResponse, useEvtMgrListener, YesNoOptions,
} from "@credo/utilities";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import { isCredoModeAtom } from "@credo/store";
import last from "lodash/last";
import { strings } from "../../i18n/config";
import { OperationType, StreamInfo, TaskType } from "../../utils/types";
import {
	isConnectedAtom, isSessionAuthAtom, userIdAtom,
} from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";
import CfgMgr from "../../config/CfgMgr";
import RequestListItem from "../../components/ListItems/RequestListItem";
import { ServerFeedItem } from "../home/requests";
import { AppUtils, EventConst } from "../../utils";
import { REQUEST_ACTION } from "../../components/ListItems/types";

interface RequestItem {
	id: number;
	"p.creat_ts": number;
	"p.firstName"?: string;
	"p.lastName"?: string;
	"p.mode": string;
	"p.postText": string;
	"p.postUuid": string;
	"p.prof_id": string;
	"p.profilePicRelUrl": string;
	"p.reqType": string;
	"p.s_name"?: string;
	"p.status": string;
	"p.targetStreamId"?: string;
}
export interface StreamJoinRequestsScreenProps {
	onDone: () => void;
	streamInfo: StreamInfo | null;
}

interface RemoveItem {
	postUuid: string;
	attribute: string;
	taskType: string;
	item: any;
	action: any;
}

const StreamJoinRequestsScreen = (props: StreamJoinRequestsScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [userId] = useAtom(userIdAtom);
	const [isLastPage, setIsLastPage] = useState(false);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [loading, setLoading] = useState(true);
	const [requestData, setRequestData] = useState<RequestItem[]>([]);
	const [, setIsLoadMore] = useState<boolean>(false);

	const dataPage = useRef(1);
	const dataSeed = useRef(1);

	const getUserRequests = async (
		inProcessSetter: ((inProcess: boolean) => void) | null,
		operation: OperationType,
	) => {
		try {
			if (inProcessSetter) {
				inProcessSetter(true);
			}
			const requestMsgData = {
				page: dataPage.current,
				seed: dataSeed.current,
				lastTs: (requestData.length > 0 && operation !== OperationType.REPLACE ? last(requestData)?.["p.creat_ts"] : 0) ?? 0,
				lastId: (requestData.length > 0 && operation !== OperationType.REPLACE ? last(requestData)?.id : 0) ?? 0,
				user_id: userId ?? "",
				fetchSize: CfgMgr.cfg_feed_nbOfItems2FetchFromDb,
				targetUserOrProfId: userId ?? "",
				mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
				streamId: `stl${streamInfo?.streamId}`,
				struname: `stl${streamInfo?.struname}`,
				statusFilter: "a",
				summary: YesNoOptions.NO,
				APIVersion: "v1",
			};

			const { items } = await StreamActions.getStreamItems(requestMsgData) as GenericResponse<ServerFeedItem[]>;
			if (items.length < CfgMgr.cfg_feed_nbOfItems2FetchFromDb) {
				setIsLastPage(true);
			}
			if (operation === OperationType.APPEND) {
				setRequestData((prevState: any) => [
					...prevState,
					...items,
				]);
			} else {
				setRequestData(items as any);
			}
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		} catch (e) {
			setLoading(false);
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		}
	};

	useEffect(() => {
		if (isSessionAuth && isConnected && streamInfo && streamInfo?.streamId) {
			getUserRequests(setLoading, OperationType.REPLACE);
		}
	}, [streamInfo]);

	const handleRemoveListItem = (listData: RemoveItem) => {
		const {
			postUuid, attribute, taskType, item, action,
		} = listData;
		if (attribute === "p.postUuid") {
			let newData = requestData.filter((item) => item[attribute] !== postUuid);
			setRequestData(newData);
			newData = newData.slice(0, CfgMgr.cfg_feed_nbOfItems2FetchFromDb);
			setRequestData(newData);
			const userName = item["p.mode"] === AppMode.CREDO ? item["p.username"] : `${item["p.firstName"]} ${item["p.lastName"]}`;
			if (taskType === TaskType.JOIN_REQUEST && action === REQUEST_ACTION.REJECT) {
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: `${strings("StreamJoinRequestsScreen.request_decline_message_part1")
						+ userName + strings("StreamJoinRequestsScreen.request_decline_message_part2")}`,
				});
			}
			if (taskType === TaskType.JOIN_REQUEST && action === REQUEST_ACTION.ACCEPT) {
				AppUtils.showToast({
					type: SnackBarTypeOptions.SUCCESS,
					message: `${strings("StreamJoinRequestsScreen.request_accept_message_part1")
						+ userName + strings("StreamJoinRequestsScreen.request_accept_message_part2")}`,
				});
			}
		}
	};

	useEvtMgrListener(EventConst.removeListItem, handleRemoveListItem);

	const handleLoadMore = () => {
		if (loading || isLastPage) return;
		dataPage.current += 1;
		getUserRequests(setIsLoadMore, OperationType.APPEND);
	};

	const renderRequests = () => (
		requestData.map((item: { id: React.Key | null | undefined; }, i: number) => (
			<RequestListItem
				key={item.id}
				item={{ ...item, streamStyle: isCredoMode ? AppMode.CREDO : AppMode.EGO }}
				isLast={requestData.length - 1 === i}
				onPage
				showBlueDot={false}
			/>
		))
	);

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner leaf_shape={SpinnerLeafType.CIRCLES} width="20px" height="20px" />
			</div>
		);
	};

	const renderRequestSection = () => {
		if (loading) {
			return (
				<div className="max-h-[calc(100vh-4rem-1px-0.5rem)] overflow-hidden">
					<HomeRequestSkeleton skeletonCount={10} />
				</div>
			);
		}

		if (!loading && requestData && requestData.length === 0) {
			return (
				<div
					className={classNames(
						"flex flex-col items-center justify-center mt-20",
					)}
				>
					<SvgIcon
						icon={RequestNoDataIcon}
						title={strings("Assets.no_request")}
					/>
					<p className="text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap px-20">
						{strings("StreamJoinRequestsScreen.no_activity_message")}
					</p>
				</div>
			);
		}

		return (
			<div className={classNames(isMobile ? "px-1" : "")}>
				<InfiniteScroll
					next={handleLoadMore}
					hasMore
					loader={renderFooter()}
					dataLength={requestData.length}
				>
					{renderRequests()}
				</InfiniteScroll>
			</div>
		);
	};

	return (
		<div className="w-full flex flex-col pb-3">
			{/* <div className="flex flex-row items-center -mx-3 pb-2 px-3">
				<SvgIcon
					icon={BackIcon}
					className={classNames(isMobile ? "ml-4" : "ml-2", "cursor-pointer")}
					color="var(--text-base)"
					onClick={onDone}
				/>
				<h4
					className={classNames(
						"text-md font-normal text-basic px-3",
					)}
				>
					{strings("StreamJoinRequestsScreen.title")}
				</h4>
			</div> */}
			{renderRequestSection()}
		</div>
	);
};

export default StreamJoinRequestsScreen;
