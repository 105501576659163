import React from "react";
import { ButtonSize, SecondaryButton } from "@credo/ui-components";

export interface ReportSuccessProps {
	title: string;
	subTitle: string;
	buttonTitle: string;
	onPressButton: () => void;
	icon: any;
}

const ReportSuccess = (props: ReportSuccessProps) => {
	const {
		title,
		subTitle,
		buttonTitle,
		onPressButton,
		icon,
	} = props;

	return (
		<div className="flex flex-col items-center justify-center py-10 px-6">
			{icon}
			<p className="mt-2 text-base text-basic text-center">{title}</p>
			<p className="mt-2 text-sm text-gray-dark text-center font-light">{subTitle}</p>
			<div className="mt-4">
				<SecondaryButton
					size={ButtonSize.MEDIUM}
					label={buttonTitle}
					handleClick={onPressButton}
				/>
			</div>
		</div>
	);
};

export default ReportSuccess;
