import {
	PhoneNumber as ParsedPhoneNumberType,
	AsYouType,
	parsePhoneNumberFromString,
} from "libphonenumber-js/max";
import { LogMgr } from "@credo/utilities";

class PhoneNumber {
	// eslint-disable-next-line class-methods-use-this
	parse(number: any, iso2: any): ParsedPhoneNumberType | undefined {
		try {
			return parsePhoneNumberFromString(number, iso2?.toUpperCase());
		} catch (err: any) {
			LogMgr.mydbg(`Exception was thrown: ${err.toString()}`);
			return undefined;
		}
	}

	isValidNumber(number: any, iso2: any): boolean {
		const phoneInfo: ParsedPhoneNumberType | undefined = this.parse(number, iso2);

		if (phoneInfo && (
			phoneInfo?.isValid() && (
				phoneInfo.getType() === "MOBILE" || phoneInfo.getType() === "FIXED_LINE_OR_MOBILE"
			)
		)) {
			return phoneInfo?.isValid();
		}

		return false;
	}

	// eslint-disable-next-line class-methods-use-this
	isLandlineNumber(number: any, iso2: any): boolean {
		const phoneWithInfo = this.parse(number, iso2);
		if (phoneWithInfo) {
			return phoneWithInfo?.getType() === "FIXED_LINE";
		}

		return false;
	}

	// eslint-disable-next-line class-methods-use-this
	format(number: any, iso2: any) {
		const formatter = new AsYouType(iso2); // eslint-disable-line new-cap
		let formatted;

		number.replace(/-/g, "")
			.replace(/ /g, "")
			.replace(/\(/g, "")
			.replace(/\)/g, "")
			.split("")
			.forEach((n: any) => {
				formatted = formatter.input(n);
			});

		return formatted;
	}
}

export default new PhoneNumber();
