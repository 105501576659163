import { classNames, LazyLoad } from "@credo/ui-components";
import React from "react";
import { isMobile } from "react-device-detect";

export const ChangePhoneNumber = LazyLoad(
	() => import(/* webpackChunkName: "ChangePhoneNumber" */ "./ChangePhoneNumber"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"text-basic",
					"bg-background",
					"overflow-visible",
				)}
			>
				<div
					className={classNames(
						"w-full text-basic overflow-hidden",
						isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
					)}
				/>
			</div>
		),
	},
);

export const PhoneVerification = LazyLoad(
	() => import(/* webpackChunkName: "PhoneVerification" */ "./PhoneVerificationScreen"),
	(module) => module.default,
	{
		fallback: (
			<div
				className={classNames(
					"block",
					"w-full max-w-screen-sm md:min-w-[40rem]",
					"text-basic",
					"bg-background",
					"overflow-visible",
				)}
			>
				<div
					className={classNames(
						"w-full text-basic overflow-hidden",
						isMobile ? "max-h-[calc(100vh-4rem-1px-3rem-4rem)]" : "max-h-[calc(100vh-4rem-1px-3rem)]",
					)}
				/>
			</div>
		),
	},
);
