import { AuthoorisationEngineFactory, AuthorisationEngine } from "./AuthorisationEngine";
import { AppManager, Manager } from "../utils";

export interface AuthorisationManagerProps<KeyT, AuthorisationEngineT extends AuthorisationEngine> {
	factory: AuthoorisationEngineFactory<AuthorisationEngineT>;
	paramsFn: (key: KeyT, props: any) => any;
}

export class AuthorisationManager<KeyT, AuthorisationEngineT extends AuthorisationEngine, ParamsT>
	extends Manager<AuthorisationManagerProps<KeyT, AuthorisationEngineT>> {
	static instance(): AuthorisationManager<any, AuthorisationEngine, any> {
		return AppManager.instance().get(AuthorisationManager.name) as unknown as AuthorisationManager<any, AuthorisationEngine, any>;
	}

	private indexMap = new Map<KeyT, AuthorisationEngineT>();

	private factory: AuthoorisationEngineFactory<AuthorisationEngineT> | undefined;

	private paramsFn: ((key: KeyT, props: any) => ParamsT) | undefined;

	setProps(props: AuthorisationManagerProps<KeyT, AuthorisationEngineT>) {
		super.setProps(props);
		this.factory = props.factory;
		this.paramsFn = props.paramsFn;
	}

	public getEngine(key: KeyT, params: any, forceCreate = false): AuthorisationEngineT {
		if (!this.factory) throw new Error("factory not initialised");

		let ae = this.indexMap.get(key);
		if (!ae || forceCreate) {
			ae = this.factory.create(params);
			this.indexMap.set(key, ae);
		}
		return ae;
	}

	public resetFactory() {
		this.indexMap.clear();
		this.factory?.reset?.();
	}
}

export default AuthorisationManager;
