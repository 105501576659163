import React, { CSSProperties, useMemo } from "react";
import * as d3 from "d3";
import defaultStyles from "../charts.module.css";
import { convertToPixels } from "../../common/utils";

export enum AxisOrientation {
	Top = "top",
	Right = "right",
	Bottom = "bottom",
	Left = "left",
}

export enum AxisType {
	Linear = "linear",
	Time = "time",
}

export type AxisStyles = {
	tickLine: string,
	tickText: string,
	axisLine: string,
	axisText: string,
	gridLine: string,

}

export type AxisProps<T> = {
	type: AxisType,
	width: number,
	domain: T[]; /** axis data range */
	range: number[]; /** axis pixel range */
	plotRange: number[] /** plot pixel range opposite the axis */
	orientation: AxisOrientation;
	className?: string;
	styles?: AxisStyles;
	options?: AxisOptions
	dataLength?: number;
}
declare type LineCoords = {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}
declare type TickCoords = {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
	yLabelOffset: number;
	xLabelOffset: number;
	labelAlign: "middle" | "start" | "end";
	xFactor: number;
	yFactor: number;
}

declare type AxisRenderConfig = {
	size: { height: number; width: number; };
	plotSize: { height: number; width: number; };
	axisLinePoints: string | undefined;
	tickOffsets: TickCoords;
	gridLineCoords: LineCoords;
	axisLineCoords: LineCoords;

}
declare type RenderMemo = {
	scale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
	labelFormat: (d: any) => string;
	renderConfig: AxisRenderConfig;
};

export type AxisOptions = {
	format: ((d: any) => string) | undefined;
	pixelsPerTick: number;
	tickLength: number;
	showGridlines: boolean;
	showAxisLine: boolean;
	showTicks: boolean;
	showTickLabels: boolean;
	labelStyle: CSSProperties | undefined;
	tickStyle: CSSProperties | undefined;
	gridlineStyle: CSSProperties | undefined;
	axisLineStyle: CSSProperties | undefined;
}
export const DEFAULT_AXIS_OPTIONS = {
	THE_WORKS: {
		format: undefined,
		tickLength: 10,
		pixelsPerTick: 30,
		showGridlines: true,
		showAxisLine: true,
		showTickLabels: true,
		showTicks: true,
	} as AxisOptions,
	MINIMAL: {
		format: undefined,
		tickLength: 10,
		pixelsPerTick: 30,
		showGridlines: false,
		showAxisLine: false,
		showTickLabels: true,
		showTicks: true,
	} as AxisOptions,
};

const Axis = ({
	type,
	width,
	domain,
	range,
	plotRange,
	orientation,
	className,
	dataLength,
	styles = defaultStyles,
	options = {
		format: undefined,
		tickLength: 10,
		pixelsPerTick: 30,
		showGridlines: true,
		showAxisLine: true,
		showTickLabels: true,
		showTicks: true,
		labelStyle: undefined,
		tickStyle: undefined,
		gridlineStyle: undefined,
		axisLineStyle: undefined,
	} as AxisOptions,

}: AxisProps<Date | number>) => {
	const { scale, labelFormat, renderConfig } = useMemo((): RenderMemo => {
		// @ts-ignore
		const res: RenderMemo = { renderConfig: {} };
		const isVertical = orientation === AxisOrientation.Left || orientation === AxisOrientation.Right;

		// working options - as we may need to tweak them
		const w_options = {
			...options,
		};

		if (isVertical) {
			res.renderConfig.size = {
				width,
				height: range[0],
			};
			res.renderConfig.plotSize = {
				width: plotRange[1] - plotRange[0],
				height: range[0] - range[1],
			};
		} else {
			res.renderConfig.size = {
				width: range[1],
				height: 0,
			};
			res.renderConfig.plotSize = {
				width: range[1] - range[0],
				height: plotRange[0] - plotRange[1],
			};
		}

		if (!w_options?.showTicks) {
			w_options.tickLength = 0;
		}

		const labelHeight = options?.labelStyle?.fontSize ? convertToPixels(options.labelStyle.fontSize.toString()) : 10;

		switch (orientation) {
			case AxisOrientation.Top:
				res.renderConfig.tickOffsets = {
					x1: 0,
					y1: width - w_options.tickLength - 2,
					yFactor: 0, /* used for offset, since its a horizontal line, no need for movement */
					x2: 0,
					y2: width - 2,
					xFactor: 1,
					yLabelOffset: width - 20,
					xLabelOffset: 0,
					labelAlign: "middle",
				};
				res.renderConfig.axisLineCoords = {
					x1: 0,
					y1: width - w_options.tickLength - 2,
					x2: range[1],
					y2: width,
				};
				res.renderConfig.axisLinePoints = [
					"M", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y2,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y2,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y1,
				].join(" ");

				res.renderConfig.gridLineCoords = {
					x1: 0,
					y1: width + plotRange[0],
					x2: 0,
					y2: width + plotRange[1],
				};

				break;
			case AxisOrientation.Bottom:
				res.renderConfig.tickOffsets = {
					x1: 0,
					y1: res.renderConfig.plotSize.height + 2,
					yFactor: 0, /* used for offset, since its a horizontal line, no need for movement */
					x2: 0,
					y2: res.renderConfig.plotSize.height + w_options.tickLength,
					xFactor: 1,
					yLabelOffset: res.renderConfig.plotSize.height + 20,
					xLabelOffset: 0,
					labelAlign: "middle",
				};
				res.renderConfig.axisLineCoords = {
					x1: 0,
					y1: res.renderConfig.plotSize.height,
					x2: range[1],
					y2: res.renderConfig.plotSize.height + w_options.tickLength + 2,
				};
				res.renderConfig.axisLinePoints = [
					"M", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y2,
					"L", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y2,
				].join(" ");

				res.renderConfig.gridLineCoords = {
					x1: 0,
					y1: plotRange[0],
					x2: 0,
					y2: plotRange[1],
				};

				break;
			case AxisOrientation.Left:
				res.renderConfig.tickOffsets = {
					x1: res.renderConfig.size.width - (w_options.tickLength),
					y1: 0,
					yFactor: 1, /* used for offset, since its a horizontal line, no need for movement */
					x2: res.renderConfig.size.width,
					y2: 0,
					xFactor: 0,
					yLabelOffset: labelHeight * 0.2,
					xLabelOffset: res.renderConfig.size.width - (w_options.tickLength + 4),
					labelAlign: "end",
				};
				res.renderConfig.axisLineCoords = {
					x1: width - w_options.tickLength - 2,
					y1: 0,
					x2: width,
					y2: range[0],
				};
				res.renderConfig.axisLinePoints = [
					"M", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y2,
					"L", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y2,
				].join(" ");

				res.renderConfig.gridLineCoords = {
					x1: res.renderConfig.size.width + plotRange[0],
					y1: 0,
					x2: res.renderConfig.size.width + plotRange[1],
					y2: 0,
				};

				break;
			case AxisOrientation.Right:
				res.renderConfig.tickOffsets = {
					x1: res.renderConfig.plotSize.width + 2,
					y1: 0,
					yFactor: 1, /* used for offset, since its a horizontal line, no need for movement */
					x2: res.renderConfig.plotSize.width + (w_options.tickLength) + 2,
					y2: 0,
					xFactor: 0,
					yLabelOffset: labelHeight / 2,
					xLabelOffset: res.renderConfig.plotSize.width + (w_options.tickLength + 4),
					labelAlign: "start",
				};
				res.renderConfig.axisLineCoords = {
					x1: res.renderConfig.plotSize.width,
					y1: 0,
					x2: res.renderConfig.plotSize.width + w_options.tickLength + 2,
					y2: range[0],
				};
				res.renderConfig.axisLinePoints = [
					"M", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y1,
					"L", res.renderConfig.axisLineCoords.x1, res.renderConfig.axisLineCoords.y2,
					"L", res.renderConfig.axisLineCoords.x2, res.renderConfig.axisLineCoords.y2,
				].join(" ");

				res.renderConfig.gridLineCoords = {
					x1: plotRange[0],
					y1: 0,
					x2: plotRange[1],
					y2: 0,
				};
				break;
			default:
				throw new Error(`Invalid Axis Orientation:${orientation}`);
		}
		switch (type) {
			case AxisType.Time:
				res.scale = d3.scaleTime().domain(domain).range(range);
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				res.labelFormat = w_options.format || d3.timeFormat("%b %d");
				break;
			case AxisType.Linear:
				res.scale = d3.scaleLinear().domain(domain).range(range);
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				res.labelFormat = w_options.format || d3.format("~s");
				break;
			default:
				throw new Error(`Invalid Axis Type:${type}`);
		}

		// eslint-disable-next-line no-param-reassign
		res.labelFormat = res.labelFormat || ((v: any) => v?.toString());

		return res;
	}, [type, domain.join("-"), range.join("-"), options, width, orientation, plotRange.join("-")]);

	const ticks = useMemo(() => {
		const rangeDiff = Math.abs(range[1] - range[0]);

		const numberOfTicksTarget = Math.max(
			1,
			Math.floor(
				rangeDiff / options.pixelsPerTick,
			),
		);

		/**
		 * x-axis should contain {dataLength} because the chart plots should match
		 * the number of datasets and the position of plots in the x-axis
		 * */
		const scaleTicks = scale.ticks(dataLength ?? numberOfTicksTarget) as Date[];

		const finalScaleTicks = scaleTicks.map((value: Date) => ({
			value,
			offset: scale(value),
		}));

		if (dataLength) {
			return finalScaleTicks.map((item) => ({
				...item,
				/**
				 * The first scale has some offset but in chart first dot is on 0,0
				 * coordinate, so we need first offset to be zero and shift others
				 * by same offset
				 * */
				offset: item.offset - finalScaleTicks[0].offset,
			}));
		}

		return finalScaleTicks;
	}, [
		domain.join("-"),
		range.join("-"),
		options.format,
		dataLength,
	]);

	// @ts-ignore
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<svg className={className}>
			{options.showAxisLine && (
				<path
					x2="100%"
					d={renderConfig.axisLinePoints}
					className={styles.axisLine}
					style={options.axisLineStyle}
				/>
			)}

			{ticks.map(({ value, offset }) => (
				<React.Fragment>
					{options.showGridlines && (
						<line
							transform={`translate(${offset * renderConfig.tickOffsets.xFactor} ${offset * renderConfig.tickOffsets.yFactor})`}
							key={`gridline-${offset}`}
							x1={renderConfig.gridLineCoords.x1}
							x2={renderConfig.gridLineCoords.x2}
							y1={renderConfig.gridLineCoords.y1}
							y2={renderConfig.gridLineCoords.y2}
							className={styles?.gridLine}
							style={options.gridlineStyle}
						/>
					)}
					{(options.showTicks || options.showTickLabels) && (
						<g
							key={`tick-${offset}`}
							transform={`translate(${offset * renderConfig.tickOffsets.xFactor} ${offset * renderConfig.tickOffsets.yFactor})`}
						>
							{options.showTicks && (
								<line
									x1={renderConfig.tickOffsets.x1}
									x2={renderConfig.tickOffsets.x2}
									y1={renderConfig.tickOffsets.y1}
									y2={renderConfig.tickOffsets.y2}
									className={styles.tickLine}
									style={options.tickStyle}
								/>
							)}
							{options.showTickLabels && (
								<text
									// @ts-ignore
									key={value.toString()}
									transform={`translate(${renderConfig.tickOffsets.xLabelOffset} ${renderConfig.tickOffsets.yLabelOffset})`}
									className={styles.tickText}
									style={{
										dominantBaseline: "middle",
										textAnchor: renderConfig.tickOffsets.labelAlign,
										...options.labelStyle,
									}}
								>
									{`${labelFormat(value)}`}
								</text>
							)}
						</g>
					)}
				</React.Fragment>
			))}
		</svg>
	);
};

export default Axis;
