import {
	ActionType,
	AppMode, cu, EvtMgr, GenericResponse, ResponseCode, SessMgr, YesNoOptions,
} from "@credo/utilities";
import isEmpty from "lodash/isEmpty";
import { MsgMgr } from "../../config/MsgMgr";
import { Consts, EventConst, MsgConst } from "../../utils";
import { EntityType, TagActions, TagCategory } from "../../utils/types";

export interface TagsResponse<T> {
	retcd: ResponseCode;
	items: T;
}

interface BaseSearchTagRequest {
	user_id?: string | null,
	mode: AppMode,
	what: string,
}

export interface SearchTagsRequest extends BaseSearchTagRequest {
	s_uxptag: YesNoOptions,
}

export interface SearchRequestObject extends BaseSearchTagRequest {
	s_users?: string;
	s_streams?: string;
}

interface SearchSuggestionsRequest extends BaseSearchTagRequest {
	s_users?: YesNoOptions,
}
interface AllTagsRequest extends BaseSearchTagRequest {
	tag_dictionary: YesNoOptions;
}

interface Items {
	cachedTags: string[];
	categories: {
		[key: string]: TagCategory;
	}
}

export interface GlobalSearchResponse {
	firstName?: string;
	lastName?: string;
	username?: string;
	dest_id: string;
	hPostUuid: string;
	etype: EntityType;
	profilePicRelUrl: string;
	entityId: string,
	id_verif_status: YesNoOptions,
	dest_id_verif_status: YesNoOptions,
	egousername?: string;
	// stream user name
	struname?: string;
}

export interface GlobalSearchRequest extends BaseSearchTagRequest {
	s_users?: YesNoOptions,
	s_streams?: YesNoOptions,
	s_history?: YesNoOptions,
	etype?: string;
	action?: string;
	fetchSize?: number;
}

export interface DeleteSearchHistoryRequest extends BaseSearchTagRequest {
	s_history?: YesNoOptions
	action?: ActionType,
	dest_id: string;
}

export interface GlobalSearchItem {
	title: string | undefined;
	username?: string;
	id: string;
	hPostUuid?: string;
	isVerified: boolean;
	etype: EntityType;
	isCredoUser?: boolean;
	profilePic: string;
	category?: string,
	isHistory?: boolean;
	firstName?: string;
}

// eslint-disable-next-line import/prefer-default-export
export class SearchActions {
	static async retrieveAllExistingTags(
		request: AllTagsRequest,
		successCallback?: (response: TagsResponse<string>) => void,
		errorCallback?: (response: TagsResponse<string>) => void,
	) {
		EvtMgr.getInstance(EventConst.allTagsLoading).notifyListeners(true);
		await MsgMgr.makeRemoteRequest_generic<AllTagsRequest, TagsResponse<string>>({
			request,
			msgName: MsgConst.getSearchData,
			successCallback: (response) => {
				// extract tag keys which will be displayed in the component
				const formattedItems: Items = JSON.parse(response.items);
				const formattedTags = Object.keys(formattedItems.categories).map((tag: string) => ({
					tag,
					...formattedItems.categories[tag],
					tags: [
						/**
						 * Added own category as a tag since we need stream
						 * when user selects this category as a tag from
						 * searched list of the tags
						 * */
						tag,
						...formattedItems.categories[tag].tags,
					],
				})).filter((singleTag) => !singleTag.tag.startsWith("_"));
				// eslint-disable-next-line no-underscore-dangle
				// TODO: Check how much time does it take or replace it with lodash
				const formatAllSubTags = formattedTags.map((tag) => tag.tags.map((subTag) => ({
					tag: subTag,
					stream: tag.stream,
					parentTag: tag.tag,
				})));
				EvtMgr.getInstance(EventConst.setAllTags).notifyListeners(formattedTags);
				EvtMgr.getInstance(EventConst.setAllSubTags).notifyListeners({
					tags: formatAllSubTags.flat(),
					action: TagActions.REPLACE,
				});
				if (successCallback) {
					EvtMgr.getInstance(EventConst.allTagsLoading).notifyListeners(false);
					successCallback(response);
				}
			},
			errorCallback: (response) => {
				EvtMgr.getInstance(EventConst.allTagsLoading).notifyListeners(false);
				if (errorCallback) {
					errorCallback(response);
				}
			},
		});
	}

	static async retrieveSearchedTags(
		request: SearchTagsRequest,
		successCallback: (response: TagsResponse<any>) => void,
		errorCallback: (response: TagsResponse<any>) => void,
	) {
		await MsgMgr.makeRemoteRequest_generic<SearchTagsRequest, TagsResponse<any>>({
			request,
			msgName: MsgConst.getSearchData,
			successCallback,
			errorCallback,
		});
	}

	static async getSearchResults(
		request: SearchRequestObject,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		await MsgMgr.makeRemoteRequest_generic<SearchRequestObject, GenericResponse<any>>({
			request,
			msgName: MsgConst.getSearchData,
			successCallback,
			errorCallback,
		});
	}

	static async globalSearch(
		request: GlobalSearchRequest,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		// When user is not logged in guest should be able to search on noAuth address
		const msgAddr = cu.isSet(SessMgr.getFromSession(Consts.user_id))
			? MsgConst.getSearchData : MsgConst.getNoAuthSearchData;

		await MsgMgr.makeRemoteRequest_generic<GlobalSearchRequest, GenericResponse<GlobalSearchResponse[]>>({
			request,
			msgName: msgAddr,
			successCallback: (response: GenericResponse<GlobalSearchResponse[]>) => {
				// TODO: Remove optional GlobalSearchResponse since we will not get the result without etype
				let formattedData: (GlobalSearchItem | GlobalSearchResponse)[] = [];

				if (!isEmpty(response.items)) {
					formattedData = response.items.map((singleItem: GlobalSearchResponse): GlobalSearchItem | GlobalSearchResponse => {
						const {
							firstName,
							lastName,
							username,
							entityId,
							id_verif_status,
							dest_id_verif_status,
							profilePicRelUrl,
							etype,
							struname,
							hPostUuid,
							egousername,
						} = singleItem;

						const intersectingKeysUS = {
							id: entityId,
							isVerified: cu.isSet(id_verif_status || dest_id_verif_status)
								&& !cu.isNo(id_verif_status || dest_id_verif_status),
							profilePic: profilePicRelUrl,
							hPostUuid,
							etype,
						};

						switch (etype) {
							case EntityType.USER:
								return {
									title: username || `${firstName} ${lastName}`,
									username: username || egousername,
									isCredoUser: !cu.isSet(firstName),
									...intersectingKeysUS,
								};
							case EntityType.STREAM:
								return {
									title: firstName,
									category: lastName,
									username: struname,
									...intersectingKeysUS,
								};
							default: return singleItem;
						}
					});
				}

				if (successCallback) {
					successCallback({
						...response,
						items: formattedData,
					});
				}
			},
			errorCallback,
		});
	}

	static async globalSearchHistory(
		request: GlobalSearchRequest,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		await MsgMgr.makeRemoteRequest_generic<GlobalSearchRequest, GenericResponse<GlobalSearchResponse[]>>({
			request,
			msgName: MsgConst.getSearchData,
			successCallback: (response: GenericResponse<GlobalSearchResponse[]>) => {
				// TODO: Remove optional GlobalSearchResponse since we will not get the result without etype
				let formattedData: (GlobalSearchItem | GlobalSearchResponse)[] = [];

				if (!isEmpty(response.items)) {
					formattedData = response.items.map((singleItem: GlobalSearchResponse): GlobalSearchItem | GlobalSearchResponse => {
						const {
							firstName,
							lastName,
							username,
							dest_id,
							id_verif_status,
							dest_id_verif_status,
							profilePicRelUrl,
							etype,
							struname,
							egousername,
							hPostUuid,
						} = singleItem;

						const intersectingKeysUS = {
							id: dest_id,
							isVerified: cu.isSet(id_verif_status || dest_id_verif_status)
								&& !cu.isNo(id_verif_status || dest_id_verif_status),
							profilePic: profilePicRelUrl,
							etype,
							isHistory: true,
							hPostUuid,
						};

						switch (etype) {
							case EntityType.USER:
								return {
									title: username || `${firstName} ${lastName}`,
									isCredoUser: !cu.isSet(firstName),
									username: username || egousername,
									...intersectingKeysUS,
								};
							case EntityType.STREAM:
								return {
									title: firstName,
									category: lastName,
									username: struname,
									...intersectingKeysUS,
								};
							default: return singleItem;
						}
					});
				}

				if (successCallback) {
					successCallback({
						...response,
						items: formattedData,
					});
				}
			},
			errorCallback,
		});
	}

	static async deleteSearchHistory(
		request: DeleteSearchHistoryRequest,
	) {
		await MsgMgr.makeRemoteRequest_generic({
			request,
			msgName: MsgConst.getSearchData,
		});
	}

	static async getUserSuggestions(
		request: SearchSuggestionsRequest,
		successCallback: (response: TagsResponse<any>) => void,
		errorCallback: (response: TagsResponse<any>) => void,
	) {
		await MsgMgr.makeRemoteRequest_generic<SearchSuggestionsRequest, GenericResponse<GlobalSearchResponse[]>>({
			request,
			msgName: MsgConst.getSearchData,
			successCallback,
			errorCallback,
		});
	}

	static async fetchMentionsSuggestions(msgData: any) {
		let suggestionData: any = [];
		if (msgData && SessMgr.isSessionAuth()) {
			const request: GlobalSearchRequest = {
				mode: cu.getAppMode(),
				s_users: YesNoOptions.YES,
				user_id: SessMgr.getFromSession(Consts.user_id),
				fetchSize: Consts.mentionUserFetchSize,
				what: msgData.what,
			};
			await SearchActions.getUserSuggestions(
				request,
				(response: any) => {
					if (cu.isSet(response.items)) {
						suggestionData = response.items?.map((element: any, idx: any) => ({
							name: element.egousername ?? element.username,
							title: element.username ? element.username : `${element.firstName} ${element.lastName}`,
							link: cu.buildSourceUrlImage(element.profilePicRelUrl || ""),
							avatar: cu.buildSourceUrlImage(element.profilePicRelUrl || ""),
							id: idx,
							...element,
						}));
					}
				},
				() => { },
			);
		}
		return suggestionData;
	}
}
