/* eslint-disable no-prototype-builtins */
import {
	ActionType,
	AppMode, AuthorisationManager,
	ConnInfoState,
	cu,
	dbg,
	EvtMgr, GenericResponse,
	LogMgr,
	SessMgr,
	ThemeOptions,
	useEvtMgrListener,
	UserSession,
	YesNoOptions,
} from "@credo/utilities";
import { useWSEvtMgrListener, WSConnStat } from "@credo/websocket";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import React, { useEffect } from "react";
import { isIOS } from "react-device-detect";
import { Snackbar, SnackBarTypeOptions } from "@credo/ui-components";
import { toast } from "react-toastify";
import sum from "lodash/sum";
import moment from "moment";
import { getAnalytics, logEvent } from "firebase/analytics";
import isEmpty from "lodash/isEmpty";
import {
	atomSetterAtm, isCredoModeAtom, UpdateStoreMessage, EventConsts, removedHintsAtom, userWalletAtom,
} from "@credo/store";
import {
	allTagsAtom,
	applicationModeAtom,
	authModalContentAtom,
	deviceTokenAtom,
	hasPushNotifPermAtom,
	isConnectedAtom,
	qrCodeLoginAtom,
	selectedInterestsAtom,
	showLoginModalAtom,
	showNewPostModalAtom,
	pendingTasksAtom,
	showSocialLoadingAtom,
	socialEmailCredAtom,
	streamJoinReqCountAtom,
	userProfileAtom,
	userSessionAtom,
	userExperienceAtom,
	userQualificationAtom,
	allCachedImagesAtom,
	userEgoRecentPostAtom,
	userSearchHistoryAtom,
	showDownloadAppModalAtom,
	newPostUserStreamsAtom,
	allSubTagsAtom,
	userStreamsPickerDataAtom,
	currentParentHomeAtom,
	userNotifsReqCountAtom,
	allTagsLoadingAtom,
	signUpInProgressAtom,
	showLogoutModalAtom,
	feedDataAtom,
	newsDataAtom,
	blastDataAtom,
	lastFeedTabIndexAtom,
	feedUxpRatingCardsAtom,
	feedSugConnCardsAtom,
	showHomeSuspenseAtom,
	confirmationModalData, gotMixFeedDataAtom, ratingTutorialModalAtom,
	userMetaDataAtom, email4SignInLinkToEmailAtom, emailRegInProcessAtom,
	credoGraphDataAtom,
} from "../utils/atoms";
import {
	AppUtils,
	clearStorage,
	ClientMetaData, ClientMetaDataMapping,
	Consts,
	EventConst,
	MsgConst,
	toggleColorTheme,
	useGetIPAddress,
	useLinkedInAuthentication,
} from "../utils";
import { HOME_PAGE, NEWS_PAGE } from "../routes/constants";
import { MsgMgr } from "../config/MsgMgr";
import { useFirebaseRedirectResponse } from "../utils/hooks/useFirebaseRedirectResponse";
import FirebaseMgr from "../utils/FirebaseMgr";
import {
	AuthModalContentType, NotiReqCount, ParentNavTypes, SocialEmailCred, Tag,
	ConfirmationModalProps,
	TagActions,
} from "../utils/types";
import { GlobalSearchItem, SearchActions } from "../services/actions/search";
import { NewPostData } from "../services/actions/new_post";
import { FeedItem } from "../modules/home/requests";
import CfgMgr from "../config/CfgMgr";
import { UserActions } from "../services/actions/user";

const GlobalListeners = () => {
	const navigate = useNavigate();
	const AES = require("crypto-js/aes");
	let isNetworkConnected: boolean = false;
	const setIsConnected = useSetAtom(isConnectedAtom);
	const [userInfo, setUserProfile] = useAtom(userProfileAtom);
	const [userSession, setUserSession] = useAtom(userSessionAtom);
	const [appMode, setAppMode] = useAtom(applicationModeAtom);
	const [deviceToken] = useAtom(deviceTokenAtom);
	const [, setQRWebLoginCode] = useAtom(qrCodeLoginAtom);
	const setHasPushNotifPerm = useSetAtom(hasPushNotifPermAtom);
	const setDeviceToken = useSetAtom(deviceTokenAtom);
	const setShowLoginModal = useSetAtom(showLoginModalAtom);
	const [pendingTasks, setPendingTasks] = useAtom(pendingTasksAtom);
	const setShowNewPostModal = useSetAtom(showNewPostModalAtom);
	const setSocialLoadingModal = useSetAtom(showSocialLoadingAtom);
	const setStreamJoinReqCount = useSetAtom(streamJoinReqCountAtom);
	const setIsCredoMode = useSetAtom(isCredoModeAtom);
	const setUserExperience = useSetAtom(userExperienceAtom);
	const setCachedImages = useSetAtom(allCachedImagesAtom);
	const setUserQualification = useSetAtom(userQualificationAtom);
	const setAllTags = useSetAtom(allTagsAtom);
	const [allSubTags, setAllSubTags] = useAtom(allSubTagsAtom);
	const setUserSelectedInterest = useSetAtom(selectedInterestsAtom);
	const setUserEgoRecentPostData = useSetAtom(userEgoRecentPostAtom);
	const showDownloadAppModal = useSetAtom(showDownloadAppModalAtom);
	const setConfirmationModalData = useSetAtom(confirmationModalData);
	const setAuthContent = useSetAtom(authModalContentAtom);
	const setSocialCred = useSetAtom(socialEmailCredAtom);
	const setUserSearchHistory = useSetAtom(userSearchHistoryAtom);
	const setNewPostUserStreams = useSetAtom(newPostUserStreamsAtom);
	const setUserStreamsPickerData = useSetAtom(userStreamsPickerDataAtom);
	const setActiveNavLink = useSetAtom(currentParentHomeAtom);
	const setTagsLoadingAtom = useSetAtom(allTagsLoadingAtom);
	const setLogoutModal = useSetAtom(showLogoutModalAtom);
	const setGotMixFeedData = useSetAtom(gotMixFeedDataAtom);
	const updateFeedData = useSetAtom(feedDataAtom);
	const feedData = useAtomValue(feedDataAtom);
	const updateNewsData = useSetAtom(newsDataAtom);
	const newsData = useAtomValue(newsDataAtom);
	const updateBlastData = useSetAtom(blastDataAtom);
	const blastData = useAtomValue(blastDataAtom);
	const [notifReqCount, setNotifReqCounts] = useAtom(userNotifsReqCountAtom);
	const [signUpInProgress] = useAtom(signUpInProgressAtom);
	const setUpdateAtom = useSetAtom(atomSetterAtm);
	const updateFeedActiveIndex = useSetAtom(lastFeedTabIndexAtom);
	const homeFeedActiveIndex = useAtomValue(lastFeedTabIndexAtom);
	const updateHomeSupsense = useSetAtom(showHomeSuspenseAtom);
	const setUserRatingCard = useSetAtom(feedUxpRatingCardsAtom);
	const setSuggConnCard = useSetAtom(feedSugConnCardsAtom);
	const setRatingTutorialModal = useSetAtom(ratingTutorialModalAtom);
	const setUserMetaData = useSetAtom(userMetaDataAtom);
	const setEmailInStorage = useSetAtom(email4SignInLinkToEmailAtom);
	const setEmailRegInProc = useSetAtom(emailRegInProcessAtom);
	const setCredoGraphData = useSetAtom(credoGraphDataAtom);
	const setRemovedHintsData = useSetAtom(removedHintsAtom);
	const setWalletBalance = useSetAtom(userWalletAtom);

	const cleanFeedData = () => {
		updateFeedData([]);
		updateNewsData([]);
		updateBlastData([]);
	};

	const setConnectionInfo = (state: ConnInfoState) => {
		if (state === ConnInfoState.OPEN) {
			setIsConnected(true);
		} else if (!WSConnStat.isMetCond(WSConnStat.IS_NETWORK)) {
			setIsConnected(false);
		}
	};

	const setPushNotificatioPerm = (hasPerm: boolean) => {
		setHasPushNotifPerm(hasPerm);
	};

	const saveDeviceToken = (token: string) => {
		setDeviceToken(token);
	};

	const onConnect = () => {
		isNetworkConnected = true;
		setTimeout(() => {
			if (isNetworkConnected && dbg) {
				// TODO: Show snackbar when successfully connected to network
			}
		}, 3000);
		WSConnStat.announceMetCond(WSConnStat.CONN_OPEN_COND); // use for pulling by a user of this
		if (dbg) LogMgr.mydbg(this, "triggering start of msg queue");

		// TODO: Check if the queue is needed

		EvtMgr.getInstance("conn").notifyListeners("connect");
	};

	const onDisconnect = () => {
		isNetworkConnected = false;
		WSConnStat.announceNotMetCond(WSConnStat.CONN_OPEN_COND);
		EvtMgr.getInstance("conn").notifyListeners("disconnect");
	};

	const updateUserSession = (msg: UserSession) => {
		setUserSession({
			...userSession,
			...msg,
		});
	};

	const updateUserInfo = (msg: any) => {
		if (msg?.items && msg?.items?.length > 0) {
			if (dbg) LogMgr.mydbg(this, "Updating user profile info atom", msg);
			setUserProfile({ ...userInfo, ...msg?.items[0] });
		}
	};

	const handleLogout = async (msg: {
		avoidLogoutModal: boolean,
		avoidNotifySvr: boolean
	}) => {
		if (!msg?.avoidLogoutModal) {
			setLogoutModal(true);
		}
		/**
		 * We need to pause the logout process for split second
		 * top stop flashing the screen when the states get cleared.
		 * Because when states(session with it) get cleared app
		 * navigates to home screen.
		 * To stop that we are already navigating to home screen, but
		 * we need time to open the modal fully. Else it will
		 * navigate to home and then open the modal it will be a bad
		 * UX.
		 * */
		await AppUtils.sleep(500);
		navigate(NEWS_PAGE, { replace: true });
		if (!msg?.avoidLogoutModal) {
			cleanFeedData();
		}
		const logoutRequest = {
			user_id: userSession?.user_id,
			mode: appMode,
			dvc_tok: cu.isSet(deviceToken) ? deviceToken : "dummy_token",
			is_ios: isIOS ? YesNoOptions.YES : YesNoOptions.NO,
		};
		/**
		 * If we are logging user out from server no point in notifying
		 * server that user is logged out
		 * */
		if (!msg?.avoidNotifySvr) {
			MsgMgr.makeRemoteRequest_generic({
				msgName: MsgConst.notifyLogout2Svr,
				request: logoutRequest,
			});
		}
		clearStorage();
		await SessMgr.setInSessionAndPersistSync({
			sid: null,
			user_id: null,
			firstName: undefined,
			lastName: undefined,
			sessAuth: false,
		});
		setCredoGraphData({
			isLoading: true,
			data: undefined,
		});
		setGotMixFeedData(false);
		setUserRatingCard([]);
		setSuggConnCard([]);
		updateFeedActiveIndex(0);
		setUserProfile(null);
		setUserSession(null);
		setUserExperience(null);
		setUserQualification(null);
		setUserEgoRecentPostData(null);
		setStreamJoinReqCount(null);
		setNewPostUserStreams(null);
		setUserStreamsPickerData(null);
		setPendingTasks(null);
		setQRWebLoginCode("");
		setUserSelectedInterest([]);
		setUserSearchHistory([]);
		setNotifReqCounts({
			notifications: {
				ego: 0,
				credo: 0,
			},
			requests: {
				ego: 0,
				credo: 0,
			},
			network: 0,
		});
		await FirebaseMgr.signOut();
		await global.firebaseMgr.deleteCurrentToken();
		await global.firebaseMgr.generateTokenForPN();
		setUserMetaData(null);
		AuthorisationManager.instance().resetFactory();
		// Replace the entire stack at the time of logout for security
		// navigate(HOME_PAGE, { replace: true });
	};

	const checkAndCreateBrowserUuid = () => {
		let browserId = cu.getGlobalVar(Consts.browserId);
		if (!cu.isSet(browserId)) {
			browserId = uuidv5(uuidv4(), uuidv5.URL);
			localStorage.setItem(Consts.browserId, JSON.stringify(browserId));
		}
	};

	/**
	 * Set `true` if you need to display login modal.
	 * Set the login modal atom state to required value which controls
	 * if the modal should be displayed or not.
	 *
	 * @param {boolean} show state which should be set which will define
	 * the modal should display or not.
	 * */
	const handleLoginModal = (show: boolean) => {
		setShowLoginModal(show);
	};

	/**
	 * Set `true` if you need to display new post modal.
	 * @param {boolean} show state which should be set which will define
	 * the modal should display or not.
	 * */
	const handleNewPostModal = (show: boolean) => {
		setShowNewPostModal(show);
	};

	/**
	 * Set `true` if you need to display app download modal.
	 * Set the app download modal atom state to required value which controls
	 * if the modal should be displayed or not.
	 *
	 * @param {boolean} show state which should be set which will define
	 * the modal should display or not.
	 * */
	const handleDownloadAppModal = (show: boolean) => {
		showDownloadAppModal(show);
	};

	const handleConfirmationModal = (item: ConfirmationModalProps) => {
		setConfirmationModalData(item);
	};

	/**
	 * Set true when user is trying to log in using social login. This will
	 * display the loader/modal when the social log in is under process.
	 *
	 * @param {boolean} show state which should be set which will define
	 * the modal should display or not.
	 * */
	const handleSocialLoadingModal = (show: boolean) => {
		setSocialLoadingModal(show);
	};

	/**
	 * Show toast msg with msg type
	 * @param {object} props snackbar props
	 * */
	const showToastMsg = (props: {
		showButton?: boolean;
		buttonTitle?: string;
		containerStyle?: string;
		textStyle?: string;
		buttonStyle?: string;
		message: string;
		type?: SnackBarTypeOptions | SnackBarTypeOptions.INFO;
	}) => {
		toast(<Snackbar {...props} />);
	};

	/**
	 * Clears user session in atom and localstorage
	 * */
	const handleClearSession = () => {
		setUserSession(null);
		setUserExperience(null);
		setUserQualification(null);
		setPendingTasks(null);
	};

	const handleToggleTheme = (mode: ThemeOptions) => {
		toggleColorTheme(mode);
		setAppMode(mode === ThemeOptions.EGO ? AppMode.EGO : AppMode.CREDO);
		setIsCredoMode(mode === ThemeOptions.CREDO);
	};

	const handleUserHistoryItems = (msg: any) => {
		const userExperience: any = [];
		const userQualification: any = [];
		if (msg && msg.items && msg.items.length > 0) {
			const userHistoryItem = msg.items;
			userHistoryItem.forEach((item: any) => {
				if (!item.hasOwnProperty("prof_id") && item.hasOwnProperty("entity") && item.entity === "e") {
					userExperience.push(item);
				}
				if (!item.hasOwnProperty("prof_id") && item.hasOwnProperty("entity") && item.entity === "q") {
					userQualification.push(item);
				}
			});
		}
		if (userExperience.length > 0) setUserExperience(userExperience);
		if (userQualification.length > 0) setUserQualification(userQualification);
	};

	const handleUserRecentPost = (msg: any) => {
		const eprof_id = Consts.main_wall_ego_stream_id + userInfo?.eprof_id;
		if (msg.streamId && eprof_id === msg.streamId) {
			if (msg.items && msg.items.length > 0) {
				if (msg.items.length === 1) {
					setUserEgoRecentPostData(msg.items[0]);
				}
			} else {
				setUserEgoRecentPostData(null);
			}
		}
	};

	const handleUserStreams = (msg: any) => {
		if (msg.items && msg.items.length > 0) {
			setNewPostUserStreams(msg.items);
		}
	};

	const handleUpdateAllTags = (tags: Tag[]) => {
		setAllTags(tags);
	};

	const handleUpdateAllSubTags = (tagData: { tags: Tag[], action: TagActions}) => {
		switch (tagData.action) {
			case TagActions.REPLACE:
				setAllSubTags(tagData.tags);
				break;

			case TagActions.APPEND:
				setAllSubTags([...allSubTags, ...tagData.tags]);
				break;

			default:
				setAllSubTags(tagData.tags);
				break;
		}
	};

	const handleAuthModalType = (value: AuthModalContentType) => {
		setAuthContent(value);
	};

	const setSocialCreds = (value: SocialEmailCred) => {
		setSocialCred(value);
	};

	const handleGlobalSearchHistory = () => {
		const request = {
			what: "NA",
			s_users: YesNoOptions.YES,
			s_streams: YesNoOptions.YES,
			s_history: YesNoOptions.YES,
			action: ActionType.GET,
			user_id: userSession?.user_id,
			mode: appMode,
		};

		const handleSuccess = (response: GenericResponse<GlobalSearchItem[]>) => {
			setUserSearchHistory(response.items);
		};

		const handleError = () => {
			setUserSearchHistory([]);
		};

		SearchActions.globalSearchHistory(
			request,
			handleSuccess,
			handleError,
		);
	};

	const handleActiveNavLink = (value: ParentNavTypes) => {
		setActiveNavLink(value);
	};

	const handlePendingTasks = ({ task, action = ActionType.CREATE, notify = true }
		: { task: NewPostData, action: ActionType, notify?: boolean }) => {
		let allPendingTasks: any = pendingTasks || [];
		if (!cu.getGlobalVar(Consts.pendingTasks)) {
			cu.setGlobalVar(Consts.pendingTasks, allPendingTasks);
		}
		switch (action) {
			case ActionType.CREATE: {
				allPendingTasks = [...allPendingTasks, task];
				setPendingTasks(allPendingTasks);
				cu.setGlobalVar(Consts.pendingTasks, allPendingTasks);
				break;
			}
			case ActionType.UPDATE: {
				const index = allPendingTasks.findIndex((x: NewPostData) => x.postUuid === task.postUuid);
				if (index !== -1) {
					const progress = task.progress ? task.progress : 0;
					const obj = {
						...allPendingTasks[index],
						...task,
						progress: allPendingTasks[index].progress + progress,
					};
					allPendingTasks[index] = obj;
					setPendingTasks(allPendingTasks);
					cu.setGlobalVar(Consts.pendingTasks, allPendingTasks);
				}
				break;
			}
			case ActionType.DELETE: {
				const index = allPendingTasks.findIndex((x: NewPostData) => x.postUuid === task.postUuid);
				const item = allPendingTasks[index];
				const updatedPendingTasks = allPendingTasks.filter((item: NewPostData) => item.postUuid !== task.postUuid);
				setPendingTasks(updatedPendingTasks);
				cu.setGlobalVar(Consts.pendingTasks, updatedPendingTasks);
				if (index !== -1) {
					if (notify) { EvtMgr.getInstance(EventConst.handlePostFromFeed).notifyListeners(item); }
				}
				break;
			}
			default:
				break;
		}
	};

	const handleNotificationsCount = (message: NotiReqCount) => {
		const hasRequests = sum(Object.values(message.requests)) > 0;
		const hasNotifications = sum(Object.values(message.notifications)) > 0;
		const hasNetwork = message.network > 0;

		let updatedCount = notifReqCount;

		if (hasRequests) {
			updatedCount = {
				...updatedCount,
				requests: {
					ego: message.requests.ego + notifReqCount.requests.ego,
					credo: message.requests.credo + notifReqCount.requests.credo,
				},
			};
			cu.setGlobalVar(Consts.userRequestsLastTs, moment().valueOf());
		}

		if (hasNotifications) {
			updatedCount = {
				...updatedCount,
				notifications: message.notifications,
			};
			cu.setGlobalVar(Consts.userNotifsLastTs, moment().valueOf());
		}

		if (hasNetwork) {
			updatedCount = {
				...updatedCount,
				network: message.network,
			};
		}

		setNotifReqCounts(updatedCount);
	};

	const handleTagsLoading = (value: boolean) => {
		setTagsLoadingAtom(value);
	};

	const updateHomeData = (
		firstNonActiveFeed: FeedItem[],
		secondNonActiveFeed: FeedItem[],
		post: FeedItem,
		firstNonActiveFeedSetter: any,
		secondNonActiveFeedSetter: any,
	) => {
		const firstNonActiveDataIndex = firstNonActiveFeed.map((element) => element["p.postUuid"]).indexOf(post["p.postUuid"]);
		if (firstNonActiveDataIndex > -1) {
			const updateFeed = firstNonActiveFeed;
			updateFeed.splice(firstNonActiveDataIndex, 1, post);
			firstNonActiveFeedSetter(updateFeed);
		}

		const secondNonActiveDataIndex = secondNonActiveFeed.map((element) => element["p.postUuid"]).indexOf(post["p.postUuid"]);
		if (secondNonActiveDataIndex > -1) {
			const updateFeed2 = secondNonActiveFeed;
			updateFeed2.splice(secondNonActiveDataIndex, 1, post);
			secondNonActiveFeedSetter(updateFeed2);
		}
	};

	const updateOrDeleteHomeData = (data: FeedItem[], post: FeedItem, dataIndex: number, action: ActionType) => {
		const updateData = data;
		switch (action) {
			case ActionType.UPDATE:
				updateData.splice(dataIndex, 1, post);
				break;
			case ActionType.DELETE:
				updateData.splice(dataIndex, 1);
				break;
			default:
				break;
		}
		return updateData;
	};

	const updateHomeFeedData = ({ post, action = ActionType.UPDATE }: { post: FeedItem, action: ActionType }) => {
		let isDataUpdated = false;

		switch (homeFeedActiveIndex) {
			// Home user's Feed
			case 0: {
				const dataIndex = feedData.map((element) => element["p.postUuid"]).indexOf(post["p.postUuid"]);
				if (dataIndex > -1) {
					let updateFeed = feedData;
					updateFeed = updateOrDeleteHomeData(feedData, post, dataIndex, action);
					updateFeedData(updateFeed);
					isDataUpdated = true;
				}
				if (!isDataUpdated) {
					updateHomeData(newsData, blastData, post, updateNewsData, updateBlastData);
				}
			}
				break;
			// News Data
			case 1: {
				const dataIndex = newsData.map((element) => element["p.postUuid"]).indexOf(post["p.postUuid"]);
				if (dataIndex > -1) {
					let updateFeed = newsData;
					updateFeed = updateOrDeleteHomeData(newsData, post, dataIndex, action);
					updateNewsData(updateFeed);
				}
				if (!isDataUpdated) {
					updateHomeData(feedData, blastData, post, updateFeedData, updateBlastData);
				}
			}
				break;
			// Blast data
			case 2: {
				const dataIndex = blastData.map((element) => element["p.postUuid"]).indexOf(post["p.postUuid"]);
				if (dataIndex > -1) {
					let updateFeed = blastData;
					updateFeed = updateOrDeleteHomeData(blastData, post, dataIndex, action);
					updateBlastData(updateFeed);
				}
				if (!isDataUpdated) {
					updateHomeData(newsData, feedData, post, updateNewsData, updateFeedData);
				}
			}
				break;
			default: break;
		}
	};

	const handleUpdateHomeSuspenseAtom = (msg: boolean) => {
		updateHomeSupsense(msg);
	};

	useEffect(() => {
		checkAndCreateBrowserUuid();
	}, []);

	const logAnalyticsEvent = (evt: any) => {
		try {
			if (evt?.name) {
				const analytics = getAnalytics();
				logEvent(analytics, evt.name, {
					sid: SessMgr.getFromSession(Consts.sid),
					platform: "Web",
					ts: Date.now(),
					userId: AES.encrypt(SessMgr.getFromSession(Consts.user_id), CfgMgr.analytics_hash_key),
				});
			}
		} catch (err) {
			if (dbg) LogMgr.mydbg(this, "error while firing event: ", err);
		}
	};

	const handleRatingTutorialAtom = (message: { showModal: boolean }) => {
		const {
			showModal,
		} = message;

		setRatingTutorialModal(showModal);
	};

	useEffect(() => {
		if (!signUpInProgress && userSession?.sid) {
			updateUserSession({
				...userSession,
				regComplete: true,
			});
		}
	}, [signUpInProgress]);

	const handleUpdateAtom = (msg: UpdateStoreMessage) => {
		setUpdateAtom([msg.atomName, msg.nextValue]);
	};

	const handleClientMetaData = (msg: any) => {
		if (cu.isSuccessMsg(msg) && msg?.items && msg?.items.length > 0) {
			let data = msg?.items[0]["meta.metadata"];
			let metaData = {};
			if (data) {
				data = data.replaceAll("{", "").replaceAll("}", "");
				const values = data.split(",");
				values?.forEach((ele: any) => {
					if (ele) {
						const keyValues = ele.trim().split("=");
						if (keyValues && keyValues.length > 1) {
							metaData = {
								...metaData,
								[keyValues[0]]: keyValues[0] === ClientMetaDataMapping.removedHints
									? keyValues[1] : JSON.parse(keyValues[1]),
							};
						}
					}
				});
			}
			if (metaData && !isEmpty(metaData)) {
				setUserMetaData(metaData);
				// If removed hints exists then update the atom
				// @ts-ignore
				if (metaData[ClientMetaDataMapping.removedHints] && (metaData[ClientMetaDataMapping.removedHints])?.length > 0) {
					// hint metadata example: "rh=&1&2&4"
					// where `&`=separator and numbers are id of removed hints
					// @ts-ignore
					setRemovedHintsData(metaData[ClientMetaDataMapping.removedHints]);
				}
			} else {
				setUserMetaData(ClientMetaData);
				UserActions.saveClientMetaDataToServer(ClientMetaData);
			}
		} else if (cu.isSuccessMsg(msg)) {
			setUserMetaData(ClientMetaData);
			UserActions.saveClientMetaDataToServer(ClientMetaData);
		}
	};

	const handleCustomEmailSignUp = (email: string) => {
		if (cu.isSet(email)) {
			setAuthContent(AuthModalContentType.EMAIL_NOT_YET_REG);
			setEmailInStorage(email);
			setEmailRegInProc(YesNoOptions.YES);
			setShowLoginModal(true);
			EvtMgr
				.getInstance(EventConst.showHomeSuspense)
				.notifyListeners(true);
			navigate(HOME_PAGE);
		}
	};

	useGetIPAddress();
	useLinkedInAuthentication();
	useFirebaseRedirectResponse();
	useWSEvtMgrListener(EventConst.wsConn, setConnectionInfo);
	useWSEvtMgrListener(EventConst.socketOnConnect, onConnect);
	useWSEvtMgrListener(EventConst.socketOnDisconnect, onDisconnect);
	useEvtMgrListener(EventConst.updateUserSess, updateUserSession);
	useEvtMgrListener(EventConst.userInfo, updateUserInfo);
	useEvtMgrListener(EventConst.logoutUser, handleLogout);
	useEvtMgrListener(EventConst.pushNotificationPerm, setPushNotificatioPerm);
	useEvtMgrListener(EventConst.saveDeviceToken, saveDeviceToken);
	useEvtMgrListener(EventConst.showLoginModal, handleLoginModal);
	useEvtMgrListener(EventConst.showSocialLoading, handleSocialLoadingModal);
	useEvtMgrListener(EventConst.showToast, showToastMsg);
	useEvtMgrListener(EventConst.streamJoinReqCountUpdated, setStreamJoinReqCount);
	useEvtMgrListener(EventConst.clearSession, handleClearSession);
	useEvtMgrListener(EventConst.toggleTheme, handleToggleTheme);
	useEvtMgrListener(EventConst.userHistoryItems, handleUserHistoryItems);
	useEvtMgrListener(EventConst.saveCachedImages, setCachedImages);
	useEvtMgrListener(EventConst.setAllTags, handleUpdateAllTags);
	useEvtMgrListener(EventConst.userStreamItems, handleUserRecentPost);
	useEvtMgrListener(EventConst.showDownloadAppModal, handleDownloadAppModal);
	useEvtMgrListener(EventConst.showConfirmationModal, handleConfirmationModal);
	useEvtMgrListener(EventConst.setAllSubTags, handleUpdateAllSubTags);
	useEvtMgrListener(EventConst.changeAuthModalType, handleAuthModalType);
	useEvtMgrListener(EventConst.setSocialEmailCred, setSocialCreds);
	useEvtMgrListener(EventConst.setUserSearchHistory, handleGlobalSearchHistory);
	useEvtMgrListener(EventConst.newPostUserStreams, handleUserStreams);
	useEvtMgrListener(EventConst.setRouteParent, handleActiveNavLink);
	useEvtMgrListener(EventConst.notifReqCount, handleNotificationsCount);
	useEvtMgrListener(EventConst.showNewPostModal, handleNewPostModal);
	useEvtMgrListener(EventConst.updatePendingTasks, handlePendingTasks);
	useEvtMgrListener(EventConst.allTagsLoading, handleTagsLoading);
	useEvtMgrListener(EventConsts.updateAtomStore, handleUpdateAtom);
	useEvtMgrListener(EventConst.updateFeedData, updateHomeFeedData);
	useEvtMgrListener(EventConst.showHomeSuspense, handleUpdateHomeSuspenseAtom);
	useEvtMgrListener(EventConst.logAnalyticsEvent, logAnalyticsEvent);
	useEvtMgrListener(EventConst.ratingTutorialModal, handleRatingTutorialAtom);
	useEvtMgrListener(EventConst.getUserClientMetadata, handleClientMetaData);
	useEvtMgrListener(EventConst.showEmailRegisterModal, handleCustomEmailSignUp);
	useEvtMgrListener(EventConst.setUserWalletBalance, setWalletBalance);
	return null;
};

export default GlobalListeners;
