import { atom } from "jotai";
import { EventBusMgr } from "@credo/websocket";
import { atomWithStorage } from "jotai/utils";
import {
	AppMode, cu, DataStatus, LighteningAddressesAtom, UserSession, YesNoOptions,
} from "@credo/utilities";
import { CountryData } from "react-phone-input-2";
import { CombinedGraphData } from "@credo/ui-components";
import { lighteningAddressesAtom } from "@credo/store";
import {
	AppHeader,
	AuthModalContentType,
	BoostBoardMapAtom,
	CommentBeenRepliedObject,
	ConfirmationModalProps,
	CookiesInfo,
	EngageTabOptions,
	FeedType,
	FilterData,
	FilterTag,
	NewPostContentOptions,
	NotificationItem,
	NotiReqCount,
	ParentNavTypes,
	PendingCommentsItem,
	RequestItem,
	SocialEmailCred,
	StreamInfo,
	StreamItem,
	StreamProfileJoinReqCount,
	Tag,
	UserHistoryItem,
	UserProfile,
	UserQualificationItem,
} from "./types";
import { Consts } from "./Consts";
import FirebaseMgr from "./FirebaseMgr";
import { DeviceLocationObject } from "../modules/auth/requests";
import { FeedItem } from "../modules/home/requests";
import { GlobalSearchItem } from "../services/actions/search";
import { languages } from "../i18n/constants";
import { RouteMgr } from "../modules/auth/RouteMgr";
import { HOME_PAGE, REGISTER_CONFIRM, REGISTER_INTEREST } from "../routes/constants";
import { BoostHistory, TXNStatus, WalletHistory } from "../services/actions/boost/types";

export const applicationModeAtom = atomWithStorage<AppMode>(Consts.mode, AppMode.CREDO);
export const clientIPAddressAtom = atomWithStorage<string | null>(Consts.clientIPAddress, null);
export const clientCountryCodeAtom = atomWithStorage<string | null>(Consts.clientCountryCode, null);
export const clientLocationAtom = atomWithStorage<DeviceLocationObject | null>(Consts.clientLocation, null);
// Credo mode needs to come from localstorage before initialising the app for user
export const eventBusMgrAtom = atom(new EventBusMgr());
export const firebaseMgrAtom = atom(new FirebaseMgr());
export const isConnectedAtom = atom<boolean>(false);
export const userProfileAtom = atom<UserProfile | null>(null);
export const qrCodeLoginAtom = atomWithStorage<string>(Consts.qrCodeLogin, "");
export const userSessionAtom = atomWithStorage<UserSession | null>(Consts.sess, null);
export const isSessionAuthAtom = atom((get) => get(userSessionAtom)?.sessAuth);
export const isSessInProgressAtom = atom((get) => get(userSessionAtom)?.sessInProgress);
export const userIdAtom = atom((get) => get(userSessionAtom)?.user_id);
export const userPhoneAtom = atom((get) => get(userSessionAtom)?.phone);
export const userExperienceAtom = atomWithStorage<UserHistoryItem[] | null>(Consts.userExperience, []);
export const userQualificationAtom = atomWithStorage<UserQualificationItem[] | null>(Consts.userQualification, []);
export const allCachedImagesAtom = atomWithStorage<any>(Consts.allCachedImages, {});
export const userEgoRecentPostAtom = atomWithStorage<FeedItem | null>(Consts.userEgoRecentPost, null);
export const userMetaDataAtom = atomWithStorage<any>(Consts.metaData, {});
export const newPostUserStreamsAtom = atomWithStorage<StreamItem[] | null>(Consts.newPostUserStreams, null);
export const lastActiveStreamIdCredoAtom = atom<string | null>(null);
export const lastActiveStreamIdEgoAtom = atom<string | null>(null);
export const userStreamsPickerDataAtom = atom<any>([]);
export const showNewPostStreamChnageTooltipAtom = atom<boolean>(false);
export const showNewPostModalAtom = atom<boolean>(false);
export const streamDetailsForNewPostAtom = atom<StreamInfo | null>(null);
export const newStreamSelectedExpertise = atom<any>([]);
export const sharedPostDetailsAtom = atom<FeedItem | null>(null);
export const quickShareLinkAtom = atom<string | null>(null);
export const showCredoModeWarning = atom<boolean>(false);
export const pendingTasksAtom = atomWithStorage<Array<any>[] | null>(Consts.pendingTasksAtom, null);
/**
 * Active link on left panel when user clicks on
 * any link from the route page.
 * Eg: When user clicks on any link from home,
 * Home should be active unless its user's own profile
 * */
export const currentParentHomeAtom = atom<ParentNavTypes>(ParentNavTypes.HOME);
/**
 * Right Section height
 * */
export const rightSectionHeightAtom = atom<number>(0);

export const cookiesInfoAtom = atom<CookiesInfo | null>(null);
export const downloadAppBannerAtom = atomWithStorage(Consts.showDownloadAppBanner, YesNoOptions.YES);

// Push notification
export const hasPushNotifPermAtom = atomWithStorage<boolean>(Consts.hasPushNotifPerm, false);
export const deviceTokenAtom = atomWithStorage<string>(Consts.deviceToken, "");

// Notification Counts
export const userNotifsReqCountAtom = atom<NotiReqCount>({
	notifications: {
		ego: 0,
		credo: 0,
	},
	requests: {
		ego: 0,
		credo: 0,
	},
	network: 0,
});
export const getNotifCountAtom = atom((get) => {
	const { notifications } = get(userNotifsReqCountAtom);
	return notifications;
});
export const getReqCountAtom = atom((get) => {
	const { requests } = get(userNotifsReqCountAtom);
	return requests;
});
export const getNetworkCountAtom = atom((get) => {
	const { network } = get(userNotifsReqCountAtom);
	return network;
});
/**
 * returns notifications and requests counts in
 * this object
 * {
 *   egoCount: 0,
 *   credoCount: 0,
 * }
 * */
export const getInboxCountAtom = atom((get) => {
	const { notifications } = get(userNotifsReqCountAtom);
	const { requests } = get(userNotifsReqCountAtom);

	const egoCount = notifications.ego + requests.ego;
	const credoCount = notifications.credo + requests.credo;

	return {
		egoCount,
		credoCount,
	};
});

// Auth Modal
export const showLoginModalAtom = atom<boolean>(false);
export const showSignUpContainerAtom = atom<boolean>(false);
export const showValidatingEmailModalAtom = atom<boolean>(false);
export const authModalContentAtom = atomWithStorage<AuthModalContentType>(Consts.authContent, AuthModalContentType.LOGIN);
export const selectedPlatformAtom = atom<number>(-1);
export const showSocialLoadingAtom = atomWithStorage<boolean>(Consts.showSocialLoading, false);
export const selectedCountryAtom = atomWithStorage<CountryData | {}>(Consts.clientCountryData, {});
export const email4SignInLinkToEmailAtom = atomWithStorage(Consts.email4SignInLinkToEmail, "");
/**
 * When user tries to sign up using email, this should be set as y
 * when user is done or ran into some error while creating the acc
 * this state and key must be cleared from localstorage
 * */
export const emailRegInProcessAtom = atomWithStorage<YesNoOptions>(Consts.emailRegInProcess, YesNoOptions.NO);
/**
 * While signing up using social we need the firebaseUID/LinkedInID and
 * email, so we will save that data here and make user when
 * EmailNotYetRegistered component unmounts this atom will be reset to null
 * */
export const socialEmailCredAtom = atom<SocialEmailCred | null>(null);
/**
 * Derived read only atom used to get the information if the user has
 * completed the signup process or not based on user's first name
 * has been added or not and tags have been added or not.
 * */
export const signUpInProgressAtom = atom((get) => {
	const userSession = get(userSessionAtom);
	const noName = !cu.isSet(userSession?.firstName) || userSession?.firstName?.toLowerCase() === "null";
	const noTags = userSession?.countUxpTags === 0;

	if (noName) {
		RouteMgr.setDontAllowRouteChange(true);
		RouteMgr.setVerificationSuccessScreen(REGISTER_CONFIRM);
	}

	if (noTags && !noName) {
		RouteMgr.setDontAllowRouteChange(true);
		RouteMgr.setVerificationSuccessScreen(REGISTER_INTEREST);
	}

	if (!noTags && !noName) {
		RouteMgr.setDontAllowRouteChange(false);
		RouteMgr.setVerificationSuccessScreen(HOME_PAGE);
	}

	return noName || noTags;
});
export const showLogoutModalAtom = atom<boolean>(false);
export const verificationCodeAtom = atom<string>("");

// Requests
export const userPendingRequestsAtom = atom<RequestItem[]>([]);

// Network Notifications
export const userNetworkNotificationsAtom = atom<NotificationItem[]>([]);

// App download modal
export const showDownloadAppModalAtom = atom<boolean>(false);
export const downloadAppUrlAtom = atom<string>("");
export const confirmationModalData = atom<ConfirmationModalProps | null>(null);

// Stream Join requests count
export const streamJoinReqCountAtom = atomWithStorage<number | null>(Consts.streamJoinReqCountAtom, 0);
export const streamProfileJoinReqCountAtom = atomWithStorage<StreamProfileJoinReqCount>(Consts.streamProfileJoinReqCountAtom, {});

// User
export const selectedInterestsAtom = atom<Tag[]>([]);
export const allTagsAtom = atom<Tag[]>([]);
export const allTagsLoadingAtom = atom<boolean>(false);
export const userHasEmailSetAtom = atom((get) => {
	const userProfile = get(userProfileAtom);
	return cu.isSet(userProfile?.email_custom) || cu.isSet(userProfile?.email_google)
		|| cu.isSet(userProfile?.email_apple) || cu.isSet(userProfile?.email_facebook)
		|| cu.isSet(userProfile?.email_linkedin) || cu.isSet(userProfile?.email_linkedin);
});
export const userSetEmailAtom = atom((get) => {
	const userProfile = get(userProfileAtom);
	return userProfile?.email_custom || userProfile?.email_google
		|| userProfile?.email_apple || userProfile?.email_facebook
		|| userProfile?.email_linkedin || userProfile?.email_linkedin;
});
export const userSelectedLanguageAtom = atom((get) => get(userProfileAtom)?.lang || languages[0].value);
export const showUserSuspendedModalAtom = atom<ConfirmationModalProps | null>(null);

export const feedUxpRatingCardsAtom = atom<FeedItem[]>([]);
export const feedSugConnCardsAtom = atom<FeedItem[]>([]);
export const allSubTagsAtom = atom<Tag[]>([]);

// SearchModal
export const showSearchModalAtom = atom<boolean>(false);
export const userSearchHistoryAtom = atom<GlobalSearchItem[]>([]);
export const showSearchResultContainer = atom<boolean>(false);
/**
 * Animating search is displayed on tablet devices like ipad
 * */
export const showAnimatingSearchAtom = atom<boolean>(false);

// Comments
export const pendingCommentsAtom = atom<PendingCommentsItem[]>([]);
export const pendingSubCommentsAtom = atom<PendingCommentsItem[]>([]);
export const commentBeenRepliedAtom = atom<CommentBeenRepliedObject | null>(null);

// Home feed
export const gotMixFeedDataAtom = atom<boolean>(false);
export const feedDataAtom = atom<FeedItem[]>([]);
export const newsDataAtom = atom<FeedItem[]>([]);
export const blastDataAtom = atom<FeedItem[]>([]);
export const lastFeedTabIndexAtom = atomWithStorage<number>("lastFeedTabIndex", 0);
export const lastExploreTabIndexAtom = atom<number>(0);
/**
 * This will handle home feed loading when user is logging in
 * Using this separately because we don't want home feed to show
 * skeleton when user's account is suspended and he tries to login
 * */
export const showHomeSuspenseAtom = atom<boolean>(false);
export const showExplainFeedAtom = atomWithStorage<Array<FeedType | EngageTabOptions>>(
	"showExplain",
	[
		FeedType.FEED,
		FeedType.NEWS,
		FeedType.BLASTS,
		EngageTabOptions.EXPLORE,
		EngageTabOptions.COMMUNITIES,
		EngageTabOptions.DASHBOARD,
		EngageTabOptions.BOTS,
		EngageTabOptions.PEOPLE,
	],
);

// New post
export const newPostContent = atom<NewPostContentOptions>(NewPostContentOptions.POST);

// Misc
export const ratingTutorialModalAtom = atom<boolean>(false);

interface CredoGraphDataAtomInterface {
	isLoading: boolean;
	data: CombinedGraphData | undefined
}
export const credoGraphDataAtom = atom<CredoGraphDataAtomInterface>({
	isLoading: true,
	data: undefined,
});

// Boost
export const topFeedDataMapAtom = atom<BoostBoardMapAtom>({
	data: new Map(),
	dataIds: [],
	renderIds: [],
	isLoading: DataStatus.LOADING,
	boardInfo: {
		boardTs: 0,
		boardVersion: 0,
		boardAccessCost: 0,
		boardSnapIntervalSecs: 0,
		currentTs: 0,
		txStatus: TXNStatus.INIT,
	},
});
export const currentBoardInfoAtom = atom(
	(get) => get(topFeedDataMapAtom).boardInfo,
);
export const boardLoadingAtom = atom(
	(get) => get(topFeedDataMapAtom).isLoading,
);
export const activeBoostTagAtom = atom<string>("");

// Volts Widget
export const walletHistoryAtom = atom<{
	data: WalletHistory[],
	loadingStatus: DataStatus,
}>({
	data: [],
	loadingStatus: DataStatus.INIT,
});
export const boostHistoryAtom = atom<BoostHistory[]>([]);
export const appHeaderAtom = atom<AppHeader>({ title: "" });

// Wallet
// This data is coming from lightningAddressesAtom
export const withdrawMetaAtom = atom<LighteningAddressesAtom["withdrawMeta"]>(
	(get) => get(lighteningAddressesAtom).withdrawMeta,
);

// feed filter
export const forYouFilterTagsAtom = atom<FilterTag[]>([]);
export const selectedForYouFilterTagsAtom = atom<FilterData>({
	hasInteracted: false,
	sortBy: "relevance",
	filterByCred: "low",
	filterTags: [],
});
export const newsFilterTagsAtom = atom<FilterTag[]>([]);
export const selectedNewsFilterTagsAtom = atom<FilterData>({
	hasInteracted: false,
	sortBy: "relevance",
	filterByCred: "low",
	filterTags: [],
});
export const blastsFilterTagsAtom = atom<FilterTag[]>([]);
export const selectedBlastsFilterTagsAtom = atom<FilterData>({
	hasInteracted: false,
	sortBy: "relevance",
	filterByCred: "low",
	filterTags: [],
});
