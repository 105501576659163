import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { NEWS_PAGE } from "./constants";
import { isSessionAuthAtom } from "../utils/atoms";
import { RouteMgr } from "../modules/auth/RouteMgr";

function PrivateRoutes() {
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	if (isSessionAuth && RouteMgr.getDontAllowRouteChange()) {
		return <Navigate to={RouteMgr.getVerificationSuccessScreen()} replace />;
	}
	if (isSessionAuth) { return <Outlet />; }
	return <Navigate to={NEWS_PAGE} />;
}

export default PrivateRoutes;
