import React from "react";
import { classNames } from "../common";

export interface CredoBadgeProps {
	/**
	 * Badge Value
	 * */
	badgeValue: number | string,
	/**
	 * CSS style class for badge container
	 * */
	containerStyle?: any;
	/**
	 * CSS style class for badge text
	 * */
	badgeValueStyle?: any;
}

export function CredoBadge({
	badgeValue,
	containerStyle,
	badgeValueStyle,
}: CredoBadgeProps) {
	if (!badgeValue) {
		return null;
	}

	return (
		<div
			className={
				classNames(
					"flex min-w-[18px] max-w-min h-[18px] justify-center items-center px-1",
					"border border-error-2 bg-badge-background text-badge-text rounded-full",
					containerStyle,
				)
			}
		>
			<p
				className={
					classNames(
						"text-center text-xs text-badge-text align-middle px-px",
						badgeValueStyle,
					)
				}
			>
				{Number(badgeValue) > 9 ? "9+" : badgeValue}
			</p>
		</div>
	);
}

CredoBadge.defaultProps = {
	containerStyle: "",
	badgeValueStyle: "",
};
