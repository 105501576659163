import React, { useMemo, useState } from "react";
import {
	classNames,
	EngageDismissIcon,
	FollowIcon,
	Modal,
	MoreMenu,
	MoreMenuInterface,
	MultipleButtons,
	ProfilePicture,
	SvgIcon,
	TagIcon,
	ThreeDotIcon,
	UnfollowIcon,
} from "@credo/ui-components";
import { Link, useNavigate } from "react-router-dom";
import {
	ActionType, AppMode, cu, EvtMgr, SessMgr,
} from "@credo/utilities";
import { useAtom } from "jotai";
import { useLongPress } from "react-use";
import { isDesktop, isMobile } from "react-device-detect";
import { EngageActions, ExploreCommunity } from "../../../services/actions/engage";
import {
	Actions,
	EntityType,
	ItemType,
	ProfileSize,
	RecommendationRank,
	RelationTypeStatus,
} from "../../../utils/types";
import { OTHER_USER_PROFILE_PAGE, STREAM_PROFILE_PAGE } from "../../../routes/constants";
import { strings } from "../../../i18n/config";

import { applicationModeAtom, isSessionAuthAtom, userIdAtom } from "../../../utils/atoms";
import {
	AnalyticEventsConst, AppUtils, Consts, EventConst,
} from "../../../utils";
import { StreamActions } from "../../../services/actions/stream";

interface ExploreItemProps {
	/**
	 * Id for unique key purposes
	 * */
	id: number;
	/**
	 * Item object which needs to be rendered.
	 * */
	item: ExploreCommunity,
	/**
	 * Type of the entity which needs to be rendered.
	 * */
	entityType: EntityType;
	/**
	 * Action when remove item action is executed
	 * */
	onRemoveItem: (item: ExploreCommunity) => void;
	/**
	 * Hide all the buttons which includes more button
	 * amd follow button. Mainly useful on the Right
	 * Section's Explore sub Section.
	 *
	 * @default false;
	 * */
	hideAllButtons?: boolean;
	/**
	 * Optional extra classNames for outermost
	 * link/button of the item.
	 *
	 * @default ""
	 * */
	className?: string
}

const exploreButtons = {
	c: {
		showFollow: true,
		showUnfollow: false,
	},
	e: {
		showFollow: true,
		showUnfollow: false,
	},
};

const ExploreItem: React.FC<ExploreItemProps> = (props: ExploreItemProps) => {
	const {
		id,
		item,
		entityType,
		onRemoveItem,
		hideAllButtons,
		className,
	} = props;

	const [buttons, setButtons] = useState(exploreButtons);
	const [showMobileMoreMenu, setShowMobileMoreMenu] = useState<boolean>(false);
	const [showMoreIcon, setShowMoreIcon] = useState(false);

	const [appMode] = useAtom(applicationModeAtom);
	const [isSessAuth] = useAtom(isSessionAuthAtom);
	const [userId] = useAtom(userIdAtom);

	const navigate = useNavigate();

	const itemLink: string = entityType === EntityType.STREAM
		? `${STREAM_PROFILE_PAGE}/${item.struname}`
		: `${OTHER_USER_PROFILE_PAGE}/${item.struname}`;

	const onLongPress = () => {
		if (isMobile) {
			setShowMobileMoreMenu(true);
		}
	};

	const defaultOptions = {
		isPreventDefault: false,
		delay: 800,
	};

	const longPressEvent = useLongPress(onLongPress, defaultOptions);

	const removeItem = () => {
		onRemoveItem(item);
		const request = {
			user_id: userId ?? "",
			sid: SessMgr.getFromSession(Consts.sid),
			mode: AppMode.EGO,
			itemType: ItemType.STREAM,
			itemVal: item.streamId,
			action: ActionType.REMOVE,
		};
		EngageActions.removeItemFromRec(request);
	};

	const menuItems = [
		{
			title: strings("ExplorePage.dismiss_rec"),
			icon: <SvgIcon icon={EngageDismissIcon} height={18} width={18} />,
			onClick: removeItem,
		},
	];

	const onMoreMenuItemClick = (event: React.MouseEvent<HTMLElement>, menuItem: MoreMenuInterface) => {
		event.stopPropagation();
		menuItem.onClick();
		setShowMobileMoreMenu(false);
	};

	const renderMoreIcon = () => {
		if (isDesktop) {
			return (
				<MoreMenu
					iconComp={
						(
							<div className={classNames(
								"flex w-8 h-8 rounded-2xl bg-background-comment items-center justify-center cursor-pointer ml-2",
								showMoreIcon && menuItems.length > 0 ? "visible" : "invisible",
							)}
							>
								<SvgIcon
									icon={ThreeDotIcon}
									width={16}
								/>
							</div>
						)
					}
					items={menuItems}
					containerStyle={classNames(
						"xs:-left-56 sm:-left-60 -left-48",
						id === 0 ? "!top-6" : "-top-24",
					)}
				/>
			);
		}
		return null;
	};

	const renderMobileMoreMenu = () => {
		if (isMobile && showMobileMoreMenu && !hideAllButtons) {
			return (
				<Modal
					id="explore-item-more-menu-modal"
					show={showMobileMoreMenu}
					onClose={() => setShowMobileMoreMenu(false)}
					hideCloseIcon
					dialogClassName={classNames(
						"bg-background !p-0 max-w-full flex flex-col justify-center !rounded-none absolute bottom-0",
					)}
					dialogWrapperClassName="!p-0 z-[200]"
					closeIconClassName={classNames("hover:bg-background-secondary")}
					titleClassName="text-primary"
				>
					<div className="flex flex-col px-4 py-2">
						{menuItems.map((item: any) => (
							<div key={item.title}>
								<button
									type="button"
									key={item}
									onClick={(event) => onMoreMenuItemClick(event, item)}
									className={classNames("flex items-center rounded-lg transition w-full py-4 outline-none")}
								>
									<div className={classNames("flex items-center h-4 w-4 mr-3",
										"justify-center text-gray-dark")}
									>
										{item.icon}
									</div>
									<p className={
										classNames(
											"text-sm font-medium text-title-color text-left",
										)
									}
									>
										{item.title}
									</p>
								</button>
								<div style={{ height: 1 }} className="bg-post-bar-border ml-7" />
							</div>
						))}
					</div>
				</Modal>
			);
		}
		return null;
	};

	const showLoginModal = () => {
		AppUtils.handleLoginModal(true);
	};

	const followStream = (event: React.MouseEvent<HTMLElement>) => {
		// Will ignore the wrapping link of the item
		EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
			name: AnalyticEventsConst.exploreFollowCommunity,
		});
		event.preventDefault();
		event.stopPropagation();
		const manageRelation = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: appMode,
			targetStreamId: item?.streamId,
			relType: RelationTypeStatus.FOLLOW,
			action: Actions.CREATE,
			prof_id: "",
		};
		StreamActions.mngRelation4Stream(manageRelation);
		setButtons((prevState) => ({
			...prevState,
			[appMode]: {
				showFollow: false,
				showUnfollow: true,
			},
		}));
	};

	const unFollowStream = (event: React.MouseEvent<HTMLElement>) => {
		// Will ignore the wrapping link of the item
		event.preventDefault();
		event.stopPropagation();
		const manageRelation = {
			user_id: SessMgr.getFromSession(Consts.user_id),
			mode: appMode,
			targetStreamId: item?.streamId,
			relType: RelationTypeStatus.FOLLOW,
			action: Actions.DELETE,
			prof_id: "",
		};
		StreamActions.mngRelation4Stream(manageRelation);
		setButtons((prevState) => ({
			...prevState,
			[appMode]: {
				showFollow: true,
				showUnfollow: false,
			},
		}));
	};

	const buttonsToRender = useMemo(() => {
		if (!(cu.isYes(item?.ac_folcom) && item?.joinModes?.includes(cu.getAppMode()))) return [];
		const buttonArray = [
			buttons[appMode].showFollow ? {
				buttonTitle: {
					title: strings("ExplorePage.follow"),
					style: "text-primary !text-xs",
				},
				image: <SvgIcon
					icon={FollowIcon}
					height={24}
					width={24}
				/>,
				buttonStyle: "!px-0",
				buttonChildStyle: "!px-0",
				imageStyle: "!h-auto !w-auto !my-0",
				onPress: !isSessAuth ? showLoginModal : followStream,
			} : null,
			buttons[appMode].showUnfollow ? {
				buttonTitle: {
					title: strings("ExplorePage.unfollow"),
					style: "text-primary !text-xs",
				},
				image: <SvgIcon
					icon={UnfollowIcon}
					height={20}
					width={20}
				/>,
				buttonStyle: "!px-0",
				buttonChildStyle: "!px-0",
				imageStyle: "!h-auto !w-auto !my-0.5",
				onPress: !isSessAuth ? showLoginModal : unFollowStream,
			} : null,
		];

		return buttonArray.filter((item) => item);
	}, [appMode, buttons, isSessAuth]);

	const renderRecommendationBy = () => (
		<div className="flex flex-row mt-0.5">
			{(item.rank_type === RecommendationRank.CONNECTION || item.rank_type === RecommendationRank.BOTH) && (
				<div className="flex">
					<SvgIcon icon={FollowIcon} color="#96A5B9" height={18} width={18} />
				</div>
			)}
			{(item.rank_type === RecommendationRank.TAG || item.rank_type === RecommendationRank.BOTH) && (
				<div className="flex px-1 pt-0.5">
					<SvgIcon icon={TagIcon} height={14} width={14} />
				</div>
			)}
		</div>
	);

	const renderInteractionButtons = () => {
		if (!hideAllButtons) {
			return (
				<div className="flex justify-center items-center">
					{renderMoreIcon()}
					<div className="flex justify-center items-center min-w-[4.5rem] ml-3">
						<MultipleButtons
							buttons={buttonsToRender as any}
							minimumWidth={100}
							containerStyle="justify-center items-center"
						/>
					</div>
				</div>
			);
		}
		return null;
	};

	const renderContent = () => (
		<>
			<div className="flex flex-row items-center">
				<ProfilePicture
					profilePicUrl={cu.buildSourceUrlImage((item.profilePicRelUrl)) || ""}
					isStream={entityType === EntityType.STREAM}
					size={ProfileSize.SMALL}
					profilePicWrapperStyle="flex-shrink-0"
				/>
				<div className="flex flex-col items-start pl-2">
					<span
						className="text-primary text-sm leading-5 font-normal explore-item__text truncate"
					>
						{item.name}
					</span>
					<span
						className="text-xs text-gray-dark explore-item__text truncate"
					>
						{itemLink.substring(1)}
					</span>
					{renderRecommendationBy()}
				</div>
			</div>
			{renderInteractionButtons()}
			{renderMobileMoreMenu()}
		</>
	);

	const onClickItem = () => {
		navigate(itemLink);
	};

	if (isMobile) {
		return (
			<div
				role="button"
				tabIndex={0}
				onKeyDown={onClickItem}
				data-testid="explore-item-button"
				key={id}
				onClick={onClickItem}
				className={classNames(
					"flex flex-row justify-between p-4 outline-none w-full",
					"border-b border-background-tertiary",
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...longPressEvent}
			>
				{renderContent()}
			</div>
		);
	}

	return (
		<Link
			data-testid="explore-item-link"
			rel="ugc"
			key={id}
			to={itemLink}
			state={{
				meta: {
					title: item.name,
				},
			}}
			className={classNames(
				"flex flex-row justify-between p-4",
				"border-b border-background-tertiary",
				className,
			)}
			onMouseEnter={() => setShowMoreIcon(true)}
			onMouseLeave={() => setShowMoreIcon(false)}
		>
			{renderContent()}
		</Link>
	);
};

ExploreItem.defaultProps = {
	hideAllButtons: false,
	className: "",
};

export default ExploreItem;
