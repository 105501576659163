import {
	classNames,
	HomeRequestSkeleton, RequestNoDataIcon, Spinner, SpinnerLeafType, SvgIcon,
} from "@credo/ui-components";
import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import { cu, useEvtMgrListener, YesNoOptions } from "@credo/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import last from "lodash/last";
import { useMeasure, useWindowSize } from "react-use";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import sum from "lodash/sum";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { strings } from "../../i18n/config";
import {
	getReqCountAtom,
	isConnectedAtom, isSessionAuthAtom, userIdAtom, userNotifsReqCountAtom, userPendingRequestsAtom,
} from "../../utils/atoms";
import RequestListItem from "../../components/ListItems/RequestListItem";
import { UserActions } from "../../services/actions/user";
import CfgMgr from "../../config/CfgMgr";
import { Consts, EventConst } from "../../utils";
import { HelmetComponent } from "../../components/HelmetComponent";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";

const MAX_REQUEST_COUNT = CfgMgr.cfg_feed_nbOfItems2FetchFromDb;

enum OperationType {
	APPEND = "append",
	REPLACE = "replace",
}

interface RemoveItem {
	postUuid: string;
	attribute: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ProfileRequests: React.FC = () => {
	const [isConnected] = useAtom(isConnectedAtom);
	const [userId] = useAtom(userIdAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [loading, setLoading] = useState(true);
	const [requestData, setRequestData] = useAtom(userPendingRequestsAtom);
	const [isLastPage, setIsLastPage] = useState(false);
	const [, setIsLoadMore] = useState<boolean>(false);
	const [divRef, { width }] = useMeasure();
	const location = useLocation();
	const [reqCount] = useAtom(getReqCountAtom);
	const [notifReqCount, setNotifReqCount] = useAtom(userNotifsReqCountAtom);

	const dataPage = useRef(1);
	const dataSeed = useRef(1);

	const { width: windowWidth } = useWindowSize();

	useSetAppHeader({
		title: strings("Sidebar.requests"),
	});

	const getUserRequests = async (
		inProcessSetter: ((inProcess: boolean) => void) | null,
		operation: OperationType,
	) => {
		if (inProcessSetter) {
			inProcessSetter(true);
		}
		const requestMsgData = {
			page: dataPage.current,
			seed: dataSeed.current,
			lastTs: requestData.length > 0 && operation !== OperationType.REPLACE ? last(requestData)?.["p.creat_ts"] : 0,
			lastId: requestData.length > 0 && operation !== OperationType.REPLACE ? last(requestData)?.id : 0,
			user_id: userId ?? "",
			fetchSize: MAX_REQUEST_COUNT,
			targetUserOrProfId: userId ?? "",
			streamId: "None",
			summary: YesNoOptions.NO, // 'y' = summary response, 'n' = all requests
		};
		const { items } = await UserActions.fetchUserRequest(requestMsgData);
		if (items.length < MAX_REQUEST_COUNT) {
			setIsLastPage(true);
		}
		if (operation === OperationType.APPEND) {
			setRequestData((prevState) => [
				...prevState,
				...items,
			]);
		} else {
			setRequestData(items);
		}
		if (inProcessSetter) {
			inProcessSetter(false);
		}
	};

	useEffect(() => {
		if (isSessionAuth && isConnected) {
			getUserRequests(setLoading, OperationType.REPLACE);
		}
	}, [isConnected, isSessionAuth]);

	useEffect(() => {
		if (!loading) {
			getUserRequests(null, OperationType.REPLACE);
		}
		const totalRequests = sum(Object.values(reqCount));
		const hasRequests = totalRequests > 0;
		const requests = cloneDeep(notifReqCount);
		if (hasRequests) {
			set(requests, "requests.ego", 0);
			set(requests, "requests.credo", 0);
			setNotifReqCount(requests);
		}
	}, []);

	const renderRequests = () => (
		requestData.map((item, i) => (
			<RequestListItem
				key={item.id}
				item={item}
				isLast={requestData.length - 1 === i}
				onPage
			/>
		))
	);

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner leaf_shape={SpinnerLeafType.CIRCLES} width="20px" height="20px" />
			</div>
		);
	};

	const handleLoadMore = () => {
		if (loading || isLastPage) return;
		dataPage.current += 1;
		getUserRequests(setIsLoadMore, OperationType.APPEND);
	};

	const handleRemoveListItem = (listData: RemoveItem) => {
		const { postUuid, attribute } = listData;
		if (attribute === "p.postUuid") {
			let newData = requestData.filter((item) => item[attribute] !== postUuid);
			setRequestData(newData);
			newData = newData.slice(0, MAX_REQUEST_COUNT);
			setRequestData(newData);
		}
	};

	const handleNewRequests = () => {
		getUserRequests(setLoading, OperationType.REPLACE);
	};

	useEvtMgrListener(EventConst.removeListItem, handleRemoveListItem);
	useEvtMgrListener(EventConst.loadRequests, handleNewRequests);

	return (
		<div
			className={classNames(
				"block",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				"text-basic",
				"bg-background",
				"overflow-visible",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-6",
			)}
			style={{
				width: "100%",
			}}
			// @ts-ignore
			ref={divRef}
		>
			<HelmetComponent
				title={strings("ProfilePage.title")}
				url={location?.pathname}
			/>
			<div
				className={classNames(
					"flex flex-col text-white",
					"bg-background w-full",
					isMobile && windowWidth < 768 ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-4rem-1px)]",
				)}
			>
				<div className="relative pt-3">
					{loading
						? (
							<div className="max-h-[calc(100vh-4rem-1px-0.5rem)] overflow-hidden">
								<HomeRequestSkeleton skeletonCount={10} />
							</div>
						) : (
							<>
								{requestData && requestData.length > 0
									? (
										<InfiniteScroll
											next={handleLoadMore}
											hasMore
											loader={renderFooter()}
											dataLength={requestData.length}
										>
											{renderRequests()}
										</InfiniteScroll>
									) : (
										<div
											className={classNames(
												"flex flex-col items-center justify-center bg-background",
												windowWidth < 768 ? "h-[calc(100vh-4rem-1px-3rem-4rem)]" : "h-[calc(100vh-4rem-1px-3rem)]",
											)}
										>
											<SvgIcon
												icon={RequestNoDataIcon}
												title={strings("Assets.no_request")}
											/>
											<p className="w-44 text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap">
												{strings("RightMenu.no_request_message")}
											</p>
										</div>
									)}
							</>
						)}
				</div>
			</div>
		</div>
	);
};
