import {
	cu, dbg, LogMgr,
} from "@credo/utilities";
import { WebSocketConn } from "./WebSocketConn";
import CfgMgr from "../config/CfgMgr";
import { Consts } from "../utils";
import { WSEvtMgr as EvtMgr } from "../utils/WSEvtMgr";

// eslint-disable-next-line import/prefer-default-export
export class EventBusMgr {
	// arg = nulls
	ws: any = null;

	connected = false;

	init() {
		try {
			let encryption: string = "ssl"; // Check for nossl as well
			encryption = cu.isYes(CfgMgr.cfg_ws_dont_use_ssl) ? "nossl" : encryption; // added a way to override this for dev

			// @ts-ignore
			const hostAndPortA = CfgMgr[`cfg_credoWebSocketSvr_${encryption}`];
			if (dbg) LogMgr.mydbg(this, `hostAndPortA=${hostAndPortA} encryption=${encryption}`);

			const options = {
				reconnect_attempts_max: Infinity, // Max reconnect attempts
				reconnect_delay_min: 100, // Initial delay (in ms) before first reconnect attempt
				reconnect_delay_max: 31000, // Max delay (in ms) between reconnect attempts
				reconnect_exponent: 2, // Exponential backoff factor
				randomization_factor: 0.5, // Randomization factor between 0 and 1
				hostAndPortA: JSON.parse(JSON.parse(JSON.stringify(hostAndPortA))),
				encryption,
			};
			/// //this works///this.ws = new WebSocket(`wss://${global.cfg.credoHttpHost}:${global.cfg.credoHttpPort}/websocket/`);
			if (dbg) LogMgr.mydbg(this, "NEW WEB SOCKET");
			this.ws = new WebSocketConn(options);
			this.ws.enablePing(false);
			this.ws.enableReconnect(true);
			this.ws.onopen = (evt: any) => {
				this.ws.registerHandler("default", {}, this.msgHandler); // you can register other handler as well
				// Use event manager instead of fsms
				EvtMgr.getInstance(Consts.socketOnConnect).notifyListeners(); // send connect event to fsm which means do this transition
				// onSlowInternetConnection(); // show alert to user if internet connection is slow
				if (dbg) LogMgr.mydbg(this, `WS: CONNECTED !!!!! evt=${cu.jsonify(evt)}`);
				this.connected = true;
				// this.sendMsg('feed', 'info', 'MSG FROM REACT NATIVE');
			};
			this.ws.onerror = (exc: any) => {
				LogMgr.printException(this, "evtbusmgr: ", exc);
			};
			this.ws.onclose = (evt: CloseEvent) => {
				this.connected = false;
				// if(dbg)LogMgr.mydbg(this,'WS: onclose; evt='+jsonfy(evt));
				// Use event manager instead of fsms
				EvtMgr.getInstance(Consts.socketOnDisconnect).notifyListeners();
			};
			/* this.ws.onmessage = (msg)=>{
          if(dbg)LogMgr.mydbg(this,'WS: GOT MSG: '+ jsonfy(msg));
      } */
		} catch (exc) {
			LogMgr.printException(this, "evtbus: ", JSON.stringify(exc));
		}
		if (dbg)LogMgr.mydbg(this, "evtbusmgr inited1.");
	}

	msgHandler(err: any, msg: any) { // err is null
		// var jsonMsg = jsonfy(msg);
		if (dbg)LogMgr.mydbg(this, "evtbusmgr: GOT MSG", msg);
	}

	isConnected = () => this.connected;

	sendMsg(topic: string, mgsType: string, msg: any, cb: Function | undefined) { // only 1 handler for that topic will receive this
		this.ws.send(topic, mgsType, msg, cb);
	}
}
