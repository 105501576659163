import { SnackBarTypeOptions } from "@credo/ui-components";
import {
	ActionType,
	AppMode,
	cu,
	dbg,
	EvtMgr,
	GenericResponse,
	LogMgr,
	ResponseCode,
	YesNoOptions,
} from "@credo/utilities";
import CfgMgr from "../../config/CfgMgr";
import { strings } from "../../i18n/config";
import { FeedItem } from "../../modules/home/requests";
import {
	AppUtils, EventConst, MsgConst, Consts, AnalyticEventsConst,
} from "../../utils";
import FirebaseMgr from "../../utils/FirebaseMgr";
import { ItemType } from "../../utils/types";

export const NEW_POST_TAGS_LIMIT = 5;

export interface SaveSharedPostRequestObject {
	user_id: string;
	mode: string;
	targetStreamId: string;
	srcPostUuid: string;
	srcCommentUuid?: string;
	commentUuid?: string;
	postUuid: string;
	postText: string;
	commentText?: string;
	itemType: string;
	sharedContentRating: any;
	postTitle?: string;
	tags: any;
	newUserTags: any;
	removedUserTags: any;
}

export interface NewPostData {
	userId: string,
	created_by: ItemType,
	postUuid: string,
	mode: AppMode,
	postTitle: string,
	postText: string,
	mediaObj_Link_Title: string,
	mediaObj_Link_ImgUrl: string,
	mediaObj_Link_Text: string,
	mediaObj_hostname: string,
	mediaObj_channel_name: string,
	mediaObj_Link_Url: string,
	imgPathA: string[],
	videoPath: string[],
	streamId: string,
	streamName: string,
	progress: number,
	action: string,
	status: string,
	postTags: any,
	shared_postUuid: string,
	shared_creat_ts: string,
	shared_postText: string,
	shared_postTitle: string,
	shared_mode: string,
	shared_prof_id: string,
	shared_username: string,
	shared_firstName: string,
	shared_lastName: string,
	shared_profilePicRelUrl: string,
	src_s_name: string,
	shared_post_streamId: string,
	is_shared: YesNoOptions,
	shared_created_by: string,
	sharedContentRating: string,
	purpose: string,
	newUserTags: string,
	removedUserTags: string,
	reason?: string;
}

export interface SaveSharedPostResponseObject {
	addr: "shareItemIntoStream";
	items: FeedItem[];
	msg_id: string;
	procTs: number;
	retcd: string;
	sendTs: number;
	sid: string | null;
	wrap_id: string;
}

export interface CreateOrUpdatePostRequestObject {
	user_id: string;
	mode: string;
	postUuid: string;
	streamId: string;
	postTitle: string;
	postText: string;
	tags: string;
	newUserTags: string;
	removedUserTags: string;
	img_bucketRelativeRefPath0?: string;
	img_bucketRelativeRefPath1?: string;
	img_bucketRelativeRefPath2?: string;
	img_bucketRelativeRefPath3?: string;
	mediaObj_Link_Title: string;
	mediaObj_Link_ImgUrl: string;
	mediaObj_Link_Text: string;
	mediaObj_hostname: string;
	mediaObj_channel_name: string;
	mediaObj_Link_Url: string;
	action: string;
	video_bucketRelativeRefPath?: string;
}

export interface CreateOrUpdatePostResponseObject {
	addr: "savePost";
	items?: object[];
	msg_id: string;
	procTs: number;
	retcd: ResponseCode;
	sendTs: number;
	sid: string | null;
	wrap_id: string;
	postUuid?: string;
}

export async function uploadPhotosOrVideoToFirebaseAndSavePost(msgData: CreateOrUpdatePostRequestObject, msgType: string, cb: any) {
	try {
		const postDataA = cu.getGlobalVar(Consts.pendingTasks);
		const index = postDataA.findIndex((x: any) => (x.postUuid === msgData.postUuid)
			&& (x.postText === msgData.postText && x.streamId === msgData.streamId
				&& x.userId === msgData.user_id));
		if (index !== -1) {
			postDataA.postUuid = msgData.postUuid;
			if (cu.isSet(postDataA[index].imgPathA) && postDataA[index].imgPathA.length > 0) {
				for (let i = 0; i < postDataA[index].imgPathA.length; i++) {
					if (postDataA[index].imgPathA[i]
						&& !postDataA[index].imgPathA[i].includes(CfgMgr.cfg_imageStorage_path) && postDataA[index].is_shared !== "y") {
						const bucketRelativeRefPath = `${msgData.user_id}_${msgData.postUuid}_p${i}.jpg`;
						const fullFilePathOnDevice = postDataA[index].imgPathA[i];
						// eslint-disable-next-line no-await-in-loop
						await FirebaseMgr.saveFile2Firebase(fullFilePathOnDevice, bucketRelativeRefPath);
					}
					const updateTaskDetails: any = postDataA[index];
					updateTaskDetails.progress = 80 / postDataA[index].imgPathA.length;
					updateTaskDetails.postUuid = msgData.postUuid;
					EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
						task: updateTaskDetails,
						action: ActionType.UPDATE,
					});
				}
			}
			global.ebMgr.sendMsg(MsgConst.savePost, msgType, msgData, cb);
		}
	} catch (e) {
		if (dbg) LogMgr.mydbg("while creating post \"uploadPhotosOrVideoToFirebaseAndSavePost\"", e);
	}
}

export function CreateOrUpdatePost(request: CreateOrUpdatePostRequestObject) {
	return new Promise<CreateOrUpdatePostResponseObject>((resolve, reject) => {
		const callBack = async (_p: null, response: CreateOrUpdatePostResponseObject) => {
			if (response.retcd === ResponseCode.OK) {
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({ task: request, action: ActionType.DELETE });
				resolve(response);
			} else if (response.retcd === ResponseCode.ERR_STNA) {
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
					task: request,
					action: ActionType.DELETE,
					notify: false,
				});
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: strings("NewPostScreen.save_post_stream_deleted_error_msg"),
				});
				EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
					name: AnalyticEventsConst.newPost,
				});
				resolve(response);
			} else {
				const updateTaskDetails: any = request;
				if (response.retcd === ResponseCode.ERROR_POST) {
					AppUtils.showToast({
						type: SnackBarTypeOptions.ERROR,
						message: strings("NewPostScreen.save_post_error_msg"),
					});
					updateTaskDetails.status = Consts.PostFailed;
				} else if (response.retcd === ResponseCode.ERR_ADCH) {
					AppUtils.showToast({
						type: SnackBarTypeOptions.ERROR,
						message: strings("NewPostScreen.save_post_adult_content_msg"),
					});
					updateTaskDetails.status = ResponseCode.ERR_ADCH;
				} else {
					updateTaskDetails.status = Consts.PostRejected;
					updateTaskDetails.reason = strings("NewPostScreen.inappropriate_content_msg");
				}
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({ task: updateTaskDetails, action: ActionType.UPDATE });

				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from ${MsgConst.savePost} api: ${response.retcd}`);
			}
		};
		EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
			task: { postUuid: request.postUuid, progress: 10 },
			action: ActionType.UPDATE,
		});
		uploadPhotosOrVideoToFirebaseAndSavePost(request, MsgConst.savePost, callBack);
	});
}

export interface GetLinkPreviewRequestObject {
	user_id: string;
	previewURL: string;
}

export interface LinkPreviewInterface {
	title: string;
	image: string;
	desc: string;
	channel_name: string;
	url: string;
}

export function getLinkPreviewData(request: GetLinkPreviewRequestObject) {
	return new Promise<GenericResponse<LinkPreviewInterface>>((resolve, reject) => {
		const callBack = (_p: null, response: GenericResponse<LinkPreviewInterface>) => {
			if (response.retcd === ResponseCode.OK) {
				resolve(response);
			} else {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from get_linkPreview api: ${response.retcd}`);
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg(MsgConst.get_linkPreview, MsgConst.get_linkPreview, request, callBack);
	});
}

export function saveSharedPost(request: SaveSharedPostRequestObject) {
	return new Promise<SaveSharedPostResponseObject>((resolve, reject) => {
		const callBack = (_p: null, response: SaveSharedPostResponseObject) => {
			if (response.retcd === ResponseCode.OK) {
				if (response.items[0]["p.postUuid"] === cu.getGlobalVar(Consts.sharedObj).postUuid) {
					EvtMgr.getInstance(`${cu.getGlobalVar(Consts.sharedObj).srcPostUuid}${EventConst.postSharedCount}`).notifyListeners({
						srcPostUuid: cu.getGlobalVar(Consts.sharedObj).srcPostUuid,
						response,
					});
				}
				EvtMgr.getInstance(EventConst.postShared).notifyListeners(response);
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({ task: request, action: ActionType.DELETE });
				resolve(response);
			} else if (response.retcd === ResponseCode.ERR_SIIS) {
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
					task: request,
					action: ActionType.DELETE,
					notify: false,
				});
				AppUtils.showToast({
					type: SnackBarTypeOptions.ERROR,
					message: strings("NewPostScreen.shared_post_deleted"),
				});
			} else {
				const updateTaskDetails: any = request;
				if (response.retcd === ResponseCode.ERROR_POST) {
					AppUtils.showToast({
						type: SnackBarTypeOptions.ERROR,
						message: strings("NewPostScreen.save_post_error_msg"),
					});
					updateTaskDetails.status = Consts.PostFailed;
				} else if (response.retcd === ResponseCode.ERR_ADCH) {
					AppUtils.showToast({
						type: SnackBarTypeOptions.ERROR,
						message: strings("NewPostScreen.save_post_adult_content_msg"),
					});
					updateTaskDetails.status = ResponseCode.ERR_ADCH;
				} else {
					updateTaskDetails.status = Consts.PostRejected;
					updateTaskDetails.reason = strings("NewPostScreen.inappropriate_content_msg");
				}
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({ task: updateTaskDetails, action: ActionType.UPDATE });

				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from ${MsgConst.shareItemIntoStream} api: ${response.retcd}`);
			}
		};
		// @ts-ignore
		global.ebMgr.sendMsg(MsgConst.shareItemIntoStream, MsgConst.shareItemIntoStream, request, callBack);
	});
}
