import React, { useEffect, useReducer, useState } from "react";
import { AppMode, cu, EvtMgr } from "@credo/utilities";
import {
	RequestDeclineIcon, RequestAcceptIcon, SvgIcon, PostHeaderScreenType, classNames, ProfilePicture,
} from "@credo/ui-components";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import RequestUserListItemReducer from "./RequestListItemReducer";
import { EventItemScreenType, REQUEST_ACTION, REQUEST_TYPE } from "./types";
import FirebaseMgr from "../../utils/FirebaseMgr";
import {
	isConnectedAtom, userIdAtom, userProfileAtom,
} from "../../utils/atoms";
import { UserActions } from "../../services/actions/user";
import { AppUtils, EventConst } from "../../utils";
import { strings } from "../../i18n/config";
import { ParentNavTypes, TaskType } from "../../utils/types";

interface RequestListItemProps {
	/**
	 * list entry object
	 * */
	item: any;
	/**
	 * Gives whether list entry is last or not to show the divider
	 * */
	isLast: boolean;
	/**
	 * If the item needs to be rendered on the page instead of
	 * the right section. The UI will be different and is matched
	 * with the mobile app.
	 * */
	onPage?: boolean;
	showBlueDot?: boolean;
}

export default function RequestListItem({
	item,
	isLast,
	onPage,
	showBlueDot,
}: RequestListItemProps) {
	const [postedDateDiff, setPostedDateDiff] = useState(null);
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [userId] = useAtom(userIdAtom);
	const navigate = useNavigate();

	const [state, dispatch] = useReducer(RequestUserListItemReducer, {
		username: "",
		firstName: "",
		lastName: "",
		profilePicUrl: "",
		profId: "",
		postText: "",
		reqType: "",
		edit: "",
		streamNm: "",
		streamId: "",
		createTs: "",
		struname: "",
		egousername: "",
		mode: "",
	});

	const {
		username,
		firstName,
		lastName,
		profilePicUrl,
		profId,
		postText,
		reqType,
		streamNm,
		streamId,
		createTs,
		struname,
		egousername,
		mode,
	} = state;

	useEffect(() => {
		if (item) {
			dispatch({ type: EventItemScreenType.REQUEST_SCREEN, payload: item });
		}
	}, [item]);

	const calculatePostTimeDiff = () => {
		setPostedDateDiff(cu.calculatePostTime(createTs));
	};

	useEffect(() => {
		calculatePostTimeDiff();
	}, [createTs]);

	const redirectToUserProfile = () => {
		AppUtils.navigateToUserOrStream({
			type: PostHeaderScreenType.User,
			isCredoMode,
			navigate,
			egousername,
			mode,
			profId,
			userName: username,
			userProfile,
			parentLink: ParentNavTypes.PROFILE,
			...item,
			meta: {
				title: username ? `@${username}` : `${firstName} ${lastName} (@${egousername})`,
			},
		});
	};

	const redirectToStreamProfile = () => {
		AppUtils.navigateToUserOrStream({
			type: PostHeaderScreenType.Stream,
			isCredoMode,
			navigate,
			struname,
			streamId,
			mode,
			userProfile,
			parentLink: ParentNavTypes.PROFILE,
			...item,
			meta: {
				title: streamNm,
			},
		});
	};

	const renderRequest = () => (
		<div className="mb-1">
			<button
				onClick={redirectToUserProfile}
				type="button"
				className="text-primary text-sm leading-5 font-normal"
			>
				<span>
					{username || `${firstName} ${lastName}`}
				</span>
			</button>
			<div>
				<p
					className={classNames(
						"text-basic font-light text-sm leading-4",
						item.streamStyle === AppMode.CREDO ? "item-text-credo" : "item-text-ego",
					)}
				>
					<span>{`${postText} `}</span>
					{reqType === REQUEST_TYPE.JOIN_STREAM
						? (
							<button
								onClick={redirectToStreamProfile}
								type="button"
								className="text-primary text-sm leading-4 font-normal"
							>
								{`${streamNm} `}
							</button>
						) : null}
				</p>
				<span className="text-xs text-gray-dark">
					{postedDateDiff}
				</span>
			</div>
		</div>
	);

	const renderRequestBasedOnRequestType = () => {
		switch (reqType) {
			case REQUEST_TYPE.CONNECT_USER:
			case REQUEST_TYPE.JOIN_STREAM:
				return renderRequest();
			default: return null;
		}
	};

	const onPressAction = async (action: REQUEST_ACTION) => {
		if (isConnected) {
			if (item["p.reqType"] === REQUEST_TYPE.CONNECT_USER) {
				// Actioning on connection request here
				const actionRequst = {
					user_id: userId ?? "",
					mode: AppMode.EGO,
					postUuid: item["p.postUuid"],
					action,
					taskType: TaskType.CONNECT_REQUEST,
				};
				await UserActions.performActionOnTask(actionRequst);
				// TODO: show snackbar here
				EvtMgr.getInstance(EventConst.removeListItem).notifyListeners({
					...actionRequst,
					item,
					postUuid: item["p.postUuid"],
					attribute: "p.postUuid",
				});
			} else if (item["p.reqType"] === REQUEST_TYPE.JOIN_STREAM) {
				// Actioning on stream join request here
				const actionRequst = {
					user_id: userId ?? "",
					mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
					postUuid: item["p.postUuid"],
					streamId: item["p.targetStreamId"],
					action,
					taskType: TaskType.JOIN_REQUEST,
				};
				await UserActions.performActionOnTask(actionRequst);
				// TODO: show snackbar here
				EvtMgr.getInstance(EventConst.removeListItem).notifyListeners({
					...actionRequst,
					item,
					postUuid: item["p.postUuid"],
					attribute: "p.postUuid",
				});
			}
		}
	};

	if (onPage) {
		return (
			<div
				key={profId}
				className={classNames(
					"flex flex-row justify-start items-center w-full",
					item.streamStyle === AppMode.CREDO ? "item-bg-credo" : "item-bg-ego",
				)}
			>
				{showBlueDot && (
					<div className="h-full w-2 justify-center items-center flex pl-2">
						<div
							className={classNames(
								"flex relative",
								item.read ? "h-0 w-0" : "h-2 w-2",
							)}
						>
							<span className="relative inline-flex rounded-full h-2 w-2 bg-accent" />
						</div>
					</div>
				)}
				<div className="flex flex-row items-center pr-3 pl-2 py-2">
					<ProfilePicture
						profilePicUrl={FirebaseMgr.buildSourceUrlImage(profilePicUrl) ?? ""}
					/>
				</div>
				<div
					className={classNames(
						"flex flex-row justify-between flex-1",
						item.streamStyle === AppMode.CREDO ? "border-bottom-credo" : "border-bottom-ego",
						isLast ? "border-none" : "",
						"py-2 pr-3",
					)}
				>
					<div className="flex justify-center items-center">
						{renderRequestBasedOnRequestType()}
					</div>
					<div className="flex flex-row items-center">
						<SvgIcon
							icon={RequestDeclineIcon}
							title={strings("Assets.cancel_request")}
							className="cursor-pointer"
							onClick={() => onPressAction(REQUEST_ACTION.REJECT)}
						/>
						<div className="w-2" />
						<SvgIcon
							icon={RequestAcceptIcon}
							onClick={() => onPressAction(REQUEST_ACTION.ACCEPT)}
							className="cursor-pointer"
							title={strings("Assets.accept_request")}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div
				key={profId}
				className="flex flex-row justify-between items-center bg-background-tertiary w-full p-2"
			>
				<div className="flex flex-row items-center">
					<div>
						<ProfilePicture
							profilePicUrl={FirebaseMgr.buildSourceUrlImage(profilePicUrl) ?? ""}
						/>
					</div>
					<div className="flex flex-row ml-3">
						<div className="flex">
							{renderRequestBasedOnRequestType()}
						</div>
					</div>
				</div>
				<div className="flex flex-row">
					<SvgIcon
						icon={RequestDeclineIcon}
						title={strings("Assets.cancel_request")}
						className="cursor-pointer"
						onClick={() => onPressAction(REQUEST_ACTION.REJECT)}
					/>
					<div className="w-2" />
					<SvgIcon
						icon={RequestAcceptIcon}
						onClick={() => onPressAction(REQUEST_ACTION.ACCEPT)}
						className="cursor-pointer"
						title={strings("Assets.accept_request")}
					/>
				</div>
			</div>
			{!isLast && <div style={{ height: 1 }} className="bg-background mx-4" />}
		</>
	);
}

RequestListItem.defaultProps = {
	onPage: false,
	showBlueDot: true,
};
