// eslint-disable-next-line import/prefer-default-export
import {
	cu, dbg, EvtMgr, YesNoOptions, LogMgr, GenericResponse, ResponseCode, ActionType,
} from "@credo/utilities";
import { SnackBarTypeOptions } from "@credo/ui-components";
import { MsgMgr } from "../config/MsgMgr";
import { Consts, EventConst } from "./Consts";
import { findOrCreateUser } from "../modules/auth/requests";
import { UserActions } from "../services/actions/user";
import { isCredoConnOpenAndNetAvailable } from "./network";
import { UserProfile } from "./types";
import AppUtils from "./AppUtils";
import { strings } from "../i18n/config";

/**
 * Request the server for QR authentication in interval
 *
 * @param {number} timeout The interval for requesting the server.
 * @param {Function} setTimerRef Function which will set the timer
 * into a ref and in the caller component the timer can be cleared
 * */
export const checkQRLoop = (timeout: number, setTimerRef: (timer: any) => void) => {
	const qrLoginCode = localStorage.getItem(Consts.qrCodeLogin);
	/**
	 * Requesting to server in an interval of time.
	 * Removed Timeout, not needed here.
	 * */
	const qrTimer = setInterval(() => {
		if (dbg) LogMgr.mydbg("checking qr", cu.isYes(cu.getGlobalVar(Consts.isQRAuth)));
		if (!cu.isYes(cu.getGlobalVar(Consts.isQRAuth))) {
			if (isCredoConnOpenAndNetAvailable()) {
				MsgMgr.makeRemoteRequest_generic<{qrCode: string, action: string}, GenericResponse<UserProfile[]>>({
					msgName: `get_${EventConst.qrLogin}`,
					instanceCallback: (response: any) => {
						// @ts-ignore
						EvtMgr.getInstance(`${EventConst.qrLogin}_${JSON.parse(qrLoginCode)}`).notifyListeners(response);
					},
					request: {
						// @ts-ignore
						qrCode: JSON.parse(qrLoginCode),
						action: ActionType.CHECK_QR,
						// user_id: SessMgr.getFromSession(Consts.user_id),
					},
				});
			} else if (dbg) LogMgr.mydbg("not connected or no net; will retry.");
		} else if (dbg) LogMgr.mydbg("qr already auth");
	}, timeout);
	setTimerRef(qrTimer);
};

/**
 * Keeping browser id in local storage even after a user logs out
 * along with client location, client country code and client IP
 * address also resetting the theme to CREDO
 * */
export const clearStorage = () => {
	const browserId = localStorage.getItem(Consts.browserId);
	const clientLocation = localStorage.getItem(Consts.clientLocation);
	const clientCountryCode = localStorage.getItem(Consts.clientCountryCode);
	const clientIPAddress = localStorage.getItem(Consts.clientIPAddress);
	const cookiesInfo = localStorage.getItem(Consts.cookiesInfo);
	const mode = localStorage.getItem(Consts.mode);
	localStorage.clear();
	if (browserId) {
		localStorage.setItem(Consts.browserId, browserId);
	}
	if (clientLocation && clientCountryCode) {
		localStorage.setItem(Consts.clientLocation, clientLocation);
		localStorage.setItem(Consts.clientCountryCode, clientCountryCode);
	}
	if (clientIPAddress) {
		localStorage.setItem(Consts.clientIPAddress, clientIPAddress);
	}
	if (cookiesInfo) {
		localStorage.setItem(Consts.cookiesInfo, cookiesInfo);
	}
	if (mode) {
		localStorage.setItem(Consts.mode, mode);
	}
};

/**
 * QR Authentication setup. This will add a listener in the app so
 * that whenever server responds back to the app we will catch that
 * event and execute findOrCreateUser to get the user session.
 * Until the response the get_qrLogin action is called to the server
 * in interval to check if QR is authenticated or not.
 *
 * NOTE: Timer should be clear after unmount of the component where
 * it is being called
 *
 * @param {number} timeout The interval for requesting the server.
 * @param {Function} setTimerRef Function which will set the timer
 * into a ref and in the caller component the timer can be cleared
 * @see checkQRLoop
 * */
export const setupWaitToAuthWithQR = (timeout: number, setTimerRef: (timer: any) => void) => {
	if (dbg) LogMgr.mydbg(this, "setting up wait for qr");

	// @ts-ignore
	const qrLoginCode = JSON.parse(localStorage.getItem(Consts.qrCodeLogin));

	EvtMgr.getInstance(`${EventConst.qrLogin}_${qrLoginCode}`).addListener(async (msg: any) => {
		if (cu.isSuccessMsg(msg)) {
			if (msg.retcd === ResponseCode.OK_QR_AUTH) {
				if (dbg) LogMgr.mydbg("MsgMgr", "qr code accepted, will create session", msg);
				cu.setGlobalVar(Consts.isQRAuth, YesNoOptions.YES);
				const { phone } = msg;
				const { user_id } = msg;
				const qrCode = qrLoginCode;
				const msgData = {
					phone,
					user_id,
					qrCode,
				};
				const clientLocation = cu.getGlobalVar(Consts.clientLocation);
				if (phone && cu.isSet(phone)) {
					await findOrCreateUser(msgData, clientLocation);
				} else {
					AppUtils.showToast({
						type: SnackBarTypeOptions.ERROR,
						message: strings("AuthModal.error.qr_no_number"),
					});
				}
				// Since saveData is called here, no need to call it again after findOrCreate Response
				UserActions.sendSaveData();
			}
		} else {
			// TODO: Show snackbar here for the error
		}
	});

	if (dbg) LogMgr.mydbg(this, "starting check_qr_loop");
	checkQRLoop(timeout, setTimerRef);
};
