import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
	RadioButtonEmptyIcon, RadioButtonSelectedIcon, classNames, SvgIcon, BackIcon,
} from "@credo/ui-components";
import { useAtom } from "jotai";
import { cu, EvtMgr, StreamModes } from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { strings } from "../../i18n/config";
import { EventConst } from "../../utils";
import { ReportListItem, StreamInfo } from "../../utils/types";
import { userSessionAtom } from "../../utils/atoms";
import { StreamActions } from "../../services/actions/stream";

export interface StreamJoinModeScreenProps {
	onDone: () => void;
	streamInfo: any;
}

const radioProps = [
	{ label: strings("StreamJoinModeScreen.ego_or_credo"), value: StreamModes.BOTH },
	{ label: strings("StreamJoinModeScreen.credo_only"), value: StreamModes.CREDO },
	{ label: strings("StreamJoinModeScreen.ego_only"), value: StreamModes.EGO },
];

const StreamJoinModeScreen = (props: StreamJoinModeScreenProps) => {
	const {
		onDone,
		streamInfo,
	} = props;
	const [userSession] = useAtom(userSessionAtom);
	const [communityDetails, setCommunityDetails] = useState<StreamInfo | null>(streamInfo);

	useEffect(() => {
		setCommunityDetails(streamInfo);
	}, [streamInfo]);

	const changeJoinMode = (value: any) => {
		const updateStream = {
			user_id: userSession?.user_id || "",
			mode: cu.getAppMode(),
			name: streamInfo?.name,
			descr: streamInfo.descr,
			struname: streamInfo.struname,
			style: streamInfo?.style,
			type: streamInfo?.type,
			hier: streamInfo?.hier,
			categ: streamInfo?.categ,
			disc: streamInfo?.disc,
			streamId: streamInfo?.streamId,
			joinModes: value,
		};

		StreamActions.updateStreamInfo(
			updateStream,
			(response) => {
				EvtMgr.getInstance(EventConst.updateStreamInfo).notifyListeners({
					...response,
					items: [updateStream],
				});
				setCommunityDetails({
					...communityDetails,
					...updateStream,
				});
			},
			() => { },
		);
	};

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const renderRadioGroupItem = (item: ReportListItem) => (
		<RadioGroup.Option key={item.value} value={item.value}>
			{({ checked }) => (
				<div className="px-2">
					<div
						className="flex flex-row py-2.5 items-center hover:cursor-pointer"
					>
						<div className="w-10 h-6">
							{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
						</div>
						<div className="w-full">
							<p className="text-sm font-medium text-title-color">{item.label}</p>
						</div>
					</div>
					<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
				</div>
			)}
		</RadioGroup.Option>
	);

	return (
		<div className="w-full flex flex-col pb-3 px-3 h-[calc(100vh-100px)]">
			<RadioGroup
				value={communityDetails?.joinModes}
				onChange={changeJoinMode}
				disabled={!!communityDetails?.joinModes}
				className={classNames(communityDetails?.joinModes ? "opacity-50" : "")}
			>
				{radioProps.map((item: ReportListItem) => renderRadioGroupItem(item))}
			</RadioGroup>
		</div>
	);
};

export default StreamJoinModeScreen;
