/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import {
	PostProgressBar, SnackBarTypeOptions, UserProfile,
} from "@credo/ui-components";
import {
	ActionType, AppMode, cu, EvtMgr,
} from "@credo/utilities";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import CfgMgr from "../../config/CfgMgr";
import { strings } from "../../i18n/config";
import { CreateOrUpdatePost, NewPostData } from "../../services/actions/new_post";
import { AppUtils, Consts, EventConst } from "../../utils";
import {
	allCachedImagesAtom,
	isConnectedAtom,
	pendingTasksAtom,
	userProfileAtom,
} from "../../utils/atoms";

interface PendingTasksProps {
	streamId?: string;
}

export default function PendingTasks({
	streamId,
}: PendingTasksProps) {
	const [userProfile] = useAtom(userProfileAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [cachedImages] = useAtom(allCachedImagesAtom);
	const [egoProfilePicture, setEgoProfilePicture] = useState("");
	const [credoProfilePicture, setCredoProfilePicture] = useState("");
	const [pendingTasks] = useAtom(pendingTasksAtom);

	const removeProgressBar = (postData: NewPostData) => {
		EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
			task: { postUuid: postData.postUuid },
			action: ActionType.DELETE,
			notify: false,
		});
	};

	const retryNewPost = (postData: NewPostData) => {
		const msgData: any = {
			user_id: postData?.userId,
			mode: postData?.mode,
			postUuid: postData?.postUuid,
			postText: postData?.postText,
			postTitle: postData?.postTitle,
			mediaObj_Link_Title: postData?.mediaObj_Link_Title,
			mediaObj_Link_Text: postData?.mediaObj_Link_Text,
			mediaObj_hostname: postData?.mediaObj_hostname,
			mediaObj_channel_name: postData?.mediaObj_channel_name,
			mediaObj_Link_Url: postData?.mediaObj_Link_Url,
			mediaObj_Link_ImgUrl: postData?.mediaObj_Link_ImgUrl,
			tags: "",
			newUserTags: postData?.newUserTags,
			removedUserTags: postData?.removedUserTags,
			streamId: postData?.streamId,
			action: postData?.action,
		};
		if (cu.isSet(postData?.imgPathA) && postData?.imgPathA.length > 0) {
			for (let i = 0; i < postData?.imgPathA.length; i++) {
				if (CfgMgr.cfg_imageStorage_path && postData?.imgPathA[i]
					&& postData?.imgPathA[i].includes(CfgMgr.cfg_imageStorage_path || "")) {
					msgData[`img_bucketRelativeRefPath${i}`] = postData?.imgPathA[i].substring(
						CfgMgr.cfg_imageStorage_path?.length, postData?.imgPathA[i].indexOf("?"),
					);
				} else {
					msgData[`img_bucketRelativeRefPath${i}`] = `${postData?.userId}_${postData?.postUuid}_p${i}.jpg`;
				}
			}
		}
		CreateOrUpdatePost(msgData);
	};

	const retryPost = (postData: NewPostData) => {
		if (isConnected) {
			const pendingTasks = cu.getGlobalVar(Consts.pendingTasks);
			const index = pendingTasks.findIndex((x: { postUuid: string; }) => x.postUuid === postData.postUuid);
			if (index !== -1) {
				EvtMgr.getInstance(EventConst.updatePendingTasks).notifyListeners({
					task: { postUuid: postData.postUuid, status: Consts.Posting, progress: 0 },
					action: ActionType.UPDATE,
				});
				switch (postData?.purpose) {
					case Consts.purpose_new_post:
						retryNewPost(postData);
						break;
					default:
						break;
				}
			}
		} else {
			AppUtils.showToast({
				type: SnackBarTypeOptions.ERROR,
				message: `${strings("PostProgressBar.no_internet_title")} \n${strings("PostProgressBar.no_internet_error_msg")}`,
			});
		}
	};

	if (!pendingTasks) {
		return null;
	}

	let allPendingTasks = pendingTasks;

	if (cu.isSet(streamId)) {
		allPendingTasks = pendingTasks.filter((e: any) => e.streamId === streamId);
	}

	const getCachedImage = (url: string) => {
		if (!url) return null;
		return cachedImages && cachedImages[url] ? cachedImages[url] : cu.buildSourceUrlImage(url);
	};

	useEffect(() => {
		if (userProfile) {
			setEgoProfilePicture(getCachedImage(userProfile?.eprofilePicRelUrl));
			setCredoProfilePicture(getCachedImage(userProfile?.cprofilePicRelUrl));
		}
	}, [userProfile]);

	const getCrtUserIcon = (userInfo: UserProfile | null, mode: AppMode) => {
		if (!cu.isSet(userInfo)) {
			return "";
		}
		if (AppMode.CREDO === mode) {
			return userInfo?.cprofilePicRelUrl;
		}
		return userInfo?.eprofilePicRelUrl;
	};

	const getProfilePic = (mode: AppMode) => {
		let profilePic = mode === AppMode.CREDO ? credoProfilePicture : egoProfilePicture;
		if (!cu.isSet(profilePic)) {
			profilePic = getCrtUserIcon(userProfile, mode) || "";
		}
		return profilePic;
	};

	const getPostMessage = (postItem: NewPostData) => {
		let message = "";
		switch (postItem.status) {
			case Consts.PostRejected:
				message = strings("PostProgressBar.posting_failed") + postItem.reason;
				break;
			case Consts.savePostAdultContent:
				message = strings("PostProgressBar.save_post_adult_content_msg");
				break;
			case Consts.NoInternet:
				message = strings("PostProgressBar.no_internet_msg");
				break;
			case Consts.PostFailed:
				message = `${strings("PostProgressBar.failed_posting_to")
					+ AppUtils.handleStreamName(postItem.streamName)}`;
				break;
			case Consts.PostUpdating:
				message = `${strings("PostProgressBar.updating_in")
					+ AppUtils.handleStreamName(postItem.streamName)}`;
				break;
			case Consts.PostSharing:
				message = `${strings("PostProgressBar.sharing_to") + postItem.streamName}`;
				break;
			default:
				message = `${strings("PostProgressBar.posting_to")
					+ AppUtils.handleStreamName(postItem.streamName)}`;
				break;
		}
		return message;
	};

	return (
		<div className="flex bg-background-tertiary w-full flex-col">
			{allPendingTasks?.map((postItem: any, key: any) => (
				<div key={postItem.postUuid + key} className="pb-px">
					<PostProgressBar
						item={postItem}
						isFailed={postItem.status === Consts.PostFailed}
						percent={postItem.status === Consts.PostFailed
							|| postItem.status === Consts.PostRejected
							|| postItem.status === Consts.savePostAdultContent
							|| (postItem.status === Consts.NoInternet && !isConnected)
							? 100 : postItem.progress}
						showRemoveIcon={postItem.status === Consts.savePostAdultContent}
						removeProgressBar={() => removeProgressBar(postItem)}
						retryPost={() => retryPost(postItem)}
						message={getPostMessage(postItem)}
						profilePicture={getProfilePic(postItem.mode)}
						progressColor={postItem.status === Consts.PostFailed || postItem.status === Consts.PostRejected
							|| postItem.status === Consts.savePostAdultContent || postItem.status === Consts.NoInternet
							? "bg-error" : "bg-primary"}
					/>
				</div>
			))}
		</div>
	);
}

PendingTasks.defaultProps = {
	streamId: null,
};
