import { classNames } from "@credo/ui-components";
import React from "react";
import { strings } from "../../i18n/config";

// eslint-disable-next-line import/prefer-default-export
export const PostAuthor: React.FC = () => (
	<button
		type="button"
		className={classNames(
			"ml-2.5 px-1 rounded cursor-pointer flex justify-center items-center h-[1.25rem]",
			"border border-author-indicator-border",
			"mt-0.5",
		)}
	>
		<p className="text-basic text-2xs-less-space">
			{strings("PostAuthor.author")}
		</p>
	</button>
);
