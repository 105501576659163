import { useEffect, useRef } from "react";
import { WSEvtMgr } from "../WSEvtMgr";

/**
 * Adds and removes the Websocket event with its listener when
 * component mounts and unmounts respectively.
 *
 * @param {string} eventName The name of the event to be captured.
 * @param {Function} handler The function/listener which will be
 * executed when the event is fired.
 * */
// eslint-disable-next-line import/prefer-default-export
export function useWSEvtMgrListener(eventName: string, handler: (...args: any[]) => void) {
	const savedHandler = useRef((...args: any[]) => { });

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (...args: any[]) => savedHandler.current(...args);
		WSEvtMgr.getInstance(eventName).addListener(eventListener);

		return () => {
			WSEvtMgr.getInstance(eventName).removeListener(eventListener);
		};
	}, [eventName]);
}
