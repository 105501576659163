// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification

import {
	AppMode, cu, dbg, EvtMgr, LogMgr,
} from "@credo/utilities";
// eslint-disable-next-line import/no-extraneous-dependencies
import { MessagePayload, NextFn, Observer } from "@firebase/messaging";
import CfgMgr from "../config/CfgMgr";
import { EventConst } from "./Consts";
import FirebaseMgr from "./FirebaseMgr";
import { JoinModes, PushNotiFicationType } from "./types";
import { OTHER_USER_PROFILE_PAGE, POST_DETAIL_PAGE, STREAM_PROFILE_PAGE } from "../routes/constants";

export interface NotificationPayload {
	from: string,
		messageId: string,
		notification: {
		title: string,
			body: string
	},
	data: {
		prof_id: string,
			notifType: PushNotiFicationType,
			additionalParam: string,
			user_id: string
	}
}

export interface NotificationAdditionalParams {
	id: string;
	firstName: string;
	username: string;
	egousername: string;
	pCommentUuid: string;
	commentUuid: string;
	struname: string;
	srcPostUuid: string;
	srcCommentUuid: string;
	srcMode: AppMode;
	src_mode: AppMode;
	joinModes: JoinModes;
	adminMode: AppMode;
	// used for suggested contacts
	dest_egousername: string;
}

export default class PushNotificationsMgr {
	/**
	 * returns true if app has push notification permission or not
	 * @return {boolean}
	 * */
	static hasPushNoticationPerm = () => Notification.permission === "granted";

	/**
	 * checks and request for app push notification permission
	 * */
	static requestPermission() {
		// Let's check if the browser supports notifications
		if (!("Notification" in window)) {
			if (dbg) LogMgr.mydbg(this, "This browser does not support desktop notification");
			EvtMgr.getInstance(EventConst.pushNotificationPerm).notifyListeners(false);
		} else if (this.hasPushNoticationPerm()) {
			if (dbg) LogMgr.mydbg(this, "Permission already granted");
			EvtMgr.getInstance(EventConst.pushNotificationPerm).notifyListeners(true);
		} else if (Notification.permission !== "denied") {
			// we need to ask the user for permission
			if (dbg) LogMgr.mydbg(this, "Asking the user for permission");
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					if (dbg) LogMgr.mydbg(this, "Permission granted");
					// TODO: set const in global const
					EvtMgr.getInstance(EventConst.pushNotificationPerm).notifyListeners(true);
				} else {
					if (dbg) LogMgr.mydbg(this, "Permission declined");
					EvtMgr.getInstance(EventConst.pushNotificationPerm).notifyListeners(false);
				}
			});
		}
	}

	static convertAdditionParamToObject(splitArray: string[]) {
		if (splitArray.length < 2) return { id: splitArray[0] };
		return splitArray.reduce((acc, value) => {
			const split = value?.split("=");
			const key = split[0]?.trim();
			const keyValue = split[1]?.trim();
			return { ...acc, [key]: keyValue };
		}, {});
	}

	static navigateToStreamProfile(url: string, params: NotificationAdditionalParams) {
		if (params?.joinModes && params?.joinModes !== JoinModes.EGO_OR_CREDO) {
			return `${url}${STREAM_PROFILE_PAGE}/${params.struname}?mode=${params?.joinModes}`;
		}
		if (params?.joinModes && params?.joinModes === JoinModes.EGO_OR_CREDO) {
			if (params?.adminMode) {
				return `${url}${STREAM_PROFILE_PAGE}/${params.struname}?mode=${params?.adminMode}`;
			}
		}
		if (params?.src_mode) {
			return `${url}${STREAM_PROFILE_PAGE}/${params.struname}?mode=${params?.src_mode}`;
		}
		return `${url}${STREAM_PROFILE_PAGE}/${params.struname}`;
	}

	// dest_egousername
	static urlBuilder(url:string, notificationType: PushNotiFicationType, params: NotificationAdditionalParams) {
		/**
		 * Suppose a credo user follows an ego user, when user navigates using the notification
		 * the user should be able to see the follow back button that user's profile.
		 * */
		switch (notificationType) {
			/**
			 * If you have joined any comm or any user joins your
			 * created comm
			 * */
			case PushNotiFicationType.JoinStream:
			case PushNotiFicationType.FollowStream:
			case PushNotiFicationType.AdminStream:
				return this.navigateToStreamProfile(url, params);
			case PushNotiFicationType.ConnectionRequest:
				return `${url}${OTHER_USER_PROFILE_PAGE}/${params.egousername}?mode=e`;
			case PushNotiFicationType.FollowUser:
				if (params.username) {
					return `${url}${OTHER_USER_PROFILE_PAGE}/${params.username}`;
				}
				return `${url}${OTHER_USER_PROFILE_PAGE}/${params.egousername}`;
			case PushNotiFicationType.PostNotif:
				if (params.id) return `${url}${POST_DETAIL_PAGE}/id=${params.id}`;
				return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}`;
			/**
			 * Credo user A created a post, an ego user B comments on the post
			 * user A is in ego mode, opens the notification srcMode
			 * */
			case PushNotiFicationType.Comment:
				if (cu.isSet(params.pCommentUuid) && params.pCommentUuid !== "null" && params?.srcMode) {
					return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}$commentUuid=${params.pCommentUuid}`
						+ `$subCommentUuid=${params.commentUuid}?mode=${params?.srcMode}`;
				}
				if (cu.isSet(params.pCommentUuid) && params.pCommentUuid !== "null") {
					return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}$commentUuid=${params.pCommentUuid}`
						+ `$subCommentUuid=${params.commentUuid}`;
				}
				if (params.commentUuid && params?.srcMode) {
					return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}$commentUuid=${params.commentUuid}?mode=${params?.srcMode}`;
				}
				return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}$commentUuid=${params.commentUuid}`;
			/**
			 * Credo user A tags User B a post, user B is in ego mode
			 * user B clicks on notification and the app will be opened
			 * in credo mode (srcMode)
			 * */
			case PushNotiFicationType.UserTag:
				if (params.srcCommentUuid && params?.srcMode) {
					return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}`
						+ `$commentUuid=${params.srcCommentUuid}?mode=${params?.srcMode}`;
				}
				if (params.srcCommentUuid) {
					return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}$commentUuid=${params.srcCommentUuid}`;
				}
				return `${url}${POST_DETAIL_PAGE}/id=${params.srcPostUuid}`;
			case PushNotiFicationType.SuggestedContacts:
				return `${url}${OTHER_USER_PROFILE_PAGE}/${params.dest_egousername}`;
			default: return url;
		}
	}

	/**
	 * navigates user on click or push notification
	 * */
	static onNotificationClick(event: any, notification: NotificationPayload) {
		const notificationData = notification.data;
		if (dbg) {
			LogMgr.mydbg("Push Notification data", notificationData);
		}
		const splitParams = notificationData.additionalParam.split(",");
		if (notificationData && window) {
			window.open(
				this.urlBuilder(
					CfgMgr.APP_DOMAIN,
					notificationData.notifType,
					this.convertAdditionParamToObject(splitParams) as NotificationAdditionalParams,
				),
			);
		}
	}

	/**
	 * Shows push notification on the app
	 * @params {string} title: push notification title
	 * @params {any} notification: notification has data and notification type
	 * */
	static showPushNotification(title: string, notification: any, payload?: NextFn<MessagePayload> | Observer<MessagePayload>) {
		if (this.hasPushNoticationPerm()) {
			let icon = "";
			try {
				icon = FirebaseMgr.buildSourceUrlImage(`push_notification/web_pushNotif_${(CfgMgr.CFG_FLAVOR).toLowerCase()}.png`) ?? "";
			} catch (err) {
				if (dbg) LogMgr.mydbg(this, "Error while getting icon");
			}
			const notifOptions = {
				body: notification.body,
				data: notification.data,
				icon,
				onclick,
			};
			const notifResult = new Notification(title, notifOptions);
			if (notifResult) {
				notifResult.onclick = (event: any) => {
					event.preventDefault(); // prevent the browser from focusing the Notification's tab
					this.onNotificationClick(event, payload as unknown as NotificationPayload);
					notifResult.close();
				};
			}
		}
	}

	/**
	 * Refresh the requests section on getting push notification
	 * @params {any} notification: has notification type and data
	 * */
	static performActionOnPNReceive(notification: any) {
		const notificationType = notification?.data ? notification.data.notifType : notification?.notifType;
		const notificationData = notification?.data;
		switch (notificationType) {
			case PushNotiFicationType.ConnectionRequest:
				EvtMgr.getInstance(EventConst.loadRequests).notifyListeners();
				EvtMgr.getInstance(EventConst.loadNotifications).notifyListeners();
				EvtMgr.getInstance(EventConst.updateOtherUserData).notifyListeners(notificationData);
				break;
			case PushNotiFicationType.JoinStream:
				EvtMgr.getInstance(EventConst.loadRequests).notifyListeners();
				EvtMgr.getInstance(EventConst.updateStreamData).notifyListeners(notificationData);
				break;
			case PushNotiFicationType.Comment:
			case PushNotiFicationType.UserTag:
			case PushNotiFicationType.FollowUser:
			case PushNotiFicationType.AdminStream:
				EvtMgr.getInstance(EventConst.loadNotifications).notifyListeners();
				break;
			default:
				break;
		}
	}
}
