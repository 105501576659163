import {
	ActionType,
	cu, dbg, EvtMgr, LogMgr, ResponseCode, SessMgr, ThemeOptions, YesNoOptions,
} from "@credo/utilities";
import { SnackBarTypeOptions, RegEx } from "@credo/ui-components";
import {
	AnalyticEventsConst,
	AppUtils, Consts, EventConst,
} from "../../utils";
import { UserActions } from "../../services/actions/user";
import {
	CHANGE_EMAIL_PAGE,
	HOME_PAGE, REGISTER_CONFIRM, REGISTER_INTEREST, REGISTER_PAGE, SOCIAL_LOGIN_PAGE,
} from "../../routes/constants";
import { RouteMgr } from "./RouteMgr";
import { strings } from "../../i18n/config";
import { AuthModalContentType, SocialLoginType } from "../../utils/types";
import FirebaseMgr from "../../utils/FirebaseMgr";

export interface PhoneLoginRequestObject {
	user_id?: string | null;
	phone: string | null;
	verificationCode?: string | null;
	email?: string | null;
	password?: string | null;
	emailSource?: string | null;
	firebaseUid?: string | null;
	action?: string | null;
	new_phone?: string | null;
	cntry_code?: string | null;
	default_email?: string | null;
	qrCode?: string | null;
}

export interface PhoneLoginResponseObject {
	addr: "findOrCreateUser";
	info: string;
	msg_id: string;
	procTs: string;
	replyAddress: string;
	retcd: string;
	sendTs: string;
	sid: string;
	wrap_id: string;
	firstName: string;
	lastName: string;
	user_id: string;
	countUxpTags?: number;
}

export interface DeviceLocationObject {
	country: string;
	short_name: string;
	locality: string;
	sublocality: string;
}

/**
 * NOTE: We are using routes/privateRoute and routes/publicRoute to
 * navigate between logged in stack and public stack. What we can do
 * is, save the route in somewhere and then call that route in those
 * pages so that after route user will be directed to that route as
 * per condition. "verification_success_screen" must be sent to the
 * route state.
 * */

export function findOrCreateUser(
	request: PhoneLoginRequestObject,
	deviceLocation: DeviceLocationObject | null,
	sessInProgress: boolean = true,
) {
	SessMgr.setInSessionAndPersistSync({
		sessInProgress,
	});
	return new Promise<PhoneLoginResponseObject>((resolve, reject) => {
		const callBack = async (_p: null, response: PhoneLoginResponseObject) => {
			if (dbg) {
				LogMgr.mydbg("GOT MSG: RESP from svr for findOrCreateUser", response);
			}
			if (
				response.retcd && (response.retcd === ResponseCode.OK
					|| response.retcd === ResponseCode.SUCCESS || response.retcd.startsWith(ResponseCode.OK_WITH))
			) {
				let sessAuth = false;
				/**
				 * Set true if session is partially authenticated
				 * as in suppose user is verified, and we get the
				 * information that user has linked email with user's
				 * account, so user has to verify the email in order
				 * to login and complete the session authentication.
				 *
				 * Note: that if this is set as false does not means
				 * that session is not authenticated at all. please
				 * check sessAuth for full session authentication
				 *
				 * @see sessAuth
				 * */
				let sessInProgress = false;
				RouteMgr.setVerificationSuccessNavParams({
					...RouteMgr.getVerificationSuccessNavParams(),
					msg: response,
				});
				// this means either we found user profile in db or we just created a new one
				// in either way, the sid from thsi msg becames new sid which means now there is a session and user is also authenticated

				if (response.retcd === ResponseCode.OK_REQ_ELNA) {
					/**
					 * Since user has a linked email account with his/her account
					 * unit user verifies that, user is not fully authenticated.
					 * if user tries to send any other protected request, server
					 * will throw a REAU error code in response which means user
					 * needs to be re-authenticated. So we should first authenticate
					 * the user fully.
					 * asking crt user to decide if he wants to create new acct or login with alternative email because phone is taken
					 * */
					RouteMgr.setVerificationSuccessModalScreen(AuthModalContentType.PHONE_ALREADY_REG);
					sessInProgress = true;
					await SessMgr.setInSessionAndPersistSync({
						sessInProgress,
					});
				}

				if (response.retcd === ResponseCode.OK_REQ_CCNA) {
					// asking crt user to decide if he wants to create new acct with this phone he entered which is not currently in db
					RouteMgr.setVerificationSuccessModalScreen(AuthModalContentType.PHONE_NOT_YET_REG);
				} else if (response.retcd === ResponseCode.OK_EMUP || response.retcd === ResponseCode.OK_CEUP) {
					// dont need to update user_id and sid from server since we should have them already
					if (response.retcd === ResponseCode.OK_CEUP) {
						EvtMgr.getInstance(EventConst.showDefaultEmailModal).notifyListeners({ isSocialEmailSetToDefaultEmail: true });
					}
					sessAuth = true;
					// TODO: Handle Update user info
					// EvtMgr.getInstance(Consts.userInfo).addListener(CmHandlers.handleUpdate4userInfo);
					UserActions.retrieveInfoFromSvr();
					UserActions.sendSaveData();
				} else if (response.retcd === ResponseCode.OK_DELE) { // Get response when social connection has bees successfully deleted
					EvtMgr.getInstance(EventConst.removeSocialConnection).notifyListeners(response);
					sessAuth = true;
				} else if (response.retcd === ResponseCode.OK_LOGIN_QR) { // OK LOGIN VIA QRCODE
					if (response.firstName !== "null" && response.lastName !== "null") {
						AppUtils.toggleTheme(ThemeOptions.CREDO);
						RouteMgr.setVerificationSuccessScreen(HOME_PAGE);
					} else {
						// TODO: Change theme here to ego
						// let theming = cu.getGlobalVar('splash.theming');
						// theming.changeTheme(false);
						// cu.setGlobalVarsAndPersist(CmConst.switchVal, false);
						// cu.setGlobalVar('splash.theming', theming);
						RouteMgr.setVerificationSuccessScreen("ProfileEgoNameDescScreen");
					}
					// sets in global context in the global.objs.sess object and in db
					// Since user is able to access the settings page in the app we
					// can assume that user has successfully completed the registration
					await SessMgr.setInSessionAndPersistSync({
						sid: response.sid,
						user_id: response.user_id,
						firstName: response.firstName,
						lastName: response.lastName,
						countUxpTags: response.countUxpTags,
						sessAuth: true,
						regComplete: true,
						sessInProgress,
					});
					await UserActions.retrieveInfoFromSvr();
					// Save data is already been called right after QR scan, no need to call it here again

					// if anyone wants to know the session is now authenticated like sending contact list
					EvtMgr.getInstance(EventConst.sessAuth).notifyListeners({ isSessAuth: true });
					// MyNavigatorService.navigate_via_history_with_push("/networkRating");
				} else if (response.retcd === ResponseCode.OK_USR_SUSP) {
					// user is suspended
					sessAuth = false;
					RouteMgr.setVerificationSuccessScreen(AuthModalContentType.LOGIN);
					EvtMgr
						.getInstance(EventConst.logoutUser)
						.notifyListeners({
							avoidLogoutModal: true,
							avoidNotifySvr: true,
						});
					AppUtils.handleLoginModal(false);
					const userDataA = response.info.split(",");
					const reason = userDataA?.[0];
					const timeLeft = userDataA?.[1].split(":")[1];
					AppUtils.handleConfirmationModal({
						isVisible: true,
						onClose: () => AppUtils.handleConfirmationModal(null as any),
						cancel_button_function: () => AppUtils.handleConfirmationModal(null as any),
						ok_button_title: strings("AuthModal.suspended_user.ok"),
						ok_button_function: () => AppUtils.handleConfirmationModal(null as any),
						title: strings("AuthModal.suspended_user.title"),
						message: `${strings("AuthModal.suspended_user.suspension_reason")}: ${reason.trim()}`
							+ `\n${strings("AuthModal.suspended_user.suspension_time_left")}: ${timeLeft && parseInt(timeLeft, 10) === 0
								? strings("AuthModal.suspended_user.indefinitely") : cu.secondsToDhms(Number(timeLeft))}`,
						tx: null,
					});
				}
				if (response.retcd === ResponseCode.OK_FWD_URL) {
					AppUtils.showToast({
						message: strings(
							"AuthModal.error.redirect_url",
							{
								url: response.info,
							},
						),
						type: SnackBarTypeOptions.ERROR,
					});
					if (RegEx.url.test(response.info)) {
						window.open(
							response.info,
							"_blank",
						);
					}
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(`Received url from api findOrCreateUser: ${response.info} with code ${response.retcd}`);
					return;
					// eslint-disable-next-line no-else-return
				} else if (response.retcd === ResponseCode.OK_EMUP) {
					sessAuth = true;
					cu.setGlobalVar(Consts.feedCardsFetch, Consts.feedCardsFetchCount);
					RouteMgr.setVerificationSuccessScreen(CHANGE_EMAIL_PAGE);
					await UserActions.retrieveInfoFromSvr();
					const sessObj: any = {
						sid: response.sid,
						sessAuth: true,
						regComplete: true,
						sessInProgress: false,
					};
					if (request.action === ActionType.DELETE && request.emailSource === SocialLoginType.CUSTOM_EMAIL) {
						sessObj.verified_custom_email = "";
						AppUtils.showToast({
							message: strings("ChangeEmail.custom_email_removed_msg"),
							type: SnackBarTypeOptions.SUCCESS,
						});
					} else if (request.emailSource === SocialLoginType.CUSTOM_EMAIL) {
						sessObj.verified_custom_email = request.email || "";
						AppUtils.showToast({
							message: strings("ChangeEmail.custom_email_updated_msg"),
							type: SnackBarTypeOptions.SUCCESS,
						});
					}
					await SessMgr.setInSessionAndPersistSync(sessObj);
				} else {
					let regComplete = false;
					if (response.retcd === ResponseCode.OK_PHUP) {
						// OK_PHUP means User has successfully changed his phone number
						cu.setGlobalVar(Consts.feedCardsFetch, Consts.feedCardsFetchCount);
						RouteMgr.setVerificationSuccessScreen(HOME_PAGE);
						regComplete = true;
					} else if (
						response.retcd === ResponseCode.OK_NUCR || response.retcd === ResponseCode.OK_NUVU
						|| response.retcd === ResponseCode.OK_LOBP || response.retcd === ResponseCode.OK_EMFO
					) {
						// sets the flag that user will be rating the first time.
						cu.setGlobalVar(Consts.ratingFirstTime, true);
						// OK_NUVU means created from a vu user;
						// OK_LOBP means user logged by recently validated phone
						// eslint-disable-next-line eqeqeq
						if (
							cu.isSet(response?.firstName) && response?.firstName !== "null"
							&& cu.isSet(response?.lastName) && response?.lastName !== "null"
						) {
							if (cu.isSet(response?.countUxpTags) && response?.countUxpTags === 0) {
								RouteMgr.setVerificationSuccessModalScreen(AuthModalContentType.LOADING);
								RouteMgr.setDontAllowRouteChange(true);
								RouteMgr.setVerificationSuccessScreen(REGISTER_INTEREST);
								AppUtils.toggleTheme(ThemeOptions.EGO);
							} else {
								// setData2CacheAndGlobalVar(Consts.feedCardsFetchCount, Consts.feed_cards_fetch_count);
								cu.setGlobalVar(Consts.feedCardsFetch, Consts.feedCardsFetchCount);
								RouteMgr.setVerificationSuccessScreen(HOME_PAGE);
								AppUtils.toggleTheme(ThemeOptions.CREDO);
								regComplete = true;
							}
						} else if (
							cu.isSet(request?.email) && cu.isSet(request?.emailSource)
							&& cu.isSet(response?.firstName) && response?.firstName === "null"
							&& cu.isSet(response?.lastName) && response?.lastName === "null"
						) {
							if (cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess))) {
								localStorage.removeItem(Consts.emailRegInProcess);
							}
							RouteMgr.setVerificationSuccessModalScreen(AuthModalContentType.LOADING);
							RouteMgr.setDontAllowRouteChange(true);
							AppUtils.toggleTheme(ThemeOptions.EGO);
							RouteMgr.setVerificationSuccessScreen(REGISTER_PAGE);
						} else {
							// Removing "email registration is in progress" flag
							if (cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess))) {
								localStorage.removeItem(Consts.emailRegInProcess);
							}
							// Should swtich theme if user is NUCR or user with no tags
							cu.setGlobalVar(Consts.feedCardsFetch, 0);
							RouteMgr.setVerificationSuccessModalScreen(AuthModalContentType.LOADING);
							RouteMgr.setDontAllowRouteChange(true);
							RouteMgr.setVerificationSuccessScreen(REGISTER_CONFIRM);
						}
					}
					await SessMgr.setInSessionAndPersistSync({
						sid: response.sid,
						user_id: response.user_id,
						firstName: response.firstName,
						lastName: response.lastName,
						sessAuth: true,
						countUxpTags: response.countUxpTags,
						regComplete,
						sessInProgress,
					});
					sessAuth = true;
					// sets in global context in the global.objs.sess object and in db
					UserActions.sendSaveData();
					EvtMgr.getInstance(EventConst.sessAuth).notifyListeners(Consts.userId);
					// if anyone wants to know the session is now authenticated like sending contact list
				}

				// if (!isSet(getGlobalVar(Consts.switchVal))) {
				// 	await getValueOfSwitch();
				// }
				if (!sessAuth) {
					EvtMgr.getInstance(EventConst.loginSuccess).notifyListeners(false);
					// NavigatorService.navigate(verification_success_screen, verification_success_screen_navigation_params);
				} else {
					// When session is authenticated only then show suspense on homefeed.
					EvtMgr
						.getInstance(EventConst.showHomeSuspense)
						.notifyListeners(true);
					if (RouteMgr.getVerificationSuccessScreen() === "ProfileEgoNameDescScreen") {
						EvtMgr.getInstance(EventConst.loginSuccess).notifyListeners(false);
						// NavigatorService.navigate(verification_success_screen, verification_success_screen_navigation_params);
					} if (RouteMgr.getVerificationSuccessScreen() === HOME_PAGE) {
						EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
							name: AnalyticEventsConst.login,
						});
						EvtMgr.getInstance(EventConst.loginSuccess).notifyListeners(true);
					} else if (RouteMgr.getVerificationSuccessScreen() === SOCIAL_LOGIN_PAGE
					|| RouteMgr.getVerificationSuccessScreen() === CHANGE_EMAIL_PAGE) {
						EvtMgr.getInstance(EventConst.showHomeSuspense).notifyListeners(false);
					} else {
						// NavigatorService.navigate(verification_success_screen, verification_success_screen_navigation_params);
					}
				}

				if (RouteMgr.getVerificationSuccessModalScreen() !== AuthModalContentType.LOGIN) {
					EvtMgr.getInstance(EventConst.changeAuthModalType).notifyListeners(RouteMgr.getVerificationSuccessModalScreen());
				}

				resolve(response);
			} else {
				// Removing "email registration is in progress" flag
				if (cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess))) {
					localStorage.removeItem(Consts.emailRegInProcess);
				}
				switch (response.retcd) {
					case ResponseCode.ERR_EMNF:
						// AppUtils.showToast({
						// 	message: strings("AuthModal.error.email_not_found"),
						// 	type: SnackBarTypeOptions.ERROR,
						// });
						EvtMgr.getInstance(EventConst.handleValidationModal).notifyListeners(false);
						if (cu.getGlobalVar(Consts.socialAuthType) !== SocialLoginType.CUSTOM_EMAIL) {
							cu.setGlobalVar(Consts.socialRegInProcess, YesNoOptions.YES);
							EvtMgr.getInstance(EventConst.showLoginModal).notifyListeners(true);
							EvtMgr.getInstance(EventConst.changeAuthModalType).notifyListeners(AuthModalContentType.EMAIL_NOT_YET_REG);
						}
						await FirebaseMgr.signOut();
						break;
					case ResponseCode.ERR_MAIL:
						if (RouteMgr.getSocialLoginVerificationFailedScreen()) {
							// NavigatorService.checkSplashLoadingAndNavigate("Profile", {
							// 	screen: social_login_verification_failed_screen,
							// });
							RouteMgr.setSocialLoginVerificationFailedScreen(null);
						}
						AppUtils.showToast({
							message: strings("AuthModal.error.error_for_email_msg"),
							type: SnackBarTypeOptions.ERROR,
						});
						break;
					case ResponseCode.ERR_BADPUC:
						AppUtils.showToast({
							message: strings("AuthModal.error.email_associated_with_other_phone"),
							type: SnackBarTypeOptions.ERROR,
						});
						EvtMgr.getInstance(EventConst.showSocialLoading).notifyListeners(false);
						/**
						 * Use Case: After BADPUC comes up go to gmail login and click
						 * on back button on the browser user was being logged in to
						 * the account with the email with which user got the above error.
						 * So signing out any firebase account after any error is the best
						 * way to remove any logged account and un-expected login with wrong
						 * email.
						 * */
						await FirebaseMgr.signOut();
						break;
					case ResponseCode.ERR_INVS:
						AppUtils.showToast({
							message: response.info,
							type: SnackBarTypeOptions.ERROR,
						});
						if (cu.isYes(cu.getGlobalVar(Consts.isQRAuth))) {
							EvtMgr.getInstance(EventConst.resetQR).notifyListeners();
						}
						break;
					default:
					// showSnackbarMsg(strings("MsgMgr.report_item_error"), "error");
				}
				/**
				 * We need the error to be a string to based on which we will decide if we need to
				 * show the snackbar internally or not. So we will reject a string and display an
				 * error in the console.
				 * */
				// eslint-disable-next-line no-console
				console.error(`Received not ok from api findOrCreateUser: ${response.retcd}`);
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(`Received not ok from api findOrCreateUser: ${response.retcd}`);
			}
		};

		global.ebMgr.sendMsg("findOrCreateUser", "findOrCreateUser", request, callBack);
	});
}
