import React, { useState } from "react";
import {
	classNames,
	CredoBadge, FollowIcon, Modal, NotificationIcon, SvgIcon, ViewConnectionsIcon, XThinIcon,
} from "@credo/ui-components";
import { cu } from "@credo/utilities";
import { isMobile, isTablet } from "react-device-detect";
import { strings } from "../../../i18n/config";

interface EngageCommunityIconTutorialProps {
	isVisible: boolean;
	onClose: any;
}

export default function EngageCommunityIconTutorial({
	isVisible,
	onClose,
}: EngageCommunityIconTutorialProps) {
	const [upperIcons] = useState([
		{
			icon: ViewConnectionsIcon,
			title: strings("EngageCommunityIconTutorial.community_joined"),
			stroke: "var(--gray-dark)",
		},
		{
			icon: FollowIcon,
			title: strings("EngageCommunityIconTutorial.community_followed"),
			stroke: "var(--gray-dark)",
		},
		{
			icon: NotificationIcon,
			title: strings("EngageCommunityIconTutorial.join_req"),
			stroke: "var(--accent)",
		},
		{ icon: null, title: strings("EngageCommunityIconTutorial.pending_req"), showBadge: true },
	]);

	const onCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const getDialogueClasses = () => {
		if (isMobile && !isTablet) {
			return classNames(
				"bg-background-primary flex flex-col justify-center items-center max-w-full h-screen !rounded-none",
			);
		}
		return "bg-background-primary flex max-w-2xl md:h-[24rem] 2xs:h-[20rem]";
	};

	return (
		<Modal
			show={isVisible}
			overlayClass="pointer-events-none"
			dialogClassName={getDialogueClasses()}
			dialogWrapperClassName={classNames(
				"!bg-transparant !p-0 !m-0",
			)}
			dialogParentClassName={classNames(
				"!bg-transparant opacity-95",
			)}
			closeTitle={strings("AuthModal.close_title")}
			onClose={onCancel}
		>
			<div className={getDialogueClasses()}>
				<div className="flex flex-col p-4 min-h-[60%] mt-5">
					<div className="flex h-[30%] items-center flex-col justify-center">
						<p className="text-center px-5 text-gray-dark">
							{strings("EngageCommunityIconTutorial.tutorials_msg")}
						</p>
					</div>
					<div className="flex flex-row flex-1">
						{upperIcons.map((item) => (
							<div key={item?.title} className="flex flex-col px-1 flex-1">
								<div className="flex flex-1 flex-row items-center justify-center">
									{!cu.isSet(item.icon) && item.showBadge === true
										? (
											<CredoBadge badgeValue={3} />
										)
										: <SvgIcon icon={item.icon} stroke={item?.stroke || ""} />}
								</div>
								<div className="flex flex-1">
									<p className="text-center text-gray-dark">
										{item.title}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
}
