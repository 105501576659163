import React, { useMemo } from "react";
import { cu, dbg, LogMgr } from "@credo/utilities";

interface CurrentBoostProps {
	/**
	 * Boost level information of post.
	 * */
	totalBoost: {
		/**
		 * Total boost level which this post has in previous snap
		 * */
		prev: number,
		/**
		 * Total boost level which this post has at this time
		 * */
		current: number,
	}
}

// eslint-disable-next-line import/prefer-default-export
export const CurrentBoost: React.FC<CurrentBoostProps> = (props: CurrentBoostProps) => {
	const {
		totalBoost,
	} = props;

	const deltaBars = useMemo(() => {
		const proportion = (totalBoost.current / 7);
		const delta = totalBoost.current - totalBoost.prev;
		const deltaBarCount = delta !== 0 ? Math.floor(Math.max(1, Math.abs(delta / proportion))) : 0;
		const barColor = delta >= 0 ? "var(--tag-green)" : "var(--error)";

		if (dbg) {
			LogMgr.mydbg(
				"CurrentBoost delta data is ",
				{
					delta,
					deltaBarCount,
					proportion,
					actualCount: delta / proportion,
				},
			);
		}

		return {
			count: deltaBarCount,
			color: barColor,
		};
	}, [totalBoost]);

	const renderBars = () => (
		[5, 3, 1].map((item, index) => (
			<rect
				key={`${item}-${index}`}
				id="Rectangle"
				fill={deltaBars.count - index > 0 ? deltaBars.color : "#000000"}
				x="0"
				y={item}
				width="12"
				height="1"
				rx="0.5"
			/>
		))
	);

	return (
		<svg width="100%" viewBox="0 0 16 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<rect id="back" stroke="#212636" fill="#212636" x="0.5" y="0.5" width="15" height="23" rx="2" />
			<title>{totalBoost.current}</title>
			<g id="CB-Icon" transform="translate(2.000000, 1.000000)">
				<rect id="Rectangle" fill="#212636" x="0" y="0" width="12" height="13" />
				<rect id="Rectangle" fill="#0FF077" x="0" y="13" width="12" height="1" rx="0.5" fillOpacity="0.522372159" />
				<rect id="Rectangle" fill="#0FF077" x="0" y="11" width="12" height="1" rx="0.5" fillOpacity="0.522372159" />
				<rect id="Rectangle" fill="#0FF077" x="0" y="9" width="12" height="1" rx="0.5" fillOpacity="0.522372159" />
				<rect id="Rectangle" fill="#0FF077" x="0" y="7" width="12" height="1" rx="0.5" fillOpacity="0.522372159" />
				{renderBars()}
			</g>
			<text
				fontFamily="PTMono-Bold, PT Mono"
				fontSize={totalBoost.current > 10 ** 3 ? "4" : "7"}
				fontWeight="bold"
				letterSpacing="0.0033334"
				fill="#0FF077"
				dominantBaseline="middle"
				x="50%"
				y={20}
				textAnchor="middle"
			>
				{cu.formatNumberWithThousandsSep(totalBoost.current ?? 0)}
			</text>
		</svg>
	);
};
