import * as React from "react"
import { SVGProps } from "react"
import omit from "lodash/omit";

const CredoHelmet = (props: SVGProps<SVGSVGElement>) => {
	const newProps = omit(props, ["stroke", "fill"]);
	return (
		<svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...newProps}>
			<title>Credo</title>
			<defs>
				<radialGradient cx="61.5295534%" cy="50%" fx="61.5295534%" fy="50%" r="68.2862774%" gradientTransform="translate(0.615296,0.500000),rotate(1.543004),scale(1.000000,0.824370),translate(-0.615296,-0.500000)" id="credo-helmet-radialGradient-1">
					<stop stopColor="#01FF76" offset="0%"></stop>
					<stop stopColor="#0FEE86" offset="51.8193177%"></stop>
					<stop stopColor="#17A994" offset="73.4486801%"></stop>
					<stop stopColor="#179DA9" offset="82.1267511%"></stop>
					<stop stopColor="#5A6BE7" offset="100%"></stop>
					<stop stopColor="#4252C6" offset="100%"></stop>
				</radialGradient>
				<path d="M256.851052,14.5 C394.094148,14.5 497.5,118.638808 497.5,255.997951 L497.5,349.5 L256.851052,349.5 C228.076154,349.5 203.833263,338.853057 186.964022,319.802375 C171.754901,302.626491 163.633778,279.698233 163.633778,256 C163.633778,232.301767 171.754901,209.373509 186.964022,192.197625 C203.833263,173.146943 228.076154,162.5 256.851052,162.5 L388.151338,162.5 C398.920891,162.5 407.651338,171.230447 407.651338,182 C407.651338,192.769553 398.920891,201.5 388.151338,201.5 L256.851052,201.5 C239.11386,201.5 225.540747,207.461012 216.162171,218.052375 C207.481435,227.855658 202.633778,241.541983 202.633778,256 C202.633778,270.458017 207.481435,284.144342 216.162171,293.947625 C225.540747,304.538988 239.11386,310.5 256.851052,310.5 L458.5,310.5 L458.5,255.997951 C458.5,141.275305 374.204675,55.2352563 260.311367,53.5259276 L256.851052,53.5 C208.080184,53.5 157.444568,71.9901422 119.577064,104.903165 C77.4579676,141.511514 53.5,193.121257 53.5,256 C53.5,307.921791 72.5488153,358.482016 106.620752,395.79942 C143.188961,435.850876 194.608677,458.5 256,458.5 L446.456323,458.5 C448.033166,458.5 450.993987,456.820484 454.00582,453.360058 C455.602627,451.525416 456.948182,449.461548 457.823154,447.580611 C458.121864,446.938472 458.337317,446.377507 458.466656,445.94779 L458.5,445.827 L458.5,405.444679 C458.5,394.789696 467.045681,386.130664 477.656991,385.947635 L478,385.944679 C488.769553,385.944679 497.5,394.675126 497.5,405.444679 L497.5,446.436468 C497.5,457.146759 491.859442,469.272324 483.423788,478.964415 C473.633062,490.21341 460.787522,497.5 446.456323,497.5 L256,497.5 C183.657054,497.5 121.781849,470.245489 77.8196348,422.095725 C37.0935679,377.490363 14.5,317.521495 14.5,256 C14.5,181.729323 43.4252623,119.419139 93.9928415,75.4676842 C139.038175,36.3159591 198.781606,14.5 256.851052,14.5 Z" id="credo-helmet-path-2"></path>
				<filter x="-0.5%" y="-0.5%" width="101.0%" height="101.0%" filterUnits="objectBoundingBox" id="credo-helmet-filter-3">
					<feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
					<feOffset dx="2" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
					<feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
					<feColorMatrix values="0 0 0 0 0.087023628   0 0 0 0 0.611097204   0 0 0 0 1  0 0 0 0.629807692 0" type="matrix" in="shadowInnerInner1" result="shadowMatrixInner1"></feColorMatrix>
					<feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner2"></feGaussianBlur>
					<feOffset dx="-1" dy="-2" in="shadowBlurInner2" result="shadowOffsetInner2"></feOffset>
					<feComposite in="shadowOffsetInner2" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner2"></feComposite>
					<feColorMatrix values="0 0 0 0 0.0588235294   0 0 0 0 0.941176471   0 0 0 0 0.466666667  0 0 0 1 0" type="matrix" in="shadowInnerInner2" result="shadowMatrixInner2"></feColorMatrix>
					<feMerge>
						<feMergeNode in="shadowMatrixInner1"></feMergeNode>
						<feMergeNode in="shadowMatrixInner2"></feMergeNode>
					</feMerge>
				</filter>
			</defs>
			<g id="credo-helmet-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="credo-helmet-Logo---Master">
					<path d="M245.76,512 C242.834859,512 239.964808,511.764134 237.167828,511.310382 C104.574378,501.675437 0,391.050046 0,256 C0,114.615104 114.615104,0 256,0 C391.050046,0 501.675437,104.574378 511.317615,237.164577 C511.764134,239.964808 512,242.834859 512,245.76 L512,458.752 C512,488.160058 488.160058,512 458.752,512 L245.76,512 Z" id="credo-helmet-Path" fill="#212636"></path>
					<g id="credo-helmet-Path" fillRule="nonzero">
						<use fill="url(#credo-helmet-radialGradient-1)" xlinkHref="#credo-helmet-path-2"></use>
						<use fill="black" fillOpacity="1" filter="url(#credo-helmet-filter-3)" xlinkHref="#credo-helmet-path-2"></use>
					</g>
				</g>
			</g>
		</svg>

	)
}

export default CredoHelmet;
