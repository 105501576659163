import {
	AppMode, GenericResponse, ResponseCode, YesNoOptions,
} from "@credo/utilities";

export interface GenericBoostResponse<ItemsType> extends GenericResponse<ItemsType>{
	boardAccessCost: number,
	boardVersion: number,
	boardTs: number,
	boardSnapIntervalSecs: number,
	currentTs: number;
	retcd: ResponseCode,
	txStatus: TXNStatus,
}

export interface BoostRankFeedRequest {
	filterByTags: string[],
	user_id: string,
	mode: AppMode,
	fetchSize: number,
	summary: YesNoOptions,
	APIVersion?: string,
}

export interface PostBoostInfoRequest {
	user_id: string;
	postUuid: string;
	tag: string;
}

export enum TXNStatus {
	INSUF_BALANCE = "insufficient_balance",
	SUCCESS = "success",
	INIT = "init",
	COMPLETED = "completed",
}

/**
 * Wallet summary
 * */

export interface WalletSummaryRequest {
	user_id: string;
}

export interface BalanceHistoryItem {
	date: string,
	balance: number,
}

export interface WalletSummary {
	boost_value_history: BalanceHistoryItem[];
	wallet_balance_history: BalanceHistoryItem[];
}

/**
 * Wallet History
 * */

export interface WalletHistoryRequest {
	user_id: string;
	lastTransactionId: number,
	fetchSize: number
}

export enum VoltsHistoryItemType {
	BOOST_POST = "BOOST_POST",
	CAMPAIGN_REWARD = "CAMPAIGN_REWARD",
	CONSUME_BOARD = "CONSUME_BOARD",
	WITHDRAW_VIEWERSHIP_INCOME = "WITHDRAW_VIEWERSHIP_INCOME",
	WITHDRAW_BOOST = "WITHDRAW_BOOST",
	WITHDRAW_LIGHTNING = "WITHDRAW_LIGHTNING",
	DEPOSIT_LIGHTNING = "DEPOSIT_LIGHTNING",
}

export interface WalletHistory {
	transactionId: number,
	type: VoltsHistoryItemType,
	createTime: number,
	description?: string,
	tag: string,
	value: number
	extraMeta: string | null;
}

/**
 * Boost History
 * */

export interface VoltsBoostHistoryRequest {
	user_id: string;
	lastTs: number,
}

export interface BoostHistory {
	"tag": string,
	"postUuid": string,
	"userBoost": number,
	"postPnl": number,
	"postTitle": string,
	"rank": number
	lastTs: number
}
