import React from "react";
import { StreamNoNetworkIcon } from "@credo/ui-components";
import { strings } from "../../../i18n/config";

// eslint-disable-next-line import/prefer-default-export
export const NoConnection: React.FC = () => (
	<div className="w-full flex flex-col justify-center items-center py-10">
		<StreamNoNetworkIcon />
		<span className="w-40 py-5 text-sm text-support text-gray-dark text-center">
			{strings("StreamProfile.no_internet_msg")}
		</span>
	</div>
);
