import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import { PROFILE_PAGE, SETTINGS_PAGE, SUPPORT_PAGE } from "./constants";
import { isSessionAuthAtom } from "../utils/atoms";

/**
 * Navigate to support page with text when user is not loggedin
 * if user is logged in and tries to access /support take user
 * to /settings/support
 * */
function PrivateSupportRoute() {
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	if (isSessionAuth) {
		return <Navigate to={`${PROFILE_PAGE}${SETTINGS_PAGE}${SUPPORT_PAGE}`} replace />;
	}
	return <Outlet />;
}

export default PrivateSupportRoute;
