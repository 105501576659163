import {
	BellIcon,
	ConnectIcon, DeleteIcon, DevIcon, FlagIcon,
	LanguageIcon,
	LockIcon, LogoutIcon,
	MEmailIcon,
	TelephoneIcon,
	TimezoneIcon,
	VerificationIcon,
} from "@credo/ui-components";
import { EvtMgr } from "@credo/utilities";
import { strings } from "../../i18n/config";
import {
	CHANGE_EMAIL_PAGE,
	CHANGE_PHONE_PAGE,
	DEV_PAGE, LANGUAGE_PAGE, PRIVACY_SETTINGS_PAGE, REPORT_A_PROBLEM_PAGE,
	DELETE_ACCOUNT, SETTINGS_PAGE, SOCIAL_LOGIN_PAGE, STREAM_PROFILE_PAGE, SUPPORT_PAGE, PROFILE_PAGE,
} from "../../routes/constants";
import { AnalyticEventsConst, Consts, EventConst } from "../../utils";

export interface MenuItem {
	id: number,
	icon: any,
	title: string,
	appOnly?: boolean,
	url?: string,
	onClick?: () => void,
	devOnly?: boolean,
	isExternal?: boolean;
}

const handleLogout = () => {
	EvtMgr.getInstance(EventConst.logoutUser).notifyListeners();
	EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
		name: AnalyticEventsConst.settingLogout,
	});
};

export const menuItems: MenuItem[] = [
	{
		id: 1,
		icon: TelephoneIcon,
		title: strings("SettingsPage.change_phone_number"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${CHANGE_PHONE_PAGE}`,
	},
	{
		id: 2,
		icon: MEmailIcon,
		title: strings("SettingsPage.email"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${CHANGE_EMAIL_PAGE}`,
	},
	{
		id: 3,
		icon: ConnectIcon,
		title: strings("SettingsPage.social_connections"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${SOCIAL_LOGIN_PAGE}`,
	},
	{
		id: 4,
		icon: LanguageIcon,
		title: strings("SettingsPage.language"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${LANGUAGE_PAGE}`,
	},
	{
		id: 5,
		icon: TimezoneIcon,
		title: strings("SettingsPage.timezone"),
		appOnly: true,
	},
	{
		id: 6,
		icon: LockIcon,
		title: strings("SettingsPage.privacy_settings"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${PRIVACY_SETTINGS_PAGE}`,
	},
	{
		id: 7,
		icon: VerificationIcon,
		title: strings("SettingsPage.request_verification"),
		appOnly: true,
	},
	{
		id: 8,
		icon: BellIcon,
		title: strings("SettingsPage.notifications"),
		appOnly: true,
	},
	// {
	// 	id: 9,
	// 	icon: BellIcon,
	// 	title: strings("SettingsPage.notifications"),
	// 	appOnly: true,
	// },
	{
		id: 10,
		icon: FlagIcon,
		title: strings("SettingsPage.support"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${SUPPORT_PAGE}`,
	},
	{
		id: 11,
		icon: DevIcon,
		title: strings("SettingsPage.dev_page"),
		appOnly: false,
		url: DEV_PAGE,
		devOnly: true,
	},
	{
		id: 12,
		icon: DeleteIcon,
		title: strings("SettingsPage.delete_account"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${DELETE_ACCOUNT}`,
	},
	{
		id: 13,
		icon: LogoutIcon,
		title: strings("SettingsPage.logout"),
		appOnly: false,
		onClick: handleLogout,
	},
];

export const supportMenu = [
	{
		id: 1,
		icon: FlagIcon,
		title: strings("SettingsSupportPage.discord"),
		appOnly: false,
		url: Consts.discord_link,
		isExternal: true,
	},
	{
		id: 2,
		icon: DevIcon,
		title: strings("SettingsSupportPage.credo"),
		appOnly: false,
		url: `${STREAM_PROFILE_PAGE}/credo`,
	},
	{
		id: 3,
		icon: DevIcon,
		title: strings("SettingsSupportPage.report_a_problem"),
		appOnly: false,
		url: `${PROFILE_PAGE}${SETTINGS_PAGE}${REPORT_A_PROBLEM_PAGE}`,
	},
];
