import React, { useState } from "react";
import { useDebounce } from "react-use";
import { RadioGroup } from "@headlessui/react";
import {
	ButtonSize, InputField, RadioButtonEmptyIcon, RadioButtonSelectedIcon, PrimaryButton,
} from "@credo/ui-components";
import { ReportListItem } from "../../utils/types";
import { strings } from "../../i18n/config";

export interface ReportProps {
	radioProps: any;
	onSubmit: (reportReason: string, reportMessage: string) => void;
}

const Report = (props: ReportProps) => {
	const {
		radioProps,
		onSubmit,
	} = props;
	const [reportReason, setReportReason] = useState("");
	const [reportMessage, setReportMessage] = useState("");
	const [isReportSelected, setIsReportSelected] = useState(false);

	const checkValues = () => {
		if (!reportReason) {
			setIsReportSelected(false);
			return;
		} if (reportReason === "other") {
			setIsReportSelected(!!reportMessage);
			return;
		}
		setIsReportSelected(true);
	};

	useDebounce(() => {
		checkValues();
	}, 200, [reportReason, reportMessage]);

	const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReportMessage(event?.target.value);
	};

	const submitReport = () => {
		onSubmit(reportReason, reportMessage);
	};

	const renderOtherInput = () => {
		if (reportReason === "other") {
			return (
				<InputField
					value={reportMessage}
					wrapperClassName="mt-4 mx-6 h-28 max-h-[112px] overflow-auto"
					onChange={onMessageChange}
					placeholder={strings("ReportPostScreen.message")}
					isMultiline
				/>
			);
		}
		return null;
	};

	return (
		<div className="flex flex-col px-2">
			<RadioGroup value={reportReason} onChange={setReportReason}>
				{radioProps.map((item: ReportListItem) => (
					<RadioGroup.Option key={item.value} value={item.value}>
						{({ checked }) => (
							<div>
								<div
									className="flex flex-row py-2.5 items-center hover:cursor-pointer"
								>
									<div className="w-10 h-6">
										{checked ? <RadioButtonSelectedIcon /> : <RadioButtonEmptyIcon />}
									</div>
									<div className="w-full">
										<p className="text-sm font-medium text-title-color">{item.label}</p>
									</div>
								</div>
								<div style={{ height: 1 }} className="bg-post-bar-border ml-10" />
							</div>
						)}
					</RadioGroup.Option>
				))}
			</RadioGroup>
			{renderOtherInput()}
			<div className="flex items-end justify-end mt-4">
				<PrimaryButton
					size={ButtonSize.MEDIUM}
					label={strings("ReportPostScreen.submit")}
					disabled={!isReportSelected}
					handleClick={submitReport}
				/>
			</div>
		</div>
	);
};

export default Report;
