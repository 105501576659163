import { useMemo } from "react";
import { PermissionActions } from "@credo/utilities";
import { useAtomValue } from "jotai";
import { Feature, FeatureMetaForPerm, useAuthManager } from "../index";
import { isSessionAuthAtom } from "../atoms";

// eslint-disable-next-line import/prefer-default-export
export const useTopBoardPermission = (): { isTopBoardAllowed: boolean, permInitialised: boolean } => {
	const isSessionAuth = useAtomValue(isSessionAuthAtom);
	const { authManager, initialised: permInitialised } = useAuthManager();

	const isTopBoardAllowed = useMemo(() => (isSessionAuth ? authManager.isActionAllowed(
		PermissionActions.ACCESS,
		new Feature(FeatureMetaForPerm.boostBoard.id, FeatureMetaForPerm.boostBoard.name),
	) : false), [authManager, isSessionAuth]);

	return { isTopBoardAllowed, permInitialised };
};
