import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getApp, initializeApp } from "firebase/app";
import {
	ActionCodeSettings,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	isSignInWithEmailLink,
	OAuthProvider, onAuthStateChanged,
	sendSignInLinkToEmail,
	signInWithEmailLink,
	signInWithRedirect,
	signOut,
	TwitterAuthProvider,
	UserCredential,
} from "firebase/auth";
import {
	ActionType,
	cu, dbg, EvtMgr, LogMgr, SessMgr, YesNoOptions,
} from "@credo/utilities";
import {
	deleteToken, getMessaging, getToken, onMessage,
} from "firebase/messaging";
import isNil from "lodash/isNil";
import { SnackBarTypeOptions } from "@credo/ui-components";
import {
	getDownloadURL, getMetadata, getStorage, ref, uploadBytes,
} from "firebase/storage";
import CfgMgr from "../config/CfgMgr";
import PushNotificationsMgr from "./PushNotificationsMgr";
import { AnalyticEventsConst, Consts, EventConst } from "./Consts";
import { findOrCreateUser } from "../modules/auth/requests";
import { AppUtils } from "./index";
import { strings } from "../i18n/config";
import { DEFAULT_COUNTRY_CODE, DEV_MODE } from "../config/constants";
import { SocialLoginType } from "./types";

interface CredoFirebaseUser extends UserCredential {
	additionalUserInfo?: {
		isNewUser?: boolean
	},
}

const firebaseConfig = {
	apiKey: CfgMgr.cfg_firebase_js_sdk_apiKey,
	authDomain: CfgMgr.cfg_firebase_js_sdk_authDomain,
	databaseURL: CfgMgr.cfg_firebase_js_sdk_databaseURL,
	projectId: CfgMgr.cfg_firebase_js_sdk_projectId,
	storageBucket: CfgMgr.cfg_firebase_js_sdk_storageBucket,
	messagingSenderId: CfgMgr.cfg_firebase_js_sdk_messagingSenderId,
	appId: CfgMgr.cfg_firebase_js_sdk_appId,
	measurementId: CfgMgr.cfg_firebase_js_sdk_measurementId,
};

export default class FirebaseMgr {
	static app: any = null;

	credoApp: any = null;

	appCheck: any = null;

	messaging: any = null;

	token: string = "";

	// @ts-ignore
	actionCodeSettings: ActionCodeSettings = CfgMgr.FIREBASE_ACTION_SETTINGS;

	init() {
		if (dbg) LogMgr.mydbg(this, "Initialising firebase now");
		try {
			FirebaseMgr.app = initializeApp(firebaseConfig);
			this.messaging = getMessaging(FirebaseMgr.app);
			// Foreground PN
			onMessage(this.messaging, (payload: any) => {
				if (dbg) LogMgr.mydbg("Foreground notification", payload);
				const notificationData = payload.notification;
				if (notificationData?.title) {
					PushNotificationsMgr.performActionOnPNReceive(payload);
					PushNotificationsMgr.showPushNotification(notificationData.title, notificationData, payload);
				}
			});

			if (dbg) LogMgr.mydbg(this, "Firebase initialised:", FirebaseMgr.app);

			if (dbg) LogMgr.mydbg(this, "Initialising app check now");

			this.credoApp = getApp();

			// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
			// key is the counterpart to the secret key you set in the Firebase console.

			// Debug tokens allow development and testing environments to pass App Check verification.
			if (DEV_MODE) {
				this.credoApp.FIREBASE_APPCHECK_DEBUG_TOKEN = CfgMgr.FIREBASE_APPCHECK_DEBUG_TOKEN;
			}
			this.appCheck = initializeAppCheck(FirebaseMgr.app, {
				provider: new ReCaptchaV3Provider(CfgMgr.cfg_firebase_app_check_site_key),

				// Optional argument. If true, the SDK automatically refreshes App Check
				// tokens as needed.
				isTokenAutoRefreshEnabled: true,
			});
			if (dbg) LogMgr.mydbg(this, "App check initialised:", this.appCheck);
			this.generateTokenForPN();
		} catch (error) {
			LogMgr.myerr(this, "init: Error while initializing firebase", error);
		}
	}

	async generateTokenForPN() {
		try {
			const currentToken = await getToken(this.messaging, { vapidKey: CfgMgr.cfg_device_token_generation_key });
			if (currentToken) {
				this.token = currentToken;
				EvtMgr.getInstance(EventConst.saveDeviceToken).notifyListeners(currentToken);
				if (dbg) LogMgr.mydbg("PN Token generated:", currentToken);
			} else if (dbg) LogMgr.mydbg("No registration token available. Request permission to generate one.");
		} catch (err) {
			if (dbg) LogMgr.mydbg("An error occurred while retrieving token. ", err);
		}
		return this.token;
	}

	getToken() {
		return this.token;
	}

	async deleteCurrentToken() {
		await deleteToken(this.messaging);
	}

	static buildSourceUrlImage(bucketRelativeRefPath: string) {
		if (isNil(bucketRelativeRefPath) || typeof bucketRelativeRefPath !== "string") {
			return null;
		}
		const bucketRelativeRefPathURL = bucketRelativeRefPath.replace(/\//g, "%2F");
		return `${CfgMgr.cfg_imageStorage_path + bucketRelativeRefPathURL}?alt=media`;
	}

	/**
	 * Sends email for verification and validation link for the user, which will
	 * open the app and validate the email account. Once the user clicks on the
	 * link, the user is redirected to /signInViaEmail link and the other handler
	 * function executes.
	 *
	 * @see handleSignInViaEmail
	 *
	 * @param {string} email - Email where the link needs to be sent.
	 * @param {() => void} onSuccessCallback - Callback to be executed when email
	 * is sent successfully
	 * @param {() => void} onErrorCallback - Callback to be executed when there
	 * is an error while sending the link over the email.
	 * */
	async doSendSignInLinkToEmail(
		email: string,
		onSuccessCallback?: () => void,
		onErrorCallback?: () => void,
		isSessionAuthenticated: boolean = false,
	) {
		cu.setGlobalVar(Consts.email4SignInLinkToEmail, email);
		const auth = getAuth();
		if (auth) {
			sendSignInLinkToEmail(auth, email, this.actionCodeSettings)
				.then(() => {
					if (dbg) LogMgr.mydbg(this, "a validation link has been sent to the email user provided");
					cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.CUSTOM_EMAIL);
					AppUtils.showToast({
						message: strings("AuthModal.success.email_sent", { email }),
						type: SnackBarTypeOptions.SUCCESS,
					});
					if (onSuccessCallback) {
						onSuccessCallback();
					}
				})
				.catch((error: any) => {
					cu.setGlobalVar(Consts.email4SignInLinkToEmail, null);
					if (cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess))) {
						localStorage.removeItem(Consts.emailRegInProcess);
					}
					if (dbg) LogMgr.mydbg(this, "Error while sending validation link by email", error);
					AppUtils.showToast({
						message: strings("AuthModal.error.send_error"),
						type: SnackBarTypeOptions.ERROR,
					});
					if (onErrorCallback) {
						onErrorCallback();
					}
				});
			if (!isSessionAuthenticated) {
				await SessMgr.setInSessionAndPersistSync({
					sessInProgress: false,
					sessAuth: false,
				});
			}
		} else if (dbg) LogMgr.mydbg(this, "Firebase app not initialized");
	}

	/**
	 * Handles the email validation process when user clicks on the link
	 * sent over their email.
	 * */
	async handleSignInViaEmail(url: any) {
		const auth = getAuth();
		if (this.credoApp) {
			// Firebase is not sending the mode=signIn so need to hardcode
			if (isSignInWithEmailLink(auth, `${url}&mode=signIn`)) {
				// Additional state parameters can also be passed via URL.
				// This can be used to continue the user's intended action before triggering
				// the sign-in operation.
				// Get the email if available. This should be available if the user completes
				// the flow on the same device where they started it.
				const email = cu.getGlobalVar(Consts.email4SignInLinkToEmail);
				if (!email) {
					// User opened the link on a different device. To prevent session fixation
					// attacks, ask the user to provide the associated email again. For example:
					if (dbg) LogMgr.mydbg(this, "Email not found in storage while validating the email");
					EvtMgr.getInstance(EventConst.handleValidationModal).notifyListeners(false);
					EvtMgr.getInstance(EventConst.showLoginModal).notifyListeners(true);
					AppUtils.showToast({
						message: strings("AuthModal.error.email_not_found_storage"),
						type: SnackBarTypeOptions.ERROR,
					});
				} else {
					// The client SDK will parse the code from the link for you.
					if (dbg) LogMgr.mydbg(this, "Email found, validating using firebase", email);
					// Firebase is not sending the mode=signIn so need to hardcode
					signInWithEmailLink(auth, email, `${url}&mode=signIn`)
						.then((result: CredoFirebaseUser) => {
							// Clear email from storage.
							// window.localStorage.removeItem(Consts.email4SignInLinkToEmail);
							// You can access the new user via result.user
							// Additional user info profile not available via:
							// result.additionalUserInfo.profile == null
							// You can check if the user is new or existing:
							// result.additionalUserInfo.isNewUser
							if (dbg) {
								LogMgr.mydbg(
									this,
									"firebase: SUCCESS, email validated and user now signed in via this email; user is : "
									+ `${result?.user}; is new user?: ${result?.additionalUserInfo?.isNewUser};`
									+ `firebaseUserCredential is: ${result}`,
								);
							}
							let firebaseUid = null;

							try {
								firebaseUid = result?.user.uid;
							} catch (e) {
								LogMgr.myerr("cannot retrieve firebaseUid from response");
							}
							const customEmailDetails = cu.getGlobalVar(Consts.customEmailDetails);
							if (!cu.isSet(customEmailDetails) || customEmailDetails?.emailSource !== SocialLoginType.CUSTOM_EMAIL) {
								const requestObject = {
									user_id: SessMgr.getFromSession(Consts.user_id),
									phone: null,
									verificationCode: null,
									email,
									password: null,
									emailSource: SocialLoginType.CUSTOM_EMAIL,
									firebaseUid,
									action: cu.isYes(cu.getGlobalVar(Consts.emailRegInProcess)) ? ActionType.CREATE : null,
									new_phone: null,
									cntry_code: cu.getGlobalVar(Consts.clientCountryCode) || DEFAULT_COUNTRY_CODE,
									default_email:
										cu.isSet(cu.getGlobalVar(Consts.email4SignInLinkToEmail)) ? YesNoOptions.NO : YesNoOptions.YES,
								};
								findOrCreateUser(requestObject, null, !SessMgr.isSessionAuth());
							} else {
								cu.setGlobalVar(Consts.customEmailDetails, {
									email,
									emailSource: SocialLoginType.CUSTOM_EMAIL,
									firebaseUid,
								});
								EvtMgr.getInstance(EventConst.showEmailRegisterModal).notifyListeners(email);
							}
							EvtMgr.getInstance(EventConst.logAnalyticsEvent).notifyListeners({
								name: AnalyticEventsConst.signInViaEmailLinkLogin,
							});
						})
						.catch((error) => {
							// Some error occurred, you can inspect the code: error.code
							// Common errors could be invalid email and invalid or expired OTPs.
							if (dbg) LogMgr.mydbg(this, "Error while validating the email", error);
							AppUtils.showToast({
								message: strings("AuthModal.error.email_login_error"),
								type: SnackBarTypeOptions.ERROR,
							});
							EvtMgr.getInstance(EventConst.handleValidationModal).notifyListeners(false);
							EvtMgr.getInstance(EventConst.showLoginModal).notifyListeners(true);
						});
				}
			} else {
				if (dbg) LogMgr.mydbg(this, "Error while validating sign in link with isSignInWithEmailLink");
				AppUtils.showToast({
					message: strings("AuthModal.error.email_while_validating"),
					type: SnackBarTypeOptions.ERROR,
				});
				EvtMgr.getInstance(EventConst.showLoginModal).notifyListeners(true);
				EvtMgr.getInstance(EventConst.handleValidationModal).notifyListeners(false);
			} // isSignInWithEmailLink(auth, window.location.href)
		} else if (dbg) {
			if (dbg) LogMgr.mydbg("Firebase app not initialized");
		}
	}

	/**
	 * Generic OAuth login function which can be used by passing the required
	 * parameters as provider and provider name to handle the login using
	 * firebase. Using this function we have created multiple logins like,
	 * doTwitterLogin, doLoginWithGoogle, which will redirect to the required
	 * social login page and user can Log in from there, once the login is complete
	 * user is redirected back to our app. Once user is redirected to our app
	 * we capture the response using getRedirectResponse.
	 *
	 * @see doTwitterLogin, doLoginWithGoogle, doLoginWithFacebook, doLoginWithApple, getRedirectResponse
	 *
	 * @param {any} provider - OauthProvider which should be passed to redirect to
	 * the required Social sign in option using firebase.
	 *
	 * */
	// eslint-disable-next-line class-methods-use-this
	async OAuthWithRedirect(provider: any) {
		// Should we call the auth only once in the class and use that
		// instead of calling it again and again?
		const auth = getAuth();
		const isAddingSocialLogin = cu.getGlobalVar("IsAddingSocialLogin");
		if (!isAddingSocialLogin) {
			await SessMgr.setInSessionAndPersistSync({
				sessInProgress: true,
			});
		}
		return signInWithRedirect(auth, provider);
	}

	async getRedirectResponse(onSuccessCallback: () => void) {
		const auth = getAuth();
		const authName = cu.getGlobalVar(Consts.socialAuthType);
		const isAddingSocialLogin = cu.getGlobalVar("IsAddingSocialLogin");
		onAuthStateChanged(auth, async (user) => {
			if (dbg) {
				LogMgr.mydbg(
					`onAuthStateChanged Firebase Login user ${cu.getGlobalVar(Consts.socialAuthType)}:`,
					user,
				);
			}

			if (user && authName && authName !== SocialLoginType.CUSTOM_EMAIL) {
				if (dbg) LogMgr.mydbg(this, `${authName?.toUpperCase()} Firebase Login SUCCESS: Result of the authentication`, { user });
				let email = null;
				let firebaseUid = null;
				try {
					email = user.providerData[0].email;
					firebaseUid = user.uid;
					if (email && firebaseUid) {
						EvtMgr.getInstance(EventConst.setSocialEmailCred).notifyListeners({
							email,
							firebaseUUID: firebaseUid,
						});
					}
				} catch (e) {
					if (dbg) LogMgr.myerr(`${authName} Firebase Login: cannot retrieve email or uid from response`);
				}

				if (cu.isSet(email)) {
					const requestObject = {
						user_id: SessMgr.getFromSession(Consts.user_id),
						phone: SessMgr.getFromSession(Consts.phone) || null,
						verificationCode: null,
						email,
						password: null,
						emailSource: authName,
						firebaseUid,
						action: null,
						new_phone: null,
						cntry_code: cu.getGlobalVar(Consts.clientCountryCode) || DEFAULT_COUNTRY_CODE,
						default_email: YesNoOptions.NO,
					};
					await findOrCreateUser(requestObject, null, !isAddingSocialLogin);
				} else if (dbg) LogMgr.mydbg(this, `${authName.toUpperCase()} Firebase Login Error: Email not found`, { user });
			}
			if (onSuccessCallback) {
				onSuccessCallback();
			}
		});
		cu.setGlobalVar("IsAddingSocialLogin", null);
	}

	/**
	 * Redirects to Twitter for login using firebase OAuth
	 *
	 * @see getRedirectResponse
	 * @see OAuthWithRedirect
	 * */
	// eslint-disable-next-line class-methods-use-this
	async doTwitterLogin() {
		const provider = new TwitterAuthProvider();
		provider.addScope("email");
		cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.TWITTER);
		await this.OAuthWithRedirect(provider);
	}

	/**
	 * Redirects to Google for login using firebase OAuth
	 *
	 * @see getRedirectResponse
	 * @see OAuthWithRedirect
	 * */
	// eslint-disable-next-line class-methods-use-this
	async doLoginWithGoogle() {
		const provider = new GoogleAuthProvider();
		provider.addScope("email");
		cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.GOOGLE);
		await this.OAuthWithRedirect(provider);
	}

	/**
	 * Redirects to Facebook for login using firebase OAuth
	 *
	 * @see getRedirectResponse
	 * @see OAuthWithRedirect
	 * */
	// eslint-disable-next-line class-methods-use-this
	async doLoginWithFacebook() {
		const provider = new FacebookAuthProvider();
		provider.addScope("email");
		cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.FACEBOOK);
		await this.OAuthWithRedirect(provider);
	}

	/**
	 * Redirects to Apple for login using firebase OAuth
	 *
	 * @see getRedirectResponse
	 * @see OAuthWithRedirect
	 * */
	// eslint-disable-next-line class-methods-use-this
	async doLoginWithApple() {
		const provider = new OAuthProvider("apple.com");
		provider.addScope("email");
		provider.addScope("name");
		cu.setGlobalVar(Consts.socialAuthType, SocialLoginType.APPLE);
		await this.OAuthWithRedirect(provider);
	}

	static async signOut() {
		const auth = getAuth();
		if (dbg) LogMgr.mydbg("Firebase Signing out");
		await signOut(auth);
	}

	static async createShortURLLink(args: any) {
		const {
			linkUrl,
			socialDescr,
			socialImageUrl,
			socialTitle,
			paramObj,
			urlObjName,
			ofl,
		} = args;

		const appDomain = CfgMgr.cfg_dynamicLinkDomain1;
		const deeplinkUrl = CfgMgr.cfg_dynamicLinkUrl;

		let params = "";
		if (cu.isSet(paramObj)) {
			Object.entries(paramObj).map((item) => {
				params += `${item[0]}=${item[1]}&`;
				return null;
			});
		}

		let generatedShortLinkUrl = null;

		const link = `https://${deeplinkUrl}/${urlObjName}?${params}`;
		let otherFallbackUrl = "";

		// isi does not work on dev, so it will just work on prod
		const isi = dbg ? "" : `&isi=${CfgMgr.cfg_app_store_id}`;

		let otherParams = `&apn=${CfgMgr.cfg_androidConfig_packageName}&ibi=${CfgMgr.cfg_iosConfig_bundleId}${isi}`;

		if (linkUrl) {
			// fallback url for android, ios and web
			otherParams += `&ofl=${linkUrl}&afl=${linkUrl}&ifl=${linkUrl}&ipfl=${linkUrl}`;
		}
		// optional other fallback link which we can conditionally add
		if (ofl) {
			otherFallbackUrl += `&ofl=${ofl}`;
		}
		if (socialDescr) {
			// description for link preview
			otherParams += `&sd=${socialDescr}`;
		}
		if (socialImageUrl) {
			// image for link preview
			otherParams += `&si=${socialImageUrl}`;
		}
		if (socialTitle) {
			// title for link preview
			otherParams += `&st=${socialTitle}`;
		}

		/**
		 * efr=1 avoids the preview.page.link to be shown on the phone when
		 * link is opened on the device/sim
		 * */
		try {
			const request = JSON.stringify({
				longDynamicLink: `https://${appDomain}/?link=${link}${otherParams}${otherFallbackUrl}&efr=1`,
				suffix: {
					option: "SHORT",
				},
			});

			// for reference https://firebase.google.com/support/guides/url-shortener
			await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${CfgMgr.cfg_firebase_js_sdk_apiKey}`, {
				method: "post",
				headers: { "Content-Type": "application/json" },
				body: request,
			}).then((response) => response.json())
				.then((responseJson) => {
					if (responseJson && responseJson?.shortLink) {
						generatedShortLinkUrl = responseJson?.shortLink;
					} else {
						if (dbg) LogMgr.mydbg(this, "while creating dynamic link got error in the response", responseJson);
						AppUtils.showToast({ message: strings("AppUtils.please_try_again"), type: SnackBarTypeOptions.ERROR });
					}
				})
				.catch((error) => {
					if (dbg) LogMgr.mydbg(this, "while creating dynamic link", error);
					AppUtils.showToast({ message: strings("AppUtils.please_try_again"), type: SnackBarTypeOptions.ERROR });
				});
		} catch (e) {
			if (dbg) LogMgr.mydbg(this, "while creating dynamic link", e);
			AppUtils.showToast({ message: strings("AppUtils.please_try_again"), type: SnackBarTypeOptions.ERROR });
		}

		return generatedShortLinkUrl;
	}

	static getStorageRef(bucketRelativeRefPath: any) {
		return ref(getStorage(FirebaseMgr.app), bucketRelativeRefPath);
	}

	static async saveFile2StorageAsync(fullFilePathOnDevice: any, bucketRelativeRefPath: any) { // returns a promise
		try {
			const metadata = {
				contentType: "image/jpeg",
			};

			const blob = await new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.onload = () => {
					resolve(xhr.response);
				};
				xhr.onerror = () => {
					reject(new TypeError("Network request failed"));
				};
				xhr.responseType = "blob";
				xhr.open("GET", fullFilePathOnDevice, true);
				xhr.send(null);
			});
			await uploadBytes(this.getStorageRef(bucketRelativeRefPath), blob as Blob, metadata);
		} catch (Err) {
			return null;
		}
		return null;
	}

	static async getDownloadUrl(bucketRelativeRefPath: any) {
		let url = null;
		try {
			url = getDownloadURL(this.getStorageRef(bucketRelativeRefPath));
		} catch (err) {
			if (dbg) LogMgr.mydbg(this, `while getting url for "${bucketRelativeRefPath}"`, err);
		}
		return url;
	}

	static async getDownloadUrlAsync(bucketRelativeRefPath: any) {
		return getDownloadURL(this.getStorageRef(bucketRelativeRefPath));
	}

	static async getMetadata(bucketRelativeRefPath: any) {
		let metadata = null;
		try {
			metadata = getMetadata(this.getStorageRef(bucketRelativeRefPath));
		} catch (err) {
			if (dbg) LogMgr.mydbg(this, `while getting metadata for "${bucketRelativeRefPath}"`, err);
		}
		return metadata;
	}

	static async fileExistsInFirebase(bucketRelativeRefPath: any) {
		const url = await this.getDownloadUrl(bucketRelativeRefPath);
		return cu.isSet(url);
	}

	static async saveFile2Firebase(
		fullFilePathOnDevice: any,
		bucketRelativeRefPath: any,
		skipIfFileAlreadyUploaded = false,
	) {
		if (!isNil(fullFilePathOnDevice) && !isNil(bucketRelativeRefPath)) {
			try {
				if (skipIfFileAlreadyUploaded) {
					const metadata = await this.getMetadata(bucketRelativeRefPath);
					if (metadata != null) {
						return true;
					}
				}
				await this.saveFile2StorageAsync(fullFilePathOnDevice, bucketRelativeRefPath);
				return true;
			} catch (err) {
				if (dbg) LogMgr.mydbg(this, `while saving image for "${fullFilePathOnDevice} ${bucketRelativeRefPath}"`, err);
			}
		}
		return false;
	}
}
