import React from "react";
import { AppMode, cu } from "@credo/utilities";
import {
	PostHeaderScreenType, ProfilePicture, SecondaryButton, ButtonSize,
} from "@credo/ui-components";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { isCredoModeAtom } from "@credo/store";
import { userProfileAtom } from "../../../utils/atoms";
import { AppUtils } from "../../../utils";
import { strings } from "../../../i18n/config";

export interface BlockedUser {
	creat_ts: number;
	dest_mode: AppMode;
	egousername?: string;
	firstName: string;
	lastName: string;
	prof_id: string;
	profilePicRelUrl: string;
	username?: string;
}
interface BlockedUserItemProps {
	/**
	 * list entry object
	 * */
	item: BlockedUser;
	/**
	 * Gives whether list entry is last or not to show the divider
	 * */
	isLast: boolean;
	unblockUser: (item: any) => void;
}

export default function BlockedUserItem({
	item,
	isLast,
	unblockUser,
}: BlockedUserItemProps) {
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const navigate = useNavigate();

	const redirectToUserProfile = () => {
		AppUtils.navigateToUserOrStream({
			type: PostHeaderScreenType.User,
			isCredoMode,
			navigate,
			egousername: item?.egousername,
			mode: item?.dest_mode,
			profId: item?.prof_id,
			userName: item?.username,
			userProfile,
			...item,
			meta: {
				title: item?.username ? `@${item?.username}` : `${item?.firstName} ${item?.lastName} (@${item?.egousername})`,
			},
		});
	};

	return (
		<>
			<div
				key={item?.prof_id}
				className="flex flex-1 flex-row justify-between items-center w-full p-2"
			>
				<div className="flex flex-row w-full items-center cursor-pointer">
					<div className="flex flex-row w-full items-center cursor-pointer" onClick={redirectToUserProfile} aria-hidden>
						<div className="flex flex-col justify-center h-10 w-10 outline-none">
							<ProfilePicture
								profilePicUrl={cu.buildSourceUrlImage(item?.profilePicRelUrl) ?? ""}
							/>
						</div>
						<div className="flex flex-1 flex-col ml-3 mb-1 w-full mr-2">
							<span className="text-basic text-sm leading-5 font-normal break-all w-full text-start">
								{item?.username || `${item?.firstName} ${item?.lastName}`}
							</span>
						</div>
					</div>
					<div className="flex flex-1 flex-col items-end z-[100]">
						<SecondaryButton
							label={strings("SettingsPSBlockedUsersScreen.unblock")}
							size={ButtonSize.SMALL}
							buttonClassNames="uppercase"
							dimensionsClasses="w-28 h-7"
							isDanger
							onClick={() => unblockUser(item)}
						/>
					</div>
				</div>
			</div>
			{!isLast && <div style={{ height: 1 }} className="bg-background-tertiary ml-4" />}
		</>
	);
}

BlockedUserItem.defaultProps = {

};
