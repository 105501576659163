import React from "react";
import { SvgIcon, XThinIcon } from "@credo/ui-components";

interface OwnProps {
	message: string;
	onClickClose: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const ExplainComponent: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		message,
		onClickClose,
	} = props;
	return (
		<div className="flex flex-1 w-full bg-accent p-4 rounded-b-2xl">
			<div className="flex flex-1 w-full justify-center text-black pt-2.5">
				<p className="text-black text-base">
					{message}
				</p>
			</div>
			<div className="flex justify-center items-center pt-2.5">
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
				<div
					className="p-1 rounded-full bg-black cursor-pointer"
					onClick={onClickClose}
				>
					<SvgIcon
						height={10}
						width={10}
						icon={XThinIcon}
						color="var(--accent)"
					/>
				</div>
			</div>
		</div>
	);
};
