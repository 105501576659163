import {
	AppMode,
	cu,
	dbg, EvtMgr, GenericResponse, LogMgr, ResponseCode, SessMgr,
} from "@credo/utilities";
import { MsgMgr } from "../../config/MsgMgr";
import { ServerFeedItem } from "../../modules/home/requests";
import { UserItem } from "../../modules/stream/UserListItem";
import { Consts, EventConst, MsgConst } from "../../utils";
import { StreamInfo, StreamItem } from "../../utils/types";

interface FetchStreamInfoRequestObject {
	user_id: string;
	mode: string;
	streamId?: string,
	s_history?: string,
	struname?: string,
	postUuid?: string;
}
interface mngRelation4StreamRequestObject {
	user_id: string;
	mode: string;
	targetStreamId?: string;
	action: string;
	relType: string;
	prof_id?: string;
}
interface performActionOnTaskRequestObject {
	user_id: string;
	mode: string;
	postUuid?: string,
	streamId?: string,
	action: string,
	taskType: string,
	targetPostUuid?: string,
}
interface FetchStreamItemsRequestObject {
	user_id: string;
	mode: string;
	page: number;
	seed: number;
	lastTs: number;
	lastId: number;
	fetchSize: number;
	streamId?: string;
	statusFilter?: string;
	struname?: string,
	APIVersion?: string,
}

interface FetchNoAuthStreamItemsRequestObject {
	lastTs: number;
	fetchSize: number;
	streamId: string;
	struname: string;
	maxNbOfCo: number;
	APIVersion: string;
}

interface FetchNoAuthStreamInfoRequestObject {
	streamId: string;
	struname: string;
}

interface UpdateStreamRequestObject {
	user_id: string;
	mode: string;
	name?: string,
	descr?: string,
	style?: string,
	struname?: string,
	type?: string,
	hier?: string,
	categ?: string,
	profilePicRelUrl?: string,
	coverPicRelUrl?: string,
	streamId?: string,
	disc?: string,
	joinModes?: string,
	ac_folcom?: string,
	ac_joicom?: string,
	ac_joiaut?: string,
	ac_alwpos?: string,
	ps_folsee?: string,
	ps_memsee?: string,
	src_prop_val?: string,
}

interface CreateStreamRequestObject {
	user_id: string;
	mode: AppMode;
	name: string;
	struname: string;
	descr: string;
	style: string;
	type: string;
	hier: string;
	categ: string | null;
	disc: string;
	joinModes: string;
	prof_id: string;
	streamId: string;
}

export interface fetchStreamMembersRequestObject {
	user_id: string;
	mode: string;
	page: number;
	seed: number;
	lastTs: number;
	lastId: number;
	fetchSize: number;
	streamId?: string;
	targetUserOrProfId: string;
	what?: string;
}

export interface FetchStreamFollowersItemsRequestObject {
	user_id: string;
	mode: string;
	page: number;
	seed: number;
	lastTs: number;
	lastId: number;
	fetchSize: number;
	targetUserOrProfId: string;
	streamId: string,
}

// eslint-disable-next-line import/prefer-default-export
export class StreamActions {
	/**
	 * Retrieve the stream info from the server by passing stream ID or struname
	 * if the current user is authenticated successfully.
	 *
	 * */
	static retrieveStreamInfoFromSvr(request: FetchStreamInfoRequestObject) {
		if (SessMgr.isSessionAuth()) {
			// get stream info from server
			MsgMgr.makeRemoteRequest_generic<FetchStreamInfoRequestObject, GenericResponse<StreamInfo[]>>({
				msgName: `get_${MsgConst.streamInfo}`,
				instanceCallback: (response: any) => {
					if (response.retcd === ResponseCode.ERR_STNA) {
						EvtMgr.getInstance(EventConst.streamDeleted).notifyListeners(response);
					} else {
						EvtMgr.getInstance(EventConst.streamInfo).notifyListeners(response);
					}
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: cu.getAppMode(),
					streamId: request.streamId,
					s_history: request.s_history,
					struname: request.struname,
					postUuid: request.postUuid,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not retrieving stream info from svr");
	}

	static mngRelation4Stream(request: mngRelation4StreamRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<mngRelation4StreamRequestObject, GenericResponse<StreamInfo[]>>({
				msgName: EventConst.mngRelation4Stream,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(MsgConst.mngRelation4Stream).notifyListeners({ ...response, ...request });
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					targetStreamId: request.targetStreamId,
					action: request.action,
					relType: request.relType,
					prof_id: request.prof_id,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, `sess not authenticated so not performing stream ${request.action} info from svr`);
	}

	static performActionOnTask(request: performActionOnTaskRequestObject) {
		if (SessMgr.isSessionAuth()) {
			MsgMgr.makeRemoteRequest_generic<performActionOnTaskRequestObject, GenericResponse<StreamInfo[]>>({
				msgName: EventConst.actionOnTask,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(MsgConst.actionOnTask).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: request.mode,
					postUuid: request.postUuid,
					streamId: request.streamId,
					action: request.action,
					taskType: request.taskType,
					targetPostUuid: request.targetPostUuid,
				},
			});
		} else if (dbg) LogMgr.mydbg(this, "sess not authenticated so not performing action on stream info from svr");
	}

	static async getStreamItems(request: FetchStreamItemsRequestObject) {
		if (SessMgr.isSessionAuth()) {
			// get stream items from server
			return MsgMgr.makeRemoteRequest_generic<FetchStreamItemsRequestObject, GenericResponse<ServerFeedItem[]>>({
				msgName: `get_${MsgConst.streamItems}`,
				instanceCallback: (response: any) => {
					EvtMgr.getInstance(EventConst.streamItems).notifyListeners(response);
				},
				request: {
					user_id: SessMgr.getFromSession(Consts.user_id),
					mode: cu.getAppMode(),
					streamId: request.streamId,
					struname: request.struname,
					page: request.page,
					seed: request.seed,
					lastTs: request.lastTs,
					lastId: request.lastId,
					fetchSize: request.fetchSize,
					statusFilter: request.statusFilter,
					APIVersion: request.APIVersion,
				},
			});
		} if (dbg) LogMgr.mydbg(this, "sess not authenticated to get stream items from svr");
		return null;
	}

	/**
	 * Retrieve the stream items from the server without authentication.
	 * */
	static async getNoAuthStreamItems(request: FetchNoAuthStreamItemsRequestObject) {
		return MsgMgr.makeRemoteRequest_generic<FetchNoAuthStreamItemsRequestObject, GenericResponse<StreamItem[]>>({
			msgName: `get_${MsgConst.noAuthStreamItems}`,
			msgAddr: `get${MsgConst.noAuthStreamItems}`,
			instanceCallback: (response: any) => {
				EvtMgr.getInstance(EventConst.noAuthStreamItems).notifyListeners(response);
			},
			request: {
				streamId: request.streamId,
				struname: request.struname,
				lastTs: request.lastTs,
				fetchSize: request.fetchSize,
				maxNbOfCo: request.maxNbOfCo,
				APIVersion: request.APIVersion,
			},
		});
	}

	/**
	 * Retrieve the stream info from the server without authentication.
	 *
	 * */
	static getNoAuthStreamInfo(request: FetchNoAuthStreamInfoRequestObject) {
		MsgMgr.makeRemoteRequest_generic<FetchNoAuthStreamInfoRequestObject, GenericResponse<StreamInfo[]>>({
			msgName: `get_${MsgConst.noAuthStreamInfo}`,
			msgAddr: `get${MsgConst.noAuthStreamInfo}`,
			instanceCallback: (response: any) => {
				if (response.retcd === ResponseCode.ERR_STNA) {
					EvtMgr.getInstance(EventConst.streamDeleted).notifyListeners(response);
				} else {
					EvtMgr.getInstance(EventConst.noAuthStreamInfo).notifyListeners(response);
				}
			},
			request: {
				streamId: request.streamId,
				struname: request.struname,
			},
		});
	}

	static updateStreamInfo(
		request: UpdateStreamRequestObject,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		MsgMgr.makeRemoteRequest_generic<UpdateStreamRequestObject, GenericResponse<StreamInfo[]>>({
			msgName: MsgConst.updateStreamInfo,
			msgAddr: MsgConst.updateStreamInfo,
			successCallback,
			errorCallback,
			request: {
				...request,
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
			},
		});
	}

	static createStream(
		request: CreateStreamRequestObject,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		MsgMgr.makeRemoteRequest_generic<CreateStreamRequestObject, GenericResponse<any>>({
			msgName: MsgConst.createStream,
			successCallback,
			errorCallback,
			request: {
				...request,
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
			},
		});
	}

	static fetchStreamMembers(
		request: fetchStreamMembersRequestObject,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		return MsgMgr.makeRemoteRequest_generic<fetchStreamMembersRequestObject, GenericResponse<UserItem[]>>({
			msgName: MsgConst.streamMembersItems,
			msgAddr: MsgConst.streamMembersItems,
			successCallback,
			errorCallback,
			request: {
				...request,
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
			},
		});
	}

	static fetchStreamFollowers(
		request: FetchStreamFollowersItemsRequestObject,
		successCallback: (response: any) => void,
		errorCallback: (response: any) => void,
	) {
		return MsgMgr.makeRemoteRequest_generic<FetchStreamFollowersItemsRequestObject, GenericResponse<UserItem[]>>({
			msgName: MsgConst.streamFollowersItems,
			msgAddr: MsgConst.streamFollowersItems,
			successCallback,
			errorCallback,
			request: {
				...request,
				user_id: SessMgr.getFromSession(Consts.user_id),
				mode: request.mode,
			},
		});
	}
}
