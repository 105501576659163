import React, { useEffect, useRef, useState } from "react";
import styles from "./widget-carousel.module.css";
import { classNames } from "../common";
import { SwitchIcon } from "../assets/icons";

export type WidgetCarouselProps = {
	widgets: {
		onEnter: () => void | undefined;
		onLeave: () => void | undefined;
		render: () => React.ReactNode;
		onClick?: (event?: any) => void;
	}[];
	autoAdvance: boolean;
	canAdvance: () => boolean | undefined;
	advanceInterval: number;
}

export const WidgetCarousel = ({
	widgets,
	autoAdvance = true,
	canAdvance = () => true,
	advanceInterval = 3000,
}: WidgetCarouselProps) => {
	const [widgetIndex, setWidgetIndex] = useState({
		index: 0,
		nextIndex: 1,
		previousIndex: -1,
		dirDelta: 1,
	});
	const [isTransitioning, setIsTransitioning] = useState(false);
	const [resetInterval, setResetInterval] = useState<boolean>(false);

	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

	const [switchButtonState, setSwitchButtonState] = useState({
		down: false,
		timer: 0,
	});

	const clearCarousalInterval = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	};

	const switchMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setSwitchButtonState({
			down: true,
			timer: window.setTimeout(toggleAutoAdvance, 1000),
		});
		clearCarousalInterval();
		setResetInterval(true);
	};
	const switchMouseUp = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (switchButtonState.down) {
			doAdvance();
		}
		if (switchButtonState.timer !== 0) {
			window.clearTimeout(switchButtonState.timer);
		}

		setSwitchButtonState({
			down: false,
			timer: 0,
		});
	};

	const [s_autoAdvance, setAutoAdvance] = useState(autoAdvance);
	const toggleAutoAdvance = () => {
		// d console.log(`Toggling from:${s_autoAdvance}`);
		setAutoAdvance((cur) => !cur);
		setSwitchButtonState({
			down: false,
			timer: 0,
		});
	};

	const doAdvance = () => {
		// d console.log("Advancing");
		setIsTransitioning(true);
		let ndd = 0;
		// eslint-disable-next-line no-return-assign
		setWidgetIndex((curIndex) => ({
			index: curIndex.nextIndex,
			// eslint-disable-next-line no-nested-ternary
			dirDelta: (ndd = (curIndex.nextIndex === widgets?.length - 1)
				? -1
				: (curIndex.nextIndex === 0 ? 1 : curIndex.dirDelta)),
			nextIndex: (curIndex.nextIndex + ndd) % widgets?.length,
			previousIndex: curIndex.index,
		}));
	};

	/** auto advance interval setup */
	useEffect(() => {
		// d console.log(`Setting up auto advance interval ${advanceInterval}`);
		clearCarousalInterval();
		intervalRef.current = setInterval(() => {
			// d console.log(`Advance interval check: ${s_autoAdvance}`);
			if (s_autoAdvance && canAdvance()) {
				doAdvance();
				setResetInterval(false);
			}
		}, advanceInterval);
		return clearCarousalInterval;
	}, [s_autoAdvance, canAdvance, advanceInterval, resetInterval]);

	const onEndTransition = () => {
		setIsTransitioning(false);
		if (widgetIndex.previousIndex !== -1) widgets[widgetIndex.previousIndex].onLeave();
		widgets[widgetIndex.index].onEnter();
	};

	return (
		<div style={{
			position: "relative",
			width: "100%",
			height: "31px",
			overflow: "hidden",
			scrollBehavior: "smooth",
		}}
		>
			{widgets.map((widget, index) => (
				<button
					type="button"
					key={index}
					className={classNames(styles.widget,
						// eslint-disable-next-line no-nested-ternary
						widgetIndex.index === index
							? styles.current
							: styles.previous)}
					style={{
						top: `${(widgetIndex.index - index) * 31}px`,
					}}
					onTransitionEnd={widgetIndex.index === index ? onEndTransition : undefined}
					onClick={(event) => {
						if (widget.onClick && !isTransitioning) {
							widget.onClick(event);
						}
					}}
				>
					{widget.render()}
				</button>
			))}
			{widgets.length > 1 && (
				<button
					type="button"
					className={classNames(styles.switcherButton, isTransitioning ? styles.inTransition : null)}
					onMouseDown={switchMouseDown}
					onMouseUp={switchMouseUp}
				>
					<SwitchIcon
						containerStyle={{
							pointerEvents: "none",
							fill: s_autoAdvance ? "limegreen" : "black",
						}}
						pathStyle={{
							pointerEvents: "none",
							fill: s_autoAdvance ? "black" : "limegreen",
						}}
					/>
				</button>
			)}
		</div>
	);
};
