import {
	classNames,
	FeedNoPost,
	HomeRequestSkeleton, SharedActivities, Spinner, SpinnerLeafType, SvgIcon,
} from "@credo/ui-components";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
	AppMode, cu, dbg, LogMgr, useEvtMgrListener, YesNoOptions,
} from "@credo/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import last from "lodash/last";
import { useMeasure, useWindowSize } from "react-use";
import { useLocation, useNavigate } from "react-router-dom";
import { isCredoModeAtom } from "@credo/store";
import { isMobile } from "react-device-detect";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { strings } from "../../i18n/config";
import {
	getNetworkCountAtom,
	isConnectedAtom, isSessionAuthAtom, userIdAtom, userNetworkNotificationsAtom, userNotifsReqCountAtom, userProfileAtom,
} from "../../utils/atoms";
import { UserActions } from "../../services/actions/user";
import CfgMgr from "../../config/CfgMgr";
import { AppUtils, Consts, EventConst } from "../../utils";
import { HelmetComponent } from "../../components/HelmetComponent";
import { useSetAppHeader } from "../../utils/hooks/useSetAppHeader";

const MAX_NETWORK_NOTIFICATIONS_COUNT = CfgMgr.cfg_feed_nbOfItems2FetchFromDb;

enum OperationType {
	APPEND = "append",
	REPLACE = "replace",
}

// eslint-disable-next-line import/prefer-default-export
export const ProfileNetwork: React.FC = () => {
	const [isCredoMode] = useAtom(isCredoModeAtom);
	const [userProfile] = useAtom(userProfileAtom);
	const [isConnected] = useAtom(isConnectedAtom);
	const [networkCount] = useAtom(getNetworkCountAtom);
	const [notifReqCount, setNotifReqCount] = useAtom(userNotifsReqCountAtom);
	const [userId] = useAtom(userIdAtom);
	const [isSessionAuth] = useAtom(isSessionAuthAtom);
	const [loading, setLoading] = useState(true);
	const [networkNotificationsData, setNetworkNotificationsData] = useAtom(userNetworkNotificationsAtom);
	const [isLastPage, setIsLastPage] = useState(false);
	const [, setIsLoadMore] = useState<boolean>(false);
	const navigate = useNavigate();
	const [divRef, { width }] = useMeasure();
	const location = useLocation();

	const { width: windowWidth } = useWindowSize();

	useSetAppHeader({
		title: strings("Sidebar.network"),
	});

	const getUserNetworkNotifications = async (
		inProcessSetter: ((inProcess: boolean) => void) | null,
		operation: OperationType,
	) => {
		if (inProcessSetter) {
			inProcessSetter(true);
		}
		const lastItem = last(networkNotificationsData);
		const lastTs = lastItem && cu.isSet(lastItem?.createTs) ? lastItem?.createTs : 0;
		const requestMsgData = {
			lastTs: operation !== OperationType.REPLACE && networkNotificationsData.length > 0 ? lastTs : 0,
			lastId: networkNotificationsData && networkNotificationsData.length > 0
				? last(networkNotificationsData)?.id : 0,
			user_id: userId ?? "",
			mode: isCredoMode ? AppMode.CREDO : AppMode.EGO,
			fetchSize: MAX_NETWORK_NOTIFICATIONS_COUNT,
			summary: YesNoOptions.NO, // 'y' = summary response, 'n' = all network notifications
		};

		const successCallback = (response: any) => {
			if (inProcessSetter) {
				inProcessSetter(false);
			}
			if (operation === OperationType.REPLACE) {
				setNetworkNotificationsData(response?.items);
			} else {
				setNetworkNotificationsData((prevState: any) => [
					...prevState,
					...response.items,
				]);
			}
			if (response.items.length < MAX_NETWORK_NOTIFICATIONS_COUNT) {
				setIsLastPage(true);
			}
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		};

		const errorCallback = (response: any) => {
			if (dbg) LogMgr.mydbg("error while fetching network notifications", response);
			if (inProcessSetter) {
				inProcessSetter(false);
			}
		};
		UserActions.fetchNetworkNotifications(requestMsgData, successCallback, errorCallback);
	};

	useEffect(() => {
		if (isSessionAuth && isConnected) {
			getUserNetworkNotifications(setLoading, OperationType.REPLACE);
		}
	}, [isConnected, isSessionAuth]);

	useEffect(() => {
		if (!loading) {
			getUserNetworkNotifications(null, OperationType.REPLACE);
		}
		const hasNetwork = networkCount > 0;
		const notifs = cloneDeep(notifReqCount);
		if (hasNetwork) {
			set(notifs, "network", 0);
			setNotifReqCount(notifs);
		}
	}, []);

	const navigateToUser = (id: string, username: string, mode: string, egousername: string, targetText: string) => {
		AppUtils.navigateToUserProfile(
			id,
			username,
			mode,
			isCredoMode,
			userProfile,
			navigate,
			egousername,
			YesNoOptions.NO,
			{
				title: targetText,
			},
		);
	};

	const navigateToStream = (struname: string, streamId: string, targetText: string) => {
		AppUtils.navigateToStreamProfile(streamId, struname, navigate, { title: targetText });
	};

	const renderSharedActivities = () => (
		networkNotificationsData
			.filter((element) => !element.isNotHandled)
			.map((item, i) => (
				<SharedActivities
					// eslint-disable-next-line react/no-array-index-key
					key={i}
					postData={item}
					isLast={networkNotificationsData.length - 1 === i}
					handleClickStream={navigateToStream}
					handleClickUser={navigateToUser}
				/>
			))
	);

	const renderFooter = () => {
		if (isLastPage || !isConnected) return null;
		return (
			<div className="flex items-center justify-center py-4">
				<Spinner leaf_shape={SpinnerLeafType.CIRCLES} width="20px" height="20px" />
			</div>
		);
	};

	const handleLoadMore = () => {
		if (loading || isLastPage) return;
		getUserNetworkNotifications(setIsLoadMore, OperationType.APPEND);
	};

	/**
	 * If the length of the data is less than max item
	 * count and some of the data is not handled by the
	 * current version of UI, we should take the lastTs
	 * and get more data so user can still scroll through
	 * the pages. We have to do this because the library
	 * react-infinite-scroll-component does not support
	 * fetching more data when the data is less than the
	 * screen height.
	 * */
	useEffect(() => {
		if (
			networkNotificationsData
				.filter((element) => !element.isNotHandled)
				.length <= MAX_NETWORK_NOTIFICATIONS_COUNT
			&& !isLastPage
		) {
			handleLoadMore();
		}
	}, [networkNotificationsData, isLastPage]);

	const handleRefresh = () => {
		getUserNetworkNotifications(null, OperationType.REPLACE);
	};

	useEvtMgrListener(EventConst.loadNetwork, handleRefresh);

	return (
		<div
			// @ts-ignore
			ref={divRef}
			className={classNames(
				"block",
				"w-full max-w-screen-sm md:min-w-[40rem]",
				"text-basic",
				"bg-background",
				"overflow-visible",
				cu.getGlobalVar(Consts.isBottomNavVisible) ? "-mt-3" : "pt-6",
			)}
			style={{
				width: "100%",
			}}
		>
			<HelmetComponent
				title={strings("ProfilePage.title")}
				url={location?.pathname}
			/>
			<div
				className={classNames(
					"flex flex-col text-white",
					"bg-background w-full",
					isMobile && windowWidth < 768 ? "min-h-[calc(100vh-4rem-1px-3rem)]" : "min-h-[calc(100vh-4rem-1px)]",
				)}
			>
				<div className="relative pt-3">
					{loading
						? (
							<div className="max-h-[calc(100vh-4rem-1px-0.5rem)] overflow-hidden">
								<HomeRequestSkeleton skeletonCount={10} />
							</div>
						) : (
							<>
								{networkNotificationsData && networkNotificationsData.length > 0
									? (
										<InfiniteScroll
											next={handleLoadMore}
											hasMore
											loader={renderFooter()}
											dataLength={networkNotificationsData.length}
										>
											{renderSharedActivities()}
										</InfiniteScroll>
									) : (
										<div
											className={classNames(
												"flex flex-col items-center justify-center",
												windowWidth < 768 ? "h-[calc(100vh-4rem-1px-3rem-4rem)]" : "h-[calc(100vh-4rem-1px-3rem)]",
											)}
											style={{
												width,
											}}
										>
											<SvgIcon
												icon={FeedNoPost}
												title={strings("Assets.no_network_notifications")}
											/>
											<p className="w-44 text-center text-gray-dark text-sm mt-4 whitespace-pre-wrap">
												{strings("NetworkTab.no_network_notifs_message")}
											</p>
										</div>
									)}
							</>
						)}
				</div>

			</div>
		</div>
	);
};
