import {
	AppMode,
	AuthorisationManager, Context, DataStatus,
} from "@credo/utilities";
import { useAtomValue } from "jotai";
import { userPermissionsAtom, userProfileAtom } from "@credo/store";
import { useEffect, useState } from "react";
import { applicationModeAtom, userIdAtom } from "../atoms";
import { User } from "./PermissionsObjects";

// eslint-disable-next-line import/prefer-default-export
export const useAuthManager = () => {
	const userId = useAtomValue(userIdAtom);
	const appMode = useAtomValue(applicationModeAtom);
	const userProfile = useAtomValue(userProfileAtom);
	const permissionsData = useAtomValue(userPermissionsAtom);
	const [initialised, setInitialised] = useState<boolean>(false);
	const [authManagerInstance, setInstance] = useState(
		AuthorisationManager.instance().getEngine(
			userId,
			{
				context: new Context()
					.add("actor", userProfile as unknown as User),
				permissions: permissionsData[appMode],
			},
		),
	);

	useEffect(() => {
		if (permissionsData[AppMode.EGO].length > 0 || permissionsData[AppMode.CREDO].length > 0) {
			setInstance(AuthorisationManager.instance().getEngine(
				userId,
				{
					context: new Context()
						.add("actor", userProfile as unknown as User),
					permissions: permissionsData[appMode],
				},
				true,
			));
		}
		if (permissionsData.dataStatus === DataStatus.LOADED) {
			setInitialised(true);
		}
	}, [permissionsData, appMode]);

	return {
		authManager: authManagerInstance,
		initialised,
	};
};
