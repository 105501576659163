/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";
import {
	classNames,
	CredoBadge,
	FollowIcon,
	InfoIcon,
	NotificationIcon,
	ProfilePicture,
	SvgIcon,
	ViewConnectionsIcon,
} from "@credo/ui-components";
import { useNavigate } from "react-router-dom";
import { cu, YesNoOptions } from "@credo/utilities";
import { isMobile } from "react-device-detect";
import { useAtom } from "jotai";
import {
	EntityType,
	ProfileSize,
	RelationTypeStatus,
} from "../../../utils/types";
import { OTHER_USER_PROFILE_PAGE, STREAM_PROFILE_PAGE, USER_ALL_POSTS } from "../../../routes/constants";
import { AppUtils } from "../../../utils";
import EngageCommunityIconTutorial from "./EngageCommunityIconTutorial";
import { userProfileAtom } from "../../../utils/atoms";

interface UserCommunityItemProps {
	/**
	 * Id for unique key purposes
	 * */
	id: number;
	/**
	 * Item object which needs to be rendered.
	 * */
	item: any,
	/**
	 * Type of the entity which needs to be rendered.
	 * */
	entityType: EntityType;
	/**
	 * Optional extra classNames for outermost
	 * link/button of the item.
	 *
	 * @default ""
	 * */
	className?: string;
	showInfoIcon?: boolean;
	isEgoWall?: boolean;
}

const UserCommunityItem: React.FC<UserCommunityItemProps> = (props: UserCommunityItemProps) => {
	const {
		id,
		item,
		entityType,
		className,
		showInfoIcon,
		isEgoWall,
	} = props;

	const [showCommIconTutorial, setShowCommIconTutorial] = useState(false);
	const [userInfo] = useAtom(userProfileAtom);

	const imageUrl = useMemo(() => {
		if (cu.isSet(item.profilePicRelUrl)) {
			return cu.buildSourceUrlImage((item.profilePicRelUrl));
		}
		return isEgoWall ? cu.buildSourceUrlImage("u_e_pp.png") : cu.buildSourceUrlImage("s_pp_dg.png");
	}, [item]);

	const openCommIconTutorial = (e: any) => {
		e.stopPropagation();
		setShowCommIconTutorial(true);
	};

	const closeCommIconTutorial = () => {
		setShowCommIconTutorial(false);
	};

	const navigate = useNavigate();

	const itemLink: string = entityType === EntityType.STREAM
		? `${STREAM_PROFILE_PAGE}/${item.struname}`
		: `${OTHER_USER_PROFILE_PAGE}/${item.struname}`;

	const renderInfoButtons = () => (
		<div className="flex justify-center items-center ml-1">
			{item?.adminMode && item?.adminMode !== YesNoOptions.NO
				? (
					<div className="flex flex-row justify-center items-center flex-1">
						{item.totPendingReq > 0
							? (
								<CredoBadge
									badgeValue={item.totPendingReq > 9 ? "9+" : item.totPendingReq}
									containerStyle="mr-1"
								/>
							)
							: null}
						{item.totPendingReq > 0 ? (
							<SvgIcon
								icon={NotificationIcon}
								stroke="var(--accent)"
							/>
						) : (
							<SvgIcon
								icon={NotificationIcon}
								stroke="var(--gray-dark)"
							/>
						)}
					</div>
				)
				: cu.isSet(item.entityType) && (item.entityType === RelationTypeStatus.JOIN_STREAM || item.entityType === "b")
					? (
						<div className="flex justify-center items-center flex-1">
							<SvgIcon
								icon={ViewConnectionsIcon}
								stroke="var(--gray-dark)"
							/>
						</div>
					)
					: cu.isSet(item.entityType) && item.entityType === RelationTypeStatus.FOLLOW
						? (
							<div className="flex justify-center items-center flex-1">
								<SvgIcon
									icon={FollowIcon}
									stroke="var(--gray-dark)"
								/>
							</div>
						)
						: null}
		</div>
	);

	const renderContent = () => (
		<>
			<div className="flex flex-row items-center">
				<ProfilePicture
					profilePicUrl={imageUrl ?? ""}
					isStream={entityType === EntityType.STREAM}
					size={ProfileSize.SMALL}
				/>
				<div className="flex flex-col items-start text-start pl-2">
					<span
						className="text-primary text-sm leading-5 font-normal explore-item__text truncate"
					>
						{AppUtils.handleStreamName(item.name)}
					</span>
					{cu.isSet(item.struname) && (
						<span
							className="text-xs text-gray-dark explore-item__text truncate"
						>
							{itemLink.substring(1)}
						</span>
					)}
				</div>
			</div>
			{renderInfoButtons()}
			{showCommIconTutorial
				? (
					<EngageCommunityIconTutorial
						isVisible={showCommIconTutorial}
						onClose={closeCommIconTutorial}
					/>
				)
				: null}
		</>
	);

	const onClickItem = () => {
		if (isEgoWall) {
			navigate(`${USER_ALL_POSTS}/${userInfo?.egousername}`);
		} else { navigate(itemLink); }
	};

	if (isMobile) {
		return (
			<div
				role="button"
				tabIndex={0}
				onKeyDown={onClickItem}
				key={id}
				onClick={onClickItem}
				className={classNames(
					"flex flex-row justify-between items-center p-4 outline-none w-full",
					"border-b border-background-tertiary relative",
					className,
				)}
			>
				{showInfoIcon && (
					<SvgIcon
						icon={InfoIcon}
						className={classNames("absolute top-[2px] right-4 z-10 -scale-x-100")}
						onClick={openCommIconTutorial}
					/>
				)}
				{renderContent()}
			</div>
		);
	}

	return (
		<button
			type="button"
			key={id}
			onClick={onClickItem}
			className={classNames(
				"flex flex-row justify-between items-center p-4 w-full",
				"border-b border-background-tertiary relative",
				className,
			)}
		>
			{showInfoIcon && (
				<SvgIcon
					icon={InfoIcon}
					className={classNames("absolute top-[2px] right-4 z-10 -scale-x-100")}
					onClick={openCommIconTutorial}
				/>
			)}
			{renderContent()}
		</button>
	);
};

UserCommunityItem.defaultProps = {
	className: "",
	showInfoIcon: false,
	isEgoWall: false,
};

export default UserCommunityItem;
