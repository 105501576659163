import React, { useEffect, useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import {
	cu, EvtMgr, GenericResponse, LogMgr, useEvtMgrListener,
} from "@credo/utilities";
import isEmpty from "lodash/isEmpty";
import {
	credoGraphDataAtom, isConnectedAtom, isSessionAuthAtom, userIdAtom,
} from "../../utils/atoms";
import { UserActions } from "../../services/actions/user";
import { Consts, EventConst } from "../../utils";
import { GraphDataRequest, GraphDataResponse } from "./types";

const defaultOut = {
	overallScore: 0,
	overallScoreData: {
		label: "All Tags",
		data: [
			[
				{
					aggRating: 0,
					score: 0,
				},
			],
		],
	},
	seriesLabels: ["All time"],
	tagData: [],
};

// eslint-disable-next-line import/prefer-default-export
export const GraphDataProvider: React.FC = () => {
	const isConnected = useAtomValue(isConnectedAtom);
	const isSessionAuth = useAtomValue(isSessionAuthAtom);
	const userId = useAtomValue(userIdAtom);
	const setCredoGraphData = useSetAtom(credoGraphDataAtom);
	const fetchDataInterval = useRef<any>(null);

	const fetchData = () => {
		const request = {
			user_id: userId || "",
		};

		const successCallback = (response: any) => {
			if (response?.items?.length > 0) {
				const { items } = response;
				if (items?.length > 0) {
					setCredoGraphData({
						isLoading: false,
						data: {
							/**
							 * send empty data regardless of overallScore because it can be
							 * 0.23 and no tags in it. So should handle it internally anyway
							 * */
							credoGraph: isEmpty(items[0]?.out) ? defaultOut : items[0]?.out,
							influenceGraph: isEmpty(items[0]?.in) ? defaultOut : items[0]?.in,
						},
					});
				} else {
					setCredoGraphData({
						isLoading: false,
						data: {
							credoGraph: defaultOut,
							influenceGraph: defaultOut,
						},
					});
				}
			}
		};
		const errorCallback = (response: any) => {
			LogMgr.mydbg(`GraphDataProvider: Error while fetching Credograph data for logged in user ${userId}: `, response.retcd);
		};
		UserActions.getCredoGraphData(
			request,
			successCallback,
			errorCallback,
		);
	};

	useEffect(() => {
		if (isConnected && cu.isSet(userId) && isSessionAuth) {
			// Initial call when there is no data and then next call will be interval
			fetchData();
			if (fetchDataInterval.current) {
				clearInterval(fetchDataInterval.current);
			}
			fetchDataInterval.current = setInterval(fetchData, Consts.fetchGraphDataInterval);
		}

		return () => {
			clearInterval(fetchDataInterval.current);
		};
	}, [isConnected, isSessionAuth, userId]);

	/**
	 * Handling Post/Comment Author tags modal graph
	 * data. Since out components are in ui-components
	 * we need to handle the data by events
	 * */
	const fetchPostGraphData = (message: { request: GraphDataRequest }) => {
		const request: GraphDataRequest = {
			...message.request,
			user_id: userId || "",
		};

		const successCallback = (response: GenericResponse<GraphDataResponse[]>) => {
			if (response.items?.length > 0 && response.items[0].in) {
				EvtMgr
					.getInstance(EventConst.pushPostGraphDataToComp)
					.notifyListeners({
						data: {
							credoGraph: isEmpty(response.items[0]?.out) ? defaultOut : response.items[0]?.out,
							influenceGraph: isEmpty(response.items[0]?.in) ? defaultOut : response.items[0]?.in,
						},
					});
			}
		};

		const errorCallback = (response: any) => {
			LogMgr.mydbg(
				`GraphDataProvider: Error while fetching Credograph data for this post ${message.request.post_id}: `,
				response.retcd,
			);
			EvtMgr
				.getInstance(EventConst.pushPostGraphDataToComp)
				.notifyListeners({
					error: response.retcd,
				});
		};

		if (userId) {
			UserActions.getCredoGraphData(
				request,
				successCallback,
				errorCallback,
			);
		}
	};

	useEvtMgrListener(EventConst.getPostGraphData, fetchPostGraphData);

	return null;
};
