import React, {
	useCallback, useEffect, useMemo, useState,
} from "react";
import { BoostActionType } from "@credo/utilities";
import { TabPanel, TabViewMode } from "../../credo-tab";
import { classNames, ScalerSize, SvgIcon } from "../../common";
import { InfoCircleIcon } from "../../assets/icons";
import { ButtonSize, SecondaryButton } from "../../button";
import SvgRatingSelector, { SvgRatingSelectorProps } from "../svg-rating-selector";
import {
	CredoTag, CredoTagDataProvider, TagMessages,
} from "../credo-tag";
import { BoostTrayProps } from "../../boost";
import { BoostContent } from "./boost-content";
import styles from "./boost-content.module.css";

enum InfoType {
	BOOST = "boost",
	RATING = "rating",
}

export interface CreedSelectorContentProps extends Omit<SvgRatingSelectorProps, "size"> {
	tag: string;
	messages: TagMessages;
	boostTrayProps?: BoostTrayProps;
	tagDataProvider: CredoTagDataProvider;
}

/**
 * TODO:
 * 1. TagDataProvider is being drilled a lot, figure out a way to avoid that kind of data drilling. (maybe create a context?)
 * 5. Remove commented code and ego section from selector component
 * 9. Add doc for portal and Creed selector components
 * */

// eslint-disable-next-line import/prefer-default-export
export const CreedSelectorContent: React.FC<CreedSelectorContentProps> = (
	props: CreedSelectorContentProps,
) => {
	const {
		tag,
		messages,
		rating,
		onRatingHover,
		onCancel,
		onSelected,
		anchor,
		egoInfoProps,
		boostTrayProps,
		tagDataProvider,
	} = props;

	const [showInfo, setShowInfo] = useState<boolean>(false);

	const handleClickViewTutorial = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		event.preventDefault();
		if (egoInfoProps?.onPressTagInfoBubble) {
			egoInfoProps.onPressTagInfoBubble();
			onCancel();
		}
	}, [egoInfoProps]);

	const handleChangeTab = useCallback((tabIndex: number) => {
		if (tabIndex === 1) {
			boostTrayProps?.onActions({
				type: BoostActionType.FETCH_DATA,
				message: {
					tag,
				},
			});
		}
	}, [tag]);

	useEffect(() => {
		if (document) {
			const element = document.getElementById("creed-selector-content__tab-panel__wrapper");
			if (element) {
				element.classList.add("flex", "flex-col", "w-full", "justify-center", "items-center");
			}
		}

		return () => {
			const element = document.getElementById("creed-selector-content__tab-panel__wrapper");
			if (element) {
				element.classList.remove("flex", "flex-col", "w-full", "justify-center", "items-center");
			}
		};
	}, []);

	const renderInfo = useCallback((type: InfoType) => {
		const handleIconClick = () => setShowInfo((prevState) => !prevState);
		const getText = () => {
			switch (type) {
				case InfoType.BOOST:
					return messages.boost_this_post;
				case InfoType.RATING:
				default:
					return messages.rate_this_item;
			}
		};
		return (
			<div
				className={classNames(
					"flex flex-col max-w-[120px] sm:w-[120px]",
					showInfo ? "justify-start" : "justify-center",
				)}
			>
				<p className="text-off-white font-pt-sans text-lg tracking-[0.138462px] font-normal">
					{getText()}
					{" "}
					<CredoTag
						id={`creed-selector-tag-${tag}`}
						tag={tag}
						size={ScalerSize.S}
						onRate={() => {}}
						dataProvider={tagDataProvider}
						isUserOwnPost={false}
						isCredoMode={false}
						messages={messages}
						disable
					/>
				</p>
				<div
					className={classNames(
						"rounded-lg mt-1 relative",
						showInfo ? "bg-credo-black" : "",
					)}
				>
					<SvgIcon
						icon={InfoCircleIcon}
						width={18}
						height={18}
						className="cursor-pointer"
						onClick={handleIconClick}
					/>
					{showInfo && (
						<React.Fragment>
							<p className="text-off-white text-[11px] leading-[14px] font-pt-sans p-1.5">
								{messages.rating_info}
							</p>
							<div className="w-full flex justify-center items-center my-2">
								<SecondaryButton
									onClick={handleClickViewTutorial}
									dimensionsClasses="h-[18px] rounded-[16px] text-xs font-light !text-accent !lowercase px-2"
									size={ButtonSize.SMALL}
									label={messages.view_tutorial}
									buttonClassNames="!border-accent"
								/>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}, [messages, showInfo]);

	const ratingSelectorContent = useMemo(() => (
		<div
			className={classNames(
				"flex justify-center items-center h-[260px] w-full min-w-[321px]",
				styles.ratingContent,
			)}
		>
			{renderInfo(InfoType.RATING)}
			<div className="flex flex-row">
				<SvgRatingSelector
					rating={rating}
					anchor={anchor}
					onCancel={onCancel}
					onSelected={onSelected}
					onRatingHover={onRatingHover}
					showEgoModal={false}
					size={ScalerSize.M}
				/>
				<div className="flex flex-col justify-between items-start py-6 relative -top-2">
					<span className="text-creed-high text-[13px] font-bold">
						{messages.creed_high}
					</span>
					<span className="text-creed-med text-[13px] font-bold">
						{messages.creed_med}
					</span>
					<span className="text-creed-low text-[13px] font-bold">
						{messages.creed_low}
					</span>
				</div>
			</div>
		</div>
	), [renderInfo]);

	const boostPostContent = useMemo(() => {
		if (boostTrayProps) {
			return (
				<BoostContent
					tag={tag}
					messages={{
						boost_this_post: messages.boost_this_post,
						boost_info: messages.boost_info,
						view_tutorial: messages.view_tutorial,
					}}
					boostTrayProps={boostTrayProps}
				/>
			);
		}
		return null;
	}, [boostTrayProps, tag, messages]);

	const categories = useMemo(() => {
		const tabs = {
			Rate: ratingSelectorContent,
		};
		if (boostPostContent && boostTrayProps?.authorisation.isBoostAllowed) {
			return {
				...tabs,
				Boost: <div className="flex justify-center">{boostPostContent}</div>,
			};
		}
		return tabs;
	}, [ratingSelectorContent, boostPostContent]);

	return (
		<TabPanel
			tab_categories={categories}
			tabViewMode={TabViewMode.TAB_WITH_PANEL}
			headerClassName="justify-center w-full"
			id="creed-selector-content__tab-panel__wrapper"
			onChangeTab={handleChangeTab}
		/>
	);
};
