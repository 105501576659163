import React, {
	useCallback, useEffect, useLayoutEffect, useRef, useState,
} from "react";
import { DeviceDetectMgr } from "@credo/utilities";
import { Logger } from "../common";

export enum ModalState {
	Active = "active",
	Inactive = "inactive",
	Closing = "closing" // because we can't close ourselves
}

export interface ModalEvent {
	insideModal: boolean;
}

export type ModalPopupProps = {
	className?: string,
	style?: object,
	closeOnOutsideClick?: boolean,
	closeOnESC?: boolean,
	onClose?: () => void,
	onMouseUp?: (evt: any) => void,
	onMouseDown?: (evt: any) => void,
	onMouseMove?: (evt: any) => void,
	onModalStateChange?: (newState: ModalState) => void,
	children: React.ReactNode
};

export const ModalPopup: React.FC<ModalPopupProps> = ({
	className,
	style = {},
	onClose = () => {
	},
	onMouseDown = (_evt: any) => {
	},
	onMouseUp = (_evt: any) => {
	},
	onMouseMove = (_evt: any) => {
	},
	onModalStateChange = (_n: ModalState) => {
	},
	closeOnOutsideClick = true,
	closeOnESC = true,
	children,

}: ModalPopupProps) => {
	const modalContainerRef = useRef<HTMLDivElement>(null);
	const [modalState, _setModalState] = useState(ModalState.Inactive);

	useEffect(() => {
		/**
		 * On Mobile we have to set the modalState as Active because there is
		 * no hove on mobile to make it active. Due to which, if we clicked on
		 * the modal used to close without even updating the rating.
		 * */
		if (DeviceDetectMgr.isMobile()) setModalState(ModalState.Active);
	}, []);

	const setModalState = (newState: ModalState) => {
		if (newState !== modalState) {
			// Logger.debug("Updated state:" + newState);
			_setModalState(newState);
			onModalStateChange(newState);
		}
	};

	/**
	 * Modal onmousemove handler, it protects the rest of the doc from the mouse
	 *
	 */
	const modalOnMouseMove = useCallback((evt: any) => {
		// Logger.debug("modalOnMouseMove:" + evt);

		if (!modalContainerRef.current) {
			Logger.error("We should have been uninstalled");
			return;
		}
		if (modalState === ModalState.Closing) {
			return;
		}

		// eslint-disable-next-line no-param-reassign
		evt.insideModal = (modalContainerRef.current as HTMLElement).contains(evt.target);

		onMouseMove(evt);

		if (evt.insideModal) {
			setModalState(ModalState.Active);
		} else {
			setModalState(ModalState.Inactive);
		}

		if (modalState === ModalState.Inactive) {
			evt.stopPropagation();
			evt.preventDefault();
		}
	}, [modalContainerRef, modalState, onMouseMove]);

	const close = () => {
		setModalState(ModalState.Closing);
		onClose();
	};
	/**
	 * Modal onmouseup - which either submits the selected rating or cnacels selection if the cursor is outside
	 * the selector
	 */
	const modalOnMouseUp = useCallback((evt: any) => {
		// Logger.debug("modalOnMouseUp:" + evt);
		if (evt.ctrlKey) return; // TODO decide whether to keep this developer feature

		// eslint-disable-next-line no-param-reassign
		evt.insideModal = modalContainerRef.current && modalContainerRef.current.contains(evt.target);

		if (!evt.insideModal && closeOnOutsideClick) {
			close();
		}
	}, [modalContainerRef, onMouseUp]);

	const modalOnMouseDown = useCallback((evt: any) => {
		// Logger.debug("modalOnMouseDown:" + evt);
		// eslint-disable-next-line no-param-reassign
		evt.insideModal = modalContainerRef.current && (modalContainerRef.current as HTMLElement).contains(evt.target);

		if (!evt.insideModal && closeOnOutsideClick) {
			close();
		}
	}, [modalContainerRef, onMouseDown]);

	const modalOnKeyPress = useCallback((evt: any) => {
		if (evt.key === "Escape" && closeOnESC) {
			evt.stopPropagation();
			evt.preventDefault();
			close();
		}
	}, [modalContainerRef, closeOnESC]);

	/* setup modal event hooks */
	useLayoutEffect(() => {
		// Logger.debug("Installed handlers");
		document.addEventListener("mouseup", modalOnMouseUp, { capture: true });
		document.addEventListener("mousemove", modalOnMouseMove, { capture: true });
		document.addEventListener("mousedown", modalOnMouseDown, { capture: true });
		window.addEventListener("keydown", modalOnKeyPress, { capture: true });

		return () => {
			// clean up
			// Logger.debug("Uninstalled handlers");
			document.removeEventListener("mouseup", modalOnMouseUp, { capture: true });
			document.removeEventListener("mousemove", modalOnMouseMove, { capture: true });
			document.removeEventListener("mousedown", modalOnMouseDown, { capture: true });
			window.removeEventListener("keydown", modalOnKeyPress, { capture: true });
		};
	}, [modalOnMouseMove, modalOnMouseUp, closeOnESC]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			ref={modalContainerRef}
			className={className}
			style={{
				position: "absolute",
				zIndex: 15,
				...style,
			}}
			onKeyDown={modalOnKeyPress}
		>
			{children}
		</div>
	);
};

ModalPopup.defaultProps = {
	className: undefined,
	style: {},
	onClose: () => {
	},
	onMouseDown: (_evt: any) => {
	},
	onMouseUp: (_evt: any) => {
	},
	onMouseMove: (_evt: any) => {
	},
	onModalStateChange: (_n: ModalState) => {
	},
	closeOnOutsideClick: true,
	closeOnESC: true,
};
